<template>
    <v-container fluid class="pt-0">
    <template v-if="contentStore.select_component != null">

        <!-- 넓이 높이 -->
        <v-row align="center">
            <label style="width:50px;text-align:center;font-size:12px">W</label>
            <input 
                v-model="contentStore.select_component.model.width" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="width:50px;text-align:center;font-size:12px">H</label>
            <input 
                v-model="contentStore.select_component.model.height" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>
        
        <!-- X좌표, Y좌표 -->
        <v-row align="center" style="margin-top:15px">
            <label style="width:50px;text-align:center;font-size:12px">X</label>
            <input 
                v-model="contentStore.select_component.model.transx" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="width:50px;text-align:center;font-size:12px">Y</label>
            <input 
                v-model="contentStore.select_component.model.transy" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>

        <!-- 회전 -->
        <v-row align="center" style="margin-top:15px">
            <label style="width:50px;text-align:center;font-size:12px">R</label>
            <input 
                v-model="contentStore.select_component.model.rotate" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>


        <v-row align="center" class="mt-5">
            <label v-if="this.$storage.getStorageSync('GRADE') > 90"
                style="width:70px;text-align:center;font-size:12px;margin-left:10px;margin-right:0px">Select Lock</label>
            <input v-if="this.$storage.getStorageSync('GRADE') > 90"
                type="checkbox" v-model="contentStore.select_component.model.selectlock"/>
            <label style="width:70px;text-align:center;font-size:12px;margin-left:10px;margin-right:0px">Move Lock</label>
            <input type="checkbox" v-model="contentStore.select_component.model.movelock"/>
        </v-row>        

        <v-row>
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

        <!-- 배경 타입 (사용 안함) 
        <template v-if="contentStore.select_component.model.component_type != 1">
            <v-row justify="center" align="center" dense class="pa-0">
                <v-col cols="12">
                    <label style="font-size:12px">BG Type</label>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0">
                    <v-btn-toggle
                        v-model="contentStore.select_component.model.bgtype"
                        color="primary" 
                        mandatory style="height:34px"
                        @update:modelValue="updateRender"
                        >
                        <v-btn :value="0" style="width:100px;height:30px" variant="text" size="small">color</v-btn>
                        <v-btn :value="1" style="width:100px;height:30px" variant="text" size="small">image</v-btn>
                    </v-btn-toggle>                
                </v-col>
            </v-row>

            <v-row v-if="contentStore.select_component.model.bgtype == 0" justify="center" align="center" dense class="mt-0 pt-0">
                <v-col cols="12">
                    <dialogbgcolor dense title="BG Color" ref="bgcolor" />
                </v-col>
            </v-row>
            
            <v-row v-if="contentStore.select_component.model.bgtype == 1" justify="center" align="center" dense>
                <v-col cols="12">
                    <v-text-field 
                        v-model.transx="contentStore.select_component.model.backgroundImg"
                        @change="updateRender"
                        variant="outlined" density="compact" 
                        single-line hide-details 
                        />
                </v-col>
            </v-row>
        </template>        
        -->

        
        <v-row justify="center" align="center" class="mt-0" dense v-if="this.$storage.getStorageSync('GRADE') > 90">
            
            <v-col cols="12">
                <label style="font-size:12px">Component Type</label>
            </v-col>
            <v-col cols="12">

                <select 
                    v-model="contentStore.select_component.model.component_type" @change="changecomponenttype"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in componenttypelist" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>

                <!-- 
                <v-select 
                    v-model="component.model.component_type" :items="componenttypelist"
                    density="compact" variant="outlined"
                    item-title="name" item-value="value" @update:modelValue="updateRender"
                    style="font-size:12px"
                    />
                -->
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-3 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="투명도" property="opacity" :min="0" :max="1.0" :step="0.01" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </template>
    </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import Component_Model from '@/nextts/model/Component_Model';
export default {
  
  setup(){
    let bgcolor      = ref(null);
    const contentStore = useContentStore();
    return {bgcolor, contentStore}
  },

  methods:{
    
    changecomponenttype(){
        if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_COLOR){
            this.contentStore.select_component.model.backgroundColor = "#ffffffff";
        }else if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_SOUND){
            this.contentStore.select_component.model.width  = 100;
            this.contentStore.select_component.model.height = 100;
            this.changemodel();
        }else if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_API){
            this.contentStore.select_component.model.width  = 100;
            this.contentStore.select_component.model.height = 100;
            this.changemodel();
        }else if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_DATALOAD){
            this.contentStore.select_component.model.width  = 100;
            this.contentStore.select_component.model.height = 100;
            this.changemodel();
        }else if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_TEXT_SCROLL){
            this.contentStore.select_component.model.texthori_align = "left";
        }
        this.updateRender();
    },

    changemodel(){

        //직접 입력으로 수치 변경시 문자열로 좌표가 추가되는 오류가 발생함. 한번 컨버팅 해줘야 버그가 안생김.
        this.contentStore.select_component.model.transx = Number(this.contentStore.select_component.model.transx);
        this.contentStore.select_component.model.transy = Number(this.contentStore.select_component.model.transy);
        
        //컴포넌트 다시 그리고
        this.contentStore.select_component.updateTransElementAll();

        //무브어블 다시 선택
        //this.$emit("reselectmoveable");
        this.$emitter.emit("reselectmoveable");


    },

    updateRender(){
        //컴포넌트 타입이나 배경타입 변경시 렌더링 다시
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },


  },

  //프로퍼티 선언
  props:{
    
  },  

  data(){
    return{
        
        component:null,
        componenttypelist:[
                {name:"TEXT",  value:Component_Model.COMPONENT_TEXT}
            ,   {name:"IMAGE", value:Component_Model.COMPONENT_IMAGE}
            ,   {name:"TEXT SWITCHER",  value:Component_Model.COMPONENT_TEXTSWITCHER}
            ,   {name:"IMAGE SWITCHER", value:Component_Model.COMPONENT_IMAGESWITCHER}
            ,   {name:"COLOR",   value:Component_Model.COMPONENT_COLOR}
            ,   {name:"MOVIE",   value:Component_Model.COMPONENT_MOVIE}
            ,   {name:"YOUTUBE", value:Component_Model.COMPONENT_YOUTUBE}
            ,   {name:"WEBVIEW", value:Component_Model.COMPONENT_WEBVIEW}
            ,   {name:"DATETIME", value:Component_Model.COMPONENT_DATETIME}
            //,   {name:"ARTTEXT", value:Component_Model.COMPONENT_ARTTEXT}
            ,   {name:"TEXT SCROLL", value:Component_Model.COMPONENT_TEXT_SCROLL}
            ,   {name:"SOUND",   value:Component_Model.COMPONENT_SOUND}
            ,   {name:"API",     value:Component_Model.COMPONENT_API}
            ,   {name:"DATA LOAD",     value:Component_Model.COMPONENT_DATALOAD}

        ],
        bgtypelist:[
                {name:"color",  value:0}
            ,   {name:"image",  value:1}
        ],

    }
  },

  mounted(){
    
  },

  
}
</script>