<template>

  <v-container fluid class="pa-0 ma-0">
  <template v-if="contentStore.select_component != null">


        <v-menu 
          v-model="dialog_com"
          location="start"
          :close-on-content-click="true"
          @update:modelValue="menu_handler"
          >
          <template v-slot:activator="{ props }">

            <div v-bind="props" style="cursor:pointer">
              <div class="fontselect d-flex">
                <span class="me-auto">{{ comname }}</span>
                <span class="mr-1 dropdown-icon">&#9662;</span>
              </div>
            </div>

            <select class="mt-1"
              v-model="contentStore.select_component.model.fontweight_idx"
              @change="changefontweight" 
              style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                <template v-for="cptype in subList_com" :key="cptype.FONTWEIGHT_IDX">
                    <option style="font-size:12px" :value="cptype.FONTWEIGHT_IDX">{{cptype.FONTWEIGHT_NAME}}</option>
                </template>
            </select>

          </template>

          <v-card class="ma-0 pa-1" style="width:250px;max-width:250px;min-height:400px;max-height:700px">

              <v-list :lines="false" density="compact" nav>
                <v-list-item color="primary"
                  v-for="element in itemList_com" class="pa-0 ma-0 pl-2"
                  :key="element.FONT_IDX" style="height:20px"
                  :value="element" 
                  @mouseover="previewfont(element)"
                  @click="selectfont(element)"
                  >
                  <template v-if="element.FONT_WEIGHT != null && element.FONT_WEIGHT.length > 0">
                    <label :style="`font-size:14px; font-family:${element.FONT_FAMILY};font-weight:${element.FONT_WEIGHT}`">{{ element.FONT_NAME }}</label>
                  </template>
                  <template v-else>
                    <label :style="'font-size:14px; font-family:'+element.FONT_FAMILY">{{ element.FONT_NAME }}</label>
                  </template>
                  
                </v-list-item>
              </v-list>

            <v-row dense justify="center" class="pa-0 ma-0">
              <v-col cols="11">
                <div style="background:gray;height:1px;" />
              </v-col>
            </v-row>
            <v-card-actions>
              <v-row justify="center">
                <v-btn variant="text" size="small" @click="close_com">닫기</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>

        </v-menu>


  </template>
  </v-container>

</template>

<script>
import wait from 'waait';
import { useContentStore } from "@/stores/content";

    export default {
      
        components:{
          
        },

        props:{
          comname: String,
        },

        watch:{
            comname: async function(v1){
                if(v1){
                  console.log("font change comname...");
                  //해당 컴포넌트의 선택 폰트 정보가 변경되면 하위 굵기를 가져와야 한다.
                  await wait(250);
                  this.backupfont();
                  this.loadfontweight(this.contentStore.select_component.model.font_idx);
                }
            },
        },
        
        setup(){
          const contentStore = useContentStore();
          return {contentStore};
        },

        mounted(){
          this.backupfont();
          console.log(`font dialog mounted... ${this.font_idx} / ${this.fontweight_idx}`);
          this.search_run_com();
        },

        data: ()=> ({
            dialog_com : false,
            load_flag : false,

            //백업
            font_name:"",
            textfont:"",
            font_url:"",
            font_format:"",
            font_weight:"",
            font_style:"",
            font_idx:-1,
            fontweight_idx:-1,

            itemList_com: [],
            subList_com: [],
            search_keyword_com : '',

        }),

        methods: {

          testfocus(){
            console.log("testfocus font...");
          },

          testblur(){
            console.log("testblur font...");
          },

          backupfont(){
            console.log("backup font...");
            //처음 폰트데이터를 백업한다.
            this.font_name  = this.contentStore.select_component.model.font_name
            this.textfont   = this.contentStore.select_component.model.textfont;
            this.font_url   = this.contentStore.select_component.model.font_url;
            this.font_format = this.contentStore.select_component.model.font_format;
            this.font_weight = this.contentStore.select_component.model.font_weight;
            this.font_style = this.contentStore.select_component.model.font_style;
            this.font_idx   = this.contentStore.select_component.model.font_idx;
            this.fontweight_idx = this.contentStore.select_component.model.fontweight_idx;
          },

          changefontweight(event){
            
            const foundItem = this.subList_com.find(item => Number(item.FONTWEIGHT_IDX) === Number(event.target.value));
            if(foundItem){
              this.setfontweight(foundItem);
            }
            console.log("change font weight:  " + JSON.stringify(foundItem) + " / " + event.target.value);

          },

          //마우스 오버시 폰트 미리보기
          previewfont(element){

            console.log(element.FONT_NAME);
            //this.contentStore.select_component.model.font_name    = element.FONT_NAME;  이건 프리뷰에서 바꾸지 말 것.
            this.contentStore.select_component.model.textfont     = element.FONT_FAMILY;
            this.contentStore.select_component.model.font_url     = element.FONT_PATH_REF;
            this.contentStore.select_component.model.font_format  = element.FONT_FORMAT;
            this.contentStore.select_component.model.font_weight  = element.FONT_WEIGHT;
            this.contentStore.select_component.model.font_style   = "normal";
            this.contentStore.select_component.model.font_idx       = element.FONT_IDX;
            this.contentStore.select_component.model.fontweight_idx = element.FONTWEIGHT_IDX;
            this.contentStore.select_component.updateRender();

          },
          
          selectfont(element){

            console.log("select : "+element.FONT_NAME);

            //일단 선택
            this.contentStore.select_component.model.font_name    = element.FONT_NAME;    //선택시엔 바꿔야함.
            this.contentStore.select_component.model.textfont     = element.FONT_FAMILY;
            this.contentStore.select_component.model.font_url     = element.FONT_PATH_REF;
            this.contentStore.select_component.model.font_format  = element.FONT_FORMAT;
            this.contentStore.select_component.model.font_weight  = element.FONT_WEIGHT;
            this.contentStore.select_component.model.font_style   = "normal";
            this.contentStore.select_component.model.font_idx       = element.FONT_IDX;
            this.contentStore.select_component.model.fontweight_idx = element.FONTWEIGHT_IDX;
            this.contentStore.select_component.updateRender();

            //폰트 굵기 가져오기
            this.loadfontweight(element.FONT_IDX);



          },

          //검색
          search_run_com(searchkeyword){
            if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
              this.search_keyword_com = searchkeyword;
            }else{
              this.search_keyword_com = '';
            }
            this.loadpage_com();
          },

          async loadpage_com(){

            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }
            
            this.$http.post(this.$host+'/ComFontList',{
                  search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                , sort_name: "FONT_NAME"
                , sort_type: "ASC" 
                , page: 1
                , pagerow: 9999
            },{headers: { firetoken:token}})
            .then((result)=>{
                
                if(result.data.resultCode == 0){
                  
                    const list = result.data.resultData;
                    this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화

                    list.forEach(element => {

                        this.itemList_com.push(element); 

                        //폰트 등록
                        let fonturl = "url('"+element.FONT_PATH_REF+"')";
                        if(isNaN(element.FONT_FORMAT)){
                          fonturl += " format('"+element.FONT_FORMAT+"')"
                        }

                        let fontoption = {weight: element.FONT_WEIGHT ? element.FONT_WEIGHT : "normal", style:"normal"};
                        var temp_font = new FontFace(element.FONT_FAMILY, fonturl, fontoption);
                        document.fonts.add(temp_font);

                    });

                    //이미 지정된 폰트값이 있는가?
                    const foundItem = this.itemList_com.find(item => item.FONT_IDX === this.font_idx);
                    if(foundItem != null && foundItem != undefined){

                      console.log("1111111");

                      //있다면
                      this.loadfontweight(this.font_idx);
                    }else{

                      console.log("222222");

                      //값이 없으면 첫번째 폰트 지정
                      this.selectfont(this.itemList_com[0]);
                    }

                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                
            });

        },        


        async loadfontweight(fidx){

          let token;
          try{
            token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
          }
            
          this.$http.post(this.$host+'/ComFontWeightList',{
            FONT_IDX: fidx
          },{headers: { firetoken:token}})
          .then((result)=>{
              
              if(result.data.resultCode == 0){
                  
                  const list = result.data.resultData;
                  this.subList_com.splice(0, this.subList_com.length);   //데이터 초기화

                  list.forEach(element => {
                      this.subList_com.push(element); 
                      //폰트 등록
                      let fonturl = "url('"+element.FONT_PATH_REF+"')";
                      if(isNaN(element.FONT_FORMAT)){
                        fonturl += " format('"+element.FONT_FORMAT+"')"
                      }

                      console.log("element.FONT_WEIGHT : " + element.FONT_WEIGHT.length);

                      let fontoption = {weight:element.FONT_WEIGHT == null || element.FONT_WEIGHT.length == 0 ? "normal" : element.FONT_WEIGHT, style:"normal"};
                      var temp_font = new FontFace(element.FONT_FAMILY, fonturl, fontoption);
                      document.fonts.add(temp_font);
                  });

                  //굵기를 가져왔다면 현재 굵기값이 있는지 체크
                  const foundItem = this.subList_com.find(item => item.FONTWEIGHT_IDX === this.fontweight_idx);
                  if(foundItem != null && foundItem != undefined){
                    this.setfontweight(foundItem);
                  }else{
                    //값이 없으면 첫버내 값 지정
                    this.setfontweight(this.subList_com[0]);
                  }

              }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$emitter.emit('clearsession');
              }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              
          });

        },                

        setfontweight(element){
          this.contentStore.select_component.model.font_name    = element.FONT_NAME;    //선택시엔 바꿔야함.
          this.contentStore.select_component.model.textfont     = element.FONT_FAMILY;
          this.contentStore.select_component.model.font_url     = element.FONT_PATH_REF;
          this.contentStore.select_component.model.font_format  = element.FONT_FORMAT;
          this.contentStore.select_component.model.font_weight  = element.FONT_WEIGHT;
          this.contentStore.select_component.model.font_style   = "normal";
          this.contentStore.select_component.model.font_idx       = element.FONT_IDX;
          this.contentStore.select_component.model.fontweight_idx = element.FONTWEIGHT_IDX;
          this.contentStore.select_component.updateRender();
        },

            //다이얼로그 닫기
            close_com () {
              this.contentStore.select_component.model.font_name    = this.font_name;
              this.contentStore.select_component.model.textfont     = this.textfont;
              this.contentStore.select_component.model.font_url     = this.font_url;
              this.contentStore.select_component.model.font_format  = this.font_format;
              this.contentStore.select_component.model.font_weight  = this.font_weight;
              this.contentStore.select_component.model.font_style   = "normal";
              this.contentStore.select_component.model.font_idx       = this.font_idx;
              this.contentStore.select_component.model.fontweight_idx = this.fontweight_idx;

              
              this.updateRender();
              this.dialog_com = false
            },


            menu_handler(){
              //최초 또는 메뉴 외곽 클릭시 
              //백업
              this.font_name = this.contentStore.select_component.model.font_name
              this.textfont = this.contentStore.select_component.model.textfont;
              this.font_url = this.contentStore.select_component.model.font_url;
              this.font_format = this.contentStore.select_component.model.font_format;
              this.font_weight = this.contentStore.select_component.model.font_weight;
              this.font_style = this.contentStore.select_component.model.font_style;
              this.font_idx   = this.contentStore.select_component.model.font_idx;
              this.fontweight_idx = this.contentStore.select_component.model.fontweight_idx;
            },

            //편집창 컴포넌트 렌더링
            updateRender(){
                if(this.contentStore.select_component != null){
                    this.contentStore.saveSnapshot();
                    this.contentStore.select_component.updateRender();
                }
            },

        }

    }
</script>

<style scoped>
.fontselect{
  width:100%;
  border: 1px gray solid; 
  border-radius:5px; 
  padding-left:5px;
  font-size:14px;
  line-height: 28px;
  height:28px;
  font-weight: bold;
}
.dropdown-icon {
  margin-left: 10px;
  color: #333333;
  font-size: 28px;
}
</style>