
//기본 데이터 속성
class AniUtil{

    //기본 진입 애니매이션 정의
    public static getDefaultInAni():String{
        return '{"delay":0, "duration":0.5, "x":0, "y":0, "scale":1, "rotate":0, "alpha":1}';
    }

    //기본 아웃 애니매이션 정의
    public static getDefaultOutAni():String{
        return '{"delay":0, "duration":0.5, "x":0, "y":0, "scale":1, "rotate":0, "alpha":0}';
    }

    //스위치 애니매이션 설정
    public static setSwitcherAni(timeline:GSAPTimeline, anitype:number, el1:HTMLElement, el2:HTMLElement){
        switch(anitype){
            case 0 : //FADE
                timeline!.fromTo(el1, {alpha:"0%"}, {alpha:"100%", duration: 1, overwrite: "auto"});
                timeline!.fromTo(el2, {alpha:"100%"}, {alpha:"0%", duration: 1, overwrite: "auto"}, "-=1");
                break;
            case 1 : //LEFT
                timeline!.fromTo(el1, {x:"100%"}, {x:"0%", duration: 1, overwrite: "auto"});
                timeline!.fromTo(el2, {x:"0%"}, {x:"-100%", duration: 1, overwrite: "auto"}, "-=1");
                break;
            case 2 : //RIGHT
                timeline!.fromTo(el1, {x:"-100%"}, {x:"0%", duration: 1, overwrite: "auto"});
                timeline!.fromTo(el2, {x:"0%"}, {x:"100%", duration: 1, overwrite: "auto"}, "-=1");
                break;
            case 3 : //TOP
                timeline!.fromTo(el1, {y:"100%"}, {y:"0%", duration: 1, overwrite: "auto"});
                timeline!.fromTo(el2, {y:"0%"}, {y:"-100%", duration: 1, overwrite: "auto"}, "-=1");
                break;
            case 4 : //DOWN
                timeline!.fromTo(el1, {y:"-100%"}, {y:"0%", duration: 1, overwrite: "auto"});
                timeline!.fromTo(el2, {y:"0%"}, {y:"100%", duration: 1, overwrite: "auto"}, "-=1");
                break;
        }
    }

    

}
export default AniUtil
