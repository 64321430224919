
//컴포넌트 정의 모델
class APIParam_Model {

    key:string = "";
    value:string = "";

    constructor(){

    }

    setStringValue(params:string):void{
        const temp  = JSON.parse(JSON.stringify(params));
        this.key    = temp.key;
        this.value  = temp.value;
    }


}
//module.exports = {Component_Model}
export default APIParam_Model
