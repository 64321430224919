<template>

  <v-container fluid>

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>

              <v-row>
                  <v-text-field variant="outlined" density="compact" label="이름"
                  v-model="editedItem.POPUP_NAME"  ></v-text-field>
              </v-row>

              <v-row>
                <v-select 
                  v-model="editedItem.CATEGORY_IDX" :items="categoryList"
                  density="compact" variant="outlined"
                  item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
                  label="카테고리"/>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="6" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="6" md="3" align="end">
        <v-select 
          label="카테고리"
          v-model="SEARCH_CATEGORY_IDX" :items="categoryList" 
          item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
          outlined color="#808080" hide-details 
          density="compact" variant="outlined"
          @update:modelValue="loadpage(1)"
          />
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
    </v-row>


  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>

  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        :mobile="false"
        >
        <template v-slot:header.POPUP_NAME>
          <a :class="sort_name=='POPUP_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('POPUP_NAME')">NAME</a>
        </template>

        <template v-slot:item.THUM_JSON="{ item }">  
          <v-row justify="center">
            <v-col align="center" cols="12">
              <template v-if="item.POPUP_IDX != undefined">
              <ThumScreen
                :id="item.POPUP_IDX"
                :screen_model="item.screen_model"
                :scalewidth="200"
                :key="renderKey"
                />
              </template>
            </v-col>
          </v-row>
        </template>     

        <!-- 
        <template v-slot:item.POPUP_IMG="{ item }">  
          <v-img
              width="200"
              :src="item.POPUP_IMG"
              :lazy-src="item.POPUP_IMG"
              aspect-ratio="1"
              />
        </template>     
        -->

        <template v-slot:item.POPUP_NAME="{ item }">  
            <a style="cursor:pointer" @click="move_edit(item)">{{item.POPUP_NAME}}</a>
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
            <v-icon small @click="test_play(item)">mdi-play</v-icon>
            
        </template>     
        <template v-slot:bottom></template>
      </v-data-table>
    </v-col>
  </v-row>

  
  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import Screen_Model from '@/nextts/model/Screen_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';

export default {

    components:{
      ThumScreen,
    },

    data: () => ({

      renderKey:0,

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          SUBACCNT_IDX:'',
          LOCALID:'',
          SUBACCNT:'',
          PW:'',
          STATE:1,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'POPUP_NAME',  sortable: false, align:'center' },
        {title: 'Category', key: 'CATEGORY_NAME', width:200, sortable: false, align:'center' },
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      SEARCH_CATEGORY_IDX:null,   
      categoryList:[],

      //추가 변수들
      STATELIST: [
          {name:'활성',     value:1}
        , {name:'비활성',   value:0}
      ],
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      grade:0,

    }),

    setup(){
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        let contentStore   = useContentStore();
        return {paging, editor, searchbar, contentStore};
    },

    mounted(){
      
      if(this.$storage.getStorageSync("GRADE") < 80){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
        this.$router.push("/Login");
        return;
      }

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      this.SEARCH_LOCALID    = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      console.log("grade :" + this.grade);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      newinsert(){
        this.$router.push({path:"/EditContent", query:{type:2, mode:0}});
      },

      move_edit(item){

        //셋팅해서 편집으로 들어가기 (사용안함)
        /*
        var jobj = JSON.parse(JSON.stringify(item.POPUP_JSON));
        this.contentStore.setContentSize(jobj.width, jobj.height);
        this.contentStore.content_model.idx = item.POPUP_IDX;
        this.contentStore.content_model.content_type = 0;
        this.contentStore.content_model.content_mode = 0; //관리자 모드
        this.contentStore.content_model.name = item.POPUP_NAME;
        this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
        this.contentStore.content_model.setStringValue(item.POPUP_JSON);
        */
        this.$router.push({path:"/EditContent", query:{type:2, idx:item.POPUP_IDX, mode:0}});
      },


      //전체 재생 플레이 테스트
      test_play(item){
        var jobj = JSON.parse(JSON.stringify(item.POPUP_JSON));
        this.contentStore.setContentSize(jobj.width, jobj.height);
        this.contentStore.content_model.idx = item.POPUP_IDX;
        this.contentStore.content_model.content_type = 0;
        this.contentStore.content_model.content_mode = 0; //관리자 모드
        this.contentStore.content_model.name = item.POPUP_NAME;
        this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
        this.contentStore.content_model.setStringValue(item.POPUP_JSON);
        this.$router.push({path:"/ContentPlay"});
      },


      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadanicategory(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}
        
        this.$http.post(this.$host+'/CategoryTypeList',{
              search_keyword: ''
            , CATEGORY_TYPE: 50
            , sort_name: "CATEGORY_SORT"
            , sort_type: "ASC"
        },{headers: { firetoken:token}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.categoryList.splice(0, this.categoryList.length);   //데이터 초기화
              this.categoryList.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
              list.forEach(element => {
                this.categoryList.push(element); 
              });
              
              this.SEARCH_CATEGORY_IDX = this.categoryList[0].CATEGORY_IDX;
              this.loadpage(this.page);

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },

      async loadpage(p){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          console.log("loadpage : " + this.page);
          this.$http.post(this.$host+'/PopupList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => 
                {
                  let screen_model        = new Screen_Model();
                  screen_model.setStringValue(element.THUM_JSON);
                  screen_model.cacheFont();
                  element.screen_model = screen_model;
                  this.itemList.push(element); 
                });
                this.renderKey++;
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadanicategory();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.editedItem.POPUP_NAME  == undefined || this.editedItem.POPUP_NAME.length < 1){
          this.$alert.fire("이름을 입력하세요");
          return;
        } 
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        //수정
        this.loading = true;
        this.$http.post(this.$host+'/PopupUpdate2',{
              POPUP_NAME: escape(this.editedItem.POPUP_NAME)
            , CATEGORY_IDX: this.editedItem.CATEGORY_IDX
            , POPUP_IDX: this.editedItem.POPUP_IDX
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            const p = this.page;
            this.page = 0;
            this.$alert.fire("수정이 완료되었습니다.").then(()=>{
              this.loadpage(p);
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('수정이 실패했습니다.');
        });

        this.close()
      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/PopupList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){


        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/PopupDelete',{
              POPUP_IDX:item.POPUP_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  this.loadpage(1);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage(1);
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage(1);
      },



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>