import GradientPoint_Model from '@/nextts/model/GradientPoint_Model';

//그라디언트 모델 - ART TEXT 전용
class Gradient_Model {

    type: string = "linear";
    degree: number = 0;
    points: Array<GradientPoint_Model>;

    constructor(){
        this.points = Array<GradientPoint_Model>();
        const gp1 = new GradientPoint_Model();
        gp1.setStringValue(JSON.parse(JSON.stringify({left: 0,red: 0,green: 0,blue: 0,alpha: 1,})));
        const gp2 = new GradientPoint_Model();
        gp2.setStringValue(JSON.parse(JSON.stringify({left: 100,red: 255,green: 0,blue: 0,alpha: 1,})));
        this.points.push(gp1);
        this.points.push(gp2);
    }

    //초기화
    resetColor(){
        this.type = "linear";
        this.degree = 0;
        this.points.splice(0, this.points.length);
        const gp1 = new GradientPoint_Model();
        gp1.setStringValue(JSON.parse(JSON.stringify({left: 0,red: 0,green: 0,blue: 0,alpha: 1,})));
        const gp2 = new GradientPoint_Model();
        gp2.setStringValue(JSON.parse(JSON.stringify({left: 100,red: 255,green: 0,blue: 0,alpha: 1,})));
        this.points.push(gp1);
        this.points.push(gp2);
    }


    generateGradientStyle(){
        let style = ''
        const sortedPoints = this.points.slice();
        sortedPoints.sort((a, b) => a.left - b.left)
        if (this.type === 'linear') {
          style = `linear-gradient(${this.degree}deg,`
        } else {
          style = 'radial-gradient('
        }
        sortedPoints.forEach((point, index) => {
          style += `rgba(${point.red}, ${point.green}, ${point.blue}, ${point.alpha}) ${point.left}%`
          if (index !== sortedPoints.length - 1) {
            style += ','
          }
        })
        style += ')'
        return style
    }


    setStringValue(params:string):void{
    
        const temp  = JSON.parse(JSON.stringify(params));
        
        this.type       = temp.type;
        this.degree     = temp.degree;

        //포인트 정보
        this.points.splice(0, this.points.length);
        temp.points.forEach((pnt:string) => {
            const cm = new GradientPoint_Model();
            cm.setStringValue(pnt);
            this.points.push(cm);
        });
        
    }



}
//module.exports = {Component_Model}
export default Gradient_Model
