<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 0 && this.contentStore.content_model.content_type == 2">

    <v-container fluid class="ma-0 pt-0">
    
        <!-- 팝업 사용선택 -->
        <v-row align="center" dense>
            <v-col cols="12">
                <v-switch label="팝업 맵핑" color="red"  hide-details v-model="this.contentStore.select_component.model.use_popup"></v-switch>
            </v-col>
        </v-row>

        <v-row dense class="mt-3 mb-0"> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{


  },

  data(){
    return{

    }
  },

  mounted(){
    
  },

  
}
</script>