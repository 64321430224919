<template>
  <v-container fluid>

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              
              <v-row justify="space-around">
                <v-col
                  cols="11"
                  sm="5"
                >
                  <v-text-field
                    v-model="time"
                    :active="menu2"
                    :focus="menu2"
                    label="Picker in menu"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      activator="parent"
                      transition="scale-transition"
                    >
                      <v-time-picker
                        v-if="menu2"
                        v-model="time"
                        full-width
                      ></v-time-picker>
                    </v-menu>
                  </v-text-field>
                </v-col>
          
                <v-col
                  cols="11"
                  sm="5"
                >
                  <v-text-field
                    v-model="time"
                    :active="modal2"
                    :focused="modal2"
                    label="Picker in dialog"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                  >
                    <v-dialog
                      v-model="modal2"
                      activator="parent"
                      width="auto"
                    >
                      <v-time-picker
                        v-if="modal2"
                        v-model="time"
                      ></v-time-picker>
                    </v-dialog>
                  </v-text-field>
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="savetime">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>

      <v-col cols="12" md="3" v-if="this.$storage.getStorageSync('GRADE') >= 10">
        <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
      </v-col>

      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="save">저장</v-btn>
      </v-col>
    </v-row>

  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>


  <v-row align="start" dense>
    <v-col cols="12" md="6"> 
      <v-table 
        fixed-header density="compact" height="600"
        >
        <thead>
          <tr>
            <th style="width:200px; text-align:center" class="text-left">이미지</th>
            <th style="width:200px; text-align:center" class="text-center">소유자</th>
            <th style="width:200px; text-align:center" class="text-center">컨텐츠명</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="content in contentList" :key="content">
          <td style="width:200px; text-align:center" >
            <template v-if="content.MCON_IDX != undefined">
              <ThumScreen
                :id="content.MCGROUPDETAIL_IDX"
                :screen_model="content.screen_model"
                :scalewidth="200" 
                draggable="true"
                @drag="drag_handler"
                @dragstart="($event) => dragstart_handler($event, content)"
                @dragover="dragover_handler"
                @dragend="dragend_handler"
                @dragenter="dragenter_handler"
                @dragleave="dragleave_handler"
                @drop="drop_handler"
                />
            </template>
          </td>
          <td style="width:200px; text-align:center">{{content.PARENT_NAME}}</td>
          <td style="width:200px; text-align:center">{{content.MCON_NAME}}</td>
          </tr>
        </tbody>
      </v-table>

    </v-col>

    <v-col cols="12" md="6"> 

      <v-table 
        fixed-header density="compact" height="600"
        @drop="drop_handler_table"
        @dragover.prevent
        >
        <thead>
          <tr>
            <th style="width:50px;" class="text-center"></th>
            <th style="width:200px; text-align:center" class="text-left">이미지</th>
            <th style="width:200px; text-align:center" class="text-center">이름</th>
            <th style="width:80px; text-align:center" class="text-center">삭제</th>
          </tr>
        </thead>
        <draggable v-model="itemList" tag="tbody"  
          @change="handleDrag" :key="listRenderCount"
          >
          <template #item="{ element }">
            <tr>
              <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
              <td style="width:200px; text-align:center" >
                <template v-if="element.MCON_IDX != undefined">
                <ThumScreen
                  :id="element.MCON_IDX"
                  :screen_model="element.screen_model"
                  :scalewidth="200" 
                  />
                </template>
                <!-- 
                  <v-img width="200" :src="element.MCON_IMG"/>
                -->
              </td>
              <td style="width:80px; text-align:center">{{element.MCON_NAME}}</td>
              <td style="width:80px; text-align:center"><v-icon small @click="delete_item(element)">mdi-delete</v-icon></td>
            </tr>
          </template>
        </draggable>
      </v-table>

      

    </v-col>
  </v-row>

  

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import Screen_Model from '@/nextts/model/Screen_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';

export default {

    components:{
      draggable, ThumScreen
    },

    data: () => ({
      
      listRenderCount:0,
      
      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          LOCALID:'',
          MCGROUP_NAME:'',
      },
      editedItem: {},
      selected:[],              //테이블에 선택된 아이템 리스트

      
      theaders1: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: '소유자', key: 'NAME',  sortable: false, align:'center' },
        {title: '컨텐츠명', key: 'MCON_NAME',  sortable: false, align:'center' }
      ],
      
      theaders2: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' },
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],
      contentList: [],
      itemList: [],
      deleteList: [],

      sort_name: 'MCGROUP_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      grade:0,
      
      MCGROUP_IDX: null,

    }),

    setup(){

    },

    mounted(){
      
      this.MCGROUP_IDX         = this.$route.query.idx;
      this.SEARCH_LOCALID    = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      console.log("grade :" + this.grade);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      savetime(){
        this.close();
      },

      newinsert(){
        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.dialog = true;
      },

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        this.dialog_pw  = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },      

      //우측 순서변경
      handleDrag(){
        this.listRenderCount++;
      },

      drop_handler_table(event){
        //console.log("drop_handler_table");
        var drop_item = event.dataTransfer.getData('customData');
        if(drop_item == null || drop_item == undefined || drop_item.length == 0){
          return;
        }
        console.log("drop_handler_table : " + drop_item);

        const dropitem   = JSON.parse(drop_item);
        let screen_model = new Screen_Model();
        screen_model.setStringValue(dropitem.THUM_JSON);
        dropitem.screen_model = screen_model;
        this.itemList.push(dropitem);

      },

      dragstart_handler(event, item){
        //드롭 객체를 저장
        console.log("dragstart : " + JSON.stringify(item));
        event.dataTransfer.setData('customData', JSON.stringify(item));
      },

      drag_handler() {
        //ondrag =  드래그할때 동작 
        //console.log("drag_handler : ");
      },
      dragover_handler(event) {
        //console.log("dragover_handler");
        this.isDragOver = true;
        event.preventDefault();
      },
      dragend_handler() {
        //console.log("dragend_handler");
      },
      dragenter_handler() {
        //console.log("dragenter_handler");
        this.isDragOver = true;
      },
      dragleave_handler(){
        //console.log("dragleave_handler");
        this.isDragOver = false;
      },
      
      drop_handler(event){
        //console.log("drop_handler");
        event.preventDefault();
        this.isDragOver = false;
      },




      
      //등록된 내컨텐츠 로드
      async loadmycontent(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.$http.post(this.$host+'/MyContentList',{
              search_keyword: ""
            , sort_name: "MCON_NAME"
            , sort_type: "DESC"
            , page: 1
            , pagerow: 99999
            , LOCALID:this.SEARCH_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.contentList.splice(0, this.contentList.length);   //데이터 초기화
              list.forEach(element => {
                let screen_model        = new Screen_Model();
                screen_model.setStringValue(element.THUM_JSON);
                screen_model.cacheFont();
                element.screen_model = screen_model;
                if(element.MCON_STATE == 1){
                  this.contentList.push(element); 
                }
              });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },      

      //그룹컨텐츠 상세내역 로드
      async loadpage(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.$http.post(this.$host+'/MyContentGroupDetail',{
                MCGROUP_IDX:this.MCGROUP_IDX
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                  try{
                    let screen_model        = new Screen_Model();
                    screen_model.setStringValue(element.THUM_JSON);
                    screen_model.cacheFont();
                    element.screen_model = screen_model;
                    this.itemList.push(element); 
                  }catch(error){
                  }
                });
                this.loading = false;
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){

        //내컨텐츠 로드
        this.loadmycontent();

        //컨텐츠 그룹상세 로드
        this.loadpage();

      },      


      //저장
      async save () {

          if(this.grade > 90){
            if(this.SEARCH_LOCALID == null || this.SEARCH_LOCALID == undefined){
              this.$alert.fire("계정을 선택하세요");
              return;
            }
          }        

          //순서 저장
          var tempidx = 1;
          this.itemList.forEach(item=>{
            item.SORT = tempidx;
            tempidx++;
          });
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.$http.post(this.$host+'/MyContentGroupSave',{
                CONTENTLIST: escape(JSON.stringify(this.itemList))
              , DELETELIST: escape(JSON.stringify(this.deleteList))
              , MCGROUP_IDX: this.MCGROUP_IDX
              , LOCALID:this.SEARCH_LOCALID
              , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("저장이 완료되었습니다.").then(()=>{
                this.loadpage();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('저장이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('저장이 실패했습니다.');
          });
        
      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage();
      },      

      //아이템 삭제
      delete_item(item){
        item.mode = "delete";
        let index = this.itemList.indexOf(item);
        this.itemList.splice(index, 1);
        this.deleteList.push(item);
      },

      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage(1);
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage(1);
      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.dragOver{
  border: 10px rgb(255, 0, 0) solid;
}

.dragNone{
  border: 1px rgb(107, 107, 107) solid;
}

</style>