<!-- 현재 해당 페이지는 사용안함 -->
<template>

  <v-container fluid >

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />


    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" max-width="600px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

                <v-row dense align="center" class="text-center">
                  <v-col cols="3">
                    <label>동영상 타이틀</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      single-line v-model="editedItem.MV_NAME" dense
                      placeholder="타이틀을 입력하세요" variant="outlined" density="compact" color="#808080" hide-details        
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">상태</label>
                  </v-col>
                  <v-col cols="9">
                    <v-select v-model="editedItem.MV_STATE" :items="STATELIST" variant="outlined" density="compact"
                      item-title="name" item-value="value" class="dialogselectstyle"
                      hide-details elevation="0" dense height="48px"
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" class="text-center">
                  <v-col cols="3">
                    <label>태그</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      single-line v-model="editedItem.MV_TAG" dense
                      placeholder="태그를 입력하세요" variant="outlined" density="compact" color="#808080" hide-details        
                      />
                  </v-col>
                </v-row>

                <v-row  dense align="center" class="text-center">
                  <v-col cols="3">
                    <label class="mytextstyle">동영상</label>
                  </v-col>
                  <v-col cols="9">
                    <v-file-input 
                      variant="outlined" density="compact" dense height="48" class="popupiputstyle"
                      show-size hide-details v-model="uploadMovieFile" 
                      accept="video/mp4"
                      @change="changeMoviefile"
                      >
                    </v-file-input>
                  </v-col>
                </v-row>

                <v-row  dense align="center" class="text-center">
                  <v-col cols="3">
                    <label class="mytextstyle">동영상</label>
                  </v-col>
                  <v-col cols="9" align="center" align-self="center" style="height:120px;width:120px">
                    <!-- <video ref="video" width="100%" controls ></video> -->
                    <video ref="video" style="width:100%" controls :src="editedItem.MV_PATH"></video>
                  </v-col>
                </v-row>

                <v-row class="mt-15">
                </v-row>
                <v-row class="mt-15">
                </v-row>

            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

  <v-row align="center" justify="start" class="mt-5" dense>
    <v-col cols="6" md="3" sm="12" xs="12">
      <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
    <v-col cols="6" md="3" align="end">
      <v-select 
        label="카테고리"
        v-model="SEARCH_CATEGORY_IDX" :items="categoryList" 
        item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
        outlined color="#808080" hide-details 
        density="compact" variant="outlined"
        @update:modelValue="loadpage(1)"
        />
    </v-col>
    <v-col cols="3" md="1" class="pa-0" align="center">         
      <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
    </v-col>
  </v-row>

  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>

    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="MV_IDX"
        :loading="loading"
        :mobile="false"
        >
        <template v-slot:header.MV_NAME>
          <a :class="sort_name=='MV_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('MV_NAME')">타이틀</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일시</a>
        </template>

        <template v-slot:item.MV_PATH="{ item }">
          <video ref="video" style="width:200px" :src="item.MV_PATH" controls />
        </template>

        <template v-slot:item.REG_DATE="{ item }">  
          {{ this.$momenttz.utc(item.REG_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }}
        </template>


        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>  
        
        <template v-slot:bottom></template>

      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref as fref, uploadBytesResumable, uploadBytes, uploadString, deleteObject } from "firebase/storage";
import { ref } from '@vue/reactivity';
export default {
    components:{
      VueCropper
    },

    setup(){
        var searchbar = ref(null);
        var paging = ref(null);
        return {searchbar, paging};
    },

    data: () => ({

      progress_dialog:false,
      progress_value:0,
      uploadMovieFile : [],

      STATELIST: [
          {name:'사용안함',   value:0}
        , {name:'사용',       value:1}
      ],

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          MV_NAME:'',
          MV_TAG:'',
          MV_PATH:'',
          MV_STATE:1,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '동영상', key: 'MV_PATH', width:200,sortable: false, align:'center'},
        {title: '타이틀', key: 'MV_NAME', sortable: false, align:'center', class:"cheader_normal"},
        {title: '상태', key: 'MV_STATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '등록일', key: 'REG_DATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '수정', key: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      SEARCH_CATEGORY_IDX:null,   
      categoryList:[],

    }),

    mounted(){

      if(this.$storage.getStorageSync("GRADE") < 80){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }


      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {


      async loadanicategory(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}
        
        this.$http.post(this.$host+'/CategoryTypeList',{
              search_keyword: ''
            , CATEGORY_TYPE: 3
            , sort_name: "CATEGORY_SORT"
            , sort_type: "ASC"
        },{headers: { firetoken:token}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.categoryList.splice(0, this.categoryList.length);   //데이터 초기화
              this.categoryList.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
              list.forEach(element => {
                this.categoryList.push(element); 
              });
              
              this.SEARCH_CATEGORY_IDX = this.categoryList[0].CATEGORY_IDX;
              this.loadpage(this.page);

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/ResMvList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //이미지 선택시
      changeMoviefile(){
        if(this.uploadMovieFile != null || this.uploadMovieFile != undefined || this.uploadMovieFile[0].name.length > 0){
          console.log("change movie : " + this.uploadMovieFile[0].size);
          if(this.uploadMovieFile[0].size/1024/1024 > 50){
            this.$alert.fire("동영상은 50MB 이하 파일만 사용할 수 있습니다.");
            return;
          }
          const videourl = URL.createObjectURL(this.uploadMovieFile[0]);
          
          this.editedItem.MV_PATH = videourl;
          //this.$refs.video.src = videourl;
        }
      },

      //firebase storage에 파일 업로드 및 프로그레스 표시
      uploadFileStorage(storage_ref, blobd_ata, metadata){
        return new Promise((resolve, reject)=>{

            const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                }                
              },
              (error) => {
                console.log("upload error : " + error);
                reject(null);
              },
              () => {
                // Upload completed successfully - 업로드 완료시 경로 리턴
                resolve(uploadTask.snapshot.ref);
              }
            );

        })
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },



      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/ResMvList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadanicategory();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.uploadMovieFile == null || this.uploadMovieFile == undefined || this.uploadMovieFile.length < 1){
            if(this.isNullCheck(this.editedItem.MV_PATH_REF)){
              //기존에 등록된 영상이 있으면 패스
              this.$alert.fire("동영상을 등록하세요");
              return;
            }
        }else{
            
            this.progress_dialog = true;            
            let mvpath;
            //신규인지 업데이트인지 체크
            if(!this.isNullCheck(this.editedItem.MV_PATH_REF)){
              mvpath  = this.editedItem.MV_PATH_REF;
            }else{
              let curdate = Date.now().toString();
              mvpath  = `mvres/${curdate}.mp4`;
            }

            const uploadfile_ref1 = fref(this.$firestorage, mvpath);
            const metadata1 = { contentType: 'video/mp4', resumable: false }; 
            let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadMovieFile[0], metadata1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$alert.fire("동영상 등록 오류 발생");
              return;
            } 
            this.uploadMovieFile = [];
            /*
            if(!this.isNullCheck(this.editedItem.MV_PATH_REF)){
              const desertRef1 = fref(this.$firestorage, this.editedItem.MV_PATH_REF);
              await deleteObject(desertRef1);
            }
            */
            this.editedItem.MV_PATH_REF = upload_cb1._location.path_;
        }

        if(this.editedIndex > -1){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/ResMvUpdate', {
              MV_IDX: this.editedItem.MV_IDX
            , MV_NAME: this.editedItem.MV_NAME == null || this.editedItem.MV_NAME == undefined ? '' : escape(this.editedItem.MV_NAME)
            , MV_TAG: this.editedItem.MV_TAG == null || this.editedItem.MV_TAG == undefined ? '' : escape(this.editedItem.MV_TAG)
            , MV_PATH: this.editedItem.MV_PATH_REF == null || this.editedItem.MV_PATH_REF == undefined ? '' : escape(this.editedItem.MV_PATH_REF)
            , MV_STATE: this.editedItem.MV_STATE
          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(this.page);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.progress_dialog = false;
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });
        }else{
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/ResMvInsert',{
              MV_NAME: this.editedItem.MV_NAME == null || this.editedItem.MV_NAME == undefined ? '' : escape(this.editedItem.MV_NAME)
            , MV_TAG: this.editedItem.MV_TAG == null || this.editedItem.MV_TAG == undefined ? '' : escape(this.editedItem.MV_TAG)
            , MV_PATH: this.editedItem.MV_PATH_REF == null || this.editedItem.MV_PATH_REF == undefined ? '' : escape(this.editedItem.MV_PATH_REF)
            , MV_STATE: this.editedItem.MV_STATE
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                
                const p = this.page;
                this.page = 0;
                this.$alert.fire("새로운 동영상이 추가되었습니다.").then(()=>{
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert.fire('추가 실패');
          });
        }

        this.close()
      },      

      //검색
      search_run(){
        this.loadpage(1);
      },
      

      //신규 추가
      newinsert(){
        this.uploadMovieFile = [];
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
        window.scrollTo(0,0);
      },

      //아이템 수정
      edit_item(item){

        console.log("item : " + JSON.stringify(item));
        this.uploadMovieFile = [];
        this.dialogTitle     = "수정하기"
        this.editedIndex     = this.itemList.indexOf(item);
        this.editedItem      = Object.assign({}, item);
        //this.video.src = this.editedItem.MV_PATH;
        this.dialog = true;
      },



      //아이템 삭제
      delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/ResMvDelete',{
              MV_IDX:item.MV_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                
                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
                
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>