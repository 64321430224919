<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 40">

    <v-container fluid class="ma-0 pt-0">

        <v-row justify="center" align="center" dense class="mt-0 pt-0">

            <v-row class="pt-0 ma-0" align="center" justify="center" dense>
                <v-col cols="12">
                    <label style="font-size:12px">DATA TYPE</label>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <select 
                        v-model="contentStore.select_component.model.load_type"
                        style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                        <template v-for="cptype in datatypelist" :key="cptype.name">
                            <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                        </template>
                    </select>
                </v-col>
            </v-row>

            <v-row class="pt-0 ma-0" align="center" justify="center" dense>
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">저장소 이름</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <input 
                        v-model="contentStore.select_component.model.load_name" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                </v-col>
            </v-row>

            <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="contentStore.select_component.model.load_type == 1">
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">Value Map Key</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <input 
                        v-model="contentStore.select_component.model.load_path" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                </v-col>
            </v-row>
            
        </v-row>

        <v-row dense class="mt-3"> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{


  },

  data(){
    return{

        datatypelist:[
                {name:"Text",       value:0}
            ,   {name:"Api",        value:1}
        ],        

    }
  },

  mounted(){
    
  },

  
}
</script>