<template>
  <v-container fluid class="pa-0 ma-0" style="overflow:auto" :key="renderCount">
    <ComInfo_Screen   ref="infoscreen" />
    <ComInfo_Position ref="infopos" />
    <ComInfo_Text     ref="infotext" />
    <ComInfo_DateTime ref="infodatetime" />
    <ComInfo_ArtText  ref="infoarttext" />
    <ComInfo_Image    ref="infoimage" />
    <ComInfo_TextSwitcher   ref="infotextswitcher" />
    <ComInfo_ImageSwitcher  ref="infoimageswitcher" />
    <ComInfo_Color    ref="infocolor" />
    <ComInfo_Movie    ref="infomovie" />
    <ComInfo_Youtube  ref="infoyoutube" />
    <ComInfo_Webview  ref="infowebview" />
    <ComInfo_Sound      ref="infosound" />
    <ComInfo_API        ref="infoapi" />
    <ComInfo_TextScroll ref="infotextscroll" />
    <ComInfo_Outline    ref="infooutline" />
    <ComInfo_Shadow     ref="infoshadow" />
    <ComInfo_Mask       ref="infomask" />
    <ComInfo_Popup      ref="infopopup" />
    <ComInfo_APIMAP     ref="infoapimap" />
    <ComInfo_Save       ref="infosave" />
    <ComInfo_DataLoad   ref="infodataload" />
    <ComInfo_Event      ref="infoevent" />    
    
    
  </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import ComInfo_Screen from '@/nextvue/infopanel/ComInfo_Screen.vue'
import ComInfo_Position from '@/nextvue/infopanel/ComInfo_Position.vue'
import ComInfo_Text from '@/nextvue/infopanel/ComInfo_Text.vue'
import ComInfo_DateTime from '@/nextvue/infopanel/ComInfo_DateTime.vue'
import ComInfo_ArtText from '@/nextvue/infopanel/ComInfo_ArtText.vue'
import ComInfo_Image from '@/nextvue/infopanel/ComInfo_Image.vue'
import ComInfo_TextSwitcher from '@/nextvue/infopanel/ComInfo_TextSwitcher.vue'
import ComInfo_ImageSwitcher from '@/nextvue/infopanel/ComInfo_ImageSwitcher.vue'
import ComInfo_Color from '@/nextvue/infopanel/ComInfo_Color.vue'
import ComInfo_Movie from '@/nextvue/infopanel/ComInfo_Movie.vue'
import ComInfo_Youtube from '@/nextvue/infopanel/ComInfo_Youtube.vue'
import ComInfo_Webview from '@/nextvue/infopanel/ComInfo_Webview.vue'
import ComInfo_Sound from '@/nextvue/infopanel/ComInfo_Sound.vue'
import ComInfo_API from '@/nextvue/infopanel/ComInfo_API.vue'
import ComInfo_APIMAP from '@/nextvue/infopanel/ComInfo_APIMAP.vue'
import ComInfo_Event from '@/nextvue/infopanel/ComInfo_Event.vue'
import ComInfo_TextScroll from '@/nextvue/infopanel/ComInfo_TextScroll.vue'
import ComInfo_Outline from '@/nextvue/infopanel/ComInfo_Outline.vue'
import ComInfo_Shadow from '@/nextvue/infopanel/ComInfo_Shadow.vue'
import ComInfo_Mask from '@/nextvue/infopanel/ComInfo_Mask.vue'
import ComInfo_Popup from '@/nextvue/infopanel/ComInfo_Popup.vue'
import ComInfo_Save from '@/nextvue/infopanel/ComInfo_Save.vue'
import ComInfo_DataLoad from '@/nextvue/infopanel/ComInfo_DataLoad.vue'

import { useContentStore } from "@/stores/content";

export default {
  
  setup(){
    let infoscreen    = ref(null);
    let infopos       = ref(null);
    let infotext      = ref(null);
    let infoimage     = ref(null);
    let infotextswitcher  = ref(null);
    let infoimageswitcher = ref(null);
    let infocolor     = ref(null);
    let infomovie     = ref(null);
    let infoyoutube   = ref(null);
    let infowebview   = ref(null);
    let infosound     = ref(null);
    let infoapi       = ref(null);
    let infoapimap    = ref(null);
    let infoevent     = ref(null);
    let infotextscroll = ref(null);
    let infoshadow    = ref(null);
    let infomask      = ref(null);
    let infooutline   = ref(null);
    let infodatetime   = ref(null);
    let infoarttext   = ref(null);
    let infopopup     = ref(null);
    let infosave      = ref(null);
    let infodataload  = ref(null);
    
    const contentStore = useContentStore();
    return {
       infoscreen, infopos, infotext, infoimage
      , infotextswitcher, infoimageswitcher, infocolor, infodataload
      , infomovie, infoyoutube, infowebview, infoshadow, infosave
      , infosound, infoapi, infotextscroll, infoapimap, infoevent
      , infomask, infooutline, infodatetime, infoarttext, infopopup
      , contentStore
    }
  },
  
  // eslint-disable-next-line vue/no-unused-components
  components:{
      ComInfo_Screen, ComInfo_Position, ComInfo_Text, ComInfo_Image
    , ComInfo_TextSwitcher, ComInfo_ImageSwitcher, ComInfo_Color
    , ComInfo_Movie, ComInfo_Youtube, ComInfo_Webview, ComInfo_Shadow
    , ComInfo_Sound, ComInfo_API, ComInfo_APIMAP, ComInfo_TextScroll
    , ComInfo_Mask, ComInfo_Outline, ComInfo_DateTime, ComInfo_ArtText
    , ComInfo_Popup, ComInfo_Event, ComInfo_Save, ComInfo_DataLoad
  },

  //프로퍼티 선언
  props:{

  },  

  data(){
    return{
        renderCount:0
    }
  },

  mounted(){
    this.$emitter.on('updateinfopanel', this.updateinfopanel);
    this.renderCount++;
  },

  beforeUnmount(){
    this.$emitter.off('updateinfopanel');
  },

  methods:{

    updateinfopanel(){
      this.renderCount++;
    }    

  },



}
</script>