<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 3">
    <v-container fluid>

        <v-dialog persistent v-model="textinput_dialog" max-width="780px">
            <v-card class="pa-2">
               <v-card-text>
                   <v-text-field
                       v-model="this.contentStore.select_component.model.imgswitcher_list[imgswitcher_pos]"
                       single-line variant="outlined" density="compact"
                       @keydown="keydownevent"
                       />
               </v-card-text>
               <v-card-actions>
                   <v-row justify="center">
                       <v-btn class="dialog_cancel_btn" rounded large @click="textinput_dialog = false">닫기</v-btn>
                   </v-row>
               </v-card-actions>
            </v-card>
       </v-dialog>

        <!-- 이미지 크롭 팝업 -->
        <v-row align="center"  justify="center" >
        <v-dialog persistent scrollable overlay-color="#00000040"
            :fullscreen="false"
            v-model="crop_dialog" max-width="500px">

            <v-card class="pa-0 ma-0">

            <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
                <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span style="color:white">이미지 편집</span>
                </v-col>
            </v-row>
            </v-card-title>

            <v-card-text class="ma-0 pl-5 pr-5 pt-3">

                <v-row align="center" justify="center" dense>
                <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <div style="width:100%;height:500px;">
                    <vueCropper
                    ref="cropper_land"
                    :img="imgSrc"
                    :outputType="option.outputType"
                    fixed="true"
                    :fixedNumber="option.fixedNumber"
                    :full="option.full"
                    :canMove="option.canMove"
                    :canMoveBox="option.canMoveBox"
                    :fixedBox="option.fixedBox"
                    :original="option.original"
                    :autoCrop="option.autoCrop"
                    :autoCropWidth="option.autoCropWidth"
                    :autoCropHeight="option.autoCropHeight"
                    :centerBox="option.centerBox"
                    :high="option.high"
                    :infoTrue="option.infoTrue"
                    :maxImgSize="option.maxImgSize"
                    :enlarge="option.enlarge"
                    :mode="option.mode"
                    :limitMinSize="option.limitMinSize"
                    />
                </div>
                </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="mt-1 mb-1">
                <v-row align="center" justify="center">
                <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                    <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">저장</v-btn>
                    </v-col>
                    </v-row>
                </v-col>
                </v-row>
            </v-card-actions>

            </v-card>
        </v-dialog>
        </v-row>     


        <!-- 이미지 스위처 설정 -->
        <v-row justify="start" align="center" dense class="mt-2 pt-0">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">전환 시간</label>
            <input 
                v-model="contentStore.select_component.model.imgswitcher_time" 
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:40px;text-align:left;font-size:12px">Sec</label>
        </v-row>

        <v-row justify="center" align="center" class="mt-1" dense>
            <v-col cols="12" class="ma-0">
                <label style="font-size:12px">전환 애니매이션</label>
            </v-col>
            <v-col cols="12" class="pa-0">
                <select 
                    v-model="contentStore.select_component.model.imgswitcher_ani"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in switcher_anilist" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>


        <v-row justify="center" align="center" class="mt-1 pa-0" dense>
            <v-col cols="9">
                <div style="font-size:12px">이미지 리스트(밸유맵)</div>
            </v-col>
            <v-col cols="3" align="center">
                <v-icon @click="selectchoice" color="black" icon="mdi-plus" size="x-small"></v-icon>
            </v-col>
        </v-row>
        <v-divider/>

        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense>
          <draggable
            :list="contentStore.select_component.model.imgswitcher_list"
            class="pa-0 ma-0"
            ghost-class="ghost" 
            >
            <template #item="{ element, index }">
                <tr>
                    <template v-if="contentStore.select_component.model.api_use">
                        <td class="tbline" style="vertical-align: middle;text-align:center"><v-icon small>mdi-drag</v-icon></td>
                        <td colspan="3" class="text-left tbline" style="font-size:12px;vertical-align: middle;" >
                            <div style="width:150px;max-width:150px;overflow-wrap: break-word;">{{element}}</div>
                        </td>
                        <td class="pa-0 tbline" style="vertical-align: middle;">
                            <v-icon @click="edittext(index)" color="black" icon="mdi-pencil" size="x-small"></v-icon>
                            <v-icon @click="deletetext(element)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                        </td>
                    </template>
                    <template v-else>
                        <td class="tbline" style="vertical-align: middle;text-align:center"><v-icon small>mdi-drag</v-icon></td>
                        <td class="tbline" style="width:50px"></td>
                        <td class="tbline" style="vertical-align: middle;" >
                            <v-img  width="50" style="hegiht:auto" :src="element" class="image"/>
                        </td>
                        <td class="tbline" style="width:50px"></td>
                        <td class="pa-0 tbline" style="vertical-align: middle;">
                            <v-icon @click="deleteimg(element)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                        </td>
                    </template>
                </tr>
            </template>
          </draggable>    
        </v-row>
        


    </v-container>
    </template>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Component_Model from '@/nextts/model/Component_Model'

// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import draggable from 'vuedraggable' //https://github.com/SortableJS/vue.draggable.next

import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";

export default {

  components:{
    VueCropper, draggable
  },

  setup(){
    let cropper_land = ref(null);
    const contentStore = useContentStore();
    return {cropper_land, contentStore};
  },

  methods:{

    selectchoice(){

        if(this.contentStore.select_component.model.api_use){
            //api
            this.addtextlist();
        }else{
            this.fileSelect();
        }

    },

    //텍스트 수정
    edittext(pos){
        this.imgswitcher_pos  = pos;
        this.textinput_dialog  = true;
    },

    //텍스트 삭제
    deletetext(am){
        const idx = this.contentStore.select_component.model.imgswitcher_list.indexOf(am);
        this.contentStore.select_component.model.imgswitcher_list.splice(idx, 1);
    },    

    //space바를 입력하기 위해서
    keydownevent(ev){
        ev.stopPropagation();
        //this.updateRender();
    },

    //텍스트 추가
    addtextlist(){
        this.contentStore.select_component.model.imgswitcher_list.push("내용을 입력하세요");
        this.imgswitcher_pos = this.contentStore.select_component.model.imgswitcher_list.length-1;
        this.textinput_dialog  = true;
    },

    save_text(){
        this.textinput_dialog  = false;
    },

    //텍스트 삭제
    deleteimg(am){
        const idx = this.contentStore.select_component.model.imgswitcher_list.indexOf(am);
        this.contentStore.select_component.model.imgswitcher_list.splice(idx, 1);
    },    

    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    clearFile(){
        //this.previewImg = "";
        this.uploadFile = null;
        this.contentStore.select_component.model.image = null;
    },

    fileSelect(){

        const changefunction = this.changefile;
        var input    = document.createElement("input");
        input.style  = "display:none";
        input.name   = "imageinputcamera";
        input.type   = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){

            //아이폰, 아이패드, 맥 => input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
            document.body.appendChild(input);
            input.onchange = function (event) {
                //추가된 input 제거
                document.body.removeChild(input);
                changefunction(event.target.files[0]);
            };

        }else{
            input.onchange = function (event) {
                changefunction(event.target.files[0]);
            };
        }
        input.click();
    },    

    //이미지 선택시
    changefile(file){

        if(file.size/1024/1024 > 10){
            alert("10메가 이하 파일만 사용하세요.");
            this.uploadFile = [];
            return;
        }

        //이미지 크롭 팝업 띄우기
        this.option.autoCropWidth  = this.contentStore.select_component.model.width;  
        this.option.autoCropHeight = this.contentStore.select_component.model.height;
        this.option.fixedNumber = [this.contentStore.select_component.model.width, this.contentStore.select_component.model.height];

        const reader = new FileReader();
        reader.onload = (event)=>{
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

    },


    //이미지 크롭시
    cropimg(){

        //이미지 크롭 
        this.cropper_land.getCropBlob(blob=>{

            this.crop_dialog = false;
            this.cropper_land.getCropData(async data=>{
  
                this.uploadFile = blob;  //업로드용 blob 데이터
                this.contentStore.select_component.model.imgswitcher_list.push(data);
                
                //썸네일 생성
                /*
                var image     = await this.loadImage(data); //이미지 사이즈 알 수 있음.
                const canvas  = document.createElement("canvas");
                canvas.width  = 50;
                canvas.height = 50;
                canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

                //this.previewThumImg1 = canvas.toDataURL("image/jpeg");  //미리보기 데이터
                //this.uploadFileThum1 = await this.dataURItoBlob(this.previewThumImg1);     //썸네일 blob데이터
                //this.uploadFileThum = this.dataURItoBlob(canvas.toDataURL("image/jpeg"));     //썸네일 blob데이터
                */
               
            });

        });
    },    

    //지정한 data를 넣은 이미지를 리턴한다.
    loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
            myImage.onload = () => resolve(myImage)
        })
    },

      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

  },

  //프로퍼티 선언
  props:{
  },  

  data(){
    return{
        textinput_dialog:false,
        imgswitcher_pos:0,
        switcher_anilist:[
                {name:"FADE",   value:0}
            ,   {name:"LEFT",   value:1}
            ,   {name:"RIGHT",  value:2}
            ,   {name:"TOP",    value:3}
            ,   {name:"DOWN",   value:4}
        ],

        crop_dialog:false,
        imgSrc:'',

        //previewImg:"",
        uploadFile : [],
        uploadFileThum : [],


        //신규 크롭 파라메터
        option: {
            img: "",
            size: 1,
            full: true,
            outputType: "jpg",
            canMove: true,
            fixed: false,          //크롭박스 비율 고정
            fixedNumber: [1,1],  //크롭박스 비율
            fixedBox: false,      //true면 크롭박스 크기 조절 안됨
            original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
            canMoveBox: true,
            autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
            autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
            autoCropHeight:300,   //크롭박스의 세로 크기 
            centerBox: true,
            high: false,
            cropData: {},
                    enlarge: 1,
            mode: 'contain',
            maxImgSize: 2000,
            limitMinSize: [100, 100]
        },            


    }
  },

  mounted(){
    
  },

  
}
</script>