<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 0">
    <v-container fluid class="ma-0 pt-0">
  
        <v-row class="pt-3 ma-0" align="center" justify="center" dense>
            <v-col cols="6" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.texthori_align"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="change_textalign"
                    >
                    <v-btn value="left" width="32" icon="mdi-format-align-left" size="x-small"></v-btn>
                    <v-btn value="center" width="32" icon="mdi-format-align-center" size="x-small"></v-btn>
                    <v-btn value="right" width="32px" icon="mdi-format-align-right" size="x-small"></v-btn>
                </v-btn-toggle>                
            </v-col>

            <v-col cols="6" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.textverti_align"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="change_textalign"
                    >
                    <v-btn value="start" icon="mdi-format-align-top" size="x-small"></v-btn>
                    <v-btn value="center" icon="mdi-format-align-middle" size="x-small"></v-btn>
                    <v-btn value="end" icon="mdi-format-align-bottom" size="x-small"></v-btn>
                </v-btn-toggle>                
            </v-col>
        </v-row>

        <v-row justify="center" align="center" dense class="mt-0 pt-0">
            <v-col cols="3">
                
                <dialogftcolor dense title="Font Color" ref="bgcolor" 
                    :color="this.contentStore.select_component.model.textcolor"
                    />

            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-bold" flat size="small"
                    :color="this.contentStore.select_component.model.textBold == 0 ? 'white' : 'purple'"
                    @click="bold_handler"
                    >
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-italic" flat size="small"
                    :color="this.contentStore.select_component.model.textItalic == 0 ? 'white' : 'purple'"
                    @click="Italic_handler"
                    >
                </v-btn>
            </v-col>

            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-underline" flat size="small"
                    :color="this.contentStore.select_component.model.textUnderline == 0 ? 'white' : 'purple'"
                    @click="underline_handler"
                    >
                </v-btn>
            </v-col>

            <!-- 밑줄 사용안함
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-underline" flat size="small"
                    :color="this.contentStore.select_component.model.textUnderline == 0 ? 'white' : 'purple'"
                    @click="underline_handler"
                    >
                </v-btn>
            </v-col>
            -->
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
            
            <dialogfont :comname="this.contentStore.select_component.model.font_name" />
            <!-- 
            <comfont 
                :comname="this.contentStore.select_component.model.textfont"
                @cancelcallback="comfontcancel" @callback="comfontcb"
                />
            -->

            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-3 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="폰트 사이즈" property="textsize" :min="10" :max="300" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="글자 간격" property="letterSpacing" :min="-100" :max="100" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="줄 간격" property="lineHeight" :min="0" :max="500" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    textchange(){
        this.contentStore.select_component.updateInnerText();
    },

    change_textalign(){
        this.updateRender();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    colorcallback(){
        this.updateRender();
    }, 
    
    underline_handler(){
        if(this.contentStore.select_component.model.textUnderline == 0){
            this.contentStore.select_component.model.textUnderline = 1;
        }else{
            this.contentStore.select_component.model.textUnderline = 0;
        }
        this.updateRender();
    },

    bold_handler(){
        if(this.contentStore.select_component.model.textBold == 0){
            this.contentStore.select_component.model.textBold = 1;
        }else{
            this.contentStore.select_component.model.textBold = 0;
        }
        this.updateRender();
    },

    Italic_handler(){
        if(this.contentStore.select_component.model.textItalic == 0){
            this.contentStore.select_component.model.textItalic = 1;
        }else{
            this.contentStore.select_component.model.textItalic = 0;
        }
        this.updateRender();
    },


      //폰트선택 취소
      comfontcancel(){

      },

  },

  data(){
    return{

        horialign:[
                {name:"left",       value:"left"}
            ,   {name:"center",     value:"center"}
            ,   {name:"right",      value:"right"}
        ],

        vertialign:[
                {name:"top",        value:"top"}
            ,   {name:"center",     value:"center"}
            ,   {name:"end",        value:"end"}
        ],

        colortype:[
               {name:"Color",     value:"color"}
            ,  {name:"Gradient",  value:"gradient"}
            ,  {name:"Image",     value:"image"}
            
        ],

    }
  },

  mounted(){
    
  },

  
}
</script>