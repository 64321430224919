import Component_Interface from '@/nextts/edit/Component_Interface'
import Component_Model from  '@/nextts/model/Component_Model';


//컴포넌트 안에 컴포넌트를 넣는 이중 그룹 테스트용 객체
//아직 개발은 안하고 파일만 분리한 상태임.

//기본 데이터 속성
class EditBasic_ElementGroup extends HTMLElement implements Component_Interface{
    
    //Component_Interface 구현
    model:Component_Model

    constructor(cm:Component_Model){
        super();
        this.model = cm;

        //좌표체계
        this.style.position = "absolute";

        //movealbe 및 selecto 타겟팅
        this.classList.add("drag");

        //그룹 복원
        if(this.model.isgroup && this.model.groupid.length > 0){
            this.classList.add(this.model.groupid);
        }
        
        //이벤트 추가
        this.ondblclick  = this.event_doubleclick;
        this.onkeyup     = this.focusoutevent;

        //드래그 앤 드랍 이벤트
        this.draggable   = true;
        this.ondrag      = this.ondraghandler;

        this.updateRender();
        this.updateTransElementAll();
    }

    ondraghandler(){
        console.log("ondraghandler....");
    }



    //Component_Interface - 컴포넌트 외형 그리기
    updateRender(){

        //console.log("================ updateRender =============== ");

        //배경타입 
        if(this.model.bgtype == 0){
            //console.log("update render : bg type color");
            //color
            this.style.backgroundColor  = this.model.backgroundColor;
            this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.model.bgtype == 1){
            //console.log("update render : bg type image");
            //image
            this.style.backgroundColor  = "#00000000";
            this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.style.backgroundImage  = "url('"+this.model.backgroundImg+"')"
            this.style.backgroundRepeat = "no-repeat";
        }

        //컴포넌트 타입
        if(this.model.component_type == Component_Model.COMPONENT_TEXT){
            //console.log("update render : component type text");

            this.innerText          = this.model.text;
            
            this.style.fontFamily   = this.model.textfont;
            this.style.fontSize     = this.model.textsize+'px';
            this.style.color        = this.model.textcolor;
            this.style.textAlign    = this.model.texthori_align;    //left, center, right
            
            //텍스트 세로 정렬
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.justifyContent   = "center";     //top, center, end
            this.style.lineHeight       = "120%";

            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "true";

            


        }else if(this.model.component_type == Component_Model.COMPONENT_IMAGE){
            //console.log("update render : component type image : " + this.model.image);

            this.style.backgroundColor  = "#ffffff";
            this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            if(this.model.image != null && this.model.image != undefined && this.model.image.length > 0){
                if(this.model.image.toLowerCase().startsWith("http")){
                    this.style.backgroundImage  = "url('"+this.model.image+"')"
                }else{
                    this.style.backgroundImage  = this.model.image;
                }
            }
            this.style.backgroundRepeat = "no-repeat";

            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "false";
            this.innerText = "";

        }

    }

    updateTransElement(){
        this.updateRender();
        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;
    }

    updateTransElementAll(){
        this.updateRender();

        //this.style.cssText += "width:"+`${this.model.width}px`+"; height:"+`${this.model.height}px`+"; transform: translateX("+`${this.model.transx}px`+") translateY("+`${this.model.transy}px`+") rotate("+`${this.model.rotate}deg`+")";
        
        this.style.width  = `${this.model.width}px`;
        this.style.height = `${this.model.height}px`;
        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

    }


    /*******************************************************************************************************/
    //텍스트 편집 메서드
    /*******************************************************************************************************/
    //컴포넌트 더블 클릭시
    event_doubleclick(e:MouseEvent){
        //텍스트의 경우에는 포커스를 넣어서 수정하도록 한다.
        if(this.model.component_type == Component_Model.COMPONENT_TEXT){
            console.log("double click...text");
            e.preventDefault();
            this.style.pointerEvents = "auto";
            this.focus();
            
        }
    }
    //편집시 더블클릭으로 포커스를 넣은 후 키입력으로 텍스트 내용 변경
    focusoutevent(){
        if(this.model.component_type == Component_Model.COMPONENT_TEXT){
            this.model.text = this.innerText;
        }
    }
    //편집창에서 텍스트 입력시 innerText에 반영해주기
    updateInnerText(){
        if(this.model.component_type == Component_Model.COMPONENT_TEXT){
            this.innerText = this.model.text;
        }
    }
    

}

export default EditBasic_ElementGroup
customElements.define('editbasic-elementgroup', EditBasic_ElementGroup);