

//기본 애니매이션 속성
class AniProperty_Model {

    propname:string    = "";
    propvalue:string   = "";
    proptype:string    = "string";

    propstep:number    = 1;
    propmin:number     = 0;
    propmax:number     = 100;

    constructor(){
        
    }

    //JSON 스트링을 객체로 변환
    setStringValue(params:string):void{
        const temp      = JSON.parse(JSON.stringify(params));
        this.propname   = temp.propname;
        this.propvalue  = temp.propvalue;
        this.proptype   = temp.proptype;
        this.propstep   = temp.propstep;
        this.propmin    = temp.propmin;
        this.propmax    = temp.propmax;

    }    

}

export default AniProperty_Model
