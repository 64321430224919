<!-- 업체 선택용 공통 팝업 -->
<template>
<v-row>
    <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="dialog_com" max-width="600px" 
        >
        <v-card class="dialog_round pa-2">
            <v-row dense justify="center" align="center" class="mt-3">
                <v-col align="start" cols="5">
                    <label class="ml-5">컨텐츠 선택</label>
                </v-col>
                <v-col cols="5">
                    <v-select 
                    label="카테고리"
                    v-model="SEARCH_CATEGORY_IDX_COM" :items="categoryList_com" 
                    item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
                    outlined color="#808080" hide-details 
                    density="compact" variant="outlined"
                    @update:modelValue="loadpage_com(1)"
                    />
                </v-col>
            </v-row>
            <v-row dense justify="center">
                <v-col cols="11">
                <div style="background:#000000;height:1px;" />
                </v-col>
            </v-row>
        <v-card-text>

        <v-container fluid>

            <v-table style="min-height:400px">
                <thead>
                    <tr height="30">
                        <th style="height:30px" class="text-left">Thum</th>
                        <th style="height:30px" class="text-left">Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in itemList_com" :key="item.CON_IDX" @click="select_func(item)">

                        <td>            
                            <template v-if="item.CON_IDX != undefined">
                            <v-row justify="center">
                                <v-col align="center" cols="12">
                                  <ThumScreen
                                    :id="item.CON_IDX"
                                    :screen_model="item.screen_model"
                                    :scalewidth="200" 
                                    />
                                </v-col>
                            </v-row>
                            </template>
                            <!-- 
                            <v-img
                                width="200"
                                :src="item.CON_IMG"
                                :lazy-src="item.CON_IMG"
                                cover
                                class="bg-grey-lighten-2"
                                />
                            -->
                        </td>
                        <td style="min-width:200px">{{ item.CON_NAME }}</td>
                    </tr>
                </tbody>
            </v-table>

            <!-- 하단검색 -->
            <searchbar ref="searchbar_com" @searchcallback="search_run_com"/>
            
            <!-- 하단페이징영역 -->
            <pagingbar ref="paging_com" :page="page_com" @loadpage="movepage_com"/>  

        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-row justify="center">
        <v-btn class="dialog_cancel_btn" rounded large @click="close_com">닫기</v-btn>
        </v-row>
        </v-card-actions>
    </v-card>
    </v-dialog>     
</v-row>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import Screen_Model from '@/nextts/model/Screen_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';
import wait from 'waait';

export default {

    components:{
        ThumScreen,
    },
        
    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{
        comname: String,
    },
    
    
    watch:{
        dialog_com: async function(v1){
            //console.log("v1 : " + v1 + " / v2 : " + v2);
            if(v1){
                //팝업이 뜬 경우
                await wait(250);
                this.loadanicategory_com();
            }
        },
    },
    

    setup(){
        console.log("setup com accnt");
        const searchbar_com = ref(null);
        const paging_com    = ref(null);
        let contentStore   = useContentStore();
        return {contentStore, searchbar_com, paging_com};
    },

    data: ()=> ({
        dialog_com : false,
        load_flag : false,
        sort_name_com: 'REG_DATE',
        sort_type_com: true,    //true : DESC, false: ASC
        
        page_com : 0,
        pagerow_com: 20,
        maxpage_com : 0,       //전체 페이지 개수
        categoryList_com:[],
        SEARCH_CATEGORY_IDX_COM:null,
        itemList_com: [],
        search_keyword_com : '',
    }),

    mounted(){
        console.log("com accnt mount");
        this.page_com = 1;
    },


    methods: {

        update_dialog(){

            console.log("update dialog...");

        },
        
        searchbar_run(){
            console.log("searchbar run : " + this.search_keyword);
            this.$emit('searchcallback', this.search_keyword);
        },

        movepage_com(p){
            this.loadpage_com(p);
        },

        async loadanicategory_com(){
          console.log("loadanicategory...");
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.$http.post(this.$host+'/CategoryTypeList',{
                search_keyword: ''
              , CATEGORY_TYPE: 6
              , sort_name: "CATEGORY_SORT"
              , sort_type: "ASC"
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                const list = result.data.resultData;
                this.categoryList_com.splice(0, this.categoryList_com.length);   //데이터 초기화
                this.categoryList_com.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
                list.forEach(element => {
                  this.categoryList_com.push(element); 
                });
                
                this.SEARCH_CATEGORY_IDX_COM = this.categoryList_com[0].CATEGORY_IDX;
                this.loadpage_com(1);

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      //검색
      search_run_com(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword_com = searchkeyword;
        }else{
          this.search_keyword_com = '';
        }
        this.loadpage_com(1);
      },      

        showpopup_com(){
            this.dialog_com = true;
            this.load_flag = false;
        },

        //다이얼로그 닫기
        close_com () {
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },

        //선택취소
        clear_com(){
            this.$emit('cancelcallback', '')
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },

        //선택
        async select_func(select_item) {

            //컨텐츠 상세 읽어서 리턴해야하나.
            let token;
            try{
                token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
                token = this.$storage.getStorageSync("FIRETOKEN");
            }
            this.$http.post(this.$host+'/ContentDetail',{
            CON_IDX: select_item.CON_IDX
            },{headers: { firetoken:token}})
            .then(async (result)=>{
            if(result.data.resultCode == 0){
                const item = result.data.resultData[0];
                this.$emit('callback', item);
                //콜백 호출 후 닫아야함.
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';

            }else if(result.data.resultCode == 2){
                //콜백 호출 후 닫아야함.
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';

                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
            }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                //콜백 호출 후 닫아야함.
                this.dialog_com = false
                this.select_com = [];
                this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                this.search_keyword_com = '';
            });
            
        },              

        //현재 편집중인 컨텐츠와 동일 사이즈 컨텐츠만 로드한다.
        async loadpage_com(p){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.page_com = p;
            this.paging_com.setpage(this.page_com);
            this.$http.post(this.$host+'/ContentList',{
                  search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                , sort_name:this.sort_name_com
                , sort_type: this.sort_type_com == true ? "DESC" : "ASC" 
                , page: this.page_com
                , CATEGORY_IDX: this.SEARCH_CATEGORY_IDX_COM
                , CON_WIDTH: this.contentStore.content_model.width
                , CON_HEIGHT: this.contentStore.content_model.height
                , pagerow: this.pagerow_com
            },{headers: { firetoken:token}})
            .then((result)=>{
                
                if(result.data.resultCode == 0){

                    this.totalcnt_com = result.data.totalcnt;
                    this.paging_com.makepaging(this.totalcnt_com, this.pagerow_com);
                    const list = result.data.resultData;
                    this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                    list.forEach(element => {
                        let screen_model        = new Screen_Model();
                        screen_model.setStringValue(element.THUM_JSON);
                        screen_model.cacheFont();
                        element.screen_model = screen_model;
                        this.itemList_com.push(element); 
                    });

                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                
            });

        },        


    }

}
</script>

<style scoped>

</style>