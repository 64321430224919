<template>
    <template v-if="contentStore.select_component != null && (contentStore.select_component.model.component_type == 1 || contentStore.select_component.model.component_type == 3 
    || contentStore.select_component.model.component_type == 4 || contentStore.select_component.model.component_type == 5 )">
    <v-container fluid class="ma-0 pt-0">
    
        <!-- 사용선택 -->
        <v-row align="center" dense>
            <v-col cols="12">
                <v-switch label="마스크 사용" color="red" hide-details v-model="this.contentStore.select_component.model.mask_use"
                :onchange="changecallback"    
                />
            </v-col>
        </v-row>


        <v-row justify="center" align="center" dense class="mt-0 pt-0" v-if="this.contentStore.select_component.model.mask_use">
            <template v-if="this.contentStore.select_component.model.mask_image.length > 0">
                <v-row dense  justify="center">
                <div style="position:relative">
                    <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:150px;background-color:#00000080">
                        <v-icon style="width:50px;height:40px;color:white" @click="fileSelect()">mdi-pencil</v-icon>
                        <!-- <v-icon style="width:50px;height:40px;color:white" @click="clearFile()">mdi-window-close</v-icon> -->
                    </div>
                    <img  width="150" style="hegiht:auto" :src="this.contentStore.select_component.model.mask_image" class="image">
                </div>
                </v-row>
            </template>
            <template v-else>
                <v-row dense class="image-border2" align="center" justify="center">
                    <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect()">mdi-image</v-icon>
                </v-row>
            </template>
        </v-row>


        <v-row justify="center" align="center" dense class="mt-0 pt-0" v-if="this.contentStore.select_component.model.mask_use">
            <v-col cols="6">
                <dialogmask dense/>
            </v-col>
        </v-row>        

        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.mask_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="마스크 가로 위치(%)" property="mask_left" :min="-200" :max="200" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.mask_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="마스크 세로 위치(%)" property="mask_top" :min="-200" :max="200" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.mask_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="마스크 넓이(%)" property="mask_width" :min="1" :max="300" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.mask_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="마스크 높이(%)" property="mask_height" :min="1" :max="300" :step="1" />
            </v-col>
        </v-row>

        <v-row align="center" class="mt-5" v-if="this.contentStore.select_component.model.mask_use">
            <label style="width:70px;text-align:center;font-size:12px;margin-left:10px;margin-right:0px">Mask Repeat</label>
            <input type="checkbox" v-model="contentStore.select_component.model.mask_repeat"
                @change="changecallback"    
                />
        </v-row>        

        <v-row dense class="mt-3"> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    changecallback(){
        this.updateRender();
    }, 
    
    load_masksample(){
        
        this.contentStore.select_component.model.mask_image = "https://storage.googleapis.com/necsolution-59f61.appspot.com/imgmask/circle.png";

    },



    fileSelect(){

        const changefunction = this.changefile;
        var input    = document.createElement("input");
        input.style  = "display:none";
        input.name   = "imageinputcamera";
        input.type   = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
            //아이폰, 아이패드, 맥 => input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
            document.body.appendChild(input);
            input.onchange = function (event) {
                //추가된 input 제거
                document.body.removeChild(input);
                changefunction(event.target.files[0]);
            };

        }else{
            input.onchange = function (event) {
                changefunction(event.target.files[0]);
            };
        }
        input.click();
    },        


    //이미지 선택시
    changefile(file){

        if(file.size/1024/1024 > 10){
            alert("10메가 이하 파일만 사용하세요.");
            return;
        }

        //이미지 크롭 팝업 띄우기
        const reader = new FileReader();
        reader.onload = (event)=>{
            //this.crop_dialog = true;
            //this.imgSrc = event.target.result;  //이게 blob데이터
            this.contentStore.select_component.model.mask_image = event.target.result;
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

    },    

  },

  data(){
    return{
        uploadFile : [],
        uploadFileThum : [],
        imgSrc:'',
    }
  },

  mounted(){
    
  },

  
}
</script>

<style scoped>


</style>