
<!-- 
커스텀 객체를 만들어서 타입스크립트와 자바스크립트를 혼용한 샘플 (moveable과 selecto때문에 타입스크립트 여기서 못씀)
인피니티 뷰어 내부에 여러개의 스크린을 추가해서 스크린 자체의 위치를 이동할 수 있는 버전 (피그마처럼)
-->
<template>
  <v-container fluid class="pa-0 ma-0">
    
    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" :msg="progress_msg" :progress="progress_value" />    

    <!-- 컨텐츠 선택 팝업 -->
    <comcontent ref="comcontent" @callback="select_content" />

    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">New Content</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row dense align="center" class="text-center" justify="center">
                  <label>타이틀을 입력하세요</label>
                </v-row>
                <v-row dense align="center" justify="center"  style="margin-top:10px">
                  <v-text-field v-model="content_name" variant="outlined" density="compact" />
                </v-row>
                <v-row dense align="center" class="text-center" justify="center">
                  <label>사이즈를 입력하세요</label>
                </v-row>
                <v-row dense align="center" justify="center"  style="margin-top:10px">
                  <label style="font-size:12px;margin-right:5px">WIDTH</label>
                  <input 
                      v-model="size_width" 
                      style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                      />
                  <label style="font-size:12px;margin-right:5px;margin-left:5px">HEIGHT</label>
                  <input 
                      v-model="size_height" 
                      style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                      />
                </v-row>
                <v-row dense align="center" justify="center" style="margin-top:20px">
                  <label>컨텐츠 타입을 선택하세요</label>
                </v-row>
                <v-row dense align="center" justify="center">
                  <v-btn-toggle
                    v-model="content_type"
                    rounded="0"
                    color="deep-purple-accent-3"
                    group
                  >
                    <v-btn size="small" value="content">
                      컨텐츠
                    </v-btn>
                    <v-btn size="small" value="asset">
                      Asset
                    </v-btn>
                    <v-btn size="small" value="popup">
                      Popup
                    </v-btn>
                  </v-btn-toggle>                  
                </v-row>
            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="dialog = false">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="new_content">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 전체 재생 -->
    <complay ref="complay"/>

    <!-- 커스텀 탑바 -->
    <v-app-bar :elevation="1  " style="position:fixed;top:0px;left:0px;">
      <v-app-bar-nav-icon style="color:#808080" @click.stop="activateDrawer"></v-app-bar-nav-icon>
      <div class="info_text">Edit Type : </div>
      <label style="width:50px;text-align:center;font-size:12px;margin-right:5px;font-weight:bold">{{ content_type == 'content' ? 'Content' : content_type == 'Asset' ? 'Asset' : 'Popup' }}</label>
      <div class="info_text ml-1">Size :&nbsp;</div>
      <input 
          v-model="contentStore.content_model.width" :disabled="contentStore.content_model.content_mode == 1"
          style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
          />
      <label style="text-align:center;font-size:12px;margin-left:5px;margin-right:5px">x</label>
      <input 
          v-model="contentStore.content_model.height" :disabled="contentStore.content_model.content_mode == 1"
          style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
          />

      <div class="info_text ml-3">Name :&nbsp;</div>
      <input 
          v-model="contentStore.content_model.name" :disabled="contentStore.content_model.content_mode == 0"
          style="width:200px;font-size:12px;border: solid 1px gray;border-radius:3px;margin-left:1px;padding-left:10px"
          />

      
      <!-- top menu -->      
      <div class="d-flex align-center justify-center">
        <v-btn class="ml-3" prepend-icon="mdi-content-save" variant="outlined" size="small" @click="save_content">SAVE</v-btn>
        <v-btn class="ml-3" prepend-icon="mdi-sticker-plus" variant="outlined" size="small" @click="load_content">IMPORT</v-btn>
        <v-btn class="ml-3" prepend-icon="mdi-plus" variant="outlined" size="small" @click="addcomponent">ADD</v-btn>
        <v-btn class="ml-3" prepend-icon="mdi-play" variant="flat" size="small" elevation="0" @click="play_screen" color="black">PLAY SCREEN</v-btn>
        <v-btn class="ml-3" prepend-icon="mdi-animation-play" variant="flat" size="small" elevation="0" @click="play_all" color="black">PLAY ALL</v-btn>
        <v-btn v-if="content_type == 'asset'" density="compact" elevation="0" icon="mdi-crop" @click="crop"/>
        <v-btn v-if="this.$storage.getStorageSync('GRADE') > 90"
          class="ml-3" size="small" elevation="0" prepend-icon="mdi-download" variant="outlined" @click="download">DOWNLOAD</v-btn>
      </div>
      <v-spacer/>
    </v-app-bar>
        
    <div class="d-flex flex-row align-stretch" >

      <!-- left panel -->
      <div class="paneloutline scrollBar flex-grow-0 flex-shrink-0" 
         style="min-width:355px;margin-top:25px;background:white;"
         :style="`height:${windowHeight-25}px`"
         >
        <EditLeft_Panel ref="editleft_panel" />
      </div>
       
      <!-- center -->
      <div class="d-flex flex-column flex-grow-1" ref="center_panel">
        <EditWindow 
          ref="editwindow"
          style="position:absolute;"
          :style="`width:${windowWidth}px;height: ${windowHeight}px`"
          /> 
      </div>      

      <!-- right panel -->
      <div class="paneloutline right_panel_style flex-grow-0 flex-shrink-0" 
          style="margin-top:25px;background:white;overflow:auto"
          :style="`height:${windowHeight-25}px`"
          >
          <EditRight_Panel ref="editright_panel" />
      </div>
      
    </div>



  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";

//커스텀 컴포넌트
import EditWindow from '@/components/EditWindow.vue'
import EditRight_Panel from '@/nextvue/infopanel/EditRight_Panel.vue'
import EditLeft_Panel from '@/nextvue/infopanel/EditLeft_Panel.vue'

// eslint-disable-next-line no-unused-vars
import { addDoc, getDoc, doc, setDoc, collection, updateDoc, getDocs } from "firebase/firestore";
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model'
import { reactive, ref } from '@vue/reactivity';
import Component_Model from '@/nextts/model/Component_Model';

import { ref as fref, uploadBytesResumable, deleteObject } from "firebase/storage";
import wait from 'waait';
import Content_Model from '@/nextts/model/Content_Model';
//import { component } from 'v-viewer';
//import html2canvas from 'html2canvas'
import FontFaceObserver from 'fontfaceobserver';
// eslint-disable-next-line no-unused-vars
import Play_Background from '@/nextts/play/Play_Background';
// eslint-disable-next-line no-unused-vars
import download from 'downloadjs';

import { v4 as uuidv4 } from 'uuid';
export default {

  components:{
    // eslint-disable-next-line vue/no-unused-components
    EditWindow, EditRight_Panel, EditLeft_Panel
  },

  setup(){
    let complay          = ref(null);
    let comcontent       = ref(null);
    let editwindow       = ref(null);
    let editleft_panel   = ref(null);
    let center_panel   = ref(null);
    let editright_panel  = ref(null);

    let aniplay_model  = reactive(new AnimationPlay_Model());
    let windowHeight   = ref(window.innerHeight-36);

    let contentStore   = useContentStore();

    return{
      complay, comcontent, editwindow, aniplay_model, windowHeight, editleft_panel, editright_panel, center_panel, contentStore
    }

  },



  data:()=>({

    content_idx:null,
    progress_dialog:false,
    progress_value:0,
    progress_msg:'Please Wait...',
    
    isLoading:false,

    windowWidth:window.innerWidth-614,
    content_model:null,
    dialog:false,
    save_dialog:false,
    content_type:"content",
    size_width:1920,
    size_height:1080,

    content_name:"",
    content_mode:1,    //0:관리자, 1:사용자

    drawer:false

  }),


  mounted(){
    //resize 이벤트 설정
    console.log("type : "+this.$route.query.type);
    console.log("idx: " + this.$route.query.idx);
    console.log("mode: " + this.$route.query.mode);
    
    this.$emitter.emit('hidetitle', true);  //타이틀 감추기
    this.$emitter.on('drawerChanged', this.drawerChanged);

    //관리자모드, 사용자모드 구분
    if(this.$route.query.mode){
      this.content_mode = this.$route.query.mode;
    }

    if(this.$route.query.type){
      if(this.$route.query.type == 0){
        this.content_type = "content";
      }else if(this.$route.query.type == 1){
        this.content_type = "asset";
      }else if(this.$route.query.type == 2){
        this.content_type = "popup";
      }
    }

    if(this.$route.query.type && this.$route.query.idx){
      //이전 화면에서 컨텐츠나 에셋을 셋팅해서 들어옴. (수정 진입)
      this.content_idx = this.$route.query.idx;
      if(this.content_mode == 0){
        //관리자 모드 
        if(this.$route.query.type == 0){
          //컨텐츠
          this.loadAdminContent(this.content_idx); 
        }else if(this.$route.query.type == 1){
          //에셋
          this.loadAdminAsset(this.content_idx);
        }else if(this.$route.query.type == 2){
          //팝업
          this.loadAdminPopup(this.content_idx); 
        }
      }else{
        //사용자 모드 
        this.loadUserContent(this.content_idx);
      }

    }else if(this.$route.query.conas){     
      //추가 버튼 누르고 템플릿 선택해서 진입시
      this.content_idx = uuidv4();  //uuid로 신규 idx를 만듬.
      this.set_content();

    }else if(this.$route.query.type){
      //추가 버튼 눌러서 진입 (신규 컨텐츠 만들기)
      this.content_idx = uuidv4();  //uuid로 신규 idx를 만듬.
      this.contentStore.setContentModel(1920,1080);
      this.contentStore.createImgList();
      this.dialog = true;

    }else{
      //관리자 - 컨텐츠편집 진입 (이건 비정상루트, 삭제예정)
      this.contentStore.setContentModel(this.size_width, this.size_height);
      this.contentStore.createImgList();
    }

    addEventListener('resize', this.resizeevent);

    
    //파베 데이터 가져오기 - 컨텐츠 편집에 사용자 로드인 경우만
    if(this.content_type == "content" && this.content_mode == 1){
      this.getFBContent();
    }
    
  },

  beforeUnmount(){
    console.log("beforeUnmount EditContent");
    this.$emitter.emit('hidetitle', false);   //타이틀 보이기
    this.$emitter.off('drawerChanged');
    removeEventListener('resize', this.resizeevent);
    this.complay.destroyplay();

  },

  methods:{

    activateDrawer() {
      this.$emit('toggle-drawer');
    },

    //로그인된 또는 선택된 계정의 파베 실시간 페이지 데잍터 읽기
    async getFBContent(){
      const localid = this.$storage.getStorageSync("SEARCH_LOCALID");
      const parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      const docsRef = doc(this.$firestore, "company", String(parent_localid), "users", String(localid), "docs", "page");
      const docSnap = await getDoc(docsRef);
      if(docSnap.exists()){
          // 문서가 존재할 때 데이터 처리
          const datas = docSnap.data();
          for (const key in datas.datamap) {
            console.log("key : " + key);
            //이미 있는 키의 값을 삭제
            if (Content_Model.apiData.has(key)) {
                Content_Model.apiData.delete(key); 
            }
            //새로 값 지정
            Content_Model.apiData.set(key, datas.datamap[key]);

            //전체 카운트 ex) test_count의 0번째의 count에 test의 전체 카운트가 들어있음.
            Content_Model.apiData.set(`${key}_count`, [{'count':datas.datamap[key].length}]);
          }
      }
    },

    //현재 스크린 재생
    async play_screen(){
      
      //에디트 윈도우에서 직접 재생
      //this.$emitter.emit("clearSelect");
      //this.editwindow.play_screen();
     
      //팝업재생
      let select_pos = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.complay.dialog_com = true;
      await wait(250);
      this.complay.initplay(select_pos, false);

    },

    //전체 재생
    async play_all(){
      
      //this.$router.push("/ContentPlay");
      this.complay.dialog_com = true;
      await wait(250);
      this.complay.initplay(0, true);
      
    },

    set_content(){

      //기존 언두 히스토리 초기화
      this.contentStore.clearHistory();

      //스냅샷
      this.contentStore.saveSnapshot();
      
      //폰트 로드처리
      const screen_list = this.contentStore.content_model.screen_list;
      const fontFaces = [];
      for(const screen of screen_list){
        for(const cp of screen.component_list){

          if(cp.font_url != null && cp.font_url != undefined && cp.font_url.length > 0){
            let fonturl = "url('"+cp.font_url+"')";
            if(cp.font_format != null && cp.font_format != undefined && cp.font_format.length > 0){
              fonturl += " format('"+cp.font_format+"')";
            }
            const fontoption = {weight:cp.font_weight == null || cp.font_weight.length == 0 ? "normal" : cp.font_weight, style:"normal"};
            const fontface = new FontFace(cp.textfont, fonturl, fontoption);
            document.fonts.add(fontface);

            console.log("add fontFace : " + fontface.family);

            fontFaces.push(fontface);
          }

        }
      }


      console.log("load fontFaces 1 : " + fontFaces.length);
      for(const ff of fontFaces){
        console.log("load fontFaces 2 : " + ff.family);
      }
      


      if(fontFaces.length > 0){
        Promise.all(fontFaces.map(this.observeFontLoad))
        .then(() => {
            console.log('모든 폰트가 성공적으로 로드되었습니다.');
            this.contentStore.select_screen = this.contentStore.content_model.screen_list[0];
            this.editwindow.drawscreen();
        })
        .catch(error => {
            console.error('폰트 로드 중 오류가 발생했습니다:', error);
            this.contentStore.select_screen = this.contentStore.content_model.screen_list[0];
            this.editwindow.drawscreen();
        });
      }else{
        //로드할께 없으면
        this.contentStore.select_screen = this.contentStore.content_model.screen_list[0];
        this.editwindow.drawscreen();

      }      

    },

    //폰트로드 감지
    observeFontLoad(fontFace) {
      const observer = new FontFaceObserver(fontFace.family);
      return observer.load();
    },

    new_content(){

      if(this.content_name == null || this.content_name == undefined || this.content_name.length < 1){
        this.dialog = false;
        this.$alert.fire("타이틀을 입력하세요");
        return;
      }
      if(this.size_width == null || this.size_width == undefined || this.size_width.length < 1){
        this.dialog = false;
        this.$alert.fire("WIDTH를 입력하세요");
        return;
      }
      if(this.size_height == null || this.size_height == undefined || this.size_height.length < 1){
        this.dialog = false;
        this.$alert.fire("HEIGHT를 입력하세요");
        return;
      }

      if(this.size_width < 100 || this.size_width > 10000){
        this.dialog = false;
        this.$alert.fire("WIDTH는 100 ~ 10000 사이의 값을 입력하세요");
        return;
      }
      if(this.size_height < 100 || this.size_height > 10000){
        this.dialog = false;
        this.$alert.fire("HEIGHT는 100 ~ 10000 사이의 값을 입력하세요");
        return;
      }

      //신규 컨텐츠 사이즈 설정
      this.contentStore.setContentModel(this.size_width, this.size_height);
      this.contentStore.createImgList();
      if(this.content_type == "content"){
        this.contentStore.content_model.content_type = 0;
      }else if(this.content_type == "asset"){
        this.contentStore.content_model.content_type = 1;
      }else if(this.content_type == "popup"){
        this.contentStore.content_model.content_type = 2;
      }
      this.contentStore.content_model.name = this.content_name;
      this.contentStore.content_model.content_mode = this.content_mode;
      
      //기본으로 스크린 한개 추가
      this.editleft_panel.info_screen.addscreen();

      //선택한 스크린 선택하고 그리기
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[0];
      this.editwindow.drawscreen();

      this.dialog = false;
    },


    async save_content(){
      //await targetFileRef.putString(url, 'data_url');

      if(this.contentStore.content_model.name.length < 1){
          this.$alert.fire("이름을 입력하세요.");
          return;
      }

      if(this.contentStore.content_model.screen_list.length < 1){
          this.$alert.fire("컨텐츠를 추가해주세요.");
          return;
      }

      //썸네일 갱신
      //this.editleft_panel.click_screen();
      
      //폴더명 정의
      let folder = "";
      if(this.content_mode == 0){
        //관리자 모드
        if(this.content_type == "content"){
          folder = "content/" + this.content_idx;
        }else if(this.content_type == "asset"){
          folder = "asset/" + this.content_idx;
        }else if(this.content_type == "popup"){
          folder = "popup/" + this.content_idx;
        }
      }else{
        let localid = this.contentStore.content_model.localid;
        let parent_localid = this.contentStore.content_model.parent_localid;
        if(localid == null || localid == undefined){
          localid = this.$storage.getStorageSync("SEARCH_LOCALID");
          parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
        }
        //사용자 모드 - 사용자의 accnt_idx 지정.
        folder = "company/"+parent_localid+"/users/"+localid+"/"+this.content_idx;
      }
      
      
      console.log("save_content folder : " + folder);


      //저장 시작
      this.progress_dialog = true;

      //이미지 동기화 처리(신규, 수정, 삭제)
      const tempImgs = [];
      for(const screen of this.contentStore.content_model.screen_list){

        console.log("save_content check screen");

        //스크린 배경이 이미지면 처리
        if(screen.bgtype == 1){
          await this.imageCheck(screen, "backgroundImg", folder, tempImgs);
        }

        for(const component of screen.component_list){
          if(component.component_type == Component_Model.COMPONENT_IMAGE){
            if(!component.api_use){
              await this.imageCheck(component, "image", folder, tempImgs);
            }

          }else if(component.component_type == Component_Model.COMPONENT_IMAGESWITCHER){
            if(component.imgswitcher_list != null && component.imgswitcher_list.length > 0){
              if(!component.api_use){
                for(let i=0;i<component.imgswitcher_list.length;i++){
                  await this.imageCheckList(component, i, folder, tempImgs);
                }
              }
            }

          }else if(component.component_type == Component_Model.COMPONENT_MOVIE){
            await this.movieCheck(component, "movie", folder, tempImgs);

          }else if(component.component_type == Component_Model.COMPONENT_SOUND){
            await this.audioCheck(component, "sound", folder, tempImgs);

          }

          //마스크 이미지
          if(component.mask_use && component.mask_image.length > 0){
            await this.imageCheck(component, "mask_image", folder, tempImgs);
          }

        }
      }

      //기존 이미지중 빠진 이미지 삭제
      this.progress_msg = "데이터 체크중...";
      console.log("save_content save_images len : " + this.contentStore.save_images.length);

      console.log("save_content tempImgs : " + JSON.stringify(tempImgs));

      for(const img1 of this.contentStore.save_images){
        console.log("save_content get img1 : "+img1);
        var flag = false;
        for(const img2 of tempImgs){
          console.log("save_content img2 : "+img2);
          if(img1 == img2){
            flag = true;  //여전히 존재함.
            break;
          }
        }
        if(flag == false){
          //해당 이미지는 제거된 상태임 
          if(img1.startsWith(this.$storagepath+folder)){
            //자신의 컨텐츠가 맞다면 제거 처리 (asset이나 content면 복제되었기 때문에 원본을 지우면 안됨)
            console.log("save_content delete img : " + img1.toString().replace(this.$storagepath, "").split("?")[0]);
            const deleteRef = fref(this.$firestorage, img1.toString().replace(this.$storagepath, "").split("?")[0]);
            try{
              await deleteObject(deleteRef);
            }catch(error){
            }
          }
        }
      }

      this.progress_msg = "저장중입니다...";

      //썸네일 뜨기 - 사용안함, JSON 다이렉트로 사용함.
      //var thum_url = await this.editleft_panel.save_screen(this.content_mode, this.content_type, this.content_idx);

      //컨텐츠 저장
      this.editwindow.save(this.content_mode, this.content_idx);

      this.progress_dialog = false;

    },


    //이미지 처리 알고리즘 - 신규면 올리고, 다른path면 복제
    //object : 컴포넌트 객체, 
    //propertyName : 멤버변수
    async imageCheck(object, propertyName, folder, tempImgs){

      console.log("imageCheck -------------------------------------- ");
      console.log("property name : " + propertyName);
      console.log("folder : " + folder);
      //console.log("property name2 : " + object[propertyName]);
      

      if(object[propertyName].startsWith("data:image")){
        
        //첨부된 이미지면 업로드
        console.log("imgcheck....1");

          //이미지 blob의 경우
          this.progress_msg = "데이터 업로드중...";

          const upload_ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_new.png");
          const metadata = { contentType: 'image/png' };

          //data:image는 blob으로 변경시켜서 업로드 해야함. 진행상황을 보여주는 함수가 blob만 지원함.
          const img_blob = this.dataURItoBlob(object[propertyName]);
          let upload_cb = await this.uploadFileStorage(upload_ref, img_blob, metadata);
          if(upload_cb != null){
            object[propertyName] = this.$storagepath+upload_cb._location.path_;
            console.log(propertyName+" new image upload1 : " + object[propertyName]);
          }

      }else if(!object[propertyName].startsWith(this.$storagepath+folder)){
        //관리자 리소스를 사용자에게 복제해준다.
        console.log("imgcheck....3");

        //복제 처리 - storage_path + folder로 시작하지 않으면 복제 대상임.
        this.progress_msg = "데이터 복사중...";

        try{
          //데이터 복제
          const originRef = object[propertyName].toString().replace(this.$storagepath, "").split("?")[0];
          const targetFileRef = folder+"/"+Date.now().toString()+"_copy.png";

          console.log("imgcheck3 origin " + originRef);
          console.log("imgcheck3 target " + targetFileRef);

          const path = await this.resourceCopy(originRef, targetFileRef);
          object[propertyName] = this.$storagepath + path;
          //path뒤에 ?update=시간은 넣지 않아도 될듯.


        }catch(error){
          console.log(error);
        }
      
      }else{
        tempImgs.push(object[propertyName]);
      }

  },

  //이미지 스위처 처리 알고리즘 - 신규면 올리고, 다른path면 복제
  async imageCheckList(object, position, folder, tempImgs){

    if(object.imgswitcher_list[position].startsWith("data:image")){

        //신규로 지정된 경우 이미지 업로드
        this.progress_msg = "데이터 업로드중...";

        const upload_ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_new.png");
        const metadata = { contentType: 'image/png' };

        //data:image는 blob으로 변경시켜서 업로드 해야함. 진행상황을 보여주는 함수가 blob만 지원함.
        const img_blob = this.dataURItoBlob(object.imgswitcher_list[position]);
        let upload_cb = await this.uploadFileStorage(upload_ref, img_blob, metadata);
        if(upload_cb != null){
          object.imgswitcher_list[position] = this.$storagepath+upload_cb._location.path_;
          console.log("position " + position +" new image upload1 : " + object.imgswitcher_list[position]);
        }

    }else if(!object.imgswitcher_list[position].startsWith(this.$storagepath+folder)){

      //복제 처리 - storage_path + folder로 시작하지 않으면 복제 대상임.
      this.progress_msg = "데이터 복사중...";

      try{
        //데이터 복제
        const originRef = object.imgswitcher_list[position].toString().replace(this.$storagepath, "").split("?")[0];
        const targetFileRef = folder+"/"+Date.now().toString()+"_copy.png";
        const path = await this.resourceCopy(originRef, targetFileRef);
        object.imgswitcher_list[position] = this.$storagepath + path;

      }catch(error){
        console.log(error);
      }
    
    }else{
      tempImgs.push(object.imgswitcher_list[position]);
    }
  },

  //동영상 처리 알고리즘 - 신규면 올리고, 다른path면 복제
  async movieCheck(object, propertyName, folder, tempImgs){

    if(object[propertyName] instanceof File){

        //신규로 지정된 경우 이미지 업로드
        this.progress_msg = "데이터 업로드중...";

        const upload_ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_new.mp4");
        const metadata = { contentType: 'video/mp4' };

        //blob으로 업로드 해야함. 진행상황을 보여주는 함수가 blob만 지원함.
        const movie_blob = object[propertyName];
        let upload_cb = await this.uploadFileStorage(upload_ref, movie_blob, metadata);
        if(upload_cb != null){
          object[propertyName] = this.$storagepath+upload_cb._location.path_;
          console.log(propertyName+" new movie upload1 : " + object[propertyName]);
        }

    }else if(!object[propertyName].startsWith(this.$storagepath+folder)){

      //복제 처리 - storage_path + folder로 시작하지 않으면 복제 대상임.
      this.progress_msg = "데이터 복사중...";

      try{
        //데이터 복제
        const originRef = object[propertyName].toString().replace(this.$storagepath, "").split("?")[0];
        const targetFileRef = folder+"/"+Date.now().toString()+"_copy.mp4";
        const path = await this.resourceCopy(originRef, targetFileRef);
        object[propertyName] = this.$storagepath + path;
        
      }catch(error){
        console.log(error);
      }
    
    }else{
      tempImgs.push(object[propertyName]);
    }

  },


  //오디오 처리 알고리즘 - 신규면 올리고, 다른path면 복제
  async audioCheck(object, propertyName, folder, tempImgs){

    if(object[propertyName] instanceof File){

        //신규로 지정된 경우 이미지 업로드
        this.progress_msg = "데이터 업로드중...";

        const upload_ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_new.mp3");
        const metadata = { contentType: 'audio/mp3' };

        //blob으로 업로드 해야함. 진행상황을 보여주는 함수가 blob만 지원함.
        const audio_blob = object[propertyName];
        let upload_cb = await this.uploadFileStorage(upload_ref, audio_blob, metadata);
        if(upload_cb != null){
          object[propertyName] = this.$storagepath+upload_cb._location.path_;
          console.log(propertyName+" new audio upload1 : " + object[propertyName]);
        }

    }else if(!object[propertyName].startsWith(this.$storagepath+folder)){

      //복제 처리 - storage_path + folder로 시작하지 않으면 복제 대상임.
      this.progress_msg = "데이터 복사중...";

      try{
        //데이터 복제
        const originRef = object[propertyName].toString().replace(this.$storagepath, "").split("?")[0];
        const targetFileRef = folder+"/"+Date.now().toString()+"_copy.mp3";
        const path = await this.resourceCopy(originRef, targetFileRef);
        object[propertyName] = this.$storagepath + path;

      }catch(error){
        console.log(error);
      }

    }else{
      tempImgs.push(object[propertyName]);
    }

  },


    dataURItoBlob(dataURI){
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    },

    // Data URI를 Blob 객체로 변환하는 함수
    dataURItoBlob2(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },

    //firebase storage에 파일 업로드 및 프로그레스 표시
    uploadFileStorage(storage_ref, blobd_ata, metadata){
      return new Promise((resolve, reject)=>{

          const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
          uploadTask.on('state_changed',
            (snapshot) => {
              this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running : ' + this.progress_value);
                  break;
              }                
            },
            (error) => {
              reject(error);
            },
            () => {
              // Upload completed successfully - 업로드 완료시 경로 리턴
              resolve(uploadTask.snapshot.ref);
            }
          );

      })
    },      


    //관리자 컨텐츠 로드
    async loadAdminContent(cidx){
      
      this.isLoading = true;

      console.log("save_content loadAdminContent...");
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

        this.$http.post(this.$host+'/ContentDetail',{
            CON_IDX:cidx
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.isLoading = false;
          if(result.data.resultCode == 0){
              const item = result.data.resultData[0];
              var jobj = JSON.parse(JSON.stringify(item.CON_JSON));
              this.contentStore.setContentModel(jobj.width, jobj.height);
              this.contentStore.content_model.idx = item.CON_IDX;
              this.contentStore.content_model.content_type = 0;
              this.contentStore.content_model.content_mode = 0; //관리자 모드
              this.contentStore.content_model.name = item.CON_NAME;
              this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
              this.contentStore.content_model.setStringValue(item.CON_JSON);
              this.contentStore.createImgList();
              this.set_content();
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

    },

    //관리자 에셋 로드
    async loadAdminAsset(cidx){
      
      console.log("loadAdminAsset...");
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

        this.$http.post(this.$host+'/AssetDetail',{
          ASSET_IDX:cidx
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              const item = result.data.resultData[0];
              var jobj = JSON.parse(JSON.stringify(item.ASSET_JSON));
              this.contentStore.setContentModel(jobj.width, jobj.height);
              this.contentStore.content_model.idx = item.ASSET_IDX;
              this.contentStore.content_model.content_type = 1; //asset
              this.contentStore.content_model.content_mode = 0; //관리자 모드
              this.contentStore.content_model.name = item.ASSET_NAME;
              this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
              this.contentStore.content_model.setStringValue(item.ASSET_JSON);
              this.contentStore.createImgList();
              this.set_content();
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

    },


    //관리자 팝업 컨텐츠 로드
    async loadAdminPopup(cidx){
      
      console.log("loadAdminPopup...");
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

        this.$http.post(this.$host+'/PopupDetail',{
          POPUP_IDX:cidx
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              const item = result.data.resultData[0];
              var jobj = JSON.parse(JSON.stringify(item.POPUP_JSON));
              this.contentStore.setContentModel(jobj.width, jobj.height);
              this.contentStore.content_model.idx = item.POPUP_IDX;
              this.contentStore.content_model.content_type = 2; //popup
              this.contentStore.content_model.content_mode = 0; //관리자 모드
              this.contentStore.content_model.name = item.POPUP_NAME;
              this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
              this.contentStore.content_model.setStringValue(item.POPUP_JSON);
              this.contentStore.createImgList();
              this.set_content();
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

    },

    async loadUserContent(cidx){
      
      console.log("loadUserContent : " + this.$storage.getStorageSync("SEARCH_LOCALID"));
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }
        const localid = this.$storage.getStorageSync("SEARCH_LOCALID");
        this.$http.post(this.$host+'/MyContentDetail',{
            MCON_IDX:cidx
          , LOCALID: localid
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              const item = result.data.resultData[0];
              var jobj = JSON.parse(JSON.stringify(item.MCON_JSON));

              //console.log("1 : " + JSON.stringify(this.contentStore.getContentModel));
              //console.log("2 : " + this.contentStore.content_model);
              this.contentStore.setContentModel(jobj.width, jobj.height);
              this.contentStore.setContentSize(jobj.width, jobj.height);
              this.contentStore.content_model.mcon_idx = item.MCON_IDX;
              this.contentStore.content_model.content_type = 0;
              this.contentStore.content_model.content_mode = 1; //사용자 모드
              this.contentStore.content_model.name = item.MCON_NAME;
              this.contentStore.content_model.localid = item.LOCALID;
              this.contentStore.content_model.parent_localid = item.PARENT_LOCALID;
              this.contentStore.content_model.setStringValue(item.MCON_JSON);
              this.contentStore.createImgList();
              this.set_content();
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

    },    



    //파일 카피 - asset리소스를 copy
    async resourceCopy(originref, copyref){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

      const result = await this.$http.post(this.$host+'/fileCopy',{
          ORIGIN_REF: originref
        , COPY_REF: copyref
      },{headers: { firetoken:token}});
      
      if(result.data.resultCode == 0){
        const path = result.data.path;
        return path;
      }else{
        return null;
      }

    },


    load_content(){
      this.comcontent.dialog_com = true;
    },

    //동일 사이즈 컨텐츠 템플릿 로드 콜백 - 기존 컨텐츠에 추가.
    select_content(item){
      console.log("---------------------------------------------------------");
      console.log(JSON.stringify(item));
      console.log(item.CON_JSON.width);
      
      const cm = new Content_Model(item.CON_JSON.width, item.CON_JSON.height);
      cm.setStringValue(item.CON_JSON);
      
      //폰트 로드처리
      const fontFaces = [];
      //기존 컨텐츠에 스크린을 추가함.
      for(const screen of cm.screen_list){
        for(const cp of screen.component_list){
          if(cp.font_url != null && cp.font_url != undefined && cp.font_url.length > 0){
            let fonturl = "url('"+cp.font_url+"')";
            if(cp.font_format != null && cp.font_format != undefined && cp.font_format.length > 0){
              fonturl += " format('"+cp.font_format+"')";
            }
            const fontoption = {weight:cp.font_weight == null || cp.font_weight.length == 0 ? "normal" : cp.font_weight, style:"normal"};
            const fontface = new FontFace(cp.textfont, fonturl, fontoption);
            document.fonts.add(fontface);
            fontFaces.push(fontface);
          }
        }
        this.contentStore.content_model.screen_list.push(screen);
      }

      if(fontFaces.length > 0){
        Promise.all(fontFaces.map(this.observeFontLoad))
        .then(() => {
            console.log('모든 폰트가 성공적으로 로드되었습니다.');
            this.editwindow.drawscreen();
        })
        .catch(error => {
            console.error('폰트 로드 중 오류가 발생했습니다:', error);
            this.editwindow.drawscreen();
        });
      }else{
        //로드할께 없으면
        this.editwindow.drawscreen();
      }
      
      //this.contentStore.content_model.idx  = item.CON_IDX;
      //this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
      //this.contentStore.content_model.name = item.CON_NAME;
      //this.contentStore.content_model.setStringValue(item.CON_JSON);
      //this.contentStore.select_screen = this.contentStore.content_model.screen_list[0];
      //this.editwindow.drawscreen();
    },
    

    //컴포넌트 추가
    addcomponent(){
      if(this.contentStore.select_screen == null){
        this.$alert.fire("컴포넌트를 추가하실 스크린을 선택하세요.");
        return;
      }
      this.editwindow.addcomponent();
    },

    drawerChanged(drawer){
      console.log("drawerChanged : " + drawer);
      this.drawer = drawer;
      if(drawer){
        this.windowHeight = window.innerHeight-40;
        //this.windowWidth  = this.center_panel.getBoundingClientRect().width;
        //this.windowWidth  = window.innerWidth-887;
      }else{
        this.windowHeight = window.innerHeight-40;
        //this.windowWidth  = this.center_panel.getBoundingClientRect().width;
        //this.windowWidth  = window.innerWidth-630
      }
    },

    resizeevent(){
      this.windowWidth  = this.center_panel.getBoundingClientRect().width;
      this.windowHeight = window.innerHeight-40;
      //this.windowWidth  = window.innerWidth-506;
    },

    async download(){

      this.editwindow.downloadimg();

      /*
      const screen_list    = this.contentStore.content_model.screen_list;
      const cur_view  = new Play_Background();
      cur_view.setInit(this.contentStore.content_model.width, this.contentStore.content_model.height);
      cur_view.setModel(screen_list[0]);
      cur_view.init();
      document.body.appendChild(cur_view);

      //html2canvas를 쓰려면 div가 화면에 추가되서 렌더링이 된 상태여야 함.
      var img = await html2canvas(cur_view, {useCORS:true});  //cors해줘야 이미지 URL이 표시됨.
      var imgData = img.toDataURL();

      const a = document.createElement('a');
      a.href = imgData;
      a.download = 'customDivImage.png';
      a.click();
      
      document.body.removeChild(cur_view);
      */

    },

    //asset의 경우 범위를 자동으로 컷해준다. 썸네일 공백생기는거 방지
    crop(){

      if(this.content_type == "asset"){
        
        //에셋의 경우 사이즈를 정교하게 따야함.
        const sm = this.contentStore.content_model.screen_list[0];
        const minX = Math.min(...sm.component_list.map(cm => cm.posx));
        const minY = Math.min(...sm.component_list.map(cm => cm.posy));
        const maxX = Math.max(...sm.component_list.map(cm => cm.posx+cm.width));
        const maxY = Math.max(...sm.component_list.map(cm => cm.posy+cm.height));
        console.log("asset save : " + minX+"/"+minY+"/"+maxX+"/"+maxY);
        this.contentStore.content_model.width  = maxX-minX;
        this.contentStore.content_model.height = maxY-minY;
        sm.width = maxX-minX;
        sm.height = maxY-minY;
        sm.component_list.forEach((component)=>{
          component.posx   = component.posx - minX;
          component.posy   = component.posy - minY;
          component.transx = component.transx - minX;
          component.transy = component.transy - minY;
        });

        
        this.contentStore.select_screen = this.contentStore.content_model.screen_list[0];
        this.editleft_panel.info_screen.screenselect(this.contentStore.select_screen);
        //this.editwindow.drawscreen();

      }


    },


  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.right_panel_style{
  width: 240px;
  min-width: 240px;
  max-width: 240px;
  overflow: hidden;
}

</style>