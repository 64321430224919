<template>
  <v-container fluid style="background:#ffffff;min-height:100%">

      <v-row justify="center" class="mt-10">
        <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
          <div id="title1_1" :class="$vuetify.display.mobile ? 'h1_text_mb' : 'h1_text'" >전세계 어디서나 바로</div>      
          <div id="title1_2" :class="$vuetify.display.mobile ? 'h1_text_mb' : 'h1_text'">바뀌는 디지털사이니지</div>      
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-15 mb-15" style="position:relative">
        <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
          <label id="title_logo" style="position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" :class="$vuetify.display.mobile ? 'barotitle_land_mb' : 'barotitle_land'">BARO</label>
          <label id="title_logo2" style="opacity: 0;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);" :class="$vuetify.display.mobile ? 'barotitle_land_mb' : 'barotitle_land'">BARO</label>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-15">
        <v-col cols="12" md="8">
          <div class="d-flex flex-column justify-center align-center land_bg1">
            <label class="white_subtitle">용도에 맞는 새로운 디자인을 만드세요.</label>
            <div class="d-flex justify-center align-center mt-15">
              <div class="white_round_bg mr-10">Baro 시작하기</div>
              <div class="white_round_bg">사용법 동영상 보기</div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="mt-15"></v-row>
      <v-row justify="center" class="mt-15">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <label id="title2" :class="$vuetify.display.mobile ? 'barotitle_land_mb' : 'barotitle_land'" style="margin-top:8px">BARO</label>
          <div id="title2" :class="$vuetify.display.mobile ? 'h2_text_mb' : 'h2_text'">&nbsp;만드는 나만의 컨텐츠!</div>      
        </v-col>
      </v-row>

      <v-row justify="space-around" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center">
          <v-sheet
            id="title2_img"
            rounded="xl"
            class="mx-auto"
            height="176"
            width="301" 
            elevation="0"           
            color="#F2F2F2"
            >         
              <v-img src="@/assets/content_img1.webp" width="301" height="176" cover class="rounded-xl img-hover-zoom"></v-img> 
            </v-sheet>
          <v-sheet
            id="title2_img"
            rounded="xl"
            class="mx-auto"
            height="176"
            width="301" 
            elevation="0"           
            color="#F2F2F2"
            >         
              <v-img src="@/assets/content_img2.png" width="301" height="176" cover class="rounded-xl img-hover-zoom"></v-img>  
            </v-sheet>
          <v-sheet
            id="title2_img"
            rounded="xl"
            class="mx-auto"
            height="176"
            width="301" 
            elevation="0"           
            color="#F2F2F2"
            >       
            <v-img src="@/assets/content_img3.webp" width="301" height="176" cover class="rounded-xl img-hover-zoom"></v-img>     
          </v-sheet>
        </v-col>
      </v-row>

      <v-row class="mt-15"></v-row>
      <v-row justify="center" class="mt-15">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <div id="title3" :class="$vuetify.display.mobile ? 'h2_text_mb' : 'h2_text'">키오스크에서 전자메뉴판 까지!</div>      
        </v-col>
      </v-row>

      <v-row justify="space-around" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center">
          <v-sheet
            id="title3_img"
            rounded="xl"
            class="mx-auto"
            height="391"
            width="264" 
            elevation="0"           
            color="#F2F2F2"
            >       
            <v-img src="@/assets/kiosk_img1.webp" width="264" height="391" cover class="rounded-xl img-hover-zoom"></v-img>     
          </v-sheet>
          <v-sheet
            id="title3_img"
            rounded="xl"
            class="mx-auto"
            height="391"
            width="264" 
            elevation="0"           
            color="#F2F2F2"
            > 
            <v-img src="@/assets/kiosk_img2.webp" width="264" height="391" cover class="rounded-xl img-hover-zoom"></v-img>              
          </v-sheet>
          <v-sheet
            id="title3_img"
            rounded="xl"
            class="mx-auto"
            height="391"
            width="264" 
            elevation="0"           
            color="#F2F2F2"
            >          
            <v-img src="@/assets/kiosk_img3.webp" width="264" height="391" cover class="rounded-xl img-hover-zoom"></v-img>     
          </v-sheet>
        </v-col>
      </v-row>      

      <v-row class="mt-15"></v-row>
      <v-row justify="center" class="mt-15">
        <v-col cols="12" md="8" class="d-flex align-center">
          <div id="title4" class="h3_text">추천 용도별</div>      
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              style="overflow: hidden;"
              > 
                <v-img src="@/assets/kind_img1.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">키오스크</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/kind_img2.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">전자메뉴판</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/kind_img3.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">매장광고</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/kind_img4.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">전시안내</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/kind_img5.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">대기호출</div>
          </div>         

        </v-col>
      </v-row>      

      <v-row class="mt-15"></v-row>
      <v-row justify="center" class="mt-15">
        <v-col cols="12" md="8" class="d-flex align-center">
          <div class="h3_text">추천 산업별</div>      
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/type_img1.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">패션.주얼리</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/type_img2.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">카페.레스토랑</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/type_img3.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">병원.약국</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/type_img4.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">교육.학원</div>
          </div>         
          <div id="title4_img" class="d-flex flex-column justify-center align-center mx-auto">
            <v-sheet
              rounded="xl"
              height="150"
              width="150" 
              elevation="0"           
              color="#F2F2F2"
              > 
              <v-img src="@/assets/type_img5.webp" width="150" height="150" cover class="rounded-xl img-hover-zoom"></v-img>
              </v-sheet>
              <div class="h4_text" style="margin-top:8px">미용.뷰티</div>
          </div>         

        </v-col>
      </v-row>      

      <v-row class="mt-15"></v-row>
      <v-row justify="center" class="mt-15">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <div id="title5" class="h3_text">지금 바로와 함께하세요</div>      
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >        
              <v-img src="@/assets/company_blue.png" height="50"></v-img>
            </v-sheet>
          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >         
              <v-img src="@/assets/company_fairpass.png" width="200" height="120"></v-img>
            </v-sheet>
          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_cube.png"  class="ml-5 mr-5" ></v-img>
              
            </v-sheet>
          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            > 
              <v-img src="@/assets/company_pict.png"  height="50"></v-img>         
            </v-sheet>
        </v-col>
      </v-row>      
      <v-row justify="center" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            > 
              <v-img src="@/assets/company_groundk.png" height="60"></v-img>         
            </v-sheet>

          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_papaya.png"  height="60"></v-img>
            </v-sheet>

          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_micelink.png" height="70"></v-img>
            </v-sheet>

          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_gamsung.png" height="70"></v-img>
            </v-sheet>

        </v-col>
      </v-row>      
      
      <v-row justify="center" class="mt-5">
        <v-col cols="12" md="8" class="d-flex align-center justify-center">
          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_dasi.png"  height="60"></v-img>
            </v-sheet>

          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_kdge.png" height="100"></v-img>
            </v-sheet>

          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_torito.png" cover height="120"></v-img>
            </v-sheet>

          <v-sheet
            id="title5_img" 
            rounded="true"
            class="mx-auto d-flex align-center justify-center"
            height="120"
            width="200" 
            elevation="4"           
            color="#ffffff"
            >          
              <v-img src="@/assets/company_loan.png" class="ml-5 mr-5" ></v-img>
            </v-sheet>

        </v-col>
      </v-row>            
      
      <v-row justify="center" class="mt-15">
        <div style="height:200px"/>
      </v-row>
      

      <v-row
      style="background:#191f28;max-height:280px !important; min-width:100% !important;" 
      class="d-flex flex-column align-center justify-start">
      
        <v-col cols="12" md="8" class="mt-3">
          <div class="footer_text1" style="margin-bottom:10px">(주)넥스퀘어</div>
          <div class="footer_text2">대표자: 라현성 | 사업자등록번호: 228-87-02062</div>
          <div class="footer_text2">통신판매업 신고번호: 제 2024-화성동탄-1282호</div>
          <div class="footer_text2">경기도 화성시 동탄기흥로 590 센트럴에이스타워 B동 603호</div>
          <div class="footer_text2">전화번호: 070-8098-9102</div>
          <div class="footer_text1" style="margin-top:10px">Copyright 2024 ©Necsquare Co.,Ltd. All Right Reserved.</div>
          <v-divider class="mt-3 mb-3" color="white"/>
          <div class="footer_text2"><a>이용약관</a><a class="ml-5">개인정보 처리 방침</a><a class="ml-5">저작권 안내</a></div>
          <div style="height:20px"/>
        </v-col>
      
      </v-row>

  </v-container>
</template>

<script>
  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
    export default {

    data: () => ({

    }),

    mounted(){
      
      gsap.registerPlugin(ScrollTrigger);

      const from = {x:0, y:-50, scale:1, rotate:0, alpha:0};
      const to   = {delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title1_1", from, to);

      const from2 = {x:0, y:-50, scale:1, rotate:0, alpha:0};
      const to2   = {delay:0.6, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title1_2", from2, to2);


      const from3 = {x:-500, y:0, scale:0.3, rotate:-40, alpha:0};
      const to3   = {delay:1.0, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title_logo", from3, to3);

      const time1 = gsap.timeline({repeat: -1, yoyo: false, repeatDelay: 2, delay: 3});
      time1.to("#title_logo", { duration:0.1, y:0, scale:1.5, skewY:0, ease:'expo' });
      time1.to("#title_logo", { duration:0.2, y:0, scale:1, skewY:0, ease:'expo' });

      const time2 = gsap.timeline({repeat: -1, yoyo: false, repeatDelay: 2, delay: 3});
      time2.to("#title_logo2", { duration:0.1, scale:1, alpha:0.5, ease:'expo' });
      time2.to("#title_logo2", { duration:0.2, scale:2, alpha:0, ease:'expo' });
      

      
      //바로 만드는 나만의 컨텐츠
      gsap.fromTo("#title2",{x:0, y:-50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out',
        scrollTrigger: { trigger:"#title2", start:"top center"}
      });      
      gsap.fromTo("#title2_img",{x:500, y:0, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'bounce', stagger:0.2,
        scrollTrigger: { trigger:"#title2", start:"top center"}
      });      

      //키오스크에서 전자메뉴판까지!
      gsap.fromTo("#title3",{x:0, y:-50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out',
        scrollTrigger: { trigger:"#title3", start:"top center"}
      });      
      gsap.fromTo("#title3_img",{x:500, y:0, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'bounce', stagger:0.2,
        scrollTrigger: { trigger:"#title3", start:"top center"}
      });      

      //추천용도별 산업벼
      gsap.fromTo("#title4_img",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic', stagger:0.1,
        scrollTrigger: { trigger:"#title4", start:"top center"}
      });     
      
      //지금바로와 함게하세요.
      gsap.fromTo("#title5_img",{x:0, y:50, scale:1, rotate:0, alpha:0}, 
      { 
        delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'step', stagger:0.1,
        scrollTrigger: { trigger:"#title5", start:"top center"}
      });     


    }

  }
</script>

<style scoped>

.h1_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 116px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h1_text_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h2_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 116px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h2_text_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h3_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h4_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #404040 !important;
  white-space: nowrap;
}

.barotitle_land{
  font-family: 'Lemon';
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 84px;
  background: linear-gradient(90deg, #FF0000 0%, #6E00FA 78.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.barotitle_land_mb{
  font-family: 'Lemon';
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  background: linear-gradient(90deg, #FF0000 0%, #6E00FA 78.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.land_bg1{
  background: linear-gradient(92.63deg, #FFE600 0.55%, #AD00FF 115.13%);
  border-radius: 23px;
  width:100%;
  height: 270px;
}

.white_subtitle{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #ffffff;
}

.white_round_bg{
  width:320px;
  height: 54px;
  background: #ffffff;
  border-radius: 30px;
  color: #000000;
  font-size: 22px;
  line-height: 54px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}


</style>
