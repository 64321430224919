<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 7">
    <v-container fluid class="ma-0 pt-0">
    
        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="center">
                WEBLINK URL
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <v-textarea
                    v-model="contentStore.select_component.model.weblink"
                    density="compact" variant="outlined"
                    />
            </v-col>
            
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

  },


  data(){
    return{


    }
  },

  mounted(){
    
  },

  
}
</script>