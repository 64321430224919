<template>
    <v-container fluid class="pa-0 ma-0" style="overflow:auto">

        <ComInfo_AniScreen      ref="aniscreen" />
        <ComInfo_AniComponent   ref="anicomponent" />
        
    </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import ComInfo_AniScreen from '@/nextvue/infopanel/ComInfo_AniScreen.vue'
import ComInfo_AniComponent from '@/nextvue/infopanel/ComInfo_AniComponent.vue'
import { useContentStore } from "@/stores/content";

export default {
  
  setup(){
    let aniscreen       = ref(null);
    let anicomponent    = ref(null);
    const contentStore  = useContentStore();
    return {aniscreen, anicomponent, contentStore}
  },

  components:{
    // eslint-disable-next-line vue/no-unused-components
    ComInfo_AniScreen, ComInfo_AniComponent
  },

  //프로퍼티 선언
  props:{

  },    

  mounted(){
    
  },


  data(){
    return{
 
    }
  },


  methods:{
    

  },



}
</script>