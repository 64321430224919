
import Screen_Model from '@/nextts/model/Screen_Model';
import gsap from "gsap";
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';
import Play_Controller from '@/nextts/play/Play_Controller';
import Component_Model from '../model/Component_Model';
import Play_Api from '@/nextts/play/Play_Api';
import Play_DataLoad from './Play_DataLoad';


//HTMLDivElement를 상속하면 하단에 define로 extends div를 정의해야함.
class Play_Background extends HTMLDivElement implements Http_Interface, Play_Interface{
    
    screen_model:Screen_Model|null = null;
    timeline:null|GSAPTimeline = null;

    //스크린 편집시 이동 및 확대축소시 사용함.
    transx:number   = 0;
    transy:number   = 0;

    constructor(){
        super();
        //this.style.overflow = "hidden";
        console.log("play background constructor");
        this.style.position = "absolute";
        this.style.overflow = "hidden";
        
        //this.style.width = "100px";
        //this.style.height = "100px";
        //this.style.backgroundColor = "blue";
        //this.textContent = "111111111";
        
        /*
        const testdiv = document.createElement("div");
        testdiv.style.backgroundColor = "red";
        testdiv.style.width = "200px";
        testdiv.style.height = "100px";
        this.appendChild(testdiv); 
        */
    }

    setInit(w:number, h:number){
        this.style.width  = w+"px";
        this.style.height = h+"px";
        //this.style.backgroundColor = c;
    }

    async setModel(m:Screen_Model){
        
        this.screen_model = m;
        //console.log("Play_Background setModel : " + JSON.stringify(this.screen_model.component_list.length));
        
        this.clearView();

        //배경 그리기
        this.updateRender();


        //데이터 로드 부터 처리
        for (const cm of this.screen_model.component_list) {
            if(cm.component_type === Component_Model.COMPONENT_DATALOAD) {
                const cp = Play_Controller.getPlayView(cm);
                cm.play_component = cp;
                if(cp != null){
                    this.appendChild(cp);
                    const interfaceObject:Play_DataLoad = cp as unknown as Play_DataLoad;
                    interfaceObject.loadData();
                }                
            }
        }

        //API 실행해주기 - 동기로 처리했음. (비동기 함수도 내부에 있음)
        for (const cm of this.screen_model.component_list) {
            if(cm.component_type === Component_Model.COMPONENT_API) {
                //바로 호출의 경우
                const cp = Play_Controller.getPlayView(cm);
                cm.play_component = cp;
                if(cp != null){
                    this.appendChild(cp);
                    if(cm.api_call == 1){
                        const interfaceObject:Play_Api = cp as unknown as Play_Api;
                        await interfaceObject.runApiAwait();
                    }
                }                
            }
        }

        //애니가 없는 컴포넌트 그리기
        this.screen_model.component_list.forEach(cm => {
            if(cm.component_type != Component_Model.COMPONENT_API && cm.component_type != Component_Model.COMPONENT_DATALOAD){
                if(cm.aniplaymodel == null || cm.aniplaymodel.anilist == null || cm.aniplaymodel.anilist.length == 0){
                    console.log("draw no ani component....");
                    const cp = Play_Controller.getPlayView(cm);
                    cm.play_component = cp;
                    if(cp != null){
                        this.appendChild(cp);
                        const interfaceObject:Play_Interface = cp as unknown as Play_Interface;
                        interfaceObject.init();                        
                    }
                }
            }
        });        


    }
    
    clearView(){
        
        //배경 클리어
        this.style.backgroundColor  = "#00000000";
        this.style.backgroundImage  = "";

        //컴포넌트 클리어
        while(this.firstChild){
            const child = this.firstChild as unknown as Play_Interface;
            if (child && typeof child.exit === 'function') {
                child.exit();
            }            
            this.removeChild(this.firstChild);
        }
    }

    updateRender(){

        if(this.screen_model == null || this.screen_model == undefined){
            return;
        }
        
        console.log("Play Background updateRender");

        //크기 설정
        /*
        this.style.width  = this.screen_model.width+'px';
        this.style.height = this.screen_model.height+'px';
        this.style.transform='scale(1.0)';
        this.style.backgroundColor = "red";
        */

        this.style.transformOrigin = ""

        //배경타입 
        if(this.screen_model.bgtype == 0){
            //color
            this.style.backgroundColor  = this.screen_model.backgroundColor;
            this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 1){
            //image
            this.style.backgroundColor  = this.screen_model.backgroundColor;
            this.style.backgroundSize   = this.screen_model.width + "px " + this.screen_model.height + "px";
            this.style.backgroundImage  = "url('"+this.screen_model.backgroundImg+"')"
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 2){
            //gradient
            this.style.background       = this.screen_model.backgroundColor;
            //this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }

    }

    //이미지 캡처용
    async saveImg(m:Screen_Model){

        this.style.position   = "fixed";
        this.style.top   = "0px";
        this.style.left  = "0px";



        this.screen_model = m;
        this.clearView();

        //배경 그리기
        this.updateRender();

        //데이터 로드 부터 처리
        for (const cm of this.screen_model.component_list) {
            if(cm.component_type === Component_Model.COMPONENT_DATALOAD) {
                const cp = Play_Controller.getPlayView(cm);
                cm.play_component = cp;
                if(cp != null){
                    this.appendChild(cp);
                    const interfaceObject:Play_DataLoad = cp as unknown as Play_DataLoad;
                    interfaceObject.loadData();
                }                
            }
        }

        //API 실행해주기 - 동기로 처리했음. (비동기 함수도 내부에 있음)
        for (const cm of this.screen_model.component_list) {
            if(cm.component_type === Component_Model.COMPONENT_API && cm.api_call == 1) {
                //바로 호출의 경우
                const cp = Play_Controller.getPlayView(cm);
                cm.play_component = cp;
                if(cp != null){
                    this.appendChild(cp);
                    const interfaceObject:Play_Api = cp as unknown as Play_Api;
                    await interfaceObject.runApiAwait();
                }                
            }
        }

        //컴포넌트 그리기
        this.screen_model.component_list.forEach(cm => {
            if(cm.component_type != Component_Model.COMPONENT_API && cm.component_type != Component_Model.COMPONENT_DATALOAD){
                const cp = Play_Controller.getPlayView(cm);
                cm.play_component = cp;
                if(cp != null){
                    this.appendChild(cp);
                    const interfaceObject:Play_Interface = cp as unknown as Play_Interface;
                    interfaceObject.drawView();                        
                }
            }
        });        

    }

    //Play_Interface 구현
    init(): void {

        console.log("Play_Background init...");

        if(this.screen_model == null) return;

        //custom api 요청보내기 (일단 패스)

        //애니가 있는 컴포넌트 그리기
        this.screen_model.component_list.forEach(cm => {
            if(cm.component_type != Component_Model.COMPONENT_API && cm.component_type != Component_Model.COMPONENT_DATALOAD){
                if(cm.aniplaymodel != null && cm.aniplaymodel.anilist != null && cm.aniplaymodel.anilist.length > 0){
                    console.log("draw ani component....");
                    const cp = Play_Controller.getPlayView(cm);
                    cm.play_component = cp;
                    if(cp != null){
                        this.appendChild(cp);
                        const interfaceObject:Play_Interface = cp as unknown as Play_Interface;
                        interfaceObject.init();                        
                    }
                }
            }
        });

        //z-order 다시 정비 - ani가 있고 없고에 따라서 꼬일 수 있음. 그래서 다시 순서를 정비해줘야한다.
        for(let i = 0 ; i<this.screen_model.component_list.length ; i++){
            const pcm = this.screen_model.component_list[i].play_component;
            if(pcm != null){
                pcm.style.zIndex = String(i);
            }
        }

        //스크린캡션 처리 (일단 패스)



        //모든 컴포넌트 시작 처리
        /*
        for (let i = 0; i < this.children.length; i++) {
            const child = this.children[i] as unknown as Play_Interface;
            if (child && typeof child.init === 'function') {
                child.init();
            }            
        } 
        */       

    }

    exit(): void {
        
        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        this.clearView();
        this.screen_model = null;

    }

    drawView(): void {
        
    }

    //Http_Interface 구현
    resultHttp(a_id:number, result:string, obj:object){

    }


    //화면전환 진입 애니매이션
    inAni(callback:Function){
        
        if(this.screen_model == null || this.screen_model == undefined){
            return;
        }
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:0, yoyo: false});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.screen_model.transx);
        let move_posy   = Number(this.screen_model.transy);
        let move_rotate = Number(this.screen_model.rotate);

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:0 });

        this.screen_model.aniplaymodel.anilist.forEach((ani, index)=>{

            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};

            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
            //console.log("aniprop : " + JSON.stringify(aniprop));
            
        });        

        this.timeline.eventCallback("onComplete", ()=>{
            console.log("timeline onComplete...");
            //애니가 끝나면 콜백 실행
            if(callback){
                callback();
            }
            
        });        

    }

    //화면전환 인 애니매이션 (전달받은 특정 형태의 애니 실행)
    setInPlayAni(aniinfo:string, callback:Function){
        
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline();

        const outani_info = JSON.parse(aniinfo);

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:0, y:0, scale:1, rotate:0, alpha:0 });
        this.timeline.to(this, outani_info);
        
        this.timeline.eventCallback("onComplete", ()=>{
            //애니가 끝나면 콜백 실행
            if(callback){
                callback();
            }
            
        });        

    }

    //화면전환 아웃 애니매이션 (전달받은 특정 형태의 애니 실행)
    setOutPlayAni(aniinfo:string, callback:Function){
        
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline();

        const outani_info = JSON.parse(aniinfo);

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:0, y:0, scale:1, rotate:0, alpha:1 });
        this.timeline.to(this, outani_info);
        
        this.timeline.eventCallback("onComplete", ()=>{
            //애니가 끝나면 콜백 실행
            if(callback){
                callback();
            }
            
        });        

    }



}

export default Play_Background
customElements.define('play-background', Play_Background, {extends:'div'});