<template>

  <v-container fluid class="pa-0 ma-0">
  <template v-if="contentStore.select_component != null">

    <v-row dense justify="center">
      <v-col >

        <v-menu 
          v-model="dialog_com"
          location="start"
          :close-on-content-click="false"
          @update:modelValue="menu_handler"
          >
          <template v-slot:activator="{ props }">
            <div v-bind="props" style="cursor:pointer">
              <v-btn variant="text" size="small">Mask Sample</v-btn>
            </div>
          </template>

          <v-card class="ma-0 pa-1">
            <v-row dense align="center" class="pa-0 ma-0">
              <v-col cols="9" class="ml-5 mt-2">
                <label style="color:black">{{title}}</label>
              </v-col>
              <v-col cols="2" class="mt-2">
                <v-btn size="small" variant="icon" class="dialog_close_btn mr-1" @click="close_com"><v-icon>mdi-close</v-icon></v-btn>
              </v-col>
            </v-row>

            <v-row dense justify="center" class="pa-0 ma-0">
              <v-col cols="11">
                <div style="background:gray;height:1px;" />
              </v-col>
            </v-row>

            <v-card-text>
              <v-container>
                <v-row justify="center">
                  <v-col 
                    v-for="mask in itemList_com"
                    cols="4" :key="mask"
                    class="d-flex child-flex ma-0 pa-1"
                    >
                    <v-img
                      width="20"
                      :src="mask.MASK_PATH"
                      cover
                      @click="selectmask(mask.MASK_PATH)"
                      />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text> 

            <v-row dense justify="center" class="pa-0 ma-0">
              <v-col cols="11">
                <div style="background:gray;height:1px;" />
              </v-col>
            </v-row>

            <!-- 하단검색 -->
            <searchbar ref="searchbar_com" @searchcallback="search_run_com"/>
            
            <!-- 하단페이징영역 -->
            <pagingbar ref="paging_com" :page="page_com" @loadpage="movepage_com"/>  

            <v-card-actions>
              <v-row justify="center">
                <v-btn variant="text" size="small" @click="close_com">취소</v-btn>
                <v-btn variant="text" size="small" class="ml-5" @click="save_com">선택</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>

        </v-menu>

      </v-col>
    </v-row>

  </template>
  </v-container>

</template>

<script>
import { ref } from '@vue/reactivity';
import wait from 'waait';
import { useContentStore } from "@/stores/content";
    export default {

        watch:{
            dialog_com: async function(v1){
                //console.log("v1 : " + v1 + " / v2 : " + v2);
                if(v1){
                    //팝업이 뜬 경우
                    await wait(250);
                    this.search_run_com();
                }
            },
        },

        setup(){
          const contentStore = useContentStore();
          const searchbar_com = ref(null);
          const paging_com    = ref(null);
          return {searchbar_com, paging_com, contentStore};
        },

        mounted(){
          this.page_com = 1;
        },

        data: ()=> ({
            dialog_com : false,
            disabled:false,
            text_backup:"",

            sort_name_com: 'REG_DATE',
            sort_type_com: true,    //true : DESC, false: ASC
            page_com : 0,
            pagerow_com: 20,
            maxpage_com : 0,       //전체 페이지 개수

            itemList_com:[],

        }),

        methods: {

          movepage_com(p){
              this.loadpage_com(p);
          },

          //검색
          search_run_com(searchkeyword){
            if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
              this.search_keyword_com = searchkeyword;
            }else{
              this.search_keyword_com = '';
            }
            this.loadpage_com(1);
          },      

          async loadpage_com(p){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.page_com = p;
            this.paging_com.setpage(this.page_com);
            this.$http.post(this.$host+'/ResMaskList',{
                  search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                , sort_name:this.sort_name_com
                , sort_type: this.sort_type_com == true ? "DESC" : "ASC" 
                , page: this.page_com
                , pagerow: this.pagerow_com
            },{headers: { firetoken:token}})
            .then((result)=>{
                
                if(result.data.resultCode == 0){
                    this.totalcnt_com = result.data.totalcnt;
                    this.paging_com.makepaging(this.totalcnt_com, this.pagerow_com);
                    const list = result.data.resultData;
                    this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                    list.forEach(element => {
                        this.itemList_com.push(element); 
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                
            });

        },                  

            selectmask(mask_url){
              this.contentStore.select_component.model.mask_image = mask_url;
              this.updateRender();
            },

            //다이얼로그 닫기
            close_com () {
              this.contentStore.select_component.model.mask_image = this.text_backup;
              this.updateRender();
              this.dialog_com = false
            },

            //추가 저장
            save_com () {
              //콜백 호출 후 닫아야함.
              //this.$emit('callback');
              this.updateRender();
              this.dialog_com = false
            },      

            menu_handler(){
              //최초 또는 메뉴 외곽 클릭시 
              //텍스트값 백업
              if(this.contentStore.select_component.model.mask_image.length > 0){
                this.text_backup = this.contentStore.select_component.model.mask_image;
              }else{
                this.text_backup = "";
              }
              
            },

            //편집창 컴포넌트 렌더링
            updateRender(){
                if(this.contentStore.select_component != null){
                    this.contentStore.saveSnapshot();
                    this.contentStore.select_component.updateRender();
                }
            },

        }

    }
</script>

<style scoped>


</style>