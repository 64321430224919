import { defineStore } from 'pinia'
import { computed, ref, reactive } from "vue";
import Content_Model from '@/nextts/model/Content_Model'
import Screen_Model from '@/nextts/model/Screen_Model'
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'
import EditBasic_Screen from '@/nextts/edit/EditBasic_Screen';
import Component_Model from '@/nextts/model/Component_Model';

export const useContentStore = defineStore("content",{
    
    

    state:()=>({
        
        //실시간 데이터 편집용 (모바일 전용)
        datamap: null as object | null,

        //기본 편집용 컨텐츠 모델
        content_model : reactive(new Content_Model(1920, 1080)),

        //info_screen - 선택된 screen 객체
        select_screen : null as Screen_Model | null,

        //info_layer - 선택된 component 객체 (모델 아니고 컴포넌트 element임)
        select_component : null as EditBasic_Element | EditBasic_Screen | null,

        //저장시 이미지 싱크 처리를 위한 배열
        save_images : new Array<String>(),

        //info_scree - drag and drop용 객체
        drag_screen : null as Screen_Model | null,  //드래그 시작 객체
        drop_screen : null as Screen_Model | null,  //드롭한 객체

        //undo redo용 히스토리 객체
        history: new Array(),
        currentIndex : -1,

        //텍스트 입력모드(편집창에서 스페이스바로 스크린이동 때문에 충돌하는 부분 처리)
        isInputText : false
    }),

    
    //값을 읽을 때는 compute사용 - 이건 그냥 멤버변수처럼 호출해서 사용함.
    getters:{
        getContentModel : (state) => state.content_model,
        getScreenList:(state) => state.content_model.screen_list,
        getSelectScreen:(state) => state.select_screen,
    },
    
    actions:{

        clearHistory(){
            this.currentIndex = -1;
            this.history.splice(0, this.history.length);
        },

        saveSnapshot() {
            //스냅샷은 객체를 deep copy하기 위해 스트링 변환후 히스토리에 저장한다. 반응형이라 객체로 저장하면 문제됨.
            //JSON 파싱 - component와 screen은 드로잉 오브젝트라서 제외시킨다.
            const str = 
            JSON.stringify(
                JSON.parse(
                    JSON.stringify(this.content_model, (key, value)=>{
                        if(key === "component" || key === "screen" || key === "play_component"){
                            return undefined;
                        }else{
                            return value;
                        }
                    })
            ));
            
            //언두나 리두가 없는 상태면 계속 쌓는다.
            if(this.currentIndex == this.history.length-1){
                //개수제한
                if (this.history.length > 100) {
                    this.history.shift(); // 배열에서 첫 번째 요소 제거 (가장 오래된 데이터)
                }                
                this.history.push(str);
                this.currentIndex = this.history.length - 1;
            }else{
                //언두나 리두가 일어난 상태에서 스냅샷이 발생하면 현재 포지션 이후 모두 제거
                this.history.splice(this.currentIndex+1);
                if (this.history.length > 100) {
                    this.history.shift(); // 배열에서 첫 번째 요소 제거 (가장 오래된 데이터)
                }                
                this.history.push(str);
                this.currentIndex = this.history.length - 1;
            }

            console.log("saveSnapshot len : " + this.history.length + " / " + this.currentIndex);


        },

        //뒤로
        undo() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.content_model.setStringValue(JSON.parse(this.history[this.currentIndex]));
            }
            console.log("saveSnapshot undo : " + this.history.length + " / " + this.currentIndex);
        },
      
        //앞으로
        redo() {
            if (this.currentIndex < this.history.length - 1) {
                this.currentIndex++;
                this.content_model.setStringValue(JSON.parse(this.history[this.currentIndex]));
            }
            console.log("saveSnapshot redo : " + this.history.length + " / " + this.currentIndex);
        },         
          

        //컨텐츠 모델을 새로 생성
        setContentModel(w:number, h:number){
            this.content_model = reactive(new Content_Model(w, h));
        },

        //스크린 리스트에서 선택된 객체 설정
        selectScreenModel(select:Screen_Model){
            this.select_screen = select;
        },

        addScreenModel(sm:Screen_Model){
            this.content_model!.screen_list.push(sm);
        },

        //setContentModel을 사용하도록 수정해야한다. content_model을 새로 생성하지 않으면 undo실행 후 재 진입시 오류남.
        setContentSize(w:number, h:number){
            this.content_model!.width  = w;
            this.content_model!.height = h;
            this.createImgList();   //사용된 이미지 리스트 생성
        },

        //컨텐츠에 사용된 이미지 리스트 갱신하기.
        createImgList(){
            //http로 시작하는 이미지 리스트 추가
            this.save_images.splice(0, this.save_images.length);
            this.content_model.screen_list.forEach(screen=>{
                screen.component_list.forEach(component=>{
                    if(component.component_type == Component_Model.COMPONENT_IMAGE){
                        if(!component.api_use && component.image.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                            this.save_images.push(component.image);
                        }
                        if(component.mask_use){
                            if(component.mask_image.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                                this.save_images.push(component.mask_image);
                            }
                        }
                    }else if(component.component_type == Component_Model.COMPONENT_COLOR){
                        if(component.mask_use){
                            if(component.mask_image.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                                this.save_images.push(component.mask_image);
                            }
                        }

                    }else if(component.component_type == Component_Model.COMPONENT_IMAGESWITCHER){

                        if(!component.api_use){
                            if(component.imgswitcher_list != null && component.imgswitcher_list.length > 0){
                                component.imgswitcher_list.forEach(img => {
                                    if(img.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                                        this.save_images.push(img);
                                    }
                                });
                            }
                        }
                        if(component.mask_use){
                            if(component.mask_image.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                                this.save_images.push(component.mask_image);
                            }
                        }

                    }else if(component.component_type == Component_Model.COMPONENT_MOVIE){
                        if(component.movie.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                            this.save_images.push(component.movie);
                        }
                        if(component.mask_use){
                            if(component.mask_image.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                                this.save_images.push(component.mask_image);
                            }
                        }

                    }else if(component.component_type == Component_Model.COMPONENT_SOUND){
                        if(component.movie.toLocaleLowerCase().startsWith("https://storage.googleapis.com/necsolution-59f61.appspot.com")){
                            this.save_images.push(component.sound);
                        }
                    }
                });
            });
            console.log("save_content save images : " + JSON.stringify(this.save_images));
        }

    },

    

})

