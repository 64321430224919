//애니매이션 재생용 모델 - 실제 컴포넌트가 소유하는 애니매이션 객체.
import Animation_Model from "@/nextts/model/Animation_Model";

class AnimationPlay_Model {
    
    ani_idx:number;
    category_idx:number;
    aniplayname:string;
    repeat:number;
    repeatDelay:number;
    yoyo:boolean;
    anilist:Animation_Model[];
    
    constructor(){
        this.ani_idx = 0;
        this.category_idx = -1;
        this.aniplayname = "";
        this.repeat = 0;
        this.repeatDelay = 0;
        this.yoyo = false;
        this.anilist = [];
    }

    //서버에서 읽어온 json정보로 셋팅
    setStringValue(params:string):void{
    
        const temp          = JSON.parse(JSON.stringify(params));
        this.ani_idx        = temp.ani_idx
        this.aniplayname    = temp.aniplayname
        this.repeat         = temp.repeat
        this.repeatDelay    = temp.repeatDelay
        this.yoyo           = temp.yoyo

        this.anilist.splice(0, this.anilist.length);
        temp.anilist.forEach((ani:string) => {
            const am = new Animation_Model();
            am.setStringValue(ani);
            this.anilist.push(am);
        });
        
    }

}

export default AnimationPlay_Model
