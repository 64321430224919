
<template>

  <v-row>
    <v-col cols="12">
      <v-card
        color="#f0f0f0" class="overflow-y-auto" max-height="300px">
        <v-card-text>
            <h2 style="text-align: center;">서비스 이용약관</h2>
            <br>
            <div class="col-12">
                <p class="mb-2"><strong>주식회사 넥스퀘어(이하 "회사")</strong>가 운영하는 BARO(kioskbaro.com) 웹사이트(이하 '서비스')와 PC 프로그램(이하 '플레이어')를 이용할 수 있는 조건, "회사" 와 "회원" 간의 권리, 의무 및 책임 등을 규정합니다.</p>
            </div>
            <br>
            <h3 style="margin: 10;">목차</h3>
            <p style="margin: 10;">
                제1조 목적<br>
                제2조 이용약관의 효력 및 변경<br>
                제3조 용어의 정의<br>
                제4조 서비스 이용계약의 성립<br>
                제5조 서비스 이용의 요구사항<br>
                제6조 개인정보의 보호 및 사용<br>
                
                제7조 이용의 제한<br>
                제8조 서비스 이용시간<br>

                제9조 컨텐츠의 저작권과 이용<br>
                제10조 당사의 의무<br>
                제11조 회원의 의무<br>

                제12조 유료 서비스<br>
                제13조 결제방식<br>
                제14조 요금제의 변경<br>
                제15조 결제취소 및 환불<br>
                제16조 과오금<br>
                제17조 환불절차<br>

                제18조 분쟁해결<br>
                제19조 손해배상<br>
                제20조 면책 조항<br>
                제21조 관할법원 및 준거법<br>
                
                <br>
            </p>
            <br>

            <div class="col-12">
                <h3 class="mb-2">제1조 목적</h3>
            </div>
            <div class="col-12">
                <p>본 이용약관(이하 '약관')은 주식회사 넥스퀘어(이하 '회사')가 운영하는 BARO 웹사이트(kioskbaro.com) 및 관련 PC 프로그램(이하 '플레이어')을 이용함에 있어 회사와 회원 간의 권리, 의무 및 책임 사항을 명확히 함으로써 양 당사자의 법적 이익을 보호하고, 원활한 서비스 제공을 도모하기 위해 제정됩니다. 이 약관은 회원이 온라인에서 이 약관에 동의함으로써 효력이 발생하며, 회사는 필요한 경우 관련 법령을 준수하며 이 약관을 수정할 수 있습니다. 약관의 변경 사항은 웹사이트에 공시하거나 회원에게 통지함으로써 효력이 발생합니다.</p><br>
                <p>당사의 서비스를 사용하는 회원은 아래의 항목에 동의한 것으로 간주합니다.</p><br>
                <p>1. 서비스 이용약관</p>
                <p>2. 개인정보 처리방침</p>
                <p>3. 저작권 규정</p>
            </div>
            <br>


            <div class="col-12">
                <h3 class="mb-2">제2조 이용약관의 효력 및 변경</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	효력의 발생</h4>
            <v-col cols="12">
                - 본 약관은 서비스 내 또는 연결된 웹사이트(이하 '서비스 홈페이지')에 게시되거나, 다른 방법으로 회원에게 공지함으로써 효력이 발생합니다. 회원이 이 약관의 내용에 동의하고 서비스의 이용 절차를 완료함으로써 약관에 동의한 것으로 간주됩니다.<br><br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	약관의 변경</h4>
            <v-col cols="12">
                - 회사는 법률이나 서비스의 변경사항을 반영하기 위하여 필요한 경우 이용약관을 수정할 수 있습니다. 약관이 변경될 경우 회사는 변경 사항의 적용 일자 및 변경 내용, 변경 이유를 명시하여 최소한 적용 일자 7일 전부터 서비스 홈페이지에 공지하거나 회원에게 전자적 수단을 통해 통지합니다.<br><br>
                - 회사가 전자적 수단을 통해 변경 내용을 통지할 경우, 회원의 연락처 불명 또는 오류 등으로 인해 정상적인 통지가 어려운 경우에도 서비스 홈페이지에 게시하는 것으로 충분한 통지로 간주합니다.<br><br>
                - 회원은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관의 적용 일자 전에 회원 탈퇴를 요청할 수 있습니다. 약관 변경의 적용 일자 이후에도 서비스를 계속 사용할 경우 변경된 약관에 동의한 것으로 간주됩니다.<br><br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제3조 용어의 정의</h3>
            </div>
            <v-col cols="12">
                회사: '주식회사 넥스퀘어'를 지칭하며, BARO 웹사이트(kioskbaro.com) 및 관련 PC 프로그램(이하 '플레이어')을 운영하는 법인입니다.<br>
                회원: 회사와 서비스 이용 계약을 체결하고 회사가 제공하는 서비스를 이용하기 위해 회원 ID를 부여받은 사람을 말합니다.<br>
                서비스: 회사가 운영하는 BARO 웹사이트와 플레이어를 통해 회원에게 제공하는 모든 서비스를 의미합니다.<br>
                플레이어: BARO 서비스를 윈도우 PC에서 플레이 하기 위한 PC 프로그램을 의미합니다.<br>
                무료회원: 유료회원이 아닌 회원으로 회원가입 이후에 기본적으로 모든 회원에게 부여되는 자격을 가진 회원을 의미합니다.<br>
                유료회원: 결제를 통해 유료"서비스"를 이용하는 회원을 의미합니다.<br>
                이용계약: 서비스를 제공받기 위하여 회사와 체결하는 계약을 말하며 회원가입과 동일한 의미입니다.<br>
                결제: 회원이 특정 '유료서비스'를 이용하기 위하여 각종 지불수단을 통하여 회사가 정한 일정 금액을 회사에 지불하는 것을 의미합니다.<br>
                정기결제: '회원'이 '유료서비스'를 이용하기 위해 미리 등록한 결제수단을 통하여 월/년 단위의 이용기간에대한 이용요금이 정기적으로 결제되고, 이용기간이 자동 갱신되는 '결제' 방식을 의미합니다.<br>
                해지: '회원' 이 '회사'와 체결한 '이용 계약' 을 해약하는 것을 말하며, '회원 탈퇴' 와 동일한 의미입니다.<br>
                콘텐츠: '디자인 요소'와 '템플릿' 및 각각의 키워드, 이름, 설명 등의 메타데이터를 포함합니다.<br>
                디자인 에셋: '회원'이 '에디터'를 통해 사용할 수 있도록 '회사'가 제공하는 모든 유료 또는 무료의 사진, 비트맵 이미지, 벡터 이미지, GIF 애니메이션, 아이콘, 선, 도형, 일러스트, 글꼴, 영상, 음원, 폰트 등의 데이터를 의미합니다.<br>
                템플릿: '회원'이 '에디터'를 통해 편집 및 사용할 수 있도록 '디자인 에셋'를 조합한 데이터를 의미합니다.<br>
                회원 콘텐츠 : '회원'이 '회사'에서 제공하는 '에디터'를 통해 직접 편집 또는 저장한 데이터를 의미합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제4조 서비스 이용계약의 성립</h3>
            </div>
            <v-col cols="12">
                서비스 이용계약은 회원이 되고자 하는 자(이하 '이용신청자')가 약관의 내용에 대하여 동의를 하고, 회사가 정한 양식에 따라 이용신청을 하며 회사가 이를 승낙함으로써 성립됩니다.<br>
                이용신청자는 회사가 요구하는 정보(예: 회사명, 이메일 주소, 필요 시 법인 정보 등)를 제공하여야 합니다.<br>
                모든 회원은 타인의 정보를 도용하거나 허위의 정보를 제공할 경우 이용계약이 무효가 될 수 있으며, 관련 법령에 따라 처벌 받을 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	계약의 승낙과 제한</h4>
            <p style="margin: 10;">
                회사는 이용신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 회사는 아래와 같은 경우에 대하여 승낙을 거부하거나 사후에 이용계약을 해지할 수 있습니다.
            </p>
            <v-col cols="12">
                신청 내용에 허위, 기재누락, 오기가 있는 경우<br>
                기타 이용신청자가 회사의 기술상 또는 업무상 문제를 일으킬 것으로 판단되는 경우<br>
                이용신청자가 과거 회사의 서비스 이용규정을 위반하여 이용계약이 해지된 전력이 있는 경우<br>
                법령 또는 약관을 위반하여 회사나 다른 회원의 권리 또는 이익을 침해하거나 그러할 우려가 있는 경우<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	계약의 승낙과 제한</h4>
            <v-col cols="12">
                회사의 승낙은 이용신청자에게 서비스 사용 시작 통지가 도달한 시점으로부터 효력이 발생합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제5조 서비스 이용의 요구사항</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	이용자의 준수 의무</h4>
            <v-col cols="12">
                회원은 서비스 이용에 있어서 회사가 제공하는 절차에 따라야 하며, 이에 반하는 경우 서비스 이용에 제한을 받을 수 있습니다.<br>
                회원은 서비스 이용 시 법령, 이 약관의 규정, 이용안내 및 서비스 관련 공지사항 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	시스템 요구사항</h4>
            <v-col cols="12">
                서비스를 이용하기 위해 회원은 회사가 지정하는 최소한의 기술 사양을 갖춘 컴퓨터, 운영체제, 인터넷 연결, 필요 소프트웨어 등을 준비해야 합니다. 서비스의 원활한 이용을 위해 회사는 회원에게 특정 시스템 사양을 권장할 수 있습니다.<br>
                회사는 서비스의 효율적인 이용을 위해 회원에게 정기적으로 소프트웨어 업데이트나 시스템 점검 등을 권장할 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	회원의 정보 보안</h4>
            <v-col cols="12">
                회원은 자신의 로그인 정보를 안전하게 관리해야 하며, 제3자에게 이를 양도하거나 공유할 수 없습니다. 로그인 정보의 관리 소홀, 부정 사용에 의한 모든 결과와 책임은 회원에게 있습니다.<br>
                회원은 자신의 계정이나 로그인 정보가 도용되거나 제3자에 의해 사용되고 있다는 사실을 인지한 경우 즉시 회사에 통보하고 회사의 안내에 따라야 합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	지적 재산권의 존중</h4>
            <v-col cols="12">
                서비스와 관련된 모든 저작권 및 지적 재산권은 회사에 귀속됩니다. 회원은 서비스를 이용함에 있어 회사의 지적 재산을 침해하지 않도록 주의해야 하며, 회사의 사전 승인 없이 서비스 내용을 변경하거나, 서비스를 타인에게 제공하는 행위 등을 하여서는 안 됩니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제6조 개인정보의 보호 및 사용</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	개인정보의 수집 및 이용</h4>
            <v-col cols="12">
                회사는 서비스의 원활한 제공을 위하여 회원가입 시 회원으로부터 필요한 최소한의 개인정보를 수집합니다. 수집하는 개인정보의 항목, 수집 및 이용목적, 보유 및 이용기간은 회원가입 절차에서 고지하며 회원의 동의를 받습니다.<br>
                회사는 수집한 개인정보를 당초 고지한 범위 내에서만 사용하며, 그 범위를 초과하여 이용하거나 제3자에게 제공하는 경우에는 사전에 회원에게 명시적으로 동의를 얻어야 합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	개인정보의 보호</h4>
            <v-col cols="12">
                회사는 개인정보 보호법 등 관련 법령이 요구하는 바에 따라 회원의 개인정보를 안전하게 관리합니다. 개인정보의 분실, 도난, 유출, 변조 또는 훼손을 방지하기 위한 기술적, 관리적 대책을 마련하고 지속적으로 개선합니다.<br>
                회원은 개인정보의 보호를 위해 자신의 로그인 정보와 비밀번호를 안전하게 관리해야 하며, 이를 소홀히 함으로써 발생하는 모든 결과에 대해 책임을 집니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	개인정보의 수정 및 삭제</h4>
            <v-col cols="12">
                회원은 언제든지 등록되어 있는 자신의 개인정보를 조회하고 수정할 수 있습니다. 또한, 회원은 서비스 이용 해지 시 자신의 개인정보 삭제를 요청할 수 있으며, 회사는 관련 법령에서 정하는 바에 따라 해당 정보를 지체 없이 파기합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	개인정보의 제3자 제공 및 위탁 처리</h4>
            <v-col cols="12">
                회사는 서비스 향상 및 원활한 제공을 위해 필요한 경우 외부 업체에 개인정보 처리를 위탁할 수 있으나, 이 경우 위탁을 받는 업체의 명칭과 위탁하는 업무의 내용을 사전에 회원에게 고지하고 필요한 경우 사전 동의를 받습니다. 또한, 위탁 계약 체결 시 개인정보가 안전하게 처리되도록 관련 법령에 따른 필요한 사항을 계약에 명시하고, 계약 이행을 지속적으로 감독합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	개인정보 보호 책임자</h4>
            <v-col cols="12">
                회사는 개인정보 보호와 관련한 회원의 불만을 처리하고 피해 구제를 위하여 개인정보 보호 책임자를 지정하며, 연락처는 서비스 홈페이지 또는 개인정보 처리방침에서 확인할 수 있습니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제7조 이용의 제한</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	이용 제한의 기준</h4>
            <p style="margin: 10;">
                회사는 회원이 본 약관의 규정을 위반하거나, 서비스의 정상적인 운영을 방해하는 행위를 하였을 경우, 경고, 일시정지, 영구이용정지 등의 조치를 취할 수 있습니다.<br>
                회원이 다음과 같은 행위를 할 경우, 회사는 해당 회원에 대한 서비스 이용 제한 조치를 취할 수 있습니다:
            </p>            
            <v-col cols="12">
                법령 또는 공서양속에 반하는 행위<br>
                회사 또는 다른 회원의 권리나 명예, 신용을 손상시키는 행위<br>
                서비스에 게시된 정보를 변조하거나, 정보를 스팸처럼 대량으로 전송하는 행위<br>
                회사가 제공하는 서비스를 비정상적인 방법으로 이용하거나 시도하는 행위<br>
                바이러스, 코드, 파일, 프로그램 등을 전송하거나 게시하는 행위, 기타 기술적 해킹을 시도하는 행위<br>
                회사 및 기타 제3자의 지적재산권을 침해하는 행위<br>
                정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반하는 행위<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	이용 제한 절차</h4>
            <v-col cols="12">
                회사는 회원의 서비스 이용 제한 사유가 발생한 경우, 회원에게 제한 사유, 제한 유형, 기간 및 이의 제기 방법을 명시하여 통지합니다.<br>
                회원은 이용 제한 통지를 받은 후 정해진 기간 내에 서면 또는 전자우편을 통해 이의를 제기할 수 있으며, 회사는 합리적인 기간 내에 이에 대해 답변을 제공합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	이용 제한의 해제</h4>
            <v-col cols="12">
                이용 제한 기간이 만료되었거나, 회사가 이용 제한 조치의 사유가 해소되었다고 판단하는 경우, 이용 제한을 해제할 수 있습니다.<br>
                회원이 이용 제한 조치 이후 서비스 이용에 대한 자신의 행위를 수정하고 이를 회사에 소명할 경우, 회사는 이용 제한을 재검토할 수 있습니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제8조 서비스 이용시간</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	서비스 이용시간</h4>
            <v-col cols="12">
                서비스의 이용은 회사의 업무나 기술상의 이유로 중단될 필요가 없는 한 연중무휴, 1일 24시간 제공됩니다. 다만, 시스템 정기 점검, 업그레이드, 교체 또는 오류 수정 등 필요한 경우, 회사는 서비스를 일시적으로 중단할 수 있으며, 이 경우 회사는 사전에 웹사이트, 이메일 또는 기타 방법으로 회원에게 통지합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	서비스 중단</h4>
            <p style="margin: 10;">
                회사는 다음과 같은 경우에 서비스 전부 또는 일부의 제공을 중단할 수 있습니다:<br>
            </p>              
            <v-col cols="12">
                컴퓨터, 서버 등 정보통신설비의 보수점검·교체 또는 고장, 통신의 두절 등 운영상 상당한 이유가 있는 경우<br>
                국가비상사태, 정전, 서비스 이용의 폭주 등 예측할 수 없는 이유로 정상적인 서비스 이용에 지장이 있는 경우<br>
                법률상 또는 정부의 명령, 지시, 규제 등에 의해 서비스를 제공할 수 없는 경우<br>
                기타 불가항력적인 사유가 있는 경우<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	서비스 예고 및 통지</h4>
            <v-col cols="12">
                가능한 한, 서비스 중단은 회원에게 사전에 통지됩니다. 사전 통지가 불가능한 경우에는 사후에 즉시 통지하여야 합니다.<br>
                회사는 서비스 중단의 사유 및 기간 등을 회원에게 알릴 의무를 가지며, 이를 위반하여 발생하는 회원의 손해에 대해선 법적 책임을 질 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	서비스 이용제한</h4>
            <v-col cols="12">
                회사는 회원이 서비스를 이용함에 있어 법률, 이 약관, 기타 회사가 정한 이용정책을 위반하거나 정상적인 서비스 운영을 방해하는 경우, 사전 통지 후 서비스 이용을 일시적으로 제한할 수 있습니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제9조 컨텐츠의 저작권과 이용</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	저작권의 귀속</h4>
            <v-col cols="12">
                서비스 내에 포함되어 있는 모든 컨텐츠(텍스트, 그래픽, 로고, 아이콘, 이미지, 오디오 클립, 디지털 다운로드, 데이터 컴파일레이션 등)의 저작권은 회사에 귀속됩니다. 또한, 서비스를 통해 제공되는 컨텐츠 중 회원이 직접 생성한 컨텐츠의 저작권은 해당 컨텐츠의 생성자에게 귀속됩니다.<br>
                회사는 필요한 경우 계약을 통해 타인의 저작물을 서비스에 포함시키며, 해당 저작물의 저작권은 원 저작자에게 귀속됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	컨텐츠의 이용</h4>
            <v-col cols="12">
                회원은 서비스를 통해 접근 가능한 컨텐츠를 개인적인 용도로만 사용할 수 있으며, 회사의 사전 서면 동의 없이 컨텐츠를 수정, 대여, 임대, 대출, 판매, 배포, 제3자에게 제공, 상업적으로 이용하거나 이를 이용하여 서비스를 제공하는 행위를 하여서는 안 됩니다.<br>
                회원이 서비스 내에서 생성하거나 업로드한 컨텐츠에 대해 회원은 회사에게 해당 컨텐츠의 이용권을 부여하게 됩니다. 이는 회사가 서비스의 운영, 확장 및 홍보를 목적으로 해당 컨텐츠를 사용할 수 있도록 하기 위함입니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	저작권 침해 주의</h4>
            <v-col cols="12">
                회원은 서비스 이용 중 다른 회원 또는 제3자의 저작권을 침해하면 안 됩니다. 저작권 침해가 의심되는 경우, 회원은 즉시 해당 컨텐츠의 사용을 중단하고, 필요한 경우 회사에 이를 알려야 합니다.<br>
                회사는 저작권 침해가 확인된 경우, 관련 법률에 따라 필요한 조치를 취할 수 있으며, 해당 회원에 대한 서비스 이용 제한 또는 계약 해지 등의 조치를 취할 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	저작권 보호 조치</h4>
            <v-col cols="12">
                회사는 컨텐츠에 대한 저작권을 보호하기 위해 필요한 기술적, 법적 조치를 취할 수 있으며, 회원은 이러한 조치를 존중하고 이에 따라야 합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제10조 당사의 의무</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	서비스 제공의 의무</h4>
            <v-col cols="12">
                회사는 본 약관 및 관련 법령을 준수하며, 안정적이고 지속적인 서비스 제공을 위하여 최선을 다합니다.<br>
                회사는 시스템의 정기 점검, 보수 및 개선 작업을 통해 서비스 중단을 최소화하고, 발생할 수 있는 문제를 신속히 해결하기 위해 노력합니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	정보의 보안</h4>
            <v-col cols="12">
                회사는 회원의 개인정보를 포함한 모든 정보의 보안 유지에 최선을 다하며, 정보 보안을 위해 기술적, 관리적 대책을 시행합니다.<br>
                회사는 개인정보 보호 관련 법령을 준수하며, 회원의 개인정보가 유출되지 않도록 안전한 보안 시스템을 유지합니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	고객 지원</h4>
            <v-col cols="12">
                회사는 회원의 문의 사항이나 불만을 신속하게 처리하기 위해 고객지원 시스템을 운영합니다.<br>
                회사는 회원의 서비스 이용과 관련된 문의 사항에 대해 적절하고 신속한 답변을 제공할 의무를 가집니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	투명한 운영</h4>
            <v-col cols="12">
                회사는 서비스 운영과 관련된 정책 변경, 서비스 중단, 이용약관의 변경 사항 등을 회원에게 적극적으로 통지합니다.<br>
                회사는 이용약관의 규정을 투명하게 운영하고, 약관 변경 시 회원의 의견을 수렴하며, 변경 사항을 사전에 공지합니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	법령 준수</h4>
            <v-col cols="12">
                회사는 서비스 제공 과정에서 적용되는 모든 국내 법령을 준수하며, 회원의 권리가 침해되지 않도록 노력합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제11조 회원의 의무</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	법령 및 약관의 준수</h4>
            <v-col cols="12">
                회원은 서비스 이용 시 관련 법령, 본 약관의 규정, 서비스와 관련된 고지사항 및 주의사항을 준수하여야 합니다.<br>
                회원은 내부 규정, 운영 정책, 절차 등 회사가 정한 사항을 성실히 이행해야 하며, 이를 어길 시 서비스 이용에 제약을 받을 수 있습니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	정보의 제공 및 변경</h4>
            <v-col cols="12">
                회원은 회원가입 시 진실한 정보를 제공해야 하며, 제공된 정보에 변경이 있는 경우 즉시 갱신해야 합니다.<br>
                허위 정보 제공이나 정보의 미갱신으로 인해 발생하는 불이익에 대해서 회사는 책임지지 않습니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	계정 관리의 책임</h4>
            <v-col cols="12">
                회원은 자신의 계정 및 비밀번호의 관리를 철저히 하여야 하며, 이를 타인에게 양도하거나 대여할 수 없습니다.<br>
                계정의 부정 사용이나 보안 문제가 발생한 경우, 회원은 즉시 회사에 이를 통보하고, 회사의 지시에 따라야 합니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	공공질서 및 미풍양속의 준수</h4>
            <v-col cols="12">
                회원은 서비스 이용 과정에서 공공질서를 해치거나 미풍양속을 저해하는 행위를 하지 않아야 합니다.<br>
                회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승인 없이 상업적으로 이용하거나 제3자에게 제공하는 행위를 금지합니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	지적 재산권의 존중</h4>
            <v-col cols="12">
                회원은 서비스 이용 중에 접근 가능한 컨텐츠의 저작권을 포함한 모든 지적 재산권을 존중해야 하며, 회사 또는 제3자의 지적 재산을 무단으로 사용하거나 도용하지 않아야 합니다.<br>                
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제12조 유료 서비스</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	유료 서비스의 정의</h4>
            <v-col cols="12">
                회사는 특정 서비스 또는 컨텐츠에 대해 유료로 제공하는 서비스를 운영할 수 있으며, 이러한 유료 서비스의 내용, 가격, 이용 방법 및 조건은 서비스 내에서 별도로 명시하고 고지합니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	결제 조건 및 방법</h4>
            <v-col cols="12">
                회원이 유료 서비스를 이용하고자 할 때는 회사에서 정한 결제 수단을 통해 요금을 지불해야 합니다.<br>
                모든 결제는 회원의 명시적인 동의를 거쳐 처리되며, 사용자는 결제 전에 유료 서비스의 내용을 충분히 이해하고 동의하는 것으로 간주됩니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	유료 서비스의 변경 및 환불</h4>
            <v-col cols="12">
                회사는 유료 서비스의 내용, 가격, 이용 조건 등을 변경할 수 있는 권리를 가집니다. 변경된 내용은 서비스 내에 사전 고지하며, 고지 시점 이후의 서비스 이용에 적용됩니다.<br>
                회원은 구매한 유료 서비스에 대해 법령이 정하는 바에 따라 철회 및 환불을 요청할 수 있습니다. 환불 조건, 절차 및 필요한 사항은 서비스 내 또는 구매 시 명시된 환불 정책을 참조하십시오.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	유료 서비스의 이용 제한</h4>
            <v-col cols="12">
                회원이 결제한 유료 서비스를 약관 또는 회사의 정책에 위반하여 사용하는 경우, 회사는 해당 서비스의 이용을 제한하거나 중단할 수 있습니다.<br>
                위반 사항에 대해 회사는 회원에게 통보하며, 이용 제한 또는 중단 조치에 대하여 회원은 회사에 이의를 제기할 수 있습니다.<br>                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	특별 조건</h4>
            <v-col cols="12">
                특정 유료 서비스는 추가적인 이용 조건이나 정책이 적용될 수 있으며, 이 경우 해당 서비스의 이용 전에 별도의 동의를 받을 수 있습니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제13조 결제방식</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	결제 수단</h4>
            <v-col cols="12">
                회사는 회원에게 다양한 결제 수단을 제공합니다. 이용 가능한 결제 수단에는 신용카드, 직불카드, 은행 이체, 전자 지갑, 그리고 기타 온라인 결제 플랫폼이 포함될 수 있습니다.<br>
                제공되는 결제 수단은 서비스의 특성과 회원의 편의를 고려하여 시간에 따라 조정될 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	결제 정보 보안</h4>
            <v-col cols="12">
                회사는 결제 과정에서 회원의 결제 정보를 안전하게 보호하기 위해 최신 보안 기술을 사용합니다.<br>
                회원은 자신의 결제 정보를 안전하게 관리할 책임이 있으며, 무단 사용이 의심되는 경우 즉시 회사에 통보해야 합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	자동 결제</h4>
            <v-col cols="12">
                회원이 유료 서비스에 대해 자동 결제 옵션을 선택한 경우, 이용 기간이 종료되기 전에 해당 서비스 요금이 자동으로 청구됩니다.<br>
                자동 결제 설정은 언제든지 회원이 관리할 수 있으며, 서비스를 이용하려면 회사의 정책에 따라 변경이 가능합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	결제 확인 및 영수증 발행</h4>
            <v-col cols="12">
                결제가 완료되면 회사는 회원에게 결제 확인을 통보하며, 요청 시 영수증을 제공할 수 있습니다.<br>
                회원은 결제 관련 문의 사항이 있을 경우, 회사의 고객 지원 서비스를 이용하여 문의하고 해결할 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	환불 처리</h4>
            <v-col cols="12">
                결제 후 환불 요청은 회사의 환불 정책에 따라 처리됩니다. 환불 가능 여부 및 조건은 유료 서비스의 성격 및 구매 후 경과 시간에 따라 달라질 수 있습니다.<br>
                환불 절차는 투명하게 운영되며, 회원은 환불 절차와 관련된 모든 권리를 이해하고 동의해야 합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제14조 요금제의 변경</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	요금제 변경의 권한</h4>
            <v-col cols="12">
                회사는 서비스의 경제적 조건, 운영 비용의 변동, 시장 상황의 변화 등을 고려하여 언제든지 요금제를 변경할 수 있는 권리를 보유합니다.<br>
                변경된 요금제는 새로운 이용 약정을 체결하거나 기존 이용 약정이 갱신될 때 적용됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	사전 통지</h4>
            <v-col cols="12">
                요금제 변경 시 회사는 변경 내용을 서비스 홈페이지, 이메일 또는 기타 적절한 수단을 통해 회원에게 최소 30일 전에 고지합니다.<br>
                고지된 요금제 변경 내용은 고지 후 30일이 경과한 시점에서 효력이 발생합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	회원의 선택권</h4>
            <v-col cols="12">
                요금제 변경이 공지된 후 회원은 변경 내용의 적용 전까지 서비스 계약을 해지할 권리를 가집니다.<br>
                회원이 고지된 요금제 변경에 동의하지 않을 경우, 회원은 변경 효력 발생일 이전에 서비스 이용 계약을 해지할 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	예외 조항</h4>
            <v-col cols="12">
                특정 요금제에 대해 이미 결제를 완료한 회원은 해당 요금제의 유효 기간 동안은 변경된 요금제의 영향을 받지 않습니다.<br>
                회사는 특별한 사정이 있는 경우, 예를 들어 법적 요구 또는 경제적 긴급 상황에 의해, 통지 기간 없이 요금제를 변경할 수 있으나, 이 경우 회사는 변경된 요금제의 적용에 대해 회원에게 상세한 설명과 함께 적절한 보상 방안을 제공합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제15조 결제취소 및 환불</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	결제 취소 가능성</h4>
            <v-col cols="12">
                회원은 결제를 완료한 후 일정 기간 내에 서비스 이용 시작 전에 결제 취소를 요청할 수 있습니다. 이 기간과 조건은 서비스의 종류에 따라 다르며, 서비스 페이지 또는 결제 화면에서 명확히 고지됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	환불 조건</h4>
            <v-col cols="12">
                결제 취소가 승인된 경우, 회사는 가능한 한 빠르게, 그리고 가능한 모든 수단을 통해 환불을 진행합니다.<br>
                환불 가능성과 기간은 구매한 서비스의 특성, 사용 여부, 결제 방법 등에 따라 다를 수 있으며, 이는 사전에 명시된 환불 정책에 따라 결정됩니다.<br>
                일부 서비스의 경우, 특정 사용 기간이 경과하거나 일정 수준 이상의 이용이 확인된 경우, 환불이 제한될 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	환불 절차</h4>
            <v-col cols="12">
                환불 요청은 회사가 제공하는 고객 지원 또는 서비스 관리 플랫폼을 통해 이루어져야 합니다.<br>
                회원은 환불 요청 시 구매 확인 정보와 결제 취소 사유를 명확히 제공해야 하며, 필요한 경우 추가 정보 제공을 요구받을 수 있습니다.<br>
                환불은 원칙적으로 원 결제 수단을 통해 이루어집니다. 예외적인 상황에서는 다른 방법을 통한 환불이 가능할 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	비환불 조항</h4>
            <v-col cols="12">
                일부 유료 서비스 또는 디지털 콘텐츠의 경우, 서비스의 성격상 환불이 불가능할 수 있습니다. 이러한 비환불 가능 서비스는 구매 전에 명확히 표시되어야 합니다.<br>
                환불 불가능한 서비스에 대해 회원이 구매 결정을 내릴 때는 해당 사항을 신중히 고려해야 합니다.<br>
                
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	분쟁 해결</h4>
            <v-col cols="12">
                환불과 관련하여 발생하는 분쟁은 회사의 고객 서비스 부서를 통해 우선적으로 해결을 모색합니다.<br>
                분쟁 해결에 대한 회사의 결정이 회원의 기대에 부합하지 않는 경우, 법적 조치를 포함한 추가적인 해결 방안을 모색할 수 있습니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제16조 과오금</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	과오금 발생 시 대처</h4>
            <v-col cols="12">
                과오금이란 회원이 지불해야 할 금액보다 과도하게 청구되거나 지급된 경우를 의미합니다.<br>
                회사는 과오금이 발생했음을 인지하거나 회원으로부터 통지를 받은 즉시 해당 사항을 조사하고, 과오금 발생 원인을 규명합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	과오금 환불 절차</h4>
            <v-col cols="12">
                과오금의 원인이 회사에 의한 것으로 확인된 경우, 회사는 지체 없이 과오금을 회원에게 환불합니다.<br>
                환불은 원칙적으로 원 결제 수단으로 이루어지며, 이는 과오금 발생일로부터 최대 30일 이내에 완료됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	회원의 책임</h4>
            <v-col cols="12">
                회원은 과오금이 의심되는 거래를 발견할 경우 즉시 회사에 이를 알려야 합니다.<br>
                과오금 관련 문의 시, 회원은 관련 거래의 증빙 자료 또는 지원 정보를 제공해야 하며, 이는 과오금 조사 및 처리를 신속히 진행하는 데 필요합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	상호 협력</h4>
            <v-col cols="12">
                회사와 회원은 과오금과 관련된 문제 해결을 위해 상호 협력해야 하며, 필요한 모든 정보와 지원을 제공합니다.<br>
                과오금 문제 해결을 위한 회사의 결정은 회원에게 통보되며, 회원은 이에 대한 이의를 제기할 권리를 가집니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	예방 조치</h4>
            <v-col cols="12">
                회사는 과오금을 방지하기 위한 기술적, 관리적 조치를 지속적으로 강화하며, 과오금 발생을 최소화하기 위한 노력을 기울입니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제17조 환불절차</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	환불 요청</h4>
            <v-col cols="12">
                회원은 구매한 서비스의 환불을 원할 경우, 회사가 정한 절차에 따라 환불 요청을 할 수 있습니다.<br>
                환불 요청은 회사의 고객 지원 센터를 통해 온라인으로 제출되어야 하며, 요청 시 필요한 모든 정보를 정확하게 제공해야 합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	환불 조건 검토</h4>
            <v-col cols="12">
                회사는 환불 요청을 받은 후, 구매된 서비스의 환불 조건과 일치하는지 검토합니다.<br>
                환불 요건이 충족되지 않는 경우, 회사는 환불을 거부할 수 있으며, 그 이유를 회원에게 명시적으로 통지합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	환불 실행</h4>
            <v-col cols="12">
                환불 조건이 충족되는 경우, 회사는 지체 없이 환불 절차를 진행합니다.<br>
                환불은 원칙적으로 원 결제 수단으로 이루어지며, 처리 기간은 환불 요청일로부터 최대 30일 이내입니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	비정상적 거래의 처리</h4>
            <v-col cols="12">
                부정 사용이나 결제 사기 등 비정상적인 거래로 인한 환불 요청의 경우, 회사는 추가적인 검증 절차를 진행할 수 있습니다.<br>
                이 경우, 회원은 회사의 요청에 따라 추가 증빙 자료를 제공해야 하며, 이는 환불 결정에 필수적인 요소가 됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	분쟁 해결</h4>
            <v-col cols="12">
                환불과 관련하여 회원과 회사 사이에 분쟁이 발생한 경우, 회사는 내부 분쟁 해결 절차를 통해 문제를 해결하려고 시도합니다.<br>
                분쟁이 지속될 경우, 법적 조치를 포함하여 외부 중재 기관을 통한 해결이 추진될 수 있습니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제18조 분쟁해결</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	분쟁 발생 시 초기 대응</h4>
            <v-col cols="12">
                회원과 회사 간에 서비스 이용과 관련하여 발생하는 모든 분쟁은 우선적으로 양 당사자 간의 협의를 통해 해결을 시도합니다.<br>
                회원은 분쟁과 관련하여 회사에 문의하거나 불만을 제기할 수 있으며, 회사는 이에 대해 신속하고 공정하게 대응합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	내부 분쟁 해결 절차</h4>
            <v-col cols="12">
                회사는 분쟁 해결을 위한 내부 절차를 마련하고 있으며, 이는 회원의 문제를 신속하게 해결하기 위해 설계되었습니다.<br>
                분쟁 해결 절차는 투명하고 접근하기 쉬운 방식으로 운영되며, 모든 관련 정보는 회원에게 제공됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	중재 기관의 이용</h4>
            <v-col cols="12">
                내부 절차를 통해 분쟁이 해결되지 않을 경우, 회사와 회원은 상호 합의 하에 중재 기관 또는 관련 법원에 분쟁 해결을 위임할 수 있습니다.<br>
                회사는 법적 요구 사항에 따라 적절한 중재 기관을 선택하거나 추천할 수 있으며, 회원은 이 과정에 참여할 권리를 가집니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	법적 절차</h4>
            <v-col cols="12">
                모든 중재 절차에도 불구하고, 회원은 법적 권리를 포기하지 않으며, 법적 조치를 취할 수 있는 권리를 유지합니다.<br>
                분쟁이 법원에 제기될 경우, 회사와 회원은 관할 법원의 결정을 준수해야 하며, 법적 절차에 따라 처리됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	문제 해결을 위한 협력</h4>
            <v-col cols="12">
                회사와 회원은 분쟁 해결 과정에서 필요한 모든 정보와 증거를 공유하고 협력해야 합니다.<br>
                이 과정에서 양 당사자는 공정하고 합리적인 태도를 유지하며, 상호 존중의 원칙을 바탕으로 행동합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제19조 손해배상</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	손해배상의 책임</h4>
            <v-col cols="12">
                회사는 서비스 제공 중 발생한 기술적 오류, 시스템 장애, 또는 데이터 유실 등으로 인해 회원이 입은 직접적인 손해에 대하여 손해배상 책임을 집니다.<br>
                회사의 고의 또는 중대한 과실이 없는 한, 간접적, 우발적, 특별한, 또는 결과적 손해에 대해서는 배상 책임을 지지 않습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	손해의 범위와 한계</h4>
            <v-col cols="12">
                손해 배상은 실제로 입증된 손해에 대해서만 이루어지며, 회원이 입은 손해의 정도와 직접적인 연관성을 기준으로 산정됩니다.<br>
                손해배상 청구는 손해 발생을 인지한 날로부터 일정 기간 내에 서면으로 회사에 제출되어야 하며, 이 기간은 법적으로 정해진 기한을 준수합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	손해배상 청구 절차</h4>
            <v-col cols="12">
                회원이 손해배상을 청구할 경우, 회원은 손해의 성격, 규모, 발생 경위 등을 상세히 제공해야 하며, 필요한 경우 증빙 자료를 제출해야 합니다.<br>
                회사는 제출된 자료를 바탕으로 청구의 타당성을 심사하고, 해당 손해에 대한 책임 유무 및 배상액을 결정합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	분쟁의 해결</h4>
            <v-col cols="12">
                손해배상과 관련된 분쟁이 발생할 경우, 제18조의 분쟁해결 절차에 따라 처리됩니다.<br>
                회사와 회원은 손해배상 관련 분쟁을 원만하게 해결하기 위해 필요한 협의와 중재 절차에 성실히 참여합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	보험 및 제3자 책임</h4>
            <v-col cols="12">
                회사는 필요에 따라 적절한 보험에 가입하여 예기치 못한 손해 발생에 대비합니다.<br>
                제3자의 행위에 의해 발생한 손해에 대해서는 해당 제3자에게 책임을 요구할 수 있으며, 회사는 이 과정에서 회원을 지원할 수 있습니다.<br>
            </v-col>

            
            <div class="col-12">
                <h3 class="mb-2">제20조 면책 조항</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	자연재해 또는 불가항력</h4>
            <v-col cols="12">
                회사는 지진, 홍수, 태풍 등의 자연재해 또는 불가항력적 사건으로 인한 서비스의 중단, 지연, 또는 데이터 손실에 대해 책임을 지지 않습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	제3자의 행위</h4>
            <v-col cols="12">
                회사는 제3자가 회사의 통제 범위 외에서 일으킨 행위, 예를 들어 해킹, 바이러스 유포, 기타 사이버 공격 등으로 인한 서비스 장애나 데이터 손실에 대해 면책됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	기술적 한계</h4>
            <v-col cols="12">
                회사는 기술적 한계나 불가피한 사정으로 인해 서비스를 제공하지 못하거나 기대에 미치지 못하는 경우 책임에서 면제됩니다. 단, 회사는 가능한 한 기술적 한계를 극복하기 위해 노력합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	정보의 정확성</h4>
            <v-col cols="12">
                회사는 제공하는 정보의 정확성과 최신성을 보장하기 위해 합리적인 노력을 기울이지만, 정보가 항상 최신이거나 정확함을 보장하지는 않습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	이용자 책임</h4>
            <v-col cols="12">
                회원이 서비스 이용 중에 제공된 정보를 바탕으로 행동을 취하거나 결정을 내릴 경우, 그 결과에 대한 책임은 전적으로 회원에게 있습니다.<br>
                회원은 자신의 로그인 정보와 계정 보안을 관리할 책임이 있으며, 이를 소홀히 함으로써 발생하는 피해에 대해 회사는 책임을 지지 않습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	협력업체 및 제휴 서비스</h4>
            <v-col cols="12">
                회사는 제휴 서비스나 협력업체가 제공하는 서비스의 내용에 대해서는 책임을 지지 않습니다. 회원은 이러한 서비스를 이용함에 있어 제3자의 약관 및 정책을 확인하고 따라야 합니다.<br>
            </v-col>


            <div class="col-12">
                <h3 class="mb-2">제21조 관할법원 및 준거법</h3>
            </div>
            <h4 style="margin: 10;color:#447ec4">•	준거법</h4>
            <v-col cols="12">
                이 약관과 회사의 서비스에 관한 모든 법적 사항은 대한민국 법률을 준거법으로 합니다. 서비스 이용과 관련하여 발생하는 모든 분쟁의 해석과 적용에는 대한민국의 법률이 적용됩니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	관할 법원</h4>
            <v-col cols="12">
                서비스 이용과 관련하여 회사와 회원 간에 발생하는 법적 분쟁에 대해 소송이 제기될 경우, 회사의 본사 소재지를 관할하는 대한민국의 법원을 전속적인 관할 법원으로 합니다.<br>
                단, 국제거래에 따른 분쟁이 발생할 경우, 해당 국가의 법률과 관련 규정을 고려하여 관할 법원이 정해질 수 있습니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	분쟁 해결의 우선 순위</h4>
            <v-col cols="12">
                회사와 회원은 법적 분쟁이 발생하기 전에 상호 협의를 통해 문제를 해결하려는 노력을 우선적으로 기울여야 합니다.<br>
                협의를 통한 문제 해결이 불가능할 경우, 제18조에 따른 분쟁해결 절차가 시행되며, 이 절차에 따라 문제를 해결하기 위해 노력해야 합니다.<br>
            </v-col>
            <h4 style="margin: 10;color:#447ec4">•	국제적 적용</h4>
            <v-col cols="12">
                서비스가 국제적으로 제공되는 경우, 각 국가의 법률이나 규정에 따라 다소 차이가 발생할 수 있으며, 회원은 해당 국가의 법률을 준수할 의무가 있습니다.<br>
                회사는 다양한 국가의 법률 및 규정을 존중하며, 국제 법률에 대한 충돌을 최소화하기 위한 조치를 취합니다.<br>
            </v-col>
    
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
export default {
 
    data: () => ({
    }),

    mounted(){ 
    },

    methods: {

    },
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    border: 2px solid rgb(200, 200, 200);
    letter-spacing: 1px;
    font-family: sans-serif;
    font-size: .8rem;
    width:100%
}
thead {
    background-color: #ffffff;
}
tbody {
    background-color: #ffffff;
}
td,
th {
    border: 1px solid rgb(190, 190, 190);
    padding: 5px 10px;
}

td {
    text-align: center;
}
</style>