<template>
  <v-container style="width:240px" fluid>

    <v-row justify="center" dense>
      <v-col cols="11">
        <div class="info_text" style="color:white">사용하실 컴포넌트를 클릭하거나 스크린에 드랍하세요.</div>
      </v-col>
    </v-row>

      <v-row justify="start" class="mt-1">
        <v-col
          v-for="item in itemList"
          style="cursor: grab;" class="ma-0 pa-0 mt-2" align="center"
          :key="item"
          cols="6"
          >
          <div
            @click="addcomponent(item)"
            @dragstart="($event) => component_dragstart($event, item)"
            style="width: 110px;height:80px;background:white"
            draggable="true"
            class="d-flex flex-column align-center justify-center rounded-lg"
            >
            <v-icon class="mb-2">{{ this.getComponentIcon(item) }}</v-icon>
            <div style="font-size:12px;font-weight:bold">{{ this.getComponentTitle(item) }}</div>
          </div>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import Screen_Model from '@/nextts/model/Screen_Model';
import Component_Model from '@/nextts/model/Component_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';

export default {

components:{
  ThumScreen,
},

//프로퍼티 선언
props:{
  
},  

setup(){
  var paging = ref(null);
  const contentStore = useContentStore();
  return{ paging, contentStore }
},

data(){
  return{
    renderCount:0,

    totalcnt : 0,       //전체 아이템 개수
    page:1,
    pagerow : 20,

    restype:1,
    //카테고리 타입
    restypelist:[
      {name:"ASSETS",  value:1}
    , {name:"IMAGE",   value:2}
    , {name:"VIDEO",   value:3}
    ],
    categoryList:[],
    SEARCH_CATEGORY_IDX:null,
    itemList: [],
  }
},

mounted(){

  this.itemList.splice(0, this.itemList.length);   //데이터 초기화

  //컴포넌트 기본 설정
  const cm1 = new Component_Model();
  cm1.component_type = Component_Model.COMPONENT_TEXT;
  cm1.width = 300;
  cm1.height = 100;
  cm1.textsize = 30;
  cm1.text = "내용을 입력하세요";
  this.itemList.push(cm1);

  const cm4 = new Component_Model();
  cm4.component_type = Component_Model.COMPONENT_TEXTSWITCHER;
  cm4.width = 300;
  cm4.height = 100;
  cm4.textsize = 30;
  cm4.textswitcher_list.push("텍스트 내용1");
  cm4.textswitcher_list.push("텍스트 내용2");
  this.itemList.push(cm4);

  const cm9 = new Component_Model();
  cm9.component_type = Component_Model.COMPONENT_TEXT_SCROLL;
  cm9.width = 300;
  cm9.height = 100;
  cm9.textsize = 30;
  cm9.text = "스크롤 텍스트 내용을 입력하세요.";
  this.itemList.push(cm9);

  const cm10 = new Component_Model();
  cm10.component_type = Component_Model.COMPONENT_DATETIME;
  cm10.width = 300;
  cm10.height = 100;
  cm10.textsize = 30;
  this.itemList.push(cm10);

  const cm2 = new Component_Model();
  cm2.component_type = Component_Model.COMPONENT_IMAGE;
  cm2.width   = 200;
  cm2.height  = 200;
  this.itemList.push(cm2);

  const cm5 = new Component_Model();
  cm5.component_type = Component_Model.COMPONENT_IMAGESWITCHER;
  cm5.width   = 400;
  cm5.height  = 250;
  this.itemList.push(cm5);

  const cm6 = new Component_Model();
  cm6.component_type = Component_Model.COMPONENT_COLOR;
  cm6.width   = 200;
  cm6.height  = 200;
  this.itemList.push(cm6);

  const cm3 = new Component_Model();
  cm3.component_type = Component_Model.COMPONENT_MOVIE;
  cm3.width = 600;
  cm3.height = 400;
  this.itemList.push(cm3);

  const cm7 = new Component_Model();
  cm7.component_type = Component_Model.COMPONENT_YOUTUBE;
  cm7.width = 600;
  cm7.height = 400;
  this.itemList.push(cm7);

  const cm8 = new Component_Model();
  cm8.component_type = Component_Model.COMPONENT_WEBVIEW;
  cm8.width = 600;
  cm8.height = 400;
  this.itemList.push(cm8);

  const cm11 = new Component_Model();
  cm11.component_type = Component_Model.COMPONENT_SOUND;
  cm11.width = 200;
  cm11.height = 200;
  this.itemList.push(cm11);

},

methods:{

  getComponentTitle(item){

    if(item.component_type == Component_Model.COMPONENT_TEXT){
      return "TEXT";
    }else if(item.component_type == Component_Model.COMPONENT_IMAGE){
      return "IMAGE";
    }else if(item.component_type == Component_Model.COMPONENT_TEXTSWITCHER){
      return "TEXT SWITCHER";
    }else if(item.component_type == Component_Model.COMPONENT_IMAGESWITCHER){
      return "IMAGE SWITCHER";
    }else if(item.component_type == Component_Model.COMPONENT_COLOR){
      return "COLOR";
    }else if(item.component_type == Component_Model.COMPONENT_MOVIE){
      return "MOVIE";
    }else if(item.component_type == Component_Model.COMPONENT_YOUTUBE){
      return "YOUTUBE";
    }else if(item.component_type == Component_Model.COMPONENT_WEBVIEW){
      return "WEBVIEW";
    }else if(item.component_type == Component_Model.COMPONENT_TEXT_SCROLL){
      return "TEXT SCROLL";
    }else if(item.component_type == Component_Model.COMPONENT_DATETIME){
      return "DATETIME";
    }else if(item.component_type == Component_Model.COMPONENT_SOUND){
      return "SOUND";
    }else if(item.component_type == Component_Model.COMPONENT_API){
      return "API";
    }else if(item.component_type == Component_Model.COMPONENT_DATALOAD){
      return "DATA LOAD";
    }

  },

  getComponentIcon(item){

    if(item.component_type == Component_Model.COMPONENT_TEXT){
      return "mdi-text-box";
    }else if(item.component_type == Component_Model.COMPONENT_IMAGE){
      return "mdi-image";
    }else if(item.component_type == Component_Model.COMPONENT_TEXTSWITCHER){
      return "mdi-text-box-multiple";
    }else if(item.component_type == Component_Model.COMPONENT_IMAGESWITCHER){
      return "mdi-image-multiple";
    }else if(item.component_type == Component_Model.COMPONENT_COLOR){
      return "mdi-palette";
    }else if(item.component_type == Component_Model.COMPONENT_MOVIE){
      return "mdi-video";
    }else if(item.component_type == Component_Model.COMPONENT_YOUTUBE){
      return "mdi-youtube";
    }else if(item.component_type == Component_Model.COMPONENT_WEBVIEW){
      return "mdi-web";
    }else if(item.component_type == Component_Model.COMPONENT_TEXT_SCROLL){
      return "mdi-format-text-rotation-none";
    }else if(item.component_type == Component_Model.COMPONENT_DATETIME){
      return "mdi-clock-time-eight";
    }else if(item.component_type == Component_Model.COMPONENT_SOUND){
      return "mdi-volume-high";
    }else if(item.component_type == Component_Model.COMPONENT_API){
      return "API";
    }else if(item.component_type == Component_Model.COMPONENT_DATALOAD){
      return "DATA LOAD";
    }

  },  

  movepage(p){
    this.loadpage(p);
  },

  component_dragstart(event, item){
    event.dataTransfer.setData('componentData', JSON.stringify(item));
  },

  async addcomponent(item){

    //var temp = await this.loadImage(item.IMG_PATH);

    //현재 컨텐츠의 넓이와 높이
    const content_width  = this.contentStore.content_model.width;
    const content_height = this.contentStore.content_model.height;

    const component  = new Component_Model();
    component.setStringValue(item);

    //component.component_type = item.component_type;
    //component.image  = item.IMG_PATH;
    //component.width  = item.width;
    //component.height = item.height;

    component.posx   = (content_width-component.width)/2;
    component.posy   = (content_height-component.height)/2;
    component.transx = (content_width-component.width)/2;
    component.transy = (content_height-component.height)/2;
    component.rotate = 0;
    this.contentStore.select_screen.component_list.push(component);
    this.$emitter.emit('drawscreen');

  },

  loadImage(imgurl){
    return new Promise((resolve, reject)=>{
      var tempimg = new Image();
      tempimg.onload = () =>resolve(tempimg);
      tempimg.onerror = reject;
      tempimg.src = imgurl;
    })
  },    



}

}
</script>