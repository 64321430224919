<template>
    <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog_com" 
        :fullscreen="$vuetify.display.mobile ? true : false" 
        :width="$vuetify.display.mobile ? '100%' : '800px'"
        class="none_radius">
        
        <v-card>

            <v-card-title>
                <v-row justify="space-between">
                    <v-col cols="6" md="3">
                    <span class="dialog_title">컨텐츠 선택</span>
                  </v-col>
                  <v-spacer/>
                  <v-col cols="6" md="3" align="end">
                    <v-select 
                      label="카테고리"
                      v-model="SEARCH_CATEGORY_IDX_COM" :items="categoryList_com" 
                      item-title="CATEGORY_NAME" item-value="CATEGORY_IDX"
                      outlined color="#808080" hide-details 
                      density="compact" variant="outlined"
                      @update:modelValue="loadpage_com(1)"
                      />
                  </v-col>
            
                </v-row>
            </v-card-title>

            <v-card-text class="ma-0 pl-5 pr-5 pt-3">
                <v-container>

                    <v-row align="start" justify="center" dense v-if="itemList_com.length > 0">
                        <v-col cols="12" align="center">
                          <div class="d-flex flex-wrap justify-center">
                            <div v-for="item in itemList_com" :key="item" class="ma-1">
                              <template v-if="item != null && item != undefined">
                              <ThumScreen
                                :screen_model="item.screen_model"
                                :scalewidth="200" 
                                :style="select_com == item ? 'border: 1px solid red' : 'border: 1px solid gray'"
                                @click="select_item(item)"
                                />
                              </template>
                            </div>
                          </div>
                        </v-col>

                    </v-row>

                    <!-- 하단검색 -->
                    <searchbar ref="searchbar_com" @searchcallback="search_run_com"/>
                    
                    <!-- 하단페이징영역 -->
                    <pagingbar ref="paging_com" :page="page_com" @loadpage="movepage_com"/>  

                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-row justify="center">
                    <v-col align="center" cols="12" md="10">
                        <v-btn class="mr-5" @click="close_com">닫기</v-btn>
                        <v-btn class="mr-5" @click="clear_com">선택취소</v-btn>
                        <v-btn class=""  @click="save_com">선택</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
  
        </v-card>
    
    </v-dialog>
    

  </template>
  
  <script>
  import { useContentStore } from "@/stores/content";
  import { ref } from '@vue/reactivity';
  import Screen_Model from '@/nextts/model/Screen_Model';
  import ThumScreen from '@/nextvue/preview/ThumScreen.vue';
  
  export default {
  
      components:{
        ThumScreen,
      },
  
      watch:{

      },

      data: () => ({
        
        content_type:0,
        
        dialog_com: false,

        //필수 페이징 파라메터
        page_com : 0,
  
        itemList_com: [],
        select_com:{},
        
        SEARCH_CATEGORY_IDX_COM:null,   
        categoryList_com:[],


        sort_name_com: 'REG_DATE',
        sort_type_com: true,    //true : DESC, false: ASC
        search_keyword_com : '',
  
        //한 페이지 ROW 개수 설정
        pagerow_com : 20,       //한 페이지에 보여줄 row수
  
      }),
  
      setup(){
          var paging_com = ref(null);
          var searchbar_com = ref(null);
          let contentStore   = useContentStore();
          return {paging_com, searchbar_com, contentStore};
      },


      methods: {

        //다이얼로그 닫기
        close_com () {
            this.dialog_com = false
            this.select_com = [];
        },
        
        //선택 초기화
        clear_com () {
            this.$emit('callback', null);
            this.dialog_com = false
            this.select_com = [];
        },        
        
        //추가 저장
        save_com(){

            if(this.content_type == 0){
                if(this.select_com.CON_IDX != null && this.select_com.CON_IDX != undefined){
                    this.$emit('callback', {idx:this.select_com.CON_IDX, name:this.select_com.CON_NAME});
                    //콜백 호출 후 닫아야함.
                    this.dialog_com = false
                    this.select_com = [];
                }else{
                    this.$alert.fire('컨텐츠를 선택하세요');
                    return;
                }
            }else if(this.content_type == 2){
                if(this.select_com.POPUP_IDX != null && this.select_com.POPUP_IDX != undefined){
                    this.$emit('callback', {idx:this.select_com.POPUP_IDX, name:this.select_com.POPUP_NAME});
                    //콜백 호출 후 닫아야함.
                    this.dialog_com = false
                    this.select_com = [];
                }else{
                    this.$alert.fire('팝업을 선택하세요');
                    return;
                }
            }

        },  

        select_item(item){
            this.select_com = item;
        },

        loadcontent(content_type){

            console.log("loadcontent : " + content_type);
            this.content_type = content_type;   //0:컨텐츠, 2:팝업
            
            this.dialog_com = true;
            if(this.content_type == 0){
                this.loadanicategory_com(0);
            }else if(this.content_type == 2){
                this.loadanicategory_com(50);
            }
            
        },


        async loadanicategory_com(ctype){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }
        
            this.$http.post(this.$host+'/CategoryTypeList',{
                search_keyword: ''
                , CATEGORY_TYPE: ctype
                , sort_name: "CATEGORY_SORT"
                , sort_type: "ASC"
            },{headers: { firetoken:token}})
            .then((result)=>{
            
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.categoryList_com.splice(0, this.categoryList_com.length);   //데이터 초기화
                this.categoryList_com.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
                list.forEach(element => {
                    this.categoryList_com.push(element); 
                });
                
                this.SEARCH_CATEGORY_IDX_COM = this.categoryList_com[0].CATEGORY_IDX;
                this.loadpage_com(1);

            }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
            }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
                this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
                this.$alert.fire(error.message);
            }
            });

        },        

        //검색
        search_run_com(searchkeyword){
          if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
            this.search_keyword_com = searchkeyword;
          }else{
            this.search_keyword_com = '';
          }
          this.loadpage_com(1);
        },      
          
        movepage_com(p){
          this.loadpage_com(p);
        },

        customsort_com(sortname){
          this.sort_name_com = sortname;
          this.sort_type_com = !this.sort_type_com;
          this.loadpage_com(this.page_com);
        },
  
        async loadpage_com(p){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.page_com = p;
            this.paging_com.setpage(this.page_com);

            var hosturl = "/ContentList";
            if(this.content_type == 2){
                hosturl = "/PopupList";
            }

            this.$http.post(this.$host + hosturl,{
                search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
              , sort_name:this.sort_name_com
              , sort_type: this.sort_type_com == true ? "DESC" : "ASC" 
              , page: this.page_com
              , pagerow: this.pagerow_com
              , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX_COM
            },{headers: { firetoken:token}})
            .then((result)=>{
              
              if(result.data.resultCode == 0){
                  this.paging_com.makepaging(result.data.totalcnt, this.pagerow_com);
                  const list = result.data.resultData;
                  this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                  list.forEach(element => {
                    let screen_model        = new Screen_Model();
                    screen_model.setStringValue(element.THUM_JSON);
                    screen_model.cacheFont();
                    element.screen_model = screen_model;
                    this.itemList_com.push(element); 
                  });
                  console.log("item list : " + this.itemList_com.length);
                  this.$forceUpdate();
                }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
              this.loading = false;
              if(error.message == 'Request failed with status code 429')
              {
                this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
              }else{
                this.$alert.fire(error.message);
              }
            });
  
        },


      },
    
  }
  </script>
  
  <!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
  <style scoped>
  
  
  
  </style>