import AnimationPlay_Model from "@/nextts/model/AnimationPlay_Model";
import Component_Model from '@/nextts/model/Component_Model';
import EditBasic_ScreenFixed from "../edit/EditBasic_ScreenFixed";
import Gradient_Model from "@/nextts/model/Gradient_Model";

//기본 데이터 속성
class Screen_Model {

    screen_idx:number           = 0;
    screen_name:string          = "";
    next_name:string            = "";

    //위치크기 정보 (기본 정보)
    width:number    = 0;
    height:number   = 0;
    posx:number     = 0;
    posy:number     = 0;
    alpha:number    = 1.0;
    
    //변형 정보 (변형 매트릭스)
    rotate:number   = 0;
    transx:number   = 0;
    transy:number   = 0;

    //기본 외형정보
    bgtype:number           = 0;      //0:color, 1:image
    backgroundColor:string  = "#ffffff";
    backgroundImg:string    = "";


    //그라데이션 컬러 (배경, 폰트, 컬러 공통임)
    //gradient:Gradient_Model;

    //애니매이션 정보 - 화면전환용
    aniplaymodel:AnimationPlay_Model;

    //컴포넌트 리스트 정보
    component_list:Array<Component_Model>;

    //Drawing 객체 - JSON.parse에서 제외시켜야 한다.
    screen:EditBasic_ScreenFixed | null = null;

    //컨텐츠 확인을 위한 대기시간
    trans_time:number = 3;

    constructor(){
        this.component_list = Array<Component_Model>();
        this.aniplaymodel   = new AnimationPlay_Model();
        //this.gradient       = new Gradient_Model();
    }

    setStringValue(params:string):void{
        
        const temp          = JSON.parse(JSON.stringify(params));

        //애니매이션 정보
        if(temp.aniplaymodel != undefined && temp.aniplaymodel != null){
            this.aniplaymodel.setStringValue(temp.aniplaymodel);
        }

        this.screen_idx     = temp.screen_idx;
        this.screen_name    = temp.screen_name;
        this.next_name      = temp.next_name == null || temp.next_name == undefined ? "" : temp.next_name;

        this.width          = temp.width;
        this.height         = temp.height;
        this.posx           = 0;
        this.posy           = 0;
        this.alpha          = temp.alpha;

        this.rotate         = temp.rotate;
        this.transx         = 0;
        this.transy         = 0;

        this.bgtype             = temp.bgtype;
        
        //그라디언트
        /*
        if(temp.gradient != undefined && temp.gradient != null){
            this.gradient.setStringValue(temp.gradient);
        }
        */
        
        this.backgroundColor    = temp.backgroundColor;
        this.backgroundImg      = temp.backgroundImg;
        this.trans_time         = temp.trans_time;
        
        //컴포넌트 정보
        this.component_list.splice(0, this.component_list.length);
        temp.component_list.forEach((component:string) => {
            const cm = new Component_Model();
            cm.setStringValue(component);
            this.component_list.push(cm);
        });
        
    }

    //컴포넌트에 사용된 모든 폰트를 캐싱한다. (썸네일)
    cacheFont(){

        this.component_list.forEach((component) => {
            
            //폰트 처리
            if(component.font_url != null && component.font_url != undefined && component.font_url.length > 0){
            let fonturl = "url('"+component.font_url+"')";
            if(component.font_format != null && component.font_format != undefined && component.font_format.length > 0){
                fonturl += " format('"+component.font_format+"')";
            }
            const fontoption = {weight:component.font_weight.length == 0 ? "normal" : component.font_weight, style:"normal"};
            const fontface = new FontFace(component.textfont, fonturl, fontoption);
            document.fonts.add(fontface);
            }
            
        });

    }


}

export default Screen_Model
