import Component_Interface from '@/nextts/edit/Component_Interface'
import Component_Model from  '@/nextts/model/Component_Model';
import gsap from "gsap";
import Animation_Model from '../model/Animation_Model';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

//기본 데이터 속성
class EditBasic_Element extends HTMLElement implements Component_Interface{
    
    //Component_Interface 구현
    prevtype:number | undefined;   //이전 컴포넌트 타입. 타입이 바뀌면 한번 지워주고 그려라.
    model:Component_Model;
    timeline?:null|GSAPTimeline;

    //SVG 텍스트 전용
    svgElement?: null|SVGSVGElement;
    textPathElement?: null|SVGTextPathElement;
    path?:null|SVGPathElement;
    text?:null|SVGTextElement;
    defs?:null|SVGDefsElement;

    //이미지 마스크용
    base64String?:null|string;
    
    //셀렉트락 처리
    selectlock:boolean = false;

    constructor(cm:Component_Model){
        super();
        this.model = cm;
        if(this.model != null){
            this.prevtype = this.model.component_type;
        }
        

        //좌표체계
        this.style.position = "absolute";

        //movealbe 및 selecto 타겟팅
        this.classList.add("drag");

        //그룹 복원
        if(this.model != null && this.model != undefined){
            if(this.model.isgroup != null && this.model.isgroup && this.model.groupid.length > 0){
                this.classList.add(this.model.groupid);
            }
        }
        
        //이벤트 추가
        this.ondblclick  = this.event_doubleclick;
        this.onkeydown   = this.onkeydownevent;
        this.onkeyup     = this.onkeyupevent;

        this.onmouseover = this.onmouseover_event;
        this.onmouseout = this.onmouseout_event;

        this.updateRender();
        this.updateTransElementAll();
        
    }


    async checkLock(){
        if(this.model.selectlock){
            console.log("check lock...");
            this.selectlock = true;
            this.classList.remove("drag");
            this.contentEditable = "false";
        }
    }


    //Component_Interface 구현 - 컴포넌트 외형 그리기
    async updateRender(){

        //console.log("================ updateRender ===================");
        //console.log("bg : " + this.model.backgroundColor);

        if(this.model == null || this.model == undefined){
            return;
        }

        if(this.prevtype != this.model.component_type){
            //타입이 변경되면 한번 초기화 필요.
            while (this.firstChild) {
                this.removeChild(this.firstChild);
            }                
            this.svgElement = null;
            this.textPathElement = null;
            this.path = null;
            this.text = null;
            this.defs = null;
            this.prevtype = this.model.component_type;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "#00000000";
        this.style.backgroundImage  = "";
        this.style.whiteSpace       = "normal";
        this.style.overflow         = "visible";
        this.style.textShadow       = "";
        this.style.boxShadow        = "";
        this.style.opacity          = String(this.model.opacity);


        /*
        if(this.model.bgtype == 0){
            //color
            this.style.backgroundColor  = this.model.backgroundColor;
            this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.model.bgtype == 1){
            //image
            this.style.backgroundColor  = "#00000000";
            this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.style.backgroundImage  = "url('"+this.model.backgroundImg+"')"
            this.style.backgroundRepeat = "no-repeat";
        }
        */

        //컴포넌트 타입
        if(this.model.component_type == Component_Model.COMPONENT_TEXT || this.model.component_type == Component_Model.COMPONENT_DATETIME){

            this.innerText          = this.model.text;
            this.style.fontFamily   = this.model.textfont;
            this.style.fontSize     = this.model.textsize+'px';
            this.style.textAlign    = this.model.texthori_align;    //left, center, right
            
            //텍스트 세로 정렬
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = this.model.textverti_align;       //top, center, end
            
            this.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.style.lineHeight       = this.model.lineHeight+"%"     
            
            //언더라인
            if(this.model.textUnderline == 0){
                this.style.textDecoration = "none";
            }else{
                this.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.style.fontStyle = "normal";
            }else{
                this.style.fontStyle = "italic";
            }


            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "true";

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
                //this.style.textShadow = "-4px -4px 0 #000,4px -4px 0 #000,-4px 4px 0 #000,4px 4px 0 #000,-8px 0 0 #FF0000,8px 0 0 #FF0000,0 -8px 0 #FF0000,0 8px 0 #FF0000";
            }else{
                this.style.textShadow = "";
            }

            //외각선 처리
            if(this.model.outline_use){
                this.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
            }else{
                this.style.webkitTextStroke = "";
            }

            this.style.backgroundClip  = "";
            this.style.webkitBackgroundClip = "";
            this.style.backgroundImage = "";
            this.style.color = this.model.textcolor;
            this.style.webkitTextFillColor = this.model.textcolor;

            //세로배치시 영문과 특수문자가 옆으로 누워버림.
            //this.style.writingMode = this.model.writingMode;

            if(this.model.component_type == Component_Model.COMPONENT_DATETIME){
                this.contentEditable = "false"; //텍스트 변경 불가
                if(this.model.date_type.length > 0){
                    this.innerText = moment(new Date().toString()).format(this.model.date_type);
                }
            }

        }else if(this.model.component_type == Component_Model.COMPONENT_ARTTEXT){            

            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "true";

            /***************************************************************************
             SVG TEXT를 사용 (연구중, 현재 사용안함.)
             문제1. 리니어 그라데이션이 엉뚱한 좌표로 회전함.
             문제2. 여러개의 SVG TEXT사용시 렌더링이 꼬임.
             문제4. 그림자도 defs로 따로 만들어야함.
            ***************************************************************************/
            if(this.svgElement == null){
                this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                this.defs = document.createElementNS(this.svgElement.namespaceURI, "defs") as SVGDefsElement;
                this.path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                this.text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                this.textPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'textPath');
                this.svgElement.appendChild(this.defs);
                this.svgElement.appendChild(this.path);
                this.svgElement.appendChild(this.text);
                this.text.appendChild(this.textPathElement);
                this.appendChild(this.svgElement);      
            }
           this.svgElement.setAttribute("width", `${this.model.width}`);
           this.svgElement.setAttribute("height", `${this.model.height}`);

            //휘어짐.
            //m이 소문자여야 상대좌표다. 대문자면 절대좌표임. 시작x,y 커브기준점 x,y, 종료x,종료높이 0이면 처음높이 그대로.
            this.path!.setAttribute('d', `m 0 ${(this.model.height/2)+(this.model.textsize/2)+this.model.text_offsetverti} q ${this.model.width/2}, ${this.model.text_arc} ${this.model.width}, 0`)
            this.path!.setAttribute('fill', 'none');
            this.path!.setAttribute('stroke', 'red');
            const textuuid = uuidv4();
            this.path!.setAttribute('id', `${textuuid}`);

            this.text!.style.fontFamily = this.model.textfont;
            this.text!.style.fontSize = `${this.model.textsize}px`;
            this.text!.style.textAlign    = this.model.texthori_align;    //left, center, right

            //텍스트 세로 정렬
            //this.text!.style.display          = "flex";
            //this.text!.style.flexDirection    = "column";
            //this.text!.style.lineHeight       = "120%";
            //this.text!.style.justifyContent   = this.model.textverti_align;       //top, center, end

            this.text!.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.text!.style.lineHeight       = this.model.lineHeight+"%"     

            if(this.model.color_type == "color"){
                this.text!.style.fill       = this.model.textcolor;

            }else if(this.model.color_type == "gradient"){

                //하위 효과 초기화
                while (this.defs && this.defs.firstChild) {
                    this.defs.removeChild(this.defs.firstChild);
                }                
                
                // SVGLinearGradientElement 타입으로 linearGradient 요소 생성
                if(this.model.gradient.type == "linear"){

                    //linear
                    const linearGradient: SVGLinearGradientElement = document.createElementNS(this.svgElement.namespaceURI, "linearGradient") as SVGLinearGradientElement;
                    const gradient_id = uuidv4();
                    linearGradient.setAttribute("id", `${gradient_id}`);
                    linearGradient.setAttribute("gradientTransform", `rotate(${Number(this.model.gradient.degree)-90})`);
                    linearGradient.setAttribute("gradientUnits", "userSpaceOnUse");
                    // SVGStopElement 타입으로 첫 번째 stop 요소 생성
                    for(const point of this.model.gradient.points){
                        const stop: SVGStopElement = document.createElementNS(this.svgElement.namespaceURI, "stop") as SVGStopElement;
                        stop.setAttribute("offset", `${point.left}%`);
                        stop.setAttribute("style", `stop-color:rgb(${point.red},${point.green},${point.blue});stop-opacity:${point.alpha}`);
                        linearGradient.appendChild(stop);
                    }
    
                    // 요소들을 조립
                    this.defs!.appendChild(linearGradient);
                    this.text!.style.fill       = `url(#${gradient_id})`;
                    console.log("linear gradient : " + this.model.gradient.type);

                }else{
                    //radial
                    const radialGradient: SVGRadialGradientElement = document.createElementNS(this.svgElement.namespaceURI, "radialGradient") as SVGRadialGradientElement;
                    const gradient_id = uuidv4();
                    radialGradient.setAttribute("id", `${gradient_id}`);
                    radialGradient.setAttribute("cx", "50%");
                    radialGradient.setAttribute("cy", "50%");
                    radialGradient.setAttribute("r", "10%");
                    // SVGStopElement 타입으로 첫 번째 stop 요소 생성
                    for(const point of this.model.gradient.points){
                        const stop: SVGStopElement = document.createElementNS(this.svgElement.namespaceURI, "stop") as SVGStopElement;
                        stop.setAttribute("offset", `${point.left}%`);
                        stop.setAttribute("style", `stop-color:rgb(${point.red},${point.green},${point.blue});stop-opacity:${point.alpha}`);
                        radialGradient.appendChild(stop);
                    }
                    // 요소들을 조립
                    this.defs!.appendChild(radialGradient);
                    this.text!.style.fill       = `url(#${gradient_id})`;
                }
                
            }else if(this.model.color_type == "image"){
                console.log("image...");
            }
            
            

            //외각선
            if(this.model.outline_use){ 
                this.text!.style.stroke = `${this.model.outline_color}`;
                this.text!.style.strokeWidth = `${this.model.outline_size}`;
                this.text!.style.strokeDasharray = `${this.model.outline_dash1},${this.model.outline_dash2}`;
                this.text!.style.strokeDashoffset = `${this.model.outline_offset}`;
            }else{
                this.text!.style.stroke = "";
                this.text!.style.strokeWidth = "";
            }             

            //언더라인
            if(this.model.textUnderline == 0){
                this.text!.style.textDecoration = "none";
            }else{
                this.text!.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.text!.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.text!.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.text!.style.fontStyle = "normal";
            }else{
                this.text!.style.fontStyle = "italic";
            }


            //input 텍스트처리 (더블클릭시)
            //this.contentEditable = "true";

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                //this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }else{
                //this.style.textShadow = "";
            }

            //anchor가 중앙이면 x좌표를 중앙으로 맞춤.
            
            if(this.model.texthori_align == "left"){
                this.text!.setAttribute("x","0%");
                this.text!.setAttribute("text-anchor","start");
            }else if(this.model.texthori_align == "center"){
                this.text!.setAttribute("x","50%");
                this.text!.setAttribute("text-anchor","middle");
            }else if(this.model.texthori_align == "right"){
                this.text!.setAttribute("x","100%");
                this.text!.setAttribute("text-anchor","end");
            }
            
            if(this.model.textverti_align == "top"){
                this.text!.setAttribute("y","0%");
            }else if(this.model.texthori_align == "center"){
                this.text!.setAttribute("y","50%");
            }else if(this.model.texthori_align == "end"){
                this.text!.setAttribute("y","100%");
            }
            

            //anchor가 중앙이면 x좌표를 중앙으로 맞춤.
            /*
            this.text!.setAttribute("x","50%");
            this.text!.setAttribute("text-anchor","middle");
            */
           
            this.textPathElement!.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${textuuid}`);
            this.textPathElement!.setAttribute('startOffset', `${this.model.text_offset}`);
            this.textPathElement!.textContent = this.model.text;

        }else if(this.model.component_type == Component_Model.COMPONENT_IMAGE){
            //console.log("update render : component type image : " + this.model.image);

            this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.style.backgroundRepeat = "no-repeat";

            if(this.model.api_use){
                const imgPath = require('@/assets/apilogo.png');
                this.style.backgroundImage  = `url(${imgPath})`
                this.style.backgroundSize   = "50%";
                this.style.backgroundPosition = "center";
                this.style.backgroundColor = "white";
            }else{
                if(this.model.image != null && this.model.image != undefined && this.model.image.length > 0){
                    if(this.model.image.toLowerCase().startsWith("http")){
                        //인터넷에 업로드된 이미지면
                        this.style.backgroundImage  = "url('"+this.model.image+"')"
                    }else{
                        this.style.backgroundImage  = `url(${this.model.image})`;
                    }
                }else{
                    //아무것도 없으면
                    const imgPath = require('@/assets/apilogo.png');
                    this.style.backgroundImage  = `url(${imgPath})`
                    this.style.backgroundSize   = "50%";
                    this.style.backgroundPosition = "center";
                    this.style.backgroundColor = "white";
    
                }
            }
            
            this.style.backgroundRepeat = "no-repeat";

            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "false";
            this.innerText = "";

            //마스크 처리
            if(this.model.mask_use){
                this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    
                    if(this.model.mask_image.toLowerCase().startsWith("http")){
                        //인터넷에 업로드된 이미지면
                        try {
                            if(this.base64String == null){
                                this.base64String = await this.convertImageToBase64(this.model.mask_image);
                            }
                            this.style.webkitMaskImage = `url(${this.base64String})`;    
                        } catch (error) {
                            console.error("Error during image conversion to Base64:", error);
                        }                        
                    }else{
                        this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                    }

                }else{
                    this.style.webkitMaskImage = ""; 
                }
                
            }else{
                this.style.webkitMaskImage = "";
                this.style.webkitMaskPosition = "";
                this.style.webkitMaskSize = "";
            }

        }else if(this.model.component_type == Component_Model.COMPONENT_TEXTSWITCHER){

            
            if(this.model.api_use){
                this.innerText          = "API 텍스트 스위처";
            }else{
                if(this.model.textswitcher_list != null && this.model.textswitcher_list.length > 0){
                    this.innerText          = this.model.textswitcher_list[0] as string;
                }else{
                    this.innerText          = "입력된 텍스트가 없습니다.";
                }
            }
    
            this.style.fontFamily   = this.model.textfont;
            this.style.fontSize     = this.model.textsize+'px';
            this.style.color        = this.model.textcolor;
            this.style.textAlign    = this.model.texthori_align;    //left, center, right
            
            //텍스트 세로 정렬
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = this.model.textverti_align;       //top, center, end
            
            this.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.style.lineHeight       = this.model.lineHeight+"%"     
            
            //언더라인
            if(this.model.textUnderline == 0){
                this.style.textDecoration = "none";
            }else{
                this.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.style.fontStyle = "normal";
            }else{
                this.style.fontStyle = "italic";
            }

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }
            
            if(this.model.outline_use){
                this.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
            }

            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_IMAGESWITCHER){

            if(this.model.api_use){
                this.style.backgroundColor  = "#00000000";
                this.style.backgroundImage  = "";
                this.style.backgroundRepeat = "no-repeat";
    
                this.style.color            = "black";
                this.innerText              = "API 이미지 스위처";
                this.style.textAlign        = "center";    
                this.style.display          = "flex";
                this.style.flexDirection    = "column";
                this.style.lineHeight       = "120%";
                this.style.justifyContent   = "center";
            }else{
                if(this.model.imgswitcher_list != null && this.model.imgswitcher_list.length > 0){
                    this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
                    this.style.backgroundRepeat = "no-repeat";
                    if(this.model.imgswitcher_list[0].toLowerCase().startsWith("http")){
                        //인터넷에 업로드된 이미지면
                        this.style.backgroundImage  = "url('"+this.model.imgswitcher_list[0]+"')"
                    }else{
                        this.style.backgroundImage  = `url(${this.model.imgswitcher_list[0]})`;
                    }
                    this.innerText              = "";
                }else{
                    this.style.backgroundColor  = "#00000000";
                    this.style.backgroundImage  = "";
                    this.style.backgroundRepeat = "no-repeat";
                    this.style.fontSize         = '30px';
                    this.style.color            = "black";
                    this.innerText              = "우측 INFO창에서 이미지 리스트에 이미지를 추가세요";
                    this.style.textAlign        = "center";    
                    this.style.display          = "flex";
                    this.style.flexDirection    = "column";
                    this.style.lineHeight       = "120%";
                    this.style.justifyContent   = "center";
                }
            }
            
            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "false";

            //마스크 처리
            if(this.model.mask_use){
                this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.style.webkitMaskImage = "";    
                }
            }else{
                this.style.webkitMaskImage = "";
                this.style.webkitMaskPosition = "";
                this.style.webkitMaskSize = "";
            }


        }else if(this.model.component_type == Component_Model.COMPONENT_COLOR){

            if(this.model.color_type == "color"){
                this.style.backgroundColor  = this.model.backgroundColor;
                //this.style.background       = "";
            }else if(this.model.color_type == "gradient"){
                //this.style.backgroundColor  = "";
                this.style.background       = this.model.backgroundColor;
            }
            
            //this.style.backgroundImage  = ""; //그라디언트 때문에..

            //border-radius 처리
            this.style.borderRadius = `${this.model.border_radius}px`;

            //외각선
            if(this.model.outline_use){ 
                this.style.border = `${this.model.outline_size}px solid ${this.model.outline_color}`;
            }else{
                this.style.border = "";
            }             

            this.style.backgroundRepeat = "no-repeat";
            this.innerText = "";
            this.contentEditable = "false";

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.style.boxShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }

            //마스크 처리
            
            if(this.model.mask_use){
                this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.style.webkitMaskImage = "";    
                }
            }else{
                this.style.webkitMaskImage = "";
                this.style.webkitMaskPosition = "";
                this.style.webkitMaskSize = "";
            }
            


        }else if(this.model.component_type == Component_Model.COMPONENT_MOVIE){

            this.style.backgroundColor  = "black";
            
            const imgPath = require('@/assets/playlogo.png');
            this.style.backgroundImage  = `url(${imgPath})`
            this.style.backgroundSize   = "30%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";
            this.style.color            = "white";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

            //마스크 처리
            if(this.model.mask_use){
                this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.style.webkitMaskImage = "";    
                }
            }else{
                this.style.webkitMaskImage = "";
                this.style.webkitMaskPosition = "";
                this.style.webkitMaskSize = "";
            }

            /*
            if(this.model.movie instanceof File){
                this.innerText              = "MOVIE";
            }else if(this.model.movie != null && this.model.movie.length > 0){
                this.innerText              = "MOVIE";
            }else{
                this.innerText              = "Select Movie";
            }
            */

        }else if(this.model.component_type == Component_Model.COMPONENT_YOUTUBE){
            this.style.backgroundColor  = "black";
            
            const imgPath = require('@/assets/youtubelogo.png');
            this.style.backgroundImage  = `url(${imgPath})`
            this.style.backgroundSize   = "50%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";

            this.style.color            = "white";
            this.innerText              = "";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_WEBVIEW){
            this.style.backgroundColor  = "black";
            
            const imgPath = require('@/assets/homelogo.png');
            this.style.backgroundImage  = `url(${imgPath})`
            this.style.backgroundSize   = "30%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";

            this.style.color            = "white";
            this.innerText              = "";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";
        }else if(this.model.component_type == Component_Model.COMPONENT_ANITEST){
            //this.style.backgroundColor  = "black";

            const imgPath = require('@/assets/staricon.svg');
            this.style.backgroundImage  = `url(${imgPath})`
            //this.style.backgroundSize   = "30%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";
            this.style.color            = "white";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_ANISHOW){
            //this.style.backgroundColor  = "black";
            
            this.onmouseover = null;    

            const imgPath = require('@/assets/staricon.svg');
            this.style.backgroundImage  = `url(${imgPath})`
            //this.style.backgroundSize   = "30%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";
            this.style.color            = "white";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_SOUND){
            this.style.backgroundColor  = "gray";
            const imgPath = require('@/assets/soundlogo.png');
            this.style.backgroundImage  = `url(${imgPath})`
            this.style.backgroundSize   = "80%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";
            this.style.color            = "white";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_API){
            this.style.backgroundColor  = "gray";
            
            const imgPath = require('@/assets/apilogo.png');
            this.style.backgroundImage  = `url(${imgPath})`
            this.style.backgroundSize   = "80%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";
            this.style.color            = "white";
            this.innerText              = "";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_DATALOAD){
            this.style.backgroundColor  = "gray";
            const imgPath = require('@/assets/soundlogo.png');
            this.style.backgroundImage  = `url(${imgPath})`
            this.style.backgroundSize   = "80%";
            this.style.backgroundPosition = "center";

            this.style.backgroundRepeat = "no-repeat";
            this.style.color            = "white";
            this.style.textAlign        = "center";    
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = "center";
            this.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_TEXT_SCROLL){

            this.innerText          = this.model.text;
            this.style.fontFamily   = this.model.textfont;
            this.style.fontSize     = this.model.textsize+'px';
            this.style.color        = this.model.textcolor;
            this.style.textAlign    = this.model.texthori_align;    //left, center, right
            
            //텍스트 줄 바꿈 없음
            this.style.whiteSpace       = "nowrap";
            this.style.overflow         = "hidden";


            //텍스트 세로 정렬
            this.style.display          = "flex";
            this.style.flexDirection    = "column";
            this.style.lineHeight       = "120%";
            this.style.justifyContent   = this.model.textverti_align;       //top, center, end
            
            this.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.style.lineHeight       = this.model.lineHeight+"%"     
            
            //언더라인
            if(this.model.textUnderline == 0){
                this.style.textDecoration = "none";
            }else{
                this.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.style.fontStyle = "normal";
            }else{
                this.style.fontStyle = "italic";
            }

            //input 텍스트처리 (더블클릭시)
            this.contentEditable = "true";

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }

            if(this.model.outline_use){
                this.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
            }
                        

        }

        //셀렉트 락처리
        if(this.selectlock){
            this.checkLock();
        } 


    }


    //이미지 마스크 CORS 우회 처리
    loadMaskImageAsync(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // CORS 정책 준수를 위해 필요
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image at ${url}`));
            img.src = url;
        });
    }

    convertImageToBase64(url: string): Promise<string> {
        return this.loadMaskImageAsync(url).then((img: HTMLImageElement) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Unable to get canvas context');
            }
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            return canvas.toDataURL('image/png');
        }).catch(error => {
            console.error("Error in converting image to Base64:", error);
            throw error;
        });
    }

    updateTransElement(){
        if(this.model == null || this.model == undefined){
            return;
        }
        this.updateRender();
        //this.style.cssText += "transform: translateX("+`${this.model.transx}px`+") translateY("+`${this.model.transy}px`+") rotate("+`${this.model.rotate}deg`+")";
        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;
    }

    updateTransElementAll(){
        if(this.model == null || this.model == undefined){
            return;
        }
        this.updateRender();

        this.style.width  = `${this.model.width}px`;
        this.style.height = `${this.model.height}px`;
        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

        //this.style.cssText += "width:"+`${this.model.width}px`+"; height:"+`${this.model.height}px`+"; transform: translateX("+`${this.model.transx}px`+") translateY("+`${this.model.transy}px`+") rotate("+`${this.model.rotate}deg`+")";
        
    }


    //애니매이션
    playani(){
        if(this.model == null || this.model == undefined){
            return;
        }
        
        if(this.model.aniplaymodel == null || this.model.aniplaymodel == undefined){
            return;
        }

        if(this.model.aniplaymodel.anilist == null || this.model.aniplaymodel.anilist == undefined || this.model.aniplaymodel.anilist.length < 1){
            return;
        }

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);
        //회전, 로테이션, 알파는 

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
        });        

        this.timeline.eventCallback("onComplete", ()=>{
            console.log("animation compltete....");
            this.stopAni();
        });

    }

    //애니매이션
    playEffect(effect:Animation_Model){
        
        if(effect == null || effect == undefined){
            return;
        }

        console.log("playEffect : " + effect);

        const move_posx   = Number(this.model.transx) + Number(effect.movex);
        const move_posy   = Number(this.model.transy) + Number(effect.movey);
        const move_rotate = Number(this.model.rotate) + Number(effect.rotate);
  
        gsap.fromTo(this
          , {x:this.model.transx, y:this.model.transy, scale:1, rotate:this.model.rotate, alpha:this.model.alpha}
          , {delay:effect.delay, duration:effect.duration
            , x:move_posx, y:move_posy, scale:effect.scale, rotate:move_rotate
            , ease:`${effect.ease}.${effect.easetype}`
          }
        );

    }    

    //중지
    stopAni(){
        
        if(this.timeline != null){

            this.timeline.clear();
            this.timeline.to(this, {x:this.model.transx, y:this.model.transy, scale:1, rotate:this.model.rotate, alpha:this.model.alpha, duration:0});

        }

    }


    /*******************************************************************************************************/
    //텍스트 편집 메서드
    /*******************************************************************************************************/

    onmouseover_event(e:MouseEvent){
        if(this.selectlock){
            this.style.outline = "none";
        }else{
            this.style.outline = "2px solid red";
        }
        
    }   

    onmouseout_event(e:MouseEvent){
        this.style.outline = "none";
    }

    //컴포넌트 더블 클릭시
    event_doubleclick(e:MouseEvent){
        
        if(this.model == null || this.model == undefined){
            return;
        }
        //텍스트의 경우에는 포커스를 넣어서 수정하도록 한다.
        if(this.model.component_type == Component_Model.COMPONENT_TEXT || this.model.component_type == Component_Model.COMPONENT_ARTTEXT){
            console.log("double click...text");
            e.preventDefault();
            this.style.pointerEvents = "auto";
            this.focus();
        }
    }
    //편집시 더블클릭으로 포커스를 넣은 후 키입력으로 텍스트 내용 변경
    onkeyupevent(evt:KeyboardEvent){
        //스페이스바 누르면 에디트모드/이동모드가 변경되기 때문에 부모에게 키이벤트 버블링 막기
        evt.stopPropagation();
        if(this.model == null || this.model == undefined){
            return;
        }
        if(this.model.component_type == Component_Model.COMPONENT_TEXT || this.model.component_type == Component_Model.COMPONENT_ARTTEXT){
            
            this.model.text = this.innerText;
        }
    }
    onkeydownevent(evt:KeyboardEvent){
        //스페이스바 누르면 에디트모드/이동모드가 변경되기 때문에 부모에게 키이벤트 버블링 막기
        evt.stopPropagation();
    }

    

}

export default EditBasic_Element
customElements.define('editbasic-element', EditBasic_Element);