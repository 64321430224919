<template>

  <v-container fluid style="background:white;min-height:1000px">

    <v-row justify="center" class="mt-1" >
      <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
        <div id="title2" :class="$vuetify.display.mobile ? 'h2_text_mb' : 'h2_text'">새로운 컨텐츠 만들기</div>
        <div id="title3" :class="$vuetify.display.mobile ? 'h3_text_mb' : 'h3_text'">전문가가 아니여도 전문적인 컨텐츠를 쉽고 빠르게 만들 수 있어요</div>
      </v-col>
    </v-row>

    <v-row justify="space-around" class="mt-10">
      <v-col cols="12" md="8" class="d-flex align-center">
        <v-row>
          <v-col cols="12" md="4">
            <v-sheet
              id="title3_img"
              rounded="xl"
              class="mx-auto"
              height="391"
              width="264" 
              elevation="1"           
              color="ffffff"
              @click="starttemplate"
              >       
              <div style="display:absolute" class="img-hover-zoom">
                <v-img src="@/assets/kiosk_img1.webp" width="264" height="391" cover class="rounded-xl"></v-img>              
                <div style="text-align:center;">템플릿으로 시작하기</div>
                <div style="text-align:center;">1000개 이상의 템플릿</div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-sheet
              id="title3_img"
              rounded="xl"
              class="mx-auto"
              height="391"
              width="264" 
              elevation="0"           
              color="#F2F2F2"
              @click="startcompanytemplate"
              > 
              <div style="display:absolute" class="img-hover-zoom">
                <v-img src="@/assets/kiosk_img2.webp" width="264" height="391" cover class="rounded-xl"></v-img>              
                <div style="text-align:center;">기업 템플릿으로 시작하기</div>
                <div style="text-align:center;">기업에서 만든 템플릿</div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-sheet
              id="title3_img"
              rounded="xl"
              class="mx-auto"
              height="391"
              width="264" 
              elevation="0"           
              color="#F2F2F2"
              @click="startempty"
              >          
              <div style="display:absolute" class="img-hover-zoom">
                <v-img src="@/assets/kiosk_img3.webp" width="264" height="391" cover class="rounded-xl"></v-img>              
                <div style="text-align:center;">처음부터 시작하기</div>
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </v-col>
    </v-row>      

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';

export default {

    data: () => ({

      SEARCH_CATEGORY_IDX:null,   
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      grade:0,

    }),

    setup(){
       let contentStore   = useContentStore();
        return {contentStore};
    },

    mounted(){

      this.SEARCH_LOCALID    = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      console.log("grade :" + this.grade);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {
      
      initpage(){

      },

      starttemplate(){
        this.$router.push({path:"/SelectContent"});
      },

      startcompanytemplate(){
        this.$router.push({path:"/SelectContentCompany"});
      },

      startempty(){
        this.$router.push({path:"/EditContent", query:{type:0, mode:1}});
      }

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>