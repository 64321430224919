
<template>

    <v-row>
      <v-col cols="12">
        <v-card
          color="#f0f0f0" class="overflow-y-auto" max-height="300px">
          <v-card-text>
              <h2 style="text-align: center;">개인정보 처리방침</h2>
              <br>
              <div class="col-12">
                  <p class="mb-2">BARO(kioskbaro.com)를 운영하는 <strong>주식회사 넥스퀘어(이하 "회사")</strong>는 개인정보보호법 제30조에 의거 이용자의 개인정보보호와 권익을 보호하고 관련된 고충 및 애로사항을 신속하게 처리하기 위하여 다음과 같이 개인정보처리방침을 제정하여 공개합니다.</p>
                  <p>회사는 관계법령에서 규정하고 있는 책임과 의무를 준수하고 실천하기 위해 최선의 노력을 하고 있습니다.</p>
              </div>
              <br>
              <h3 style="margin: 10;">목차</h3>
              <p style="margin: 10;">
                  제1조 목적<br>
                  제2조 개인정보의 수집 및 이용에 관한 안내<br>
                  제3조 개인정보자동수집 장치의 설치와 운영거부에 관한 사항<br>
                  제4조 개인정보의 보유·이용기간 및 파기<br>
                  제5조 개인정보 처리 위탁<br>
                  제6조 개인정보의 제3자 제공<br>
                  제7조 개인정보의 국외이전<br>
                  제8조 개인정보의 안전성 확보조치<br>
                  제9조 이용자 및 법정대리인의 권리와 그 행사 방법<br>
                  제10조 개인정보보호 책임자 및 이용자 권익침해에 대한 구제방법<br>
                  제11조 개인정보처리방침 변경에 관한 사항<br>
                  <br>
              </p>
              <br>
  
              <div class="col-12">
                  <h3 class="mb-2">제1조 목적</h3>
              </div>
              <div class="col-12">
                  <p>본 약관은 회사가 제공하는 BARO(https://kioskbaro.com) 웹사이트(이하 '서비스')에서 회원으로 가입하고 이를 이용함에 있어 회사와 회원(약관에 동의한 이용자)의 권리와 의무 및 책임사항을 규정함을 목적으로 합니다.</p><br>
                  <p>당사의 서비스를 사용하는 회원은 아래의 항목에 동의한 것으로 간주합니다.</p><br>
                  <p>1. 서비스 이용약관</p>
                  <p>2. 개인정보 처리방침</p>
                  <p>3. 저작권 규정</p>
              </div>
              <br>
  
                      <h3 style="margin: 10;color:#447ec4">제1조 개인정보의 수집 및 이용에 관한 안내</h3><br>
                      <p style="margin: 10;">
                          <strong>회사는</strong>아래와 같이 서비스를 사용에 따라 개인정보의 수집목적, 항목, 보유 및 이용기간을 달리하여 서비스 제공을 위해 아래와 같은 방법으로 회원의 개인정보를 수집합니다. <br><br>
                          <v-col cols="12">
                          - 회사가 운영하는 웹사이트 이용과정에서 이용자가 직접 개인정보를 입력하는 방식<br>
                          - 서비스 이용 과정에서 이용자와 고객센터간 상담과정 시 정보제공 동의에 의해 수집되는 방식<br>
                          - 서비스를 이용하는 과정에 쿠키, 접속로그 등 자동으로 생성 및 수집되는 방식<br>
                          </v-col>
                      </p>
                      <br>
                      <h4 style="margin: 10;color:#447ec4">•	회원가입 및 서비스 이용</h4><br>
                      <table>
                          <thead>
                              <tr>
                                  <th>수집목적</th>
                                  <th>필수항목</th>
                                  <th>선택항목</th>
                                  <th>보유이용기간</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>회원가입 및 이용자 식별</td>
                                  <td>이름, 이메일주소, 지역</td>
                                  <td style="text-align:left"></td>
                                  <td>회원탈퇴 시 까지</td>
                              </tr>
                              <tr>
                                  <td>서비스 안내 및 상담</td>
                                  <td>이름, 이메일주소</td>
                                  <td style="text-align:left">전화번호, 상담내용</td>
                                  <td>회원탈퇴 시 까지</td>
                              </tr>
                          </tbody>
                      </table>
                      <br><br>
      
                      <h4 style="margin: 10;color:#447ec4">•	서비스 운영 및 관리</h4><br>
                      <table>
                          <thead>
                              <tr>
                                  <th>수집목적</th>
                                  <th>필수항목</th>
                                  <th>선택항목</th>
                                  <th>보유이용기간</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>서비스 상담 및 안내</td>
                                  <td style="text-align:left">이름, 전화번호, 이메일주소, 소속</td>
                                  <td></td>
                                  <td>수집일로부터 6개월까지</td>
                              </tr>
                              <tr>
                                  <td>세금계산서 발행</td>
                                  <td style="text-align:left">회사명, 대표자명, 사업자등록번호, 사업자등록증사본, 입금계좌정보(은행명, 예금주) 담당자 정보(이름, 연락처, 이메일주소)</td>
                                  <td></td>
                                  <td>회원 탈퇴시까지</td>
                              </tr>
                              <tr>
                                  <td>결제</td>
                                  <td style="text-align:left">이름, 휴대전화번호, 카드사 및 카드번호, 결제승인번호, 결제기록</td>
                                  <td></td>
                                  <td>회원 탈퇴시까지</td>
                              </tr>
                          </tbody>
                      </table>
                      <br><br>  
      
                      <p style="margin: 10;">
                          <span style="color:black">“회사”가 수집한 개인정보를 제한적으로 이용하는 것은 아래와 같이 원활한 서비스를 제공하기 위함이며, 이외의 용도로는 이용되지 않습니다. 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다. 또한 이용자가 정보제공을 원치 않을 경우 개인정보 수집 및 제공에 동의하지 않을 수 있으며, 동의 후에도 언제든지 철회할 수 있습니다.</span><br>
                          <v-col cols="12">
                          • 회원가입 페이지를 통해 수집한 회원의 정보는 고객상담 및 서비스 이용등 회사가 필요하다고 판단한 정보에 대하여 원활한 서비스 운영을 위한 범위 내에서만 개인정보를 이용합니다.<br>
                          • 회원의 본인 식별 및 본인의사 확인 및 문의사항 또는 불만사항 처리 등의 회원관리를 위하여 개인정보를 이용합니다.<br>
                          • 회사는 회원에게 보다 다양한 서비스를 제공하고 각종 행사정보(신규 전시회 또는 이벤트) 안내를 위하여 회원으로부터 수집한 정보를 이용하여 회사가 제공하는 이메일 발송 등의 방법으로 광고 또는 마케팅 활동을 수행할 수 있습니다. 이 경우 수신을 원치 않으시면 고객센터에 유선상으로 통보하시거나 고지되는 거부방법을 통하여 해당 서비스를 거절하실 수 있습니다.<br>
                          • 또한 법령 및 이용약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위 및 비인가 행위에 대한 방지 및 제재, 계정도용 및 부정거래 방지, 고지사항 전달, 분쟁 조정을 위한 기록보존 등 이용자 보호 및 서비스 품질향상과 안정적인 운영을 위하여 개인정보를 이용합니다.<br>
                          • 회사가 처리하는 회원정보의 목적과 항목이 변경될 경우에는 관련법령에 따라 사전에 동의를 요청합니다 <br>
                          • 회사는 주민등록번호 처리를 원칙적으로 금지하며, 업무 수행 중 법률, 대통령령, 국회규칙, 대법원규칙, 헌법재판소규칙, 중앙선거관리위원회 규칙 및 감사원규칙에서 구체적으로 주민등록번호의 처리를 요구할 경우에만 처리하고 있습니다.<br>
                          • 회사는 아동의 개인정보를 보호하기 위하여 만 14세 미만 아동의 개인정보를 수집하지 않습니다.<br>
                          </v-col>
                      </p>
      
                      <h3 style="margin: 10;color:#447ec4">제3조 개인정보자동수집 장치의 설치와 운영거부에 관한 사항</h3><br>
                      <p style="margin: 10;">
                          회사는 서비스 이용과정에서 이용자로부터 다음과 같은 정보들이 자동으로 생성/수집되고 다음의 목적으로 이용될 수 있습니다<br>
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	개인정보 자동수집정보 사용목적</h4>
                      <v-col cols="12">
                          - 관련법규의 준수: 회사는 관련법규의 준수를 위해 이용자의 접속기록(로그인)을 보관할 의무가 있습니다.<br><br>
                          - 서비스 품질향상 및 상품 개발: 방문일시, 서비스 이용기록, 접속IP정보, 쿠키, 브라우져 정보, 광고식별자, 이동통신단말기 정보 항목을 수집하며 수집일부터 12개월까지 보유 이용되며 기간 경과 후 즉시 삭제됩니다<br>
                      </v-col>
                      <br>
                      <h4 style="margin: 10;color:#447ec4">•	개인정보 자동수집안내 및 거부방법</h4>
                      <v-col cols="12">
                          - 아래의 분석도구를 활용하여 이용자의 주요행동(행태정보)를 수집 및 분석합니다.  수집된 정보로 개인을 알아 볼 가능성은 낮습니다.
                          <table>
                              <tbody>
                                  <tr>
                                      <td>수탁사명</td>
                                      <td style="text-align:left">서비스 이용통계(분석도구: 구글 애널리틱스, 네이버 애널리틱스)</td>
                                  </tr>
                              </tbody>
                          </table><br>
                          - 쿠키의 설치운영 및 거부 방법 : 아래 방법을 통해 쿠키 저장을 거부 할 수 있습니다.
                          <table>
                              <tbody>
                                  <tr>
                                      <td>Internet Explorer</td>
                                      <td style="text-align:left">웹브라우저 상단의 도구 > 인터넷 옵션 > 개인정보 메뉴의 옵션 설정</td>
                                  </tr>
                                  <tr>
                                      <td>Microsoft Edge</td>
                                      <td style="text-align:left">웹브라우저 상단 메뉴 > 설정 > 고급 설정 보기 > 쿠키 메뉴의 옵션 설정</td>
                                  </tr>
                                  <tr>
                                      <td>Chrome 웹브라우저</td>
                                      <td style="text-align:left">웹브라우저 상단 메뉴 > 설정 > 고급 > 콘텐츠 설정 > 쿠키 메뉴의 옵션 설정</td>
                                  </tr>
                                  <tr>
                                      <td>Chrome 모바일</td>
                                      <td style="text-align:left">크롬 App > 오른쪽상단 더보기 > 방문 기록 인터넷 사용 기록 삭제 > 기간선택 > 쿠키 및 사이트 데이터'와 '캐시된 이미지 또는 파일' 옆의 체크박스를 선택 > 인터넷 사용기록 삭제</td>
                                  </tr>
                                  <tr>
                                      <td>Safari 모바일</td>
                                      <td style="text-align:left">Safari App > 방문기록 및 웹사이트 데이터 지우기 > 확인</td>
                                  </tr>
                                  <tr>
                                      <td>Naver 모바일</td>
                                      <td style="text-align:left">Naver App > 설정 > 캐시삭제 + 인터넷 사용 기록 > 쿠키삭제</td>
                                  </tr>
      
                              </tbody>
                          </table>
                      </v-col>
                      <br>
      
                      <h3 style="margin: 10;color:#447ec4">제4조 개인정보의 보유·이용기간 및 파기</h3><br>
                      <p style="margin: 10;">
                          회사가 수집한 개인정보는 이용자로부터 개인정보 수집 시에 동의 받은 개인정보 보유·이용기간 내 또는 관련법령에 따른 개인정보 보유·이용기간 내 처리하고 해당 목적이 달성 및 보유이용기간이 경과할 시에는 지체없이 해당 개인정보를 복구 또는 재생할 수 없는 방법으로 파기합니다.<br>
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	이용자 정보</h4>
                      <v-col cols="12">
                          - 이용자가 서비스 회원을 탈퇴할 경우 회사는 즉시 개인정보를 삭제합니다. 단, 회원을 탈퇴하더라도 관계 법령에서 개인정보를 보존해야 할 필요가 있는 경우, 해당 법률의 규정에 따릅니다.<br><br>
                          - 이용자가 개인정보 수집 이용에 관한 동의를 철회하는 경우, 회사는 수집한 이용자의 개인정보를 지체없이 파기합니다.<br><br>
                      </v-col>
                      <br>
                      <h4 style="margin: 10;color:#447ec4">•	관련법령에 따른 의무 보유기간</h4>
                      <table>
                          <thead>
                              <tr>
                                  <th>보유·이용목적</th>
                                  <th>근거법령</th>
                                  <th>보유·이용기간</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>법원의 영장을 받아 수사기관 요청 시</td>
                                  <td>통신비밀 보호법</td>
                                  <td>3개월</td>
                              </tr>
                              <tr>
                                  <td>표시•광고에 관한 기록</td>
                                  <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                                  <td>6개월</td>
                              </tr>
                              <tr>
                                  <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                  <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                                  <td>5년</td>
                              </tr>
                              <tr>
                                  <td>계약 또는 청약철회 등에 관한 기록</td>
                                  <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                                  <td>5년</td>
                              </tr>
                              <tr>
                                  <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                  <td>전자상거래 등에서 소비자보호에 관한 법률</td>
                                  <td>3년</td>
                              </tr>
                              <tr>
                                  <td>거래내역 및 증빙서류와 관련된 정보</td>
                                  <td>국세기본법, 법인세법</td>
                                  <td>5년</td>
                              </tr>
                          </tbody>
                      </table>
                      <br><br>
      
                      <h3 style="margin: 10;color:#447ec4">제5조 개인정보 처리 위탁</h3><br>
                      <p style="margin: 10;">
                          회사는 원활한 서비스 제공 및 개인정보 업무처리를 위하여 이용자의 일부 개인정보처리업무를 위탁하고 있으며 위탁 계약 체결 시 관련법령에 따라 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 위탁업무의 내용이나 수탁자가 추가, 변경될 경우에는 지체 없이 관련 법령에 따른 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다<br>
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	개인정보처리 위탁업무 및 수탁사 안내</h4>
                      <table>
                          <thead>
                              <tr>
                                  <th>수탁업체</th>
                                  <th>위탁업무의 내용</th>
                                  <th>연락처</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>나이스평가정보㈜</td>
                                  <td>본인인증</td>
                                  <td>niceid@nice.co.kr</td>
                              </tr>
                              <tr>
                                  <td>(주)포트원</td>
                                  <td>전자결재 대행</td>
                                  <td>cs@portone.io</td>
                              </tr>
                              <tr>
                                  <td>토스 페이먼츠</td>
                                  <td>전자결재 대행</td>
                                  <td>1544-7772</td>
                              </tr>
                              <tr>
                                  <td>네이버 N클라우드</td>
                                  <td>대량 이메일(뉴스레터) 발송 서비스</td>
                                  <td>1544-5876</td>
                              </tr>
                              <tr>
                                  <td>채널톡</td>
                                  <td>고객 상담 시스템 운영</td>
                                  <td>https://channel.io/ko</td>
                              </tr>
                              <tr>
                                  <td>파이어베이스</td>
                                  <td>푸쉬알림 서비스 운영</td>
                                  <td>https://firebase.google.com/support</td>
                              </tr>
                              <tr>
                                  <td>구글</td>
                                  <td>앱 회원가입 및 이용자 식별</td>
                                  <td>googlekrsupport@google.com</td>
                              </tr>
      
                          </tbody>
                      </table>
                      <br><br>
      
                      <h3 style="margin: 10;color:#447ec4">제6조 개인정보의 제3자 제공</h3><br>
                      <p style="margin: 10;">
                          회사는 회원의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	이용자가 사전에 동의한 경우</h4>
                      <h4 style="margin: 10;color:#447ec4">•	관련법령에 근거한 사전동의 없은 제3자 제공안내</h4>
                      <p style="margin: 10;">
                          <v-col cols="12">
                              - 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
                              - 관계법령에 의하여 국가기관으로부터 요구받은 경우<br>
                              - 범죄에 대한 수사상의 목적이 있거나, 정보통신 윤리위원회의 요청이 있는 경우<br>
                              - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우<br>
                          </v-col>
                      </p>
                      <br>
      
                      <h3 style="margin: 10;color:#447ec4">제7조 개인정보의 국외이전</h3><br>
                      <p style="margin: 10;">
                          •	회사는 개인정보를 국외의 다른 사업자에게 제공하지 않습니다. 다만, 추후 정보통신서비스의 제공에 관한 계약 이행 및 이용자 편의 증진 등을 위하여 개인정보 처리 업무를 국외에 전송할 수 있으며, 이 경우 사전 동의 안내 또는 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
                      </p>
      
                      <h3 style="margin: 10;color:#447ec4">제8조 개인정보의 안전성 확보조치</h3><br>
                      <p style="margin: 10;">
                          회사는 이용자의 개인정보를 안전하게 관리하여 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 최선을 다하고 있으며 필요한 기술적, 관리적 및 물리적 조치를 하고 있습니다.
                      </p>
                      <v-col cols="12">
                      <h4 style="margin: 10;color:#447ec4">•	개인정보 취급 직원의 최소화 및 교육</h4>
                      <p style="margin: 10;">
                          개인정보를 취급하는 직원을 최소화하고, 주기적인 개인정보 보호 교육을 실시하여 개인정보를 관리하는 대책을 시행하고 있습니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	내부 관리계획의 수립 및 시행</h4>
                      <p style="margin: 10;">
                          개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	접속 기록의 보관 및 위변조 방지</h4>
                      <p style="margin: 10;">
                          개인정보 침해사고 발생 시 대응이 용이하도록 개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	개인정보의 암호화</h4>
                      <p style="margin: 10;">
                          이용자의 개인정보는 암호화되어 저장 및 관리되고 있습니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	해킹 등에 대비한 기술적 대책</h4>
                      <p style="margin: 10;">
                          회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 합니다. 또한 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	개인정보에 대한 접근통제 및 제한</h4>
                      <p style="margin: 10;">
                          개인정보를 처리하는 개인정보처리시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위한 조치를 하고 있습니다.
                      </p>
                      </v-col>
      
                      <h3 style="margin: 10;color:#447ec4">제9조 이용자 및 법정대리인의 권리와 그 행사 방법</h3><br>
                      <p style="margin: 10;">
                          회사는 이용자(또는 법정대리인)의 개인정보 권리를 보호하기 위해 아래와 같이 행사 방법을 마련하고 있습니다.
                      </p>
                      <v-col>
                          <h4 style="margin: 10;color:#447ec4">•	이용자의 권리 및 행사방법</h4>
                          <p style="margin: 10;">
                              - 열람 또는 수정: (웹사이트) 마이페이지 > 등록정보 확인 및 수정<br>
                              - 회원탈퇴 또는 삭제요청: (앱) 마이페이지 > 회원탈퇴<br><br>
                              - 그 밖에 서면, 전자우편 등을 통하여 개인정보의 처리 정지 및 삭제를 요구할 수 있습니다.<br><br>
                              - 회사는 개인정보의 오류 등에 대한 정정 또는 삭제를 요청한 경우에는 정정 또는 삭제를 완료하기 전까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br><br>
                              - 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 당해 개인정보의 삭제를 요구할 수 없습니다.<br><br>
                              - 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인인지를 확인합니다.<br><br>
                          </p>
                          <h4 style="margin: 10;color:#447ec4">•	법정대리인의 권리 및 행사방법</h4>
                          <p style="margin: 10;">
                              - 이용자의 법정대리인이나 위임을 받은 자 등 대리인이 이용자의 권리(열람, 정정, 처리정지, 삭제)를 행사하는 경우 개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다.<br>
                              - 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 정당한 대리인인지를 확인합니다.<br>
                          </p>
                      </v-col>
                      
                      <h3 style="margin: 10;color:#447ec4">제10조 개인정보보호 책임자 및 이용자 권익침해에 대한 구제방법</h3><br>
                      <p style="margin: 10;">
                          회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자를 지정하고 있습니다.
                      </p>
                      <table>
                          <thead>
                              <tr>
                                  <th>개인정보 보호 책임자</th>
                                  <th>직책</th>
                                  <th>연락처</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>라현성</td>
                                  <td>CEO</td>
                                  <td>070-8098-9102<br>dev@necsquare.com</td>
                              </tr>
                          </tbody>
                      </table>
                      <h4 style="margin: 10;color:#447ec4">•	개인정보보호 책임자의 역할</h4>
                      <p style="margin: 10;">
                          이용자는 서비스를 이용하면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보보호책임자에게 문의하실 수 있습니다. 회사는 이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                      </p>
                      <h4 style="margin: 10;color:#447ec4">•	권익침해관련 도움받을 수 있는 기관</h4>
                      <p style="margin: 10;">
                          이용자는 아래의 기관에 대해 개인정보 침해에 대한 피해규제 및 상담을 받으실 수 있습니다.<br>
                          권익침해 관련 자세한 도움이 필요하시면 아래 기관에 문의하여 주시기 바랍니다.<br>
                      </p>
      
                      <table>
                          <thead>
                              <tr>
                                  <th>구분</th>
                                  <th>내용</th>
                                  <th>비고</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>개인정보 침해신고센터<br>(한국인터넷진흥원 운영)</td>
                                  <td style="text-align:left">
                                      - 소관업무: 개인정보 침해사실 신고, 상담 신청<br>
                                      - 홈페이지: privacy.kisa.or.kr<br>
                                      - 전화: (국번없이) 118<br>
                                      - 주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2 3층 개인정보침해신고센터
                                  </td>
                                  <td></td>
                              </tr>
                              <tr>
                                  <td>개인정보 분쟁조정위원회</td>
                                  <td style="text-align:left">
                                      - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br>
                                      - 홈페이지: www.kopico.go.kr<br>
                                      - 전화: (국번없이) 1833-6972<br>
                                      - 주소: (03171) 서울특별시 종로구 세종대로 209 정부서울청사 4층
                                  </td>
                                  <td></td>
                              </tr>
                              <tr>
                                  <td>대검찰청 사이버범죄수사단</td>
                                  <td style="text-align:left">- 전화 : 02-3480-3573<br>- 홈페이지 : www.spo.go.kr</td>
                                  <td></td>
                              </tr>
                              <tr>
                                  <td>경찰청 사이버안전국</td>
                                  <td style="text-align:left">- 전화 : 182<br>- 홈페이지 : cyberbureau.police.go.kr</td>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
      
                      <br>
                      <h3 style="margin: 10;color:#447ec4">제11조 개인정보처리방침 변경에 관한 사항</h3><br>
                      <p style="margin: 10;">
                          개인정보처리방침은 시행일로부터 적용되며, 관련법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 지체없이 홈페이지를 통하여 고지할 것입니다. 아래 셀렉트 박스를 클릭하시면 이전 버전의 개인정보처리방침을 볼 수 있습니다.
                      </p>
      
                      <p style="margin: 10;">
                          처리방침 시행일 2024-05-30
                      </p>
      
                      <br>
  
      
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  
  </template>
  
  <script>
  export default {
   
      data: () => ({
      }),
  
      mounted(){ 
      },
  
      methods: {
  
      },
  }
  </script>
  
  <style scoped>
  table {
      border-collapse: collapse;
      border: 2px solid rgb(200, 200, 200);
      letter-spacing: 1px;
      font-family: sans-serif;
      font-size: .8rem;
      width:100%
  }
  thead {
      background-color: #ffffff;
  }
  tbody {
      background-color: #ffffff;
  }
  td,
  th {
      border: 1px solid rgb(190, 190, 190);
      padding: 5px 10px;
  }
  
  td {
      text-align: center;
  }
  </style>