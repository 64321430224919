
import Component_Model from  '@/nextts/model/Component_Model';
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';
import gsap from "gsap";
import Content_Model from '@/nextts/model/Content_Model';
import { v4 as uuidv4 } from 'uuid';

//기본 데이터 속성
class Play_ArtText extends HTMLElement implements Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;
    
    //SVG 텍스트 전용
    svgElement?: null|SVGSVGElement;
    textPathElement?: null|SVGTextPathElement;
    path?:null|SVGPathElement;
    text?:null|SVGTextElement;
    defs?:null|SVGDefsElement;

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.position = "absolute";
    }
    
    //Play_Interface 구현
    init(): void {
        //console.log("Play Text init");
        //커스텀 api등을 통해야 하는 경우에는 분기하고

        //컴포넌트 시작
        this.playAni();

    }

    exit(): void {
        
        //효과음 초기화 (일단 패스)

        //타이머 초기화

        //모든 자식 svg 제거
        while (this.firstChild) {
            this.removeChild(this.firstChild);
        }                

        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        
        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
        }


    }

    playAni(){
        
        if(this.model == null) return;

        //애니가 있으면 offsettime만큼 대기 후 실행
        if(this.model.aniplaymodel != null && this.model.aniplaymodel.anilist != null && this.model.aniplaymodel.anilist.length > 0){
            //첫번째 이펙트 대기시간 만큼 대기 후 뷰를 그리고 애니를 시작한다.
            //첫번째 delay는 여기서 사용했기 때문에 runAni에서는 첫번째 deplay는 무조건 0으로 처리해야한다.
            setTimeout(() => {
               this.drawView();
               this.runAni();
            }, this.model.aniplaymodel.anilist[0].delay * 1000);

        }else{
            //애니 없음
            this.drawView();
        }

    }

    //애니매이션 실행
    runAni(){
        //console.log("Play Text run ani");
        if(this.model == null) return;

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);
        //회전, 로테이션, 알파는 

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
            
        });        

    }


    drawView(){

        //console.log("================ Play_Text drawView ===================");
        if(this.model == null || this.model == undefined){
            return;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "#00000000";
        this.style.backgroundImage  = "";
        this.style.whiteSpace       = "normal";
        this.style.overflow         = "hidden";
        this.style.textShadow       = "";
        this.style.boxShadow        = "";
        this.style.opacity          = String(this.model.opacity);

        if(this.svgElement == null){
            this.svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            this.defs = document.createElementNS(this.svgElement.namespaceURI, "defs") as SVGDefsElement;
            this.path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            this.text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            this.textPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'textPath');
            this.svgElement.appendChild(this.defs);
            this.svgElement.appendChild(this.path);
            this.svgElement.appendChild(this.text);
            this.text.appendChild(this.textPathElement);
            this.appendChild(this.svgElement);      
        }
       this.svgElement.setAttribute("width", `${this.model.width}`);
       this.svgElement.setAttribute("height", `${this.model.height}`);

        //휘어짐.
        //m이 소문자여야 상대좌표다. 대문자면 절대좌표임. 시작x,y 커브기준점 x,y, 종료x,종료높이 0이면 처음높이 그대로.
        this.path!.setAttribute('d', `m 0 ${(this.model.height/2)+(this.model.textsize/2)+this.model.text_offsetverti} q ${this.model.width/2}, ${this.model.text_arc} ${this.model.width}, 0`)
        this.path!.setAttribute('fill', 'none');
        //this.path!.setAttribute('stroke', 'red');
        const textuuid = uuidv4();
        this.path!.setAttribute('id', `${textuuid}`);

        this.text!.style.fontSize = `${this.model.textsize}px`;
        if(this.model.color_type == "color"){
            this.text!.style.fill       = this.model.textcolor;

        }else if(this.model.color_type == "gradient"){

            //하위 효과 초기화
            while (this.defs && this.defs.firstChild) {
                this.defs.removeChild(this.defs.firstChild);
            }                
            
            // SVGLinearGradientElement 타입으로 linearGradient 요소 생성
            if(this.model.gradient.type == "linear"){

                //linear
                const linearGradient: SVGLinearGradientElement = document.createElementNS(this.svgElement.namespaceURI, "linearGradient") as SVGLinearGradientElement;
                const gradient_id = uuidv4();
                linearGradient.setAttribute("id", `${gradient_id}`);
                linearGradient.setAttribute("gradientTransform", `rotate(${Number(this.model.gradient.degree)-90})`);
                linearGradient.setAttribute("gradientUnits", "userSpaceOnUse");
                // SVGStopElement 타입으로 첫 번째 stop 요소 생성
                for(const point of this.model.gradient.points){
                    const stop: SVGStopElement = document.createElementNS(this.svgElement.namespaceURI, "stop") as SVGStopElement;
                    stop.setAttribute("offset", `${point.left}%`);
                    stop.setAttribute("style", `stop-color:rgb(${point.red},${point.green},${point.blue});stop-opacity:${point.alpha}`);
                    linearGradient.appendChild(stop);
                }

                // 요소들을 조립
                this.defs!.appendChild(linearGradient);
                this.text!.style.fill       = `url(#${gradient_id})`;
                console.log("linear gradient : " + this.model.gradient.type);

            }else{
                //radial
                const radialGradient: SVGRadialGradientElement = document.createElementNS(this.svgElement.namespaceURI, "radialGradient") as SVGRadialGradientElement;
                const gradient_id = uuidv4();
                radialGradient.setAttribute("id", `${gradient_id}`);
                radialGradient.setAttribute("cx", "50%");
                radialGradient.setAttribute("cy", "50%");
                radialGradient.setAttribute("r", "10%");
                // SVGStopElement 타입으로 첫 번째 stop 요소 생성
                for(const point of this.model.gradient.points){
                    const stop: SVGStopElement = document.createElementNS(this.svgElement.namespaceURI, "stop") as SVGStopElement;
                    stop.setAttribute("offset", `${point.left}%`);
                    stop.setAttribute("style", `stop-color:rgb(${point.red},${point.green},${point.blue});stop-opacity:${point.alpha}`);
                    radialGradient.appendChild(stop);
                }
                // 요소들을 조립
                this.defs!.appendChild(radialGradient);
                this.text!.style.fill       = `url(#${gradient_id})`;
            }
            
        }else if(this.model.color_type == "image"){
            console.log("image...");
        }
        
        this.text!.style.fontFamily = this.model.textfont;

        //외각선
        if(this.model.outline_use){ 
            this.text!.style.stroke = `${this.model.outline_color}`;
            this.text!.style.strokeWidth = `${this.model.outline_size}`;
            this.text!.style.strokeDasharray = `${this.model.outline_dash1},${this.model.outline_dash2}`;
            this.text!.style.strokeDashoffset = `${this.model.outline_offset}`;
        }else{
            this.text!.style.stroke = "";
            this.text!.style.strokeWidth = "";
        }             

        //input 텍스트처리 (더블클릭시)
        //this.contentEditable = "true";

        //그림자 우측, 아래, 블러범위, 색상
        if(this.model.shadow_use){
            //this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
        }else{
            //this.style.textShadow = "";
        }

        //언더라인
        if(this.model.textUnderline == 0){
            this.text!.style.textDecoration = "none";
        }else{
            this.text!.style.textDecoration = "underline";
        }
        
        //볼드
        if(this.model.textBold == 0){
            this.text!.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
        }else{
            this.text!.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
        }

        //이탤릭
        if(this.model.textItalic == 0){
            this.text!.style.fontStyle = "normal";
        }else{
            this.text!.style.fontStyle = "italic";
        }        
        
        //anchor가 중앙이면 x좌표를 중앙으로 맞춤.
        this.text!.setAttribute("x","50%");
        this.text!.setAttribute("text-anchor","middle");

        this.textPathElement!.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${textuuid}`);
        this.textPathElement!.setAttribute('startOffset', `${this.model.text_offset}`);
        this.textPathElement!.textContent = this.model.text;

        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;

    }

}

export default Play_ArtText
customElements.define('play-arttext', Play_ArtText);