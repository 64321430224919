<template>
  <v-container fluid class="ma-0 pa-0" style="width:240px;background:white;height:100%;">
    <v-tabs 
      v-model="tab"
      fixed-tabs style="background:#f0f0f0;height:40px"
      >
      <div class="d-flex align-center justify-space-evenly w-100" style="background:white;border-bottom: 1px solid #e6e6e6;">
        <div @click="tab=1" :class="tab == 1 ? 'tab_title_select' : 'tab_title'">INFO</div>
        <div v-if="this.$storage.getStorageSync('GRADE') > 90" @click="tab=2" :class="tab == 2 ? 'tab_title_select' : 'tab_title'">ANI</div>
        <div @click="tab=4" :class="tab == 4 ? 'tab_title_select' : 'tab_title'">ANI</div>
        <div @click="tab=3" :class="tab == 3 ? 'tab_title_select' : 'tab_title'">MY</div>
      </div>
    </v-tabs>
    <v-window v-model="tab">
        <v-window-item :value="1">
          <Info_Panel ref="info_panel" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-window-item>
        <v-window-item v-if="this.$storage.getStorageSync('GRADE') > 90" :value="2">
          <Info_Animation ref="info_animation" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-window-item>
        <v-window-item :value="4">
          <Info_Anitemp ref="info_anitemp" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-window-item>
        <v-window-item :value="3">
          <Info_My ref="my_animation" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-window-item>
    </v-window>
  </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import Info_Panel from '@/nextvue/infopanel/Info_Panel.vue';
import Info_Animation from '@/nextvue/infopanel/Info_Animation.vue'
import Info_My from '@/nextvue/infopanel/Info_My.vue'
import Info_Anitemp from '@/nextvue/infopanel/Info_Anitemp.vue'

export default {
  
  setup(){
    let info_panel      = ref(null);
    let info_animation  = ref(null);
    let info_my         = ref(null);
    let info_anitemp  = ref(null);
    let windowHeight    = ref(window.innerHeight-110);
    return {info_panel, info_animation, info_my, info_anitemp, windowHeight}
  },

  components:{
    Info_Panel, Info_Animation, Info_My, Info_Anitemp
  },

  //프로퍼티 선언
  props:{
    panel_height:{
      type:Number,
      default(){
        return window.innerHeight-100;
      }
    },
  },  

  data(){
    return{
        tab: 1,
        component:null,

    }
  },

  mounted(){
    addEventListener('resize', this.resizeevent);
  },

  beforeUnmount(){
    removeEventListener('resize', this.resizeevent);
  },

  methods:{

    resizeevent(){
      this.windowHeight = window.innerHeight-150;
    },



  },



}
</script>

<style scoped>

.tab_title{
  font-family: Inter;
  font-size: 11px;
  cursor: pointer;
}

.tab_title_select{
  font-family: Inter;
  font-size: 11px;
  cursor: pointer;
  font-weight: bold;
}

</style>