import APIParam_Model from "./APIParam_Model";

//컴포넌트 정의 모델
class API_Model {

    list: Array<APIParam_Model>;

    constructor(){
        this.list = new Array<APIParam_Model>();
    }

    deepcopy(){
        const deepCopyList = JSON.parse(JSON.stringify(this.list));
        return deepCopyList;
    }

    setStringValue(params:string):void{
    
        const temp  = JSON.parse(JSON.stringify(params));

        this.list.splice(0, this.list.length);
        temp.list.forEach((pnt:string) => {
            const cm = new APIParam_Model();
            cm.setStringValue(pnt);
            this.list.push(cm);
        });
        
    }    

}
//module.exports = {Component_Model}
export default API_Model
