<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 2">
    <v-container fluid class="ma-0 pt-0">
        
        <v-dialog persistent v-model="textinput_dialog" max-width="780px">
             <v-card class="pa-2">
                <v-card-text>
                    <v-text-field
                        v-model="this.contentStore.select_component.model.textswitcher_list[textswitcher_pos]"
                        single-line variant="outlined" density="compact"
                        @keydown="keydownevent"
                        />
                </v-card-text>
                <v-card-actions>
                    <v-row justify="center">
                        <v-btn class="dialog_cancel_btn" rounded large @click="textinput_dialog = false">닫기</v-btn>
                    </v-row>
                </v-card-actions>
             </v-card>
        </v-dialog>

        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="6" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.texthori_align"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="change_textalign"
                    >
                    <v-btn value="left" width="32" icon="mdi-format-align-left" size="x-small"></v-btn>
                    <v-btn value="center" width="32" icon="mdi-format-align-center" size="x-small"></v-btn>
                    <v-btn value="right" width="32px" icon="mdi-format-align-right" size="x-small"></v-btn>
                </v-btn-toggle>                
            </v-col>

            <v-col cols="6" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.textverti_align"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="change_textalign"
                    >
                    <v-btn value="start" icon="mdi-format-align-top" size="x-small"></v-btn>
                    <v-btn value="center" icon="mdi-format-align-middle" size="x-small"></v-btn>
                    <v-btn value="end" icon="mdi-format-align-bottom" size="x-small"></v-btn>
                </v-btn-toggle>                
            </v-col>
        </v-row>
        
        <v-row justify="center" align="center" dense class="mt-0 pt-0">
            <v-col cols="3">
                <dialogftcolor dense title="Font Color" ref="bgcolor" 
                    :color="this.contentStore.select_component.model.textcolor"
                    />
                
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-bold" flat size="small"
                    :color="this.contentStore.select_component.model.textBold == 0 ? 'white' : 'purple'"
                    @click="bold_handler"
                    >
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-italic" flat size="small"
                    :color="this.contentStore.select_component.model.textItalic == 0 ? 'white' : 'purple'"
                    @click="Italic_handler"
                    >
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-underline" flat size="small"
                    :color="this.contentStore.select_component.model.textUnderline == 0 ? 'white' : 'purple'"
                    @click="underline_handler"
                    >
                </v-btn>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="11">
            <dialogfont :comname="this.contentStore.select_component.model.font_name" />
            <!-- 
            <comfont 
                :comname="this.contentStore.select_component.model.textfont"
                @cancelcallback="comfontcancel" @callback="comfontcb"
                />
            -->
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-3 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="폰트 사이즈" property="textsize" :min="10" :max="300" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="글자 간격" property="letterSpacing" :min="-100" :max="100" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="줄 간격" property="lineHeight" :min="0" :max="500" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

        <!-- 텍스트 스위처 설정 -->
        <v-row justify="start" align="center" dense class="mt-2 pt-0">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">전환 시간</label>
            <input 
                v-model="contentStore.select_component.model.textswitcher_time" 
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:40px;text-align:left;font-size:12px">Sec</label>
        </v-row>

        <v-row justify="center" align="center" class="mt-1" dense>
            <v-col cols="12" class="ma-0">
                <label style="font-size:12px">전환 애니매이션</label>
            </v-col>
            <v-col cols="12" class="pa-0">
                <select 
                    v-model="contentStore.select_component.model.textswitcher_ani"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in switcher_anilist" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>


        <v-row justify="center" align="center" class="mt-1 pa-0" dense>
            <v-col cols="10">
                <div style="font-size:12px">텍스트 리스트(밸유맵)</div>
            </v-col>
            <v-col cols="2" align="center">
                <v-icon @click="addtextlist" color="black" icon="mdi-plus" size="x-small"></v-icon>
            </v-col>
        </v-row>
        <v-divider/>

        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense>
          <draggable
            :list="contentStore.select_component.model.textswitcher_list"
            class="pa-0 ma-0"
            ghost-class="ghost" 
            >
            <template #item="{ element, index }">
                <tr style="border-bottom: 1px solid black;">
                    <td class="tbline" style="vertical-align: middle;text-align:center"><v-icon small>mdi-drag</v-icon></td>
                    <td class="text-left tbline" style="font-size:12px;vertical-align: middle;" >
                        <div style="width:150px;max-width:150px;overflow-wrap: break-word;">{{element}}</div>
                    </td>
                    <td class="pa-0 tbline" style="vertical-align: middle;">
                        <v-icon @click="edittext(index)" color="black" icon="mdi-pencil" size="x-small"></v-icon>
                        <v-icon @click="deletetext(element)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                    </td>
                </tr>
            </template>
            
          </draggable>    
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import draggable from 'vuedraggable' //https://github.com/SortableJS/vue.draggable.next

export default {
  
  components: {
    draggable,
  },

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{
    
    //space바를 입력하기 위해서
    keydownevent(ev){
        ev.stopPropagation();
        //this.updateRender();
    },

    //텍스트 추가
    addtextlist(){
        this.contentStore.select_component.model.textswitcher_list.push("내용을 입력하세요");
        this.textswitcher_pos = this.contentStore.select_component.model.textswitcher_list.length-1;
        this.textinput_dialog  = true;
    },
    
    save_text(){
        this.textinput_dialog  = false;
    },

    //텍스트 수정
    edittext(pos){
        this.textswitcher_pos  = pos;
        this.textinput_dialog  = true;
    },

    //텍스트 삭제
    deletetext(am){
        const idx = this.contentStore.select_component.model.textswitcher_list.indexOf(am);
        this.contentStore.select_component.model.textswitcher_list.splice(idx, 1);
    },    

    textchange(){
        this.contentStore.select_component.updateInnerText();
    },

    //폰트선택 취소
    comfontcancel(){
    },

    //폰트선택 콜백
    comfontcb(val){
        if(val.FONT_FAMILY.length > 0){
            this.contentStore.select_component.model.textfont     = val.FONT_FAMILY;
            this.contentStore.select_component.model.font_url     = val.FONT_PATH_REF;
            this.contentStore.select_component.model.font_format  = val.FONT_FORMAT;
            this.contentStore.select_component.model.font_weight  = val.FONT_WEIGHT;
            this.contentStore.select_component.model.font_style   = val.FONT_STYLE;

        }else{
            return;
        }
        this.updateRender();
    },    

    change_textalign(){
        this.updateRender();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    colorcallback(){
        this.updateRender();
    }, 
    
    underline_handler(){
        if(this.contentStore.select_component.model.textUnderline == 0){
            this.contentStore.select_component.model.textUnderline = 1;
        }else{
            this.contentStore.select_component.model.textUnderline = 0;
        }
        this.updateRender();
    },

    bold_handler(){
        if(this.contentStore.select_component.model.textBold == 0){
            this.contentStore.select_component.model.textBold = 1;
        }else{
            this.contentStore.select_component.model.textBold = 0;
        }
        this.updateRender();
    },

    Italic_handler(){
        if(this.contentStore.select_component.model.textItalic == 0){
            this.contentStore.select_component.model.textItalic = 1;
        }else{
            this.contentStore.select_component.model.textItalic = 0;
        }
        this.updateRender();
    }


    /* 마우스 드래그로 숫치를 +,-하기
    mousemove_handler(ev){
        if(ev.target.downflag){
            console.log("mousemove_handler : " + ev.offsetX);     
        }
    },
    mousedown_handler(ev){
        console.log("mousedown_handler");
        ev.target.downflag = true;
    },
    mouseup_handler(ev){
        console.log("mouseup_handler");
        ev.target.downflag = false;
    }
    */


  },

  data(){
    return{
        
        textswitcher_pos:0,
        textinput_dialog:false,
        
        switcher_anilist:[
                {name:"FADE",   value:0}
            ,   {name:"LEFT",   value:1}
            ,   {name:"RIGHT",  value:2}
            ,   {name:"TOP",    value:3}
            ,   {name:"DOWN",   value:4}
        ],

        horialign:[
                {name:"left",       value:"left"}
            ,   {name:"center",     value:"center"}
            ,   {name:"right",      value:"right"}
        ],

        vertialign:[
                {name:"top",        value:"top"}
            ,   {name:"center",     value:"center"}
            ,   {name:"end",        value:"end"}
        ],

    }
  },

  mounted(){
    
  },

  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.tbline{
    border-bottom: 1px solid #e4e4e4;
}
 
</style>