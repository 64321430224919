<template>
  <v-container fluid class="ma-0 pa-0" style="height:100%;">
    <div class="d-flex flex-row" style="background:#252627;height:100%;">
    <v-tabs 
      v-model="tab"
      direction="vertical"
      slider-color="#f78166"
      color="white"
      height="60"
      >
      
      <v-btn stacked prepend-icon="mdi-animation" @click="tab=1" style="font-size:11px" elevation="0" class="mr-1" 
             :color="tab == 1 ? '#252627':'#101010'">Screen</v-btn>
      <v-btn stacked prepend-icon="mdi-layers" @click="selectLayerPanel" style="font-size:11px" elevation="0" class="mr-1" 
             :color="tab == 2 ? '#252627':'#101010'">Layer</v-btn>
      <v-btn stacked prepend-icon="mdi-shape-plus" @click="tab=3" style="font-size:11px" elevation="0" class="mr-1" 
             :color="tab == 3 ? '#252627':'#101010'">Component</v-btn>
      <v-btn stacked prepend-icon="mdi-image-plus" @click="tab=4" style="font-size:11px" elevation="0" class="mr-1" 
             :color="tab == 4 ? '#252627':'#101010'">Resource</v-btn>
      <v-btn stacked prepend-icon="mdi-shape-plus" @click="tab=5" style="font-size:11px" elevation="0" class="mr-1" 
             :color="tab == 5 ? '#252627':'#101010'">Assets</v-btn>
      <v-btn stacked prepend-icon="mdi-format-text-variant-outline" @click="tab=6" style="font-size:11px" elevation="0" class="mr-1" 
             :color="tab == 6 ? '#252627':'#101010'">Font</v-btn>

    </v-tabs>
    <v-tabs-window v-model="tab" >
        <v-tabs-window-item :value="1" >
          <Info_Screen 
            ref="info_screen" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-tabs-window-item>
        <v-tabs-window-item :value="2"> 
          <Info_Layer ref="info_layer" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-tabs-window-item>
        <v-tabs-window-item :value="3">
          <Info_Component ref="info_component" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-tabs-window-item>
        <v-tabs-window-item :value="4">
          <Info_Resource ref="info_assets" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-tabs-window-item>
        <v-tabs-window-item :value="5">
          <Info_Assets ref="info_assets" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-tabs-window-item>
        <v-tabs-window-item :value="6">
          <Info_Fonts ref="info_fonts" 
            style="overflow:auto" class="scrollBar"
            :style="'height:'+windowHeight+'px'"
            />
        </v-tabs-window-item>
    </v-tabs-window>
    </div>
  </v-container>
</template>
<script>
import Info_Screen from '@/nextvue/infopanel/Info_Screen.vue'
import Info_Layer from '@/nextvue/infopanel/Info_Layer.vue'
import Info_Resource from '@/nextvue/infopanel/Info_Resource.vue'
import Info_Assets from '@/nextvue/infopanel/Info_Assets.vue'
import Info_Component from '@/nextvue/infopanel/Info_Component.vue'
import Info_Fonts from '@/nextvue/infopanel/Info_Fonts.vue'


// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, getCurrentInstance, callWithAsyncErrorHandling } from 'vue'
import Content_Model from '@/nextts/model/Content_Model';
import { useContentStore } from "@/stores/content";
export default {

  components:{
    Info_Screen, Info_Layer, Info_Resource, Info_Assets, Info_Component, Info_Fonts
  },
  
  //프로퍼티 선언
  props:{
    _contentmodel:{
      type:Content_Model,
      default(){
        return new Content_Model();
      }
    },
  },  

  setup(){
    const info_screen   = ref(null);
    const info_layer    = ref(null);
    const info_assets   = ref(null);
    const info_component   = ref(null);
    const info_fonts    = ref(null);
    const contentStore = useContentStore();
    let windowHeight   = ref(window.innerHeight-60);
    return {info_screen, info_layer, info_assets, info_component, info_fonts, windowHeight, contentStore};
  },

  data(){
    return{
        tab: 1,
        content_model:this._contentmodel,
    }
  },

  mounted(){
    //onresize  = this.resizeevent;
    addEventListener('resize', this.resizeevent);
  },

  beforeUnmount(){
    removeEventListener('resize', this.resizeevent);
  },

  methods:{

    selectLayerPanel(){
      try{
        this.info_layer.listUpdate();
      }catch(e){
      }finally{
        this.tab = 2;
      }
    },

    resizeevent(){
      this.windowHeight = window.innerHeight-60;
    },

    //썸네일을 만들기 전에 최종 썸네일을 갱신하기 위해 첫번째 스크린을 클릭.
    click_screen(){
      this.tab = 1; //스크린 탭으로 이동시켜야 썸네일 떠짐.
      const screens = this.contentStore.getScreenList;
      this.info_screen.screenselect(screens[0]);
    },


    //left는 content model 설정
    setContentModel(ct_model){
      console.log("setContentModel left panel : " + JSON.stringify(ct_model));
      this.content_model = ct_model;
      if(this.tab == 1){
        this.info_screen.setContentModel(this.content_model);
      }else if(this.tab == 2){
        this.info_layer.content_model  = this.content_model;
      }
    },
    

  },



}
</script>