<template>
    <v-container style="width:240px;background:#252627">
    <template v-if="contentStore.getContentModel != null">

        <v-row justify="center" v-if="this.contentStore.content_model.content_type != 2" class="mt-1">
          
          <v-tooltip text="새로운 스크린을 추가합니다."><template v-slot:activator="{ props }">
            <v-btn v-bind="props" size="small" variant="icon" @click="addscreen" prepend-icon="mdi-plus" color="white">Add</v-btn>
          </template></v-tooltip>
          <v-tooltip text="선택한 현재 스크린을 삭제합니다."><template v-slot:activator="{ props }">
            <v-btn v-bind="props" size="small" variant="icon" @click="deletescreen" prepend-icon="mdi-delete" color="white" class="ml-1" >Delete</v-btn>
          </template></v-tooltip>
        </v-row>


        <v-row justify="center" class="mt-5">
          <v-col
            class="ma-0 pa-1" align="center"
            cols="12" style="cursor:grab"
            v-for="(screen, i) in contentStore.getScreenList" :key="screen"
            >
              <PreviewScreen
                :id="'screen_'+i"
                :screen_model="screen" :key="renderCount" class="item" 
                :scalewidth="200" @click="screenselect(screen)"
                @changescreen="changescreen" :draggable="true" 
                />
          </v-col>
        </v-row>

    </template>
    </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import Screen_Model from '@/nextts/model/Screen_Model';
import PreviewScreen from '@/nextvue/preview/PreviewScreen.vue';
import { useContentStore } from "@/stores/content";

//https://github.com/SortableJS/vue.draggable.next - vue3용 드래그앤드랍
import { ref as fref, uploadBytes } from "firebase/storage";
import html2canvas from 'html2canvas'
import utils from '@/assets/js/utils.js'
export default {

  components:{
    PreviewScreen,
    
  },

  
  methods:{

    screendrag(){
      console.log("screendrag");
    },

    screenselect(screenmodel){
      //인피니티 스크롤 확인
      this.renderCount += 1;
      this.contentStore.selectScreenModel(screenmodel);
      //전역 이벤트 호출 - EditWindow가 수신해서 그림.
      this.$emitter.emit("drawscreen");
    },

    addscreen(){
      
      if(this.$storage.getStorageSync("GRADE") < 90 && this.contentStore.content_model.content_type == 0){

        if(this.$storage.getStorageSync("LIMIT_SCREEN") < this.contentStore.getScreenList.length + 1){
          this.$alert.fire(`${this.$storage.getStorageSync("LIMIT_SCREEN")}개 이상의 스크린을 추가할 수 없습니다.(권한 부족)`);
          return;
        }

      }

      if(this.contentStore.content_model == null){
        this.$alert.fire("지정된 컨텐츠가 없습니다.");
        return;
      }
      
      if(utils.isNullCheck(this.contentStore.content_model.name) || this.contentStore.content_model.name.length < 1){
        this.$alert.fire("지정된 컨텐츠가 없습니다.");
        return;
      }

      //asset과 팝업은 1개이상 추가 금지
      if(this.contentStore.content_model.content_type > 0){
        if(this.contentStore.content_model.screen_list.length > 0){
          this.$alert.fire("Screen을 1개이상 추가할 수 없습니다.");
          return;
        }
      }

      let screen_model        = new Screen_Model();
      screen_model.screen_idx = (Math.random()*16777215);

      screen_model.bgtype = 0;
      if(this.contentStore.content_model.content_type == 0){
        //컨텐츠
        screen_model.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
      }else if(this.contentStore.content_model.content_type == 1){
        //에셋
        screen_model.backgroundColor = `#FFFFFF00`;
      }else if(this.contentStore.content_model.content_type == 2){
        //팝업
        screen_model.backgroundColor = `#00000000`;
      }
      
      //screen_model.backgroundColor = `#FFFFFF`;
      screen_model.width  = this.contentStore.getContentModel.width;
      screen_model.height = this.contentStore.getContentModel.height;
      screen_model.rotate = 0;

      this.contentStore.content_model.screen_list.push(screen_model);
      this.contentStore.saveSnapshot();
      this.renderCount += 1;
    },

    deletescreen(){
      
      let select_pos = -1;
      let select_screen = this.contentStore.select_screen;
      for(var i = 0;i<this.contentStore.content_model.screen_list.length;i++){
        const sm = this.contentStore.content_model.screen_list[i];
        if(sm == select_screen){
          select_pos = i;
        }
      }
      this.contentStore.content_model.screen_list.splice(select_pos, 1);
      this.contentStore.saveSnapshot();
      this.renderCount += 1;
    },

    //드래그앤 드랍을 통한 스크린 모델 체인지 요청
    changescreen(){
      
      console.log("changescreen");
      
      let drag_pos = -1;
      let drag_screen = this.contentStore.drag_screen;
      for(var i = 0;i<this.contentStore.content_model.screen_list.length;i++){
        const sm = this.contentStore.content_model.screen_list[i];
        if(sm == drag_screen){
          drag_pos = i;
        }
      }
      
      let drop_pos = -1;
      let drop_screen = this.contentStore.drop_screen;
      for(var j = 0;j<this.contentStore.content_model.screen_list.length;j++){
        const sm = this.contentStore.content_model.screen_list[j];
        if(sm == drop_screen){
          drop_pos = j;
        }
      }

      this.contentStore.content_model.screen_list.splice(drag_pos, 1);                  //원본 위치에서 제거
      this.contentStore.content_model.screen_list.splice(drop_pos, 0, drag_screen);     //새로운 위치에 다시 추가
      this.contentStore.drag_screen = null;
      this.contentStore.drop_screen = null;

    },    

    //사용안함. JSON으로 실시간으로 보여줌. mask이미지가 html2canvas에서 안나오는 버그때문임.
    //스크린 0번을 썸네일로 저장하고 url을 리턴한다. (저장시 호출됨)
    async save_screen(content_mode, content_type, content_idx){
      
      //렌더링 갱신
      this.renderCount += 1;
      
      if(this.contentStore.content_model.screen_list.length > 0){

        //html2canvas가 생성자를 호출
        var img = await html2canvas(document.getElementById("screen_0").firstChild, {useCORS:true});  //cors해줘야 이미지 URL이 표시됨.
        var imgData = img.toDataURL();
        var blob = this.dataURItoBlob(imgData);
        
        let folder = "";
        if(content_mode == 0){
          //관리자 모드
          if(content_type == "content"){
            folder = "content/" + content_idx+"";
          }else if(content_type == "asset"){
            folder = "asset/" + content_idx+"";
          }else if(content_type == "popup"){
            folder = "popup/" + content_idx+"";
          }
        }else{
          //사용자
          let localid = this.contentStore.content_model.localid;
          let parent_localid = this.contentStore.content_model.parent_localid;
          if(localid == null || localid == undefined){
            localid = this.$storage.getStorageSync("SEARCH_LOCALID");
            parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
          }
          //사용자 모드 - 사용자의 accnt_idx 지정.
          folder = "company/"+parent_localid+"/users/"+localid+"/"+this.content_idx;
        }

        var ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_thum.jpg");
        const snapshot = await uploadBytes(ref, blob);
        //console.log("thum url : " + JSON.stringify(snapshot));
        return snapshot.metadata.fullPath;

      }else{
        return "";
      }
    },

    // Data URI를 Blob 객체로 변환하는 함수
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }

    

  },

  //프로퍼티 선언
  props:{
    
  },  

  setup(){
    const contentStore = useContentStore();
    return {contentStore};
  },

  data(){
    return{
      drag: false,
      renderCount: 0,

    }
  },

  mounted(){
    
  },

  
}
</script>