<!-- 기업/일반 선택용 공통 팝업 -->
<!-- 기업은 자기 소속 계정드 나오고 관리자는 전체 다 나옴 -->
<template>
    
    <v-row align="center" justify="center">
        <v-col cols="8" class="pa-0">
            <v-text-field
                single-line disabled
                :value="comname" 
                placeholder="계정을 선택하세요"
                color="#000000"
                hide-details
                variant="outlined"
                density="compact"
                />
        </v-col>
        <v-col cols="4" class="pa-0" align="center">
            <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="showpopup_com">선택</v-btn>
        </v-col>
    </v-row>

    <v-row>
        <v-dialog persistent scrollable overlay-color="#00000040" 
            v-model="dialog_com" max-width="780px" 
            >
            <v-card class="dialog_round pa-2">
                <v-row justify="space-between">
                    <v-col align="start" cols="9" md="8" sm="6" xs="6">
                    <span class="ml-10 dialog_title">계정 선택</span>
                    </v-col>
                    <v-col align="end" cols="3" md="2" sm="6" xs="6">
                    <v-btn class="dialog_btn mr-10" rounded large @click="clear_com">선택취소</v-btn>
                    </v-col>
                </v-row>

            <v-row dense justify="center">
                <v-col cols="11">
                <div style="background:#4e6ef7;height:2px;" />
                </v-col>
            </v-row>

          <v-card-text>
            <v-container>

                <v-row align="start" dense>
                    <v-col > 
                        <v-table>
                            <thead>
                                <tr>
                                    <th class="text-left">계정</th>
                                    <th class="text-left">이름</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in itemList_com" :key="item.name" @click="select_func(item)">
                                    <td>{{ item.ACCNT }}</td>
                                    <td>{{ item.NAME }}</td>
                                </tr>
                            </tbody>
                        </v-table>

                    </v-col>
                </v-row>

                <!-- 하단검색 -->
                <searchbar ref="searchbar_com" @searchcallback="search_run_com"/>
                
                <!-- 하단페이징영역 -->
                <pagingbar ref="paging_com" :page="page_com" @loadpage="movepage_com"/>  

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-row justify="center">
            <v-btn class="dialog_cancel_btn" rounded large @click="close_com">닫기</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>     
    </v-row>

</template>

<script>
import { ref } from '@vue/reactivity';
import wait from 'waait';
export default {
        
    //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
    props:{
        comname: {
            type:String,
            default(){
                return ""
            }
        },
        limitgrade:{
            type:Number,
            default(){
                return 1
            }
        }
    },
    
    
    watch:{
        dialog_com: async function(v1){
            //console.log("v1 : " + v1 + " / v2 : " + v2);
            if(v1){
                //팝업이 뜬 경우
                await wait(250);
                this.search_run_com();
            }
        },
    },
    

    setup(){
        console.log("setup com accnt");
        const searchbar_com = ref(null);
        const paging_com    = ref(null);
        return {searchbar_com, paging_com};
    },

    data: ()=> ({
        dialog_com : false,
        load_flag : false,
        headers_com: [
            {text: '계정',    value: 'ACCNT', sortable: false, align:'center'},
            {text: '이름',    value: 'NAME', sortable: false, align:'center' }
        ],
        sort_name_com: 'ACCNT',
        sort_type_com: true,    //true : DESC, false: ASC
        
        page_com : 0,
        pagerow_com: 20,
        maxpage_com : 0,       //전체 페이지 개수

        itemList_com: [],
        search_keyword_com : '',
    }),

    mounted(){
        console.log("com accnt mount");
        this.page_com = 1;
    },


    methods: {

        update_dialog(){

            console.log("update dialog...");

        },
        
        searchbar_run(){
            console.log("searchbar run : " + this.search_keyword);
            this.$emit('searchcallback', this.search_keyword);
        },

        movepage_com(p){
            this.loadpage_com(p);
        },

      //검색
      search_run_com(searchkeyword){
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword_com = searchkeyword;
        }else{
          this.search_keyword_com = '';
        }
        this.loadpage_com(1);
      },      

        showpopup_com(){
            this.dialog_com = true;
            this.load_flag = false;
        },

        //다이얼로그 닫기
        close_com () {
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },

        //선택취소
        clear_com(){
            this.$emit('cancelcallback', '')
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
        },

        //선택
        select_func(select_item) {
            this.$emit('callback', select_item);
            //콜백 호출 후 닫아야함.
            this.dialog_com = false
            this.select_com = [];
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            this.search_keyword_com = '';
            this.$props.comname = select_item.NAME;
        },              

        async loadpage_com(p){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.page_com = p;
            this.paging_com.setpage(this.page_com);
            this.$http.post(this.$host+'/AcntComList',{
                  search_keyword: this.search_keyword_com == null || this.search_keyword_com == undefined ?  '' : escape(this.search_keyword_com)
                , sort_name:this.sort_name_com
                , sort_type: this.sort_type_com == true ? "DESC" : "ASC" 
                , page: this.page_com
                , pagerow: this.pagerow_com
                , GRADE: this.limitgrade
            },{headers: { firetoken:token}})
            .then((result)=>{
                
                if(result.data.resultCode == 0){

                    this.totalcnt_com = result.data.totalcnt;
                    this.paging_com.makepaging(this.totalcnt_com, this.pagerow_com);
                    const list = result.data.resultData;
                    this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
                    list.forEach(element => {
                        this.itemList_com.push(element); 
                    });

                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                
            });

        },        


    }

}
</script>

<style scoped>

</style>