<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 6">
    <v-container fluid class="ma-0 pt-0">
    
        <!-- 비디오 재생 시간 -->
        <v-row justify="start" align="center" dense class="pt-0">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">재생 시간</label>
            <input 
                v-model="contentStore.select_component.model.youtube_time" disabled
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:30px;text-align:left;font-size:12px">Sec</label>
        </v-row>
        <v-row justify="start" align="center" dense class="mt-1">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">반복재생</label>
            <input type="checkbox" v-model="contentStore.select_component.model.loop"/>
        </v-row>        

        <v-row class="pt-0 ma-0 mt-3" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="center">
                YOUTUBE URL
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <v-textarea
                    v-model="contentStore.select_component.model.youtube"
                    density="compact" variant="outlined"
                    />
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <v-btn density="compact" variant="outlined" @click="checkurl">확인</v-btn>
            </v-col>
        </v-row>

        <v-row > 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>


    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    //유튜브 URL검증
    async checkurl(){

        if(this.contentStore.select_component.model.youtube == null || this.contentStore.select_component.model.youtube == undefined || this.contentStore.select_component.model.youtube.length == 0){
            this.$alert.fire("유튜브 URL을 입력하세요");
            return;
        }
        
        let videoId = this.getYoutubeVideoId(this.contentStore.select_component.model.youtube);
        console.log("videoId : "+videoId);
        if(videoId == "Invalid URL"){
            this.$alert.fire("알 수 없는 YOUTUBE URL입니다.");
            return;
        }

        if(videoId){
            const response = await this.$http.get(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=AIzaSyC1aLXCAMY4cMOUsbhzustlcyZZhTgXfa8`);
            console.log("youtube res : " + JSON.stringify(response));
            const duration = response.data.items[0].contentDetails.duration;
            this.contentStore.select_component.model.youtube_time = this.convertDuration(duration);
            this.$alert.fire("사용 가능한 유튜브 URL");
        }else{
            this.$alert.fire("알 수 없는 YOUTUBE URL입니다.");
        }
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    //유튜브 URL에서 비디오 URL만 가져오기
    getYoutubeVideoId(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return 'Invalid URL';
        }
    },

    //재생시간이 'PT2H2M32S' 형태로 나오는데 이는 ISO 8601 시간 형식으로, 각각 시간(Hour), 분(Minute), 초(Second)를 나타냅니다.
    //유튜브 재생시간을 1초 = 1로 형태로 변경 
    convertDuration(duration) {
        try{
            var match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/)
            var hours = (parseInt(match[1]) || 0);
            var minutes = (parseInt(match[2]) || 0);
            var seconds = (parseInt(match[3]) || 0);
            return hours * 3600 + minutes * 60 + seconds;
        }catch(error){
            //실시간 유튜브는 시간 오류남.
            return 3600;
        }
    }

  },


  data(){
    return{


    }
  },

  mounted(){
    
  },

  
}
</script>