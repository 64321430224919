<template>
    <template v-if="contentStore.select_component != null 
        && (contentStore.select_component.model.component_type == 0 || contentStore.select_component.model.component_type == 2 || contentStore.select_component.model.component_type == 4 
         || contentStore.select_component.model.component_type == 8 || contentStore.select_component.model.component_type == 9 || contentStore.select_component.model.component_type == 10)">
    <v-container fluid class="ma-0 pt-0">
    
        <!-- 텍스트 외각선 -->
        <v-row align="center" dense>
            <v-col cols="9">
                <v-switch label="외각선" color="red"  hide-details 
                    v-model="this.contentStore.select_component.model.outline_use"
                    @change="shadow_switchchange"
                    />
            </v-col>
            <v-col cols="3" v-if="this.contentStore.select_component.model.outline_use">
                <dialogcolor dense title="Outline Color" property="outline_color" />
            </v-col>
        </v-row>


        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.outline_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="외각선 굵기" property="outline_size" :min="1" :max="50" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.outline_use && contentStore.select_component.model.component_type == 10">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="외각선 대시1" property="outline_dash1" :min="0" :max="1000" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.outline_use && contentStore.select_component.model.component_type == 10">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="외각선 대시2" property="outline_dash2" :min="0" :max="1000" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.outline_use && contentStore.select_component.model.component_type == 10">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="외각선 오프셋" property="outline_offset" :min="0" :max="1000" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    shadow_switchchange(){
        this.updateRender();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    colorcallback(){
        this.updateRender();
    }, 
    

  },

  data(){
    return{

    }
  },

  mounted(){
    
  },

  
}
</script>