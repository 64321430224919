<template>
    <v-container style="width:240px">
    <template v-if="contentStore.select_screen != null">

        <v-row align="center" justify="center" class="mt-1">
          <v-tooltip text="새로운 컴포넌트를 추가합니다."><template v-slot:activator="{ props }">
            <v-btn v-bind="props" size="small" variant="icon" @click="addcomponent" prepend-icon="mdi-plus" color="white">Add</v-btn>
          </template></v-tooltip>
          <v-tooltip text="선택한 컴포넌트를 삭제합니다."><template v-slot:activator="{ props }">
            <v-btn v-bind="props" size="small" variant="icon" class="ml-1" @click="deletecomponent" prepend-icon="mdi-delete" color="white">Delete</v-btn>
          </template></v-tooltip>
        </v-row>
        
        <v-row class="mt-5">
          <draggable
            :list="contentStore.select_screen.component_list"
            item-key="name"
            class="list-group"
            ghost-class="ghost" @change="changedrag"
            :key="listRenderCount"
            >
            <template #item="{ element }">
              <tr @click="selectcomponentModel(element)" style="height:46px;border:1px;cursor:grab">
                
                <td style="width:20px;text-align:left;vertical-align:middle;"><v-icon small color="white">mdi-drag</v-icon></td>
                <td style="width:50px; text-align:center;vertical-align:middle;font-size:11px;color:white">
                  {{ getComponentTextName(element.component_type) }}
                </td>
                <td style="width:150px;height:44px;text-align:left;vertical-align:middle;">
                  <div :class="contentStore.select_component != null && element == contentStore.select_component.model ? 'checkerboard_select' : 'checkerboard'" style="width:150px;height:42px;overflow:hidden">
                    <layercomponent :width="150" :height="42" :model="element" :key="renderCount" />
                  </div>
                </td>

              </tr>
            </template>
          </draggable>    
        </v-row>
    </template>
    </v-container>
    
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import Component_Model from '@/nextts/model/Component_Model';
import draggable from 'vuedraggable' //https://github.com/SortableJS/vue.draggable.next
export default {

  components: {
    draggable
  },

  methods:{

    listUpdate(){
      console.log("asdfasfasfd");
      this.listRenderCount++;
    },

    //오리지널 리스트 순서를 변경
    changedrag(){

      //알아서 list내부의 순서가 바뀐다. 그리기만 다시 그려주면 된다.
      //편집창의 Screen 다시 그리기
      //전역 이벤트 호출 - EditWindow가 수신해서 그림.
      this.listRenderCount++;
      this.$emitter.emit("drawscreen");

      //console.log(e.moved.oldIndex + " / " + e.moved.newIndex);
      //let move_cm = this.contentStore.select_screen.component_list[e.moved.oldIndex];
      //console.log("move cm : " + move_cm.text);
      //this.contentStore.select_screen.component_list.splice(e.moved.oldIndex, 1);  //원본 위치에서 제거
      //this.contentStore.select_screen.component_list.splice(e.moved.newIndex, 0, move_cm);  //새로운 위치에 다시 추가

    },    

    //레이어 선택시 편집 컴포넌트 선택
    selectcomponentModel(cm){
      this.$emitter.emit('selectComponent', cm.component);
      this.contentStore.select_component = cm.component;
    },

    addcomponent(){

      //랜덤 컴포넌트 추가 (테스트)
      const posx  = Math.floor(Math.random() * 700)+50;
      const posy  = Math.floor(Math.random() * 560)+50;
      const tempcm    = new Component_Model();
      tempcm.width  = 200;
      tempcm.height = 50;
      tempcm.posx   = posx;
      tempcm.posy   = posy;
      tempcm.text   = "내용을 입력하세요";
      tempcm.rotate = 0;
      tempcm.transx = posx;
      tempcm.transy = posy;
      tempcm.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
      this.contentStore.select_screen.component_list.push(tempcm);
      this.$emitter.emit("drawscreen");

    },

    deletecomponent(){

      if(this.contentStore.select_screen == null){
        return;
      }
      if(this.contentStore.select_component == null){
        return;
      }
      if(this.contentStore.select_component.model == null){
        return;
      }
      if(this.contentStore.select_screen.component_list.includes(this.contentStore.select_component.model)){
        const idx = this.contentStore.select_screen.component_list.indexOf(this.contentStore.select_component.model);
        this.contentStore.select_screen.component_list.splice(idx, 1);
        this.changedrag();
        this.$emitter.emit("updateinfopanel");
      }

    },

    getComponentTextName(type){

      if(type == Component_Model.COMPONENT_TEXT){
        return "Text";
      }else if(type == Component_Model.COMPONENT_IMAGE){
        return "Image";
      }else if(type == Component_Model.COMPONENT_TEXTSWITCHER){
        return "Text\nSwitcher";
      }else if(type == Component_Model.COMPONENT_IMAGESWITCHER){
        return "Image\nSwitcher";
      }else if(type == Component_Model.COMPONENT_COLOR){
        return "Color";
      }else if(type == Component_Model.COMPONENT_MOVIE){
        return "Movie";
      }else if(type == Component_Model.COMPONENT_YOUTUBE){
        return "YTB";
      }else if(type == Component_Model.COMPONENT_WEBVIEW){
        return "WEB";
      }else if(type == Component_Model.COMPONENT_TEXT_SCROLL){
        return "Text\nScroll";
      }else if(type == Component_Model.COMPONENT_DATETIME){
        return "Date";
      }else if(type == Component_Model.COMPONENT_ARTTEXT){
        return "Art\nText";
      }else if(type == Component_Model.COMPONENT_SOUND){
        return "Sound";
      }else if(type == Component_Model.COMPONENT_API){
        return "API";
      }else if(type == Component_Model.COMPONENT_ANITEST){
        return "Test";
      }

    },

    saveSnapshot(){
      console.log("info layer saveSnapShot callback ");
      this.renderCount++;
    }    

  },

  //프로퍼티 선언
  props:{
    
  },  

  setup(){
    const contentStore = useContentStore();
    return {contentStore};
  },

  data(){
    return{
        renderCount: 0,
        listRenderCount:0,
        content_model:null
    }
  },

  mounted(){
    console.log("info layer mounted...")
    this.$emitter.on('savesnapshot', this.saveSnapshot);
    this.renderCount++;
  },

  beforeUnmount(){
    this.$emitter.off('savesnapshot');
  },

  
}
</script>

<style scoped>

.checkerboard2{
  background: repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px);
}

.checkerboard {
  background: repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px);
}
.checkerboard_select {
  box-shadow: 1px 1px 1px rgb(174, 0, 255), -1px -1px 1px rgb(174, 0, 255);
  background: repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px);
  
}

</style>