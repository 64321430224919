<template>
  <v-container fluid style="background:#ffffff;min-height:100%">
    <v-row justify="center" class="mt-1">
      <v-col cols="12" md="8">
        

      <v-row justify="center" class="mt-1" >
        <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
          <div id="title2" :class="$vuetify.display.mobile ? 'h2_text_mb' : 'h2_text'">무료로 시작하고!</div>
          <div id="title3" :class="$vuetify.display.mobile ? 'h3_text_mb' : 'h3_text'">더 많은 기능을 원하시면 요금제를 선택하세요</div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-center">
          <div class="priceround d-flex flex-column align-center">
            <div class="pricetitle mt-5">무료</div>
            <div class="pricevalue">Free</div>
            <div class="pricedesc">무료 체험</div>
            <div class="priceline mt-2"/>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">동시접속 1대</div>
              <v-tooltip text="컨텐츠 플레이어를 동시에 사용할 수 있는 대수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">용량 10MB</div>
              <v-tooltip text="컨텐츠에 사용되는 저장소의 총 공간">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠수 1개</div>
              <v-tooltip text="컨텐츠를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠 페이지수 1개</div>
              <v-tooltip text="컨텐츠 내부에 페이지를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%;visibility: hidden;">
              <div class="pricetext me-auto">실시간페이지 사용</div>
              <v-tooltip text="실시간으로 데이터만 빠르게 교체하는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%;visibility: hidden;">
              <div class="pricetext me-auto">팝업기능 사용</div>
              <v-tooltip text="컨텐츠위에 팝업을 생성해서 알림메시지를 보여주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%;visibility: hidden;">
              <div class="pricetext me-auto">TTS기능 사용</div>
              <v-tooltip text="팝업 알림메시지를 TTS로 읽어주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <v-btn class="mt-10 mb-5 pricebtn" color="#7930ff" @click="gostart">시작하기</v-btn>
          </div>
        </v-col>      


        <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-center">
          <div class="priceround d-flex flex-column align-center">
            <div class="pricetitle mt-5">라이트</div>
            <div class="pricevalue">월 11,000원</div>
            <div class="pricedesc">소규모 사업장 사용</div>
            <div class="priceline mt-2"/>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">동시접속 1대</div>
              <v-tooltip text="컨텐츠 플레이어를 동시에 사용할 수 있는 대수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">용량 300MB</div>
              <v-tooltip text="컨텐츠에 사용되는 저장소의 총 공간">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠수 1개</div>
              <v-tooltip text="컨텐츠를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠 페이지수 5개</div>
              <v-tooltip text="컨텐츠 내부에 페이지를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">실시간페이지 1개</div>
              <v-tooltip text="실시간으로 데이터만 빠르게 교체하는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%;visibility: hidden;">
              <div class="pricetext me-auto">팝업기능 사용</div>
              <v-tooltip text="컨텐츠위에 팝업을 생성해서 알림메시지를 보여주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%;visibility: hidden;">
              <div class="pricetext me-auto">TTS기능 사용</div>
              <v-tooltip text="팝업 알림메시지를 TTS로 읽어주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <v-btn class="mt-10 mb-5 pricebtn" color="#7930ff" @click="payment">결제하기</v-btn>
          </div>
        </v-col>        

        <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-center">
          <div class="priceround d-flex flex-column align-center">
            <div class="pricetitle mt-5">프로</div>
            <div class="pricevalue">월 33,000원</div>
            <div class="pricedesc">개인 사업장에 적합</div>
            <div class="priceline mt-2"/>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">동시접속 3대</div>
              <v-tooltip text="컨텐츠 플레이어를 동시에 사용할 수 있는 대수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">용량 1GB</div>
              <v-tooltip text="컨텐츠에 사용되는 저장소의 총 공간">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠수 3개</div>
              <v-tooltip text="컨텐츠를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠 페이지수 10개</div>
              <v-tooltip text="컨텐츠 내부에 페이지를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">실시간페이지 3개</div>
              <v-tooltip text="실시간으로 데이터만 빠르게 교체하는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">팝업기능 사용</div>
              <v-tooltip text="컨텐츠위에 팝업을 생성해서 알림메시지를 보여주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%;visibility: hidden;">
              <div class="pricetext me-auto">TTS기능 사용</div>
              <v-tooltip text="팝업 알림메시지를 TTS로 읽어주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <v-btn class="mt-10 mb-5 pricebtn" color="#7930ff" @click="payment">결제하기</v-btn>
          </div>
        </v-col>        


        <v-col cols="12" sm="6" md="3" class="d-flex align-center justify-center">
          <div class="priceround d-flex flex-column align-center">
            <div class="pricetitle mt-5">프리미엄</div>
            <div class="pricevalue">월 99,000원</div>
            <div class="pricedesc">규모있는 사업장에 적합</div>
            <div class="priceline mt-2"/>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">동시접속 10대</div>
              <v-tooltip text="컨텐츠 플레이어를 동시에 사용할 수 있는 대수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">용량 5GB</div>
              <v-tooltip text="컨텐츠에 사용되는 저장소의 총 공간">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠수 10개</div>
              <v-tooltip text="컨텐츠를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">컨텐츠 페이지수 50개</div>
              <v-tooltip text="컨텐츠 내부에 페이지를 만들 수 있는 개수">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">실시간페이지 10개</div>
              <v-tooltip text="실시간으로 데이터만 빠르게 교체하는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">팝업기능 사용</div>
              <v-tooltip text="컨텐츠위에 팝업을 생성해서 알림메시지를 보여주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <div class="d-flex align-center mt-5" style="width:80%">
              <div class="pricetext me-auto">TTS기능 사용</div>
              <v-tooltip text="팝업 알림메시지를 TTS로 읽어주는 기능">
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props" color="#000000" size="18">mdi-information-outline</v-icon>
                </template>
              </v-tooltip>              
            </div>
            <v-btn class="mt-10 mb-5 pricebtn" color="#7930ff" @click="payment">결제하기</v-btn>
          </div>
        </v-col>                

      </v-row>


      <v-row justify="center" class="mt-10" >
        <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
          <div id="title2" :class="$vuetify.display.mobile ? 'h2_text_mb' : 'h2_text'">더 큰 규모의 비즈니스 문의</div>
          <div id="title3" :class="$vuetify.display.mobile ? 'h3_text_mb' : 'h3_text'">더 많은 사용이 예상되는 비즈니스를 위한 문의가 필요하시면 아래 문의하기를 통해 문의주세요.</div>
          <div id="title3" :class="$vuetify.display.mobile ? 'h3_text_mb' : 'h3_text'">생각하신 비즈니스에 적합한 솔루션을 제공해드립니다.</div>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-1" >
        <v-col cols="12" md="6" class="d-flex flex-column justify-center align-center">
          <v-btn class="mt-10 mb-5 pricebtn" color="#7930ff" @click="gocontact">문의하기</v-btn>
        </v-col>
      </v-row>

      
      <v-row justify="center" class="mt-15">
        <div style="height:100px"/>
      </v-row>
      
    
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
    export default {

    data: () => ({

    }),

    mounted(){
      
      gsap.registerPlugin(ScrollTrigger);

      const from = {x:0, y:-50, scale:1, rotate:0, alpha:0};
      const to   = {delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title1_1", from, to);

      const from2 = {x:0, y:-50, scale:1, rotate:0, alpha:0};
      const to2   = {delay:0.6, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title1_2", from2, to2);

    },

    methods:{
      
      gocontact(){
        this.$router.push("Contact").then(()=>{
          this.ROUTERNAME = "Contact";
        })
        .catch(()=>{
        });

      },

      gostart(){
        if(this.$storage.hasKey("LOCALID")){
          //로그인 상태
          this.$router.push("MyContent").then(()=>{
            this.ROUTERNAME = "MyContent";
          })
          .catch(()=>{
          });

        }else{
          this.$router.push("Login").then(()=>{
            this.ROUTERNAME = "Login";
          })
          .catch(()=>{
          });
        }

      },

      payment(){
        
        if(this.$storage.hasKey("LOCALID")){
          //로그인 상태

          //기업회원은 결제하면 안됨.
          if(this.$storage.getStorageSync("GRADE") == 5){
            this.$alert.fire("기업회원은 결제할 수 없습니다.");
            return;
          }

        }else{
          this.$router.push("Login").then(()=>{
            this.ROUTERNAME = "Login";
          })
          .catch(()=>{
          });
        }

      }

    }


  }
</script>

<style scoped>

</style>
