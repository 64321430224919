import Content_Model from '@/nextts/model/Content_Model';
import Component_Model from  '@/nextts/model/Component_Model';
import Play_Interface from '@/nextts/play/Play_Interface';
import axios from 'axios';
import { useContentStore } from "@/stores/content";
import { emitter } from '@/emitter';
import * as xml2js from 'xml2js';

//기본 데이터 속성
class Play_Api extends HTMLElement implements Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    contentStore = useContentStore();

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.visibility = "hidden";
    }


    //동기 - 통신이 완료될 때 까지 대기해줌.
    async runApiAwait(){

        if(this.model == null) return;

        const urlRegexp = /^(http|https):\/\/[^ "]+$/;
        if(urlRegexp.test(this.model.apilink)){
            try {

                if(this.model.api_type == 0){

                    //URL객체 생성
                    const parsedUrl = new URL(this.model.apilink);

                    // URLSearchParams 객체를 이용해 파라미터 접근
                    const params: URLSearchParams = new URLSearchParams(parsedUrl.search);

                    // 파라미터들을 객체로 변환
                    for (const [key, value] of params.entries()) {
                        if(value.startsWith("@")){
                            if(this.contentStore.select_screen == null) break;        
                            const eventname = value.replace('@', '');
                            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                                const component = this.contentStore.select_screen.component_list[i];

                                if(component.component_type == Component_Model.COMPONENT_TEXT && eventname == component.event_name){
                                    if(component.play_component != null){
                                        params.set(key, component.play_component.innerText); // 직접 params를 업데이트합니다.
                                    }
                                }else if(component.component_type == Component_Model.COMPONENT_DATALOAD && eventname == component.event_name){
                                    if(component.load_data != null){
                                        params.set(key, String(component.load_data)); // 직접 params를 업데이트합니다.
                                    }
                                }
                            }
                        }
                    }

                    // URLSearchParams를 사용하여 새로운 쿼리 스트링을 생성
                    const newQueryString = params.toString();

                    // 원래 URL과 새로운 파라미터를 조합
                    const newUrl: string = `${parsedUrl.origin}${parsedUrl.pathname}?${newQueryString}`;
                    console.log("newUrl :" + newUrl);

                    //GET
                    const response = await axios.get(newUrl);
                    
                    let resultdata;                
                    if(this.model.api_datatype == "xml"){
                        const xmltojson = await this.convertXMLToJSON(response.data);
                        resultdata = xmltojson;
                    }else{
                        resultdata = response.data;
                    }

                    //성공판정
                    if(this.model.apiresult_key != null && this.model.apiresult_key != undefined && this.model.apiresult_key.length > 0){
                        //판정키가 있으면
                        if(this.getValueByPath(resultdata, this.model!.apiresult_key) != this.model?.apiresult_value){
                            console.log("fail");
                            return;
                        }
                    }

                    //전역 변수에 저장해야한다.
                    //이미 있는 키의 값을 삭제
                    if (Content_Model.apiData.has(this.model!.apiname)) {
                        Content_Model.apiData.delete(this.model!.apiname); 
                    }
                    //새로 값 지정
                    Content_Model.apiData.set(this.model!.apiname, resultdata);

                    //후 처리
                    if(this.model.apiresult_type == "Reload"){
                        //맵핑 데이터 리로드
                        emitter.emit('updatetext');

                    }else if(this.model.apiresult_type == "Page Move"){
                        //movekey로 값 가져오기.
                        const move_page = this.getValueByPath(resultdata, this.model!.apiresult_movekey);
                        //페이지 이동 - 전체 컨텐츠에서 페이지 이름을 찾아서 이동한다.
                        for(let i=0; i < this.contentStore.content_model.screen_list.length; i++){
                            const screen= this.contentStore.content_model.screen_list[i];
                            if(move_page == screen.screen_name){
                                emitter.emit('movepage', i);
                                break;
                            }
                        }

                    }

                }else if(this.model.api_type == 1){

                    //#맵핑정보 찾기                    
                    const api_params: any = this.model.api_param.deepcopy();
                    for(const parameter of api_params){
                        if(parameter.value.startsWith("@")){
                            if(this.contentStore.select_screen == null) break;        
                            const eventname = parameter.value.replace('@', '');
                            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                                const component = this.contentStore.select_screen.component_list[i];
                                
                                if(component.component_type == Component_Model.COMPONENT_TEXT && eventname == component.event_name){
                                    if(component.play_component != null){
                                        parameter.value = component.play_component.innerText;
                                    }
                                }else if(component.component_type == Component_Model.COMPONENT_DATALOAD && eventname == component.event_name){
                                    if(component.load_data != null){
                                        parameter.value = component.load_data;
                                    }
                                }
                            }
                        }
                    }

                    //POST
                    const params = api_params.reduce((acc: {[key: string]: string}, param: { key: string | number; value: string; }) => {
                        acc[param.key] = param.value;
                        return acc;
                    }, {});

                    console.log("params : " + JSON.stringify(params));

                    const headers = this.model.api_header.list.reduce((acc: {[key: string]: string}, param) => {
                        acc[param.key] = param.value;
                        return acc;
                    }, {});

                    //POST
                    const response = await axios.post(this.model.apilink, params, { headers });
                    
                    let resultdata;                
                    if(this.model.api_datatype == "xml"){
                        const xmltojson = await this.convertXMLToJSON(response.data);
                        resultdata = xmltojson;
                    }else{
                        resultdata = response.data;
                    }

                    //성공판정
                    if(this.model.apiresult_key != null && this.model.apiresult_key != undefined && this.model.apiresult_key.length > 0){
                        //판정키가 있으면
                        if(this.getValueByPath(resultdata, this.model!.apiresult_key) != this.model?.apiresult_value){
                            console.log("fail");
                            return;
                        }
                    }

                    //전역 변수에 저장해야한다.
                    //이미 있는 키의 값을 삭제
                    if (Content_Model.apiData.has(this.model!.apiname)) {
                        Content_Model.apiData.delete(this.model!.apiname); 
                    }
                    //새로 값 지정
                    Content_Model.apiData.set(this.model!.apiname, resultdata);

                    //후 처리
                    if(this.model.apiresult_type == "Reload"){
                        //맵핑 데이터 리로드
                        emitter.emit('updatetext');

                    }else if(this.model.apiresult_type == "Page Move"){

                        //movekey로 값 가져오기.
                        const move_page = this.getValueByPath(resultdata, this.model!.apiresult_movekey);

                        //페이지 이동 - 전체 컨텐츠에서 페이지 이름을 찾아서 이동한다.
                        for(let i=0; i < this.contentStore.content_model.screen_list.length; i++){
                            const screen= this.contentStore.content_model.screen_list[i];
                            if(move_page == screen.screen_name){
                                console.log("move screen name : " + screen.screen_name);
                                emitter.emit('movepage', i);
                                break;
                            }
                        }

                    }                  

                }
            }catch(error){
                console.log("api error : " + error);
            }
        }
        
    }    
    
    //Play_Interface 구현
    init(): void {

    }

    exit(): void {
        //HTMLElement 초기화
        
    }

    drawView(): void{
        
    }

    //json값에서 path에 해당하는 값을 꺼냄. [key][0][key] 와 같은 동적 path가능.
    getValueByPath<T>(json: Record<string, any>, path: string): T | undefined {
        const parts = path.match(/\[([^\]]+)\]/g) || [];
        return parts.reduce((acc: any, part: string) => {
            // 대괄호를 제거하고 실제 키값만 추출
            const key = part.replace(/\[|\]/g, '');
            // 숫자로 변환할 수 있는 키는 배열의 인덱스로, 그렇지 않은 키는 객체의 키로 처리
            return acc && (acc.hasOwnProperty(key) ? acc[key] : acc[Number(key)]);
        }, json) as T;
    }

    
    // xml2js의 parseString 함수를 Promise로 감싸는 함수
    parseXMLAsync(xml: string): Promise<any> {
        return new Promise((resolve, reject) => {
        const parser = new xml2js.Parser({ explicitArray: false }); //해당 옵션을 줘야함. 안그럼 단일값이 배열로 감싸진다.
        parser.parseString(xml, (err, result) => {
            if (err) reject(err);
            else resolve(result);
        });
        });
    }
    
    // async 함수에서 위의 함수를 사용
    async convertXMLToJSON(xml: string): Promise<any> {
        try {
            const result = await this.parseXMLAsync(xml);
            return result; // 변환된 JSON 객체를 반환
        } catch (error) {
            console.error("Error parsing XML:", error);
        }
    }
  
}

export default Play_Api
customElements.define('play-api', Play_Api);