import { createApp } from 'vue'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia'
import App from './App.vue'
import Vue from 'vue'

//라이브러리
import axios from 'axios';
import router from './router'
import vuetify from './plugins/vuetify'
import mitt from 'mitt'
import { emitter } from './emitter';

import momenttz from 'moment-timezone'

import Swal from 'sweetalert2'
import Vue3Storage, { StorageType } from "vue3-storage";

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


//공통 컴포넌트
import DialogScreenBGColor from '@/components/DialogScreenBGColor.vue'
import DialogScreenGRColor from '@/components/DialogScreenGRColor.vue'
import DialogColor from '@/components/DialogColor.vue'
import DialogBGColor from '@/components/DialogBGColor.vue'
import DialogFTColor from '@/components/DialogFTColor.vue'
import DialogFont from '@/components/DialogFont.vue'
import DialogGRColor from '@/components/DialogGRColor.vue'
import DialogMask from '@/components/DialogMask.vue'
import DialogSDColor from '@/components/DialogSDColor.vue'
import Dialog_Content from '@/components/Dialog_Content.vue'
import Dialog_MyContent from '@/components/Dialog_MyContent.vue'
import Dialog_Company from '@/components/Dialog_Company.vue'

import Com_Pagingbar from '@/components/Com_Pagingbar.vue'
import Com_Searchbar from '@/components/Com_Searchbar.vue'
import Com_Accnt from '@/components/Com_Accnt.vue'
import Com_Content from '@/components/Com_Content.vue'
import Com_Ani from '@/components/Com_Ani.vue'
import Com_MyAni from '@/components/Com_MyAni.vue'
import Com_Play from '@/components/Com_Play.vue'
import Com_Font from '@/components/Com_Font.vue'
import Com_Payment from '@/components/Com_Payment.vue'
import Com_Subscription from '@/components/Com_Subscription.vue'
import Com_Loading from '@/components/Com_Loading.vue'
import Com_EditSlider from '@/components/Com_EditSlider.vue'
import LayerComponent from '@/nextvue/preview/LayerComponent.vue'


//파이어베이스
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyANxM9e4ZrHWa5jMLEvuaSBcyllne1pniA",
  authDomain: "necsolution-59f61.firebaseapp.com",
  databaseURL: "https://necsolution-59f61-default-rtdb.firebaseio.com/",
  projectId: "necsolution-59f61",
  storageBucket: "necsolution-59f61.appspot.com",
  messagingSenderId: "785162106319",
  appId: "1:785162106319:web:048b72ec35b41d78b54eba",
  measurementId: "G-DM2GXF8CF0"
};
const firebase  = initializeApp(firebaseConfig);
const auth      = getAuth(firebase);
const db        = getFirestore(firebase);
const rdb       = getDatabase(firebase);
const storage   = getStorage(firebase, "gs://necsolution-59f61.appspot.com");

//전역 이벤트
//const emitter = mitt();

loadFonts()

const app = createApp(App);
app.config.globalProperties.$firebase     = firebase;
app.config.globalProperties.$fireauth     = auth;
app.config.globalProperties.$firestore    = db;
app.config.globalProperties.$firedb       = rdb;
app.config.globalProperties.$firestorage  = storage;
app.config.globalProperties.$storagepath  = "https://storage.googleapis.com/necsolution-59f61.appspot.com/";
app.config.globalProperties.$http         = axios;

//app.config.globalProperties.$host         = "http://192.168.0.39:8080";
app.config.globalProperties.$host       = "https://necapi-yuqtgcfr6q-du.a.run.app";


app.config.globalProperties.$momenttz   = momenttz;
app.config.globalProperties.$alert      = Swal;
app.config.globalProperties.$emitter    = emitter;


//커스텀 컴포넌트
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogscbgcolor", DialogScreenBGColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogscgrcolor", DialogScreenGRColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogcolor", DialogColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogbgcolor", DialogBGColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogftcolor", DialogFTColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogfont", DialogFont);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialoggrcolor", DialogGRColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogmask", DialogMask);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogsdcolor", DialogSDColor);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogcontent", Dialog_Content);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogmycontent", Dialog_MyContent);
// eslint-disable-next-line vue/multi-word-component-names
app.component("dialogcompany", Dialog_Company);

// eslint-disable-next-line vue/multi-word-component-names
app.component("searchbar", Com_Searchbar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("pagingbar", Com_Pagingbar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comaccnt", Com_Accnt);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comcontent", Com_Content);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comani", Com_Ani);
// eslint-disable-next-line vue/multi-word-component-names
app.component("commyani", Com_MyAni);
// eslint-disable-next-line vue/multi-word-component-names
app.component("complay", Com_Play);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comfont", Com_Font);
// eslint-disable-next-line vue/multi-word-component-names
app.component("compayment", Com_Payment);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comsubscript", Com_Subscription);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comloading", Com_Loading);
// eslint-disable-next-line vue/multi-word-component-names
app.component("comeditslider", Com_EditSlider);
// eslint-disable-next-line vue/multi-word-component-names
app.component("layercomponent", LayerComponent);






const piana = createPinia();
app.use(vuetify);
app.use(router);
app.use(piana);
app.use(VueViewer);
app.use(Vue3Storage, {namespace:"session_", storage:StorageType.Session})
//app.use(VueSessionStorage);
app.mount('#app');
