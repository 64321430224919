<!-- 현재 해당 페이지는 사용안함 -->
<template>

  <v-container fluid >

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>    

    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog1" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">카테고리</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

                <v-row dense align="center" class="text-center">
                  <v-col cols="3">
                    <label>카테고리명</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      single-line v-model="editedItem1.TYPENAME"
                      placeholder="카테고리명을 입력하세요"  color="#808080" hide-details 
                      density="compact" variant="outlined"       
                      />
                  </v-col>
                </v-row>

            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save1">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

                <v-row dense align="center" class="text-center">
                  <v-col cols="3">
                    <label>타입명</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      single-line v-model="editedItem.CATEGORY_NAME"
                      placeholder="타입명을 입력하세요"  color="#808080" hide-details 
                      density="compact" variant="outlined"       
                      />
                  </v-col>
                </v-row>
                
                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">상태</label>
                  </v-col>
                  <v-col cols="9">
                    <v-select v-model="editedItem.CATEGORY_STATE" :items="STATELIST" outlined
                      item-title="name" item-value="value" class="dialogselectstyle"
                      hide-details elevation="0" dense height="48px"
                      density="compact" variant="outlined"
                      />
                  </v-col>
                </v-row>

                <v-row  dense align="center" class="text-center">
                  <v-col cols="3">
                    <label class="mytextstyle">이미지</label>
                  </v-col>
                  <v-col cols="9" align="center" align-self="center" style="height:120px;width:120px">
                      <template v-if="previewImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                              <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                            </div>
                            <img  height="120" width="120" :src="previewImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border" align="center" justify="center">
                          <v-icon style="width:50px;height:50px" @click="fileSelect(0)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                  </v-col>
                </v-row>

            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

  <v-row align="center" justify="start" class="mt-5">
    <v-col cols="6" md="3">
      <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
  </v-row>
    
  <v-row align="start" dense>
    <v-col cols="12" md="2"> 
      <v-row>
        <v-col cols="12" align="end">
          <v-btn @click="newinsert1" size="small" variant="flat" color="black">추가</v-btn>
        </v-col>
      </v-row>

      <!-- 테이블 -->
      <v-table fixed-header density="compact" class="mt-1">
        <thead>
          <tr>
            <th style="width:80px; text-align:center" class="text-center">카테고리</th>
            <th style="width:80px; text-align:center" class="text-center">수정</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="element in CATEGORYTYPE_LIST" :key="element.CATEGORY_TYPE">
            <td style="width:80px; text-align:center">
              <a style="cursor:pointer;color:#0002ff" @click="loadcategorytype(element.CATEGORY_TYPE)">{{element.TYPENAME}}</a>
            </td>
            <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item1(element)">mdi-pencil</v-icon></td>
          </tr>
        </tbody>
      </v-table>      
    </v-col>

    <v-col cols="12" md="10"> 
      <v-row>
        <v-col cols="12" align="end">
          <v-btn @click="newinsert" size="small" variant="flat" color="black">추가</v-btn>
          <v-btn @click="saveSort" size="small" variant="flat" color="black" class="ml-1">순서저장</v-btn>
        </v-col>
      </v-row>
    
      <!-- 테이블 -->
      <v-table fixed-header density="compact" class="mt-1">
        <thead>
          <tr>
            <th style="width:50px;" class="text-center"></th>
            <th style="width:80px; text-align:center" class="text-left">이미지</th>
            <th style="width:80px; text-align:center" class="text-center">카테고리명</th>
            <th style="width:80px; text-align:center" class="text-center">타입</th>
            <th style="width:80px; text-align:center" class="text-center">상태</th>
            <th style="width:100px; text-align:center" class="text-center">등록일</th>
            <th style="width:80px; text-align:center" class="text-center">수정</th>
          </tr>
        </thead>
        <template v-if="itemList.length == 0">
          <tr>
            <td colspan="7"><div class="text-center">데이터가 없습니다.</div></td>
          </tr>
        </template>
        <template v-else>
          <draggable v-model="itemList" tag="tbody" item-key="CATEGORY_IDX">
            <template #item="{ element }">
              <tr>
                <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                <td style="width:80px; text-align:center" >
                    <v-img width="80" :src="element.CATEGORY_IMG"/>
                </td>
                <td style="width:80px; text-align:center">{{element.CATEGORY_NAME}}</td>
                <td style="width:80px; text-align:center">{{ getTypename(CATEGORY_TYPE) }}</td>
                <td style="width:80px; text-align:center">{{ element.CATEGORY_STATE == 1 ? "활성" : '비활성' }}</td>
                <td style="width:100px; text-align:center">{{ this.$momenttz.utc(element.REG_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }} </td>
                <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(element)">mdi-pencil</v-icon><v-icon small @click="delete_item(element)">mdi-delete</v-icon></td>
              </tr>
            </template>
          </draggable>
        </template>
      </v-table>

    </v-col>
  </v-row>


  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref as fref, uploadBytesResumable, uploadBytes, uploadString, deleteObject } from "firebase/storage";
import draggable from 'vuedraggable'
import { ref } from '@vue/reactivity';
export default {
    components:{
       VueCropper
      ,draggable
    },

    setup(){
        var editor = ref(null);
        return {editor};
    },

    data: () => ({

      progress_dialog:false,
      progress_value:0,
      uploadFile1 : [],
      uploadFileThum1 : [],
      previewImg1 : "",
      imgSrc:'',
      crop_dialog:false,
      photo_index:0,

      STATELIST: [
          {name:'사용안함',   value:0}
        , {name:'사용',       value:1}
      ],

      CATEGORY_TYPE:6,
      CATEGORYTYPE_LIST: [],

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

      //필수 페이징 파라메터
      totalcnt1 : 0,       //전체 아이템 개수
      totalcnt2 : 0,       //전체 아이템 개수
      loading : false,    //로딩 표시

      dialog1:false,
      editedItem1:{},
      editedIndex1: -1,
      defaultItem1: {
        TYPENAME:'',
      },

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          CATEGORY_NAME:'',
          CATEGORY_IMG:'',
          CATEGORY_STATE:1,
          CATEGORY_SORT:1,
          CATEGORY_IMG_REF:null
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '', key: '', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {title: '이미지', key: 'CATEGORY_IMG', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {title: '카테고리명', key: 'CATEGORY_NAME', sortable: false, align:'center', class:"cheader_normal"},
        {title: '타입', key: 'CATEGORY_TYPE', sortable: false, align:'center', class:"cheader_normal"},
        {title: '상태', key: 'CATEGORY_STATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '등록일', key: 'REG_DATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '수정', key: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'CATEGORY_SORT',
      sort_type: false,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

    }),

    mounted(){

      if(this.$storage.getStorageSync("GRADE") < 90){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      getTypename(type){
        for(var i=0;i<this.CATEGORYTYPE_LIST.length;i++){
          const item = this.CATEGORYTYPE_LIST[i];
          if(item.value == type){
            console.log(item.name);
            return item.name;
          }
        }
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
        input.onchange = function (event) {
          changefunction(event.target.files[0], index);
        };
        input.click();
      },

      //이미지 선택시
      changefile(file, pindex){
        if(file.size/1024/1024 > 10){
          this.$alert.fire("10MB 이하 파일만 사용할 수 있습니다.");
          return;
        }
        const reader = new FileReader();
        reader.onload = (event)=>{
          this.photo_index = pindex;
          this.crop_dialog = true;
          this.imgSrc = event.target.result;  //이게 blob데이터
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서
      },


      //이미지 크롭시
      cropimg(){
        
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){

              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data;  //이미지뷰 미리보기용

              //썸네일 생성
              var image     = await this.loadImage(data);
              const canvas  = document.createElement("canvas");
              canvas.width  = 100;
              canvas.height = 100;
              canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

              this.uploadFileThum1 = this.dataURItoBlob(canvas.toDataURL("image/jpeg"));     //썸네일 blob데이터
              console.log("uploadFileThum11 : " + this.uploadFileThum1);

            }

          });

        });      
      },

      //지정한 data를 넣은 이미지를 리턴한다.
      loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
          myImage.onload = () => resolve(myImage)
        })
      },

      //캔버스 TO BLOB
      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

      //firebase storage에 파일 업로드 및 프로그레스 표시
      uploadFileStorage(storage_ref, blobd_ata, metadata){
        return new Promise((resolve, reject)=>{

            const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                }                
              },
              (error) => {
                reject(null);
              },
              () => {
                // Upload completed successfully - 업로드 완료시 경로 리턴
                resolve(uploadTask.snapshot.ref);
              }
            );

        })
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage();
      },

      async loadpage(){
          console.log("loadpage...");
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          this.$http.post(this.$host+'/CategoryList',{
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                this.totalcnt1 = result.data.totalcnt;
                const list = result.data.resultData;
                this.CATEGORYTYPE_LIST.splice(0, this.CATEGORYTYPE_LIST.length);   //데이터 초기화
                list.forEach(element => {
                  this.CATEGORYTYPE_LIST.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },      

      loadcategorytype(category_type){
        this.CATEGORY_TYPE = category_type;
        this.loadcategorylist();
      },

      async loadcategorylist(){
          console.log("loadcategorylist...");
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          
          this.$http.post(this.$host+'/CategoryTypeList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , CATEGORY_TYPE: this.CATEGORY_TYPE
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt2 = result.data.totalcnt;
                
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                  this.itemList.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(1);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog1    = false;
        this.dialog     = false;
        setTimeout(() => {
          this.editedItem1 = Object.assign({}, this.defaultItem1);
          this.editedItem  = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          this.editedIndex1= -1;
        }, 300)
      },


      //추가 저장
      async save1 () {

        if(this.editedIndex1 > -1){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/CategoryUpdate', {
              CATEGORY_TYPE: this.editedItem1.CATEGORY_TYPE
            , TYPENAME: this.editedItem1.TYPENAME == null || this.editedItem1.TYPENAME == undefined ? '' : escape(this.editedItem1.TYPENAME)
          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });
        }else{
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/CategoryInsert',{
            TYPENAME: this.editedItem1.TYPENAME == null || this.editedItem1.TYPENAME == undefined ? '' : escape(this.editedItem1.TYPENAME)
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                this.$alert.fire("새로운 카테고리가 추가되었습니다.").then(()=>{
                  this.loadpage();
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              console.log("error1 : " + result.data.resultCode);
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            console.log("error2 : " + error);
            this.loading = false;
            this.$alert.fire('추가 실패');
          });
        }

        this.close()
      },            

      //추가 저장
      async save () {

        if(this.uploadFile1 == null || this.uploadFile1 == undefined || this.uploadFile1.length < 1){
            //이미지가 없을 수 있음.

        }else{
            
            this.progress_dialog = true;            
            let imgpath;

            //신규인지 업데이트인지 체크
            if(!this.isNullCheck(this.editedItem.CATEGORY_IMG_REF)){
              imgpath  = this.editedItem.CATEGORY_IMG_REF;
            }else{
              let curdate = Date.now().toString();
              imgpath  = `imgres/${curdate}.jpg`;
            }
          
            //대표 이미지 등록
            const uploadfile_ref1 = fref(this.$firestorage, imgpath);
            const metadata1 = { contentType: 'image/jpeg', resumable: false }; 
            let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadFile1, metadata1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$alert.fire("이미지 등록 오류 발생");
              return;
            } 
            this.uploadFile1 = [];
            /*
            try{
              if(!this.isNullCheck(this.editedItem.CATEGORY_IMG_REF)){
                const desertRef1 = fref(this.$firestorage, this.editedItem.CATEGORY_IMG_REF);
                await deleteObject(desertRef1);
              }
            }catch(err){
            }
            */
            this.editedItem.CATEGORY_IMG_REF = upload_cb1._location.path_;
        }

        if(this.editedIndex > -1){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/CategoryTypeUpdate', {
              CATEGORY_IDX: this.editedItem.CATEGORY_IDX
            , CATEGORY_NAME: this.editedItem.CATEGORY_NAME == null || this.editedItem.CATEGORY_NAME == undefined ? '' : escape(this.editedItem.CATEGORY_NAME)
            , CATEGORY_IMG: this.editedItem.CATEGORY_IMG_REF == null || this.editedItem.CATEGORY_IMG_REF == undefined ? '' : escape(this.editedItem.CATEGORY_IMG_REF)
            , CATEGORY_TYPE: this.editedItem.CATEGORY_TYPE
            , CATEGORY_STATE: this.editedItem.CATEGORY_STATE

          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadcategorylist();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.progress_dialog = false;
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });
        }else{
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/CategoryTypeInsert',{
              CATEGORY_NAME: this.editedItem.CATEGORY_NAME == null || this.editedItem.CATEGORY_NAME == undefined ? '' : escape(this.editedItem.CATEGORY_NAME)
            , CATEGORY_IMG: this.editedItem.CATEGORY_IMG_REF == null || this.editedItem.CATEGORY_IMG_REF == undefined ? '' : escape(this.editedItem.CATEGORY_IMG_REF)
            , CATEGORY_SORT: this.editedItem.CATEGORY_SORT
            , CATEGORY_TYPE: this.editedItem.CATEGORY_TYPE
            , CATEGORY_STATE: this.editedItem.CATEGORY_STATE
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                this.$alert.fire("새로운 카테고리 타입이 추가되었습니다.").then(()=>{
                  this.loadcategorylist();
                });
            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              console.log("error1 : " + result.data.resultCode);
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            console.log("error2 : " + error);
            this.loading = false;
            this.progress_dialog = false;
            this.$alert.fire('추가 실패');
          });
        }

        this.close()
      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage();
      },      

      movepage(p){
        this.$router.push({path:"/ContentTypeList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //신규 추가
      newinsert1(){
        this.editedIndex1  = -1;
        this.editedItem1 = Object.assign({}, this.defaultItem1);
        this.dialog1 = true;
        window.scrollTo(0,0);
      },

      newinsert(){
        this.uploadFile1 = [];
        this.previewImg1 = "";
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.CATEGORY_TYPE = this.CATEGORY_TYPE;
        this.editedItem.CATEGORY_SORT = parseInt(this.itemList.length+1);
        this.dialog = true;
        window.scrollTo(0,0);
      },

      //아이템 수정
      edit_item1(item){
        console.log("edit item : " + JSON.stringify(item));
        this.editedIndex1 = this.CATEGORYTYPE_LIST.indexOf(item);
        this.editedItem1  = Object.assign({}, item);
        this.dialog1 = true;
      },

      edit_item(item){
        console.log("edit item : " + JSON.stringify(item));
        this.uploadFile1 = [];
        this.previewImg1 = "";        
        this.dialogTitle = "수정하기"
        //this.editedIndex = item.CATEGORY_IDX;
        this.editedIndex = this.itemList.indexOf(item);
        console.log("edit item : " + this.editedIndex);
        
        this.editedItem  = Object.assign({}, item);
        this.previewImg1 = this.editedItem.CATEGORY_IMG != null && this.editedItem.CATEGORY_IMG.length > 0 ? this.editedItem.CATEGORY_IMG : "";
        this.dialog = true;
      },



      //아이템 삭제
      delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/CategoryTypeDelete',{
              CATEGORY_IDX:item.CATEGORY_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                
                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  this.loadcategorylist();
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
                
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },      



      async saveSort(){

        if(this.itemList.length <= 1){
          this.$alert.fire('변경하실 내역이 없습니다.');
          return;
        }

        //드래그앤드랍 순서를 저장한다.
        this.$alert.fire({showCancelButton: true, title:'순서를 저장하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            var tempidx = 1;
            this.itemList.forEach(item=>{
              item.CATEGORY_SORT = tempidx;
              tempidx++;
            });
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/CategoryTypeUpdateSort',
              {
                SORTDATA: escape(JSON.stringify(this.itemList))
              },{headers: { firetoken:token}})
            .then((result)=>{
              
              this.loading = false;
              if(result.data.resultCode == 0){

                
                  this.$alert.fire('순서가 저장되었습니다.').then(()=>{
                    this.loadpage();
                  });
    

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('순서 변경이 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('순서 변경이 실패했습니다.');
            });
          }

        });
        
      },                

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


  .image-border {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }
</style>