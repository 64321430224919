<template>
  <v-container style="width:240px;background:white" fluid>

    <v-row justify="center" dense>
      <v-col cols="11">
        <div class="info_text">사용하실 Asset을 클릭하거나 스크린에 드랍하세요</div>
      </v-col>
    </v-row>

      <v-row justify="center" class="mt-3">
          <select 
            v-model="SEARCH_CATEGORY_IDX" 
            @change="loadpage(1)"
            style="width:200px;height:26px;border: 1px gray solid; border-radius:5px; padding-left:5px; background:white;font-size:12px">
            <template v-for="cptype in categoryList" :key="cptype.CATEGORY_NAME">
                <option style="font-size:12px" :value="cptype.CATEGORY_IDX">{{cptype.CATEGORY_NAME}}</option>
            </template>
          </select>
      </v-row>

      <v-row justify="center" class="mt-4">
        <template v-if="restype == 1">
          <v-col
            v-for="item in itemList" style="cursor: grab;"
            class="d-flex justify-center child-flex ma-0 pa-1"
            :key="item"
            cols="12"
            >
            <template v-if="item.ASSET_IDX != undefined">
            <div
              @click="addasset(item)"
              @dragstart="($event) => asset_dragstart($event, item)"
              >
              <ThumScreen
                :id="item.ASSET_IDX"
                :screen_model="item.screen_model"
                :scalewidth="200"
                draggable="true"
                :key="renderCount"
                />
            </div>
            </template>
          </v-col>
        </template>
        <template v-else-if="restype == 2">
          <v-col
            v-for="item in itemList"
            class="d-flex child-flex ma-0 pa-1"
            :key="item"
            cols="6"
            >
            <v-img
              :src="item.IMG_THUM"
              cover
              class="bg-grey-lighten-2"
              @click="addimg(item)"
              @dragstart="($event) => img_dragstart($event, item)"
              />
          </v-col>
        </template>
        <template v-else>
          <v-col
            v-for="item in itemList"
            class="d-flex child-flex ma-0 pa-1"
            :key="item"
            cols="12"
            >
            <video ref="video" style="width:100%" :src="item.MV_PATH" controls 
              @click="addvideo(item)"
              @dragstart="($event) => video_dragstart($event, item)"
              />
          </v-col>
        </template>
      </v-row>

      <!-- 하단페이징영역 -->
      <pagingbar ref="paging" :pagelimit="5" :page="page" @loadpage="movepage"/>  

  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import Screen_Model from '@/nextts/model/Screen_Model';
import Component_Model from '@/nextts/model/Component_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';

export default {

components:{
  ThumScreen,
},

//프로퍼티 선언
props:{
  
},  

setup(){
  var paging = ref(null);
  const contentStore = useContentStore();
  return{ paging, contentStore }
},

data(){
  return{
    renderCount:0,

    totalcnt : 0,       //전체 아이템 개수
    page:1,
    pagerow : 20,

    restype:4,          //폰트 고정
    categoryList:[],
    SEARCH_CATEGORY_IDX:null,
    itemList: [],
  }
},

mounted(){
  this.changerestype();
},

methods:{

  movepage(p){
    this.loadpage(p);
  },

  changerestype(){
    this.itemList.splice(0, this.itemList.length);   //데이터 초기화
    this.loadanicategory();
  },

  asset_dragstart(event, item){
    //console.log("asset_dragstart : " + JSON.stringify(item));
    event.dataTransfer.setData('assetsData', JSON.stringify(item));
  },

  //에셋 클릭시 중앙에 추가한다.
  addasset(item){
    var temp = JSON.parse(JSON.stringify(item.ASSET_JSON));
    const sm = new Screen_Model();
    sm.setStringValue(temp.screen_list[0]);
    //에셋 데이터 그룹핑 처리
    var curdate = new Date();
    var gid = "gid_" + curdate.getMilliseconds();

    //해당 assets의 최소 x, y값 구하기.
    const minX = Math.min(...sm.component_list.map(cm => cm.posx));
    const minY = Math.min(...sm.component_list.map(cm => cm.posy));

    //현재 컨텐츠의 넓이와 높이
    const content_width  = this.contentStore.content_model.width;
    const content_height = this.contentStore.content_model.height;

    sm.component_list.forEach((component)=>{
      component.isgroup = true;
      component.groupid = gid;

      //폰트 처리
      if(component.font_url != null && component.font_url != undefined && component.font_url.length > 0){
        let fonturl = "url('"+component.font_url+"')";
        if(component.font_format != null && component.font_format != undefined && component.font_format.length > 0){
          fonturl += " format('"+component.font_format+"')";
        }
        const fontoption = {weight:component.font_weight.length == 0 ? "normal" : component.font_weight, style:component.font_style};
        const fontface = new FontFace(component.textfont, fonturl, fontoption);
        document.fonts.add(fontface);
      }
      
      //중앙에 배치
      component.posx   = component.posx - minX + (content_width-component.width)/2;
      component.posy   = component.posy - minY + (content_height-component.height)/2;
      component.transx = component.transx - minX + (content_width-component.width)/2;
      component.transy = component.transy - minY +  (content_height-component.height)/2;

      this.contentStore.select_screen.component_list.push(component);
    });
    this.$emitter.emit('drawscreen');
  },


  img_dragstart(event, item){
    //console.log("img_dragstart : " + JSON.stringify(item));
    event.dataTransfer.setData('imgData', JSON.stringify(item));
  },

  async addimg(item){

    var temp = await this.loadImage(item.IMG_PATH);

    //현재 컨텐츠의 넓이와 높이
    const content_width  = this.contentStore.content_model.width;
    const content_height = this.contentStore.content_model.height;

    const component  = new Component_Model();
    component.component_type = Component_Model.COMPONENT_IMAGE;
    component.image  = item.IMG_PATH;
    component.width  = temp.width;
    component.height = temp.height;
    component.posx   = (content_width-component.width)/2;
    component.posy   = (content_height-component.height)/2;
    component.transx = (content_width-component.width)/2;
    component.transy = (content_height-component.height)/2;
    component.rotate = 0;
    this.contentStore.select_screen.component_list.push(component);
    this.$emitter.emit('drawscreen');

  },
  
  video_dragstart(event, item){
    event.dataTransfer.setData('videoData', JSON.stringify(item));
  },

  async addvideo(item){

    var temp = await this.loadVideo(item.MV_PATH);
    
    //현재 컨텐츠의 넓이와 높이
    const content_width  = this.contentStore.content_model.width;
    const content_height = this.contentStore.content_model.height;

    const component  = new Component_Model();
    component.component_type = Component_Model.COMPONENT_MOVIE;
    component.movie  = item.MV_PATH;
    component.movie_time = temp.duration;
    component.width  = temp.videoWidth;
    component.height = temp.videoHeight;
    component.posx   = (content_width-component.width)/2;
    component.posy   = (content_height-component.height)/2;
    component.transx = (content_width-component.width)/2;
    component.transy = (content_height-component.height)/2;
    component.rotate = 0;
    this.contentStore.select_screen.component_list.push(component);
    this.$emitter.emit('drawscreen');
    
  },


  loadImage(imgurl){
    return new Promise((resolve, reject)=>{
      var tempimg = new Image();
      tempimg.onload = () =>resolve(tempimg);
      tempimg.onerror = reject;
      tempimg.src = imgurl;
    })
  },    

  loadVideo(videourl){
    return new Promise((resolve, reject)=>{
      const video = document.createElement('video');
      video.onloadedmetadata = function() {
        // 로드가 완료되면 비디오 객체를 resolve
        resolve(video);
      };        
      video.onerror = function() {
        // 로드 실패 시 reject 호출
        reject(new Error('비디오 로드 실패'));
      };        
      video.src = videourl;
    })
  },    

  async loadanicategory(){
    //토큰값 체크
    let token;
    try{
    token = await this.$fireauth.currentUser.getIdToken();
    }catch(error){
    token = this.$storage.getStorageSync("FIRETOKEN");
    }
    
    this.$http.post(this.$host+'/CategoryTypeList',{
          search_keyword: ''
        , CATEGORY_TYPE: this.restype
        , sort_name: "CATEGORY_SORT"
        , sort_type: "ASC"
    },{headers: { firetoken:token}})
    .then((result)=>{
      
      if(result.data.resultCode == 0){
          const list = result.data.resultData;
          this.categoryList.splice(0, this.categoryList.length);   //데이터 초기화
          this.categoryList.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
          list.forEach(element => {
            this.categoryList.push(element); 
          });
          
          this.SEARCH_CATEGORY_IDX = this.categoryList[0].CATEGORY_IDX;
          this.loadpage(1);

      }else if(result.data.resultCode == 2){
        //로그인 필요
        this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
        this.$emitter.emit('clearsession');
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((error)=>{
      if(error.message == 'Request failed with status code 429')
      {
        this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
      }else{
        this.$alert.fire(error.message);
      }
    });

  },


  loadpage(p){
    console.log("restype : " + this.restype);
    
    if(this.restype == 1){
      this.assetloadpage(p);
    }else if(this.restype == 2){
      this.imgloadpage(p);
    }else if(this.restype == 3){
      this.videoloadpage(p);
    }
    this.renderCount++;
  },


  async assetloadpage(p){

      //토큰값 체크
      let token;
      try{
      token = await this.$fireauth.currentUser.getIdToken();
      }catch(error){
      token = this.$storage.getStorageSync("FIRETOKEN");
      }

      this.page = p;
      this.paging.setpage(this.page);
      this.$http.post(this.$host+'/AssetList',{
            search_keyword: ""
          , sort_name: "REG_DATE"
          , sort_type: "DESC"
          , page: this.page
          , pagerow: this.pagerow
          , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX
      },{headers: { firetoken:token}})
      .then((result)=>{
        
        if(result.data.resultCode == 0){

            this.totalcnt = result.data.totalcnt;
            this.paging.makepaging(this.totalcnt, this.pagerow);
            const list = result.data.resultData;
            this.itemList.splice(0, this.itemList.length);   //데이터 초기화
            list.forEach(element => {
                let screen_model        = new Screen_Model();
                screen_model.setStringValue(element.THUM_JSON);
                screen_model.cacheFont();
                element.screen_model = screen_model;
                this.itemList.push(element); 
            });
        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
          this.$emitter.emit('clearsession');
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });

  },

  async imgloadpage(p){
          //토큰값 체크
          let token;
          try{
          token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
          }

      this.page = p;
      this.paging.setpage(this.page);
      this.$http.post(this.$host+'/ResImgList',{
            search_keyword: ""
          , sort_name: "REG_DATE"
          , sort_type: "DESC"
          , page: this.page
          , pagerow: this.pagerow
          , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX
      },{headers: { firetoken:token}})
      .then((result)=>{
        
        if(result.data.resultCode == 0){
            this.totalcnt = result.data.totalcnt;
            this.paging.makepaging(this.totalcnt, this.pagerow);
            const list = result.data.resultData;
            this.itemList.splice(0, this.itemList.length);   //데이터 초기화
            list.forEach(element => {
                this.itemList.push(element); 
            });

        }else if(result.data.resultCode == 2){
          //로그인 필요
          this.loading = false;
          this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
          this.$emitter.emit('clearsession');
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });

  },


  async videoloadpage(p){
          //토큰값 체크
          let token;
          try{
          token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
          }

    this.page = p;
    this.paging.setpage(this.page);
    this.$http.post(this.$host+'/ResMvList',{
          search_keyword: ""
        , sort_name: "REG_DATE"
        , sort_type: "DESC"
        , page: this.page
        , pagerow: this.pagerow
        , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX
    },{headers: { firetoken:token}})
    .then((result)=>{
      
      if(result.data.resultCode == 0){
          this.totalcnt = result.data.totalcnt;
          this.paging.makepaging(this.totalcnt, this.pagerow);
          const list = result.data.resultData;
          this.itemList.splice(0, this.itemList.length);   //데이터 초기화
          list.forEach(element => {
              this.itemList.push(element); 
          });
      }else if(result.data.resultCode == 2){
        this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
        this.$emitter.emit('clearsession');

      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((error)=>{
      if(error.message == 'Request failed with status code 429')
      {
        this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
      }else{
        this.$alert.fire(error.message);
      }
    });

  },    



}

}
</script>