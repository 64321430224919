
import Component_Model from  '@/nextts/model/Component_Model';
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';
import gsap from "gsap";
import Content_Model from '@/nextts/model/Content_Model';
import { useContentStore } from "@/stores/content";
import Play_Api from './Play_Api';
import { emitter } from '@/emitter';
import Play_Sound from './Play_Sound';

//기본 데이터 속성
class Play_Image extends HTMLElement implements Http_Interface, Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;

    contentStore = useContentStore();
    private updateTextHandler:any;
    private updateTextClickHandler:any;
    private updateTextTouchHandler:any;

    //이미지 마스크용
    base64String?:null|string;

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.position = "absolute";
    }
    
    //Play_Interface 구현
    init(): void {
        //console.log("Play Image init");
        //커스텀 api등을 통해야 하는 경우에는 분기하고
        if(this.model != null && this.model.api_use){
            //emitter로 호출하는데 bind를 안해주면 this에 접근을 못한다.
            this.updateTextHandler = this.updatetext_handler.bind(this);
            emitter.on('updatetext', this.updateTextHandler);
        }

        //이벤트 처리
        if(this.model?.event_use){
            if(this.model.event_type >= 1){
                //클릭 리스너 붙이기
                this.updateTextClickHandler = this.handleClick.bind(this);
                this.updateTextTouchHandler = this.handleTouchStart.bind(this);
                this.addEventListener('click',      this.updateTextClickHandler);
                this.addEventListener('touchstart', this.updateTextTouchHandler);                
            }
        }

        //컴포넌트 시작
        this.playAni();

    }


    handleClick(event: MouseEvent){
        this.clickevent();
    }
    
    handleTouchStart(event: TouchEvent){
        this.clickevent();
    }    

    clickevent(){

        console.log('Element was clicked!', this.model?.event_type);

        if(this.model?.event_type == 1){
            
            //페이지 이동 - 전체 컨텐츠에서 페이지 이름을 찾아서 이동한다.
            for(let i=0; i < this.contentStore.content_model.screen_list.length; i++){
                const screen= this.contentStore.content_model.screen_list[i];
                if(this.model.event_move == screen.screen_name){
                    console.log("move screen name : " + screen.screen_name);
                    emitter.emit('movepage', i);
                    break;
                }
            }


        }else if(this.model?.event_type == 2){
            //api 호출 - 현재 스크린에서 api를 찾아서 호출한다.
            if(this.contentStore.select_screen == null) return;
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_API && this.model.event_api == component.apiname){
                    
                    console.log("call api name : " + component.apiname);
                    console.log("call api component : " + component.play_component);
                    
                    const api_component = component.play_component as Play_Api;
                    api_component.runApiAwait();
                    break;

                }
            }

        }else if(this.model?.event_type == 3){
            //타이핑 - 현재 스크린에서 text 타겟을 찾아서 입력한다.
            if(this.contentStore.select_screen == null) return;

            if(this.model.event_input_key == null || this.model.event_input_key == undefined || this.model.event_input_key.length == 0){
                return;
            }
            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText += this.model.event_input_key;
                        break;
                    }
                }
            }
        }else if(this.model?.event_type == 4){

            //1글자 지우기
            if(this.contentStore.select_screen == null) return;

            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }

            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText = component.play_component.innerText.slice(0, -1);
                        break;
                    }
                }
            }


        }else if(this.model?.event_type == 5){
            //모두 지우기
            if(this.contentStore.select_screen == null) return;
            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText = "";
                        break;
                    }
                }
            }

        }else if(this.model?.event_type == 6){
            //타이핑 - 교체
            if(this.contentStore.select_screen == null) return;

            if(this.model.event_input_key == null || this.model.event_input_key == undefined || this.model.event_input_key.length == 0){
                return;
            }
            if(this.model.event_input_target == null || this.model.event_input_target == undefined || this.model.event_input_target.length == 0){
                return;
            }
            for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                const component = this.contentStore.select_screen.component_list[i];
                if(component.component_type == Component_Model.COMPONENT_TEXT && this.model.event_input_target == component.event_name){
                    if(component.play_component != null){
                        component.play_component.innerText = String(this.model.event_input_key);
                        break;
                    }
                }
            }
    
         }else if(this.model?.event_type == 20){
                //사운드 재생
                if(this.contentStore.select_screen == null) return;
                for(let i=0; i < this.contentStore.select_screen?.component_list.length; i++){
                    const component = this.contentStore.select_screen.component_list[i];
                    if(component.component_type == Component_Model.COMPONENT_SOUND && this.model.event_input_target == component.event_name){
                        
                        const api_component = component.play_component as Play_Sound;
                        api_component.soundplayevent();
                        break;
    
                    }
                }
            
        }        

    }

    //json값에서 path에 해당하는 값을 꺼냄.
    getValueByPath<T>(json: Record<string, any>, path: string): T | undefined {
        //const parts = path.match(/\w+/g) || []; // 경로 문자열에서 키 이름 추출
        //return parts.reduce((acc: any, part: string) => acc && acc[part], json) as T;

        const parts = path.match(/\[([^\]]+)\]/g) || [];
        return parts.reduce((acc: any, part: string) => {
            // 대괄호를 제거하고 실제 키값만 추출
            const key = part.replace(/\[|\]/g, '');
            // 숫자로 변환할 수 있는 키는 배열의 인덱스로, 그렇지 않은 키는 객체의 키로 처리
            return acc && (acc.hasOwnProperty(key) ? acc[key] : acc[Number(key)]);
        }, json) as T;

    }

    exit(): void {
        
        if(this.model != null && this.model.api_use){
            emitter.off('updatetext', this.updateTextHandler);
        }
        
        if(this.model?.event_use){
            if(this.model.event_type >= 1){
                //클릭 리스너 붙이기
                this.removeEventListener('click',       this.updateTextClickHandler);
                this.removeEventListener('touchstart',  this.updateTextTouchHandler);                
            }
        }

        //효과음 초기화 (일단 패스)

        //타이머 초기화

        //스위처 초기화

        //애니객체 초기화
        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }

        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
        }


    }


    async updatetext_handler(){

        if(this.model == null || this.model == undefined){
            return;
        }
        
        if(this.model.api_use){
            //api 사용이면 맵핑값 가져오기
            if(Content_Model.apiData.has(this.model.apiname)){
                const resultdata = Content_Model.apiData.get(this.model.apiname);
                const dataary = this.getValueByPath(resultdata, this.model.apiarray) as any;
                if(dataary != null && dataary != undefined){
                    if(dataary.toLowerCase().startsWith("file")){
                        this.style.backgroundImage  = "url('"+dataary+"')"
                    }else{
                        await this.loadImageAsync(dataary);
                        this.style.backgroundImage  = "url('"+dataary+"')"
                    }
                }else{
                    this.style.backgroundImage  = "";
                }
            }else{
                this.style.backgroundImage  = "";
            }
        }
    }    

    loadImageAsync(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
    }        

    playAni(){
        
        if(this.model == null) return;

        //애니가 있으면 offsettime만큼 대기 후 실행
        if(this.model.aniplaymodel != null && this.model.aniplaymodel.anilist != null && this.model.aniplaymodel.anilist.length > 0){
            //첫번째 이펙트 대기시간 만큼 대기 후 뷰를 그리고 애니를 시작한다.
            setTimeout(() => {
                this.drawView();
                this.runAni();
            }, Number(this.model.aniplaymodel.anilist[0].delay) * 1000);
        }else{
            //애니 없음
            this.drawView();
        }

    }

    //애니매이션 실행
    runAni(){
        
        if(this.model == null) return;

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.model.aniplaymodel.repeat), repeatDelay:Number(this.model.aniplaymodel.repeatDelay), yoyo:Boolean(this.model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.model.transx);
        let move_posy   = Number(this.model.transy);
        let move_rotate = Number(this.model.rotate);
        //회전, 로테이션, 알파는 

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.model.alpha });

        this.model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);
            
            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
        });        

    }


    async drawView(){
        
        if(this.model == null || this.model == undefined){
            return;
        }

        //컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 해라.
        this.style.backgroundColor  = "#00000000";
        this.style.width  = this.model.width+"px";
        this.style.height = this.model.height+"px";
        this.style.opacity          = String(this.model.opacity);
        
        /*
        if(this.model.bgtype == 0){
            //color
            this.style.backgroundColor  = this.model.backgroundColor;
            this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.model.bgtype == 1){
            //image
            this.style.backgroundColor  = "#00000000";
            this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.style.backgroundImage  = "url('"+this.model.backgroundImg+"')"
            this.style.backgroundRepeat = "no-repeat";
        }
        */
        this.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
        this.style.backgroundRepeat = "no-repeat";

        if(this.model.api_use){
            //api 사용이면 맵핑값 가져오기
            if(Content_Model.apiData.has(this.model.apiname)){
                const resultdata = Content_Model.apiData.get(this.model.apiname);
                const dataary = this.getValueByPath(resultdata, this.model.apiarray) as any;
                if(dataary != null && dataary != undefined){
                    if(dataary.toLowerCase().startsWith("file")){
                        this.style.backgroundImage  = "url('"+dataary+"')"
                    }else{
                        await this.loadImageAsync(dataary);
                        this.style.backgroundImage  = "url('"+dataary+"')"
                    }
                }else{
                    this.style.backgroundImage  = "";
                }
            }else{
                this.style.backgroundImage  = "";
            }
        }else{
            if(this.model.image != null && this.model.image != undefined && this.model.image.length > 0){
                if(this.model.image.toLowerCase().startsWith("http")){
                    //인터넷에 업로드된 이미지면
                    this.style.backgroundImage  = "url('"+this.model.image+"')"
                }else{
                    this.style.backgroundImage  = `url(${this.model.image})`;
                }
            }
        }

        //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
        if(this.model.mask_use){

            this.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
            this.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
            if(this.model.mask_repeat){
                this.style.webkitMaskRepeat = "repeat";    
            }else{
                this.style.webkitMaskRepeat = "no-repeat";    
            }
            if(this.model.mask_image.length > 0){

                if(this.model.mask_image.toLowerCase().startsWith("http")){
                    //인터넷에 업로드된 이미지면
                    try {
                        if(this.base64String == null){
                            this.base64String = await this.convertImageToBase64(this.model.mask_image);
                        }
                        this.style.webkitMaskImage = `url(${this.base64String})`;    
                    } catch (error) {
                        console.error("Error during image conversion to Base64:", error);
                    }                        
                }else{
                    this.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }
                
            }else{
                this.style.webkitMaskImage = "";    
            }
            
        }else{
            this.style.webkitMaskImage = "";
            this.style.webkitMaskPosition = "";
            this.style.webkitMaskSize = "";
        }

        //input 텍스트처리 (더블클릭시)
        this.contentEditable = "false";
        this.innerText = "";
        
        this.style.transform = `translateX(${this.model.transx}px) translateY(${this.model.transy}px) rotate(${this.model.rotate}deg)`;
        
    }


    //이미지 마스크 CORS 우회 처리
    loadMaskImageAsync(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // CORS 정책 준수를 위해 필요
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image at ${url}`));
            img.src = url;
        });
    }

    convertImageToBase64(url: string): Promise<string> {
        return this.loadMaskImageAsync(url).then((img: HTMLImageElement) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) {
                throw new Error('Unable to get canvas context');
            }
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            return canvas.toDataURL('image/png');
        }).catch(error => {
            console.error("Error in converting image to Base64:", error);
            throw error;
        });
    }    

    //Http_Interface 구현
    resultHttp(a_id:number, result:string, obj:object){

        
    }

}

export default Play_Image
customElements.define('play-image', Play_Image);