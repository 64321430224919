<template>
  <v-container>

    <v-row>
      <v-btn @click="test1">문서읽기</v-btn>
      <v-btn @click="test2">컬렉션 리스트의 문서읽기</v-btn>
      <v-btn @click="test3">문서에 부분쓰기</v-btn>
    </v-row>

    <v-row>
      <v-btn @click="test4">로그인정보 쓰기</v-btn>
      <v-btn @click="test5">로그인정보 삭제</v-btn>
      <v-btn @click="test6">로그인정보 읽기</v-btn>
      <v-btn @click="test7">리스너등록</v-btn>
      <v-btn @click="test8">리스너해제</v-btn>
    </v-row>

    <v-row>
      <v-btn @click="storewrite">store 쓰기</v-btn>
      <v-btn @click="storeupdate">store 수정</v-btn>
      <v-btn @click="storedelete">store 삭제</v-btn>
      <v-btn @click="storeread">store 읽기</v-btn>
    </v-row>

    <v-row>
      <v-btn @click="writedb">리얼디비 쓰기</v-btn>
      <v-btn @click="updatedb">리얼디비 수정</v-btn>
      <v-btn @click="readdb">리얼디비 읽기</v-btn>
      <v-btn @click="getdb">리얼디비 삭제</v-btn>
      <v-btn @click="readdb">리얼디비 등록</v-btn>
      <v-btn @click="exitdb">리얼디비 해제</v-btn>
    </v-row>

    <v-row>
      <v-btn @click="api1">api전체교체</v-btn>
      <v-btn @click="api2">api배열교체</v-btn>
      <v-btn @click="api3">api배열추가</v-btn>
      <v-btn @click="api4">api배열삭제</v-btn>
      <v-btn @click="api5">api필드교체</v-btn>
    </v-row>

  </v-container>
</template>

<script>
import { query, orderBy, deleteDoc, onSnapshot, getDoc, doc, setDoc, collection, updateDoc, getDocs, serverTimestamp, arrayUnion, deleteField } from "firebase/firestore";
import { ref as dbref, set, get, onValue, off } from "firebase/database";

export default {

  data:()=>({
    
    unlogin:null,
      
  }),


  mounted(){
    


  },


  methods:{

    async test1(){
      
      //이건 문서의 필드값을 리턴하는거다.
      const docRef = doc(this.$firestore, "users", "1", "docs", "page");
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()){
        console.log("Document data:", docSnap.id + " : " + JSON.stringify(docSnap.data().datamap));
      } else {
        console.log("No such document!");
      }   
    },

    async test2(){
      //컬렉션 리스트에 있는 문서값만 리턴함. 컬렉션이라고 해도 문서값이 없으면 실행되지 않아.
      try{

        const docRef = collection(this.$firestore, "users");
        const docSnap = await getDocs(docRef);
        docSnap.forEach((doc) => {
          console.log("Document data:", doc.id + " : " + JSON.stringify(doc.data()));
        });        
       

      }catch(error){
        console.log("error : " + error);
      }
    },

    async test3(){
      //부분 수정 후 저장
      const docRef = doc(this.$firestore, "users", "1", "docs", "page");
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()){
        //console.log("Document data:", docSnap.id + " : " + JSON.stringify(docSnap.data().datamap));
        const docData = docSnap.data();
        console.log("docData1 : " + JSON.stringify(docData));
        docData.datamap.test1[1].name = "야얍";
        console.log("docData2 : " + JSON.stringify(docData));
        //문서 전체 업데이트
        await updateDoc(docRef, docData);

      } else {
        console.log("No such document!");
      }   
    },


    async test4(){

      //장비별 로그인 데이터 쓰기
      //7e12d20d285643d0b0c403757c03ab4458b2cfb9d7bd992ca6854cb837263c57
      const docRef  = doc(this.$firestore, "company", "43Khfkr7iNbf2gj61jeehADiQMs2", "users", "Y6417MWxAANh96rk1F2F2DjHCnE2", "login", "logintime");

      /*
      for(var i=0;i<10;i++){
        const docRef  = doc(this.$firestore, "company", "43Khfkr7iNbf2gj61jeehADiQMs2", "users", "Y6417MWxAANh96rk1F2F2DjHCnE2", "logintime", `testdevice${i}`);
        await setDoc(docRef, {login_dates:serverTimestamp()}, {merge: true});
      }
      */
      for(var i=0;i<10;i++){
        await updateDoc(docRef, { devicelist: arrayUnion({ DEVICE_KEY: `testdevice_${i}`, LOGIN_DATE: new Date().toISOString()})});
      }
    
      
    },    

    async test5(){
      //로그인 정보 삭제
      const docRef  = doc(this.$firestore, "company", "43Khfkr7iNbf2gj61jeehADiQMs2", "users", "Y6417MWxAANh96rk1F2F2DjHCnE2", "login", "logintime");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const updatedList = data.devicelist.filter(device => device.DEVICE_KEY !== "1111");
        await updateDoc(docRef, { devicelist: updatedList });        
      }
    },

    async test6(){

      //로그인 정보 읽기
      const docRef  = doc(this.$firestore, "company", "43Khfkr7iNbf2gj61jeehADiQMs2", "users", "Y6417MWxAANh96rk1F2F2DjHCnE2", "login", "logintime");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const sortedList = data.devicelist.sort((a, b) => new Date(b.LOGIN_DATE) - new Date(a.LOGIN_DATE));
        console.log('Sorted devices:', sortedList);

        //배열 자르기 (동접개수 만큼)
        const limitedLogins = sortedList.slice(0, 5);

        // 특정 ID 값이 배열에 존재하는지 확인합니다.
        const exists = limitedLogins.some(item => item.DEVICE_KEY === "testdevice_5");      
        if(exists){
          console.log("login pass!");
        }else{
          console.log("login fail!");
        }

      }
    },

    async test7(){

      if(this.unlogin){
        this.unlogin();
      }

      //로그인 정보 리스닝
      const docRef  = doc(this.$firestore, "company", "43Khfkr7iNbf2gj61jeehADiQMs2", "users", "Y6417MWxAANh96rk1F2F2DjHCnE2", "login", "logintime");
      this.unlogin = onSnapshot(docRef, (doc)=>{
        
        const data = doc.data();

        console.log("devicelist : " + data.devicelist.length);

        //특정 ID 값이 배열에 존재하는지 확인합니다.
        /*
        const exists = data.devicelist.some(item => item.DEVICE_KEY === "testdevice_5");      
        if(exists){
          console.log("login pass!");
        }else{
          console.log("login fail!");
        }
        */

      });

    },

    test8(){

      if(this.unlogin){
        this.unlogin();
      }

    },  


  //파이어베이스 파이어스토어 샘플
  async writeStore(){
        //쓰기
        const connectedRef = await doc(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "connected", String(23));
        //덮어씀. merge옵션을 해줘야 중복되지 않는 컬럼이 살아있게 됨.
        await setDoc(connectedRef, { CHECK_DATE: this.$momenttz.utc().format("YYYY-MM-DD HH:mm:ss") }, {merge: true});
      },

  readStore(){
    //리스너 등록
    const connectedRef = doc(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "connected", String(23));
    console.log("readdb : " + connectedRef.path);
    this.unConnected = onSnapshot(connectedRef, (data)=>{
      console.log(`onSnapshot : ${ JSON.stringify(data.data()) }`);
    });
  },

  readcolStore(){
    //컬렉션 리스너 등록
    const connectedRef = collection(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "connected");
    console.log("readdb : " + connectedRef.path);
    this.unConnected = onSnapshot(connectedRef, (snapshot)=>{

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          //최초 조회시 모든 문서는 이쪽으로 한번 탄다. (문서가 10개면 10번 호출됨)
          this.checkPlay(change.doc);
        }
        if (change.type === "modified") {
          //수정된 문서만 탄다.
          this.checkPlay(change.doc);
          //console.log("Modified document: ", change.doc.id + " : " + change.doc.data().PLAY + " / " + change.doc.data().CHECK_DATE);
        }
        if (change.type === "removed") {
          console.log("Removed document: ", change.doc.data());
        }

      });

    });
  },

  async getStore(){
    //1회 읽기
    const connectedRef = doc(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "connected", String(23));
    const docSnap = await getDoc(connectedRef);
    if (docSnap.exists()) {
      console.log(`Data: ${JSON.stringify(docSnap.data())}`);
    } else {
      console.log("No data available");
    }      
  },

  //컬렉션 읽기
  async getcolStore(){
    const connectedRef = collection(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "connected");
    try {
      const querySnapshot = await getDocs(connectedRef);
      querySnapshot.forEach((doc) => {
        console.log(doc.id);
        //doc.id, doc.data().PLAY 등으로 읽어라.
      });
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  },

  exitStore(){
    //리스너 해제
    if(this.unConnected){
      this.unConnected();
    }
  },

  //리스너로 컬렉션 데이터 수정 감지
  readcoldb(){

    //컬렉션 리스너 등록
    const connectedRef = collection(this.$firestore, "company", this.$storage.getStorageSync("SEARCH_PARENT_LOCALID"), "users", this.$storage.getStorageSync("SEARCH_LOCALID"), "connected");
    this.unConnected = onSnapshot(connectedRef, (snapshot)=>{
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          //최초 조회시 모든 문서는 이쪽으로 한번 탄다. (문서가 10개면 10번 호출됨)
          //this.checkPlay(change.doc);
        }
        if (change.type === "modified") {
          //수정된 문서만 탄다.
          //this.checkPlay(change.doc);
          //console.log("Modified document: ", change.doc.id + " : " + change.doc.data().PLAY + " / " + change.doc.data().CHECK_DATE);
        }
        if (change.type === "removed") {
          //console.log("Removed document: ", change.doc.data());
        }
      });
    });
  },

  /****************************************************************/
  // 파이어베이스 파이어스토어 샘플
  /****************************************************************/
  //신규작성, 덮어쓰기
  async storewrite(){
    const connectedRef = await doc(this.$firestore, "company","43Khfkr7iNbf2gj61jeehADiQMs2","users","Y6417MWxAANh96rk1F2F2DjHCnE2","docs","page");
    //await setDoc(connectedRef, { CHECK_DATE: this.$momenttz.utc().format("YYYY-MM-DD HH:mm:ss"), HOHOHO:1234 });

    //이렇게 넣으면 된다.
    const map = {};
    const test1 = [];
    test1.push({NAME:'NAME1', GRADE:1, TEL:'01012341234'});
    test1.push({NAME:'NAME2', GRADE:2, TEL:'01012341235'});
    test1.push({NAME:'NAME3', GRADE:3, TEL:'01012341236'});
    map["이름"] = [...test1];

    const test2 = [];
    test2.push({TAG:'TAG1', GRADE:1, POINT:9999});
    test2.push({TAG:'TAG2', GRADE:2, POINT:5555});
    test2.push({TAG:'TAG3', GRADE:3, POINT:3333});
    map["태그"] = [...test2];

    await setDoc(connectedRef, { dataformat: map });

  },

  //부분 수정
  async storeupdate(){
    const connectedRef = await doc(this.$firestore, "company","43Khfkr7iNbf2gj61jeehADiQMs2","users","Y6417MWxAANh96rk1F2F2DjHCnE2","docs","page");
    //await updateDoc(connectedRef, { CHECK_DATE: this.$momenttz.utc().format("YYYY-MM-DD HH:mm:ss"), HOHOHO:1234 });

    await updateDoc(connectedRef, { CHECK_DATE: this.$momenttz.utc().format("YYYY-MM-DD HH:mm:ss"), HOHOHO:1234 });

  },

  //부분 삭제 (필드만)
  async storedelete(){
    const connectedRef = await doc(this.$firestore, "company","43Khfkr7iNbf2gj61jeehADiQMs2","users","Y6417MWxAANh96rk1F2F2DjHCnE2","docs","page");
    await updateDoc(connectedRef, {HOHOHO: deleteField()});
  },

  //문서 삭제
  async storedelete2(){
    const connectedRef = await doc(this.$firestore, "company","43Khfkr7iNbf2gj61jeehADiQMs2","users","Y6417MWxAANh96rk1F2F2DjHCnE2","docs","page");
    await deleteDoc(connectedRef);
  },

  async storeread(){
    
  },

  /****************************************************************/
  //파이어베이스 리얼타임 데이터베이스 샘플
  /****************************************************************/
    writedb(){
      
      //리얼디비 쓰기
      const device_Ref = dbref(this.$firedb, `device/${this.$storage.getStorageSync("SEARCH_PARENT_LOCALID")}/${this.$storage.getStorageSync("SEARCH_LOCALID")}/22`);

      //set은 전체를 덮어씀.
      set(device_Ref, {
        CHECK_DATE: this.$momenttz.utc().format("YYYY-MM-DD HH:mm"), TEST:"HAHAHA", TEST2:12345
      });

    },
    
    updatedb(){
      //부분 수정
      const device_Ref = dbref(this.$firedb, `device/${this.$storage.getStorageSync("SEARCH_PARENT_LOCALID")}/${this.$storage.getStorageSync("SEARCH_LOCALID")}/22/TEST`);
      set(device_Ref, "HOHOHO");
    },  

    readdb(){
      //실시간 디비 리스너 등록 - 변경시 계속 콜백됨.
      const read_Ref = dbref(this.$firedb, `device/${this.$storage.getStorageSync("SEARCH_PARENT_LOCALID")}/${this.$storage.getStorageSync("SEARCH_LOCALID")}`);
      onValue(read_Ref, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
      });

    },

    async getdb(){
      //디비 1회 읽기
      const read_Ref = dbref(this.$firedb, `device/${this.$storage.getStorageSync("SEARCH_PARENT_LOCALID")}/${this.$storage.getStorageSync("SEARCH_LOCALID")}`);
      const snapshot = await get(read_Ref);
      if (snapshot.exists()) {
        snapshot.forEach((childSnapshot) => {
          const key = childSnapshot.key;
          const value = childSnapshot.val();
          console.log(`Key: ${key}, Value: ${JSON.stringify(value)}, test: ${value.CHECK_DATE}`);
        });          
      } else {
        console.log("No data available");
      }
    
    },

    exitdb(){
      //실시간 디비 리스너 해제
      const read_Ref = dbref(this.$firedb, `device/${this.$storage.getStorageSync("SEARCH_PARENT_LOCALID")}/${this.$storage.getStorageSync("SEARCH_LOCALID")}`);
      off(read_Ref);

    },

    //문서 전체 교체
    api1(){

      const info = [];
      info.push({"입장":"999", "퇴장":"999", "체류":"999"});
      info.push({"입장":"888", "퇴장":"888", "체류":"888"});
      info.push({"입장":"777", "퇴장":"777", "체류":"777"});

      this.$http.post(this.$host+'/apiSetStoreDoc',{
          APIKEY:""    
        , PAGENAME:"입출입"
        , INFO: JSON.stringify(info)
      },{headers: { apikey:"4XBtRWw5XY8A9NPRZz8wPmv5SDQtcTmHQPjytjG"}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //success

        }else{
          //fail
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });      

    },

    //배열 1개 교체
    api2(){

      const info = {"입장":"666", "퇴장":"666", "체류":"666"};
      this.$http.post(this.$host+'/apiUpdateStoreArray',{
          PAGENAME:"입출입"
        , INFO: JSON.stringify(info)
        , ROW:1
      },{headers: { apikey:"4XBtRWw5XY8A9NPRZz8wPmv5SDQtcTmHQPjytjG"}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //success

        }else{
          //fail
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });            
1
    },

    //배열 1개 추가
    api3(){
      const info = {"입장":"444", "퇴장":"444", "체류":"444"};
      this.$http.post(this.$host+'/apiInsertStoreArray',{
          PAGENAME:"입출입"
        , INFO: JSON.stringify(info)
      },{headers: { apikey:"4XBtRWw5XY8A9NPRZz8wPmv5SDQtcTmHQPjytjG"}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //success

        }else{
          //fail
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });      

    },

    //배열 1개 삭제
    api4(){
      
      this.$http.post(this.$host+'/apiDeleteStoreArray',{
          PAGENAME:"입출입"
        , ROW:1
      },{headers: { apikey:"4XBtRWw5XY8A9NPRZz8wPmv5SDQtcTmHQPjytjG"}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //success

        }else{
          //fail
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });      

    },    


    //특정 배열의 필드만 수정
    api5(){
      
      this.$http.post(this.$host+'/apiUpdateFieldStore',{
          PAGENAME:"입출입"
        , ROW:1
        , FIELDNAME: "퇴장"
        , INFO:"123"
      },{headers: { apikey:"4XBtRWw5XY8A9NPRZz8wPmv5SDQtcTmHQPjytjG"}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //success

        }else{
          //fail
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        this.loading = false;
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });      

    },        

  },
  

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .parentDiv {
    display: flex;
    flex-direction: column; /* 세로로 배치 */
    align-items: center; /* 가로 정렬을 가운데로 설정 */
    height: 100vh; /* 부모 요소의 높이를 화면 전체 높이로 설정 (선택적) */
  }

  .childDiv {
    width: 80%; /* 자식 요소의 너비 설정 */
    max-width: 400px; /* 자식 요소의 최대 너비 설정 (선택적) */
    margin-bottom: 20px; /* 자식 요소 간의 간격 설정 (선택적) */
    text-align: center; /* 자식 요소 내용 가운데 정렬 (선택적) */
    
  }

</style>