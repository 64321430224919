<!-- 리소스는 사용 안해도 될 것 같아서 여기에 그냥 파베 테스트 했음. -->

<template>

  <v-container fluid>

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" :msg="progress_msg" :progress="progress_value" />

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>        

    <v-row align="center" justify="start" dense>
      <v-col cols="12">
        <label class="menunamestyle me-auto">{{ curArrayName }}</label>
      </v-col>
    </v-row>
  
    <v-row align="center" justify="start" dense>
      <v-col cols="12" class="d-flex align-center">
        <v-btn class="me-auto" text="뒤로" density="comfortable" variant="text" @click="goback"></v-btn>
        <v-btn color="deep-purple-lighten-2 mr-1" text="추가" density="comfortable" @click="addDataParam"></v-btn>
        <v-btn color="deep-purple-lighten-2" text="저장" density="comfortable" @click="save"></v-btn>
      </v-col>
    </v-row>
    
    <v-row class="mt-3" justify="center">
        
      <draggable
        v-model="itemList" tag="tbody" class="w-100 ml-3 mr-3" handle=".drag-handle"
        ghost-class="ghost" 
        @change="handleDrag" :key="listRenderCount"
        >
        <template #item="{ element }">
          <v-card
            class="mb-3 mx-auto" max-width="500"
            >
            <v-card-text>
              <div class="d-flex align-center flex-column w-100">

                <template v-for="format in columnList" :key="format">
                  <template v-if="format.type == 'text'">
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">{{ format.name }}</label>
                      <input style="width:70%;" v-model="element[format.name]" class="inputparam_bg" />
                    </div>
                  </template>
                  <template v-else>
                    <!-- 이미지 -->
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">{{ format.name }}</label>
                      <template v-if="element[format.name] != null && element[format.name] != undefined && element[format.name].length > 0">
                        <v-img  style="height:50px;cursor:pointer;" :src="element[format.name]"  contain @click="fileSelect(element, format.name, format.width, format.height)" />
                      </template>
                      <template v-else>
                        <v-icon class="inputparam_bg" style="width:50px;height:50px;color:black" @click="fileSelect(element, format.name, format.width, format.height)">mdi-image</v-icon>
                      </template>
                    </div>
                  </template>

                </template>
              </div>

          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="deep-purple-lighten-2" text="Delete" border @click="delete_item(element)"></v-btn>
          </v-card-actions>
        </v-card>
        </template>
      </draggable>      
      

        
    </v-row>

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { useContentStore } from "@/stores/content";
import draggable from 'vuedraggable'
import { ref } from '@vue/reactivity';
// eslint-disable-next-line no-unused-vars
import { ref as fref, uploadBytesResumable, deleteObject } from "firebase/storage";

export default {

    components:{
      draggable, VueCropper
    },  

    data: () => ({

      progress_dialog:false,
      progress_value:0,
      uploadFile : [],
      imgSrc:'',
      crop_dialog:false,
      temp_element:null,
      temp_name:null,
      deleteimg: [],

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],   //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },      

      loading : false,    //로딩 표시
      listRenderCount:0,
      curArrayName:'',

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          LOCALID:'',
          DEVICE_NAME:'',
          STATE:1,
      },
      editedItem: {},
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      theaders1: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' }
      ],

      theaders2: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' },
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],      
      contentList: [],
      columnList: [],
      itemList: [],
      datamap: [],

      sort_name: 'DEVICE_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',

      grade:0,
      limit_page:0,

    }),

    setup(){
        let contentStore   = useContentStore();
        return { contentStore };
    },

    async mounted(){
      
      if(this.$storage.getStorageSync("GRADE") < 1){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
        this.$router.push("/Login");
        return;
      }

      this.SEARCH_LOCALID         = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      
      if(this.$storage.getStorageSync("GRADE") < 1){
      //if(this.$storage.getStorageSync("GRADE") < 1 && this.$storage.getStorageSync("LIMIT_PAGE") == 0){
        this.$alert.fire("사용 권한이 없습니다.");
        this.$router.back();
        return;
      }
      this.limit_page = this.$storage.getStorageSync("LIMIT_PAGE");

      this.curArrayName = this.$route.query.name;
      this.datamap = this.contentStore.datamap;
      console.log("datamap : " + JSON.stringify(this.datamap));

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {


      goback(){
        this.$router.back();
      },

      async loadpage(){

        //우측 테이블에 배열 요소 표시
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화
        for (const element of this.datamap.datamap[this.curArrayName]) {
          this.itemList.push(element); 
        }

        //컬럼명 처리
        this.columnList.splice(0, this.columnList.length);   //데이터 초기화
        for (const element of this.datamap.dataformat[this.curArrayName]) {
          this.columnList.push(element); 
        }

      },


      //우측 순서변경
      handleDrag(){
        //원본 데이터에 순서가 변경된 것을 저장한다.
        console.log("itemlist : " + JSON.stringify(this.itemList));
        this.datamap.datamap[this.curArrayName] = [...this.itemList];
        console.log("cur2 : "+JSON.stringify(this.datamap.datamap));
        //this.listRenderCount++;
      },


      addDataParam(){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.curArrayName == null || this.curArrayName == undefined || this.curArrayName.length == 0){
          return;
        }
        this.itemList.push({});
        this.datamap.datamap[this.curArrayName] = [...this.itemList];
        console.log("addDataParam : " + JSON.stringify(this.datamap));
      },

      //스토리지 이미지가 들어있는지 체크
      findHttpValue(obj) {
          for (let key in obj) {
              // eslint-disable-next-line no-prototype-builtins
              if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].startsWith(this.$storagepath)) {
                  return obj[key];
              }
          }
          return null; // 'http'로 시작하는 값이 없는 경우
      },

      //아이템 삭제
      delete_item(item){

        const idx = this.itemList.indexOf(item);
        if (idx !== -1) {

          const imgpath = this.findHttpValue(item);
          if(imgpath != null && imgpath.length > 0){
            //스토리지 이미지가 이미 들어있다면 제거 대상에 등록
            this.deleteimg.push(item[name]);
          }

          this.itemList.splice(idx, 1);
        }
        this.datamap.datamap[this.curArrayName] = [...this.itemList];
      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      


      //추가 저장
      async save () {

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.datamap == null || this.datamap == undefined || this.datamap.length == 0){
          //신규 데이터맵 생성
          this.$alert.fire('저장데이터가 없습니다.');
          return;
        }


        //이미지 저장처리
        let localid = this.$storage.getStorageSync("SEARCH_LOCALID");
        let parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
        let folder = "company/"+parent_localid+"/users/"+localid+"/page";
        if(this.uploadFile.length > 0){
          //저장 시작
          this.progress_dialog = true;
          this.progress_msg = "이미지 업로드중...";
          for(const imgobject of this.uploadFile){

            console.log("1 : "+imgobject.name);
            console.log("2 : " + imgobject.data);

            await this.imageCheck(imgobject, folder);

          }
        }
        this.progress_dialog = false;


        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/MyResSave',{
              DATAMAP: JSON.stringify(this.datamap)
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then(async (result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

            //이미지 삭제 처리 - 이건 그냥 삭제해도 된다.
            if(this.deleteimg.length > 0){
              this.progress_dialog = true;
              this.progress_msg = "이미지 동기화중...";
              for(const imgurl of this.deleteimg){
                const deleteRef = fref(this.$firestorage, imgurl.toString().replace(this.$storagepath, "").split("?")[0]);
                try{
                  await deleteObject(deleteRef);
                }catch(error){
                }
              }
            }
            this.progress_dialog = false;

            this.$alert.fire("수정이 완료되었습니다.").then(()=>{
              //this.loadpage();
              this.uploadFile.splice(0, this.uploadFile.length);
              this.deleteimg.splice(0, this.deleteimg.length);
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('수정이 실패했습니다.');
        });

      },      

      async imageCheck(object, folder){

        const upload_ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_new.png");
        const metadata = { contentType: 'image/png' };

        

        this.test();

        let upload_cb = await this.uploadFileStorage(upload_ref, object.data, metadata);
        if(upload_cb != null){
          object.element[object.name] = this.$storagepath+upload_cb._location.path_;
        }

      },




      fileSelect(element, name, width, height){

        console.log("fileselect : "+width, height);
        this.option.fixedNumber = [Number(width), Number(height)];

        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
        input.onchange = function (event) {
          changefunction(event.target.files[0], element, name);
        };
        input.click();
      },

      //이미지 선택시
      changefile(file, element, name){
        if(file.size/1024/1024 > 10){
          this.$alert.fire("10MB 이하 파일만 사용할 수 있습니다.");
          return;
        }
        const reader = new FileReader();
        reader.onload = (event)=>{
          this.temp_element = element;
          this.temp_name = name;

          console.log("temp_name : " + this.temp_name);

          this.crop_dialog = true;
          this.imgSrc = event.target.result;  //이게 blob데이터
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서
      },


    //이미지 크롭시
    cropimg(){
        
      this.$refs.cropper_land.getCropBlob(blob=>{
        this.crop_dialog = false;
        this.$refs.cropper_land.getCropData(async data=>{
          
          if(this.temp_element[this.temp_name] != null && this.temp_element[this.temp_name] != undefined && this.temp_element[this.temp_name].length > 0){
            if(this.temp_element[this.temp_name].startsWith(this.$storagepath)){
              //스토리지 이미지가 이미 들어있다면 제거 대상에 등록
              this.deleteimg.push(this.temp_element[this.temp_name]);
            }
          }

          this.temp_element[this.temp_name] = data; //미리보기
          this.uploadFile.push({element:this.temp_element, name:this.temp_name, data:blob});  //업로드용 blob 데이터

        });

      });      

    },


    //지정한 data를 넣은 이미지를 리턴한다.
    loadImage(data){
      const myImage = new Image();
      myImage.src = data;
      return new Promise((resolve)=>{
        myImage.onload = () => resolve(myImage)
      })
    },

    //캔버스 TO BLOB
    dataURItoBlob(dataURI){
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    },


    //firebase storage에 파일 업로드 및 프로그레스 표시
    uploadFileStorage(storage_ref, blobd_ata, metadata){
      return new Promise((resolve, reject)=>{

          const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
          uploadTask.on('state_changed',
            (snapshot) => {
              this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }                
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
              reject(null);
            },
            () => {
              // Upload completed successfully - 업로드 완료시 경로 리턴
              resolve(uploadTask.snapshot.ref);
            }
          );

      })
    },

    isNullCheck(value){
      if(value == null || value == undefined || value.length < 1){
        return true;
      }else{
        return false;
      }
    },

  }
  
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.inputparam_bg{
  background: #e4e4e4;
  border-radius: 5px;
  color: #000000;
  padding: 5px 10px 5px 10px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

</style>