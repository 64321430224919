<template>

  <v-container fluid>
    
    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              
              <v-row>
                <v-col cols="12">
                  <v-text-field outlined label="컨텐츠 그룹명" hide-details variant="outlined" density="compact"
                  v-model="editedItem.MCGROUP_NAME"  ></v-text-field>
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>


    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>

      <v-col cols="12" md="3" v-if="this.$storage.getStorageSync('GRADE') >= 10">
        <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
      </v-col>
      
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
    </v-row>


  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>

  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        :mobile="false"
        >

        <template v-slot:item.MCGROUP_NAME="{ item }">  
          <a style="cursor:pointer" @click="move_edit(item)">{{item.MCGROUP_NAME}}</a>
        </template>     

        <template v-slot:header.MCGROUP_NAME>
          <a :class="sort_name=='MCGROUP_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('MCGROUP_NAME')">컨텐츠 그룹명</a>
        </template>
        <template v-slot:header.UP_DATE>
          <a :class="sort_name=='UP_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('UP_DATE')">수정일</a>
        </template>

        <template v-slot:item.UP_DATE="{ item }">  
          {{ this.$momenttz.utc(item.UP_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }}
        </template>

        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     
        <template v-slot:bottom></template>
      </v-data-table>
    </v-col>
  </v-row>

  
  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {
    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
        LOCALID:'',
          MCGROUP_NAME:'',
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '컨텐츠 그룹명', key: 'MCGROUP_NAME', sortable: false, align:'center'},
        {title: '수정일', key: 'UP_DATE', sortable: false, align:'center'},
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],
      itemList: [],

      sort_name: 'MCGROUP_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      grade:0,
      

    }),

    setup(){
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        return {paging, searchbar, editor};
    },

    mounted(){

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      this.SEARCH_LOCALID    = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      console.log("SEARCH_PARENT_LOCALID : " + this.SEARCH_PARENT_LOCALID);
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      console.log("grade :" + this.grade);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      move_edit(item){
        this.$router.push({path:"/MyContentGroupDetail", query:{idx:item.MCGROUP_IDX}});
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/MyContentGroupList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        this.dialog_pw  = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

          if(this.editedItem.MCGROUP_NAME  == undefined || this.editedItem.MCGROUP_NAME.length < 1){
            this.$alert.fire("컨텐츠 그룹명을 입력하세요");
            return;
          } 

          
          if(this.SEARCH_LOCALID == null || this.SEARCH_LOCALID == undefined){
            this.$alert.fire("계정을 선택하세요");
            return;
          }

        if (this.editedIndex > -1) {
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/MyContentGroupUpdate',{
                MCGROUP_NAME: escape(this.editedItem.MCGROUP_NAME)
              , MCGROUP_IDX: this.editedItem.MCGROUP_IDX
              , LOCALID: this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              const p = this.page;
              this.page = 0;
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(p);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });


        }else{
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //신규
          this.loading = true;
          this.$http.post(this.$host+'/MyContentGroupInsert',{
                LOCALID: this.SEARCH_LOCALID
              , MCGROUP_NAME: escape(this.editedItem.MCGROUP_NAME)
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                this.$alert.fire("추가가 완료되었습니다.").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('추가 실패');
          });


        }

        this.close()
      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/MyContentGroup", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //신규 추가
      newinsert(){
        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      //아이템 삭제
      delete_item(item){

        if(this.SEARCH_LOCALID == null || this.SEARCH_LOCALID == undefined){
          this.$alert.fire("계정을 선택하세요");
          return;
        }

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/MyContentGroupDelete',{
              MCGROUP_IDX:item.MCGROUP_IDX,
              LOCALID: this.SEARCH_LOCALID
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },

      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage(1);
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage(1);
      },



    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>