
<template>
  <div>
    <div ref="editor" />
  </div>
</template>

<script setup lang="ts">
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';


import { ref, defineProps, defineEmits, defineExpose, onMounted } from 'vue';

const props = defineProps({
  data: {
    type: String,
    required: false,
    default: '',
  },
});

//이벤트 정의 (해당 이름의 이벤트만 받겠다.)
//const emit = defineEmits(['setContent']);

const editorOptions = {
    //링크 클릭시 뉴탭으로 표시되도록 처리
    linkAttribute: {
        target: '_blank',
        contenteditable: 'false',
        rel: 'noopener noreferrer'          
    },

    minHeight: '200px',
    language: 'ko',
    useCommandShortcut: true,
    useDefaultHTMLSanitizer: true,
    usageStatistics: false,
    hideModeSwitch: true, //markdown wysiwyg 토글 숨기기
    plugins:[colorSyntax],
    toolbarItems: [
        'heading',
        'bold',
        'italic',
        'strike',
        'divider',
        //'hr',
        'quote',
        'divider',
        'ul',
        'ol',
        'task',
        //'indent',
        //'outdent',
        'divider',
        //'table',
        'image',
        'link',
        'divider',
        //'code',
        //'codeblock'
    ],
};

// 유튜브 플러그인 시작 - 유튜브 링크는 보여줄때만 컨버팅이네 뷰어도 만들어야한다.
/*
function youtubePlugin() {
  Editor.codeBlockManager.setReplacer('youtube', youtubeId => {
    const wrapperId = `yt${Math.random().toString(36).substr(2, 10)}`;
    setTimeout(renderYoutube.bind(null, wrapperId, youtubeId), 0);

    return `<div id="${wrapperId}"></div>`;
  });
}

function renderYoutube(wrapperId:any, youtubeId:any) {
  const el = document.querySelector(`#${wrapperId}`);
  if( !!!el ){
    return;
  }
  el.innerHTML = `<div class="toast-ui-youtube-plugin-wrap"><iframe src="https://www.youtube.com/embed/${youtubeId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
}
*/
// 유튜브 플러그인 끝

function addimagehook(file, setText, type){
    //이미지를 선택하거나 드래그앤 드랍시 바로 업로드 처리 가능.
    //sdge에 넣은것처럼 업로드 할 때 갈아 치울까?
    console.log("add image hook...");
}


const editor = ref();

// eslint-disable-next-line no-unused-vars
let instance: Editor|null = null;

function getContent(){
  return instance.getHTML();
}

//외부에서 접근 가능
defineExpose({ instance, getContent });

onMounted(() => {

  instance = new Editor({
    el: editor.value,
    height: '500px',
    initialValue: props.data,
    initialEditType: 'wysiwyg',
    previewStyle: 'vertical',
    hideModeSwitch:true,
    plugins:[colorSyntax],
    linkAttributes:{
        target: '_blank',
        rel: 'noopener noreferrer'          
    },

    events: {
      //에디터에서 작업하면 해당 함수 콜백함. 부모뷰의 setContent 이벤트 호출.
      //change: () => emit('setContent', e.getHTML()),
    },

    hooks:{
        //addImageBlobHook: addimagehook,
    },

  });


  

});
</script>
