<template>
  <v-container fluid style="background:#ffffff">

  <v-row style="background:#ffffff;" class="mt-10" justify="center">
    <v-col cols="12" md=6>

    <v-row justify="center">
      <div :class="$vuetify.display.mobile ? 'join_title_mb' : 'join_title'">회원가입</div>      
    </v-row>  

    <v-row class="mt-15">
      <v-col cols="12" class="pa-0" @click="allcheck">
        <input class="ml-5 join_check" type="checkbox" v-model="check3"/>
        <span class="ml-3 me-auto join_text">전체 동의합니다.</span>
      </v-col>
    </v-row>
    
    <v-divider class="mt-5"/> 
    
    <!-- 서비스 이용약관 -->
    <v-row class="mt-5">
      <v-col cols="12" class="pa-0">
        <input class="ml-5 join_check" type="checkbox" v-model="check1"/>
        <span class="ml-3 me-auto join_text">(필수)서비스 이용약관 동의</span>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-expand-transition class="mt-1 mb-1">
          <Policy_service v-if="check1" />
        </v-expand-transition>
      </v-col>
    </v-row>

    <!-- 개인정보 처리방침 -->
    <v-row class="mt-5">
      <v-col cols="12" class="pa-0">
        <input class="ml-5 join_check" type="checkbox" v-model="check2"/>
        <span class="ml-3 me-auto join_text">(필수)개인정보 수집 및 이용 동의</span>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-expand-transition class="mt-1 mb-1">
          <Policy_person v-if="check2" />
        </v-expand-transition>
      </v-col>
    </v-row>

      
    
        <v-row dense class="mt-10">
          <v-col cols="12">
            <p class="h4_text">회원가입 방법</p>
            <p class="mt-5">1.이름 또는 업체명, 이메일 계정과 비밀번호를 입력하시고 가입하기를 누르시면 입력하신 이메일 계정으로 인증메일을 보내드립니다.</p>
            <p>2.인증메일에 첨부된 링크를 누르시면 가입이 완료되어 계정사용이 가능합니다.</p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-5" dense>
          <v-col cols="12" md="8">
            <v-text-field v-model="user_name" density="compact" variant="outlined"
                name="name" label="이름 또는 업체명을 입력하세요" type="text"></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center" dense>
          <v-col cols="12" md="8">
            <v-autocomplete
              clearable
              label="사용지역의 기준시간 선택"
              :items="itemList"
              variant="outlined"
              density="compact"
              v-model="user_timezone"
              />
          </v-col>
        </v-row>

        <v-row justify="center" dense>
          <v-col cols="12" md="8">
            <v-text-field v-model="user_id" density="compact" variant="outlined"
              prepend-inner-icon="mdi-map-marker" name="login" label="이메일을 입력하세요" type="text"></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" dense>
          <v-col cols="12" md="8">
            <v-text-field v-model="user_pw" density="compact" variant="outlined" 
              prepend-inner-icon="mdi-lock" name="password" label="비밀번호" hint="비밀번호를 입력하세요"
              id="password" type="password"></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center" dense>
          <v-col cols="12" md="8">
            <v-text-field v-model="user_pw2" density="compact" variant="outlined" 
              prepend-inner-icon="mdi-lock" name="password2" label="비밀번호 확인" hint="비밀번호를 다시 입력하세요"
              id="password2" type="password"></v-text-field>
          </v-col>
        </v-row>
      
    <v-row justify="center" dense class="mt-5">      
      <v-col cols="12" class="pa-0" align="center">
        <v-btn width="100" text @click="cancel">취소</v-btn>
        <v-btn width="100" class="ml-3" style="color:white" color="#9f0007" @click="join">가입하기</v-btn>
      </v-col>
    </v-row>
      
    <v-row class="mt-15"/>

    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import Policy_person from '@/components/Policy_person.vue';
import Policy_service from '@/components/Policy_service.vue';
import Policy_mkt from '@/components/Policy_mkt.vue';

export default {
  components: { Policy_person, Policy_service, Policy_mkt },

  data: () => ({
        dialog:true,
        select_type:1,
        select_login:false,

        itemList:[],

        panel: null,

        check1:false,
        check2:false,
        check3:false,
        pidx:null
      , user_id : ""
      , user_pw : ""
      , user_pw2 : ""
      , user_name : ""
      , user_timezone: ""
      , rules: {
          mobile: value => !value || !/[^0-9-]/.test(value) || '숫자와 -외에는 입력할 수 없습니다.',
          eng: value => !value || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || '한글을 사용할 수 없습니다.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          notemail: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !pattern.test(value) || '이메일주소는 사용할 수 없습니다.'
          },
      },      
  }),

  mounted(){  

    this.select_login = false;
    
    if(this.$route.query.pidx == undefined || this.$route.query.pidx == null){
      this.pidx = null;
    }else{
      this.pidx = this.$route.query.pidx;
    }
    
    this.loadtimezone();

  },

  methods: {

    allcheck(){

      if(this.check3){
        this.check1 = false;
        this.check2 = false;
        this.check3 = false;
      }else{
        this.check1 = true;
        this.check2 = true;
        this.check3 = true;
      }

    },

    cancel(){

        this.$alert.fire({showCancelButton: true, title:'회원가입을 취소하시겠습니까?'})
        .then((aresult) => {
          if(aresult.isConfirmed){
            this.$router.back();
          }
        });      

    },


    async loadtimezone(){

          this.$http.post(this.$host+'/GuestTimezone',{
          })
          .then((result)=>{
            if(result.data.resultCode == 0){
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                  this.itemList.push(element.CITY); 
                });
            }else{
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },


    join(){

        if(this.check1 == false){
            this.$alert.fire("서비스약관에 동의해주세요");
            return;
        }

        if(this.check2 == false){
            this.$alert.fire("개인정보처리방침에 동의해주세요");
            return;
        }

        if(this.check3 == false){
            this.$alert.fire("마케팅정보동의에 동의해주세요");
            return;
        }

        if(this.user_timezone == null || this.user_timezone == undefined || this.user_timezone.length < 1){
            this.$alert.fire("시간대를 선택하세요");
            return;
        }

        if(this.user_name.length < 1){
            this.$alert.fire("이름을 입력하세요");
            return;
        }
        if(this.user_name.length > 10){
            this.$alert.fire("이름은 10자 이하로 입력하세요");
            return;
        }

        var emailVal = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
        if(this.user_id  == undefined || this.user_id.length < 1 || this.user_id.length > 30){
          this.$alert.fire("이메일을 입력하세요(최대 30자)");
          return;
        } 
        if(!emailVal.test(this.user_id)){
          this.$alert.fire("입력하신 이메일은 이메일 주소 양식이 아닙니다.");
          return;
        } 

        if(this.user_pw.length < 1){
            this.$alert.fire("비밀번호를 입력하세요");
            return;
        }
        if(this.user_pw.length < 6){
            this.$alert.fire("비밀번호는 최소 6글자 이상입니다.");
            return;
        }
        if(this.user_pw2.length < 1){
            this.$alert.fire("비밀번호 확인을 입력하세요");
            return;
        }
        if(this.user_pw2.length < 6){
            this.$alert.fire("비밀번호는 최소 6글자 이상입니다.");
            return;
        }
        if(this.user_pw != this.user_pw2){
            this.$alert.fire("비밀번호화 비밀번호 확인이 다릅니다.");
            return;
        }

        this.$http.post(this.$host+'/fireJoin',{
            USER_ID: this.user_id
          , USER_PW: Buffer.from(this.user_pw).toString('base64')
          , TIMEZONE: this.user_timezone
          , NAME: escape(this.user_name)
          , MKT: this.check3 ? 1 : 0
          , PARENT_LOCALID: this.pidx
        })
        .then((result)=>{
            //console.log(JSON.stringify(result));
            if(result.data.resultCode == 0){
                //신규추가 성공
                this.$alert.fire("인증메일이 발송되었습니다.\n인증메일 확인 후 로그인해주세요.");
                this.$router.push('/Login');
            }else{
              this.$alert.fire(result.data.resultMsg);
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          //console.log(error);
          this.$alert.fire('오류로 인해 회원가입이 실패했습니다.');
        });

      }

  },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


.join_title{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.join_title_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

/* 체크박스 */
.join_check{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* 체크박스의 너비 */
  height: 20px; /* 체크박스의 높이 */
  background-color: #BEBEBE; /* 체크된 상태일 때의 배경색 */
  border-color: #BEBEBE; /* 체크된 상태일 때의 테두리 색 */
  border-radius: 50px; /* 체크박스의 테두리 둥글기 */
  outline: none; /* 포커스시 윤곽선 제거 */
  cursor: pointer; /* 커서 모양 설정 */  
}
.join_check:checked{
  background-color: #FF6000; /* 체크된 상태일 때의 배경색 */
  border-color: #FF6000; /* 체크된 상태일 때의 테두리 색 */
}
.join_check::before{
  content: "\2713"; /* 체크 아이콘 (유니코드) */
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20px; /* 체크박스의 높이와 동일하게 설정 */
  color: #fff; /* 체크 아이콘의 색상 */
  font-size: 16px; /* 체크 아이콘의 크기 */
}


</style>