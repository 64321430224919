<!-- 썸네일용 -->
<template>
  <div
    :style="'height:'+height+'px;max-width:'+width+'px'"
     fluid class="pa-0" draggable="false">
    <div ref="screen" draggable="false">
      <template v-for="cm in screen_model.component_list" :key="cm">
        <PreviewComponent :model="cm" draggable="false" style="user-select: none;"/>
      </template>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { useContentStore } from "@/stores/content";
import PreviewComponent from '@/nextvue/preview/PreviewComponent.vue'
import Screen_Model from '@/nextts/model/Screen_Model'
import { ref } from '@vue/reactivity';
export default {

  components:{
    PreviewComponent
  },

  props:{
    screen_model:{
      type:Screen_Model
    },
    scalewidth:{
      type:Number
    },
    
  },

  setup(props){
    const screen = ref(null);
    const contentStore = useContentStore();

    //원본 사이즈를 축소시켜서 보여줄 스케일
    const scale1  = props.scalewidth/props.screen_model.width;
    const scale2  = props.scalewidth/props.screen_model.height;
    const scale   = Math.min(scale1, scale2);
    
    const width   = props.screen_model.width * scale;
    const height  = props.screen_model.height * scale;
    return {contentStore, screen, width, height, scale};
  },

  data:()=>({
    isDragOver:false
  }),

  watch:{
    screen_model: async function(v1){
      //console.log("v1 : " + v1 + " / v2 : " + v2);
      this.updateRender();
    },
  },

  mounted(){
    //다시 그리기
    this.updateRender();
  },

  methods:{

    //스크린 그리기
    updateRender(){
      
      console.log("thumscreen updateRender");

      const scale1  = this.scalewidth/this.screen_model.width;
      const scale2  = this.scalewidth/this.screen_model.height;
      const scale   = Math.min(scale1, scale2);

        //배경타입 
        if(this.screen_model.bgtype == 0){
            //color
            this.screen.style.backgroundColor  = this.screen_model.backgroundColor;
            //this.screen.style.backgroundImage  = "";
            this.screen.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 1){
            //image
            this.screen.style.backgroundColor  = "#00000000";
            this.screen.style.backgroundSize   = this.screen_model.width + "px " + this.screen_model.height + "px";
            this.screen.style.backgroundImage  = "url('"+this.screen_model.backgroundImg+"')"
            this.screen.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 2){            
            //gradient
            this.screen.style.background       = this.screen_model.backgroundColor;
            //this.style.backgroundImage  = "";
            this.screen.style.backgroundRepeat = "no-repeat";
        }

        this.screen.style.width  = this.screen_model.width+'px';
        this.screen.style.height = this.screen_model.height+'px';

        //축소시 좌표가 틀어지기 때문에 스케일 중심을 0 0기준으로 맞춘다.
        this.screen.style.transformOrigin = "0% 0%";
        this.screen.style.transform = 'scale('+scale+')';

        //외각 라인 그리기
        //this.screen.style.border = "1px red solid";
        
    },




  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



</style>