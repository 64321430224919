<!-- 스크린 썸네일용 뷰 -->
<template>

    <div ref="component" draggable="false"  />
  
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Component_Model from '@/nextts/model/Component_Model'
import { reactive, ref } from '@vue/reactivity';
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid';

export default {

  props:{
    model:{
      type:Component_Model
    },
  },

  setup(){
    const component = ref(null);
    return {component};
  },

  data:()=>({

  }),

  mounted(){
    
    this.component.style.position = "absolute";

    //컴포넌트의 크기, 위치 설정
    this.component.style.cssText += "width:"+`${this.model.width}px`+"; height:"+`${this.model.height}px`+"; transform: translateX("+`${this.model.transx}px`+") translateY("+`${this.model.transy}px`+") rotate("+`${this.model.rotate}deg`+")";    
    
    //컴포넌트의 모양(draw)
    this.updateRender();
    
  },


  methods:{

    //스크린 그리기
    updateRender(){

        
        this.component.style.backgroundColor  = "#00000000";
        this.component.style.backgroundImage  = "";
        this.component.style.whiteSpace       = "normal";
        this.component.style.overflow         = "visible";

        this.component.style.opacity          = String(this.model.opacity);
        
        //배경타입
        /*컴포넌트의 배경타입은 사용하지 않기로 함. 무조건 투명으로 나오게 처리.
        if(this.model.bgtype == 0){
            //color
            this.component.style.backgroundColor  = this.model.backgroundColor;
            this.component.style.backgroundImage  = "";
            this.component.style.backgroundRepeat = "no-repeat";
        }else if(this.model.bgtype == 1){
            //image
            this.component.style.backgroundColor  = "#00000000";
            this.component.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.component.style.backgroundImage  = "url('"+this.model.backgroundImg+"')"
            this.component.style.backgroundRepeat = "no-repeat";
        }
        */

        //컴포넌트 타입
        if(this.model.component_type == Component_Model.COMPONENT_TEXT || this.model.component_type == Component_Model.COMPONENT_DATETIME){

            this.component.innerText          = this.model.text;
            this.component.style.fontFamily   = this.model.textfont;
            this.component.style.fontSize     = this.model.textsize+'px';
            this.component.style.color        = this.model.textcolor;
            this.component.style.textAlign    = this.model.texthori_align;    //left, center, right
            
            //텍스트 세로 정렬
            this.component.style.display          = "flex";
            this.component.style.flexDirection    = "column";
            this.component.style.lineHeight       = "120%";
            this.component.style.justifyContent   = this.model.texthori_align; 
            
            this.component.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.component.style.lineHeight       = this.model.lineHeight+"%"     

            //언더라인
            if(this.model.textUnderline == 0){
                this.component.style.textDecoration = "none";
            }else{
                this.component.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.component.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.component.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.component.style.fontStyle = "normal";
            }else{
                this.component.style.fontStyle = "italic";
            }

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.component.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }else{
                this.component.style.textShadow = "";
            }

            if(this.model.outline_use){
                this.component.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
            }else{
                this.component.style.webkitTextStroke = "";  
            }

            this.component.style.backgroundClip  = "";
            this.component.style.webkitBackgroundClip = "";
            this.component.style.backgroundImage = "";
            this.component.style.color = this.model.textcolor;
            this.component.style.webkitTextFillColor = this.model.textcolor;

            if(this.model.component_type == Component_Model.COMPONENT_DATETIME){
                if(this.model.date_type.length > 0){
                    this.component.innerText = moment(new Date().toString()).format(this.model.date_type);
                }
            }

            //input 텍스트처리 (더블클릭시)
            this.component.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_ARTTEXT){
            const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            const defs = document.createElementNS(svgElement.namespaceURI, "defs");
            const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            const textPathElement = document.createElementNS('http://www.w3.org/2000/svg', 'textPath');
            svgElement.appendChild(defs);
            svgElement.appendChild(path);
            svgElement.appendChild(text);
            text.appendChild(textPathElement);
            this.component.appendChild(svgElement);      
            
            svgElement.setAttribute("width", `${this.model.width}`);
            svgElement.setAttribute("height", `${this.model.height}`);

            //휘어짐.
            //m이 소문자여야 상대좌표다. 대문자면 절대좌표임. 시작x,y 커브기준점 x,y, 종료x,종료높이 0이면 처음높이 그대로.
            path.setAttribute('d', `m 0 ${(this.model.height/2)+(this.model.textsize/2)+this.model.text_offsetverti} q ${this.model.width/2}, ${this.model.text_arc} ${this.model.width}, 0`)
            path.setAttribute('fill', 'none');
            path.setAttribute('stroke', 'red');
            path.setAttribute('id', 'testra');

            text.style.fontFamily = this.model.textfont;
            text.style.fontSize = `${this.model.textsize}px`;
            text.style.textAlign    = this.model.texthori_align;    //left, center, right

            //텍스트 세로 정렬
            text.style.display          = "flex";
            text.style.flexDirection    = "column";
            text.style.lineHeight       = "120%";
            text.style.justifyContent   = this.model.textverti_align;       //top, center, end

            text.style.letterSpacing    = this.model.letterSpacing+"px" 
            text.style.lineHeight       = this.model.lineHeight+"%"     

            if(this.model.color_type == "color"){
                text.style.fill       = this.model.textcolor;

            }else if(this.model.color_type == "gradient"){

                //하위 효과 초기화
                while (this.defs && this.defs.firstChild) {
                    this.defs.removeChild(this.defs.firstChild);
                }                
                
                // SVGLinearGradientElement 타입으로 linearGradient 요소 생성
                if(this.model.gradient.type == "linear"){

                    //linear
                    const linearGradient = document.createElementNS(svgElement.namespaceURI, "linearGradient");
                    const gradient_id = uuidv4();
                    linearGradient.setAttribute("id", `${gradient_id}`);
                    linearGradient.setAttribute("gradientTransform", `rotate(${Number(this.model.gradient.degree)-90})`);
                    linearGradient.setAttribute("gradientUnits", "userSpaceOnUse");
                    // SVGStopElement 타입으로 첫 번째 stop 요소 생성
                    for(const point of this.model.gradient.points){
                        const stop = document.createElementNS(svgElement.namespaceURI, "stop");
                        stop.setAttribute("offset", `${point.left}%`);
                        stop.setAttribute("style", `stop-color:rgb(${point.red},${point.green},${point.blue});stop-opacity:${point.alpha}`);
                        linearGradient.appendChild(stop);
                    }
    
                    // 요소들을 조립
                    defs.appendChild(linearGradient);
                    text.style.fill       = `url(#${gradient_id})`;

                }else{
                    //radial
                    const radialGradient = document.createElementNS(svgElement.namespaceURI, "radialGradient");
                    const gradient_id = uuidv4();
                    radialGradient.setAttribute("id", `${gradient_id}`);
                    radialGradient.setAttribute("cx", "50%");
                    radialGradient.setAttribute("cy", "50%");
                    radialGradient.setAttribute("r", "10%");
                    // SVGStopElement 타입으로 첫 번째 stop 요소 생성
                    for(const point of this.model.gradient.points){
                        const stop = document.createElementNS(svgElement.namespaceURI, "stop");
                        stop.setAttribute("offset", `${point.left}%`);
                        stop.setAttribute("style", `stop-color:rgb(${point.red},${point.green},${point.blue});stop-opacity:${point.alpha}`);
                        radialGradient.appendChild(stop);
                    }
                    // 요소들을 조립
                    defs.appendChild(radialGradient);
                    text.style.fill = `url(#${gradient_id})`;
                }
                
            }else if(this.model.color_type == "image"){
                console.log("image...");
            }

            //외각선
            if(this.model.outline_use){ 
                text.style.stroke = `${this.model.outline_color}`;
                text.style.strokeWidth = `${this.model.outline_size}`;
                text.style.strokeDasharray = `${this.model.outline_dash1},${this.model.outline_dash2}`;
                text.style.strokeDashoffset = `${this.model.outline_offset}`;
            }else{
                text.style.stroke = "";
                text.style.strokeWidth = "";
            }             

            //언더라인
            if(this.model.textUnderline == 0){
                text.style.textDecoration = "none";
            }else{
                text.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                text.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                text.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                text.style.fontStyle = "normal";
            }else{
                text.style.fontStyle = "italic";
            }


            //input 텍스트처리 (더블클릭시)
            //this.contentEditable = "true";

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                //this.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }else{
                //this.style.textShadow = "";
            }

            //anchor가 중앙이면 x좌표를 중앙으로 맞춤.
            if(this.model.texthori_align == "left"){
                text.setAttribute("x","0%");
                text.setAttribute("text-anchor","start");
            }else if(this.model.texthori_align == "center"){
                text.setAttribute("x","50%");
                text.setAttribute("text-anchor","middle");
            }else if(this.model.texthori_align == "right"){
                text.setAttribute("x","100%");
                text.setAttribute("text-anchor","end");
            }
            
            if(this.model.textverti_align == "top"){
                text.setAttribute("y","0%");
            }else if(this.model.texthori_align == "center"){
                text.setAttribute("y","50%");
            }else if(this.model.texthori_align == "end"){
                text.setAttribute("y","100%");
            }
            textPathElement.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#testra');
            textPathElement.setAttribute('startOffset', `${this.model.text_offset}`);
            textPathElement.textContent = this.model.text;

        }else if(this.model.component_type == Component_Model.COMPONENT_IMAGE){
            
            this.component.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.component.style.backgroundColor  = "#00000000";
            this.component.style.backgroundRepeat = "no-repeat";
            
            if(this.model.image != null && this.model.image != undefined && this.model.image.length > 0){
                if(this.model.image.toLowerCase().startsWith("http")){
                    this.component.style.backgroundImage  = "url('"+this.model.image+"')"
                }else{
                    this.component.style.backgroundImage  = `url(${this.model.image})`;
                }
            }
            
            this.component.style.backgroundRepeat = "no-repeat";

            //input 텍스트처리 (더블클릭시)
            this.component.contentEditable = "false";
            this.component.innerText = "";


            //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
            if(this.model.mask_use){
                this.component.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.component.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.component.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.component.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.component.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.component.style.webkitMaskImage = "";    
                }
            }else{
                this.component.style.webkitMaskImage = "";
                this.component.style.webkitMaskPosition = "";
                this.component.style.webkitMaskSize = "";
            }


        }else if(this.model.component_type == Component_Model.COMPONENT_TEXTSWITCHER){

            if(this.model.textswitcher_list != null && this.model.textswitcher_list.length > 0){
                this.component.innerText          = this.model.textswitcher_list[0];
            }else{
                this.component.innerText          = "입력된 텍스트가 없습니다.";
            }

            this.component.style.fontFamily   = this.model.textfont;
            this.component.style.fontSize     = this.model.textsize+'px';
            this.component.style.color        = this.model.textcolor;
            this.component.style.textAlign    = this.model.texthori_align;    //left, center, right
            
            //텍스트 세로 정렬
            this.component.style.display          = "flex";
            this.component.style.flexDirection    = "column";
            this.component.style.lineHeight       = "120%";
            this.component.style.justifyContent   = this.model.texthori_align; 
            
            this.component.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.component.style.lineHeight       = this.model.lineHeight+"%"     

            //언더라인
            if(this.model.textUnderline == 0){
                this.component.style.textDecoration = "none";
            }else{
                this.component.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.component.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.component.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.component.style.fontStyle = "normal";
            }else{
                this.component.style.fontStyle = "italic";
            }

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.component.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }

            if(this.model.outline_use){
                this.component.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
            }

            //input 텍스트처리 (더블클릭시)
            this.component.contentEditable = "false";

        }else if(this.model.component_type == Component_Model.COMPONENT_IMAGESWITCHER){
            
            this.component.style.backgroundSize   = this.model.width + "px " + this.model.height + "px";
            this.component.style.backgroundColor  = "#00000000";
            this.component.style.backgroundRepeat = "no-repeat";

            if(this.model.imgswitcher_list != null && this.model.imgswitcher_list.length > 0){
                this.component.style.backgroundImage  = `url(${this.model.imgswitcher_list[0]})`;
            }

            //input 텍스트처리 (더블클릭시)
            this.component.contentEditable = "false";
            this.component.innerText = "";


            //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
            if(this.model.mask_use){
                this.component.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.component.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.component.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.component.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.component.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.component.style.webkitMaskImage = "";    
                }
            }else{
                this.component.style.webkitMaskImage = "";
                this.component.style.webkitMaskPosition = "";
                this.component.style.webkitMaskSize = "";
            }


        }else if(this.model.component_type == Component_Model.COMPONENT_COLOR){

            if(this.model.color_type == "color"){
                this.component.style.backgroundColor  = this.model.backgroundColor;
            }else if(this.model.color_type == "gradient"){
                this.component.style.background       = this.model.backgroundColor;
            }

            //border-radius 처리
            this.component.style.borderRadius = `${this.model.border_radius}px`;
            //외각선
            if(this.model.outline_use){ 
                this.component.style.border = `${this.model.outline_size}px solid ${this.model.outline_color}`;
            }else{
                this.component.style.border = "";
            }             

            this.component.style.backgroundRepeat = "no-repeat";
            this.component.innerText              = "";

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.component.style.boxShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }

            //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
            if(this.model.mask_use){
                this.component.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.component.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.component.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.component.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.component.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.component.style.webkitMaskImage = "";    
                }
            }else{
                this.component.style.webkitMaskImage = "";
                this.component.style.webkitMaskPosition = "";
                this.component.style.webkitMaskSize = "";
            }



        }else if(this.model.component_type == Component_Model.COMPONENT_MOVIE){
            this.component.style.backgroundColor  = "black";
            const imgPath = require('@/assets/playlogo.png');
            this.component.style.backgroundImage  = `url(${imgPath})`
            this.component.style.backgroundSize   = "30%";
            this.component.style.backgroundPosition = "center";
            this.component.style.backgroundRepeat = "no-repeat";

            this.component.style.color            = "white";
            this.component.innerText              = "";
            this.component.style.textAlign        = "center";    
            this.component.style.display          = "flex";
            this.component.style.flexDirection    = "column";
            this.component.style.lineHeight       = "120%";
            this.component.style.justifyContent   = "center";


            //마스크 처리 - 테스트로 여기에 빼놨어, 모두 마스크 처리가 되긴 하네
            if(this.model.mask_use){
                this.component.style.webkitMaskPosition   = `${this.model.mask_left}% ${this.model.mask_top}%`;
                this.component.style.webkitMaskSize       = `${this.model.mask_width}% ${this.model.mask_height}%`;
                if(this.model.mask_repeat){
                    this.component.style.webkitMaskRepeat = "repeat";    
                }else{
                    this.component.style.webkitMaskRepeat = "no-repeat";    
                }
                if(this.model.mask_image.length > 0){
                    //https://cdn.icon-icons.com/icons2/692/PNG/512/seo-social-web-network-internet_132_icon-icons.com_61506.png
                    this.component.style.webkitMaskImage = `url(${this.model.mask_image})`;
                }else{
                    this.component.style.webkitMaskImage = "";    
                }
            }else{
                this.component.style.webkitMaskImage = "";
                this.component.style.webkitMaskPosition = "";
                this.component.style.webkitMaskSize = "";
            }


        }else if(this.model.component_type == Component_Model.COMPONENT_YOUTUBE){
            this.component.style.backgroundColor  = "black";

            const imgPath = require('@/assets/youtubelogo.png');
            this.component.style.backgroundImage  = `url(${imgPath})`
            this.component.style.backgroundSize   = "50%";
            this.component.style.backgroundPosition = "center";

            this.component.style.backgroundRepeat = "no-repeat";

            this.component.style.color            = "white";
            this.component.innerText              = "";
            this.component.style.textAlign        = "center";    
            this.component.style.display          = "flex";
            this.component.style.flexDirection    = "column";
            this.component.style.lineHeight       = "120%";
            this.component.style.justifyContent   = "center";

        }else if(this.model.component_type == Component_Model.COMPONENT_WEBVIEW){
            this.component.style.backgroundColor  = "black";
            const imgPath = require('@/assets/homelogo.png');
            this.component.style.backgroundImage  = `url(${imgPath})`
            this.component.style.backgroundSize   = "30%";
            this.component.style.backgroundPosition = "center";
            this.component.style.backgroundRepeat = "no-repeat";

            this.component.style.color            = "white";
            this.component.innerText              = "";
            this.component.style.textAlign        = "center";    
            this.component.style.display          = "flex";
            this.component.style.flexDirection    = "column";
            this.component.style.lineHeight       = "120%";
            this.component.style.justifyContent   = "center";

        }else if(this.model.component_type == Component_Model.COMPONENT_SOUND){
            //썸네일에 나오지 말아야 한다.
            
        }else if(this.model.component_type == Component_Model.COMPONENT_API){
            //썸네일에 나오지 말아야 한다.

        }else if(this.model.component_type == Component_Model.COMPONENT_DATALOAD){
            //썸네일에 나오지 말아야 한다.

        }else if(this.model.component_type == Component_Model.COMPONENT_TEXT_SCROLL){

            this.component.innerText          = this.model.text;
            this.component.style.fontFamily   = this.model.textfont;
            this.component.style.fontSize     = this.model.textsize+'px';
            this.component.style.color        = this.model.textcolor;
            this.component.style.textAlign    = "left";    //left, center, right
            
            //텍스트 줄 바꿈 없음
            this.component.style.whiteSpace       = "nowrap";
            this.component.style.overflow         = "hidden";

            //텍스트 세로 정렬
            this.component.style.display          = "flex";
            this.component.style.flexDirection    = "column";
            this.component.style.lineHeight       = "120%";
            this.component.style.justifyContent   = "center"; 
            
            this.component.style.letterSpacing    = this.model.letterSpacing+"px" 
            this.component.style.lineHeight       = this.model.lineHeight+"%"     

            //언더라인
            if(this.model.textUnderline == 0){
                this.component.style.textDecoration = "none";
            }else{
                this.component.style.textDecoration = "underline";
            }
            
            //볼드
            if(this.model.textBold == 0){
                this.component.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "normal" : this.model.font_weight;
            }else{
                this.component.style.fontWeight = this.model.font_weight == null || this.model.font_weight.length == 0 ? "bold" : this.model.font_weight;
            }

            //이탤릭
            if(this.model.textItalic == 0){
                this.component.style.fontStyle = "normal";
            }else{
                this.component.style.fontStyle = "italic";
            }

            //그림자 우측, 아래, 블러범위, 색상
            if(this.model.shadow_use){
                this.component.style.textShadow = `${this.model.shadow_hori}px ${this.model.shadow_verti}px ${this.model.shadow_blur}px ${this.model.shadow_color}`;    
            }

            if(this.model.outline_use){
                this.component.style.webkitTextStroke = `${this.model.outline_size}px ${this.model.outline_color}`;  
            }

            //input 텍스트처리 (더블클릭시)
            this.component.contentEditable = "false";

        }
        



    },



  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>