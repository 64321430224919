<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-btn @click="test1">편집기술검토</v-btn>
        기본 편집기술 검토
      </v-col>
      <v-col cols="12">
        <v-btn @click="test6">이중그룹검토</v-btn>
        컴포넌트 안에 컴포넌트를 집어 넣는 이중그룹 검토(파일만 분리함)
      </v-col>
      <v-col cols="12">
        <v-btn @click="test4">편집창검토</v-btn>
        인피니티 뷰어에 다중 스크린 추가 및 이동하고 각 스크린에 컴포넌트 추가 가능, 확대축소 가능
      </v-col>
      <v-col cols="12">
        <v-btn @click="test3">관리자 컨텐츠편집</v-btn>
        실제 편집창 레이아웃 테스트
      </v-col>
      <v-col cols="12">
        <v-btn @click="test5">드래그앤드랍검토</v-btn>
        레프트 패널의 스크린을 드래그 앤 드랍으로 순서 변경하는 샘플
      </v-col>

      <v-col cols="12">
        <v-btn @click="test7">관리자 편집창 검토</v-btn>
        인피니티 뷰어 + (스크린 셀렉토와 무브어블 안쓰게) 완료!
      </v-col>

      <v-col cols="12">
        <v-btn @click="test8">사용자 편집창 검토</v-btn>
        인피니티 뷰어 + 스크린 세로 나열 (검토필요)
      </v-col>

      <v-col cols="12">
        <v-btn @click="test9">Fablic.js 검토</v-btn>
        Fablic.js의 모든 기능 검토
      </v-col>

    </v-row>
  </v-container>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'HelloWorld',

  data () {
    return {
    }
  },

  methods:{

    test1(){
      this.$router.push("/SaveLoad");
    },

    test6(){
      this.$router.push("/GroupTest");
    },

    test4(){
      this.$router.push("/EditWindowTest");
    },

    test3(){
      this.$router.push("/EditViewer");
    },

    test5(){
      this.$router.push("/DragDrop");
    },

    test7(){
      this.$router.push("/EditWindowFixed");
    },

    test8(){
      this.$router.push("/EditWindowScroll");
    },

    test9(){
      this.$router.push("/Fablic");
    },

  }

})
</script>
