<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 8">
    <v-container fluid class="ma-0 pt-0">
        
        <v-row justify="center" align="center" dense class="mt-0 pt-0">
            <v-col cols="3">
                <dialogftcolor dense title="Font Color" ref="bgcolor" 
                    :color="this.contentStore.select_component.model.textcolor"
                    />
                
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-bold" flat size="small"
                    :color="this.contentStore.select_component.model.textBold == 0 ? 'white' : 'purple'"
                    @click="bold_handler"
                    >
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-italic" flat size="small"
                    :color="this.contentStore.select_component.model.textItalic == 0 ? 'white' : 'purple'"
                    @click="Italic_handler"
                    >
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-underline" flat size="small"
                    :color="this.contentStore.select_component.model.textUnderline == 0 ? 'white' : 'purple'"
                    @click="underline_handler"
                    >
                </v-btn>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="11">
                <dialogfont :comname="this.contentStore.select_component.model.font_name" />
                <!-- 
                <comfont 
                    :comname="this.contentStore.select_component.model.textfont"
                    @cancelcallback="comfontcancel" @callback="comfontcb"
                    />
                -->
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-3 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="폰트 사이즈" property="textsize" :min="10" :max="300" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="글자 간격" property="letterSpacing" :min="-100" :max="100" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

        <!-- 텍스트 스크롤 내용 -->
        <v-row justify="center" align="center" class="mt-1" dense>
            <v-col cols="12" class="ma-0">
                <v-textarea
                    v-model="this.contentStore.select_component.model.text"
                    type="text" variant="outlined" density="compact" 
                    hide-details @input="updateRender"
                    @keydown="keydownevent"
                    />
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { useContentStore } from "@/stores/content";

export default {

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    //폰트선택 취소
    comfontcancel(){
    },

    //폰트선택 콜백
    comfontcb(val){
        if(val.FONT_FAMILY.length > 0){
            this.contentStore.select_component.model.textfont     = val.FONT_FAMILY;
            this.contentStore.select_component.model.font_url     = val.FONT_PATH_REF;
            this.contentStore.select_component.model.font_format  = val.FONT_FORMAT;
            this.contentStore.select_component.model.font_weight  = val.FONT_WEIGHT;
            this.contentStore.select_component.model.font_style   = val.FONT_STYLE;

        }else{
            return;
        }
        this.updateRender();
    },    

    change_textalign(){
        this.updateRender();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    keydownevent(ev){
        ev.stopPropagation();
        //this.updateRender();
    },

    colorcallback(){
        this.updateRender();
    }, 
    
    underline_handler(){
        if(this.contentStore.select_component.model.textUnderline == 0){
            this.contentStore.select_component.model.textUnderline = 1;
        }else{
            this.contentStore.select_component.model.textUnderline = 0;
        }
        this.updateRender();
    },

    bold_handler(){
        if(this.contentStore.select_component.model.textBold == 0){
            this.contentStore.select_component.model.textBold = 1;
        }else{
            this.contentStore.select_component.model.textBold = 0;
        }
        this.updateRender();
    },

    Italic_handler(){
        if(this.contentStore.select_component.model.textItalic == 0){
            this.contentStore.select_component.model.textItalic = 1;
        }else{
            this.contentStore.select_component.model.textItalic = 0;
        }
        this.updateRender();
    }


    /* 마우스 드래그로 숫치를 +,-하기
    mousemove_handler(ev){
        if(ev.target.downflag){
            console.log("mousemove_handler : " + ev.offsetX);     
        }
    },
    mousedown_handler(ev){
        console.log("mousedown_handler");
        ev.target.downflag = true;
    },
    mouseup_handler(ev){
        console.log("mouseup_handler");
        ev.target.downflag = false;
    }
    */


  },

  data(){
    return{
        
        textinput_dialog:false,
    }
  },

  mounted(){
    
  },

  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.tbline{
    border-bottom: 1px solid #e4e4e4;
}
 
</style>