<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 20">
    <v-container fluid class="ma-0 pt-0">
        
        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="text-align:center;font-size:12px;margin-right:5px">사운드 바로 재생</label>
                <input type="checkbox" v-model="contentStore.select_component.model.api_call"/>
            </v-col>
        </v-row>        

        <!-- 재생 시간 -->
        <v-row justify="start" align="center" dense class="mt-1">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">재생 시간</label>
            <input 
                v-model="contentStore.select_component.model.sound_time" disabled
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:30px;text-align:left;font-size:12px">Sec</label>
        </v-row>
        <v-row justify="start" align="center" dense class="mt-2">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">대기 시간</label>
            <input 
                v-model="contentStore.select_component.model.sound_delay" 
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:30px;text-align:left;font-size:12px">Sec</label>
        </v-row>
        <v-row justify="start" align="center" dense class="mt-2">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">반복재생</label>
            <input type="checkbox" v-model="contentStore.select_component.model.loop"/>
        </v-row>        

        <!-- 
        <v-row justify="start" align="center" dense class="mt-2" style="margin-left:10px;">
            <label style="width:70px;text-align:center;font-size:12px;margin-left:10px;margin-right:0px">Loop</label>
            <input type="checkbox" v-model="contentStore.select_component.model.sound_loop"/>
        </v-row>
        -->

        <v-row dense justify="center" class="mt-3">
            <audio
                ref="audio" width="100%"
                @loadedmetadata="loadaudiodata"
                />
        </v-row>

        <v-row dense justify="center" class="mt-3">
            <v-col cols="12">
            <v-file-input 
                height="48" class="popupiputstyle" density="compact" variant="outlined"
                prepend-icon="mdi-file"
                show-size hide-details v-model="uploadFile" 
                accept="audio/mp3"
                @change="changeMoviefile"
                />
            </v-col>
        </v-row>
        
        <v-row dense justify="center" class="mt-3">
            <div>{{this.videourl}}</div>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, watch, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import Component_Model from '@/nextts/model/Component_Model';

export default {
  

  setup(){
    const audio         = ref(null);
    const contentStore  = useContentStore();
    return {audio, contentStore}
  },  

  data(){
    return{
        uploadFile : [],
    }
  },

  mounted(){
    //console.log("cominfo audio...mounted");
  },

  activated(){
    //console.log("cominfo audio...activated");
  },

  deactivated(){
    //console.log("cominfo audio...deactivated");
  },

  updated(){
    //console.log("cominfo audio...updated");
    if(this.contentStore.select_component == null) return;
    if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_SOUND){
        if(this.contentStore.select_component.model.sound instanceof File){
            this.audio.src = URL.createObjectURL(this.contentStore.select_component.model.sound);
        }else{
            this.audio.src = this.contentStore.select_component.model.sound;
        }
    }
  },

  methods:{
    

    loadaudiodata(){
        console.log("audio duration : " + this.audio.duration);
        this.contentStore.select_component.model.sound_time = this.audio.duration;
    },

    //동영상 파일 선택시
    changeMoviefile(){
        if(this.uploadFile[0] != undefined && this.uploadFile[0].name != undefined && this.uploadFile[0].name.length > 0){
            console.log("change Audio file");
            if(this.uploadFile[0].size/1024/1024 > 10){
                this.$alert.fire("효과음은 10MB 이하만 사용해주세요");
                this.uploadFile = [];
                return;
            }
            
            this.contentStore.select_component.model.sound = this.uploadFile[0];
            const audiourl = URL.createObjectURL(this.uploadFile[0]);
            this.audio.src = audiourl;
            console.log("audio url  : " + audiourl);   //파일은 blob:으로 시작함

        }
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },


  },

  
}
</script>