<!-- 스크린 썸네일용 뷰 -->
<template>

  <div
    :draggable="draggable" 
    @drag="drag_handler"
    @dragstart="dragstart_handler"
    @dragover="dragover_handler"
    @dragend="dragend_handler"
    @dragenter="dragenter_handler"
    @dragleave="dragleave_handler"
    @drop="drop_handler"
    :style="'height:'+height+'px;max-width:'+width+'px'"
    style="overflow: hidden;"
    ref="container" fluid class="pa-0">
    <div 
      ref="screen"
      :class="isDragOver ? 'dragOver' : 'dragNone'"
      >
      <!-- style="background:gray;border: 1px solid gray" -->
      <template v-for="cm in screen_model.component_list" :key="cm">
        <PreviewComponent :model="cm" draggable="false" />
      </template>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { useContentStore } from "@/stores/content";
import PreviewComponent from '@/nextvue/preview/PreviewComponent.vue'
import Screen_Model from '@/nextts/model/Screen_Model'
import { reactive, ref } from '@vue/reactivity';

export default {

  components:{
    PreviewComponent
  },

  props:{
    screen_model:{
      type:Screen_Model
    },
    scalewidth:{
      type:Number
    },
    draggable:{
      type:Boolean, 
      default(){
        return true;
      }
    }
    /*
    changescreen:{
      tpye:Function
    }
    */
  },

  setup(props){
    const screen = ref(null);
    const container = ref(null);

    const contentStore = useContentStore();

    //const changescreenhandler = ref(props.changescreen);
    //const biul    = props.screen_model.width/props.screen_model.height;
    //const height  = props.scalewidth/biul;

    //원본 사이즈를 축소시켜서 보여줄 스케일
    const scale1  = props.scalewidth/props.screen_model.width;
    const scale2  = props.scalewidth/props.screen_model.height;
    const scale   = Math.min(scale1, scale2);
    
    const width   = props.screen_model.width * scale;
    const height  = props.screen_model.height * scale;
    return {contentStore, screen, container, width, height, scale};
  },

  data:()=>({
    isDragOver:false
  }),

  mounted(){
    //다시 그리기
    this.updateRender();
  },


  methods:{
    
    dragstart_handler(){
      console.log("dragstart_handler : " + this.screen_model.backgroundColor);
      this.contentStore.drag_screen = this.screen_model;
    },

    drag_handler() {
      //  ondrag =  드래그할때 동작 
      console.log("drag_handler : " + this.screen_model.backgroundColor);
    },
    dragover_handler(event) {
      //  ondrag =  드래그할때 동작 
      console.log("dragover_handler");
      //필수
      this.isDragOver = true;
      event.preventDefault();
    },
    dragend_handler() {
      console.log("dragend_handler : "+ this.screen_model.backgroundColor);
    },
    dragenter_handler() {
      console.log("dragenter_handler : " + this.screen_model.backgroundColor);
      this.isDragOver = true;
    },
    dragleave_handler(){
      console.log("dragleave_handler : " + this.screen_model.backgroundColor);
      this.isDragOver = false;
    },
    
    drop_handler(event){
      event.preventDefault();
      console.log("drop_handler : "+ this.screen_model.backgroundColor);
      
      this.isDragOver = false;
      this.contentStore.drop_screen = this.screen_model;

      this.$emit("changescreen");

      

    },


    //스크린 그리기
    updateRender(){

        //배경타입 
        if(this.screen_model.bgtype == 0){
            //color
            this.screen.style.backgroundColor  = this.screen_model.backgroundColor;
            //this.screen.style.backgroundImage  = "";
            this.screen.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 1){
            //image
            this.screen.style.backgroundColor  = "#00000000";
            this.screen.style.backgroundSize   = this.screen_model.width + "px " + this.screen_model.height + "px";
            this.screen.style.backgroundImage  = "url('"+this.screen_model.backgroundImg+"')"
            this.screen.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 2){            
            //gradient
            this.screen.style.background       = this.screen_model.backgroundColor;
            //this.style.backgroundImage  = "";
            this.screen.style.backgroundRepeat = "no-repeat";
        }

        this.screen.style.width  = this.screen_model.width+'px';
        this.screen.style.height = this.screen_model.height+'px';

        //축소시 좌표가 틀어지기 때문에 스케일 중심을 0 0기준으로 맞춘다.
        this.screen.style.transformOrigin = "0% 0%";
        this.screen.style.transform = 'scale('+this.scale+')';

        //외각 라인 그리기
        //this.screen.style.border = "1px red solid";

    },




  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.dragOver{
  border: 10px rgb(255, 0, 0) solid;
}

.dragNone{
  border: 1px rgb(107, 107, 107) solid;
}



</style>