<!-- 현재 해당 페이지는 사용안함 -->
<template>

  <v-container fluid >

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />    

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>    


    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" max-width="600px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row dense align="center" class="text-center">
                  <v-col cols="3">
                    <label>이미지 타이틀</label>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field 
                      single-line v-model="editedItem.MASK_NAME" dense
                      placeholder="타이틀을 입력하세요" variant="outlined" density="compact" color="#808080" hide-details        
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" justify="center">
                  <v-col cols="3" align="center">
                    <label class="mytextstyle">상태</label>
                  </v-col>
                  <v-col cols="9">
                    <v-select v-model="editedItem.MASK_STATE" :items="STATELIST" variant="outlined" density="compact"
                      item-title="name" item-value="value" class="dialogselectstyle"
                      hide-details elevation="0" dense height="48px"
                      />
                  </v-col>
                </v-row>

                <v-row  dense align="center" class="text-center">
                  <v-col cols="3">
                    <label class="mytextstyle">이미지</label>
                  </v-col>
                  <v-col cols="9" align="center" align-self="center" style="height:120px;width:120px">
                      <template v-if="previewImg1.length > 0">
                        <v-row dense  justify="center">
                          <div style="position:relative">
                            <div style="position:absolute;margin-top:80px;margin-left:0px;height:40px;width:120px;background-color:#00000080">
                              <v-icon style="width:40px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                              <v-icon style="width:40px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
                            </div>
                            <img  height="120" width="120" :src="previewImg1" class="image">
                          </div>
                        </v-row>
                      </template>
                      <template v-else>
                        <v-row dense class="image-border" align="center" justify="center">
                          <v-icon style="width:50px;height:50px" @click="fileSelect(0)">mdi-plus</v-icon>
                        </v-row>
                      </template>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5" dense>
      <v-col cols="6" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
  </v-row>

  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>

    
  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="MASK_IDX"
        :loading="loading"
        :mobile="false"
        >
        <template v-slot:header.MASK_NAME>
          <a :class="sort_name=='MASK_NAME'?'cheader_sortselect':'cheader_sort'" @click="customsort('MASK_NAME')">이미지명</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일시</a>
        </template>

        <template v-slot:item.MASK_THUM="{ item }">  
            <v-col class="pa-0" align="center">
              <template v-if="item.MASK_THUM != null && item.MASK_THUM.length > 0">
                <v-img :src="item.MASK_THUM" style="width:50px;height:50px;" contain/>
              </template>
              <template v-else>
                <v-icon style="height:50px;width:50px" size="50">mdi-image</v-icon>
              </template>
            </v-col>
        </template>

        <template v-slot:item.REG_DATE="{ item }">  
          {{ this.$momenttz.utc(item.REG_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }}
        </template>

        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>  
        <template v-slot:bottom></template>
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
 
  

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref as fref, uploadBytesResumable } from "firebase/storage";
import { ref } from '@vue/reactivity';
export default {
    components:{
      VueCropper
    },

    setup(){
        var searchbar = ref(null);
        var paging = ref(null);
        return {searchbar, paging};
    },

    data: () => ({

      progress_dialog:false,
      progress_value:0,
      uploadFile1 : [],
      uploadFileThum1 : [],
      previewImg1 : "",
      imgSrc:'',
      crop_dialog:false,
      photo_index:0,

      STATELIST: [
          {name:'사용안함',   value:0}
        , {name:'사용',       value:1}
      ],

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "png",
        canMove: true,
        fixed: false,          //크롭박스 비율 고정
        fixedNumber: [1,1],  //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          MASK_NAME:'',
          MASK_TAG:'',
          THUM_IMG1:'',
          MASK_STATE:1,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '이미지', key: 'MASK_THUM', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {title: '이름', key: 'MASK_NAME', sortable: false, align:'center', class:"cheader_normal"},
        {title: '상태', key: 'MASK_STATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '등록일', key: 'REG_DATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '수정', key: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      SEARCH_CATEGORY_IDX:null,   
      categoryList:[],

    }),

    mounted(){

      if(this.$storage.getStorageSync("GRADE") < 80){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {


      async loadanicategory(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}
        
        this.$http.post(this.$host+'/CategoryTypeList',{
              search_keyword: ''
            , CATEGORY_TYPE: 2
            , sort_name: "CATEGORY_SORT"
            , sort_type: "ASC"
        },{headers: { firetoken:token}})
        .then((result)=>{
          
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.categoryList.splice(0, this.categoryList.length);   //데이터 초기화
              this.categoryList.push({CATEGORY_IDX:-1, CATEGORY_NAME:'전체'});
              list.forEach(element => {
                this.categoryList.push(element); 
              });
              
              this.SEARCH_CATEGORY_IDX = this.categoryList[0].CATEGORY_IDX;
              this.loadpage(this.page);

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/ResMaskList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
        input.onchange = function (event) {
          changefunction(event.target.files[0], index);
        };
        input.click();
      },

      //이미지 선택시
      changefile(file, pindex){
        if(file.size/1024/1024 > 10){
          this.$alert.fire("10MB 이하 파일만 사용할 수 있습니다.");
          return;
        }
        const reader = new FileReader();
        reader.onload = (event)=>{
          this.photo_index = pindex;
          this.crop_dialog = true;
          this.imgSrc = event.target.result;  //이게 blob데이터
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서
      },


      //이미지 크롭시
      cropimg(){
        
        this.$refs.cropper_land.getCropBlob(blob=>{
          this.crop_dialog = false;
          this.$refs.cropper_land.getCropData(async data=>{
            
            if(this.photo_index == 0){

              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data;  //이미지뷰 미리보기용

              //썸네일 생성
              var image     = await this.loadImage(data);
              const canvas  = document.createElement("canvas");
              canvas.width  = 100;
              canvas.height = 100;
              canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

              this.uploadFileThum1 = this.dataURItoBlob(canvas.toDataURL("image/png"));     //썸네일 blob데이터
              console.log("uploadFileThum11 : " + this.uploadFileThum1);

            }

          });

        });      
      },

      //지정한 data를 넣은 이미지를 리턴한다.
      loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
          myImage.onload = () => resolve(myImage)
        })
      },

      //캔버스 TO BLOB
      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

      //firebase storage에 파일 업로드 및 프로그레스 표시
      uploadFileStorage(storage_ref, blobd_ata, metadata){
        return new Promise((resolve, reject)=>{

            const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                }                
              },
              (error) => {
                reject(null);
              },
              () => {
                // Upload completed successfully - 업로드 완료시 경로 리턴
                resolve(uploadTask.snapshot.ref);
              }
            );

        })
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },



      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/ResMaskList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , CATEGORY_IDX:this.SEARCH_CATEGORY_IDX
          },{headers: { firetoken:token}})
          .then(async (result)=>{
            
            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;

                console.log("list : " + JSON.stringify(list));

                this.itemList.splice(0, this.itemList.length);   //데이터 초기화

                for(let element of list){
                  this.itemList.push(element); 
                }

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.uploadFile1 == null || this.uploadFile1 == undefined || this.uploadFile1.length < 1){
            if(this.isNullCheck(this.editedItem.MASK_PATH_REF)){
              //기존에 등록된 이미지가 있으면 패스
              this.$alert.fire("이미지를 등록하세요");
              return;
            }
        }else{

            //업로드 파일이 있는 경우
            this.progress_dialog = true;            
            
            let thumpath;
            let imgpath;
            //신규인지 업데이트인지 체크
            if(!this.isNullCheck(this.editedItem.MASK_PATH_REF)){
              console.log("insert update img");
              //업데이트인 경우 기존 메타데이터 읽기
              thumpath = this.editedItem.MASK_THUM_REF
              imgpath  = this.editedItem.MASK_PATH_REF;
            }else{
              console.log("insert new img");
              let curdate = Date.now().toString();
              thumpath = `imgmask/${curdate}_thum.png`;
              imgpath  = `imgmask/${curdate}.png`;
            }

            //썸네일 등록
            if(this.uploadFileThum1){

              console.log("uploadFileThum1 1");
              const uploadthum_ref1 = fref(this.$firestorage, thumpath);
              const metadata_thum1 = { contentType: 'image/png', resumable: false }; 
              let upload_thum_cb1 = await this.uploadFileStorage(uploadthum_ref1, this.uploadFileThum1, metadata_thum1);
              if(upload_thum_cb1 == null){
                this.progress_dialog = false;
                this.$alert.fire("이미지 등록 오류 발생");
                return;
              } 
              this.editedItem.MASK_THUM = upload_thum_cb1._location.path_;
              this.uploadFileThum1 = [];

              //썸네일 경로 저장
              this.editedItem.MASK_THUM_REF = upload_thum_cb1._location.path_;
            }
            
            //대표 이미지 등록
            console.log("upload file 1 : " + this.uploadFile1.type);
            const uploadfile_ref1 = fref(this.$firestorage, imgpath);
            const metadata1 = { contentType: 'image/png', resumable: false}; 
            let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadFile1, metadata1);
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$alert.fire("이미지 등록 오류 발생");
              return;
            } 
            this.uploadFile1 = [];

            //업로드 패스 저장
            this.editedItem.MASK_PATH_REF = upload_cb1._location.path_;
        }

        if(this.editedIndex > -1){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/ResMaskUpdate', {
              MASK_IDX: this.editedItem.MASK_IDX
            , MASK_NAME: this.editedItem.MASK_NAME == null || this.editedItem.MASK_NAME == undefined ? '' : escape(this.editedItem.MASK_NAME)
            , MASK_PATH: this.editedItem.MASK_PATH_REF == null || this.editedItem.MASK_PATH_REF == undefined ? '' : escape(this.editedItem.MASK_PATH_REF)
            , MASK_THUM: this.editedItem.MASK_THUM_REF == null || this.editedItem.MASK_THUM_REF == undefined ? '' : escape(this.editedItem.MASK_THUM_REF)
            , MASK_STATE: this.editedItem.MASK_STATE
          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(this.page);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.progress_dialog = false;
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });
        }else{
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/ResMaskInsert',{
              MASK_NAME: this.editedItem.MASK_NAME == null || this.editedItem.MASK_NAME == undefined ? '' : escape(this.editedItem.MASK_NAME)
            , MASK_TAG: this.editedItem.MASK_TAG == null || this.editedItem.MASK_TAG == undefined ? '' : escape(this.editedItem.MASK_TAG)
            , MASK_PATH: this.editedItem.MASK_PATH_REF == null || this.editedItem.MASK_PATH_REF == undefined ? '' : escape(this.editedItem.MASK_PATH_REF)
            , MASK_THUM: this.editedItem.MASK_THUM_REF == null || this.editedItem.MASK_THUM_REF == undefined ? '' : escape(this.editedItem.MASK_THUM_REF)
            , MASK_STATE: this.editedItem.MASK_STATE
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                
                const p = this.page;
                this.page = 0;
                this.$alert.fire("새로운 이미지가 추가되었습니다.").then(()=>{
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert.fire('추가 실패');
          });
        }

        this.close()
      },      


      //신규 추가
      newinsert(){
        this.uploadFile1 = [];
        this.previewImg1 = "";
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
        window.scrollTo(0,0);
      },

      //아이템 수정
      edit_item(item){
        this.uploadFile1 = [];
        this.previewImg1 = "";        
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item);
        this.editedItem  = Object.assign({}, item);
        this.previewImg1 = this.editedItem.MASK_PATH != null && this.editedItem.MASK_PATH.length > 0 ? this.editedItem.MASK_PATH : "";
        this.dialog = true;
      },



      //아이템 삭제
      delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/ResMaskDelete',{
              MASK_IDX:item.MASK_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                
                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
                
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },      

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


  .image-border {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }
</style>