<template>
  <v-row align="center"  justify="center">

    <v-dialog scrollable overlay-color="#000000FF" persistent v-model="pay_dialog" 
      :fullscreen="$vuetify.display.xs"
      class="none_radius" max-width="600">

      <v-card class="pa-0 ma-0">

      <v-row justify="center" align="center" class="mt-3">
        <v-col cols="12" align="center">
          <div>결제정보</div>
          <v-spacer/>
        </v-col>
      </v-row>
      
      <!-- 입력정보 표시 -->
      <v-row align="start" justify="center" dense>
        <v-col cols="11">
          
          <v-table style="border-radius:15px;">
            <tbody>
              <tr><td colspan="2" class="table_margin"></td></tr>
              <tr>
                <td class="tabletitle">결제코드</td>
                <td class="tabledesc">{{ DETAIL_CONTENT.PAY_CODE }}</td>
              </tr>
              <tr>
                <td class="tabletitle">결제방법</td>
                <td class="tabledesc">{{ DETAIL_CONTENT.PAY_METHOD }}</td>
              </tr>
              <tr>
                <td class="tabletitle">결제금액</td>
                <td class="tabledesc">{{ DETAIL_CONTENT.PAY_AMOUNT.toLocaleString()+'원' }}</td>
              </tr>
              <tr>
                <td class="tabletitle">결제자</td>
                <td class="tabledesc">{{ DETAIL_CONTENT.PAY_NAME }}</td>
              </tr>
              <tr><td colspan="2" class="table_margin"></td></tr>
            </tbody>
          </v-table>

        </v-col>
      </v-row>

      <v-card-actions class="mt-1 mb-1">
        <v-row align="center" justify="center">
          <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
            <v-row class="ma-0 pa-0" justify="center">
              <v-col cols="12" class="ma-0 pa-0" align="center">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" @click="pay_dialog = false">닫기</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-actions>
      </v-card>

    </v-dialog>

  </v-row>
    
  </template>
  
  <script>
  import { ref } from '@vue/reactivity';
    export default {
  
      setup(){


      },

      data: () => ({
        pay_dialog: false,

        grade: 0,
        localid:"",
        PAY_IDX:"",        //넘겨받은 게시글 번호
        DETAIL_CONTENT:{},   //상세정보

      }),
  
  
      beforeUnmount() {
      },

      mounted(){
        window.scrollTo(0,0);
        this.grade    = this.$storage.getStorageSync("GRADE");
        this.localid  = this.$storage.getStorageSync("LOCALID");
      },
  
      create(){
        
      },
  
      methods: {
        
        async loadpage(pay_idx){
          
          //토큰값 체크
          let token;
          try{
            token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
          }

          this.PAY_IDX = pay_idx;

          //내용 가져오기
          this.$http.post(this.$host+'/MyPaymentDetail',{
              PAY_IDX: this.PAY_IDX
            , LOCALID: this.localid
          },{headers: { firetoken:token}})
          .then(async(result)=>{
          
            if(result.data.resultCode == 0){
              //이미 선택된 아이템에 상세만 교체
              window.scrollTo(0,0);
              this.DETAIL_CONTENT  = result.data.resultData[0];
              if(this.DETAIL_CONTENT == undefined){
                this.$alert.fire("내용이 없습니다.");
                this.pay_dialog = false;
              }else{
                this.pay_dialog = true;
              }              
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            
          });
        },
  
    },
    
  }
  </script>
  
  <style lang="css">
    td{
      height: 36px !important;
    }
  </style>
  
  <!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
  <style scoped>
  .v-main__wrap{
    background: white;
  }

  .reppleinput{
    margin-left: 10px;
    margin-bottom:8px;
    margin-top:8px;
    width:100%; 
    background:#f2f2f2f2; 
    border: 1px solid #ccc; 
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: text; 
    border-radius: 30px;
    font-size: 12px;
  }

  /* 게시글 스타일 */
  .notice_title{
    font-size:20px;
    font-weight: bold;
    color:#121212;
  }
  .notice_name{
    font-size:18px;
    font-weight: bold;
    color:#252525;
  }
  .notice_date{
    font-size:14px;
    color:#626262;
  }

  /* 답글 댓글 스타일 */
  .repple_name{
    font-size:14px;
    font-weight: bold;
  }
  .repple_time{
    font-size:10px;
    font-weight: bold;
  }
  .repple_comment{
    font-size:15px;
  }
  .repple_write{
    font-size:14px;
    font-weight: bold;
    color:#494949;
    cursor: pointer;
  }
  .repple_line{
    border-bottom-color: #ececec;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
  

  
table{
  border-radius: 10px !important;
  border-collapse: separate; 
  border-spacing:0;
  border: none;
}

table, th, td {
  border: none !important;
}

.table_margin{
  height: 10px !important;
  background: #F6F6F6;
}

.tabletitle{
  width:30%;
  min-width: 100px;
  font-family: 'Pretendard';
  font-size:14px;
  font-weight:normal;
  color:#999999;
  text-align: left;
  background: #F6F6F6;
}

.tabledesc{
  width:70%;
  font-family: 'Pretendard';
  font-size:14px;
  font-weight:bold;
  color:#333333;
  border-left-width: 0px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  background: #F6F6F6;
}


  </style>