import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test1 }, {
                default: _withCtx(() => [
                  _createTextVNode("편집기술검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 기본 편집기술 검토 ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test6 }, {
                default: _withCtx(() => [
                  _createTextVNode("이중그룹검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 컴포넌트 안에 컴포넌트를 집어 넣는 이중그룹 검토(파일만 분리함) ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test4 }, {
                default: _withCtx(() => [
                  _createTextVNode("편집창검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 인피니티 뷰어에 다중 스크린 추가 및 이동하고 각 스크린에 컴포넌트 추가 가능, 확대축소 가능 ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test3 }, {
                default: _withCtx(() => [
                  _createTextVNode("관리자 컨텐츠편집")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 실제 편집창 레이아웃 테스트 ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test5 }, {
                default: _withCtx(() => [
                  _createTextVNode("드래그앤드랍검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 레프트 패널의 스크린을 드래그 앤 드랍으로 순서 변경하는 샘플 ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test7 }, {
                default: _withCtx(() => [
                  _createTextVNode("관리자 편집창 검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 인피니티 뷰어 + (스크린 셀렉토와 무브어블 안쓰게) 완료! ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test8 }, {
                default: _withCtx(() => [
                  _createTextVNode("사용자 편집창 검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" 인피니티 뷰어 + 스크린 세로 나열 (검토필요) ")
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, { onClick: _ctx.test9 }, {
                default: _withCtx(() => [
                  _createTextVNode("Fablic.js 검토")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createTextVNode(" Fablic.js의 모든 기능 검토 ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}