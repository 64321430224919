<!-- 리소스는 사용 안해도 될 것 같아서 여기에 그냥 파베 테스트 했음. -->

<template>

  <v-container fluid>

  <v-row align="center" justify="start">
    <v-col cols="12" md="3">
      <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
    <v-col cols="12" md="3" v-if="this.$storage.getStorageSync('GRADE') >= 10">
      <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
    </v-col>
  </v-row>
  
  <v-row align="start" dense class="mt-5 mt-md-3">
    <v-col cols="12" md="2"> 
      <v-row dense justify="end">
        <v-btn @click="addDataPage">추가</v-btn>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-table 
            fixed-header density="compact" height="600"
            >
            <thead>
              <tr>
                <th style="width:250px;">이름</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="(content, index) in contentList" :key="index">
              <td :style="$vuetify.display.smAndDown ? 'width:100%': 'width:250px'" class="d-flex align-center">
                <a @click="$vuetify.display.smAndDown ? setDataListMB(content) : setDataList(content)" class="me-auto" style="cursor:pointer;">{{content}}</a>
                <v-icon @click="deletefield(content)" color="black" icon="mdi-delete" size="x-small"></v-icon>
              </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="10" v-if="!$vuetify.display.smAndDown"> 
      <v-row dense justify="end">
        <v-btn @click="addDataParam">추가</v-btn>
        <v-btn @click="save">저장</v-btn>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-table 
            fixed-header density="compact" height="600"
            >
            <thead>
              <tr>
                <th style="width:50px;" class="text-center"></th>
                <th style="width:150px; text-align:center" class="text-center">필드명</th>
                <th style="width:80px; text-align:center" class="text-center">타입</th>
                <th style="width:80px; text-align:center" class="text-center">마스킹</th>
                <th style="width:70px; text-align:center" class="text-center">넓이</th>
                <th style="width:70px; text-align:center" class="text-center">높이</th>
                <th style="width:80px; text-align:center" class="text-center">삭제</th>
              </tr>
            </thead>
            <draggable v-model="itemList" tag="tbody"  
              @change="handleDrag" :key="listRenderCount"
              >
              <template #item="{ element }">
                <tr>
                  <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                  <td class="text-center"><input style="width:150px;" v-model="element.name" class="inputparam_bg" /></td>
                  <td class="text-center">
                    <select 
                      v-model="element.type"
                      style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                      <template v-for="cptype in typelist" :key="cptype.name">
                          <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                      </template>
                    </select>
                  </td>
                  <td class="text-center"><input v-model="element.mask" style="width:80px;" class="inputparam_bg" /></td>
                  <td class="text-center"><input v-model="element.width" style="width:80px;" class="inputparam_bg" /></td>
                  <td class="text-center"><input v-model="element.height" style="width:80px;" class="inputparam_bg" /></td>
                  <td class="text-center"><v-icon small @click="delete_item(element)">mdi-delete</v-icon></td>
                </tr>
              </template>
            </draggable>
          </v-table>
        </v-col>
      </v-row>
    </v-col>

  </v-row>

  

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { ref } from '@vue/reactivity';
import { useContentStore } from "@/stores/content";
// eslint-disable-next-line no-unused-vars
import { addDoc, getDoc, doc, setDoc, collection, updateDoc, getDocs, serverTimestamp } from "firebase/firestore";
export default {

    components:{
      draggable
    },  

    data: () => ({

      loading : false,    //로딩 표시
      listRenderCount:0,
      curArrayName:'',

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          LOCALID:'',
          DEVICE_NAME:'',
          STATE:1,
      },
      editedItem: {},
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      theaders1: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' }
      ],

      theaders2: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' },
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],      
      contentList: [],
      itemList: [],
      datamap: [],

      typelist:[
          {name:"TEXT",  value:"text"}
        , {name:"IMAGE", value:"image"}
      ],

      sort_name: 'DEVICE_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',

      grade:0,
      limit_page:0,

    }),

    setup(){
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        let contentStore   = useContentStore();
        return {paging, searchbar, editor, contentStore};
    },

    async mounted(){
      
      if(this.$storage.getStorageSync("GRADE") < 1){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
        this.$router.push("/Login");
        return;
      }

      this.SEARCH_LOCALID         = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      
      if(this.$storage.getStorageSync("GRADE") < 1){
      //if(this.$storage.getStorageSync("GRADE") < 1 && this.$storage.getStorageSync("LIMIT_PAGE") == 0){
        this.$alert.fire("사용 권한이 없습니다.");
        this.$router.back();
        return;
      }
      this.limit_page = this.$storage.getStorageSync("LIMIT_PAGE");

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.itemList.splice(0, this.itemList.length);
          this.$http.post(this.$host+'/MyResList',{
              LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID:this.SEARCH_PARENT_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                //const list = result.data.resultData;
                if(result.data.resultData != null){
                  
                  console.log("res : " + JSON.stringify(result.data.resultData));

                  this.datamap = result.data.resultData;
                  this.contentList.splice(0, this.contentList.length); 
                  for (const arrayName in this.datamap.dataformat){
                    this.contentList.push(arrayName); 
                  }
                  
                  if(this.contentList.length > 0){
                    this.setDataList(this.contentList[0]);  
                  }else{
                    this.datamap = [];
                  }

                }else{

                  this.contentList.splice(0, this.contentList.length); 

                }
                /*
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                */
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      setDataListMB(arrayName){

        this.contentStore.datamap = this.datamap;
        this.$router.push({path:"/UserPageDetail", query:{name:arrayName}});

      },

      setDataList(arrayName){

        this.curArrayName = arrayName;
        //우측 테이블에 배열 요소 표시
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화
        for (const element of this.datamap.dataformat[this.curArrayName]) {
          console.log("setData : " + JSON.stringify(element));
          this.itemList.push(element); 
        }
      },

      //우측 순서변경
      handleDrag(){
        //원본 데이터에 순서가 변경된 것을 저장한다.
        this.datamap.dataformat[this.curArrayName] = [...this.itemList];
      },

      deletefield(content){
        
        this.$alert.fire({title:"선택하신 필드를 정말 삭제하시겠습니까?", showCancelButton:true} ).then(async (result) => {
          if(result.isConfirmed){
            
            delete this.datamap.dataformat[content];
            const index = this.contentList.indexOf(content);
            if(index > -1){
              this.contentList.splice(index,1);
              this.save();
            }

          }
        });        

      },

      //데이터 페이지 추가
      addDataPage(){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length + 1 ){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }
        
        this.$alert.fire({
          title: '추가하실 저장소 이름을 입력하세요.',
          input: 'text',
          inputPlaceholder: '저장소 이름을 입력하세요',
          showCancelButton: true,
          confirmButtonText: '추가',
          cancelButtonText: '취소',
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!';
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {

            if(this.datamap == null || this.datamap == undefined || this.datamap.length == 0){
              //신규 데이터맵 생성
              this.datamap    = { datamap : new Map(), dataformat: new Map() };
            }
            this.datamap.datamap[result.value]    = [];
            this.datamap.dataformat[result.value] = [];

            this.contentList.splice(0, this.contentList.length); 
            for (const arrayName in this.datamap.dataformat){
              this.contentList.push(arrayName); 
            }
            
            this.setDataList(this.contentList[0]);

          }
        });        



      },

      addDataParam(){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.curArrayName == null || this.curArrayName == undefined || this.curArrayName.length == 0){
          return;
        }
        this.itemList.push({'name':'','type':'text', 'mask':'', 'width':"0", 'height':"0"});
        this.datamap.dataformat[this.curArrayName] = [...this.itemList];

      },


      //아이템 삭제
      delete_item(item){
        const idx = this.itemList.indexOf(item);
        if (idx !== -1) {
          this.itemList.splice(idx, 1);
        }
        this.datamap.dataformat[this.curArrayName] = [...this.itemList];
      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        this.dialog_pw  = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.datamap == null || this.datamap == undefined || this.datamap.length == 0){
          //신규 데이터맵 생성
          this.$alert.fire('저장데이터가 없습니다.');
          return;
        }
        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/MyResSave',{
              DATAMAP: JSON.stringify(this.datamap)
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            this.$alert.fire("수정이 완료되었습니다.").then(()=>{
              //this.loadpage();
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('수정이 실패했습니다.');
        });

      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage();
      },      


      //신규 추가
      newinsert(){
        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage();
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage();
      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.inputparam_bg{
  background: #e4e4e4;
  border-radius: 5px;
  color: #000000;
  padding: 5px 10px 5px 10px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

</style>