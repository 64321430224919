<template>

  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:1000px">

    <v-row class="pt-5">
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    
    
    <v-row justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <label style="font-size:35px;color:#121212">{{BOARD_CONTENT.TITLE}}</label>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11" style="display:inline-flex">
        <v-icon size="50">
          mdi-account-circle
        </v-icon>
        <span class="ml-2">
          <div><label style="font-size:20px;color:#626262">관리자</label></div>
          <div><label style="font-size:16px;color:#626262">{{BOARD_CONTENT.UP_DATE}}</label></div>
        </span>
        <v-spacer/>
        <span class="ml-2">
          <v-btn @click="back" elevation="0" text >목록</v-btn>
        </span>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <div style="height:3px;background:#ececec"/>
      </v-col>
    </v-row>
      
    <!-- 
    <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <div v-html="BOARD_CONTENT.DESC"/>
      </v-col>
    </v-row>
    -->

    <v-row align="start" justify="center">
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <ToastUIEditorViewer 
          v-if="BOARD_CONTENT.DESC != null" 
          :data="BOARD_CONTENT.DESC" 
          />
      </v-col>
    </v-row>

    <v-row align="center" justify="center" dense>
      <v-col cols="11" xl="8" lg="8"  md="10" xs="11" sm="11" align="end">
        <v-btn @click="back" elevation="0" text >목록</v-btn>
        <v-btn @click="movetop" elevation="0" text >TOP</v-btn>
      </v-col>
    </v-row>

    <v-row justify="center" dense class="mt-2">
      <v-col ccols="11" xl="8" lg="8"  md="10" xs="11" sm="11">
        <v-row style="height:1px;background:#9f0007"/>
      </v-col>
    </v-row>

    <v-row style="height:200px"></v-row>

  </v-container>
</template>

<script>
import ToastUIEditorViewer from '@/components/ToastUIEditorViewer.vue' 
export default {

    components: {
      ToastUIEditorViewer
    },

    data: () => ({

      loading : false,    //로딩 표시
      progress_dialog:false,

      grade: 0,
      NOTICE_IDX:"",        //넘겨받은 게시글 번호
      BOARD_CONTENT:{},   //게시글 상세정보

    }),


    beforeUnmount() {
    },

    mounted(){
      this.grade    = this.$storage.getStorageSync("GRADE");
      this.MANUAL_IDX = this.$route.query.code;
      this.loadpage();
    },

    create(){
      
    },

    methods: {




      movetop(){
        window.scrollTo(0,0);
      },

      back(){
        //뒤로
        this.$router.back();
      },
      
      loadpage(){

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestManualDetail',{
            MANUAL_IDX: this.MANUAL_IDX
        }).then((result)=>{
          
          console.log("result  : " + JSON.stringify(result));

          this.loading = false;
          if(result.data.resultCode == 0){
            //이미 선택된 아이템에 상세만 교체
            window.scrollTo(0,0);
            this.BOARD_CONTENT  = result.data.resultData[0];
            if(this.BOARD_CONTENT == undefined){
              this.$alert.fire("게시글이 없습니다.");
            }
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },



    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.v-main__wrap{
  background: white;
}
</style>