<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 1">
    <v-container fluid>

        <!-- 이미지 크롭 팝업 -->
        <v-row align="center"  justify="center" >
        <v-dialog persistent scrollable overlay-color="#00000040"
            :fullscreen="false"
            v-model="crop_dialog" max-width="500px">

            <v-card class="pa-0 ma-0">

            <v-card-title class="ma-0 pa-0" style="overflow:hidden">
            <v-row dense align="center" justify="center">
                <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                <span style="color:white">이미지 편집</span>
                </v-col>
            </v-row>
            </v-card-title>

            <v-card-text class="ma-0 pl-5 pr-5 pt-3">

                <v-row align="center" justify="center" dense>
                <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->

                <div style="width:100%;height:500px;">
                    <vueCropper
                    ref="cropper_land"
                    :img="imgSrc"
                    :outputType="option.outputType"
                    :fixed="option.fixed"
                    :fixedNumber="option.fixedNumber"
                    :full="option.full"
                    :canMove="option.canMove"
                    :canMoveBox="option.canMoveBox"
                    :fixedBox="option.fixedBox"
                    :original="option.original"
                    :autoCrop="option.autoCrop"
                    :autoCropWidth="option.autoCropWidth"
                    :autoCropHeight="option.autoCropHeight"
                    :centerBox="option.centerBox"
                    :high="option.high"
                    :infoTrue="option.infoTrue"
                    :maxImgSize="option.maxImgSize"
                    :enlarge="option.enlarge"
                    :mode="option.mode"
                    :limitMinSize="option.limitMinSize"
                    />
                </div>
                </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions class="mt-1 mb-1">
                <v-row align="center" justify="center">
                <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                    <v-row class="ma-0 pa-0">
                    <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">저장</v-btn>
                    </v-col>
                    </v-row>
                </v-col>
                </v-row>
            </v-card-actions>

            </v-card>
        </v-dialog>
        </v-row>     


    

        
        <template v-if="this.contentStore.select_component.model.image.length > 0">
            <v-row dense  justify="center">
              <div style="position:relative">
                <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:200px;background-color:#00000080">
                    <v-icon style="width:50px;height:40px;color:white" @click="fileSelect()">mdi-pencil</v-icon>
                    <!-- <v-icon style="width:50px;height:40px;color:white" @click="clearFile()">mdi-window-close</v-icon> -->
                </div>
                <img  width="200" style="hegiht:auto" :src="this.contentStore.select_component.model.image" class="image">
              </div>
            </v-row>
        </template>
        <template v-else>
            <v-row dense class="image-border2" align="center" justify="center">
                <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect()">mdi-image</v-icon>
            </v-row>
        </template>

        

        <!-- 
        <v-row align="center" justify="center">
            <v-col cols="10">
                <v-textarea
                    v-model="component.model.image"
                    type="text" variant="outlined" density="compact" 
                    hide-details @input="updateRender"
                    />
            </v-col>
        </v-row>
        -->
    </v-container>
    </template>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import Component_Model from '@/nextts/model/Component_Model'

// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";


import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";

export default {

  components:{
    VueCropper
  },

  setup(){
    let cropper_land = ref(null);
    const contentStore = useContentStore();
    return {cropper_land, contentStore};
  },

  methods:{

    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    clearFile(){
        //this.previewImg = "";
        this.uploadFile = null;
        this.contentStore.select_component.model.image = null;
    },

    fileSelect(){

        const changefunction = this.changefile;
        var input    = document.createElement("input");
        input.style  = "display:none";
        input.name   = "imageinputcamera";
        input.type   = "file";
        input.accept = ".jpg,.jpeg,.png,.gif"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){

            //아이폰, 아이패드, 맥 => input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
            document.body.appendChild(input);
            input.onchange = function (event) {
                //추가된 input 제거
                document.body.removeChild(input);
                changefunction(event.target.files[0]);
            };

        }else{
            input.onchange = function (event) {
                changefunction(event.target.files[0]);
            };
        }
        input.click();
    },    

    //이미지 선택시
    changefile(file){

        if(file.size/1024/1024 > 10){
            alert("10메가 이하 파일만 사용하세요.");
            this.uploadFile = [];
            return;
        }
        
        var fileName = file.name; // 파일 이름
        var fileExtension = fileName.split('.').pop(); // 확장자 추출
        if(fileExtension == "gif"){
            //gif는 그냥 등록
            const reader = new FileReader();
            const updateFunction = this.updateRender();
            reader.onload = (event)=>{
                this.contentStore.select_component.model.image = event.target.result;
                updateFunction();
            }
            reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        }else{
            //이미지 크롭 팝업 띄우기
            this.option.autoCropWidth  = this.contentStore.select_component.model.width;  
            this.option.autoCropHeight = this.contentStore.select_component.model.height;
            this.option.fixedNumber = [this.contentStore.select_component.model.width, this.contentStore.select_component.model.height];

            const reader = new FileReader();
            reader.onload = (event)=>{
                this.crop_dialog = true;
                this.imgSrc = event.target.result;  //이게 blob데이터
            }
            reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        }


    },


    //이미지 크롭시
    cropimg(){
        
        //const updateFunction = this.updateRender();
        
        //이미지 크롭 
        this.cropper_land.getCropBlob(blob=>{

            this.crop_dialog = false;
            this.cropper_land.getCropData(async data=>{
  
                this.uploadFile = blob;  //업로드용 blob 데이터
                this.contentStore.select_component.model.image = data;

                this.contentStore.select_component.model.component.updateRender();

                //updateFunction();
               
            });

        });
    },    

    //지정한 data를 넣은 이미지를 리턴한다.
    loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
            myImage.onload = () => resolve(myImage)
        })
    },

      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

  },

  //프로퍼티 선언
  props:{
  },  

  data(){
    return{

        crop_dialog:false,
        imgSrc:'',

        //previewImg:"",
        uploadFile : [],
        uploadFileThum : [],

        
        //신규 크롭 파라메터
        option: {
            img: "",
            size: 1,
            full: true,
            outputType: "jpg",
            canMove: true,
            fixed: true,          //크롭박스 비율 고정
            fixedNumber: [1,1],  //크롭박스 비율
            fixedBox: false,      //true면 크롭박스 크기 조절 안됨
            original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
            canMoveBox: true,
            autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
            autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
            autoCropHeight:300,   //크롭박스의 세로 크기 
            centerBox: true,
            high: false,
            cropData: {},
            enlarge: 1,
            mode: 'contain',
            maxImgSize: 5000,
            limitMinSize: [100, 100]
        },            


    }
  },

  mounted(){
    
  },

  
}
</script>