<template>

  <v-container fluid>

    <comsubscript ref="comsubscript"/>
    <compayment ref="paydialog"/>

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="500px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11" sm="11" xs="11">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              
              <v-row>
                <v-col cols="3" align="center" align-self="center">
                  <label>시작일</label>
                </v-col>
                <v-col cols="9">
                  <v-menu
                    v-model="start_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy transition="scale-transition"
                    offset-y full-width
                    min-width="290px"
                    >
                    <template v-slot:activator="{ props }">
                      <input
                        :value="showstartDate" v-bind="props" style="cursor:pointer;" readonly
                        class="input_input"
                        />                        
                    </template>
                    <v-date-picker v-model="editedItem.START_DATE" @update:modelValue="start_date = false" 
                      :first-day-of-week="0" color="primary" picker="date"
                      :format="YYYY-MM-DD"
                      />
                  </v-menu>                         
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align="center" align-self="center">
                  <label>종료일</label>
                </v-col>
                <v-col cols="9">
                  <v-menu
                    v-model="exfire_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy transition="scale-transition"
                    offset-y full-width
                    min-width="290px"
                    >
                    <template v-slot:activator="{ props }">
                      <input
                        :value="showexfireDate" v-bind="props" style="cursor:pointer;" readonly
                        class="input_input"
                        />                        
                    </template>
                    <v-date-picker v-model="editedItem.EXFIRE_DATE" @update:modelValue="exfire_date = false" 
                      :first-day-of-week="0" color="primary" picker="date"
                      :format="YYYY-MM-DD"
                      />
                  </v-menu>                         
                </v-col>
              </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="6" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="3" v-if="this.$storage.getStorageSync('GRADE') >= 10">
        <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
      </v-col>
    </v-row>

  <v-row dense no-gutters>
    <label class="total_label">{{`전체:${totalcnt}` }}</label>
  </v-row>

  <v-row align="start" dense>
    <v-col > 
      <!-- 테이블 -->
      <v-data-table
        class="datatablestyle"
        style="height: 70vh;"
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        :items-per-page="pagerow"
        :search="search"  
        item-key="IDX"
        :loading="loading"
        :mobile="false"
        >
        <template v-slot:header.START_DATE>
          <a :class="sort_name=='START_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('START_DATE')">시작일</a>
        </template>
        <template v-slot:header.EXFIRE_DATE>
          <a :class="sort_name=='EXFIRE_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('EXFIRE_DATE')">종료일</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">구매일</a>
        </template>

        <template v-slot:item.SUB_NAME="{ item }">  
            <a style="cursor:pointer;color:blue" @click="load_subscript(item)">{{item.SUB_NAME}}</a>
        </template>     
        <template v-slot:item.PAY_AMOUNT="{ item }">  
          <a style="cursor:pointer;color:blue" @click="load_payment(item)">{{item.PAY_AMOUNT}}</a>
        </template>     
        <template v-slot:item.NAME="{ item }">  
          {{ item.PARENT_NAME }}
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
        </template>     
        <template v-slot:bottom></template>
      </v-data-table>
    </v-col>
  </v-row>

  
  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import moment from 'moment';
export default {

    components:{
      
    },

    data: () => ({

      start_date : false,
      exfire_date : false,
      

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      totalsize : 0,       //전체 용량
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          SUBACCNT_IDX:'',
          LOCALID:'',
          SUBACCNT:'',
          PW:'',
          STATE:1,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '계정', key: 'ACCNT',  sortable: false, align:'center' },
        {title: '요금제', key: 'SUB_NAME', width:200, sortable: false, align:'center'},
        {title: '가격', key: 'PAY_AMOUNT',  sortable: false, align:'center' },
        {title: '소속', key: 'PARENT_NAME',  sortable: false, align:'center' },
        {title: '소유자', key: 'NAME',  sortable: false, align:'center' },
        {title: '시작일', key: 'START_DATE',  sortable: false, align:'center' },
        {title: '종료일', key: 'EXFIRE_DATE',  sortable: false, align:'center' },
        {title: '구매일', key: 'REG_DATE',  sortable: false, align:'center' },
        {title: '수정', key: 'actions',  sortable: false, align:'center' }
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
      SEARCH_CATEGORY_IDX:null,   
      categoryList:[],

      //추가 변수들
      STATELIST: [
          {name:'활성',     value:1}
        , {name:'비활성',   value:0}
      ],
      SHARELIST: [
          {name:'공유',       value:1}
        , {name:'공유안함',   value:0}
      ],
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      
      grade:0,
      limit_content:1,

    }),

    setup(){
        var comsubscript = ref(null);
        var paydialog = ref(null);
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        let contentStore   = useContentStore();
        return {comsubscript, paydialog, paging, searchbar, editor, contentStore};
    },

    computed: {
      
      showstartDate(){
        if(this.editedItem.START_DATE != null && this.editedItem.START_DATE != undefined){
          return moment(new Date(this.editedItem.START_DATE)).format('YYYY-MM-DD');
        }
      },
      showexfireDate(){
        if(this.editedItem.EXFIRE_DATE != null && this.editedItem.EXFIRE_DATE != undefined){
          return moment(new Date(this.editedItem.EXFIRE_DATE)).format('YYYY-MM-DD');
        }
      },
    },    

    mounted(){

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      this.SEARCH_LOCALID         = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME      = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade                  = this.$storage.getStorageSync("GRADE");
      this.limit_content          = this.$storage.getStorageSync("LIMIT_CONTENT");

      //특정 계정의 결제내역을 확인
      const lid = this.$route.query.lid;
      if(lid != undefined && lid != null && lid.length > 0){
        this.SEARCH_LOCALID = lid;
      }

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      load_subscript(item){
        this.comsubscript.loadpage(item.SUB_IDX);
      },

      load_payment(item){
        this.paydialog.loadpage(item.PAY_IDX);
      },


      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/MyPaymentList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
              , SHOWTYPE: 1
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt  = result.data.totalcnt;
                this.totalsize = result.data.totalsize;

                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


      //추가 저장
      async save () {

        const temp_start = new Date(this.editedItem.START_DATE);
        const temp_end   = new Date(this.editedItem.EXFIRE_DATE);
        if(temp_start > temp_end){
          this.$alert.fire("시작일이 종료일보다 큽니다.");
          return;
        }


        if (this.editedIndex > -1) {

          //토큰값 체크
          let token;
          try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/MyPaymentUpdate',{
                START_DATE: moment(new Date(this.editedItem.START_DATE)).format('YYYY-MM-DD')
              , EXFIRE_DATE: moment(new Date(this.editedItem.EXFIRE_DATE)).format('YYYY-MM-DD')
              , PAY_IDX: this.editedItem.PAY_IDX
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              const p = this.page;
              this.page = 0;
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(p);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });

        }

        this.close();
      },            

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/MyContent", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.editedItem.START_DATE  = new Date(this.editedItem.START_DATE);
        this.editedItem.EXFIRE_DATE = new Date(this.editedItem.EXFIRE_DATE);
        this.editedItem.REG_DATE    = new Date(this.editedItem.REG_DATE);
        this.dialog = true;
      },


      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage(1);
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage(1);
      },





    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


.input_input{
  background: #F3F3F3;
  border-radius: 14px;
  height: 44px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color:#333333;
}
.input_input:focus{
  background: white;
  color:#333333;
  outline-color: #8f00b3;
}

</style>