<!-- 리소스는 사용 안해도 될 것 같아서 여기에 그냥 파베 테스트 했음. -->

<template>

  <v-container fluid>

  <v-row align="center" justify="start" dense>
    <v-col cols="12">
      <label class="menunamestyle me-auto">{{ curArrayName }}</label>
    </v-col>
  </v-row>

  <v-row align="center" justify="start" dense>
    <v-col cols="12" class="d-flex align-center">
      <v-btn class="me-auto" text="뒤로" density="comfortable" variant="text" @click="goback"></v-btn>
      <v-btn color="deep-purple-lighten-2 mr-1" text="추가" density="comfortable" @click="addDataParam"></v-btn>
      <v-btn color="deep-purple-lighten-2" text="저장" density="comfortable" @click="save"></v-btn>
    </v-col>
  </v-row>
  
  <v-row class="mt-3" justify="center">

          <draggable
            v-model="itemList" tag="tbody" class="w-100 ml-3 mr-3"
            ghost-class="ghost" 
            @change="handleDrag" :key="listRenderCount"
            >
            <template #item="{ element }">
              <v-card
                class="mb-3 mx-auto" max-width="500"
                >
                <v-card-text>
                  <div class="d-flex align-center flex-column w-100">
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">타이틀</label>
                      <input style="width:70%;" v-model="element.name" class="inputparam_bg" />
                    </div>
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">타입</label>
                      <select 
                        v-model="element.type"
                        style="width:70%; height:30px;border: 1px gray solid; border-radius:5px; margin-left:5px;text-align:center">
                        <template v-for="cptype in typelist" :key="cptype.name">
                            <option style="font-size:14px" :value="cptype.value">{{cptype.name}}</option>
                        </template>
                      </select>
                    </div>
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">마스킹</label>
                      <input style="width:70%;" v-model="element.mask" class="inputparam_bg" />
                    </div>
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">넓이</label>
                      <input style="width:70%;" v-model="element.width" class="inputparam_bg" />
                    </div>
                    <div class="d-flex align-center w-100">
                      <label style="width:30%;" class="text-center">높이</label>
                      <input style="width:70%;" v-model="element.height" class="inputparam_bg" />
                    </div>
                  </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="deep-purple-lighten-2" text="Delete" border @click="delete_item(element)"></v-btn>
              </v-card-actions>
            </v-card>
            </template>
          </draggable>

  </v-row>

  

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
// eslint-disable-next-line no-unused-vars
import { addDoc, getDoc, doc, setDoc, collection, updateDoc, getDocs, serverTimestamp } from "firebase/firestore";
export default {

    components:{
      draggable
    },  

    data: () => ({

      loading : false,    //로딩 표시
      listRenderCount:0,
      curArrayName:'',

      //테이블 정의
      theaders1: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' }
      ],

      contentList: [],
      itemList: [],
      datamap: [],

      typelist:[
          {name:"TEXT",  value:"text"}
        , {name:"IMAGE", value:"image"}
      ],

      sort_name: 'DEVICE_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',

      grade:0,
      limit_page:0,

    }),

    setup(){
      let contentStore   = useContentStore();
      return {contentStore};
    },

    async mounted(){
      
      if(this.$storage.getStorageSync("GRADE") < 1){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
        this.$router.push("/Login");
        return;
      }

      this.SEARCH_LOCALID         = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      
      if(this.$storage.getStorageSync("GRADE") < 1){
        this.$alert.fire("사용 권한이 없습니다.");
        this.$router.back();
        return;
      }
      this.limit_page = this.$storage.getStorageSync("LIMIT_PAGE");

      this.curArrayName = this.$route.query.name;
      this.datamap = this.contentStore.datamap;
      console.log("datamap : " + JSON.stringify(this.datamap));
 
      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }

    },

    create(){
      
    },

    methods: {
      
      goback(){

        this.$router.back();

      },

      loadpage(){
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화
        for (const element of this.contentStore.datamap.dataformat[this.curArrayName]) {
          this.itemList.push(element); 
        }
      },

      //우측 순서변경
      handleDrag(){
        //원본 데이터에 순서가 변경된 것을 저장한다.
        this.datamap.dataformat[this.curArrayName] = [...this.itemList];
      },

      addDataParam(){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.curArrayName == null || this.curArrayName == undefined || this.curArrayName.length == 0){
          return;
        }
        this.itemList.push({'name':'','type':'text', 'mask':'', 'width':"0", 'height':"0"});
        this.datamap.dataformat[this.curArrayName] = [...this.itemList];

      },


      //아이템 삭제
      delete_item(item){
          
        this.$alert.fire({title:"선택하신 필드를 정말 삭제하시겠습니까?", showCancelButton:true} ).then(async (result) => {
          if(result.isConfirmed){

            const idx = this.itemList.indexOf(item);
            if (idx !== -1) {
              this.itemList.splice(idx, 1);
            }
            this.datamap.dataformat[this.curArrayName] = [...this.itemList];

          }
        });        
        
      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      


      //추가 저장
      async save () {

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.datamap == null || this.datamap == undefined || this.datamap.length == 0){
          //신규 데이터맵 생성
          this.$alert.fire('저장데이터가 없습니다.');
          return;
        }
        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/MyResSave',{
              DATAMAP: JSON.stringify(this.datamap)
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            this.$alert.fire("수정이 완료되었습니다.").then(()=>{
              //this.loadpage();
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('수정이 실패했습니다.');
        });

      },      


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.menu_design{
  border: 1px solid gray;
  border-radius: 5px;
}

.inputparam_bg{
  background: #e4e4e4;
  border-radius: 5px;
  color: #000000;
  padding: 5px 10px 5px 10px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

</style>