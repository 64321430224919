<template>
  <v-container fluid style="background:#ffffff;min-height:100%">
    <v-row justify="center" class="mt-1">
      <v-col cols="12" md="8">

      <v-row justify="center" class="mt-1" >
        <v-col cols="12" md="8" class="d-flex flex-column justify-center align-center">
          <div id="title2" :class="$vuetify.display.mobile ? 'h2_text_mb' : 'h2_text'">아이디어가 바로 현실이됩니다.</div>
          <div id="title3" :class="$vuetify.display.mobile ? 'h3_text_mb' : 'h3_text'">BARO는 키오스크와 디지털사이니지 컨텐츠 제작에 전문화된 솔루션입니다.</div>
          <div id="title3" :class="$vuetify.display.mobile ? 'h3_text_mb' : 'h3_text'">이런것도 가능할까요? 고민하지 마시고 문의하세요.</div>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-10" >
        <v-col cols="12" md="4">
          <label>성</label>
          <v-text-field variant="outlined" density="compact" hide-details v-model="firstname"/>
        </v-col>
        <v-col cols="12" md="4">
          <label>이름</label>
          <v-text-field variant="outlined" density="compact" hide-details v-model="lastname" />
        </v-col>
        <v-col cols="12" md="8">
          <label>Email</label>
          <v-text-field variant="outlined" density="compact" hide-details v-model="email" />
        </v-col>
        <v-col cols="12" md="8">
          <label>전화번호</label>
          <v-text-field variant="outlined" density="compact" hide-details v-model="tel" />
        </v-col>
        <v-col cols="12" md="8">
          <label>회사명</label>
          <v-text-field variant="outlined" density="compact" hide-details v-model="company" />
        </v-col>
        <v-col cols="12" md="8">
          <label>홈페이지</label>
          <v-text-field variant="outlined" density="compact" hide-details v-model="homepage" />
        </v-col>
        <v-col cols="12" md="8">
          <label>문의내용</label>
          <v-textarea variant="outlined" density="compact" hide-details v-model="comment" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          주식회사 넥스퀘어는 귀하의 개인 정보를 제품 문의에 대한 안내하는 목적으로만 사용할 것을 알립니다. 아래 제출하기 버튼을 클릭하면 당사의 개인정보 수집 및 처리방침에 동의하는 것으로 간주됩니다.
        </v-col>
        <v-col cols="12" md="8">
          <v-checkbox
            label="개인정보 수집 및 처리방침에 동의합니다."
            hide-details v-model="check"
            />
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-1" >
        <v-col cols="12" md="6" class="d-flex flex-column justify-center align-center">
          <v-btn class="mt-10 mb-5 pricebtn" color="#7930ff" @click="send">제출하기</v-btn>
        </v-col>
      </v-row>

      
      <v-row justify="center" class="mt-15">
        <div style="height:100px"/>
      </v-row>
      
    
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
  import gsap from "gsap";
  import ScrollTrigger from "gsap/ScrollTrigger";
    export default {

    data: () => ({
      firstname:"",
      lastname:"",
      email:"",
      tel:"",
      company:"",
      homepage:"",
      comment:"",
      check:false
    }),

    mounted(){
      
      gsap.registerPlugin(ScrollTrigger);

      const from = {x:0, y:-50, scale:1, rotate:0, alpha:0};
      const to   = {delay:0.3, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title1_1", from, to);

      const from2 = {x:0, y:-50, scale:1, rotate:0, alpha:0};
      const to2   = {delay:0.6, duration:1, x:0, y:0, scale:1, rotate:0, alpha:1, ease:'elastic.out' };
      gsap.fromTo("#title1_2", from2, to2);

    },

    methods:{

      send(){
        
        if(this.firstname.length == 0){
          this.$alert.fire("성을 입력하세요");
          return;
        }
        if(this.lastname.length == 0){
          this.$alert.fire("이름을 입력하세요");
          return;
        }
        if(this.email.length == 0){
          this.$alert.fire("이메일을 입력하세요");
          return;
        }
        if(this.tel.length == 0){
          this.$alert.fire("연락처를 입력하세요");
          return;
        }
        if(this.company.length == 0){
          this.$alert.fire("회사명을 입력하세요");
          return;
        }
        if(this.comment.length == 0){
          this.$alert.fire("문의 내용을 입력하세요");
          return;
        }


        this.$http.post(this.$host+'/GuestSendContact',{
            FNAME: escape(this.firstname)
          , LNAME: escape(this.lastname)
          , EMAIL: this.email
          , TEL: this.tel
          , HOMEPAGE: this.homepage
          , COMPANY: escape(this.company)
          , COMMENT: escape(this.comment)
        })
        .then((result)=>{
            //console.log(JSON.stringify(result));
            if(result.data.resultCode == 0){
                //신규추가 성공
                this.$alert.fire("문의하기가 완료되었습니다.");
            }else{
              this.$alert.fire(result.data.resultMsg);
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          //console.log(error);
          this.$alert.fire('오류로 인해 문의하기에 실패했습니다.');
        });        

      }

    }


  }
</script>

<style scoped>

.priceround{
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  max-width: 350px;
}

.pricetitle{
  border-radius: 8px;
  background-color: #7930ff;
  color: #ffffff;
  width: 80%;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: center;
}

.pricevalue{
  font-size: 30px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.pricedesc{
  font-size: 20px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: center;
}

.priceline{
  height: 1px;
  width: 90%;
  background-color: #7930ff;
}

.pricetext{
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  text-align: left;
}

.pricebtn{
  width:80%;
  height: 40px;
  color:white;
  font-weight: bold;
  font-family: 'Noto Sans KR';
  font-size: 15px;
}


.h1_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 80px;
  line-height: 116px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h1_text_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h2_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 116px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h2_text_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 42px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h3_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.h4_text{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #404040 !important;
  white-space: nowrap;
}
</style>
