<!-- 백업 확대/축소, 더블클릭 모드 스위치 성공, 셀렉터 한개만 쓴느 버전 테스트 때문에 복제해둠. -->
<template>
  <v-container fluid class="pa-0 ma-0">
  <!-- 
  <div style="width:100%;height:50px">
    <v-btn @click="screenadd_test">스크린추가</v-btn>
    <v-btn @click="randadd">랜덤추가</v-btn>
    <v-btn @click="cleanall">초기화</v-btn>
    <v-btn @click="addcomponent">추가</v-btn>
    <v-btn @click="testcopy">클론</v-btn>
    <v-btn @click="creategroup">그룹</v-btn>
    <v-btn @click="removegroup">그룹해제</v-btn>
    <v-btn @click="zoomin">확대</v-btn>
    <v-btn @click="zoomout">축소</v-btn>
  </div>
  -->

    <v-menu v-model="menuOpen" :close-on-content-click="false"
      :style="menuStyle"
      >
      <!-- 내용 생략 -->
      <v-list>
        <!-- 팝업 메뉴 아이템들 -->
        <v-list-item @click="menu_addcomponent">컴포넌트 추가</v-list-item>
        <v-list-item @click="menu_deleteselect">선택삭제</v-list-item>
        <v-list-item @click="menu_copy">복사</v-list-item>
        <v-list-item @click="menu_paste">붙여넣기</v-list-item>
        <v-list-item @click="creategroup">그룹</v-list-item>
        <v-list-item @click="removegroup">그룹해제</v-list-item>
      </v-list>      
    </v-menu>


    <div id="editarea" ref="editarea" class="editerSize infinite-viewer" 
      >
      <div 
        id="editdiv" ref="editdiv" 
        class="editerDivSize viewport selecto-area" style="background:gray;"
        @drop="drop_handler" @dragover.prevent
        @contextmenu.prevent="showContextMenu"
        />
    </div>

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import Moveable from 'moveable';
import { getElementInfo } from 'moveable';
import Selecto from "selecto";
import InfiniteViewer from "infinite-viewer";

import Component_Model from '@/nextts/model/Component_Model'
import Screen_Model from '@/nextts/model/Screen_Model'

// eslint-disable-next-line no-unused-vars
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'
// eslint-disable-next-line no-unused-vars
import EditBasic_Screen from '@/nextts/edit/EditBasic_Screen'
import EditBasic_ScreenFixed from '@/nextts/edit/EditBasic_ScreenFixed'

// eslint-disable-next-line no-unused-vars
import { reactive, ref } from '@vue/reactivity';
import FontFaceObserver from 'fontfaceobserver';
import Gradient_Model from '@/nextts/model/Gradient_Model';
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model';
// eslint-disable-next-line no-unused-vars
import Play_Background from '@/nextts/play/Play_Background';
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import domtoimage2 from 'dom-to-image-more';
// eslint-disable-next-line no-unused-vars
import download from 'downloadjs';

export default {

  setup(){
    const editarea        = ref();
    const editdiv         = ref();
    const contentStore    = useContentStore();
    return {editarea, editdiv, contentStore};
  },

  data:()=>({

    windowWidth:window.innerWidth-503,    //좌우 패널 넓이 뺌
    windowHeight:window.innerHeight-50,   //상단 메뉴바 높이를 뺌

    //스크린 내부 컴포넌트 이동/선택
    cm_moveable:null,
    cm_selecto:null,
    cm_targets:[],       //selecto 라이브러리를 통해 선택된 컴포넌트들
    guideList:[],        //snap을 처리할 가이드 리스트

    infiniteViewer:null,

    frameskip: 0,
    zoom : 1.0,

    undolist:[],
    redolist:[],
    copylist:[],    //copy한 객체의 클론 객체, paste시 다시 클론시킴. 여러번 붙여넣기가 가능하기 때문.
    pastelist:[],   //방금 붙여넣기한 객체를 추적하기 위한 값
    
    isSpacebar:false,
    isShift:false,
    isAlt:false,

    //메뉴
    menuOpen: false,
    menuPosition: { x: 0, y: 0 },
    menuPosition_view: { x: 0, y: 0 },
  }),


  beforeUnmount(){
    //window.removeEventListener('resize', this.resizeevent);
    console.log("beforeUnmount");
    removeEventListener('resize', this.resizeevent);
    removeEventListener('keydown', this.onkeydown);
    removeEventListener('keyup', this.onkeyup);
    removeEventListener('wheel', this.onwheelevent);

    this.$emitter.off('drawscreen');
    this.$emitter.off('screenMoveMode');
    this.$emitter.off('screenEditMode');
    this.$emitter.off('selectComponent');
    this.$emitter.off('clearSelect');
    this.$emitter.off('reselectmoveable');
    
  },
  
  


  mounted(){

    //resize 이벤트 설정 (리사이즈시 에디트 윈도우 사이즈 조절)
    //onresize  = this.resizeevent;   //화면 리사이즈 이벤트 처리 (현재 동작 없음)
    //onkeydown = this.onkeydown;     //키다운 이벤트, 언두,리두,스페이스바 이동 등등
    //onkeyup   = this.onkeyup;       //
    //onwheel   = this.onwheelevent;  //마우스 휠로 확대 축소
    addEventListener('resize', this.resizeevent);
    addEventListener('keydown', this.onkeydown);
    addEventListener('keyup', this.onkeyup);
    addEventListener('wheel', this.onwheelevent);
    

    //이거 써서 이벤트 전달을 중단시키면 드래그 앤 드랍에 영향받음. 조심해야함.
    //onmousemove = this.onmousemove;
    
    //전역 이벤트버스, 스크린 그리기
    this.$emitter.on('drawscreen', this.drawscreen);
    this.$emitter.on('screenMoveMode', this.screenMoveMode);
    this.$emitter.on('screenEditMode', this.screenEditMode);
    this.$emitter.on('selectComponent', this.setSelectComponent);
    this.$emitter.on('clearSelect', this.clearSelect);
    this.$emitter.on('reselectmoveable', this.reselectmoveable);
    
    
    //인피니티 뷰어 설정
    const viewer    = document.querySelector(".infinite-viewer");
    this.infiniteViewer = new InfiniteViewer(
        viewer,
        document.querySelector(".viewport"),
        {
          useMouseDrag: false,
          useWheelScroll: true,
          useAutoZoom: true,
          zoomRange: [0.1, 10],
          maxPinchWheel: 10,          
        }
    );

    this.infiniteViewer.on("scroll", () => {
        //console.log(this.infiniteViewer.getScrollLeft(), this.infiniteViewer.getScrollTop());
    });

    this.setMoveSelecForComponent();

  },


  computed: {
    //우측메뉴 위치
    menuStyle() {
      return {
        top: `${this.menuPosition.y}px`,
        left: `${this.menuPosition.x}px`
      };
    }
  },  

  methods:{


    creategroup(){
      //선택된 컴포넌트에 그룹아이디를 매긴다.
      var elements = this.cm_selecto.getSelectedTargets();
      console.log("select elements len : " + elements.length);

      if(elements.length > 1){
        var curdate = new Date();
        var gid = "gid_" + curdate.getMilliseconds();

        elements.forEach(element=>{
          //그룹 표기
          element.model.isgroup = true;
          element.model.groupid = gid;
          element.classList.add(gid);  //셀렉트를 위한 추가
        });
        var target = document.getElementsByClassName(gid);
        this.cm_moveable.target = target;
      }

    },

    removegroup(){
      //그룹해제
      var elements = this.cm_selecto.getSelectedTargets();
      console.log("remove group len : " + elements.length);
      elements.forEach(element=>{
        if(element.model.isgroup){
          var group_element = document.getElementsByClassName(element.model.groupid);
          for(var i=0;i<group_element.length;i++){
            console.log("remove group...");
            var ge = group_element[i];
            ge.classList.remove(ge.model.groupid);
            ge.model.isgroup = false;
            ge.model.groupid = "";
          }
        }
      });
      this.cm_moveable.target = elements[0];
      
    },

    menu_addcomponent(){

        if(this.contentStore.select_screen == null){
          return;
        }

        //컴포넌트 모델 생성 (크기, 위치 정보)
        const posx = this.menuPosition_view.x-100;
        const posy = this.menuPosition_view.y-50;
        const tempcm  = new Component_Model();
        tempcm.width  = 200;
        tempcm.height = 100;
        tempcm.posx   = posx;
        tempcm.posy   = posy;
        tempcm.rotate = 0;
        tempcm.transx = posx;
        tempcm.transy = posy;
        tempcm.backgroundColor = "#00000000";
        tempcm.component_type = Component_Model.COMPONENT_TEXT;
        tempcm.text   = "내용을 입력하세요";
        this.contentStore.select_screen.component_list.push(tempcm);
        this.drawscreen();
        this.saveUndo();
        this.menuOpen = false;

    },

    //선택된 컴포넌트 삭제
    menu_deleteselect(){

      if(this.contentStore.select_screen == null){
        return;
      }

      if(this.cm_targets == null || this.cm_targets.length < 1){
        console.log("not select...");
        return;
      }
      
      //console.log("menu_deleteselect : " + JSON.stringify(this.cm_targets));
      this.cm_targets.forEach(target=>{
        if(this.contentStore.select_screen.component_list.includes(target.model)){
          const idx = this.contentStore.select_screen.component_list.indexOf(target.model);
          this.contentStore.select_screen.component_list.splice(idx, 1);
        }
      });
      this.cm_targets = null;
      this.contentStore.select_component = null;
      this.$emitter.emit("updateinfopanel");
      this.drawscreen();
      this.saveUndo();
      this.menuOpen = false;
    },

    menu_copy(){
      this.saveCopy();
      this.menuOpen = false;      
    },

    menu_paste(){

      this.runPaste(true, 0);

      this.menuOpen = false;      
    },

    showContextMenu(event) {
      console.log("showContextMenu");
      event.preventDefault();

      //팝업 메뉴의 위치를 보여주기 위한 전역 좌표
      this.menuPosition = { x: event.clientX, y: event.clientY };

      //편집창 기준의 좌표
      //this.menuPosition_view = { x: event.offsetX, y: event.offsetY };
      this.menuPosition_view = { x: event.offsetX, y: event.offsetY };

      this.menuOpen = true;
    },

    async drop_handler(event) {

      console.log("event : " + event.clientX + " / " + event.clientY);
      event.preventDefault();

      const fontFaces = [];
      const files = event.dataTransfer.files;
      
      //내컴퓨터에서 파일을 드래그 앤 드랍 한 경우
      if(files.length > 0){
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const name = file.name; // 파일명
          const size = file.size; // 파일 크기 (바이트 단위)
          
          // 파일 정보를 출력하거나 업로드하는 등의 작업 수행
          console.log(`파일명: ${name}`);
          console.log(`파일 크기: ${size} bytes`);

          //이미지면 미리보기
          if (file.type.startsWith('image/')) {

            try{

              const fileinfo = await this.getImageSize(file);

              let mouseX = event.clientX;
              let mouseY = event.clientY;
              const tempcm    = new Component_Model();
              tempcm.width  = fileinfo.width;
              tempcm.height = fileinfo.height;
              tempcm.posx   = mouseX;
              tempcm.posy   = mouseY;
              tempcm.rotate = 0;
              tempcm.transx = mouseX;
              tempcm.transy = mouseY;
              
              tempcm.bgtype = 0;
              tempcm.backgroundColor = `#00000000`;
              tempcm.component_type = Component_Model.COMPONENT_IMAGE;
              tempcm.image = fileinfo.data;
              this.contentStore.select_screen.component_list.push(tempcm);

            }catch(error){
              console.log("error : " + error);
            }
            
          }
          
        }
      }else{
        
        //left info의 assets에서 드래그 앤 드랍 한 경우
        const types = Array.from(event.dataTransfer.types);



        //type값이 전부 소문자로 변형되어 들어옴.
        if(types.includes('assetsdata'.toLowerCase())){
          const drop_item = event.dataTransfer.getData('assetsData');
          if(drop_item != null){

            //드랍한 마우스 좌표
            let mouseX = event.clientX;
            let mouseY = event.clientY;

            //에디트 스크린
            const editdiv = document.getElementById("editdiv").firstChild;
            const divRect = editdiv.getBoundingClientRect();

            //에디트 스크린 기준으로 좌표값을 변경 (뷰어의 줌값도 반영해야한다.)
            console.log("divRect : " + JSON.stringify(divRect));
            const relativeX = (mouseX - divRect.left) / this.zoom;
            const relativeY = (mouseY - divRect.top) / this.zoom;

            const temp = JSON.parse(drop_item).ASSET_JSON;
            const sm = new Screen_Model();
            sm.setStringValue(temp.screen_list[0]);
            //에셋 데이터 그룹핑 처리
            var curdate = new Date();
            var gid = "gid_" + curdate.getMilliseconds();

            //해당 assets의 최소 x, y값 구하기.
            const minX = Math.min(...sm.component_list.map(cm => cm.posx));
            const minY = Math.min(...sm.component_list.map(cm => cm.posy));
            console.log("min : " + minX + " / " + minY);
            console.log("rel : " + relativeX + " / " + relativeY);

            sm.component_list.forEach((component)=>{
              component.isgroup = true;
              component.groupid = gid;
              
              //폰트 처리
              if(component.font_url != null && component.font_url != undefined && component.font_url.length > 0){
                let fonturl = "url('"+component.font_url+"')";
                if(component.font_format != null && component.font_format != undefined && component.font_format.length > 0){
                  fonturl += " format('"+component.font_format+"')";
                }
                const fontoption = {weight:component.font_weight.length == 0 ? "normal" : component.font_weight , style:"normal"};
                const fontface = new FontFace(component.textfont, fonturl, fontoption);
                document.fonts.add(fontface);
                fontFaces.push(fontface);
              }

              //마우스 포인터 위치에 생성 : 좌표가 꼭 100이 차이난다. 왜그럴까?
              component.posx   = component.posx -  minX  + relativeX;
              component.posy   = component.posy -  minY + relativeY;
              component.transx = component.transx - minX + relativeX;
              component.transy = component.transy - minY + relativeY;
              this.contentStore.select_screen.component_list.push(component);

            });

          }
        }else if(types.includes("imgdata")){
          const drop_item = event.dataTransfer.getData('imgData');
          if(drop_item != null){

            //드랍한 마우스 좌표
            let mouseX = event.clientX;
            let mouseY = event.clientY;

            //에디트 스크린
            const editdiv = document.getElementById("editdiv").firstChild;
            const divRect = editdiv.getBoundingClientRect();

            //에디트 스크린 기준으로 좌표값을 변경 (뷰어의 줌값도 반영해야한다.)
            const relativeX = (mouseX - divRect.left) / this.zoom;
            const relativeY = (mouseY - divRect.top) / this.zoom;
            const temp = JSON.parse(drop_item);
            console.log("drop img : " + JSON.stringify(drop_item));
            
            const img = await this.loadImage(temp.IMG_PATH);
            const component  = new Component_Model();
            component.component_type = Component_Model.COMPONENT_IMAGE;
            component.image  = temp.IMG_PATH;
            component.width  = img.width;
            component.height = img.height;
            component.posx   = relativeX;
            component.posy   = relativeY;
            component.transx = relativeX;
            component.transy = relativeY;
            component.rotate = 0;
            this.contentStore.select_screen.component_list.push(component);
          }          

        }else if(types.includes("videodata")){
          //비디오는 드래그 앤 드랍이 안된다.
          const drop_item = event.dataTransfer.getData('videoData');
          if(drop_item != null){
            //드랍한 마우스 좌표
            let mouseX = event.clientX;
            let mouseY = event.clientY;
            //에디트 스크린
            const editdiv = document.getElementById("editdiv").firstChild;
            const divRect = editdiv.getBoundingClientRect();
            //에디트 스크린 기준으로 좌표값을 변경 (뷰어의 줌값도 반영해야한다.)
            const relativeX = (mouseX - divRect.left) / this.zoom;
            const relativeY = (mouseY - divRect.top) / this.zoom;
            const temp = JSON.parse(drop_item);
            const video = await this.loadImage(temp.MV_PATH);
            const component  = new Component_Model();
            component.component_type = Component_Model.COMPONENT_MOVIE;
            component.movie  = temp.MV_PATH;
            component.movie_time  = video.duration;
            component.width  = video.videoWidth;
            component.height = video.videoHeight;
            component.posx   = relativeX;
            component.posy   = relativeY;
            component.transx = relativeX;
            component.transy = relativeY;
            component.rotate = 0;
            
            this.contentStore.select_screen.component_list.push(component);
          }          

        }else if(types.includes("componentdata")){

          console.log("componentData...");

          const drop_item = event.dataTransfer.getData('componentData');

          if(drop_item != null){

            //드랍한 마우스 좌표
            let mouseX = event.clientX;
            let mouseY = event.clientY;

            //에디트 스크린
            const editdiv = document.getElementById("editdiv").firstChild;
            const divRect = editdiv.getBoundingClientRect();

            //에디트 스크린 기준으로 좌표값을 변경 (뷰어의 줌값도 반영해야한다.)
            const relativeX = (mouseX - divRect.left) / this.zoom;
            const relativeY = (mouseY - divRect.top) / this.zoom;
            const temp = JSON.parse(drop_item);
            
            console.log("drop component : " + JSON.stringify(drop_item));
            
            //const img = await this.loadImage(temp.IMG_PATH);
            const component  = new Component_Model();
            component.setStringValue(temp);
            /*
            component.component_type = temp.component_type;
            component.image  = temp.IMG_PATH;
            component.width  = temp.width;
            component.height = temp.height;
            */

            component.posx   = relativeX;
            component.posy   = relativeY;
            component.transx = relativeX;
            component.transy = relativeY;
            component.rotate = 0;
            this.contentStore.select_screen.component_list.push(component);
          }      
        }

      }
      
      if(fontFaces.length > 0){
        Promise.all(fontFaces.map(this.observeFontLoad))
        .then(() => {
            console.log('모든 폰트가 성공적으로 로드되었습니다.');
            this.drawscreen();
        })
        .catch(error => {
            console.error('폰트 로드 중 오류가 발생했습니다:', error);
            this.drawscreen();
        });

      }else{
        //로드할께 없으면
        this.drawscreen();
      }
      


    },    


    loadImage(imgurl){
      return new Promise((resolve, reject)=>{
        var tempimg = new Image();
        tempimg.onload = () =>resolve(tempimg);
        tempimg.onerror = reject;
        tempimg.src = imgurl;
      })
    },    

    loadVideo(videourl){
      return new Promise((resolve, reject)=>{
        const video = document.createElement('video');
        video.onloadedmetadata = function() {
          // 로드가 완료되면 비디오 객체를 resolve
          resolve(video);
        };        
        video.onerror = function() {
          // 로드 실패 시 reject 호출
          reject(new Error('비디오 로드 실패'));
        };        
        video.src = videourl;
      })
    },    

    //폰트로드 감지
    observeFontLoad(fontFace) {
      const observer = new FontFaceObserver(fontFace.family);
      return observer.load();
    },


    //드롭한 파일의 사이즈와 data:image가져오기
    getImageSize(file){
      return new Promise((resolve, reject)=>{

        const reader = new FileReader();
        reader.onload = function(event) {
          const img = new Image();
          img.onload = function() {
            resolve({
              data: event.target.result,
              width: img.width,
              height: img.height
            });
          };
          img.onerror = function() {
            reject(new Error('이미지를 로드하는 중에 오류가 발생했습니다.'));
          };
          img.src = event.target.result;
        };
        reader.onerror = function() {
          reject(new Error('이미지를 읽는 중에 오류가 발생했습니다.'));
        };
        reader.readAsDataURL(file);        
      })
    },      



/********************************************************************************************************/
// 이벤트
/********************************************************************************************************/

    //컨트롤 누른상태로 마우스 휠 조작시 줌인/줌아웃
    onwheelevent(e){
      if(e.ctrlKey) {

        //스크롤을 내리면 e.deltaY가 양수
        if(e.deltaY > 0){
          //확대
          //this.zoomin();
          //this.zoom = this.zoom+0.1 <= 10 ? this.zoom+0.1 : 10;
          this.zoom *= 0.999 ** e.deltaY;
          this.infiniteViewer.setZoom(this.zoom);


        }else{
          //축소
          //this.zoomout();
          //this.zoom = this.zoom-0.1 > 0.2 ? this.zoom-0.1 : 0.2;
          this.zoom *= 0.999 ** e.deltaY;
          this.infiniteViewer.setZoom(this.zoom);


        }

        //컴포넌트 재렌더링 - svg text가 갱신되지 않는 문제 해결.
        if(this.contentStore.select_screen.screen){
          this.contentStore.select_screen.screen.updateComponentRender();
        }

      }
    },

    resizeevent(){
      
      this.windowHeight = window.innerHeight-50;
      //console.log("resize : " + window.innerHeight);
      //this.windowWidth  = window.innerWidth-503;
    },

    onkeyup(ev){
      if(ev.keyCode == 32){
        this.screenEditMode();
      }else if(ev.keyCode == 16){
        this.isShift = false;
      }else if(ev.keyCode == 18){
        //알트
        //this.isAlt = false;
        //this.editarea.style.cursor = "default";
      }
    },

    onkeydown(ev){
      
      //console.log("editWindow key down : " + ev.key + " / "+ev.keyCode);

      if(ev.target instanceof HTMLBodyElement){
        //편집창 내에서 키입력

        if(ev.ctrlKey){
            if(ev.keyCode == 90){         //Ctrl+Z undo
              console.log('undo...');
              this.runundo();

            }else if(ev.keyCode == 89){   //Ctrl+Y redo
              console.log('redo...');
              this.runredo();

            }else if(ev.keyCode == 67){   //Ctrl+c copy
              console.log('copy...');
              this.saveCopy();

            }else if(ev.keyCode == 86){   //Ctrl+V Paste
              console.log('paste...');
              this.runPaste(false, 100);

            }
        }else if(ev.altKey){
          //this.isAlt = true;
          //this.editarea.style.cursor = "copy";
          //ev.preventDefault();
          
        }else if(ev.keyCode == 32){
          //spacebar 누르고 있으면 일단 스크린 이동 모드로 전환시켜라.
          if(!this.isSpacebar){
            this.screenMoveMode();
          }
          ev.preventDefault();
        }else if(ev.keyCode == 16){
          this.isShift = true;
          ev.preventDefault();
        }else if(ev.keyCode == 37){
          //left
          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            ev.preventDefault();
          }else{
            this.cm_moveable.request("draggable", { deltaX: -1, deltaY: 0 }, true);
            ev.preventDefault();
          }
        }else if(ev.keyCode == 38){
          //up
          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            ev.preventDefault();
          }else{
            this.cm_moveable.request("draggable", { deltaX: 0, deltaY: -1 }, true);
            ev.preventDefault();
          }
        }else if(ev.keyCode == 39){
          //right
          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            ev.preventDefault();
          }else{
            this.cm_moveable.request("draggable", { deltaX: 1, deltaY: 0 }, true);
            ev.preventDefault();
          }
        }else if(ev.keyCode == 40){
          //down
          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            ev.preventDefault();
          }else{
            this.cm_moveable.request("draggable", { deltaX: 0, deltaY: 1 }, true);
            ev.preventDefault();
          }
        }else if(ev.keyCode == 46){
          //delete 키, 삭제
          if(this.contentStore.select_screen == null){
            return;
          }
          if(this.cm_targets == null || this.cm_targets.length < 1){
            return;
          }
          this.cm_targets.forEach(target=>{
            if(this.contentStore.select_screen.component_list.includes(target.model)){
              const idx = this.contentStore.select_screen.component_list.indexOf(target.model);
              this.contentStore.select_screen.component_list.splice(idx, 1);
            }
          });
          this.cm_targets = null;
          this.contentStore.select_component = null;
          this.$emitter.emit("updateinfopanel");
          this.drawscreen();
          this.saveUndo();
          
          
        }

      }else{
        //패널 키입력
        
      }

    },    


    //특정 컴포넌트를 무브어블에 선택시킨다.
    setSelectComponent(cp){
      if(this.cm_moveable == null){
        this.cm_targets.splice(0, this.cm_targets.length);
        this.cm_targets = [cp];
      }else{
        this.cm_moveable.target = cp;
      }
      
    },


    //셀렉토, 무브어블 제거
    screenMoveMode(){
      const viewer    = document.querySelector(".infinite-viewer");
      viewer.style.cursor = "move";

      //컴포넌트 모드인가
      this.cm_targets = this.cm_moveable.target;
      this.isSpacebar = true;

      //컴포넌트용 moveable, selecto 제거
      this.infiniteViewer.useMouseDrag = true;  //인피니티 뷰어의 드래그 이동을 허용하여 스크린을 이동시킴
      if(this.cm_moveable != null) this.cm_moveable.destroy();
      if(this.cm_selecto != null){
        console.log("selecto destroy...");
        //this.cm_selecto.setSelectedTargets([]);
        //this.cm_selecto.off();
        this.cm_selecto.destroy();
      } 
      this.cm_moveable = null;
      this.cm_selecto  = null;
    },

    //셀렉토, 무브어블 복구
    screenEditMode(){
      console.log("screenEditMode...");
      const viewer    = document.querySelector(".infinite-viewer");
      viewer.style.cursor = "default";

      this.isSpacebar = false;
      this.infiniteViewer.useMouseDrag = false;
      
      //셀렉토랑 무브어블 복원
      this.setMoveSelecForComponent();

      if(this.cm_targets != null && this.cm_targets.length > 0){
        //기존에 컴포넌트를 선택했던 타겟이 0보다 크면 다시 컴포넌트 모드로 돌아가야한다.
        this.cm_moveable.target = this.cm_targets;
        this.$emit('selectComponent', this.cm_moveable.target);
      }
    },

    //선택 초기화
    clearSelect(){
      
      this.cm_targets.splice(0, this.cm_targets.length);
      this.cm_moveable.target = this.cm_targets;
      
    },

    //무브어블 재선택
    reselectmoveable(){
      console.log("reselectmoveable");
      this.screenMoveMode();
      this.screenEditMode();
    },


/********************************************************************************************************/
// 편집
/********************************************************************************************************/

    //스크린과 컴포넌트 편집 모드를 스위치
    switchEditMode(){
      console.log("switchEditMode...");
    },

    
    play_screen(){

      //현재 선택된 스크린 전체 재생
      const select_screen = document.getElementById("select_screen");
      if(select_screen != null && select_screen != undefined){
        select_screen.play_screen();
      }
      
    },

    setMoveSelecForComponent(){

      console.log("setMoveSelecForComponent...");

      //selecto - 드래그 앤 드랍으로 컴포넌트 선택해주는 라이브러리
      const viewer    = document.querySelector(".infinite-viewer");

      if(this.cm_selecto == null){
        console.log("new selecto...");
        this.cm_selecto = new Selecto({
            //viewer,
            dragContainer: viewer,            //셀렉트 드래그가 가능한 영역
            selectableTargets: [".drag"],     //선택할 수 있는 타겟
            hitRate: 0,
            selectByClick: true,              //클릭으로 선택
            selectFromInside: false,
            toggleContinueSelect: ["shift"],  //쉬프트로 추가 선택/해제
            ratio: 0,
            getElementRect: getElementInfo
        });

        this.cm_selecto.on("dragStart", e => {

          console.log("cm_selecto dragStart...");

          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            e.inputEvent.preventDefault();
            return;
          }

          //알트를 누른 상태에서 클릭하는 경우. - 자꾸 타입스크립트 에러가 나네..
          /*
          if(this.isAlt && this.cm_targets.length > 0){
            console.log("is alt selecto");
            //복사해줘
            this.saveCopy();
            //바로 붙여넣어버려
            this.runPaste(false, 0);
            this.cm_moveable.dragStart(e.inputEvent);
            //붙여넣은것을 선택해서 이어서 드래그 자연스럽게 연동
            e.inputEvent.preventDefault();
            setTimeout(() => {
              this.cm_moveable.dragStart(e.inputEvent);
            });
          }
          */


          const target = e.inputEvent.target;
          if(this.cm_moveable.isMoveableElement(target) || this.cm_targets.some(t => t === target || t.contains(target))
          ){
            e.stop();
          }

        }).on("select", e => {

          //드래그로 선택된 경우, 클릭한 경우

          //space바를 누르고 있다면 무시
          if(this.cm_moveable == null || this.isSpacebar){
            e.inputEvent.preventDefault();
            return;
          }

          var selectlist = new Set();
          var elements   = this.cm_selecto.getSelectedTargets();
          elements.forEach(target=>{
              //선택한 컴포넌트가 그룹객체면 그룹 전체 선택
              if(target.model.isgroup){
                var gtarget = [].slice.call(document.querySelectorAll("."+target.model.groupid));
                gtarget.forEach(gt=>{
                  selectlist.add(gt);
                });
              }else{
                selectlist.add(target);
              }
          });        
          const sl = Array.from(selectlist);
          if(sl.length == 1){
            console.log("select component...1");
            //단일건을 이렇게 안넣으면 버그가 발생함. 한템포 뒤에 모델이 셋팅됨.
            this.cm_targets         = elements;
            this.cm_moveable.target = elements;
            this.contentStore.select_component = elements[0];

            //this.$emit('selectComponent', elements);
          }else if(sl.length > 1){
            console.log("select component...2");
            this.cm_moveable.target = sl;
            this.cm_targets         = sl;
            
            //this.$emit('selectComponent', sl);
          }else{
            console.log("select component...3");
            //아무것도 없는 상태 - 스크린 배경을 선택시켜라.
            //console.log("select screen bg...");
            //this.cm_targets = [];
            //this.cm_moveable.target = null;
            //this.$emit('selectComponent', elements);

          }


          //알트를 누른 상태에서 클릭하는 경우.
          /* 최초에 클릭과 동시에 스무스하게 복제 이동되는 버전인데 선택후 복제로 하자.
          if(this.isAlt && this.cm_targets.length > 0){
            console.log("is alt selecto");
            //복사해줘
            this.saveCopy();
            //바로 붙여넣어버려
            this.runPaste(false, 0);
            //붙여넣은것을 선택해서 이어서 드래그 자연스럽게 연동
            e.inputEvent.preventDefault();
            setTimeout(() => {
              this.cm_moveable.dragStart(e.inputEvent);
            });
          }
          */


        }).on("selectEnd", e => {

          if(this.isSpacebar){
            e.inputEvent.preventDefault();
            return;
          }

          if(e.isDragStart) {
              e.inputEvent.preventDefault();
              setTimeout(() => {
                this.cm_moveable.dragStart(e.inputEvent);
              });
          }else{
            this.cm_targets = e.selected;
            this.cm_moveable.target = this.cm_targets;
            console.log("selectEnd len : " + this.cm_targets.length);
            
            if(this.cm_targets.length == 0){
              //아무것도 선택이 안된 상황 배경 스크린 선택 처리
              this.contentStore.select_component = null;

            }else if(this.cm_targets.length == 1){
              //한개의 컴포넌트만 선택된 경우
              //this.contentStore.select_component = this.cm_selecto.getSelectedTargets();

            }else if(this.cm_targets.length > 1){
              //드래그로 여러개의 컴포넌트가 선택된 상황
              //INFO창을 안띄우려면 아래와 같이 설정
              this.contentStore.select_component = null;
              
              //스크린을 널로 만들면 화면단위 재생안됨.
              //this.contentStore.select_screen = null;
            }
            
            
          }

        });            

      }

      if(this.cm_moveable == null){
        console.log("new moveable...");
        this.setMoveable();  

        //snap 갱신
        if(this.contentStore.select_screen != null && this.contentStore.select_screen.component_list != null && this.contentStore.select_screen.component_list.length > 0 ){
          const components = this.contentStore.select_screen.component_list.map(item => item.component);
          this.cm_moveable.elementGuidelines = components;
        }

      }

    },


    //스크린샷 다운로드.
    //이미지 마스크 및 그림자 등에 문제가 있어서 기능 숨긴다.
    async downloadimg(){

      console.log("downloadimg...");


      const cur_view  = new Play_Background();
      cur_view.setInit(this.contentStore.content_model.width, this.contentStore.content_model.height);
      await cur_view.saveImg(this.contentStore.select_screen);
      document.body.appendChild(cur_view);
      
      var img = await html2canvas(cur_view, {useCORS:true});  //cors해줘야 이미지 URL이 표시됨.
      var imgData = img.toDataURL();
      download(imgData, 'test.jpg');
      
      document.body.removeChild(cur_view);
      cur_view.exit();

      /* domtoimage2
      const blob = await domtoimage2.toBlob(cur_view, { cacheBust: true, useCORS: true });
      if (blob) {
        //saveAs(blob, 'my-custom-div.png'); // 이미지 파일로 저장
        download(blob, 'test.jpg');
      }
      */
      
      /* html2canvas
      const cur_view  = new Play_Background();
      cur_view.setInit(this.contentStore.content_model.width, this.contentStore.content_model.height);
      await cur_view.saveImg(this.contentStore.select_screen);
      document.body.appendChild(cur_view);

      //html2canvas를 쓰려면 div가 화면에 추가되서 렌더링이 된 상태여야 함.
      var img = await html2canvas(cur_view, {useCORS:true});  //cors해줘야 이미지 URL이 표시됨.
      var imgData = img.toDataURL();
      const a = document.createElement('a');
      a.href  = imgData;
      a.download = 'customDivImage.png'; 
      a.click();
      
      cur_view.exit();
      document.body.removeChild(cur_view);
      */

    },


    //EditContent.vue에서 호출함. 좌측 Screen panel에서 썸네일을 만들어서 저장하고 썸네일 url 인자값으로 호출함.
    save(content_mode, content_idx){

      console.log("editwindow save content mode : " + content_mode);

      if(content_mode == 1 || (this.contentStore.content_model.content_mode == 1 && this.contentStore.content_model.localid != null)){
        //사용자 저장/수정 처리
        this.saveUser(content_idx);
      }else{
        //관리자 저장/수정 처리
        this.saveAdmin(content_idx);
      }

    },

    async saveAdmin(content_idx){

      //JSON 파싱 - component와 screen은 드로잉 오브젝트라서 제외시킨다.
      const jsonString = JSON.stringify(this.contentStore.content_model, (key, value)=>{
        if(key === "component" || key === "screen" || key === "play_component"){
          return undefined;
        }else{
          return value;
        }
      });      
      //엔터처서 줄바꿈한 내역은 변환해야한다. 안그럼 오류남.
      var temp_obj  = JSON.parse(jsonString.replace(/\\n/g, '\\\\n'));

      if(this.contentStore.content_model.content_type == 0){

        //컨텐츠
        if(this.contentStore.content_model.idx == null){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //신규
            this.$http.post(this.$host+'/ContentInsert',{
                CON_IDX: content_idx,
                CON_NAME:escape(this.contentStore.content_model.name),
                CATEGORY_IDX:this.contentStore.content_model.category_idx,
                CON_JSON:escape(JSON.stringify(temp_obj)),
                CON_WIDTH:temp_obj.width,
                CON_HEIGHT:temp_obj.height
            },{headers: { firetoken:token}})
            .then((result)=>{
                //console.log("result : " + JSON.stringify(result));
                if(result.data.resultCode == 0){
                    this.contentStore.content_model.idx = result.data.resultData.insertId;
                    this.contentStore.createImgList();
                    this.$alert.fire('추가가 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('추가에 실패했습니다.');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("insert error : " + error);
            });
          }else{

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //수정
            this.$http.post(this.$host+'/ContentUpdate',{
                CON_NAME:escape(this.contentStore.content_model.name),
                CATEGORY_IDX:this.contentStore.content_model.category_idx,
                CON_JSON:escape(JSON.stringify(temp_obj)),
                CON_IDX:this.contentStore.content_model.idx
            },{headers: { firetoken:token}})
            .then((result)=>{
                if(result.data.resultCode == 0){
                    this.contentStore.createImgList();
                    this.$alert.fire('수정이 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('수정에 실패했습니다.');
                }

            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("update error : " + error);
            });
          }

      }else if(this.contentStore.content_model.content_type == 1){
        //에셋

        //컨텐츠
        if(this.contentStore.content_model.idx == null){

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //신규
            this.$http.post(this.$host+'/AssetInsert',{
                ASSET_IDX: content_idx,
                ASSET_NAME:escape(this.contentStore.content_model.name),
                CATEGORY_IDX:this.contentStore.content_model.category_idx,
                ASSET_JSON:escape(JSON.stringify(temp_obj))
            },{headers: { firetoken:token}})
            .then((result)=>{
                if(result.data.resultCode == 0){
                    this.contentStore.content_model.idx = result.data.resultData.insertId;
                    this.contentStore.createImgList();
                    this.$alert.fire('추가가 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('추가에 실패했습니다.');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("insert error : " + error);
            });
          }else{

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //수정
            this.$http.post(this.$host+'/AssetUpdate',{
                ASSET_NAME:escape(this.contentStore.content_model.name),
                CATEGORY_IDX:this.contentStore.content_model.category_idx,
                ASSET_JSON:escape(JSON.stringify(temp_obj)),
                ASSET_IDX:this.contentStore.content_model.idx
            },{headers: { firetoken:token}})
            .then((result)=>{
                if(result.data.resultCode == 0){
                  this.contentStore.createImgList();
                    this.$alert.fire('수정이 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('수정에 실패했습니다.');
                }

            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("update error : " + error);
            });
          }

      }else if(this.contentStore.content_model.content_type == 2){

        //컨텐츠
        if(this.contentStore.content_model.idx == null){

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //신규
            this.$http.post(this.$host+'/PopupInsert',{
                POPUP_IDX: content_idx,
                POPUP_NAME:escape(this.contentStore.content_model.name),
                CATEGORY_IDX:this.contentStore.content_model.category_idx,
                POPUP_JSON:escape(JSON.stringify(temp_obj)),
                CON_WIDTH:temp_obj.width,
                CON_HEIGHT:temp_obj.height
            },{headers: { firetoken:token}})
            .then((result)=>{
                //console.log("result : " + JSON.stringify(result));
                if(result.data.resultCode == 0){
                    this.contentStore.content_model.idx = result.data.resultData.insertId;
                    this.contentStore.createImgList();
                    this.$alert.fire('추가가 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('추가에 실패했습니다.');
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("insert error : " + error);
            });
          }else{

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            //수정
            this.$http.post(this.$host+'/PopupUpdate',{
                POPUP_NAME:escape(this.contentStore.content_model.name),
                CATEGORY_IDX:this.contentStore.content_model.category_idx,
                POPUP_JSON:escape(JSON.stringify(temp_obj)),
                POPUP_IDX:this.contentStore.content_model.idx
            },{headers: { firetoken:token}})
            .then((result)=>{
                if(result.data.resultCode == 0){
                    this.contentStore.createImgList();
                    this.$alert.fire('수정이 완료되었습니다.').then(()=>{
                        this.$emit('loadpage');
                    });
                }else if(result.data.resultCode == 2){
                    //로그인 필요
                    this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                    this.$emitter.emit('clearsession');
                }else{
                    this.$alert.fire('수정에 실패했습니다.');
                }

            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                console.log("update error : " + error);
            });
          }        

      }

    },


    async saveUser(content_idx){

      //JSON 파싱 - component와 screen은 드로잉 오브젝트라서 제외시킨다.
      const jsonString = JSON.stringify(this.contentStore.content_model, (key, value)=>{
        if(key === "component" || key === "screen" || key === "play_component"){
          return undefined;
        }else{
          return value;
        }
      });      
      //엔터처서 줄바꿈한 내역은 변환해야한다. 안그럼 오류남.
      var temp_obj  = JSON.parse(jsonString.replace(/\\n/g, '\\\\n'));

      //사용자 컨텐츠가 null이면 추가
      if(this.contentStore.content_model.mcon_idx == null){

          console.log("MyContentInsert");
          let localid = this.contentStore.content_model.localid;
          let parent_localid = this.contentStore.content_model.parent_localid;
          if(this.contentStore.content_model.localid == null || this.contentStore.content_model.localid == undefined){
            localid = this.$storage.getStorageSync("SEARCH_LOCALID");
            parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
          }
          
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //신규
          this.$http.post(this.$host+'/MyContentInsert',{
              MCON_IDX: content_idx,
              MCON_NAME:escape(this.contentStore.content_model.name),
              MCON_JSON:escape(JSON.stringify(temp_obj)),
              LOCALID:localid,
              PARENT_LOCALID:parent_localid
          },{headers: { firetoken:token}})
          .then((result)=>{
              console.log("result : " + JSON.stringify(result.data.resultCode));
              if(result.data.resultCode == 0){
                  this.contentStore.content_model.mcon_idx = result.data.resultData.insertId;
                  this.$alert.fire('추가가 완료되었습니다.').then(()=>{
                      this.$emit('loadpage');
                  });
              }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$emitter.emit('clearsession');
              }else if(result.data.resultCode == 3){
                this.$alert.fire("사용용량 초과로 해당컨텐츠를 사용할 수 없습니다. 사용중인 요금제를 업그레이드 하거나 불필요한 컨텐츠를 제거하세요.");
              }else{
                  this.$alert.fire('추가에 실패했습니다.');
              }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              console.log("insert error : " + error);
          });
        }else{

          console.log("MyContentUpdate");
          let localid = this.contentStore.content_model.localid;
          let parent_localid = this.contentStore.content_model.parent_localid;
          if(this.contentStore.content_model.localid == null || this.contentStore.content_model.localid == undefined){
            localid = this.$storage.getStorageSync("SEARCH_LOCALID");
            parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
          }

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //수정
          this.$http.post(this.$host+'/MyContentUpdate',{
              MCON_NAME:escape(this.contentStore.content_model.name),
              MCON_JSON:escape(JSON.stringify(temp_obj)),
              MCON_IDX:this.contentStore.content_model.mcon_idx,
              LOCALID:localid,
              PARENT_LOCALID:parent_localid
          },{headers: { firetoken:token}})
          .then((result)=>{
              if(result.data.resultCode == 0){
                  this.$alert.fire('수정이 완료되었습니다.').then(()=>{
                      this.$emit('loadpage');
                  });
              }else if(result.data.resultCode == 2){
                  //로그인 필요
                  this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                  this.$emitter.emit('clearsession');
              }else if(result.data.resultCode == 3){
                this.$alert.fire("사용용량 초과로 해당컨텐츠를 사용할 수 없습니다. 사용중인 요금제를 업그레이드 하거나 불필요한 컨텐츠를 제거하세요.");
              }else{
                  this.$alert.fire('수정에 실패했습니다.');
              }

          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
              console.log("update error : " + error);
          });
        }

    },


    saveCopy(){

      //this.cm_moveable.target = sl;
      //this.cm_targets         = sl;

      var elements = this.cm_selecto.getSelectedTargets();
      //var elements = this.cm_targets;
      console.log("savecopy : " + elements.length);
      
      this.copylist.splice(0, this.copylist.length);  //기본카피 제거
      elements.forEach(element=>{
        const str = JSON.stringify(
                      JSON.parse(
                          JSON.stringify(element.model, (key, value)=>{
                              if(key === "component" || key === "screen" || key === "play_component"){
                                  return undefined;
                              }else{
                                  return value;
                              }
                          })
                    ));

        //데이터 카피
        var clone_model = new Component_Model();
        clone_model.setStringValue(JSON.parse(str));

        this.copylist.push(clone_model);        
      });
      
    },

    //ismenu는 팝업메뉴에서 붙여넣기 한 케이스로 팝업메뉴 좌표를 사용
    //ismunu가 false면 ajjustpos를 사용한다.
    runPaste(ismenu, adjustpos){
      this.pastelist.splice(0, this.pastelist.length);      
      
      var selectlist = new Set(); //카피할 객체를 중복없이 넣는다.
      for(const cm of this.copylist){
        if(cm.isgroup){
          var gtarget = [].slice.call(document.querySelectorAll("."+cm.groupid));
          gtarget.forEach(gt=>{
            selectlist.add(gt.model);
          });
        }else{
          selectlist.add(cm);
        }
      }

      //중복 제거한 카피 타겟을 다시 배열화
      const finallist = [...selectlist];
      var curdate = new Date();
      if(finallist.length == 1){
        
        finallist.forEach(cm =>{

          var clone_model = Object.assign(new Component_Model(), cm); //데이터 카피 - 그냥 카피하면 함수가 실행안됨 인스턴스 생성해서 카피해야함.

          if(clone_model.isgroup){
            clone_model.groupid = clone_model.groupid+curdate.getMilliseconds();
          }

          //그라디언트는 객체여서 이렇게 처리
          clone_model.gradient = new Gradient_Model();
          clone_model.gradient.setStringValue(JSON.parse(JSON.stringify(cm.gradient)));  

          //애니매이션은 객체여서 이렇게 처리
          clone_model.aniplaymodel = new AnimationPlay_Model();
          clone_model.aniplaymodel.setStringValue(JSON.parse(JSON.stringify(cm.aniplaymodel)));  
          clone_model.component = null;
          clone_model.play_component = null;

          const posx = ismenu ? this.menuPosition_view.x-(clone_model.width/2) : clone_model.posx + adjustpos;
          const posy = ismenu ? this.menuPosition_view.y-(clone_model.height/2) : clone_model.posy + adjustpos;
          clone_model.posx   = posx;
          clone_model.posy   = posy;
          clone_model.transx = posx;
          clone_model.transy = posy;

          this.contentStore.select_screen.component_list.push(clone_model);
          this.pastelist.push(clone_model);
        });

      }else if(finallist.length > 1){
        
        //선택된 그룹 컴포넌트의 minx, miny를 찾기  
        const smallestPosx = Math.min(...this.copylist.map(cm => cm.posx));
        const smallestPosy = Math.min(...this.copylist.map(cm => cm.posy));
        // 배열을 순회하면서 가장 작은 posx와 posy를 찾음
        
        finallist.forEach(cm =>{
          var clone_model = Object.assign(new Component_Model(), cm);        //데이터 카피
          if(clone_model.isgroup){
            clone_model.groupid = clone_model.groupid+curdate.getMilliseconds();
          }
          //그라디언트는 객체여서 이렇게 처리
          clone_model.gradient = new Gradient_Model();
          clone_model.gradient.setStringValue(JSON.parse(JSON.stringify(cm.gradient)));  
          //애니매이션은 객체여서 이렇게 처리
          clone_model.aniplaymodel = new AnimationPlay_Model();
          clone_model.aniplaymodel.setStringValue(JSON.parse(JSON.stringify(cm.aniplaymodel)));  
          clone_model.component = null;
          clone_model.play_component = null;
          const posx = ismenu ? this.menuPosition_view.x+(cm.posx-smallestPosx) : clone_model.posx+adjustpos;
          const posy = ismenu ? this.menuPosition_view.y+(cm.posy-smallestPosy) : clone_model.posy+adjustpos;
          console.log(`pos1 : ${clone_model.posx}/${clone_model.posy}`);
          clone_model.posx   = posx;
          clone_model.posy   = posy;
          clone_model.transx = posx;
          clone_model.transy = posy;
          console.log(`pos2 : ${posx}/${posy}`);
          this.contentStore.select_screen.component_list.push(clone_model);
          this.pastelist.push(clone_model);
        });
      }

      this.drawscreen();

      //붙여넣은 컴포넌트 선택처리
      this.cm_targets.splice(0, this.cm_targets.length);
      this.pastelist.forEach(pmodel=>{
        this.cm_targets.push(pmodel.component);
        this.contentStore.select_component = pmodel.component;  //이걸 해줘야 편집창 바로 사용 가능.
      });

      if(this.cm_targets != null && this.cm_targets.length > 0){
        this.cm_moveable.target = this.cm_targets;
        this.$emit('selectComponent', this.cm_moveable.target);
      }

    },
    
    saveUndo(){

      //컨텐츠 모델 전체를 스냅샷을 찍는다.
      this.contentStore.saveSnapshot();
      
      //레이어와 썸네일 갱신
      this.$emitter.emit("savesnapshot");

    },

    //언두 실행
    runundo(){
      console.log("undo");
      //select 스크린 인덱스 가져오기
      const select_idx = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.contentStore.undo();
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[select_idx];
      this.drawscreen();
    },
    
    //리두 실행
    runredo(){
      console.log("redo");
      const select_idx = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.contentStore.redo();
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[select_idx];
      this.drawscreen();
    },


    //스크린모델 그리기
    drawscreen(){
     
      console.log("draw screen....");

      //기존에 그려진 스크린 클리어
      this.cleanall();
      
      //선택된 스크린 모델로 객체 생성
      const screen = new EditBasic_ScreenFixed(reactive( this.contentStore.select_screen ), this.switchEditMode);
      screen.id = "select_screen";
      screen.setZoom(1); 

      //셀렉트 락 처리
      screen.checkLock(this.$storage.getStorageSync('GRADE'));

      this.contentStore.select_screen.screen = screen;

      const editdiv = document.getElementById("editdiv");
      editdiv.appendChild(screen);
      

      //스크린을 클릭해서 이동하면 중복으로 moveable과 selecto가 셋팅될 수 있어서 방지한다.
      try{
        if(this.cm_moveable != null) this.cm_moveable.destroy();
        if(this.cm_selecto != null){
          console.log("selecto destroy...");
          this.cm_selecto.destroy();
        } 
        this.cm_moveable = null;
        this.cm_selecto  = null;
      }catch(e){
        console.log("drawscreen error : " + e);
      }
      
      //selecto와 moveable 셋팅
      this.setMoveSelecForComponent();

      //const temp = editdiv.getElementsByClassName("drag");
      //console.log("temp : " + temp.length);

      //snap 갱신처리
      const components = this.contentStore.select_screen.component_list.map(item => item.component);
      this.cm_moveable.elementGuidelines = components;
      //this.cm_moveable.elementGuidelines = [".drag"];

    },



    screenadd_test(){
      //스크린 추가
      var width  = 1920;
      var height = 1080;
      var rotate = 0;
    
      let screen_model    = new Screen_Model();
      screen_model.width  = width;
      screen_model.height = height;
      screen_model.rotate = rotate;
      
      const screen = new EditBasic_ScreenFixed(reactive(screen_model), this.switchEditMode);
      screen.setZoom(1); 
      screen.randadd();     //랜덤으로 컴포넌트 5개 추가

      const editdiv = document.getElementById("editdiv");
      editdiv.appendChild(screen);

      //this.setMoveSelecForComponent();

    },
    
    //moveable 셋팅
    setMoveable(){

      //moveable - 이동, 회전, 리사이즈 에디터 라이브러리
      const editdiv = document.getElementById("editdiv");
      this.cm_moveable = new Moveable(editdiv, {
        
        draggable: true,   //스크린과 교차로 써야되기 때문에 초기에 false줌.
        rotatable: true,
        resizable: true,
        scalable: true,

        origin: false,      //중심부 렌더링
        keepRatio: false,   //리사이즈시 비율 유지 유무
        
        verticalGuidelines: [0,this.contentStore.content_model.height/2,this.contentStore.content_model.height],
        horizontalGuidelines: [0,this.contentStore.content_model.width/2,this.contentStore.content_model.width],
        snapThreshold: 5,

        snappable: true,
        isDisplaySnapDigit: true,
        isDisplayInnerSnapDigit: false,

        snapGap: true,
        snapDirections: {"top":true,"left":true,"bottom":true,"right":true,"center":true,"middle":true},
        elementSnapDirections: {"top":true,"left":true,"bottom":true,"right":true,"center":true,"middle":true},
        snapDigit: 0,      
        maxSnapElementGuidelineDistance: null,
        //elementGuidelines:[".drag"],
        //bounds: {"left":0,"top":0,"right":1920,"bottom":1080},
      }).on("dragStart", e=>{
        e.set([e.target.model.transx, e.target.model.transy]);      

      }).on("drag", e => {

        //left, top은 이동한 만큼의 좌표이고 beforeTranslate는 부모뷰를 기준으로 절대 좌표다.
        //console.log("drag: " + e.left + " / " + e.top + " / " + e.beforeTranslate[0] + " / " + e.beforeTranslate[1]);

        if(this.isShift){
          if(Math.abs(e.left) >= Math.abs(e.top)){
            //가로이동
            if(!e.target.model.movelock){
              e.target.model.transx = Math.floor(e.beforeTranslate[0]);
              //e.target.model.transy = Math.floor(e.beforeTranslate[1]);
              e.target.model.posx   = Math.floor(e.beforeTranslate[0]);
              //e.target.model.posy   = Math.floor(e.beforeTranslate[1]);
              e.target.updateTransElement();
            }
          }else{
            //세로이동
            if(!e.target.model.movelock){
              //e.target.model.transx = Math.floor(e.beforeTranslate[0]);
              e.target.model.transy = Math.floor(e.beforeTranslate[1]);
              //e.target.model.posx   = Math.floor(e.beforeTranslate[0]);
              e.target.model.posy   = Math.floor(e.beforeTranslate[1]);
              e.target.updateTransElement();
            }
          }
        }else{
          if(!e.target.model.movelock){
            e.target.model.transx = Math.floor(e.beforeTranslate[0]);
            e.target.model.transy = Math.floor(e.beforeTranslate[1]);
            e.target.model.posx   = Math.floor(e.beforeTranslate[0]);
            e.target.model.posy   = Math.floor(e.beforeTranslate[1]);
            e.target.updateTransElement();
          }
        }


      }).on("dragEnd", e => {
        //undo 추가
        if(e.isDrag){
          //이동한 경우만 저장
          this.saveUndo(e);
        }
      }).on("rotateStart", e => {
        e.set(e.target.model.rotate);

      }).on("rotate", e => {
        e.target.model.rotate = Math.floor(e.beforeRotate);
        e.target.updateTransElement();

      }).on("resizeStart", e => {
        e.setOrigin(["%", "%"]);
        if (e.dragStart) {
          e.dragStart.set([e.target.model.transx, e.target.model.transy]);
        }
      }).on("resize", e => {
        //console.log("resize : " + e.direction); //8포인트 방향 알 수 있음

        e.target.model.width  = Math.floor(e.width);
        e.target.model.height = Math.floor(e.height);
        e.target.model.transx = Math.floor(e.drag.beforeTranslate[0]);
        e.target.model.transy = Math.floor(e.drag.beforeTranslate[1]);

        //width와 height값이 중요하다. 이 정보가 있어야 나중에 복원이 가능함 매트릭스는 공간에 대한 변형 정보라 넓이, 높이 정보가 없음. 그래서 넓이, 높이 + 매트릭스 둘 다 가지고 있어야 함.
        e.target.updateTransElementAll();

      }).on("scale", e => {
        //스케일을 쓸필요가 있나? ratio를 쓰면 될거 같은데?
        console.log("scale : " + e.scale[0] + " / " + e.scale[1]);

      }).on("dragGroupStart", e => {
        
        e.events.forEach(({ target, set }) => {
          set([target.model.transx, target.model.transy]);
        });
      
      }).on("dragGroup", e => {
        //left, top은 이동한 만큼의 좌표이고 beforeTranslate는 부모뷰를 기준으로 절대 좌표다.

        if(this.isShift){
          if(Math.abs(e.left) >= Math.abs(e.top)){
            //가로이동
            e.events.forEach(({ target, beforeTranslate }) => {
              if(!target.model.movelock){
                target.model.transx = Math.floor(beforeTranslate[0]);
                //target.model.transy = Math.floor(beforeTranslate[1]);
                target.model.posx   = Math.floor(beforeTranslate[0]);
                //target.model.posy   = Math.floor(beforeTranslate[1]);
                target.updateTransElement();
              }
            });
          }else{
            //세로이동
            e.events.forEach(({ target, beforeTranslate }) => {
              if(!target.model.movelock){
                //target.model.transx = Math.floor(beforeTranslate[0]);
                target.model.transy = Math.floor(beforeTranslate[1]);
                //target.model.posx   = Math.floor(beforeTranslate[0]);
                target.model.posy   = Math.floor(beforeTranslate[1]);
                target.updateTransElement();
              }
            });
          }
        }else{
          e.events.forEach(({ target, beforeTranslate }) => {
            if(!target.model.movelock){
              target.model.transx = Math.floor(beforeTranslate[0]);
              target.model.transy = Math.floor(beforeTranslate[1]);
              target.model.posx   = Math.floor(beforeTranslate[0]);
              target.model.posy   = Math.floor(beforeTranslate[1]);
              target.updateTransElement();
            }
          });
        }        



      }).on("dragGroupEnd", e => {        
        /*        
        e.events.forEach(({ target }) => {
          console.log("dragGroupEnd : " + JSON.stringify(target));
        });
        */

        //단일 클릭으로 그룹선택시, 또는 쉬프트 누르고 여러개 선택시
        //INFO창을 안띄우려면 아래와 같이 설정
        this.contentStore.select_component = null;
        
        //스크린을 널로 만들면 화면단위 재생안됨.
        //this.contentStore.select_screen = null;
        if(e.isDrag){
          this.saveUndo(e);
        }

      }).on("rotateGroupStart", e => {
        console.log("rotateGroupStart...");
        e.events.forEach(({target, set, dragStart}) => {
          set(target.model.rotate);
          dragStart.set([target.model.transx, target.model.transy]);
        });

      }).on("rotateGroup", e => {
        e.events.forEach(({ target, beforeRotate, drag }) => {
          const beforeTranslate = drag.beforeTranslate;
          target.model.rotate = Math.floor(beforeRotate);
          target.model.transx = Math.floor(beforeTranslate[0]);
          target.model.transy = Math.floor(beforeTranslate[1]);
          target.updateTransElement();
        });

      }).on("resizeGroupStart", e => {
        e.events.forEach((ev) => {
          ev.setOrigin(["%", "%"]);
          if(ev.dragStart){
            ev.dragStart.set([ev.target.model.transx, ev.target.model.transy]);
          }
        });

      }).on("resizeGroup", e => {
        e.events.forEach((ev) => {
          ev.target.model.width   = Math.floor(ev.width);
          ev.target.model.height  = Math.floor(ev.height);
          ev.target.model.transx  = Math.floor(ev.drag.beforeTranslate[0]);
          ev.target.model.transy  = Math.floor(ev.drag.beforeTranslate[1]);
          ev.target.updateTransElementAll();
        });

      }).on("clickGroup", e => {
        console.log("click group...");
        this.cm_selecto.clickTarget(e.inputEvent, e.inputTarget);
        //그룹핑 된 그룹 안에 요소 클릭시
        this.contentStore.select_component = e.inputTarget;
      });

    },    

    randadd(){
      for (let i = 0; i <= 5; ++i) {
        this.addcomponent();
      }      
    },


    addcomponent(){

        if(this.contentStore.select_screen == null){
          return;
        }

        //컴포넌트 모델 생성 (크기, 위치 정보)
        const posx = Math.floor(Math.random() * 1870)+50;
        const posy = Math.floor(Math.random() * 1030)+50;
        //const rotate = Math.floor(Math.random() * 360);
      
        const tempcm    = new Component_Model("editwindow addcomponent");
        tempcm.width  = Math.floor(Math.random() * 150)+50;
        tempcm.height = Math.floor(Math.random() * 150)+50;
        tempcm.posx   = posx;
        tempcm.posy   = posy;
        //tempcm.rotate = rotate;

        tempcm.rotate = 0;
        tempcm.transx = posx;
        tempcm.transy = posy;
        
        tempcm.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
        this.contentStore.select_screen.component_list.push(tempcm);
        //this.screen_model.component_list.push(tempcm);

        this.drawscreen();
    },

    
    //초기화
    cleanall(){
      const editdiv = document.getElementById("editdiv");
      while(editdiv.hasChildNodes()){
        editdiv.removeChild(editdiv.firstChild);
      }
    },


  },


}
</script>




<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.editerSize{
  width:100% !important;
  position: absolute;
  top:0px;
  bottom:0px;
  background:gray;
  overflow:hidden;
  border:1px solid #E6E6E6;
}
.editerDivSize{
  display: flex;
  flex-direction: column; /* 세로배치 */
  background:gray;
}

.moveable-control {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-sizing: border-box;
    background: #fd3434;
    margin-top: -7px;
    margin-left: -7px;
    z-index: 10;
}
</style>