<template>
  <v-container fluid style="background:#ffffff" class="h-100">

    <v-row style="background:#ffffff;" class="mt-10" justify="center">
      <v-col cols="12" md=6>
  
        <v-row justify="center">
          <div :class="$vuetify.display.mobile ? 'join_title_mb' : 'join_title'">약관동의</div>      
        </v-row>  
    
        <v-row class="mt-15">
          <v-col cols="12" class="pa-0" @click="allcheck">
            <input class="ml-5 join_check" type="checkbox" v-model="check3"/>
            <span class="ml-3 me-auto join_text">전체 동의합니다.</span>
          </v-col>
        </v-row>
        
        <v-divider class="mt-5"/> 
        
        <!-- 서비스 이용약관 -->
        <v-row class="mt-5">
          <v-col cols="12" class="pa-0">
            <input class="ml-5 join_check" type="checkbox" v-model="check1"/>
            <span class="ml-3 me-auto join_text">(필수)서비스 이용약관 동의</span>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-expand-transition class="mt-1 mb-1">
              <Policy_service v-if="check1" />
            </v-expand-transition>
          </v-col>
        </v-row>
    
        <!-- 개인정보 처리방침 -->
        <v-row class="mt-5">
          <v-col cols="12" class="pa-0">
            <input class="ml-5 join_check" type="checkbox" v-model="check2"/>
            <span class="ml-3 me-auto join_text">(필수)개인정보 수집 및 이용 동의</span>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-expand-transition class="mt-1 mb-1">
              <Policy_person v-if="check2" />
            </v-expand-transition>
          </v-col>
        </v-row>
    

    
    <v-row justify="center" class="mt-10" dense>
      <v-col cols="12" md="8">
        <v-text-field v-model="user_name" density="compact" variant="outlined"
            name="name" label="이름 또는 업체명을 입력하세요" type="text"></v-text-field>
      </v-col>
    </v-row>

    <v-row justify="center" dense>
      <v-col cols="12" md="8">
        <v-autocomplete
          clearable
          label="사용지역의 기준시간 선택"
          :items="timeList"
          variant="outlined"
          density="compact"
          v-model="user_timezone"
          />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pa-0" align="center">
        <v-btn width="250" class="ml-3" style="color:white" color="#9f0007" @click="join">모두 확인하였으며 동의합니다.</v-btn>
      </v-col>
    </v-row>
    
    <v-row class="mt-15"/>

    </v-col>
  </v-row>

  </v-container>
</template>

<script>
import Policy_person from '@/components/Policy_person.vue';
import Policy_service from '@/components/Policy_service.vue';
import Policy_mkt from '@/components/Policy_mkt.vue';

export default {
  components: { Policy_person, Policy_service, Policy_mkt },

  data: () => ({
        dialog:true,
        select_type:1,
        select_login:false,

        timeList:[],
        user_timezone: "",

        check1:false,
        check2:false,
        check3:false,
        pidx:null
      , user_id : ""
      , user_pw : ""
      , user_pw2 : ""
      , user_name : ""
      , rules: {
          mobile: value => !value || !/[^0-9-]/.test(value) || '숫자와 -외에는 입력할 수 없습니다.',
          eng: value => !value || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value) || '한글을 사용할 수 없습니다.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          notemail: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return !pattern.test(value) || '이메일주소는 사용할 수 없습니다.'
          },
      },      
  }),

  mounted(){  

    this.select_login = false;
    
    if(this.$route.query.pidx == undefined || this.$route.query.pidx == null){
      this.pidx = null;
    }else{
      this.pidx = this.$route.query.pidx;
    }

    console.log("pidx : " + this.pidx);
    this.loadtimezone();
  },

  methods: {
    

    async loadtimezone(){

      this.$http.post(this.$host+'/GuestTimezone',{
      })
      .then((result)=>{
        if(result.data.resultCode == 0){
            const list = result.data.resultData;
            this.timeList.splice(0, this.timeList.length);   //데이터 초기화
            list.forEach(element => {
              this.timeList.push(element.CITY); 
            });
        }else{
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
        }else{
          this.$alert.fire(error.message);
        }
      });

    },    

    cancel(){

        this.$alert.fire({showCancelButton: true, title:'회원가입을 취소하시겠습니까?'})
        .then((aresult) => {
          if(aresult.isConfirmed){
            this.$router.back();
          }
        });      

    },

    allcheck(){

      if(this.check3){
        this.check1 = false;
        this.check2 = false;
        this.check3 = false;
      }else{
        this.check1 = true;
        this.check2 = true;
        this.check3 = true;
      }

    },

    async join(){

        if(this.check1 == false){
            this.$alert.fire("서비스약관에 동의해주세요");
            return;
        }

        if(this.check2 == false){
            this.$alert.fire("개인정보처리방침에 동의해주세요");
            return;
        }


        if(this.user_name.length < 1){
            this.$alert.fire("이름을 입력하세요");
            return;
        }
        if(this.user_name.length > 20){
            this.$alert.fire("이름은 20자 이하로 입력하세요");
            return;
        }

        if(this.user_timezone == null || this.user_timezone == undefined || this.user_timezone.length < 1){
            this.$alert.fire("시간대를 선택하세요");
            return;
        }

        //토큰값 체크
        let token;
        try{ 
          token = await this.$fireauth.currentUser.getIdToken(); 
        }catch(error){
          this.$alert.fire("로그인 정보가 없습니다.");
          return;
        }

        this.$http.post(this.$host+'/ServiceAgree',{
            MKT: this.check3 ? 1 : 0
          , NAME: escape(this.user_name)
          , TIMEZONE: this.user_timezone
        },{headers: { firetoken:token}})
        .then(async (result)=>{
            console.log(JSON.stringify(result));
            if(result.data.resultCode == 0){
              //강제로 토큰값 갱신.
              await this.$fireauth.currentUser.getIdToken(true);
            }else{
              this.$alert.fire(result.data.resultMsg);
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          //console.log(error);
          this.$alert.fire('오류로 인해 동의하기에 실패했습니다.');
        });

      }

  },


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.join_title{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

.join_title_mb{
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 25px;
  color: rgb(0, 0, 0) !important;
  white-space: nowrap;
}

/* 체크박스 */
.join_check{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px; /* 체크박스의 너비 */
  height: 20px; /* 체크박스의 높이 */
  background-color: #BEBEBE; /* 체크된 상태일 때의 배경색 */
  border-color: #BEBEBE; /* 체크된 상태일 때의 테두리 색 */
  border-radius: 50px; /* 체크박스의 테두리 둥글기 */
  outline: none; /* 포커스시 윤곽선 제거 */
  cursor: pointer; /* 커서 모양 설정 */  
}
.join_check:checked{
  background-color: #FF6000; /* 체크된 상태일 때의 배경색 */
  border-color: #FF6000; /* 체크된 상태일 때의 테두리 색 */
}
.join_check::before{
  content: "\2713"; /* 체크 아이콘 (유니코드) */
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20px; /* 체크박스의 높이와 동일하게 설정 */
  color: #fff; /* 체크 아이콘의 색상 */
  font-size: 16px; /* 체크 아이콘의 크기 */
}


</style>