<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 30">
    <v-container fluid class="ma-0 pt-0">

        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">API NAME</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <input 
                    v-model="contentStore.select_component.model.apiname" 
                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                    @input.self="handlePaste"
                    />
            </v-col>
        </v-row>

        <v-row align="center" class="mt-1">
            <v-col cols="12" class="pa-0" align="left">
                <label style="text-align:center;font-size:12px;margin-left:12px;margin-right:5px">API 바로 호출</label>
                <input type="checkbox" v-model="contentStore.select_component.model.api_call"/>
            </v-col>
        </v-row>        


        <v-row class="pt-0 ma-0 mt-3" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">API Result Key(정상처리 판단 키)</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <input 
                    v-model="contentStore.select_component.model.apiresult_key" 
                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                    @input.self="handlePaste"
                    />
            </v-col>
        </v-row>
        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">API Success Value(정상처리 판단 값)</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <input 
                    v-model="contentStore.select_component.model.apiresult_value" 
                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                    @input.self="handlePaste"
                    />
            </v-col>
        </v-row>        

        <v-row justify="center" align="center" class="mt-2" dense>
            <v-col cols="12">
                <label style="font-size:12px">API Result Event(후 처리)</label>
            </v-col>
            <v-col cols="12" class="pt-0">
                <select 
                    v-model="contentStore.select_component.model.apiresult_type"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in apiresulttypelist" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>
        <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="contentStore.select_component.model.apiresult_type == 'Page Move'">
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">Move Page Key</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <input 
                    v-model="contentStore.select_component.model.apiresult_movekey" 
                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                    @input.self="handlePaste"
                    />
            </v-col>
        </v-row>        

        <v-row justify="center" align="center" class="mt-2" dense>
            <v-col cols="12">
                <label style="font-size:12px">API Type</label>
            </v-col>
            <v-col cols="12" class="pt-0">
                <select 
                    v-model="contentStore.select_component.model.api_type"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in apitypelist" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-2" dense>
            <v-col cols="12">
                <label style="font-size:12px">Data Type</label>
            </v-col>
            <v-col cols="12" class="pt-0">
                <select 
                    v-model="contentStore.select_component.model.api_datatype"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in datatypelist" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>

        <v-row class="pt-1 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">{{ contentStore.select_component.model.api_type == 0 ? 'API URL' : 'HOST URL' }}</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <v-textarea
                    v-model="contentStore.select_component.model.apilink"
                    density="compact" variant="outlined"
                    />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pa-0" dense v-if="contentStore.select_component.model.api_type == 1">
            <v-col cols="10">
                <div style="font-size:12px">Header Params</div>
            </v-col>
            <v-col cols="2" align="center">
                <v-icon @click="addheaderparam" color="black" icon="mdi-plus" size="x-small"></v-icon>
            </v-col>
        </v-row>
        <v-divider v-if="contentStore.select_component.model.api_type == 1"/>
        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense v-if="contentStore.select_component.model.api_type == 1">
            <v-list>
                <template v-for="item in contentStore.select_component.model.api_header.list" :key="item">

                    <v-list-item>
                        <div class="d-flex flex-row align-center">
                            <div class="d-flex flex-column">
                                <input v-model="item.key" placeholder="key"
                                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                                    >
                                <input v-model="item.value" placeholder="value"
                                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"    
                                    >
                            </div>
                            <v-icon @click="deleteheader(item)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                        </div>
                    </v-list-item>
                    
                </template>
            </v-list>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pa-0" dense v-if="contentStore.select_component.model.api_type == 1">
            <v-col cols="10">
                <div style="font-size:12px">Params</div>
            </v-col>
            <v-col cols="2" align="center">
                <v-icon @click="addparam" color="black" icon="mdi-plus" size="x-small"></v-icon>
            </v-col>
        </v-row>
        <v-divider v-if="contentStore.select_component.model.api_type == 1"/>
        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense v-if="contentStore.select_component.model.api_type == 1">
            <v-list>
                <template v-for="item in contentStore.select_component.model.api_param.list" :key="item">

                    <v-list-item>
                        <div class="d-flex flex-row align-center">
                            <div class="d-flex flex-column">
                                <input v-model="item.key" placeholder="key"
                                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                                    >
                                <input v-model="item.value" placeholder="value"
                                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"    
                                    >
                            </div>
                            <v-icon @click="deleteparam(item)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                        </div>
                    </v-list-item>
                    
                </template>
            </v-list>
        </v-row>
        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense >
            <v-col cols="12">
                <div style="font-size:12px">value값에 @을 붙이고 eventname 사용시 실시간 맵핑</div>
            </v-col>
        </v-row>


        <!-- 
        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense>
            <v-table>
                <thead>
                    <tr>
                        <th>KEY</th>
                        <th>VALUE</th>
                    </tr>
                </thead>
                <t-body>
                    <tr v-for="item in contentStore.select_component.model.api_header" :key="item.key">
                        <td>{{ item.key }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                </t-body>
            </v-table>
        </v-row>
        -->
        
        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

        <v-row dense class="mt-15"></v-row>
        <v-row dense class="mt-15"></v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import APIParam_Model from '@/nextts/model/APIParam_Model';

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    handlePaste(event){
        console.log("handlePaste");
        event.stopPropagation();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },


    addheaderparam(){
        console.log("add header param : " + this.contentStore.select_component.model.api_header.list.length);
        this.contentStore.select_component.model.api_header.list.push(new APIParam_Model());
    },

    deleteheader(item){
        const idx = this.contentStore.select_component.model.api_header.list.indexOf(item);
        this.contentStore.select_component.model.api_header.list.splice(idx, 1);
    },

    addparam(){
        console.log("add header param : " + this.contentStore.select_component.model.api_param.list.length);
        this.contentStore.select_component.model.api_param.list.push(new APIParam_Model());
    },

    deleteparam(item){
        const idx = this.contentStore.select_component.model.api_param.list.indexOf(item);
        this.contentStore.select_component.model.api_param.list.splice(idx, 1);
    }

  },


  data(){
    return{

        apitypelist:[
                {name:"GET",  value:0}
            ,   {name:"POST", value:1}
        ],

        datatypelist:[
                {name:"JSON",   value:"json"}
            ,   {name:"XML",    value:"xml"}
        ],

        apiresulttypelist:[
                {name:"None",       value:"None"}
            ,   {name:"Reload",     value:"Reload"}
            ,   {name:"Page Move",  value:"Page Move"}
        ],

    }
  },

  mounted(){
    

  },

  
}
</script>