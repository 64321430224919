<template>
  <v-container fluid style="background:white">

    <v-row justify="center" dense no-gutters style="height:100px">
    </v-row>

    <v-row justify="center" dense no-gutters class="mt-15">
      <v-col xl="8" lg="8" md="8" cols="11" xs="11" sm="11" align="center">
        <v-img
          height="200" contain
          :src="require('@/assets/notfound.jpg')"
          >
        </v-img>
      </v-col>
    </v-row>

    <v-row justify="center" dense no-gutters>
      <v-col xl="8" lg="8" md="8" cols="11" xs="11" sm="11" align="center">
        <p style="color:#202020;display:inline;font-size:22px" class="fontchange">페이지가 없습니다.</p>
      </v-col>
    </v-row>

    <v-row justify="center" dense no-gutters style="height:500px">
    </v-row>

  </v-container>
</template>
<script>

export default {
    
    data: () => ({

    }),

    mounted(){

    },

    methods: {

    },

    created(){

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.roundbg{
  border-radius: 25px;
  background: #ffffff;
  padding: 20px;
  padding-bottom: 30px;
}

.fontchange{
  font-family: 'NotoSansKR';
}


</style>