
import Screen_Model from '@/nextts/model/Screen_Model';

//기본 데이터 속성
class Content_Model {

    idx:string|null             = null;     //오리지널 컨텐츠 idx
    category_idx:Number|null    = null;
    content_type:Number|null    = 0;        //0:content, 1:assets
    
    //사용자가 오리지널 컨텐츠를 복제하여 저장할 때 사용함.
    content_mode:Number|null    = 0;        //0:admin, 1:user
    mcon_idx:string|null        = null;
    localid:String|null         = null;     //저장할 사용자의 idx content_mode가 1일경우만 사용
    parent_localid:String|null  = null;     //저장할 사용자의 idx content_mode가 1일경우만 사용

    name:string     = "";
    
    //컨텐츠 사이즈 정보
    width:Number    = 0;
    height:Number   = 0;

    //스크린 리스트 정보
    screen_list:Array<Screen_Model>;

    //API로 조회된 데이터 저장소
    static apiData: Map<string, any> = new Map();

      constructor(w:Number, h:Number){
        this.width  = w;
        this.height = h;
        this.screen_list = Array<Screen_Model>();
    }


    //서버에서 읽어온 json정보로 셋팅
    setStringValue(params:string):void{
        
        console.log("setStringValue : " + params);

        const temp          = JSON.parse(JSON.stringify(params));
        this.width          = temp.width;
        this.height         = temp.height;
        console.log("setStringValue : " + temp.width);

        

        this.screen_list.splice(0, this.screen_list.length);
        temp.screen_list.forEach((screen:string) => {
            const sm = new Screen_Model();
            sm.setStringValue(screen);
            this.screen_list.push(sm);
        });
        
    }

}

export default Content_Model
