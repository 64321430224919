
import Component_Model from '@/nextts/model/Component_Model';
import Play_Text from '@/nextts/play/Play_Text';
import Play_Image from '@/nextts/play/Play_Image';
import Play_TextSwitcher from '@/nextts/play/Play_TextSwitcher';
import Play_ImageSwitcher from '@/nextts/play/Play_ImageSwitcher';
import Play_Color from '@/nextts/play/Play_Color';
import Play_Movie from '@/nextts/play/Play_Movie';
import Play_YouTube from '@/nextts/play/Play_YouTube';
import Play_Webview from '@/nextts/play/Play_Webview';
import Play_Sound from '@/nextts/play/Play_Sound';
import Play_Api from '@/nextts/play/Play_Api';
import Play_TextScroll from '@/nextts/play/Play_TextScroll';
import Play_DateTime from './Play_DateTime';
import Play_ArtText from './Play_ArtText';
import Play_DataLoad from './Play_DataLoad';

//기본 데이터 속성
class Play_Controller{
    

    public static getPlayView(m:Component_Model):HTMLElement|null{

        let component: HTMLElement | null = null;
        
        switch(m.component_type){
            case Component_Model.COMPONENT_TEXT : 
                component = new Play_Text(m); 
                break;
            case Component_Model.COMPONENT_IMAGE : 
                component = new Play_Image(m); 
                break;
            case Component_Model.COMPONENT_TEXTSWITCHER : 
                component = new Play_TextSwitcher(m); 
                break;
            case Component_Model.COMPONENT_IMAGESWITCHER: 
                component = new Play_ImageSwitcher(m); 
                break;
            case Component_Model.COMPONENT_COLOR: 
                component = new Play_Color(m); 
                break;
            case Component_Model.COMPONENT_MOVIE: 
                component = new Play_Movie(m); 
                break;
            case Component_Model.COMPONENT_YOUTUBE: 
                component = new Play_YouTube(m); 
                break;
            case Component_Model.COMPONENT_WEBVIEW: 
                component = new Play_Webview(m); 
                break;
            case Component_Model.COMPONENT_SOUND: 
                component = new Play_Sound(m); 
                break;
            case Component_Model.COMPONENT_DATALOAD: 
                component = new Play_DataLoad(m); 
                break;
            case Component_Model.COMPONENT_DATETIME: 
                component = new Play_DateTime(m); 
                break;
            case Component_Model.COMPONENT_ARTTEXT: 
                component = new Play_ArtText(m); 
                break;
            case Component_Model.COMPONENT_API: 
                component = new Play_Api(m); 
                break;
            case Component_Model.COMPONENT_TEXT_SCROLL: 
                component = new Play_TextScroll(m); 
                break;
            default:
                //아무런 케이스에도 해당하지 않으면
                component = null;
        }

        return component;
    }


}
export default Play_Controller
