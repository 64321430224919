<template>

  <v-container fluid>

    <!-- 추가/수정 팝업 컨텐츠 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog" max-width="800px" style="z-index:1">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="close" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              
              <v-row>

                <v-col cols="12">
                  <v-btn-toggle
                    v-model="multiple_type"
                    color="deep-purple-accent-3"
                    rounded="0"
                    group
                    >
                    <v-btn :value="0">
                      일자별 선택
                    </v-btn>
                    <v-btn :value="1">
                      기간으로 선택
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                
                <v-col cols="12">
                  <div>스케줄을 추가할 기간을 선택하세요</div>
                  <v-date-input v-model="dateRange" variant="outlined" prepend-icon="" density="compact"  :multiple="multiple_type == 0 ? '' : 'range'"></v-date-input>
                </v-col>

                <v-col cols="12">
                  <v-select v-model="content_type" :items="PLAYTYPELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" 
                    @update:modelValue="changeplaytype"
                    hide-details elevation="0"  
                    label="플레이 타입" />
                </v-col>

                <v-col cols="12" v-if="content_type == 0">
                  <v-select v-model="content_idx" :items="contentList" variant="outlined" density="compact"
                    item-title="MCON_NAME" item-value="MCON_IDX" 
                    hide-details elevation="0" 
                    label="컨텐츠" />
                </v-col>
                <v-col cols="12" v-if="content_type == 1">
                  <v-select v-model="content_idx" :items="groupList" variant="outlined" density="compact"
                    item-title="MCGROUP_NAME" item-value="MCGROUP_IDX" 
                    hide-details elevation="0" 
                    label="컨텐츠그룹" />
                </v-col>

                <v-col cols="12" v-if="content_type == 2">
                    <vue-cal 
                      ref="vuecal2"
                      class="vuecal--green-theme"
                      locale="ko"
                      active-view="day"
                      hide-view-selector
                      hide-weekends
                      hide-title-bar
                      xsmall          
                      style="min-height: 600px;"
                      :time-from="start_time * 60"
                      :time-to="end_time * 60"
                      :time-step="min_step"    
                      :snap-to-time="5"
                      @event-create="onEventCreate"
                      @event-delete="onEventDeleteDay"        
                      @event-drag-create="onDragCreateDay"
                      @event-duration-change="onDurationnChangeDay"
                      @event-drop="onEventDropDay"
                      editable-events
                      :events="eventsday"
                      >
                      <template #event="{ event }">
                        <v-tooltip :text="`${event.start.formatTime('HH:mm')} ~ ${event.end.formatTime('HH:mm')}`"  location="top">
                          <template v-slot:activator="{ props }">
                            <div v-bind="props" @click="selectContent(event, true)">
                              <small class="vuecal__event-time" style="color:white">
                                <span>{{ event.start.formatTime("HH:mm") }}</span> ~ <span>{{ event.end.formatTime("HH:mm") }}</span>
                              </small>
                              <div class="vuecal__event-title" style="color:white">
                                <label>{{ event.content_idx == null || event.content_idx == undefined ? '컨텐츠를 지정하세요' : event.title }}</label>
                              </div>
                              <div class="vuecal__event-content" style="color:white">
                                <label>{{ event.content }}</label>
                              </div>

                            </div>
                          </template>
                        </v-tooltip>

                      </template>        


                    </vue-cal>
              </v-col>
            </v-row>
              
            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="add_schedule">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 컨텐츠 선택 팝업 -->
    <v-row>
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog_content" max-width="800px">
        <v-card class="dialog_round pa-10">

          <v-row dense align="center">
            <v-col cols="11" md="11">
              <span class="dialog_title">컨텐츠 선택</span>
            </v-col>
            <v-col cols="1" md="1" sm="1" xs="1">
              <v-btn  size="x-small" elevation="0" @click="dialog_content = false" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>

          <v-row dense justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
              <div style="background:#4e6ef7;height:2px;" />
            </v-col>
          </v-row>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div>스케줄에 추가하실 컨텐츠를 선택하세요.</div>
                </v-col>
                <v-col cols="12">
                  <v-btn-toggle
                    v-model="select_content_type" @update:modelValue="changeplaytype"
                    color="deep-purple-accent-3"
                    rounded="0"
                    group
                    >
                    <v-btn :value="0">
                      컨텐츠
                    </v-btn>
                    <v-btn :value="1">
                      컨텐츠그룹
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" v-if="select_content_type == 0">
                  <v-select v-model="select_content" :items="contentList" variant="outlined" density="compact"
                    item-title="MCON_NAME" item-value="MCON_IDX" return-object
                    hide-details elevation="0" 
                    label="컨텐츠" />
                </v-col>
                <v-col cols="12" v-if="select_content_type == 1">
                  <v-select v-model="select_content" :items="groupList" variant="outlined" density="compact"
                    item-title="MCGROUP_NAME" item-value="MCGROUP_IDX" return-object
                    hide-details elevation="0" 
                    label="컨텐츠그룹" />
                </v-col>
            </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_cancel_btn mr-5" rounded large  @click="dialog_content = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn class="dialog_btn" rounded large  @click="select_content_dialog">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="12" md="6" align="center"  class="d-flex align-center justify-end">
        Show Time
        <select 
          v-model="start_time" class="ml-1"
          style="width:50px;border: 1px gray solid; border-radius:5px; text-align:center;background:white">
          <template v-for="cptype in timelist" :key="cptype.name">
              <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
          </template>
        </select>
        <label>&nbsp;~&nbsp;</label>
        <select 
          v-model="end_time" class="mr-3"
          style="width:50px;border: 1px gray solid; border-radius:5px; text-align:center;background:white">
          <template v-for="cptype in timelist" :key="cptype.name">
              <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
          </template>
        </select>
        Min Step
        <select 
          v-model="min_step" class="ml-1"
          style="width:50px;border: 1px gray solid; border-radius:5px; text-align:center;background:white">
          <template v-for="cptype in minlist" :key="cptype.name">
              <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
          </template>
        </select>
      </v-col>
    </v-row>

    <v-row align="center" justify="end">
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="addrangecontent">추가</v-btn>
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="allclear">전체삭제</v-btn>
      </v-col>
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="save_schedule">저장</v-btn>
      </v-col>
    </v-row>
  

  <!--
    locale="ko" 언어
    :time-from="0"      시작시간
    :time-to="24 * 60"  종료시간
    :time-step="10"     시간차
    today-button        오늘로 이동하는 버튼
    resize-x            다음날까지 스케줄을 지정할 수 있는지
    editable-events     에디터 옵션 (드래그, 삭제, 생성등등)
    :on-event-click="onEventClick"  이벤트 클릭 이벤트
    :onEventDblclick=""             이벤트 더블 클릭
    @cell-dblclick="onEventDbClick" 빈 셀 더블클릭

    @event-change="onEventChange" 이벤트 변경 감지. 모든 이벤트가 인자값으로 다 들어와서..

    hide-view-selector  상단의 연도, 월, 주, 일 안보이게 함.
    :time="false"       시간 안나옴.
    active-view="month" 처음 시작이 달력
    xsmall              달력 표시 텍스트의 간소화
    events-on-month-view="short"  월간 표시에서 타이틀을 표시함. 이거 안쓰면 그냥 개수나옴.
    
    :on-event-create="onEventCreate"  이벤트가 생성되자 마자 콜백
    @event-drag-create="onDragCreate" 드래그로 이벤트 생성 후 콜백
    @event-duration-change="onDurationnChange"  시간 변경시
  -->

  <v-row >
    <v-col cols="12">
      <vue-cal 
        ref="vuecal"
        class="vuecal--green-theme"
        locale="ko"
        active-view="month"
        style="min-height: 600px;"
        :disable-views="['years']"
        :time-from="start_time * 60"
        :time-to="end_time * 60"
        :time-step="min_step"    
        today-button
        startWeekOnSunday
        :snap-to-time="5"
        @event-create="onEventCreate"
        @event-delete="onEventDelete"        
        @event-drag-create="onDragCreate"
        @event-duration-change="onDurationnChange"
        @event-drop="onEventDrop"
        @event-dblclick="onEventDbClick" 
        editable-events
        :events="events"
        @cell-dblclick="onCellDbClick"
        >
        <template #event="{ event }">
          <v-tooltip :text="`${event.start.formatTime('HH:mm')} ~ ${event.end.formatTime('HH:mm')}`"  location="top">
            <template v-slot:activator="{ props }">
            <div v-bind="props" @click="selectContent(event, false)">
              <small class="vuecal__event-time" style="color:white">
                <span>{{ event.start.formatTime("HH:mm") }}</span> ~ <span>{{ event.end.formatTime("HH:mm") }}</span>
              </small>
              <div class="vuecal__event-title" style="color:white">
                <label>{{ event.content_idx == null || event.content_idx == undefined ? '컨텐츠를 지정하세요' : event.content_type == 0 ? '컨텐츠' : '컨텐츠그룹' }}</label>
              </div>
              <div class="vuecal__event-content" style="color:white">
                <label>{{ event.content }}</label>
              </div>
            </div>
            </template>
          </v-tooltip>
        </template>        
      </vue-cal>
    </v-col>
  </v-row>

  <!-- 
  hideBody:               [Boolean],         default: false
  hideTitleBar:           [Boolean],         default: false
  hideViewSelector:       [Boolean],         default: false
  hideWeekdays:           [Array],           default: []
  hideWeekends:           [Boolean],         default: false
  -->

  


  </v-container>
</template>

<script>
import { ref } from '@vue/reactivity';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import Moment from 'moment'
import { extendMoment } from 'moment-range';
import { v4 as uuidv4 } from 'uuid';
//import moment from 'moment';
export default {

    components: { VueCal },

      

    data: () => ({
      
      MSCHD_IDX: null,
      multiple_type: 0, //날짜선택 타입 : 0기간, 1레인지
      content_type: 0,  //추가할 컨텐츠 타입 : 0단일, 1컨텐츠그룹, 2데일리 스케줄
      content_idx: null,
      
      //데일리 스케줄 컨텐츠 선택
      dialog_content: false,
      dialog_content_type: 0,   //0이면 스케줄, 1이면 day스케줄
      select_content_type: 0,
      select_content: null,

      contentList: [],
      groupList: [],
      scheduleList: [],
      deleteList:[],

      PLAYTYPELIST: [
          {name:'단일컨텐츠',     value:0}
        , {name:'컨텐츠그룹',     value:1}
        , {name:'데일리 스케줄',  value:2}
      ],

      menu:false,
      SELECTDATE: new Date().toISOString().substr(0, 10),
      dateRange: null,

      start_time:8,
      end_time:18,
      min_step:60,


      timelist:[
          {name: '00', value:0}
        , {name: '01', value:1}
        , {name: '02', value:2}
        , {name: '03', value:3}
        , {name: '04', value:4}
        , {name: '05', value:5}
        , {name: '06', value:6}
        , {name: '07', value:7}
        , {name: '08', value:8}
        , {name: '09', value:9}
        , {name: '10', value:10}
        , {name: '11', value:11}
        , {name: '12', value:12}
        , {name: '13', value:13}
        , {name: '14', value:14}
        , {name: '15', value:15}
        , {name: '16', value:16}
        , {name: '17', value:17}
        , {name: '18', value:18}
        , {name: '19', value:19}
        , {name: '20', value:20}
        , {name: '21', value:21}
        , {name: '22', value:22}
        , {name: '23', value:23}
        , {name: '24', value:24}
      ],

      minlist:[
          {name: '10', value:10}
        , {name: '20', value:20}
        , {name: '30', value:30}
        , {name: '40', value:40}
        , {name: '50', value:50}
        , {name: '60', value:60}
      ],      

      dropitem: {
        id: 1,
        title: 'DropTest',
        content: 'content 1',
        duration: 60
      },

      selectedEvent: {},  //선택된 이벤트
      deleteFunction:null,
      events: [],
      eventsday: [],

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          LOCALID:'',
          MSCH_NAME:'',
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      itemList: [],

      sort_name: 'MSCH_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      STATELIST: [
          {name:'사용중지',   value:0}
        , {name:'사용',       value:1}
      ],
         
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      grade:0,
      engrules:[v => !v || !/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) || '한글을 사용할 수 없습니다.'],

    }),

    setup(){
        var vuecal = ref(null);
        return {vuecal};
    },

    mounted(){
      this.MSCHD_IDX           = this.$route.query.idx;
      this.SEARCH_LOCALID      = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      console.log("SEARCH_PARENT_LOCALID :" + this.SEARCH_PARENT_LOCALID);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      //팝업에서 컨텐츠 선택
      select_content_dialog(){

        this.dialog_content = false;
        this.selectedEvent.content_type = this.select_content_type;
        if(this.select_content_type == 0){
          if(this.select_content == null || this.select_content == undefined || this.select_content.MCON_IDX == null || this.select_content.MCON_IDX == undefined){
            this.$alert.fire("컨텐츠를 선택하세요.");
            return;
          }
          this.selectedEvent.content_idx  = this.select_content.MCON_IDX;
          this.selectedEvent.title    = "컨텐츠";
          this.selectedEvent.content  = this.select_content.MCON_NAME;
        }else{
          if(this.select_content == null || this.select_content == undefined || this.select_content.MCGROUP_IDX == null || this.select_content.MCGROUP_IDX == undefined){
            this.$alert.fire("컨텐츠 그룹을 선택하세요.");
            return;
          }
          this.selectedEvent.content_idx  = this.select_content.MCGROUP_IDX;
          this.selectedEvent.title    = "컨텐츠그룹";
          this.selectedEvent.content  = this.select_content.MCGROUP_NAME;
        }

        console.log("selectEvent2 : " + JSON.stringify(this.selectedEvent));
        
        if(this.dialog_content_type){
          //day 스케줄
          const index = this.eventsday.findIndex(event => event.id === this.selectedEvent.id);
          if (index !== -1) {
            this.eventsday[index].content_type = this.selectedEvent.content_type;
            this.eventsday[index].content_idx  = this.selectedEvent.content_idx;
            this.eventsday[index].title        = this.selectedEvent.title;
            this.eventsday[index].content      = this.selectedEvent.content;
          }        
        }else{
          //스케줄
          const index = this.events.findIndex(event => event.id === this.selectedEvent.id);
          if (index !== -1) {
            this.events[index].content_type = this.selectedEvent.content_type;
            this.events[index].content_idx  = this.selectedEvent.content_idx;
            this.events[index].title        = this.selectedEvent.title;
            this.events[index].content      = this.selectedEvent.content;
          }        

        }

        this.selectedEvent = null;

      },

      changeplaytype(){
        console.log("changeplaytype");
        this.content_idx = null;
        this.select_content = null;
      },

      //등록된 내컨텐츠 로드
      async loadmycontent(){

        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.$http.post(this.$host+'/MyAllContentList',{
          LOCALID:this.SEARCH_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){

              const clist = result.data.contentData;
              this.contentList.splice(0, this.contentList.length);
              clist.forEach(element => {
                this.contentList.push(element); 
              });

              const glist = result.data.groupData;
              this.groupList.splice(0, this.groupList.length);
              glist.forEach(element => {
                this.groupList.push(element); 
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },            

      async add_schedule(){
        

        if(this.content_type == 2){
          console.log("eventsday : " + JSON.stringify(this.eventsday));
          if(this.eventsday.length == 0){
            this.$alert.fire("1개 이상의 스케줄을 추가하세요.");
            return;
          }

          for(const event of this.eventsday){
            if(event.content_type == null || event.content_type == undefined){
              this.$alert.fire(`${ this.$momenttz.utc(event.start).tz(this.$storage.getStorageSync("TIMEZONE")).format("HH:mm") }~${ this.$momenttz.utc(event.end).tz(this.$storage.getStorageSync("TIMEZONE")).format("HH:mm") }에 컨텐츠를 지정하세요.`);
              return;
            }
          }

        }

        this.dialog = false;
        
        //신규 이벤트 생성
        const newevents = [];
        for(var date of this.dateRange){

          if(this.content_type == 2){

            for(var sevent of this.eventsday){

              //로컬시간
              const sdatetime = `${date.format("YYYY-MM-DD")} ${sevent.start.formatTime("HH:mm")}`;
              const edatetime = `${date.format("YYYY-MM-DD")} ${sevent.end.formatTime("HH:mm") }`;

              //UTC로 변환해서 서버에 전송
              const eventday = {  id: uuidv4()
                                , start: this.$momenttz.tz(sdatetime, this.$storage.getStorageSync("TIMEZONE")).utc().format("YYYY-MM-DD HH:mm")
                                , end: this.$momenttz.tz(edatetime, this.$storage.getStorageSync("TIMEZONE")).utc().format("YYYY-MM-DD HH:mm") 
                                , content_type:sevent.content_type
                                , content_idx: sevent.content_idx
                              };

              newevents.push(eventday);  
              
            }
              
          }else{

            console.log("select content : " + JSON.stringify(this.selectContent))
            
            //로컬시간
            const sdatetime = `${date.format("YYYY-MM-DD")} 00:00`;
            const edatetime = `${date.format("YYYY-MM-DD")} 23:59`;

            //UTC로 변환해서 서버에 넣어야 한다.
            const eventday = {    id: uuidv4()
                                , start: this.$momenttz.tz(sdatetime, this.$storage.getStorageSync("TIMEZONE")).utc().format("YYYY-MM-DD HH:mm")
                                , end: this.$momenttz.tz(edatetime, this.$storage.getStorageSync("TIMEZONE")).utc().format("YYYY-MM-DD HH:mm") 
                                , content_type:this.content_type 
                                , content_idx:this.content_idx
                              };
            newevents.push(eventday);  
          }
          
        }

        //신규로 추가되는 이벤트와 충돌하는 기존 이벤트를 삭제리스트로 옮긴다.
        if(this.events.length > 0){
          let targetlist = newevents.map(itemA => {
            let itemDate = itemA.start.slice(0, 10);
            return this.events.filter(itemB => itemB.start.slice(0, 10) === itemDate);
          }).flat();
          if(targetlist.length > 0){
            this.deleteList = [...this.deleteList, ...targetlist];
          }
        }

        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/MyScheduleEventsSave',{
              CONTENTLIST: escape(JSON.stringify(newevents))
            , DELETELIST: escape(JSON.stringify(this.deleteList))
            , MSCHD_IDX:this.MSCHD_IDX
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            this.$alert.fire("저장이 완료되었습니다.").then(()=>{
              this.loadschedule();
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('저장이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('저장이 실패했습니다.');
        });        

      },

      changeDateFormat(date) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString('ko-KR', options); // YYYY-MM-DD 형식으로 변환
      },      

      addrangecontent(){
        this.dateRange = null;
        this.eventsday.splice(0, this.eventsday.length);
        this.dialog = true;
        
        /*
        const sdate = '2024-05-04 12:00';
        const edate = '2024-05-04 17:00';
        this.events.push({
          id: new Date().getMilliseconds(),
          start: sdate,
          end: edate,
          title: 'A new event',
          class: 'blue-event'
        })
        */

      },

      allclear(){
        
        this.deleteList = [ ...this.events ];
        this.events.splice(0, this.events.length);

      },

      //중복을 체크하는 메소드
      isOverlapping(newEvent) {
        const moment = extendMoment(Moment);
        const ran1 = moment.range(newEvent.start, newEvent.end);
        return this.events.some(event => {
          const ran2 = moment.range(event.start, event.end);
          return ( event.id !== newEvent.id && (ran1.overlaps(ran2) || ran1.intersect(ran2)) );
        });
      },      

      isOverlappingDay(newEvent) {
        const moment = extendMoment(Moment);
        const ran1 = moment.range(newEvent.start, newEvent.end);
        return this.eventsday.some(event => {
          const ran2 = moment.range(event.start, event.end);
          return ( event.id !== newEvent.id && (ran1.overlaps(ran2) || ran1.intersect(ran2)) );
        });
      },      

      onDragCreate(event){
        console.log("drag create..." + JSON.stringify(event));
        // 중복 검사를 수행
        event.id = uuidv4();
        if(this.isOverlapping(event)) {
          console.log('This time slot is already booked!');
          //추가된 이벤트 삭제
          this.deleteFunction();
          this.onEventDelete(event);
        } else {
          console.log('Event added successfully!');
          this.events.push(event);
        }        
      },

      onDragCreateDay(event){
        event.id = uuidv4();
        if(this.isOverlappingDay(event)) {
          this.deleteFunction();
          this.onEventDeleteDay(event);
        } else {
          this.eventsday.push(event);
        }        
      },


      //이벤트가 생성될 때 콜백됨.
      //eslint-disable-next-line no-unused-vars
      onEventCreate(event, deleteEventFunction){
        if(deleteEventFunction != undefined){
          this.deleteFunction = deleteEventFunction;
        }
        //여기서 리턴이 되야 달력에 이벤트가 추가됨.
        return event;
      },

      onEventDelete(deletedEvent){
        const index = this.events.findIndex(event => event.id === deletedEvent.id);
        if (index !== -1) {
          this.deleteList.push(this.events[index]);
          this.events.splice(index, 1);
        }        
      },

      onEventDeleteDay(deletedEvent){
        const index = this.eventsday.findIndex(event => event.id === deletedEvent.id);
        if (index !== -1) {
          this.eventsday.splice(index, 1);
        }        
      },

      onDurationnChange({event, originalEvent}){
        if(this.isOverlapping(event)){
          //중복됨
          event.end = originalEvent.end;
          this.onEventUpdate(event);
        }else{
          //중복안됨
          this.onEventUpdate(event);
        }
      },
      onDurationnChangeDay({event, originalEvent}){
        if(this.isOverlappingDay(event)){
          event.end = originalEvent.end;
          this.onEventUpdateDay(event);
        }else{
          this.onEventUpdateDay(event);
        }
      },

      handleNavigation(){
        console.log("handleNavigation");
      },

      async save_schedule(){
        

        for(const event of this.events){
          if(event.content_type == null || event.content_type == undefined){
            this.$alert.fire(`${ this.$momenttz.utc(event.start).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD") }일의 ${ this.$momenttz.utc(event.start).tz(this.$storage.getStorageSync("TIMEZONE")).format("HH:mm") }~${ this.$momenttz.utc(event.end).tz(this.$storage.getStorageSync("TIMEZONE")).format("HH:mm") }에 컨텐츠를 지정하세요.`);
            return;
          }
        }
        
        for(const event of this.events){
          //드래그나 시간조절하면 시간값이 object로 바뀜. 바로 로드하면 string임.
          if(typeof event.start === 'object'){
            event.start = `${this.$momenttz.utc(event.start).format("YYYY-MM-DD HH:mm")}`;
          }else{
            //로컬시간을 UTC로 전환
            event.start = `${this.$momenttz.tz(event.start, this.$storage.getStorageSync("TIMEZONE")).utc().format("YYYY-MM-DD HH:mm")}`;
          }

          if(typeof event.end === 'object'){
            event.end = `${this.$momenttz.utc(event.end).format("YYYY-MM-DD HH:mm")}`;
          }else{
            //로컬시간을 UTC로 전환
            event.end = `${this.$momenttz.tz(event.end, this.$storage.getStorageSync("TIMEZONE")).utc().format("YYYY-MM-DD HH:mm")}`;
          }
        }

        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/MyScheduleEventsSave',{
              CONTENTLIST: escape(JSON.stringify(this.events))
            , DELETELIST: escape(JSON.stringify(this.deleteList))
            , MSCHD_IDX:this.MSCHD_IDX
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            this.$alert.fire("저장이 완료되었습니다.").then(()=>{
              this.loadschedule();
            });
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('저장이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('저장이 실패했습니다.');
        });
        


      },

      selectContent(event, isday){
        this.select_content = null;
        this.selectedEvent  = event;
        if(this.selectedEvent.content_type != null && this.selectedEvent.content_type != undefined){
          this.select_content_type = this.selectedEvent.content_type;
          if(this.selectedEvent.content_idx != null && this.selectedEvent.content_idx != undefined){
            if(this.select_content_type == 0){
              this.select_content = { MCON_IDX:this.selectedEvent.content_idx, MCON_NAME: this.selectedEvent.content};
            }else{
              this.select_content = { MCGROUP_IDX:this.selectedEvent.content_idx, MCGROUP_NAME: this.selectedEvent.content};
            }
          }
        }
        this.dialog_content_type = isday;
        this.dialog_content = true;
      },

      createEvent({event}){

        console.log("createEvent : " + event);

      },

      //external: 외부에서 드랍된거면 true, 동일 컴포넌트 드랍이면 false
      //eslint-disable-next-line no-unused-vars
      onEventDrop({ event, originalEvent, external }) {
        if(!external){
          //내부에서 이동시
          if(this.isOverlapping(event)){
            //중복됨
            console.log("duple 2");
            //원인을 알 수 없는데 여기서 이렇게 포맷을 안바꿔주면 오류남.
            const suDate = new Date(originalEvent.start);
            const seDate = new Date(originalEvent.end);
            event.start = this.formatDate(suDate);
            event.end   = this.formatDate(seDate);
            this.onEventUpdate(event);

          }else{
            //중복안됨
            this.onEventUpdate(event);
          }          
        }
      },
      onEventDropDay({ event, originalEvent, external }) {
        if(!external){
          if(this.isOverlappingDay(event)){
            //원인을 알 수 없는데 여기서 이렇게 포맷을 안바꿔주면 오류남.
            const suDate = new Date(originalEvent.start);
            const seDate = new Date(originalEvent.end);
            event.start = this.formatDate(suDate);
            event.end   = this.formatDate(seDate);
            this.onEventUpdateDay(event);
          }else{
            //중복안됨
            this.onEventUpdateDay(event);
          }          
        }
      },      

      //이벤트 객체를 업데이트 한다.
      onEventUpdate(updatedEvent) {
        const index = this.events.findIndex(event => event.id === updatedEvent.id);
        this.events.splice(index, 1, updatedEvent);
      },
      onEventUpdateDay(updatedEvent) {
        const index = this.eventsday.findIndex(event => event.id === updatedEvent.id);
        this.eventsday.splice(index, 1, updatedEvent);
      },

      onEventClick(event, e){

        this.selectedEvent = event;
        
        console.log("click event : " + JSON.stringify(event));
        
        e.stopPropagation()

      },

      //외부에서 드래그앤 드랍으로 스케줄에 넣을 때
      onEventDragStart(e, item) {
        console.log("onEventDragStart : " + JSON.stringify(item));
        e.dataTransfer.setData('event', JSON.stringify(item))
        e.dataTransfer.setData('cursor-grab-at', e.offsetY)
      },

      
      onEventDbClick(event){

        console.log(event);

        /*
        this.vuecal.createEvent(
          event,
          120,
          { title: 'New Event', class: 'blue-event' }
        ) 
        */       

      },


      onCellDbClick(event){

        console.log(event);

        /*
        this.vuecal.createEvent(
          event,
          120,
          { title: 'New Event', class: 'blue-event' }
        ) 
        */       

      },

      //UTC -> KO시간대로
      formatDate(date) {
          // 연, 월, 일, 시, 분을 포맷에 맞게 조정
          const year = date.getFullYear();
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          const hour = ('0' + date.getHours()).slice(-2);
          const minute = ('0' + date.getMinutes()).slice(-2);
          // YYYY-MM-DD HH:MM 형식으로 반환
          return `${year}-${month}-${day} ${hour}:${minute}`;
      },

      async loadschedule(){

          //토큰값 체크
          let token;
          try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          
          this.$http.post(this.$host+'/MyScheduleDetail',{
                MSCHD_IDX: this.MSCHD_IDX
              , LOCALID:this.SEARCH_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                
                const list = result.data.resultData;
                this.events.splice(0, this.events.length);
                list.forEach(element => {
                  
                  const event = {   id:element.EVENT_IDX
                                  , start: this.$momenttz.utc(element.START).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm")
                                  , end:  this.$momenttz.utc(element.END).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") 
                                  , content_type:element.CONTENT_TYPE 
                                  , content_idx: element.CONTENT_TYPE == 0 ? element.MCON_IDX : element.MCGROUP_IDX
                                  , content: element.CONTENT
                                };
                  console.log("add event " + JSON.stringify(event));
                  this.events.push(event);
                });
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        
        //컨텐츠 리스트 미리 읽어오기
        this.loadmycontent();
        
        //스케줄 읽어오기
        this.loadschedule();

      },      

              

      //다이얼로그 닫기
      close () {
        this.dialog         = false;
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.swal2-container {
  z-index: 9999 !important;  /* zIndex 설정 */
}
.clickcursor{
  cursor: pointer;
}

</style>

<style lang="scss">
  .vuecal__event { background-color:rgba(108, 0, 250, 0.486) !important} //이벤트 기본 배경색
</style>