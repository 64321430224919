<template>
    <template v-if="contentStore.select_component != null && (contentStore.select_component.model.component_type == 0 || contentStore.select_component.model.component_type == 1 
    || contentStore.select_component.model.component_type == 20 || contentStore.select_component.model.component_type == 40)">

    <v-container fluid class="ma-0 pt-0">
    
        <!-- API 사용선택 -->
        <v-row align="center" dense>
            <v-col cols="12">
                <v-switch label="이벤트 사용" color="red"  hide-details v-model="this.contentStore.select_component.model.event_use"></v-switch>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" dense class="mt-0 pt-0" v-if="this.contentStore.select_component.model.event_use">

            <v-row class="pt-0 ma-0" align="center" justify="center" dense>
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">EVENT NAME</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <input 
                        v-model="contentStore.select_component.model.event_name" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                </v-col>
            </v-row>

            <v-row class="pt-0 ma-0" align="center" justify="center" dense>
                <v-col cols="12">
                    <label style="font-size:12px">EVENT TYPE</label>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <select 
                        v-model="contentStore.select_component.model.event_type" @change="changeeventtype"
                        style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                        <template v-for="cptype in eventtypelist" :key="cptype.name">
                            <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                        </template>
                    </select>
                </v-col>
            </v-row>


            <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="this.contentStore.select_component.model.event_type == 1">
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">Move Page Name</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <select 
                        v-model="contentStore.select_component.model.event_move"
                        style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                        <template v-for="cptype in eventnamelist" :key="cptype">
                            <option style="font-size:14px" :value="cptype">{{cptype}}</option>
                        </template>
                    </select>
                    <!-- 
                    <input 
                        v-model="contentStore.select_component.model.event_move" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                    -->
                </v-col>
            </v-row>

            <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="this.contentStore.select_component.model.event_type == 2">
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">Api Name</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <select 
                        v-model="contentStore.select_component.model.event_api"
                        style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                        <template v-for="cptype in eventnamelist" :key="cptype">
                            <option style="font-size:14px" :value="cptype">{{cptype}}</option>
                        </template>
                    </select>
                    <!-- 
                    <input 
                        v-model="contentStore.select_component.model.event_api" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                    -->
                </v-col>
            </v-row>

            <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="(this.contentStore.select_component.model.event_type == 3 || this.contentStore.select_component.model.event_type == 6)">
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">Type Key</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <input 
                        v-model="contentStore.select_component.model.event_input_key" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                </v-col>
            </v-row>
            <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="this.contentStore.select_component.model.event_type >= 3 && this.contentStore.select_component.model.event_type <= 6">
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">Target Event Name</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <select 
                        v-model="contentStore.select_component.model.event_input_target"
                        style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                        <template v-for="cptype in eventnamelist" :key="cptype">
                            <option style="font-size:14px" :value="cptype">{{cptype}}</option>
                        </template>
                    </select>
                    <!-- 
                    <input 
                        v-model="contentStore.select_component.model.event_input_target" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                    -->
                </v-col>
            </v-row>

            <v-row class="pt-0 ma-0" align="center" justify="center" dense v-if="this.contentStore.select_component.model.event_type == 20">
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">Target Name</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <select 
                        v-model="contentStore.select_component.model.event_input_target"
                        style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                        <template v-for="cptype in eventnamelist" :key="cptype">
                            <option style="font-size:14px" :value="cptype">{{cptype}}</option>
                        </template>
                    </select>
                    <!-- 
                    <input 
                        v-model="contentStore.select_component.model.event_api" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                    -->
                </v-col>
            </v-row>

            
        </v-row>

        <v-row dense class="mt-3 mb-10"> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import Component_Model from '@/nextts/model/Component_Model';

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    changeeventtype(){
        
        if(this.contentStore.select_component == null || this.contentStore.select_component.model == null) return;

        if(this.contentStore.select_component.model.event_type == 0){
            //none
            this.eventnamelist.splice(0, this.eventnamelist.length);

        }else if(this.contentStore.select_component.model.event_type == 1){
            //page move
            this.eventnamelist.splice(0, this.eventnamelist.length);
            this.eventnamelist = this.contentStore.content_model.screen_list
            .filter(item => item.screen_name != null && item.screen_name.length > 0)
            .map(item => item.screen_name);

        }else if(this.contentStore.select_component.model.event_type == 2){
            //api call
            this.eventnamelist.splice(0, this.eventnamelist.length);
            this.eventnamelist = this.contentStore.select_screen.component_list
            .filter(item => item.component_type === Component_Model.COMPONENT_API && item.apiname != null && item.apiname.length > 0)
            .map(item => item.apiname);

        }else if(this.contentStore.select_component.model.event_type >= 3 && this.contentStore.select_component.model.event_type <= 6){
            //key input
            this.eventnamelist.splice(0, this.eventnamelist.length);
            this.eventnamelist = this.contentStore.select_screen.component_list
            .filter(item => item.component_type === Component_Model.COMPONENT_TEXT && item.event_name != null && item.event_name.length > 0)
            .map(item => item.event_name);

        }else if(this.contentStore.select_component.model.event_type == 20){
            //sound
            this.eventnamelist.splice(0, this.eventnamelist.length);
            this.eventnamelist = this.contentStore.select_screen.component_list
            .filter(item => item.component_type === Component_Model.COMPONENT_SOUND && item.event_name != null && item.event_name.length > 0)
            .map(item => item.event_name);
        }

    },

  },

  data(){
    return{

        eventtypelist:[
                {name:"None",       value:0}
            ,   {name:"Page Move",  value:1}
            ,   {name:"Api Call",   value:2}
            ,   {name:"Type",       value:3}
            ,   {name:"Type back",  value:4}
            ,   {name:"Type clear", value:5}
            ,   {name:"Type Replace", value:6}
            ,   {name:"Play Sound", value:20}
        ],

        eventnamelist:[],

    }
  },

  mounted(){
    console.log("cominfo event mounted");
  },

  activated(){
    console.log("cominfo event activated");
  },

  beforeUpdate(){
    console.log("cominfo event beforeUpdate");
  },

  updated(){
    console.log("cominfo event updated");
    this.changeeventtype();
  }
  

  
}
</script>