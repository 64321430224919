<template>
  <v-container fluid class="ma-0 pa-0" style="background:white;min-height:100%;overflow:hidden">
    <!-- 프로그레스 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="loading" max-width="300px">
        <v-container class="d-flex flex-column justify-center align-center" 
                     style="background-color:white;width:300px;height:300px">
          <v-row class="ma-8">
            <v-progress-circular
              :size="100"
              :width="15"
              color="#c53d45"
              indeterminate
              >
            </v-progress-circular>
          </v-row>
          <v-row class="ma-5">
            <label style="text-align:center">잠시만 기다려주세요</label>
          </v-row>
        </v-container >
      </v-dialog>
    </v-row>

    <v-row align="center" justify="center" class="mt-15">
      <v-col cols="12" md="4" align="center">
        <img  width="240" style="hegiht:auto" :src="previewImg1" class="image">
      </v-col>
    </v-row>

    <v-row align="center" justify="center">

      <v-col class="mt-5" cols="12" md="4" sm="10" xs="10">
        <div class="roundbg text-center">  

            <p id="login_title" style="color:#9618fe;font-size:20px;" class="text-center" v-if="editedItem != null">{{ editedItem.NAME }}</p>

            <v-row justify="center" dense class="mt-2">
              <v-col ccols="12">
                <v-row style="height:1px;background:#9618fe"/>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-5">
              <v-col cols="12">
                <v-row dense align="center" justify="center">
                  <v-col cols="10">
                    <v-btn variant="outlined" block @click="googlesign" class="google_btn">
                      <template v-slot:prepend>
                        <v-img src="@/assets/googlelogo.png" contain width="25"/>
                      </template>
                      Google 계정으로 로그인
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense align="center" justify="center" class="mt-5">
              <v-col cols="12">
                <v-row dense align="center" justify="center">
                  <v-col cols="10">
                    <v-btn variant="outlined" block @click="runjoin" class="google_btn">
                        회원가입
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

        </div>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
export default {

    mounted() {

      //해당 페이지는 URL로 들어오는 서브로그인 페이지임. 메뉴에서 진입하면 안됨. pidx값을 url에 넣어서 와야함.

      this.pidx = this.$route.query.pidx;
      console.log("pidx : " + this.pidx);
      if(this.pidx == undefined || this.pidx == null){
        //키값 없으면 로그인으로
        this.$alert.fire("잘못된 접근입니다.");
        //this.$router.back();
        return;
      }
      
      this.checkbox = localStorage.getItem("SAVE_ID");
      if(this.checkbox){
        this.user_id = localStorage.getItem("LOGIN_ID");
      }

      //업체정보 읽어오기
      this.loadpage();

    },

    data: () => ({
        user_id : ""
      , user_pw : ""
      , loading:false
      , checkbox:false
      , editedItem:null
      , previewImg1:null
      , pidx:null
    }),

    methods: {
      
      //구글 로그인
      async googlesign(){
        const provider = new GoogleAuthProvider();
        try {
          const result = await signInWithPopup(this.$fireauth, provider);
          //console.log("googlesign : " + JSON.stringify(result));
          //로컬아이디를 보내서 클레임 갱신
          this.createCustomtoken(result.user.uid, result.user.email, result.user.displayName);
        } catch (error) {
          console.error(error);
        }        
      },


      //서브계정 구글계정으로 로그인 => 서버에서 커스텀 클레임 셋팅
      createCustomtoken(localid, email, name){

          this.$http.post(this.$host+'/setCustomClaims',{
            LOCALID:localid,
            EMAIL:email,
            NAME: escape(name),
            PARENT_LOCALID: this.pidx //상위업체 코드
          })
          .then(async (result)=>{
            if(result.data.resultCode == 0){
              //정상적으로 클레임을 셋팅했으면 강제로 키를 갱신한다. (클레임이 키에 반영되서 나옴.)
              await this.$fireauth.currentUser.getIdToken(true);
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            //console.log("createToken error : " + error);
            this.$alert.fire("로그인에 실패했습니다. 다시 시도하세요.");
            this.logout2();
          });
          
      },                

      loadpage(){   
        this.$http.post(this.$host+'/SubAcntDetail',{
          PIDX: this.pidx
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
            const list = result.data.resultData;
            if(list.length > 0){
              this.editedItem = list[0];
              this.previewImg1 = this.editedItem.LOGO_IMG != null && this.editedItem.LOGO_IMG.length > 0 ? this.editedItem.LOGO_IMG : "";
            }else{
              this.$alert.fire('존재하지 않는 기업입니다.');
              this.$emitter.emit('clearsession');
            }
          }else{
            this.$alert.fire('존재하지 않는 기업입니다.');
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });
        
      },

      checkchange(value){
        //console.log(value);
        if(value){
          localStorage.setItem("SAVE_ID", true);
        }else{
          localStorage.setItem("SAVE_ID", false);
        }
      },

      runjoin(){
        //회원가입 팝업 표시
        this.$router.push({path:"/Join", query:{pidx:this.pidx}});
      },

      runpass(){

        if(this.user_id.length < 1){
          this.$alert.fire('비밀번호를 찾기 위한 이메일 계정을 입력하세요');
          return;
        }

        //인증코드 재발송
        this.$alert.fire("비밀번호를 잊으셨나요?\n가입하신 이메일로 비밀번호를 찾기위한 인증메일을 발송 하시겠습니까?")
        .then((aresult)=>{
          if(aresult.isConfirmed){
            this.$http.post(this.$host+'/resetpassword',{
                ACCNT: this.user_id
            })
            // eslint-disable-next-line no-unused-vars
            .then((result)=>{

              if(result.data.resultCode == 0){
                this.$alert.fire("비밀번호 찾기 인증메일이 발송되었습니다.\n인증메일을 확인하세요");
              }else{
                alert(result.data.resultMsg);      
              }
            });
          }
        })        
      },


      async login(){

          if(this.user_id.length < 1){
            this.$alert.fire('계정을 입력하세요');
            return;
          }
          if(this.user_pw.length < 1){
            this.$alert.fire('비밀번호를 입력하세요');
            return;
          }
          if(this.user_pw.length < 6){
              this.$alert.fire("비밀번호는 최소 6글자 이상입니다.");
              return;
          }

          localStorage.setItem("LOGIN_ID", this.user_id);

          //파이어베이스 로그인처리
          await signInWithEmailAndPassword(this.$fireauth, this.user_id, this.user_pw)
          .then(async (result) => {
            //console.log("signInWithEmailAndPassword result : " + JSON.stringify(result.user));
            if(!result.user.emailVerified){
              //인증코드 재발송
              this.$alert.fire({showCancelButton: true, title:'이메일 인증이 확인되지 않은 계정입니다.\n이메일 인증코드를 재발송 하시겠습니까?'})
              .then((aresult) => {
                if(aresult.isConfirmed){
                  this.$http.post(this.$host+'/sendemail',{
                      USER_ID: this.user_id
                    , USER_PW: Buffer.from(this.user_pw).toString('base64')
                  })
                  // eslint-disable-next-line no-unused-vars
                  .then((result2)=>{
                    //console.log("send email : " + JSON.stringify(result2));
                    if(result2.data.resultCode == 0){
                      this.$alert.fire("인증메일이 발송되었습니다.\n인증메일을 확인하세요");
                    }else{
                      alert(result2.data.resultMsg);      
                    }
                  });
                }
              });
              return;
              
            }else{
              //여기서 커스텀 토큰생성 호출안해도 App.vue에 등록된 리스너에서 로그인을 감지해서 idtoken을 발급받음.
              //this.createCustomtoken(result.user.uid);
              //console.log("login complete : " + JSON.stringify(result));
            }
          })
          .catch((error)=>{
            console.error('로그인 실패:' + error);
            if(error.code == 'auth/missing-email'){
              this.$alert.fire('로그인 오류, 이메일주소를 확인하세요');
            }else if(error.code == 'auth/wrong-password'){
              this.$alert.fire('로그인 오류, 패스워드를 확인하세요');
            }else if(error.code == 'auth/user-disabled'){
              this.$alert.fire('로그인 오류, 관리자가 사용자 계정을 비활성화했습니다.');
            }else if(error.code == 'auth/user-not-found'){
              this.$alert.fire('로그인 오류, 사용자를 찾을 수 없습니다.');
            }else{
              this.$alert.fire('로그인 오류, 로그인 할 수 없습니다.');
            }
            
          });


      },

    },
    created(){

      if(this.$storage.isExpire("LOCALID")){
        //키값이 있으면 로그인이 이미 된 경우
        this.$router.push('/MyPage');  
      }

    }


}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

.v-main__wrap{
  background: white;
}

.roundbg{
  border-radius: 25px;
  background: #ffffff;
  padding: 20px;
  padding-bottom: 30px;
}


</style>