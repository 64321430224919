
<!-- 
 스크린에 셀렉터와 무브어블을 안쓰고 인피니티 뷰어를 스크롤하는 방식 
 지금은 EditWindow에 이식되어서 고도화 됨. 사용안함.
-->

<template>
  <v-container fluid class="pa-0 ma-0">

  <div style="width:100%;height:50px">
    <v-btn @click="screenadd_test">스크린추가</v-btn>
    <v-btn @click="randadd">랜덤추가</v-btn>
    <v-btn @click="cleanall">초기화</v-btn>
    <v-btn @click="addcomponent">추가</v-btn>
    <v-btn @click="testcopy">클론</v-btn>
    <v-btn @click="creategroup">그룹</v-btn>
    <v-btn @click="removegroup">그룹해제</v-btn>
    <v-btn @click="zoomin">확대</v-btn>
    <v-btn @click="zoomout">축소</v-btn>
    <v-btn @click="save2">컨텐츠 저장(DB)</v-btn>
    <v-btn @click="load2">컨텐츠 로드(DB)</v-btn>
    <v-btn @click="test">TEST</v-btn>
  </div>

    
    <div id="editarea" ref="editarea" class="editerSize infinite-viewer" >
      <div id="editdiv" ref="editdiv" class="editerDivSize viewport selecto-area" style="background:gray;"/>
    </div>
    
  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import Moveable from 'moveable';
import { getElementInfo } from 'moveable';
import Selecto from "selecto";

import Component_Model from '@/nextts/model/Component_Model'
import Screen_Model from '@/nextts/model/Screen_Model'

// eslint-disable-next-line no-unused-vars
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'
// eslint-disable-next-line no-unused-vars
import EditBasic_ScreenFixed from '@/nextts/edit/EditBasic_ScreenFixed'

// eslint-disable-next-line no-unused-vars
import { reactive, ref } from '@vue/reactivity';

import InfiniteViewer from "infinite-viewer";

export default {

  setup(){
    const editarea        = ref();
    const editdiv         = ref();
    const contentStore    = useContentStore();
    return {editarea, editdiv, contentStore};
  },

  data:()=>({

    windowWidth:window.innerWidth-503,
    windowHeight:window.innerHeight-50,

    //스크린 내부 컴포넌트 이동/선택
    cm_moveable:null,
    cm_selecto:null,
    cm_targets:[],       //selecto 라이브러리를 통해 선택된 컴포넌트들
    
    infiniteViewer:null,

    frameskip: 0,
    zoom : 1.0,

    undolist:[],
    redolist:[],
    copylist:[],
    
    isSpacebar:false,


  }),


  beforeUnmount(){
    //window.removeEventListener('resize', this.resizeevent);
    removeEventListener('resize', this.resizeevent);
    removeEventListener('keydown', this.onkeydown);
    removeEventListener('keyup', this.onkeyup);
    removeEventListener('wheel', this.onwheelevent);

    this.$emitter.off('drawscreen');
  },


  mounted(){

    //resize 이벤트 설정 (리사이즈시 에디트 윈도우 사이즈 조절)
    addEventListener('resize', this.resizeevent);
    addEventListener('keydown', this.onkeydown);
    addEventListener('keyup', this.onkeyup);
    addEventListener('wheel', this.onwheelevent);

    //이거 써서 이벤트 전달을 중단시키면 드래그 앤 드랍에 영향받음. 조심해야함.
    //onmousemove = this.onmousemove;
    
    //전역 이벤트버스, 스크린 그리기
    this.$emitter.on('drawscreen', this.drawscreen);
    
    this.setMoveSelecForComponent();
    
    const viewer    = document.querySelector(".infinite-viewer");
    this.infiniteViewer = new InfiniteViewer(
        viewer,
        document.querySelector(".viewport"),
        {
          useMouseDrag: false,
          useWheelScroll: true,
          useAutoZoom: true,
          zoomRange: [0.1, 10],
          maxPinchWheel: 10,          
        }
    );

    this.infiniteViewer.on("scroll", () => {
        console.log(this.infiniteViewer.getScrollLeft(), this.infiniteViewer.getScrollTop());
    });

  },


  methods:{

/********************************************************************************************************/
// 이벤트
/********************************************************************************************************/



    //컨트롤 누른상태로 마우스 휠 조작시 줌인/줌아웃
    onwheelevent(e){
      if(e.ctrlKey) {
        //스크롤을 내리면 e.deltaY가 양수
        if(e.deltaY > 0){
          //확대
          this.zoomin();
        }else{
          //축소
          this.zoomout();
        }
      }
    },

    resizeevent(){
      
      this.windowHeight = window.innerHeight-50;
      console.log("resize : " + window.innerHeight);
      //this.windowWidth  = window.innerWidth-503;
    },

    onkeyup(ev){
      if(ev.keyCode == 32){

        this.isSpacebar = false;
        this.infiniteViewer.useMouseDrag = false;
        
        //셀렉토랑 무브어블 복원
        this.setMoveSelecForComponent();

        if(this.cm_targets != null && this.cm_targets.length > 0){
          //기존에 컴포넌트를 선택했던 타겟이 0보다 크면 다시 컴포넌트 모드로 돌아가야한다.
          this.cm_moveable.target = this.cm_targets;
          this.$emit('selectComponent', this.cm_moveable.target);
        }
      
      }
    },

    onkeydown(ev){
      //console.log("key : " + ev.key + " / "+ev.keyCode);

      if(ev.ctrlKey){
          if(ev.keyCode == 90){         //Ctrl+Z undo
            console.log('undo...');
            this.runundo();

          }else if(ev.keyCode == 89){   //Ctrl+Y redo
            console.log('redo...');
            this.runredo();

          }else if(ev.keyCode == 67){   //Ctrl+c copy
            console.log('copy...');
            this.saveCopy();

          }else if(ev.keyCode == 86){   //Ctrl+V Paste
            console.log('paste...');
            this.runPaste();

          }
      }else if(ev.keyCode == 32){
        //spacebar 누르고 있으면 일단 스크린 이동 모드로 전환시켜라.
        if(!this.isSpacebar){
          //컴포넌트 모드인가
          this.cm_targets = this.cm_moveable.target;
          //this.switchEditScreenMode();
          this.isSpacebar = true;

          //컴포넌트용 moveable, selecto 제거
          this.infiniteViewer.useMouseDrag = true;
          if(this.cm_moveable != null) this.cm_moveable.destroy();
          if(this.cm_selecto != null) this.cm_selecto.destroy();
          this.cm_moveable = null;
          this.cm_selecto  = null;

        }
        ev.preventDefault();
      }
    },    


/********************************************************************************************************/
// 편집
/********************************************************************************************************/

   
    //스크린과 컴포넌트 편집 모드를 스위치
    switchEditMode(){
      console.log("switchEditMode...");
    },



    setMoveSelecForComponent(){

      //selecto - 드래그 앤 드랍으로 컴포넌트 선택해주는 라이브러리
      const viewer    = document.querySelector(".infinite-viewer");
      
      
      this.cm_selecto = new Selecto({
          viewer,
          dragContainer: viewer,          //셀렉트 드래그가 가능한 영역
          selectableTargets: [".drag"],     //선택할 수 있는 타겟
          hitRate: 0,
          selectByClick: true,              //클릭으로 선택
          selectFromInside: false,
          toggleContinueSelect: ["shift"],  //쉬프트로 추가 선택/해제
          ratio: 0,
          getElementRect: getElementInfo,
      });
      
      this.setMoveable();

      this.cm_selecto.on("dragStart", e => {
          if(this.cm_moveable == null || this.cm_targets == null || this.isSpacebar){
            e.inputEvent.preventDefault();
            return;
          }
          const target = e.inputEvent.target;
          if (
              this.cm_moveable.isMoveableElement(target)
              || this.cm_targets.some(t => t === target || t.contains(target))
          ) {
              e.stop();
          }
      }).on("select", e => {

        console.log("select component...");

        //space바를 누르고 있다면 무시
        if(this.cm_moveable == null || this.isSpacebar){
          e.inputEvent.preventDefault();
          return;
        }
        
        var selectlist = new Set();
        var elements            = this.cm_selecto.getSelectedTargets();
        elements.forEach(target=>{
            if(target.model.isgroup){
              var gtarget = [].slice.call(document.querySelectorAll("."+target.model.groupid));
              gtarget.forEach(gt=>{
                selectlist.add(gt);
              });
            }else{
              selectlist.add(target);
            }
        });        
        const sl = Array.from(selectlist);
        console.log("selectlist : " + sl.length);
        if(sl.length == 1){
          //단일건을 이렇게 안넣으면 버그가 발생함. 한템포 뒤에 모델이 셋팅됨.
          this.cm_targets         = elements;
          this.cm_moveable.target = elements;
          this.$emit('selectComponent', elements);
        }else if(sl.length > 1){
          this.cm_moveable.target = sl;
          this.cm_targets         = sl;
          this.$emit('selectComponent', sl);
        }else{

          //아무것도 없는 상태 - 스크린 배경을 선택시켜라.
          console.log("select screen bg...");
          //this.cm_targets = [];
          //this.cm_moveable.target = null;
          //this.$emit('selectComponent', elements);

        }
        
        

      }).on("selectEnd", e => {
        
        if(this.isSpacebar){
          e.inputEvent.preventDefault();
          return;
        }
        
        if(e.isDragStart) {
          console.log("selectEnd");
            e.inputEvent.preventDefault();
            setTimeout(() => {
              this.cm_moveable.dragStart(e.inputEvent);
            });
        }else{
          this.cm_targets = e.selected;
          this.cm_moveable.target = this.cm_targets;
          console.log("selectEnd2");
        }
        

      });    




    },

    zoomin(){
      this.zoom = this.zoom+0.1 <= 10 ? this.zoom+0.1 : 10;
      //인피니티 뷰어의 줌을 조절한다. => 문제는 moveable에서 줌값 오류 발생함.
      
      this.infiniteViewer.setZoom(this.zoom);


      //this.editdiv.style.transform = 'scale('+this.zoom+')';


      //자식 screen 뷰를 모두 가져와서 스케일 처리
      /* moveable이 확대축소 안됨.
      const screens  = document.querySelectorAll(".screen");
      screens.forEach((child, index)=>{
        child.style.transform = 'scale('+this.zoom+')';
      });
      */
      
      

      /*
      if(this.screen_targets != null && this.screen_targets != undefined){
        this.screen_targets[0].setZoom(this.zoom);
        this.screen_moveable.updateTarget();
      }
      */

    },

    zoomout(){
      this.zoom = this.zoom-0.1 > 0.2 ? this.zoom-0.1 : 0.2;
      
      //this.editdiv.style.transform = 'scale('+this.zoom+')';
      
      /* moveable이 확대축소 안됨.
      const screens  = document.querySelectorAll(".screen");
      screens.forEach((child, index)=>{
        child.style.transform = 'scale('+this.zoom+')';
      });
      */

      //인피니티 뷰어의 줌을 조절한다. => 문제는 moveable에서 줌값 오류 발생함.
      this.infiniteViewer.setZoom(this.zoom);
      
      /*
      if(this.screen_targets != null && this.screen_targets != undefined){
        this.screen_targets[0].setZoom(this.zoom);
        this.screen_moveable.updateTarget();
      }
      */
      
    },

    save2(){
      
      var temp_obj  = JSON.parse('{"data":[]}');
      const edit_div = document.getElementById("edit_div");
      edit_div.childNodes.forEach(child=>{
        if(child.model){
          console.log("model : " + JSON.stringify(child.model));
          temp_obj["data"].push(child.model);
        }else{
          console.log("not found model...");
        }
      });

      this.$http.post(this.$host+'/UpdateTest',{
        COMPONENTS:escape(JSON.stringify(temp_obj))
      })
      .then((result)=>{
        if(result.data.resultCode == 0){
          console.log("update complete");
        }else{
          console.log("update error");
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        console.log("update error : " + error);
      });
    },

    load2(){
      this.$http.post(this.$host+'/SelectTest',{
      })
      .then((result)=>{
        if(result.data.resultCode == 0){
            const list = result.data.resultData;
            list[0].COMPONENTS.data.forEach(model=>{

              const cm = new Component_Model("editwindow load2");
              cm.isgroup  = model.isgroup;
              cm.groupid  = model.groupid;
              cm.component_type = model.component_type;
              cm.width    = model.width;
              cm.height   = model.height;
              cm.posx     = model.posx;
              cm.posy     = model.posy;
              cm.rotate   = model.rotate;
              cm.transx   = model.transx;
              cm.transy   = model.transy;

              this.addcomponentFrame(cm);
            });
            
        }else{
          console.log("select error");
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        console.log("error : " + error);
      });
    },


    saveCopy(){
      var elements = this.screen_selecto.getSelectedTargets();
      this.copylist.splice(0, this.copylist.length);  //기본카피 제거
      elements.forEach(element=>{
        var clone_model     = Object.assign({}, element.model);           //데이터 카피
        this.copylist.push(clone_model);        
      });
    },

    
    saveUndo(){
      //컨텐츠 모델 전체를 스냅샷을 찍는다.
      this.contentStore.saveSnapshot();
    },

    //언두 실행
    runundo(){
      console.log("undo");
      //select 스크린 인덱스 가져오기
      const select_idx = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.contentStore.undo();
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[select_idx];
      this.drawscreen();
    },
    
    //리두 실행
    runredo(){
      console.log("redo");
      const select_idx = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.contentStore.redo();
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[select_idx];
      this.drawscreen();
    },


    creategroup(){
      //선택된 컴포넌트에 그룹아이디를 매긴다.
      var elements = this.cm_selecto.getSelectedTargets();
      console.log("select elements len : " + elements.length);
      
      if(elements.length > 1){
        var curdate = new Date();
        var gid = "gid_" + curdate.getMilliseconds();

        elements.forEach(element=>{
          //그룹 표기
          element.model.isgroup = true;
          element.model.groupid = gid;
          element.classList.add(gid);  //셀렉트를 위한 추가
        });
        var target = document.getElementsByClassName(gid);
        this.cm_moveable.target = target;
      }

    },

    removegroup(){
      //그룹해제
      var elements = this.cm_selecto.getSelectedTargets();
      console.log("remove group len : " + elements.length);
      elements.forEach(element=>{
        if(element.model.isgroup){
          var group_element = document.getElementsByClassName(element.model.groupid);
          for(var i=0;i<group_element.length;i++){
            console.log("remove group...");
            var ge = group_element[i];
            ge.classList.remove(ge.model.groupid);
            ge.model.isgroup = false;
            ge.model.groupid = "";
          }
        }
      });
      this.cm_moveable.target = elements[0];
      
    },

    test(){
      //현재 스크린의 좌표를 찍어볼까.
      //const editarea  = document.getElementById("editarea");
      //console.log("editarea transform : " + editarea.style.transform + " / zoom : " + this.zoom + " / infinite zoom : " + this.infiniteViewer.getZoom());
      //this.infiniteViewer.scrollTo(100, 100);

    },


    //스크린모델 그리기
    drawscreen(){
      
      //기존에 그려진 스크린 클리어
      this.cleanall();

      
      //선택된 스크린 모델로 객체 생성
      console.log("drawscreen : " + this.contentStore.select_screen.width + " / " + this.contentStore.select_screen.height);
      const screen = new EditBasic_ScreenFixed(reactive( this.contentStore.select_screen ), this.switchEditMode);
      screen.setZoom(1); 

      const editdiv = document.getElementById("editdiv");
      editdiv.appendChild(screen);
      

      //this.screenadd_test();

    },



    screenadd_test(){
      //스크린 추가
      var width  = 1920;
      var height = 1080;
      var rotate = 0;
    
      let screen_model    = new Screen_Model();
      screen_model.width  = width;
      screen_model.height = height;
      screen_model.rotate = rotate;
      
      const screen = new EditBasic_ScreenFixed(reactive(screen_model), this.switchEditMode);
      screen.setZoom(1); 
      screen.randadd();     //랜덤으로 컴포넌트 5개 추가

      const editdiv = document.getElementById("editdiv");
      editdiv.appendChild(screen);

      //this.setMoveSelecForComponent();

    },
    
    //moveable 셋팅
    setMoveable(){

      //moveable - 이동, 회전, 리사이즈 에디터 라이브러리
      //this.screen_targets[0]
      
      const editdiv = document.getElementById("editdiv"); //매트릭스때문에 screen_targets은 잡으면 안됨.
      this.cm_moveable = new Moveable(editdiv, {
        
        draggable: true,   //스크린과 교차로 써야되기 때문에 초기에 false줌.
        rotatable: true,
        resizable: true,
        scalable: true,

        origin: false,      //중심부 렌더링
        keepRatio: false,   //리사이즈시 비율 유지 유무
          
        snappable: true,
        elementGuidelines:[document.querySelector(".drag")],
        //verticalGuidelines: [0,100,1080],
        //horizontalGuidelines: [0,100,1920],
        snapThreshold: 5,
        isDisplaySnapDigit: true,
        snapGap: true,
        snapDirections: {"top":true,"right":true,"bottom":true,"left":true},
        elementSnapDirections: {"top":true,"right":true,"bottom":true,"left":true},
        snapDigit: 0,      
        //bounds: {"left":0,"top":0,"right":1920,"bottom":1080},
      }).on("dragStart", e=>{
        e.set([e.target.model.transx, e.target.model.transy]);      

      }).on("drag", e => {

        //left, top은 이동한 만큼의 좌표이고 beforeTranslate는 부모뷰를 기준으로 절대 좌표다.
        //console.log("drag: " + e.left + " / " + e.top + " / " + e.beforeTranslate[0] + " / " + e.beforeTranslate[1]);
        e.target.model.transx = e.beforeTranslate[0];
        e.target.model.transy = e.beforeTranslate[1];
        e.target.model.posx   = e.beforeTranslate[0];
        e.target.model.posy   = e.beforeTranslate[1];
        e.target.updateTransElement();

      }).on("dragEnd", e => {
        //undo 추가
        console.log("dragEnd");
        
        this.saveUndo(e);

      }).on("rotateStart", e => {
        e.set(e.target.model.rotate);

      }).on("rotate", e => {
        e.target.model.rotate = e.beforeRotate;
        e.target.updateTransElement();

      }).on("resizeStart", e => {
        e.setOrigin(["%", "%"]);
        if (e.dragStart) {
          e.dragStart.set([e.target.model.transx, e.target.model.transy]);
        }
      }).on("resize", e => {
        console.log("resize : " + e.direction); //8포인트 방향 알 수 있음

        e.target.model.width  = e.width;
        e.target.model.height = e.height;
        e.target.model.transx = e.drag.beforeTranslate[0];
        e.target.model.transy = e.drag.beforeTranslate[1];

        //width와 height값이 중요하다. 이 정보가 있어야 나중에 복원이 가능함 매트릭스는 공간에 대한 변형 정보라 넓이, 높이 정보가 없음. 그래서 넓이, 높이 + 매트릭스 둘 다 가지고 있어야 함.
        e.target.updateTransElementAll();

      }).on("scale", e => {
        //스케일을 쓸필요가 있나? ratio를 쓰면 될거 같은데?
        console.log("scale : " + e.scale[0] + " / " + e.scale[1]);

      }).on("dragGroupStart", e => {
        
        e.events.forEach(({ target, set }) => {
          set([target.model.transx, target.model.transy]);
        });
      
      }).on("dragGroup", e => {
        //left, top은 이동한 만큼의 좌표이고 beforeTranslate는 부모뷰를 기준으로 절대 좌표다.
          e.events.forEach(({ target, beforeTranslate }) => {
            target.model.transx = beforeTranslate[0];
            target.model.transy = beforeTranslate[1];
            target.model.posx   = beforeTranslate[0];
            target.model.posy   = beforeTranslate[1];
            target.updateTransElement();
          });

      }).on("dragGroupEnd", e => {        
        /*        
        e.events.forEach(({ target }) => {
          console.log("dragGroupEnd : " + JSON.stringify(target));
        });
        */
       console.log("dragGroupEnd...");
        this.saveUndo(e);

      }).on("rotateGroupStart", e => {
        console.log("rotateGroupStart...");
        e.events.forEach(({target, set, dragStart}) => {
          set(target.model.rotate);
          dragStart.set([target.model.transx, target.model.transy]);
        });

      }).on("rotateGroup", e => {
        e.events.forEach(({ target, beforeRotate, drag }) => {
          const beforeTranslate = drag.beforeTranslate;
          target.model.rotate = beforeRotate;
          target.model.transx = beforeTranslate[0];
          target.model.transy = beforeTranslate[1];
          target.updateTransElement();
        });

      }).on("resizeGroupStart", e => {
        e.events.forEach((ev) => {
          ev.setOrigin(["%", "%"]);
          if(ev.dragStart){
            ev.dragStart.set([ev.target.model.transx, ev.target.model.transy]);
          }
        });

      }).on("resizeGroup", e => {
        e.events.forEach((ev) => {
          ev.target.model.width   = ev.width;
          ev.target.model.height  = ev.height;
          ev.target.model.transx  = ev.drag.beforeTranslate[0];
          ev.target.model.transy  = ev.drag.beforeTranslate[1];
          ev.target.updateTransElementAll();
        });

      }).on("clickGroup", e => {
        console.log("click group...");
        this.cm_selecto.clickTarget(e.inputEvent, e.inputTarget);
        
      });

    },    

    randadd(){
      for (let i = 0; i <= 5; ++i) {
        this.addcomponent();
      }      
    },


    addcomponent(){

        //컴포넌트 모델 생성 (크기, 위치 정보)
        const posx = Math.floor(Math.random() * 700)+50;
        const posy = Math.floor(Math.random() * 560)+50;
        //const rotate = Math.floor(Math.random() * 360);
      
        const tempcm    = new Component_Model("editwindow addcomponent");
        tempcm.width  = Math.floor(Math.random() * 150)+50;
        tempcm.height = Math.floor(Math.random() * 150)+50;
        tempcm.posx   = posx;
        tempcm.posy   = posy;
        //tempcm.rotate = rotate;

        tempcm.rotate = 0;
        tempcm.transx = posx;
        tempcm.transy = posy;
        
        const edit_el = new EditBasic_Element(reactive(tempcm));

        const editdiv = document.getElementById("editdiv");
        editdiv.appendChild(edit_el);
        
        //this.screen_model.component_list.push(tempcm);
  
    },

    
    //초기화
    cleanall(){
      const editdiv = document.getElementById("editdiv");
      while(editdiv.hasChildNodes()){
        editdiv.removeChild(editdiv.firstChild);
      }
    },



  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.editerSize{
  width:100% !important;
  position: fixed;
  top:120px;
  bottom:36px;
  left:0px;
  right:0px;
  background:gray;
  overflow:hidden;
}
.editerDivSize{
  display: flex;
  flex-direction: column; /* 세로배치 */
  background:gray;
}

.moveable-control {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
    box-sizing: border-box;
    background: #fd3434;
    margin-top: -7px;
    margin-left: -7px;
    z-index: 10;
}
</style>