<!-- eslint-disable vue/no-mutating-props -->
<template v-if="contentStore.select_component != null">
  <v-row justify="center" align="center" class="mt-0 pt-0">
    <v-col cols="7" align="center" class="pa-0 ma-0">
        <label style="font-size:14px">{{ title }}</label>
    </v-col>
    <v-col cols="5" class="pa-0 ma-0">
        <input 
            v-model="object[property]" 
            @change="changecallback" @keydown="keydownHandler"
            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center;"
            />
    </v-col>
    <v-col cols="11" class="pa-0 ma-0">
        <v-slider
            v-model="object[property]"
            @update:modelValue="changecallback"
            color="orange"
            :min="min"
            :max="max"
            :step="step"
            track-color="green"
            thumb-color="purple"
            :thumb-size="10"
            hide-details
            style="min-height:20px"
            />
    </v-col>
  </v-row>
</template>


<script>
import { useContentStore } from "@/stores/content";

    export default {

        props:{
          
          object:{
            type:Object,
            default(){
              return {}
            }
          },

          title:{
            type:String,
            default(){
              return "";
            }
          },

          property:{
            type:String,
            default(){
              return "";
            }
          },

          min:{
            type:Number, default(){return 0;}
          },
          max:{
            type:Number, default(){return 100;}
          },
          step:{
            type:Number, default(){return 1;}
          },
          
        },

        setup(){
          //let colorstring = ref(props.color);
          const contentStore = useContentStore();
          return {contentStore};
        },

        mounted(){
          
        },

        data: ()=> ({
            text_backup:"",
        }),

        methods: {

          keydownHandler(event){
            //del키를 누르면 컴포넌트가 삭제된다. 따라서 인풋창의 모든 키입력의 이벤트 전파를 막는다.
            event.stopPropagation();
          },

          changecallback(){
              this.updateRender();
          }, 

          //편집창 컴포넌트 렌더링
          updateRender(){
              if(this.contentStore.select_component != null){
                  this.contentStore.saveSnapshot();
                  this.contentStore.select_component.updateRender();
              }
          },

        }

    }
</script>

<style scoped>


</style>