<!-- 현재 해당 페이지는 사용안함 -->
<template>

  <v-container fluid >

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />

    <!-- 추가/수정 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#000000ff" v-model="dialog" max-width="550px">
        
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">요금제</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.SUB_NAME" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">설명</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.SUB_DESC" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">기간</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.SUB_DAYS" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">가격</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.SUB_AMOUNT" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">동시접속</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.LIMIT_CONNECT" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">용량제한</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.LIMIT_SIZE" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">컨텐츠</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.LIMIT_CONTENT" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">스크린</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.LIMIT_SCREEN" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">실시간 페이지</label>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    v-model="editedItem.LIMIT_PAGE" dense single-line
                    variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">팝업</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.LIMIT_POPUP" :items="USELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">TTS</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.LIMIT_TTS" :items="USELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">모니터링</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.LIMIT_MONITOR" :items="USELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

              <v-row dense align="center" justify="center" class="mt-4 mb-3">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">계정</label>
                </v-col>
                <v-col cols="8" align="center" class="offset-md-1 pr-4">
                  <comaccnt :comname="editedItem.OWNERNAME" @cancelcallback="comdialogcancel" @callback="comdialogcb" />
                </v-col>
              </v-row>


              <v-row dense align="center" justify="center">
                <v-col cols="3" align="center">
                  <label class="mytextstyle">상태</label>
                </v-col>
                <v-col cols="9">
                  <v-select v-model="editedItem.SUB_STATE" :items="STATELIST" variant="outlined" density="compact"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>

    <v-row align="center" justify="start" dense class="mt-3">
      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" md="1" sm="3" xs="3" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
    </v-row>

  <v-row dense>
    <v-col cols="12">
      <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
    </v-col>
  </v-row>
    
  <v-row align="start" dense>
    <v-col > 
        <v-table>
            <thead>
                <tr>
                    <th class="text-left">오너</th>
                    <th class="text-left" @click="customsort('SUB_NAME')">요금제명</th>
                    <th class="text-left" @click="customsort('SUB_DAYS')">기간</th>
                    <th class="text-left" @click="customsort('SUB_AMOUNT')">가격</th>
                    <th class="text-left" @click="customsort('LIMIT_CONNECT')">동접</th>
                    <th class="text-left" @click="customsort('LIMIT_SIZE')">용량</th>
                    <th class="text-left" @click="customsort('LIMIT_CONTENT')">컨텐츠</th>
                    <th class="text-left" @click="customsort('LIMIT_SCREEN')">스크린</th>
                    <th class="text-left" @click="customsort('LIMIT_PAGE')">실시간</th>
                    <th class="text-left" @click="customsort('LIMIT_POPUP')">팝업</th>
                    <th class="text-left" @click="customsort('LIMIT_TTS')">TTS</th>
                    <th class="text-left" @click="customsort('LIMIT_MONITOR')">모니터링</th>
                    <th class="text-left" @click="customsort('SUB_STATE')">상태</th>
                    <th class="text-left" @click="customsort('REG_DATE')">등록일</th>
                    <th class="text-left">수정</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in itemList" :key="item.name">
                    <td>{{ item.OWNERNAME }}</td>
                    <td>{{ item.SUB_NAME }}</td>
                    <td>{{ item.SUB_DAYS }}</td>
                    <td>{{ item.SUB_AMOUNT }}</td>
                    <td>{{ item.LIMIT_CONNECT }}</td>
                    <td>{{ item.LIMIT_SIZE }}</td>
                    <td>{{ item.LIMIT_CONTENT }}</td>
                    <td>{{ item.LIMIT_SCREEN }}</td>
                    <td>{{ item.LIMIT_PAGE }}</td>
                    <td>{{ item.LIMIT_POPUP == 1 ? '사용' : '미사용' }}</td>
                    <td>{{ item.LIMIT_TTS == 1 ? '사용' : '미사용' }}</td>
                    <td>{{ item.LIMIT_MONITOR == 1 ? '사용' : '미사용' }}</td>
                    <td>{{ item.SUB_STATE == 1 ? '활성' : '탈퇴' }}</td>
                    <td>{{ this.$momenttz.utc(item.REG_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD")  }}</td>
                    <td>
                        <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
                        <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
                    </td>
                </tr>
            </tbody>
        </v-table>

    </v-col>
  </v-row>


  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { ref } from '@vue/reactivity';
export default {

    setup(){
        var searchbar = ref(null);
        var paging = ref(null);
        return {searchbar, paging};
    },

    data: () => ({
      progress_dialog:false,
      progress_value:0,
      
      options: {
        focus:false,
      },      

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          OWNERID:null,
          SUB_NAME:'',
          SUB_DESC:'',
          SUB_DAYS:31,
          SUB_STATE:0,
          SUB_AMOUNT:10000,
          LIMIT_CONNECT:1,
          LIMIT_SIZE:50,
          LIMIT_CONTENT:1,
          LIMIT_SCREEN:1,
          LIMIT_PAGE:0,
          LIMIT_POPUP:0,
          LIMIT_TTS:0,
          LIMIT_MONITOR:0
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트
      itemList: [],

      //검색 파라메터
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      STATELIST: [
          {name:'비활성',       value:0}
        , {name:'활성',       value:1}
      ],

      USELIST: [
          {name:'미사용',     value:0}
        , {name:'사용',       value:1}
      ],

      grade:0,

    }),

    mounted(){

      if(this.$storage.getStorageSync("GRADE") < 90){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }
      this.grade = this.$storage.getStorageSync("GRADE");

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("/Login");
      }
    },

    methods: {

      //업체선택 취소
      comdialogcancel(){
        this.editedItem.OWNERID   = null;
        this.editedItem.OWNERNAME = "";
      },

      //업체선택 콜백
      comdialogcb(val){
        if(val.NAME.length > 0){
          this.editedItem.OWNERNAME = val.NAME;
          this.editedItem.OWNERID   = val.LOCALID;
        }else{
          this.$alert.fire('기업을 선택하세요');
          return;
        }
      },

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/SubscriptList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },

      async loadpage(p){
        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }

        this.loading = true;
        this.page = p;
        this.paging.setpage(this.page);
          this.$http.post(this.$host+'/SubscriptList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:token}})
          .then((result)=>{

            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });

                this.loading = false;
                

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

          if(this.editedItem.SUB_NAME  == undefined || this.editedItem.SUB_NAME.length < 1){
            this.$alert.fire("요금제명을 입력하세요");
            return;
          } 
          if(this.editedItem.SUB_DAYS  == undefined || this.editedItem.SUB_DAYS.length < 1){
            this.$alert.fire("기간을 입력하세요");
            return;
          } 
          if(this.editedItem.SUB_AMOUNT  == undefined || this.editedItem.SUB_AMOUNT.length < 1){
            this.$alert.fire("가격을 입력하세요");
            return;
          } 
          if(this.editedItem.LIMIT_CONNECT  == undefined || this.editedItem.LIMIT_CONNECT.length < 1){
            this.$alert.fire("동접을 입력하세요");
            return;
          } 

        if (this.editedIndex > -1) {
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/SubscriptUpdate',{
                SUB_NAME: escape(this.editedItem.SUB_NAME)
              , SUB_DESC: escape(this.editedItem.SUB_DESC)
              , SUB_DAYS: this.editedItem.SUB_DAYS
              , SUB_STATE: this.editedItem.SUB_STATE
              , SUB_AMOUNT: this.editedItem.SUB_AMOUNT
              , LIMIT_CONNECT: this.editedItem.LIMIT_CONNECT
              , LIMIT_SIZE: this.editedItem.LIMIT_SIZE
              , LIMIT_CONTENT: this.editedItem.LIMIT_CONTENT
              , LIMIT_SCREEN: this.editedItem.LIMIT_SCREEN
              , LIMIT_PAGE: this.editedItem.LIMIT_PAGE
              , LIMIT_POPUP: this.editedItem.LIMIT_POPUP
              , LIMIT_TTS: this.editedItem.LIMIT_TTS
              , LIMIT_MONITOR: this.editedItem.LIMIT_MONITOR
              , OWNERID: this.editedItem.OWNERID
              , SUB_IDX: this.editedItem.SUB_IDX
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
              const p = this.page;
              this.page = 0;
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(p);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });


        }else{
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //신규
          this.loading = true;
          this.$http.post(this.$host+'/SubscriptInsert',{
                SUB_NAME: escape(this.editedItem.SUB_NAME)
              , SUB_DESC: escape(this.editedItem.SUB_DESC)
              , SUB_DAYS: this.editedItem.SUB_DAYS
              , SUB_STATE: this.editedItem.SUB_STATE
              , SUB_AMOUNT: this.editedItem.SUB_AMOUNT
              , LIMIT_CONNECT: this.editedItem.LIMIT_CONNECT
              , LIMIT_SIZE: this.editedItem.LIMIT_SIZE
              , LIMIT_CONTENT: this.editedItem.LIMIT_CONTENT
              , LIMIT_SCREEN: this.editedItem.LIMIT_SCREEN
              , LIMIT_PAGE: this.editedItem.LIMIT_PAGE
              , LIMIT_POPUP: this.editedItem.LIMIT_POPUP
              , LIMIT_TTS: this.editedItem.LIMIT_TTS
              , LIMIT_MONITOR: this.editedItem.LIMIT_MONITOR
              , OWNERID: this.editedItem.OWNERID
          },{headers: { firetoken:token}})
          .then((result)=>{
            this.loading = false;
            if(result.data.resultCode == 0){
                this.$alert.fire("추가가 완료되었습니다.").then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('추가 실패');
          });


        }

        this.close()
      },      


      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      //신규 추가
      newinsert(){
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },


      //아이템 삭제
      async delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/SubscriptDelete',{
                SUB_IDX:item.SUB_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },      
    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


</style>