<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 5">
    <v-container fluid class="ma-0 pt-0">
        
        <!-- 비디오 재생 시간 -->
        <v-row justify="start" align="center" dense class="pt-0">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">재생 시간</label>
            <input 
                v-model="contentStore.select_component.model.movie_time" disabled
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:30px;text-align:left;font-size:12px">Sec</label>
        </v-row>

        <v-row justify="start" align="center" dense class="mt-1">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">반복재생</label>
            <input type="checkbox" v-model="contentStore.select_component.model.loop"/>
        </v-row>        

        <v-row dense justify="center" class="mt-3">
            <video 
                ref="video" width="100%"
                style="width:150px;height:80px;background:black"
                @loadedmetadata="loadvideodata"
                />
        </v-row>

        <v-row dense justify="center" class="mt-3">
            <v-col cols="12">
            <v-file-input 
                height="48" class="popupiputstyle" density="compact" variant="outlined"
                prepend-icon="mdi-file"
                show-size hide-details v-model="uploadFile" 
                accept="video/mp4"
                @change="changeMoviefile"
                />
            </v-col>
        </v-row>
        
        <v-row dense justify="center" class="mt-3">
            <div>{{this.videourl}}</div>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, watch, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import Component_Model from '@/nextts/model/Component_Model';

export default {
  

  setup(){
    const video         = ref(null);
    const contentStore  = useContentStore();
    return {video, contentStore}
  },  

  data(){
    return{

        uploadFile : [],
        uploadFileThum : [],

    }
  },

  mounted(){
    //console.log("cominfo movie...mounted");
  },

  activated(){
    //console.log("cominfo movie...activated");
  },

  deactivated(){
    //console.log("cominfo movie...deactivated");
  },

  updated(){
    //console.log("cominfo movie...updated");
    if(this.contentStore.select_component == null) return;
    if(this.contentStore.select_component.model.component_type == Component_Model.COMPONENT_MOVIE){
        if(this.contentStore.select_component.model.movie instanceof File){
            this.video.src = URL.createObjectURL(this.contentStore.select_component.model.movie);
        }else{
            this.video.src = this.contentStore.select_component.model.movie;
        }
    }
  },

  methods:{
    
    loadvideodata(){
        console.log("duration : " + this.video.duration);
        this.contentStore.select_component.model.movie_time = this.video.duration;
    },

    //동영상 파일 선택시
    changeMoviefile(){
        if(this.uploadFile[0] != undefined && this.uploadFile[0].name != undefined && this.uploadFile[0].name.length > 0){
            console.log("changeMoviefile2");
            if(this.uploadFile[0].size/1024/1024 > 100){
                this.$alert.fire("동영상은 100MB 이하만 사용해주세요");
                this.uploadFile = [];
                return;
            }
            
            this.contentStore.select_component.model.movie = this.uploadFile[0];
            const videourl = URL.createObjectURL(this.uploadFile[0]);
            this.video.src = videourl;
            console.log("video url  : " + videourl);   //파일은 blob:으로 시작함

        }
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },


  },

  
}
</script>