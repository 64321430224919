<!-- 
    검토결과
    canvas기반으로 동영상은 편법이 필요하고 iframe은 사용이 불가능하다. 
    그러나 이미지, 텍스트, 도형등은 속도가 매우 빠르고 애니매이션 지원이 뛰어나다.
-->
<template>
    <v-container>
        <v-row>
            <v-btn variant="text" size="small" @click="addRect">사각형</v-btn>
            <v-btn variant="text" size="small" @click="addPer">퍼포먼스</v-btn>
            <v-btn variant="text" size="small" @click="addCircle">원형</v-btn>
            <v-btn variant="text" size="small" @click="addTriangle">삼각형</v-btn>
            <v-btn variant="text" size="small" @click="addImageURL">이미지URL</v-btn>
            <v-btn variant="text" size="small" @click="testAni">이동</v-btn>
            <v-btn variant="text" size="small" @click="testAni2">회전</v-btn>
            <v-btn variant="text" size="small" @click="addMovie">동영상</v-btn>
        </v-row>
        <v-row>
            <div style="width:1000px;height:1000px">
                <canvas width="1000" height="1000" id="myCanvas"/>
            </div>
        </v-row>
    </v-container>
  </template>
  
  <script>
  import { fabric } from 'fabric';
  export default {
  
    data:()=>({
    
        canvas:null,
        ctx:null,
        rect:null,
        
    }),
  
  
    mounted(){

        this.canvas = new fabric.Canvas('myCanvas');
        this.canvas.selection = true; 
        
        
    },
  
  
    methods:{
        
        addRect(){
            this.rect = new fabric.Rect({
                left: 200,
                top: 100,
                fill: 'red',
                width: 100,
                height: 100,
                originX:"center",
                originY:"center",
                angle:0,
                objectCaching:false
            });
            this.canvas.add(this.rect);        
        },

        addPer(){

            for(var i=0;i<500;i++){
                const rt = new fabric.Rect({
                    left: Math.floor(Math.random() * 1000)+50,
                    top: Math.floor(Math.random() * 1000)+50,
                    fill: 'red',
                    width: 50,
                    height: 50,
                    originX:"center",
                    originY:"center",
                    angle:0,
                    objectCaching:false
                });
                this.canvas.add(rt); 
            }

        },

        addCircle(){
            var circle = new fabric.Circle({
                    radius: 50, fill: 'green', left: 100, top: 100
            });
            this.canvas.add(circle);        
        },

        addTriangle(){
            var triangle = new fabric.Triangle({
                width: 50, height: 50, fill: 'blue', left: 50, top: 50
            });
            this.canvas.add(triangle);        
        },

        async addImageURL(){
            const cv = this.canvas;
            fabric.Image.fromURL('https://storage.googleapis.com/necsolution-59f61.appspot.com/imgres/1700194380192_thum.jpg', function(oImg) {
                //oImg.set({ width: 150, height: 150, left:200, top:200, opacity: 1.0 });
                oImg.set("left",300);
                oImg.set("top",300);
                cv.add(oImg);
            });            
            
        },

        testAni(){
            //+=을 써주면 현재 좌표에 증가되고 안써주면 절대값으로 이동함.
            this.rect.animate('left', '+=50', { 
                    onChange: this.canvas.renderAll.bind(this.canvas)
                ,   duration: 1000
                ,   easing: fabric.util.ease.easeOutBounce
            });
        },

        testAni2(){
            this.rect.animate('angle', '+=50', { 
                    onChange: this.canvas.renderAll.bind(this.canvas)
                ,   duration: 1000
                ,   easing: fabric.util.ease.easeOutBounce
            });
        },

        async addMovie(){
            
            //동영상이 되는거야?
            const video = await this.loadVideo("https://storage.googleapis.com/necsolution-59f61.appspot.com/mvres/1679310176780.mp4");
            var video1 = new fabric.Image(video, {
                left: 200,
                top: 300,
                width:300,
                hegiht:150,
                angle: 0,
                originX: 'center',
                originY: 'center',
                objectCaching: false,
            });
            this.canvas.add(video1);
            video1.getElement().play();
        },

        loadVideo(videourl){
            return new Promise((resolve, reject)=>{
                const video = document.createElement('video');
                video.onloadedmetadata = function() {
                    // 로드가 완료되면 비디오 객체를 resolve
                    resolve(video);
                };        
                video.onerror = function() {
                    // 로드 실패 시 reject 호출
                    reject(new Error('비디오 로드 실패'));
                };        
                video.src = videourl;
            })
        },    



    },
    
  
  }
  </script>
  
  
  <style scoped>
  
  
  </style>