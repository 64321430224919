<!-- 전체 재생 테스트 -->
<template>
  <v-container fluid class="ma-0 pa-0">
      <div ref="play_div" id="test_div"/>
  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import AniUtil from "@/nextts/play/AniUtil";
import Play_Background from '@/nextts/play/Play_Background';

//import html2canvas from 'html2canvas'

export default {

  components:{
    
  },

  setup(){
    let play_div        = ref(null);
    let contentStore    = useContentStore();
    return{
      play_div, contentStore
    }
  },

  data:()=>({

    content_model:null,
    screen_list:null,
    
    cur_view:null,
    next_view:null,

    pos:0,      //스크린 번호
    flag:true,  //백그라운드 전환용 플래그 값
    timerId:null, //타이머를 취소할 수 있는 아이디 : clearTimeout(timerId);

  }),


  mounted(){
    this.content_model = this.contentStore.content_model;
    console.log("Content Play " + this.content_model.width + " / " + this.content_model.height);
    this.screen_list    = this.content_model.screen_list;
    
    this.play_div.style.width  = this.content_model.width+"px";
    this.play_div.style.height = this.content_model.height+"px";
    this.play_div.style.backgroundColor = "gray";
    
    this.cur_view  = new Play_Background();
    this.cur_view.setInit(this.content_model.width, this.content_model.height);
    this.play_div.appendChild(this.cur_view);

    this.next_view  = new Play_Background();
    this.next_view.setInit(this.content_model.width, this.content_model.height);
    this.play_div.appendChild(this.next_view);

    //window.innerWidth + " / " + window.innerHeight
    this.updateDimensions();
    onresize = this.resizeevent;
    this.init();

  },
  
  //화면 종료시 타이머 종료
  beforeUnmount(){
    if(this.timerId != null){
      clearTimeout(this.timerId);
    }

    //재생자원 종료
    this.next_view.exit();
    this.cur_view.exit();
    
  },

  methods:{

    updateDimensions() {
      let ratio       = this.content_model.width / this.content_model.height;
      let windowRatio = window.innerWidth / window.innerHeight;
      console.log("update zoom1 : " + ratio + " / " + windowRatio);
      if (windowRatio > ratio) {
        this.zoom = window.innerHeight / this.content_model.height;
      } else {
        this.zoom = window.innerWidth / this.content_model.width;
      }
      console.log("zoom : " + this.zoom);
      this.play_div.style.transformOrigin='top left';
      this.play_div.style.transform='scale('+this.zoom+')';
    },

    //컨텐츠 재생
    init(){

      //컨텐츠 재생 사운드 처리 (일단 패스)

      //전체 캡션 처리 (일단 패스)

      this.pos = 0;
      this.transBackground();

    },

    //화면전환
    transBackground(){
      
      console.log("transBackground... " + this.pos + "/"+this.flag);
      let screen_model = this.screen_list[this.pos];

      if(this.flag){
        //cur_view 재생
        this.next_view.style.zIndex   = String(1);
        this.cur_view.style.zIndex    = String(2);

        this.cur_view.setModel(screen_model);
        this.cur_view.style.opacity = 1.0;
        
        if(this.screen_list[this.pos].aniplaymodel != null && this.screen_list[this.pos].aniplaymodel.anilist != null && this.screen_list[this.pos].aniplaymodel.anilist.length > 0){
          //진입 화면전환 애니매이션
          console.log("have inani...1");
          this.cur_view.inAni(this.inani_Complete);
        }else{
          //진입 화면전환 애니매이션이 없으면 디폴트 진입 애니매이션 실행.
          console.log("dont have inani...1");
          let inani = AniUtil.getDefaultInAni();
          this.cur_view.setInPlayAni(inani, this.inani_Complete);
        }
        //화면 아웃 애니매이션
        let outani = AniUtil.getDefaultOutAni();
        this.next_view.setOutPlayAni(outani, this.outani_Complete(true));

      }else{
        //next_view  재생
        this.cur_view.style.zIndex    = String(1);
        this.next_view.style.zIndex   = String(2);

        this.next_view.setModel(screen_model);
        this.next_view.style.opacity = 1.0;
        
        if(this.screen_list[this.pos].aniplaymodel != null && this.screen_list[this.pos].aniplaymodel.anilist != null && this.screen_list[this.pos].aniplaymodel.anilist.length > 0){
          //진입 화면전환 애니매이션
          console.log("have inani...2");
          this.next_view.inAni(this.inani_Complete);
        }else{
          //진입 화면전환 애니매이션이 없으면 디폴트 진입 애니매이션 실행.
          console.log("dont have inani...2");
          let inani = AniUtil.getDefaultInAni();
          this.next_view.setInPlayAni(inani, this.inani_Complete);
        }
        //화면 아웃 애니매이션
        let outani = AniUtil.getDefaultOutAni();
        this.cur_view.setOutPlayAni(outani, this.outani_Complete(false));

      }
      

    },

    //화면전환 애니매이션 종료시
    inani_Complete(){

      console.log("inani_Complete..." + this.flag);

      if(this.flag){
        this.cur_view.init();
      }else{
        this.next_view.init();
      }

      //다음 페이지 실행
      var delay = 0;
      
      //해당 스크린의 애니매이션 타임중 제일 긴 시간 가져오기 + 화면대기시간
      this.screen_list[this.pos].component_list.forEach(cm=>{
        //컴포넌트의 애니매이션 시간과 재생시간중 가장 큰 값 가져오기
        delay = Math.max(delay, cm.getAniDuration());
        delay = Math.max(delay, cm.getPlayDuration());
      });
      
      //스크린의 대기시간 추가
      delay += Number(this.screen_list[this.pos].trans_time);

      if(this.pos+1 < this.screen_list.length){
        this.pos += 1;
      }else{
        this.pos = 0;
      }

      if(this.flag){
        this.flag = false;
      }else{
        this.flag = true;
      }
      
      const nextRun = this.transBackground;
      this.timerId = setTimeout(function() {        
        nextRun();
      }, delay * 1000);      

    },

    outani_Complete(outflag){
      if(outflag){
        console.log("outani_Complete... true");
        this.next_view.exit();
      }else{
        console.log("outani_Complete... false");
        this.cur_view.exit();
      }
    },

    resizeevent(){
      console.log("resizeevent ");
      this.updateDimensions();
    },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>