<template>
    <v-container fluid style="overflow:auto">

      <v-row justify="start" class="mt-1">
        <v-col 
          v-for="item in itemList_com"
          class="ma-0 pa-1" align="center"
          :key="item"
          cols="6"
          >
          <AniComponent 
            style="cursor:pointer;" 
            :anidata="item" @click="select_comani(item)"
            />
          <!-- 
          <div
            @click="select_comani(item)"
            style="width: 100px;height:80px;background:gray;cursor:pointer;"
            class="d-flex flex-column align-center justify-center rounded-lg"
            >
            <v-icon class="mb-2">mdi-animation</v-icon>
            <div style="font-size:12px">{{ item.ANI_NAME }}</div>
          </div>
          -->
        </v-col>
      </v-row>        

    </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model'
import AniComponent from '@/nextvue/preview/AniComponent.vue'
export default {
  
  setup(){
    const contentStore  = useContentStore();
    return { contentStore }
  },

  components:{
    // eslint-disable-next-line vue/no-unused-components
    AniComponent
  },

  //프로퍼티 선언
  props:{

  },    

  mounted(){
    this.loadpage_com();
  },


  data(){
    return{
      itemList_com: [],
    }
  },


  methods:{


    //애니선택시 선택된 컴포넌트에서 재생됨.
    select_comani(item){
      if(this.contentStore.select_component != null && this.contentStore.select_component.model != null){
        const select_ani = new AnimationPlay_Model();
        select_ani.setStringValue(item.ANI_JSON);
        this.contentStore.select_component.model.aniplaymodel = select_ani;
        this.contentStore.select_component.playani();
      }

    },    

    async loadpage_com(){

      //토큰값 체크
      let token;
      try{
        token = await this.$fireauth.currentUser.getIdToken();
      }catch(error){
        token = this.$storage.getStorageSync("FIRETOKEN");
      }

      this.$http.post(this.$host+'/AniBestList',{
      },{headers: { firetoken:token}})
      .then((result)=>{
        if(result.data.resultCode == 0){
            const list = result.data.resultData;
            this.itemList_com.splice(0, this.itemList_com.length);   //데이터 초기화
            list.forEach(element => {
              this.itemList_com.push(element); 
            });
        }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
      });

    },        

  },



}
</script>