<!-- 애니매이션 미리보기 뷰 -->
<template>
    
    <div ref="component" class="rounded-lg" style="overflow: hidden;"/>
    
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Component_Model from '@/nextts/model/Component_Model'
import { ref, reactive } from '@vue/reactivity';
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'
import { useContentStore } from "@/stores/content";
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model';
import gsap from "gsap";

export default {

  props:{
    anidata:AnimationPlay_Model
  },

  setup(){
    const component         = ref(null);
    const contentStore      = useContentStore();
    return {component, contentStore};
  },

  data:()=>({

    model:null,
    anielement:null,

  }),

  mounted(){

    //컴포넌트의 크기, 위치 설정
    this.component.style.cssText += "width:110px;height:90px;rotate(0deg);background:black"; 
    this.component.style.transformOrigin = "0% 0%";
    this.component.style.transform = 'scale(1)';
    
    this.model = new Component_Model();
    this.model.width  = 30;
    this.model.height = 30;
    this.model.component_type = Component_Model.COMPONENT_ANISHOW;
    this.model.posx   = 0;
    this.model.posy   = 0;
    this.model.rotate = 0;
    this.model.transx = 40;
    this.model.transy = 30;

    this.anielement = new EditBasic_Element(reactive(this.model));
    this.component.appendChild(this.anielement);

    this.aniplay();

  },

  beforeUnmount(){
    //console.log("beforeUnmount...");
  },


  methods:{

    //전체 플레이
    aniplay(){

        const aniplay = new AnimationPlay_Model();
        aniplay.setStringValue(this.anidata.ANI_JSON);

        if(aniplay == null || aniplay == undefined || aniplay.anilist.length < 1){
            console.log("groupaniplay...1");
            return;
        }

        //계속 반복해서 보여주기 위한 설정
        let timeline = gsap.timeline({repeat:-1, repeatDelay:3});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.anielement.model.transx);
        let move_posy   = Number(this.anielement.model.transy);
        let move_rotate = Number(this.anielement.model.rotate);
        //회전, 로테이션, 알파는 

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        timeline.to(this.anielement, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.anielement.model.alpha });
        
        // eslint-disable-next-line no-unused-vars
        aniplay.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);

            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
                            
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            timeline.to(this.anielement, aniprop);
        });
        

    },


  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
