
import Screen_Model from '@/nextts/model/Screen_Model';
import Component_Model from '@/nextts/model/Component_Model';
import EditBasic_Element from '@/nextts/edit/EditBasic_Element';
import gsap from "gsap";
import Animation_Model from '../model/Animation_Model';
import { reactive, ref } from '@vue/reactivity';

//기본 데이터 속성
class EditBasic_Screen extends HTMLElement {
    
    mousedoublecallback:Function
    screen_model:Screen_Model
    timeline?:null|GSAPTimeline

    //스크린 편집시 이동 및 확대축소시 사용함.
    transx:number   = 0;
    transy:number   = 0;

    constructor(sm:Screen_Model, modeswitch:Function){

        super();
        this.screen_model = sm;

        console.log("constructor sm model " + JSON.stringify(this.screen_model));

        this.mousedoublecallback = modeswitch;

        //피그마처럼 여러개의 스크린을 인피니티 뷰어 내에서 움직여야 하는 경우 사용
        this.style.position = "absolute";
        this.style.overflow = "hidden";

        //movealbe 및 selecto 타겟팅
        this.classList.add("screen");
        
        
        this.ondblclick = this.mousedoubleclick;
        

        this.updateRender();
        //this.updateTransElement();
    }

    //컴포넌트 외형 그리기
    updateRender(){
        


        //배경타입 
        if(this.screen_model.bgtype == 0){
            //color
            this.style.backgroundColor  = this.screen_model.backgroundColor;
            this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 1){
            //image
            this.style.backgroundColor  = "#00000000";
            this.style.backgroundSize   = this.screen_model.width + "px " + this.screen_model.height + "px";
            this.style.backgroundImage  = "url('"+this.screen_model.backgroundImg+"')"
            this.style.backgroundRepeat = "no-repeat";
        }else if(this.screen_model.bgtype == 2){
            //gradient
            this.style.background       = this.screen_model.backgroundColor;
            //this.style.backgroundImage  = "";
            this.style.backgroundRepeat = "no-repeat";
        }

        this.style.width  = this.screen_model.width+'px';
        this.style.height = this.screen_model.height+'px';
        this.style.transform='scale(1.0)';

        //컴포넌트 그리기
        this.drawcomponentlist();

    }

    drawcomponentlist(){
        //컴포넌트 그리기
        this.screen_model.component_list.forEach(cm => {
            this.drawcomponent(cm);
        });
    }

    drawcomponent(cmodel:Component_Model){
        const edit_el = new EditBasic_Element(reactive(cmodel));
        this.appendChild(edit_el);
    }

    randadd(){
        //컴포넌트 랜덤추가
        for (let i = 0; i < 5; ++i) {
            this.addcomponent();
        }      
    }

    addcomponent(){

        //컴포넌트 모델 생성 (크기, 위치 정보)
        const posx:number = Math.floor(Math.random() * 700)+50;
        const posy:number = Math.floor(Math.random() * 560)+50;
        const rotate:number = Math.floor(Math.random() * 360);
      
        const tempcm    = new Component_Model();
        tempcm.width  = Math.floor(Math.random() * 150)+50;
        tempcm.height = Math.floor(Math.random() * 150)+50;
        tempcm.posx   = posx;
        tempcm.posy   = posy;
        //tempcm.rotate = rotate;

        
        tempcm.text = String(Math.floor(Math.random() * 150)+50);

        
        tempcm.rotate = 0;
        tempcm.transx = posx;
        tempcm.transy = posy;

        tempcm.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
        
        const edit_el = new EditBasic_Element(reactive(tempcm));
        this.appendChild(edit_el);
        this.screen_model.component_list.push(tempcm);
  
    }  

    //포지션
    setPosition(x:number, y:number){
        this.transx = x;
        this.transy = y;
    }

    //줌설정
    setZoom(z:number){
        this.style.transform ='scale('+z+') rotate(0deg)';
        //this.style.transform ='scale('+z+') rotate(0deg) translate('+this.transx+'px,'+this.transy+'px)';
        //this.style.transform ='scale('+z+') rotate(0deg) translate(0px, 0px)';
    }

    updateTransElement(){
        this.updateRender();
        //this.style.cssText += "transform: translateX("+`${this.screen_model.transx}px`+") translateY("+`${this.screen_model.transy}px`+") rotate("+`${this.screen_model.rotate}deg`+")";
    }

    //마우스 더블 클릭시 편집모드 스위치 (스크린/컴포넌트)
    mousedoubleclick(e:MouseEvent){
        if(e.target == this){   //스크린 더블 클릭시에만 작동 (child에서 버블링으로 올라오는 이벤트 무시하도록 (텍스트 컴포넌트 더블클릭이 여기에 영향줌.))
            if(this.mousedoublecallback != null){
                this.mousedoublecallback();
            }
        }
    }
    


    //애니매이션
    playani(){
        if(this.screen_model == null || this.screen_model == undefined){
            return;
        }
        
        if(this.screen_model.aniplaymodel == null || this.screen_model.aniplaymodel == undefined){
            return;
        }

        if(this.screen_model.aniplaymodel.anilist == null || this.screen_model.aniplaymodel.anilist == undefined || this.screen_model.aniplaymodel.anilist.length < 1){
            return;
        }

        if(this.timeline != null){
            this.timeline.clear();
            this.timeline = null;
        }
        this.timeline = gsap.timeline({repeat:Number(this.screen_model.aniplaymodel.repeat), repeatDelay:Number(this.screen_model.aniplaymodel.repeatDelay), yoyo:Boolean(this.screen_model.aniplaymodel.yoyo)});

        //애니의 좌표를 += 되는 개념이다. 나머지는 그냥 바로 해당 수치로 변경되면 된다.
        let move_posx   = Number(this.screen_model.transx);
        let move_posy   = Number(this.screen_model.transy);
        let move_rotate = Number(this.screen_model.rotate);
        //회전, 로테이션, 알파는 

        //초기위치 - 이건 애니매이션을 한 화면에서 여러번 재생하니깐 해주는거다. 실제 뷰어에서는 필요 없다.
        //this.timeline.to(this, { duration:0, x:move_posx, y:move_posy, scale:1, rotate:move_rotate, alpha:this.screen_model.alpha });

        this.screen_model.aniplaymodel.anilist.forEach((ani, index)=>{
            move_posx += Number(ani.movex);
            move_posy += Number(ani.movey);
            move_rotate += Number(ani.rotate);
            
            //첫번째 인덱스의 딜레이는 항상 0으로 처리해야한다.
            const aniprop: {[key: string]: string|number} = { delay: ani.delay, duration:ani.duration, x:move_posx, y:move_posy, scale:ani.scale, rotate:move_rotate, alpha:ani.alpha, ease:`${ani.ease}.${ani.easetype}` 
                                                            , transformOrigin: `${ani.centerx}% ${ani.centery}%`};
            //추가 커스텀 속성처리
            ani.proplist.forEach(prop=>{
                aniprop[prop.propname] = prop.propvalue;
            });
            this.timeline!.to(this, aniprop);
            
        });        

        this.timeline.eventCallback("onComplete", ()=>{
            console.log("animation compltete....");
            this.stopAni();
        });

    }

    //애니매이션
    playEffect(effect:Animation_Model){
        
        if(effect == null || effect == undefined){
            return;
        }

        console.log("playEffect : " + effect);

        const move_posx   = Number(this.screen_model.transx) + Number(effect.movex);
        const move_posy   = Number(this.screen_model.transy) + Number(effect.movey);
        const move_rotate = Number(this.screen_model.rotate) + Number(effect.rotate);
  
        gsap.fromTo(this
          , {x:this.screen_model.transx, y:this.screen_model.transy, scale:1, rotate:this.screen_model.rotate, alpha:this.screen_model.alpha}
          , {delay:effect.delay, duration:effect.duration
            , x:move_posx, y:move_posy, scale:effect.scale, rotate:move_rotate
            , ease:`${effect.ease}.${effect.easetype}`
          }
        );

    }    

    //중지
    stopAni(){
        
        if(this.timeline != null){

            this.timeline.clear();
            this.timeline.to(this, {x:this.screen_model.transx, y:this.screen_model.transy, scale:1, rotate:this.screen_model.rotate, alpha:this.screen_model.alpha, duration:0});

        }

    }    

}

export default EditBasic_Screen
customElements.define('editbasic-screen', EditBasic_Screen);