<!-- 커스텀 객체를 만들어서 타입스크립트와 자바스크립트를 혼용한 샘플 (moveable과 selecto때문에 타입스크립트 여기서 못씀) -->
<!-- moveable로 변형한 객체의 복원과 애니 테스트 (완료됨) -->
<template>
  <v-container>

    <div style="display:flex;flex-direction:row">

      <!-- left panel -->
      <div class="paneloutline scrollBar" 
         style="position:fixed;left:0px;top:32px;width:240px;background:white;overflow:hidden"
         :style="'height:'+windowHeight+'px'"
         >
        <EditLeft_Panel ref="editleft_panel" />
      </div>
       
      <!-- top menu -->
      <div style="position:fixed;left:242px;right:241px;top:100px;height:50px;background:red">
        <v-btn @click="new_content">신규컨텐츠</v-btn>
        <v-btn @click="save_content">컨텐츠 저장(DB)</v-btn>
        <v-btn @click="load_content">컨텐츠 로드(DB)</v-btn>
        <v-btn @click="test">TEST</v-btn>
      </div>

      
      <EditWindow 
        style="position:fixed;left:242px;bottom:36px;right:241px;top:183px"
        :style="'width:'+windowWidth+'px'"
        /> 
      

      <!-- right panel -->
      <div class="paneloutline" 
          :style="'height:'+windowHeight+'px'"
          style="position:fixed;right:0px;top:32px;width:240px;background:white;overflow:auto"
          
          >
          <EditRight_Panel ref="editright_panel" />
      </div>
      
    </div>


    <!-- 
    <div style="position:fixed;bottom:0px;left:0px;width:200px;height:300px">
      
        <v-btn @click="addcontent">Insert</v-btn>
        <v-btn @click="updatecontent">Update</v-btn>
        <v-btn @click="loadcontent">Load</v-btn>
        <v-btn @click="zoomin">확대</v-btn>
        <v-btn @click="zoomout">축소</v-btn>
        <v-btn @click="addcomponent">컴포넌트추가</v-btn>
        <v-btn @click="updatecontent">컴포넌트삭제</v-btn>
        <v-btn @click="cleanall">초기화</v-btn>

    </div>
    -->

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";

//커스텀 컴포넌트
import EditWindow from '@/components/EditWindow.vue'
import EditRight_Panel from '@/nextvue/infopanel/EditRight_Panel.vue'
import EditLeft_Panel from '@/nextvue/infopanel/EditLeft_Panel.vue'

// eslint-disable-next-line no-unused-vars
import { addDoc, getDoc, doc, setDoc, collection, updateDoc, getDocs } from "firebase/firestore";
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model'
import { reactive, ref } from '@vue/reactivity';


export default {

  components:{
    // eslint-disable-next-line vue/no-unused-components
    EditWindow, EditRight_Panel, EditLeft_Panel
  },

  setup(){

    let editleft_panel   = ref(null);
    let editright_panel  = ref(null);

    let aniplay_model  = reactive(new AnimationPlay_Model());
    let windowHeight   = ref(window.innerHeight-66);

    let contentStore   = useContentStore();

    return{
      aniplay_model, windowHeight, editleft_panel, editright_panel, contentStore
    }

  },

  data:()=>({
    windowWidth:window.innerWidth-503,
    content_model:null,
  }),


  mounted(){
    //resize 이벤트 설정
    addEventListener('resize', this.resizeevent);
  },

  beforeUnmount(){
    removeEventListener('resize', this.resizeevent);
  },

  methods:{

    new_content(){
      
      //this.contentStore.$reset();
      this.contentStore.setContentModel(1000, 1000);

      //신규 컨텐츠 - 일단 기본으로 1920x1080생성, 추후에는 입력받아서 동적으로 사이즈 정하도록 수정
      //this.contentStore.createContentModel(1920, 1080);
      //this.content_model = reactive(new Content_Model(1920, 1080));
      //this.editleft_panel.setContentModel(this.content_model);

    },
    save_content(){
      //컨텐츠 저장

    },
    load_content(){
      //컨텐츠 로드

    },
    
    resizeevent(){
      this.windowHeight = window.innerHeight-100;
      this.windowWidth  = window.innerWidth-503;
    },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>