<!-- 커스텀 객체를 만들어서 타입스크립트와 자바스크립트를 혼용한 샘플 (moveable과 selecto때문에 타입스크립트 여기서 못씀) -->
<!-- moveable로 변형한 객체의 복원과 애니 테스트 (완료됨) -->
<!-- 순수 편집기술 기술검토 -->
<template>
  <v-container>

  <v-row>
    <v-btn @click="randadd">랜덤추가</v-btn>
    <v-btn @click="cleanall">초기화</v-btn>
    <v-btn @click="addcomponent">추가</v-btn>
    <v-btn @click="testani">단일애니</v-btn>
    <v-btn @click="testanigroup">그룹애니</v-btn>
    <v-btn @click="printinfo">정보확인</v-btn>
    <v-btn @click="testcopy">클론</v-btn>
    <v-btn @click="creategroup">그룹</v-btn>
    <v-btn @click="removegroup">그룹해제</v-btn>
    <v-btn @click="zoomin">확대</v-btn>
    <v-btn @click="zoomout">축소</v-btn>
  </v-row>

  <v-row>
    <v-btn @click="createcollection">컬렉션생성</v-btn>
    <v-btn @click="readcollection">컬렉션읽기</v-btn>
    
    <v-btn @click="createdoc">생성(FB)</v-btn>
    <v-btn @click="updatedocument">수정(FB)</v-btn>
    <v-btn @click="readdoc">로드(FB)</v-btn>

    <v-btn @click="save2">저장(DB)</v-btn>
    <v-btn @click="load2">로드(DB)</v-btn>
  </v-row>

  <div id="edit_div" style="background:gray;width:800px;height:800px;margin-top:100px;margin-left:50px;">
  </div>

  </v-container>
</template>

<script>
import moveable from 'moveable';
import { getElementInfo } from 'moveable';
import Selecto from "selecto";
//import { toCSS } from 'transformation-matrix';

// eslint-disable-next-line no-unused-vars
import { addDoc, getDoc, doc, setDoc, collection, updateDoc, getDocs } from "firebase/firestore";

import Component_Model from '@/nextts/model/Component_Model'
import EditBasic_Element from '@/nextts/edit/EditBasic_Element'

export default {

  data:()=>({
    moveable:null,
    selecto:null,
    
    targets:[],       //selecto 라이브러리를 통해 선택된 컴포넌트들
    componentList:[], //추가된 모든 컴포넌트들

    frameskip: 0,
    zoom : 1,

    undolist:[],
    redolist:[],
    copylist:[],

  }),
  

  beforeUnmount(){
    removeEventListener('keydown', this.onkeydown);
  },  

  mounted(){

    addEventListener('keydown', this.onkeydown);
    const edit_div = document.getElementById("edit_div");


    //selecto - 드래그 앤 드랍으로 컴포넌트 선택해주는 라이브러리
    this.selecto = new Selecto({
        edit_div,
        dragContainer: edit_div,      //셀렉트 드래그가 가능한 영역
        selectableTargets: [".drag"], //선택할 수 있는 타겟
        hitRate: 0,
        selectByClick: true,              //클릭으로 선택
        selectFromInside: false,
        toggleContinueSelect: ["shift"],  //쉬프트로 추가 선택/해제
        ratio: 0,
        getElementRect: getElementInfo
    });


    this.setMoveable();

    this.selecto.on("dragStart", e => {
        const target = e.inputEvent.target;
        if (
            this.moveable.isMoveableElement(target)
            || this.targets.some(t => t === target || t.contains(target))
        ) {
            e.stop();
        }
    }).on("select", e => {
      //그룹핑 때문에 아래와 같이 셀렉트 해줘야한다.
      var selectlist = new Set();
      this.targets = e.selected;
      this.targets.forEach(target=>{
        if(target.model.isgroup){
          var gtarget = [].slice.call(document.querySelectorAll("."+target.model.groupid));
          gtarget.forEach(gt=>{
            selectlist.add(gt);
          });
        }else{
          selectlist.add(target);
        }
      });
      this.moveable.target = Array.from(selectlist);

    }).on("selectEnd", e => {
      console.log("selectEnd....");
        if (e.isDragStart) {
            e.inputEvent.preventDefault();
            setTimeout(() => {
              this.moveable.dragStart(e.inputEvent);
            });
        }
    });    

  },


  methods:{
    zoomin(){
      this.zoom += 0.1;
      document.querySelector('#edit_div').style.transform='scale('+this.zoom+')';
    },

    zoomout(){
      this.zoom -= 0.1;
      document.querySelector('#edit_div').style.transform='scale('+this.zoom+')';
    },

    //콜렉션 생성
    async createcollection() {
      try {
        const docRef = await addDoc(collection(this.$firestore, "users", "rock1108"), {
          first: "Alan",
          middle: "Mathison",
          last: "Turing",
          born: 1912
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    },

    //콜렉션 읽기
    async readcollection(){
      const querySnapshot = await getDocs(collection(this.$firestore, "users"));
      querySnapshot.forEach((doc) => {
        console.log(doc.id + " : " + JSON.stringify(doc.data()));
      });      
    },

    //문서 생성
    async createdoc() {
      var data = { width:100, height:100 };
      await setDoc(doc(this.$firestore, "users", "rock1108"), data);
    },
    
    //문서 수정
    async updatedocument(){
      
      //var templist = [];
      var temp_obj  = JSON.parse('{"data":[]}');

      const edit_div = document.getElementById("edit_div");
      edit_div.childNodes.forEach(child=>{
        if(child.model){
          console.log("model : " + JSON.stringify(child.model));
          //templist.push(child);
          temp_obj["data"].push(child.model);
        }else{
          console.log("not found model...");
        }
      });
      
      /* 단일 이면 아래처럼
      var temp_obj  = JSON.parse('{"data":{}}');
      temp_obj.data = edit_div.outerHTML;
      */
      console.log("========= update firebase doc =============");
      console.log(JSON.stringify(temp_obj));
      const docRef = doc(this.$firestore, "users", "rock1108");
      await updateDoc(docRef, JSON.parse(JSON.stringify(temp_obj)));
      
    },

    //문서 읽기
    async readdoc(){
      
      const docRef = doc(this.$firestore, "users", "rock1108");
      const docSnap = await getDoc(docRef);
      if(docSnap.exists()){
        console.log("Document data:", docSnap.id + " : " + JSON.stringify(docSnap.data().data));
        docSnap.data().data.forEach(model => {

          const cm = new Component_Model("saveload readdoc");
          cm.isgroup  = model.isgroup;
          cm.groupid  = model.groupid;
          cm.component_type = model.component_type;
          cm.width    = model.width;
          cm.height   = model.height;
          cm.posx     = model.posx;
          cm.posy     = model.posy;
          cm.rotate   = model.rotate;
          cm.transx   = model.transx;
          cm.transy   = model.transy;

          this.addcomponentFrame(cm);

        });
      } else {
        console.log("No such document!");
      }      
    },

    save2(){
      
      var temp_obj  = JSON.parse('{"data":[]}');
      const edit_div = document.getElementById("edit_div");
      edit_div.childNodes.forEach(child=>{
        if(child.model){
          console.log("model : " + JSON.stringify(child.model));
          temp_obj["data"].push(child.model);
        }else{
          console.log("not found model...");
        }
      });

      this.$http.post(this.$host+'/UpdateTest',{
        COMPONENTS:escape(JSON.stringify(temp_obj))
      })
      .then((result)=>{
        if(result.data.resultCode == 0){
          console.log("update complete");
        }else{
          console.log("update error");
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        console.log("update error : " + error);
      });
    },

    load2(){
      this.$http.post(this.$host+'/SelectTest',{
      })
      .then((result)=>{
        if(result.data.resultCode == 0){
            const list = result.data.resultData;
            list[0].COMPONENTS.data.forEach(model=>{

              const cm = new Component_Model("saveload load2");
              cm.isgroup  = model.isgroup;
              cm.groupid  = model.groupid;
              cm.component_type = model.component_type;
              cm.width    = model.width;
              cm.height   = model.height;
              cm.posx     = model.posx;
              cm.posy     = model.posy;
              cm.rotate   = model.rotate;
              cm.transx   = model.transx;
              cm.transy   = model.transy;

              this.addcomponentFrame(cm);
            });
            
        }else{
          console.log("select error");
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        console.log("error : " + error);
      });
    },

    onkeydown(ev){
      if(ev.ctrlKey){
          if(ev.keyCode == 90){         //Ctrl+Z undo
            console.log('undo...');
            this.runundo();

          }else if(ev.keyCode == 89){   //Ctrl+Y redo
            console.log('redo...');
            this.runredo();

          }else if(ev.keyCode == 67){   //Ctrl+c copy
            console.log('copy...');
            this.saveCopy();

          }else if(ev.keyCode == 86){   //Ctrl+V Paste
            console.log('paste...');
            this.runPaste();

          }
      }
    },

    saveCopy(){
      var elements = this.selecto.getSelectedTargets();
      this.copylist.splice(0, this.copylist.length);  //기본카피 제거
      elements.forEach(element=>{
        var clone_model     = Object.assign({}, element.model);           //데이터 카피
        this.copylist.push(clone_model);        
      });
    },

    runPaste(){
      if(this.copylist.length > 0){
        this.copylist.forEach(cm =>{

          const copymodel = JSON.parse(JSON.stringify(cm));
          let target = new EditBasic_Element(copymodel);
          const edit_div = document.getElementById("edit_div");
          edit_div.appendChild(target);
          this.componentList.push(target);

        });
      }
    },

    saveUndo(){
      //컨텐츠 모델 전체를 스냅샷을 찍는다.
      this.contentStore.saveSnapshot();
    },

    //언두 실행
    runundo(){
      console.log("undo");
      //select 스크린 인덱스 가져오기
      const select_idx = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.contentStore.undo();
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[select_idx];
      this.drawscreen();
    },
    
    //리두 실행
    runredo(){
      console.log("redo");
      const select_idx = this.contentStore.content_model.screen_list.indexOf(this.contentStore.select_screen);
      this.contentStore.redo();
      this.contentStore.select_screen = this.contentStore.content_model.screen_list[select_idx];
      this.drawscreen();
    },



    creategroup(){
      //선택된 컴포넌트에 그룹아이디를 매긴다.
      var elements = this.selecto.getSelectedTargets();
      console.log("select elements len : " + elements.length);
      
      if(elements.length > 1){
        var curdate = new Date();
        var gid = "gid_" + curdate.getMilliseconds();

        elements.forEach(element=>{
          //그룹 표기
          element.model.isgroup = true;
          element.model.groupid = gid;
          element.classList.add(gid);  //셀렉트를 위한 추가
        });
        var target = document.getElementsByClassName(gid);
        this.moveable.target = target;
      }

    },

    removegroup(){
      //그룹해제
      var elements = this.selecto.getSelectedTargets();
      console.log("remove group len : " + elements.length);
      elements.forEach(element=>{
        if(element.model.isgroup){
          var group_element = document.getElementsByClassName(element.model.groupid);
          for(var i=0;i<group_element.length;i++){
            console.log("remove group...");
            var ge = group_element[i];
            ge.classList.remove(ge.model.groupid);
            ge.model.isgroup = false;
            ge.model.groupid = "";
          }
        }
      });
      this.moveable.target = elements[0];
      
    },

    randadd(){
      for (let i = 0; i <= 5; ++i) {
        this.addcomponent();
      }      
    },

    
    //moveable 셋팅
    setMoveable(){
      
      const edit_div = document.getElementById("edit_div");

      //moveable - 이동, 회전, 리사이즈 에디터 라이브러리
      this.moveable = new moveable(edit_div, {
        draggable: true,
        rotatable: true,
        resizable: true,
        scalable: true,
        origin: false,      //중심부 렌더링
        keepRatio: false,   //리사이즈시 비율 유지 유무
        
        snappable: true,
        elementGuidelines:[document.querySelector(".drag")],
        verticalGuidelines: [0,540,1080],
        horizontalGuidelines: [0,960,1920],
        snapThreshold: 5,
        isDisplaySnapDigit: true,
        snapGap: true,
        snapDirections: {"top":true,"right":true,"bottom":true,"left":true},
        elementSnapDirections: {"top":true,"right":true,"bottom":true,"left":true},
        snapDigit: 0,      
        //bounds: {"left":0,"top":0,"right":1920,"bottom":1080},
      }).on("dragStart", e=>{
        e.set([e.target.model.transx, e.target.model.transy]);      

      }).on("drag", e => {

        e.target.model.transx = e.beforeTranslate[0];
        e.target.model.transy = e.beforeTranslate[1];
        e.target.model.posx   = e.beforeTranslate[0];
        e.target.model.posy   = e.beforeTranslate[1];
        e.target.updateTransElement();

      }).on("dragEnd", e => {
        //undo 추가
        //console.log("dragEnd : " + JSON.stringify(e.target));
        this.saveUndo(e);

      }).on("rotateStart", e => {
        e.set(e.target.model.rotate);

      }).on("rotate", e => {
        e.target.model.rotate = e.beforeRotate;
        e.target.updateTransElement();

      }).on("resizeStart", e => {
        e.setOrigin(["%", "%"]);
        if (e.dragStart) {
          e.dragStart.set([e.target.model.transx, e.target.model.transy]);
        }
      }).on("resize", e => {
        console.log("resize : " + e.direction); //8포인트 방향 알 수 있음

        e.target.model.width  = e.width;
        e.target.model.height = e.height;
        e.target.model.transx = e.drag.beforeTranslate[0];
        e.target.model.transy = e.drag.beforeTranslate[1];

        //width와 height값이 중요하다. 이 정보가 있어야 나중에 복원이 가능함 매트릭스는 공간에 대한 변형 정보라 넓이, 높이 정보가 없음. 그래서 넓이, 높이 + 매트릭스 둘 다 가지고 있어야 함.
        e.target.updateTransElementAll();

      }).on("scale", e => {
        //스케일을 쓸필요가 있나? ratio를 쓰면 될거 같은데?
        console.log("scale : " + e.scale[0] + " / " + e.scale[1]);

      }).on("dragGroupStart", e => {
        e.events.forEach(({ target, set }) => {
          set([target.model.transx, target.model.transy]);
        });
      
      }).on("dragGroup", e => {
        
          e.events.forEach(({ target, beforeTranslate }) => {
            target.model.transx = beforeTranslate[0];
            target.model.transy = beforeTranslate[1];
            target.model.posx   = beforeTranslate[0];
            target.model.posy   = beforeTranslate[1];
            target.updateTransElement();
          });

      }).on("dragGroupEnd", e => {        
        /*        
        e.events.forEach(({ target }) => {
          console.log("dragGroupEnd : " + JSON.stringify(target));
        });
        */
        this.saveUndo(e);

      }).on("rotateGroupStart", e => {
        console.log("rotateGroupStart...");
        e.events.forEach(({target, set, dragStart}) => {
          set(target.model.rotate);
          dragStart.set([target.model.transx, target.model.transy]);
        });

      }).on("rotateGroup", e => {
        e.events.forEach(({ target, beforeRotate, drag }) => {
          const beforeTranslate = drag.beforeTranslate;
          target.model.rotate = beforeRotate;
          target.model.transx = beforeTranslate[0];
          target.model.transy = beforeTranslate[1];
          target.updateTransElement();
        });

      }).on("resizeGroupStart", e => {
        e.events.forEach((ev) => {
          ev.setOrigin(["%", "%"]);
          if(ev.dragStart){
            ev.dragStart.set([ev.target.model.transx, ev.target.model.transy]);
          }
        });

      }).on("resizeGroup", e => {
        e.events.forEach((ev) => {
          ev.target.model.width   = ev.width;
          ev.target.model.height  = ev.height;
          ev.target.model.transx  = ev.drag.beforeTranslate[0];
          ev.target.model.transy  = ev.drag.beforeTranslate[1];
          ev.target.updateTransElementAll();
        });

      }).on("clickGroup", e => {
        this.selecto.clickTarget(e.inputEvent, e.inputTarget);
        console.log("click group...");
      });

    },

    //초기화
    cleanall(){
      this.componentList.splice(0, this.componentList.length);
      const edit_div = document.getElementById("edit_div");
      while(edit_div.hasChildNodes()){
        edit_div.removeChild(edit_div.firstChild);
      }
      this.moveable.destroy();
      this.setMoveable();

    },

    addcomponent(){

      //컴포넌트 모델 생성 (크기, 위치 정보)
      var width  = Math.floor(Math.random() * 150)+50;
      var height = Math.floor(Math.random() * 150)+50;
      var posx = Math.floor(Math.random() * 700)+50;
      var posy = Math.floor(Math.random() * 560)+50;
      var rotate = Math.floor(Math.random() * 360);
    
      let tempcm    = new Component_Model("saveload addcomponent");
      tempcm.width  = width;
      tempcm.height = height;
      tempcm.posx   = posx;
      tempcm.posy   = posy;
      tempcm.rotate = rotate;
      tempcm.transx = posx;
      tempcm.transy = posy;
      tempcm.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
      
      tempcm.text  = Math.floor(Math.random() * 150)+50;
      
      let edit_el = new EditBasic_Element(tempcm);

      const edit_div = document.getElementById("edit_div");
      edit_div.appendChild(edit_el);
      this.componentList.push(edit_el);

    },

    addcomponentFrame(model){
      const target = new EditBasic_Element(model);
      const edit_div = document.getElementById("edit_div");
      edit_div.appendChild(target);
      this.componentList.push(target);
    },    

    testcopy(){
      
      //컴포넌트 복원을 위한 테스트 => 성공함.
      const testdiv     = this.moveable.target[0];  //현재 선택된 타겟을 복제해라.
      var clone_model   = Object.assign({}, testdiv.model); //데이터 카피
      const copy        = new EditBasic_Element(clone_model);

      const edit_div = document.getElementById("edit_div");
      edit_div.appendChild(copy);
      
    },

    printinfo(){

      //엘리먼트 정보 출력해보기
      const testdiv = this.moveable.target[0];
      console.log("model : " + JSON.stringify(testdiv));

      const style1  = window.getComputedStyle(testdiv);
      const matrix1 = new DOMMatrixReadOnly(style1.transform);
      console.log("matrix : " + matrix1);

      //이건 쓰지마 전역 좌표야
      const testrect = testdiv.getBoundingClientRect();
      console.log("getBoundingClientRect : " + JSON.stringify(testrect));

    },

    //매트릭스 값으로 인해서 애니가 잘못된 형태로 이동할 수 있는데 getBoundingClientRect를 이용해서 해결했다.
    testani(){
      
      const testdiv  = this.moveable.target[0];
      
      var targetX  = Math.floor(Math.random() * 150)+30;
      var targetY  = Math.floor(Math.random() * 150)+30;
      var targetR  = Math.floor(Math.random() * 360);
      var aniTime  = Math.floor(Math.random() * 5000)+500;

      //애니
      const aniFrame = [
        { transform: "translate("+testdiv.model.posx+"px, "+testdiv.model.posy+"px) rotate("+testdiv.model.rotate+") scale(1)", opacity:1 },
        { transform: "translate("+Number(targetX)+"px, "+Number(targetY)+"px) rotate("+targetR+"deg) scale(1)", opacity:1 },
      ];
      //애니옵션
      const aniOption = {
        delay: 0,           //시작전 딜레이
        endDelay: 0,        //종료후 딜레이
        duration: aniTime,  //재생시간
        iterations: 1,      //반복횟수
        fill: "forwards",   //애니가 멈춘 위치에서 정지
        easing: "linear"
      }
      testdiv.animate(aniFrame, aniOption);
    },


    testanigroup(){
      

      this.componentList.forEach(testdiv =>{

        var targetX  = Math.floor(Math.random() * 150)+30;
        var targetY  = Math.floor(Math.random() * 150)+30;
        var targetR  = Math.floor(Math.random() * 360);
        var aniTime  = Math.floor(Math.random() * 5000)+500;

        //애니
        const aniFrame = [
          { transform: "translate("+testdiv.model.posx+"px, "+testdiv.model.posy+"px) rotate("+testdiv.model.rotate+") scale(1)", opacity:1 },
          { transform: "translate("+Number(targetX)+"px, "+Number(targetY)+"px) rotate("+targetR+"deg) scale(1)", opacity:1 },
        ];
        //애니옵션
        const aniOption = {
          delay: 0,           //시작전 딜레이
          endDelay: 0,        //종료후 딜레이
          duration: aniTime,  //재생시간
          iterations: 1,      //반복횟수
          fill: "forwards",   //애니가 멈춘 위치에서 정지
          easing: "linear"
        }
        testdiv.animate(aniFrame, aniOption);

      });

    }    
  },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>