<!-- 비회원 웹뷰어를 위한 테스트 페이지 -->
<template>
    
    <div id="info_div" class="d-flex flex-column justify-center align-center" style="width:100%;height:50%">
        <div>새로운 메시지를 확인하세요</div>
        <button @click="contentplay">확인하기</button>
    </div>

    <div id="frame_div" ref="frame_div" style="visibility:hidden;display:flex;justify-content:center;align-items:center;height:100vh" class="checkerboard">
        <div ref="play_div" id="test_div" />
        <!-- <v-btn width="80px" style="position:fixed;left:10px;top;10px" size="small" @click="close_com">Close</v-btn> -->
    </div>

</template>
  
  <script>
  import { useContentStore } from "@/stores/content";
  import { ref } from '@vue/reactivity';
  import AniUtil from "@/nextts/play/AniUtil";
  import Play_Background from '@/nextts/play/Play_Background';
  import FontFaceObserver from 'fontfaceobserver';

  
  export default {
  
    components:{
      
    },
  
    setup(){
      let frame_div       = ref(null);
      let play_div        = ref(null);
      let contentStore    = useContentStore();
      return{
        play_div, contentStore, frame_div
      }
    },
  
    data:()=>({

      content_idx:null,
      content_model:null,
      screen_list:null,
      
      cur_view:null,
      next_view:null,
  
      pos:0,        //스크린 번호
      flag:true,    //백그라운드 전환용 플래그 값
      timerId:null, //타이머를 취소할 수 있는 아이디 : clearTimeout(timerId);
  
      isTotal:true, //전체 재생, 화면재생
  
      //재생하는 모니터의 풀사이즈
      play_width: 1920,
      play_height: 1080,
  
    }),
  
  
    mounted(){


      if(this.$route.query.idx){

        //이전 화면에서 컨텐츠나 에셋을 셋팅해서 들어옴. (수정 진입)
        this.content_idx = this.$route.query.idx;

        //사용자 모드 
        this.loadUserContent(this.content_idx);
        
      }else{
        this.$alert.fire("컨텐츠가 없습니다.");
        this.$router.back();
      }

      this.$emitter.on('movepage', this.handlemovepage);
      document.addEventListener('fullscreenchange', this.handleFullscreenChange);

    },
    
    //화면 종료시 타이머 종료
    beforeUnmount(){
      console.log("com play beforeUnmount");
      this.$emitter.off('movepage');
      document.removeEventListener('fullscreenchange', this.handleFullscreenChange);
      if(this.timerId != null){
        clearTimeout(this.timerId);
      }
    },
  
    methods:{

        contentplay(){
            const info_div = document.getElementById("info_div");
            info_div.style.visibility = "hidden";
            const framediv = document.getElementById("frame_div");
            framediv.style.visibility = "visible";
            
            this.initplay(0, true);
        },

        async loadUserContent(cidx){
      
            this.$http.post(this.$host+'/GuestContentDetail',{
                MCON_IDX:cidx
            })
            .then((result)=>{
                if(result.data.resultCode == 0){
                    const item = result.data.resultData[0];
                    var jobj = JSON.parse(JSON.stringify(item.MCON_JSON));
                    this.contentStore.setContentModel(jobj.width, jobj.height);
                    this.contentStore.content_model.mcon_idx = item.MCON_IDX;
                    this.contentStore.content_model.content_type = 0;
                    this.contentStore.content_model.content_mode = 1; //사용자 모드
                    this.contentStore.content_model.name = item.MCON_NAME;
                    this.contentStore.content_model.localid = item.LOCALID;
                    this.contentStore.content_model.parent_localid = item.PARENT_LOCALID;
                    this.contentStore.content_model.setStringValue(item.MCON_JSON);
                    this.contentStore.createImgList();
                    this.set_content();
                }else{
                    this.$alert.fire("컨텐츠가 없습니다.");
                    this.$router.back();
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                if(error.message == 'Request failed with status code 429')
                {
                    this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
                }else{
                    this.$alert.fire("컨텐츠가 없습니다.");
                    this.$router.back();
                }
            });

        },    


        set_content(){

            //폰트 로드처리
            const screen_list = this.contentStore.content_model.screen_list;
            const fontFaces = [];
            for(const screen of screen_list){
                for(const cp of screen.component_list){

                    if(cp.font_url != null && cp.font_url != undefined && cp.font_url.length > 0){
                        let fonturl = "url('"+cp.font_url+"')";
                        if(cp.font_format != null && cp.font_format != undefined && cp.font_format.length > 0){
                        fonturl += " format('"+cp.font_format+"')";
                        }
                        const fontoption = {weight:cp.font_weight == null || cp.font_weight.length == 0 ? "normal" : cp.font_weight, style:"normal"};
                        const fontface = new FontFace(cp.textfont, fonturl, fontoption);
                        document.fonts.add(fontface);
                        fontFaces.push(fontface);
                    }

                }
            }

            if(fontFaces.length > 0){
                Promise.all(fontFaces.map(this.observeFontLoad))
                .then(() => {
                    console.log('모든 폰트가 성공적으로 로드되었습니다.');
                    //this.initplay(0, true);
                })
                .catch(error => {
                    console.error('폰트 로드 중 오류가 발생했습니다:', error);
                    //this.initplay(0, true);
                });
            }else{
                //로드할께 없으면
                //this.initplay(0, true);
            }      

        },

        //폰트로드 감지
        observeFontLoad(fontFace) {
            const observer = new FontFaceObserver(fontFace.family);
            return observer.load();
        },        

  
      //페이지 이동
      handlemovepage(p) {
        try{
            const page_pos = Number(p); 
            if(this.timerId != null){
              clearTimeout(this.timerId);
              this.timerId = null;
            }
            if(this.screen_list.length > page_pos){
              this.init(page_pos);
            }
          }catch(error){
            console.log("error : " + error);
          }
      },    
  
      //풀스크린 모드가 해제될 때 처리할 로직 추가
      handleFullscreenChange() {
        if(!document.fullscreenElement) {
          console.log('풀스크린 모드가 해제되었습니다.');
          this.close_com();
        }
      },    
  
      //폰트 생성
      createFontFace(fontInfo) {
        return new FontFace(fontInfo.name, `url(${fontInfo.url})`);
      },
  
  
    /**
     * 두 수를 더하는 함수
     * @param {number} p - 재생 페이지 번호
     * @param {boolean} flag - true면 전체 재생, false면 p페이지만 재생
     * @returns {void} - 두 숫자의 합
     */    
     initplay(p, flag) {
  
        this.content_model  = this.contentStore.content_model;
        this.screen_list    = this.content_model.screen_list;
  
        this.play_div.style.width  = this.content_model.width+"px";
        this.play_div.style.height = this.content_model.height+"px";
        this.play_div.style.backgroundColor = "gray";
        
        this.cur_view  = new Play_Background();
        this.cur_view.setInit(this.content_model.width, this.content_model.height);
        this.play_div.appendChild(this.cur_view);
  
        this.next_view  = new Play_Background();
        this.next_view.setInit(this.content_model.width, this.content_model.height);
        this.play_div.appendChild(this.next_view);
  
        this.play_width  = window.screen.width;
        this.play_height = window.screen.height;
        this.frame_div.style.width  = this.play_width + "px";
        this.frame_div.style.height = this.play_height + "px";
  
        
        //해상도 처리
        this.updateDimensions();
      
        //다시 주석처리 해라.
        //onresize = this.resizeevent;  
  
        //풀스크린 재생 (브라우저 주소창등 사라짐)
        this.frame_div.requestFullscreen();
  
        //폰트 로드처리
        const fontFaces = [];
        for(const screen of this.screen_list){
          for(const cp of screen.component_list){
  
            if(cp.font_url != null && cp.font_url != undefined && cp.font_url.length > 0){
              let fonturl = "url('"+cp.font_url+"')";
              if(cp.font_format != null && cp.font_format != undefined && cp.font_format.length > 0){
                fonturl += " format('"+cp.font_format+"')";
              }
              const fontoption = {weight:cp.font_weight == null || cp.font_weight.length == 0 ? "normal" : cp.font_weight, style:"normal"};
              const fontface = new FontFace(cp.textfont, fonturl, fontoption);
              document.fonts.add(fontface);
              fontFaces.push(fontface);
            }
          }
        }
  
        if(fontFaces.length > 0){
          Promise.all(fontFaces.map(this.observeFontLoad))
          .then(() => {
              console.log('모든 폰트가 성공적으로 로드되었습니다.');
              this.init(p, flag);
          })
          .catch(error => {
              console.error('폰트 로드 중 오류가 발생했습니다:', error);
              this.close_com();
          });
        }else{
          //로드할께 없으면
          this.init(p, flag);
        }
  
      },
  
  
  
      destroyplay(){
        if(this.timerId != null){
          clearTimeout(this.timerId);
        }
      },
  
  
      //다이얼로그 닫기
      close_com () {
        
        if(this.timerId != null){
          clearTimeout(this.timerId);
          this.timerId = null;
        }
  
        this.cur_view.exit();
        this.cur_view = null;
  
        this.next_view.exit();
        this.next_view = null;
  
        try{
            const info_div = document.getElementById("info_div");
            info_div.style.visibility = "visible";
            const framediv = document.getElementById("frame_div");
            framediv.style.visibility = "hidden";
          //document.exitFullscreen();
        }catch(er){
          console.log(er);
        }

      },
  
      updateDimensions() {
  
        // 가로와 세로 비율을 계산하여 더 작은 비율을 zoom 값으로 사용
        const scaleX = this.play_width / this.content_model.width;
        const scaleY = this.play_height / this.content_model.height;
        const zoom = Math.min(scaleX, scaleY);
  
        // 정가운데 정렬을 위한 translate 사용
        this.play_div.style.position = 'absolute';
        this.play_div.style.top = '50%';
        this.play_div.style.left = '50%';
        this.play_div.style.transformOrigin = '50% 50%';
        this.play_div.style.transform = `translate(-50%, -50%) scale(${zoom})`;
  
      },
  
      //컨텐츠 재생
      init(p, flag){
        //컨텐츠 재생 사운드 처리 (일단 패스)
        //전체 캡션 처리 (일단 패스)
        this.pos = p;
        this.isTotal = flag;
        this.transBackground();
      },
  
      //화면전환
      async transBackground(){
        
        console.log("transBackground... " + this.pos + "/"+this.flag);
        let screen_model = this.screen_list[this.pos];
  
        if(this.flag){
          //cur_view 재생
          this.next_view.style.zIndex   = String(1);
          this.cur_view.style.zIndex    = String(2);
  
          //여기서 모든 뷰를 다 깔끔하게 그리고 들어와야 하는데.. 넘 느린데?
          await this.cur_view.setModel(screen_model);
          this.cur_view.style.opacity = 1.0;
          
          if(this.screen_list[this.pos].aniplaymodel != null && this.screen_list[this.pos].aniplaymodel.anilist != null && this.screen_list[this.pos].aniplaymodel.anilist.length > 0){
            //진입 화면전환 애니매이션
            this.cur_view.inAni(this.inani_Complete);
            
          }else{
            //진입 화면전환 애니매이션이 없으면 디폴트 진입 애니매이션 실행.
            let inani = AniUtil.getDefaultInAni();
            this.cur_view.setInPlayAni(inani, this.inani_Complete);
          }
          //화면 아웃 애니매이션
          let outani = AniUtil.getDefaultOutAni();
          this.next_view.setOutPlayAni(outani, this.outani_Complete(true));
    
        }else{
          //next_view  재생
          this.cur_view.style.zIndex    = String(1);
          this.next_view.style.zIndex   = String(2);
  
          //여기서 모든 뷰를 다 깔끔하게 그리고 들어와야 하는데.. 넘 느린데?
          await this.next_view.setModel(screen_model);
          this.next_view.style.opacity = 1.0;
          
          if(this.screen_list[this.pos].aniplaymodel != null && this.screen_list[this.pos].aniplaymodel.anilist != null && this.screen_list[this.pos].aniplaymodel.anilist.length > 0){
            //진입 화면전환 애니매이션
            this.next_view.inAni(this.inani_Complete);
          }else{
            //진입 화면전환 애니매이션이 없으면 디폴트 진입 애니매이션 실행.
            let inani = AniUtil.getDefaultInAni();
            this.next_view.setInPlayAni(inani, this.inani_Complete);
          }
          //화면 아웃 애니매이션
          let outani = AniUtil.getDefaultOutAni();
          this.cur_view.setOutPlayAni(outani, this.outani_Complete(false));
  
        }
        
  
      },
  
      //화면전환 애니매이션 종료시
      inani_Complete(){
  
        console.log("inani_Complete..." + this.flag);
  
        if(this.flag){
          this.cur_view.init();
        }else{
          this.next_view.init();
        }
  
        //다음 페이지 실행
        var delay = 0;
        
        //해당 스크린의 애니매이션 타임중 제일 긴 시간 가져오기 + 화면대기시간
        this.screen_list[this.pos].component_list.forEach(cm=>{
          //컴포넌트의 애니매이션 시간과 재생시간중 가장 큰 값 가져오기
          delay = Math.max(delay, cm.getAniDuration());
          //console.log("delay1 : " + delay);
          delay = Math.max(delay, cm.getPlayDuration());
          //console.log("delay2 : " + delay);
        });
        
        
        //스크린의 대기시간 추가
        delay += Number(this.screen_list[this.pos].trans_time);
  
        //next page 사용체크
        if(this.screen_list[this.pos].next_name != null && this.screen_list[this.pos].next_name != undefined && this.screen_list[this.pos].next_name.length > 0){
          //page 찾기
          const index = this.screen_list.findIndex(obj => obj.screen_name === this.screen_list[this.pos].next_name);
          if(index > -1){
            this.pos = index;
          }else{
            if(this.pos+1 < this.screen_list.length){
              this.pos += 1;
            }else{
              this.pos = 0;
            }
          }
        }else{
          if(this.pos+1 < this.screen_list.length){
            this.pos += 1;
          }else{
            this.pos = 0;
          }
        }

  
        if(this.flag){
          this.flag = false;
        }else{
          this.flag = true;
        }
        
        const nextRun = this.transBackground;
        this.timerId = setTimeout(function() {        
          nextRun();
        }, delay * 1000);      
  
      },
  
      outani_Complete(outflag){
        if(outflag){
          console.log("outani_Complete... true");
          this.next_view.exit();
        }else{
          console.log("outani_Complete... false");
          this.cur_view.exit();
        }
      },
  
      resizeevent(){
        console.log("resizeevent ");
        this.updateDimensions();
      },
  
    }
  
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .checkerboard {
    background: repeating-linear-gradient(45deg, #444, #444 10px, #888 0, #888 20px);
  }
  </style>