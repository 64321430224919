<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 4">
    <v-container fluid class="pt-0">

        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.color_type"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="changecomponenttype"
                    >
                    <v-btn value="color" width="100" size="x-small">Color</v-btn>
                    <v-btn value="gradient" width="100" size="x-small">Gradient</v-btn>
                </v-btn-toggle>                
            </v-col>
        </v-row>        

        <v-row justify="center" align="center" dense class="mt-0 pt-0">
            <v-col cols="12" v-if="contentStore.select_component.model.color_type == 'color'">
                <dialogbgcolor dense title="BG Color" ref="bgcolor" />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" dense class="mt-5 mb-4 pt-0" 
            v-if="contentStore.select_component.model.color_type == 'gradient'">
            <v-col cols="12"  align="center">
                <dialoggrcolor dense title="BG Color" property="backgroundColor"/>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-0 pt-0" dense>
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="Border Radius" property="border_radius" :min="0" :max="300" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
export default {
  
  setup(){
    let bgcolor      = ref(null);
    const contentStore = useContentStore();
    return {bgcolor, contentStore}
  },

  methods:{
    
    changecomponenttype(){
        this.updateRender();
    },

    changemodel(){

        //직접 입력으로 수치 변경시 문자열로 좌표가 추가되는 오류가 발생함. 한번 컨버팅 해줘야 버그가 안생김.
        this.contentStore.select_component.model.transx = Number(this.contentStore.select_component.model.transx);
        this.contentStore.select_component.model.transy = Number(this.contentStore.select_component.model.transy);
        
        //컴포넌트 다시 그리고
        this.contentStore.select_component.updateTransElementAll();

        //무브어블 다시 선택
        this.$emit("reselectmoveable");
        //this.$emitter.emit("reselectmoveable");

    },

    updateRender(){
        //컴포넌트 타입이나 배경타입 변경시 렌더링 다시
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },


  },

  //프로퍼티 선언
  props:{
    
  },  

  data(){
    return{
        
    }
  },

  mounted(){
    
  },

  
}
</script>