<template>
    <v-container fluid class="pt-0">
    <template v-if="contentStore.select_component != null">

        <comani ref="comani" @callback="select_comani" />
        <commyani ref="commyani" @callback="select_myani" />

        <!-- 우측 애니매이션 인포 (컴포넌트용) -->
        <v-row>
            <v-dialog hide-overlay persistent v-model="effect_dialog" max-width="500px" >
                <v-card>
                <v-card-title>Animation Setting</v-card-title>
                <v-card-text class="ma-0 pl-5 pr-5 pt-3">
                    <v-container>

                        <v-row align="center">
                            <v-col cols="6" align="center" class="ma-0 pa-0">
                                <label style="font-size:12px">Effect Name</label>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                <input 
                                    v-model="animodel.aniname" 
                                    style="padding-left:6px;height:26px;width:210px;font-size:12px;border: solid 1px gray;border-radius:3px;"
                                    />
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">Wait</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.delay" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">Duration</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.duration" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">X</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.movex" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">Y</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.movey" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>               
                        

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">CenterX</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.centerx" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="7" align="center" class="pa-0 ma-0">
                                        <label style="font-size:12px">CenterY</label>
                                    </v-col>
                                    <v-col cols="5" class="ma-0 pa-0">
                                        <input 
                                            v-model="animodel.centery" 
                                            style="width:70px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>                                   

                        <v-row justify="center" align="center" class="mt-1 pt-0">
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="Scale" property="scale" :min="0.1" :max="10" :step="0.01" />
                            </v-col>
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="Rolate" property="rotate" :min="-360" :max="360" :step="1" />
                            </v-col>
                        </v-row>

                        <v-row justify="center" align="center" class="mt-1 pt-0">
                            <v-col cols="6">
                                <comeditslider :object="animodel" title="Opacity" property="alpha" :min="0" :max="1" :step="0.01" />
                            </v-col>
                            <v-col cols="6">
                            </v-col>
                        </v-row>

                        <v-row align="center">
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Ease</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <select 
                                            v-model="animodel.ease"
                                            style="width:100%;border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                                            <template v-for="cptype in easelist" :key="cptype.name">
                                                <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                                            </template>
                                        </select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Out</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <select 
                                            v-model="animodel.easetype"
                                            style="width:100%;border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                                            <template v-for="cptype in easetype" :key="cptype.name">
                                                <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                                            </template>
                                        </select>                                        
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6"><v-divider/></v-row>

                        <!-- 커스텀 속성 추가 -->
                        <v-row align="center" class="mt-3" >
                            <v-col cols="6">
                                <label style="width:50px;text-align:center;font-size:12px;font-weight:bold">Custom Property</label>
                            </v-col>
                            <v-col cols="6">
                                <v-btn @click="addprop" density="compact" size="small" variant="text" style="font-size:11px">Add</v-btn>
                            </v-col>
                        </v-row>

                        <v-row align="center" v-for="prop in animodel.proplist" :key="prop">

                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Prop Type</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <select 
                                            v-model="prop.proptype"
                                            style="width:100%;border: 1px gray solid; border-radius:3px; padding-left:5px;font-size:14px">
                                            <template v-for="prtype in proptype" :key="prtype.name">
                                                <option style="font-size:12px" :value="prtype.value">{{prtype.name}}</option>
                                            </template>
                                        </select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" >
                            </v-col>

                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Property</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <input 
                                            v-model="prop.propname" 
                                            style="width:100%;margin-left:0px;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0">
                                        <label style="width:50px;text-align:center;font-size:12px">Value</label>
                                    </v-col>
                                    <v-col cols="6" class="ma-0 pa-0">
                                        <input 
                                            v-model="prop.propvalue" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>

                            <!-- number 속성 -->
                            <v-col cols="6" class="mt-1" v-if="prop.proptype == 'number'">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0" >                                    
                                        <label style="font-size:12px">Min</label>
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0">
                                        <input
                                            v-model="prop.propmin" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1" v-if="prop.proptype == 'number'">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0" >                                    
                                        <label style="font-size:12px">Max</label>
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0">
                                        <input
                                            v-model="prop.propmax" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="mt-1" v-if="prop.proptype == 'number'">
                                <v-row>
                                    <v-col cols="6" align="center" class="pa-0 ma-0" >
                                        <label style="font-size:12px">Step</label>
                                    </v-col>
                                    <v-col cols="6" class="pa-0 ma-0" >
                                        <input 
                                            v-model="prop.propstep" 
                                            style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;padding-left:5px;"
                                            />
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-divider class="mt-1"/>
                        </v-row>
                        

                    </v-container>
                </v-card-text>

                <v-card-actions class="ma-0 pa-0">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="effect_dialog = false">취소</v-btn>
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0">
                        <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save_effect">확인</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>

                </v-card>
            </v-dialog>  
        </v-row>        

        <v-row align="center" justify="center" class="mt-3" dense>
            <v-btn variant="text" size="small" @click="load_assetani">Asset</v-btn>
            <v-btn variant="text" size="small" @click="load_myani">MY</v-btn>
        </v-row>

        <v-row align="center">
            <label style="width:70px;text-align:right;font-size:12px;margin-right:10px">Repeat</label>
            <input 
                v-model="contentStore.select_component.model.aniplaymodel.repeat" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>
        <v-row align="center">
            <label style="width:70px;text-align:center;font-size:12px;margin-right:10px">Repeat Wait</label>
            <input 
                v-model="contentStore.select_component.model.aniplaymodel.repeatDelay" 
                @change="changemodel" style="width:50px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
        </v-row>
        <v-row align="center">
            <label style="width:70px;text-align:center;font-size:12px;margin-left:10px;margin-right:0px">Reverse</label>
            <input type="checkbox" v-model="contentStore.select_component.model.aniplaymodel.yoyo"/>
        </v-row>

        <v-row>
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

        <v-row align="center">
            <v-col cols="12" align="center">
                <v-btn style="font-size:11px" variant="tonal" @click="new_effect"
                    prepend-icon="mdi-transfer-down"
                    >Add Effect
                </v-btn>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col cols="12">
                <v-divider/>        
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pa-0" dense>
            <v-col cols="7">
                <div style="font-size:12px">Effect List</div>
            </v-col>
            <v-col cols="2" align="center">
                <v-btn @click="anilistplay" density="compact" size="small" variant="text" style="font-size:11px">PLAY</v-btn>
            </v-col>
            <v-col cols="2" align="center">
                <v-btn @click="aniliststop" density="compact" size="small" variant="text" style="font-size:11px">STOP</v-btn>
            </v-col>
        </v-row>
        <v-divider/>

        <v-row justify="center" align="center" class="pa-0 ma-0 mt-1" dense>
            <draggable
              :list="contentStore.select_component.model.aniplaymodel.anilist"
              class="pa-0 ma-0"
              ghost-class="ghost" 
              >
              <template #item="{ element }">
                  <tr style="border-bottom: 1px solid black;">
                      <td class="tbline" style="vertical-align: middle;text-align:center;cursor:grab"><v-icon small>mdi-drag</v-icon></td>
                      <td class="text-left tbline" style="font-size:12px;vertical-align: middle;cursor:pointer" >
                        <div style="width:150px;max-width:150px;overflow-wrap: break-word;" @click="editeffect(element)">{{element.aniname}}</div>
                      </td>
                      <td class="pa-0 tbline" style="vertical-align: middle;">
                          <v-icon @click="effectplay(element)" color="black" icon="mdi-play" size="x-small"></v-icon>
                          <v-icon @click="deleffect(element)" color="black" icon="mdi-delete" size="x-small"></v-icon>
                      </td>
                  </tr>
              </template>
              
            </draggable>    
          </v-row>
  


        <v-row align="center" justify="center" class="mt-5">
            <v-btn variant="text" size="small" @click="addmyanimation">Add MyAni</v-btn>
        </v-row>

    </template>
    </v-container>
</template>

<script>
import Edit_Animation from '@/nextvue/infopanel/Edit_Animation.vue'
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import Animation_Model from '@/nextts/model/Animation_Model'
import AnimationPlay_Model from '@/nextts/model/AnimationPlay_Model'
import draggable from 'vuedraggable' //https://github.com/SortableJS/vue.draggable.next
import AniProperty_Model from '@/nextts/model/AniProperty_Model';

export default {

  components:{
    // eslint-disable-next-line vue/no-unused-components
    draggable, Edit_Animation
  },

  setup(){
    let comani       = ref(null);
    let commyani     = ref(null);
    let bgcolor      = ref(null);
    const contentStore = useContentStore();
    return {comani, commyani, bgcolor, contentStore}
  },

  methods:{
    
    addprop(){
        var apm = new AniProperty_Model();
        this.animodel.proplist.push(apm);
    },

    load_assetani(){
        this.comani.dialog_com = true;
    },

    load_myani(){
        this.commyani.dialog_mycom = true;
    },
    
    //Com_Ani 콜백
    select_comani(item){
      const select_ani = new AnimationPlay_Model();
      select_ani.setStringValue(item.ANI_JSON);
      this.contentStore.select_component.model.aniplaymodel = select_ani;
    },

    select_myani(item){
      const select_ani = new AnimationPlay_Model();
      select_ani.setStringValue(item.ANI_JSON);
      this.contentStore.select_component.model.aniplaymodel = select_ani;
    },

    changecomponenttype(){
        this.updateRender();
    },

    changemodel(){

        //직접 입력으로 수치 변경시 문자열로 좌표가 추가되는 오류가 발생함. 한번 컨버팅 해줘야 버그가 안생김.
        this.contentStore.select_component.model.transx = Number(this.contentStore.select_component.model.transx);
        this.contentStore.select_component.model.transy = Number(this.contentStore.select_component.model.transy);
        
        //컴포넌트 다시 그리고
        this.contentStore.select_component.updateTransElementAll();

        //무브어블 다시 선택
        this.$emit("reselectmoveable");
        //this.$emitter.emit("reselectmoveable");

    },

    updateRender(){
        //컴포넌트 타입이나 배경타입 변경시 렌더링 다시
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },


    save_effect(){
        /*
        let add      = reactive(new Animation_Model());
        add.aniname  = this.animodel.aniname;
        add.delay    = this.animodel.delay;
        add.duration = this.animodel.duration;
        add.movex    = this.animodel.movex;
        add.movey    = this.animodel.movey;
        add.scale    = this.animodel.scale;
        add.rotate   = this.animodel.rotate;
        add.alpha    = this.animodel.alpha;
        */
       if(!this.contentStore.select_component.model.aniplaymodel.anilist.includes(this.animodel)){
            this.contentStore.select_component.model.aniplaymodel.anilist.push(this.animodel);
       }
       this.effect_dialog = false;
    },

    new_effect(){
        this.animodel = reactive(new Animation_Model());
        this.effect_dialog = true;
    },
    
    editeffect(am){
        this.animodel = am;
        this.effect_dialog = true;
    },

    //이펙트 재생
    effectplay(am){
        this.contentStore.select_component.playEffect(am);
    },

    //이펙트 삭제
    deleffect(am){
        const idx = this.contentStore.select_component.model.aniplaymodel.anilist.indexOf(am);
        this.contentStore.select_component.model.aniplaymodel.anilist.splice(idx, 1);
    },    

    //애니플레이 모델 재생(전체 이펙트)
    anilistplay(){
        this.$emitter.emit("clearSelect");
        this.contentStore.select_component.playani();
    },    

    //애니플레이 중지
    aniliststop(){
        this.contentStore.select_component.stopAni();
    },

    //현재 애니매이션을 나의 애니매이션에 추가하기
    addmyanimation(){
      this.$alert.fire({
        title: '추가하실 애니매이션 이름을 입력하세요',
        input: 'text',
        inputPlaceholder: '애니매이션을 구분하기 위한 이름을 입력하세요',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          const text = result.value;
          this.updateaniplay(text);
        }
      });
    },

    async updateaniplay(aniname){
        const SEARCH_LOCALID    = this.$storage.getStorageSync("SEARCH_LOCALID");
        var temp_obj  = JSON.parse(JSON.stringify(this.contentStore.select_component.model.aniplaymodel));
        //토큰값 체크
        let token;
        try{
        token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
        token = this.$storage.getStorageSync("FIRETOKEN");
        }

        //신규
        this.$http.post(this.$host+'/MyAnimationInsert',{
              MYANI_NAME:escape(aniname)
            , ANI_JSON:escape(JSON.stringify(temp_obj))
            , LOCALID:SEARCH_LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
            if(result.data.resultCode == 0){
                this.$alert.fire('추가가 완료되었습니다.').then(()=>{
                });
            }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
            }else{
                this.$alert.fire('추가에 실패했습니다.');
            }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
            console.log("insert error : " + error);
        });
    },

  },

  //프로퍼티 선언
  props:{
    
  },  

  data(){

    return{
        
        effect_dialog:false,
        animodel: reactive(new Animation_Model()),

        easelist:[
                {name:"none",   value:"none"}
            ,   {name:"power1", value:"power1"}
            ,   {name:"power2", value:"power2"}
            ,   {name:"power3", value:"power3"}
            ,   {name:"power4", value:"power4"}
            ,   {name:"back",   value:"back"}
            ,   {name:"elastic",value:"elastic"}
            ,   {name:"bounce", value:"bounce"}
            ,   {name:"rough",  value:"rough"}
            ,   {name:"slow",   value:"slow"}
            ,   {name:"steps",  value:"steps"}
            ,   {name:"circ",   value:"circ"}
            ,   {name:"expo",   value:"expo"}
            ,   {name:"sine",   value:"sine"}
        ],
        easetype:[
                {name:"in",     value:"in"}
            ,   {name:"out",    value:"out"}
            ,   {name:"inOut",  value:"inOut"}
        ],

        proptype:[
                {name:"string", value:"string"}
            ,   {name:"number", value:"number"}
            ,   {name:"color",  value:"color"}
        ]

    }
  },

  mounted(){
    
  },

  
}
</script>