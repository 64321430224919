<!-- 현재 해당 페이지는 사용안함 -->
<template>

  <v-container fluid >

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />

    <!-- 라이센스 정보 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="license_dialog" max-width="800px">

        <v-card class="pa-1 ma-0">
          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
              <v-row>
                <v-col>
                  <!-- <div style="white-space:pre-wrap">{{ this.licenseitem.LICENSE_NAME }}</div> -->
                   <div style="white-space:pre-wrap" v-html="this.licenseitem.LICENSE_NAME"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex">
                  <v-btn @click="opensite(licenseitem.LICENSE_URL)">Link</v-btn>
                  <v-spacer/>
                  <v-btn color="black" @click="license_dialog = false">닫기</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

      </v-dialog>
    </v-row>

    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog1" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">폰트</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense>
                <v-text-field 
                  single-line v-model="editedItem1.FONT_NAME" dense
                  variant="outlined" density="compact"
                  placeholder="폰트명을 입력하세요" color="#808080" hide-details
                  />
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12">
                  <label class="mytextstyle">폰트 언어</label>
                </v-col>
              </v-row>
              <v-row dense align="center" justify="center" class="mt-1">
                <v-col cols="12">
                  <v-select v-model="editedItem1.FONT_NATION" :items="FONTNATIONLIST"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    variant="outlined" density="compact"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>              

              <v-row dense>
                <v-textarea
                  single-line v-model="editedItem1.LICENSE_NAME" dense
                  variant="outlined" density="compact"
                  placeholder="라이센스명을 입력하세요" color="#808080" hide-details
                  />
              </v-row>

              <v-row dense>
                <v-btn-toggle
                  v-model="LICENSE_TYPE"
                  color="deep-purple-accent-3"
                  rounded="0"
                  group
                  >
                  <v-btn :value="0">
                    직접입력
                  </v-btn>
                  <v-btn :value="1">
                    파일첨부
                  </v-btn>
                </v-btn-toggle>                
              </v-row>
              <v-row dense v-if="LICENSE_TYPE == 0">
                <v-text-field 
                  single-line v-model="editedItem1.LICENSE_URL_REF" dense
                  variant="outlined" density="compact" 
                  placeholder="라이센스 URL" color="#808080" hide-details
                  />
              </v-row>
              <v-row dense v-if="LICENSE_TYPE == 1">
                <template v-if="editedItem1.LICENSE_URL_REF == null || editedItem1.LICENSE_URL_REF.length < 1">
                  <v-file-input 
                    height="48" class="popupiputstyle" density="compact" variant="outlined"
                    prepend-icon="mdi-file"
                    show-size hide-details v-model="uploadFile" 
                    @change="changefile"
                    />
                </template>
                <template v-else>
                  <v-text-field 
                    single-line v-model="editedItem1.LICENSE_URL_REF" dense
                    variant="outlined" density="compact" 
                    placeholder="라이센스 URL" color="#808080" hide-details
                    />
                </template>
              </v-row>
              
              <v-row class="mt-3">
                <v-col cols="12">
                  <label class="mytextstyle">상태</label>
                </v-col>
              </v-row>
              <v-row dense align="center" justify="center" class="mt-1">
                <v-col cols="12">
                  <v-select v-model="editedItem1.FONT_STATE" :items="STATELIST"
                    item-title="name" item-value="value" class="dialogselectstyle"
                    variant="outlined" density="compact"
                    hide-details elevation="0" dense height="48px"
                    />
                </v-col>
              </v-row>              

            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save1">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>


    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ FONT_NAME }} {{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>

              <v-row dense class="mt-3">
                <v-text-field 
                  single-line v-model="editedItem.FONTWEIGHT_NAME" dense
                  variant="outlined" density="compact"
                  placeholder="FONT 굵기명을 입력하세요" color="#808080" hide-details
                  />
              </v-row>

              <v-row dense class="mt-3">
                <v-text-field 
                  single-line v-model="editedItem.FONT_FAMILY" dense
                  variant="outlined" density="compact"
                  placeholder="FONT FAMILY를 입력하세요" color="#808080" hide-details
                  />
              </v-row>

              <v-row dense class="mt-3">
                <v-text-field 
                  single-line v-model="editedItem.FONT_WEIGHT" dense
                  variant="outlined" density="compact"
                  placeholder="FONT WEIGHT를 입력하세요" color="#808080" hide-details
                  />
              </v-row>

              <v-row dense class="mt-3">
                <v-text-field 
                  single-line v-model="editedItem.FONT_FORMAT" dense
                  variant="outlined" density="compact"
                  placeholder="FONT FORMAT을 입력하세요" color="#808080" hide-details
                  />
              </v-row>
  
            <v-row dense class="mt-3">
              <template v-if="editedItem.FONT_PATH_REF == null || editedItem.FONT_PATH_REF.length < 1">
                <v-file-input 
                  height="48" class="popupiputstyle" density="compact" variant="outlined"
                  prepend-icon="mdi-file"
                  show-size hide-details v-model="uploadFile" 
                  @change="changefile"
                  />
              </template>
              <template v-else>
                <v-text-field 
                  single-line v-model="editedItem.FONT_PATH_REF" dense
                  variant="outlined" density="compact" 
                  placeholder="url" color="#808080" hide-details
                  />
              </template>
            </v-row>

            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

  <v-row align="center" justify="start" class="mt-5">
    <v-col cols="6" md="3">
      <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
    </v-col>
    <v-spacer/>
  </v-row>
    
  <v-row align="start" dense>
    <v-col cols="12" md="6"> 

      <v-row>
        <v-col cols="12" align="end">
          <v-btn @click="newinsert1" size="small" variant="flat" color="black">추가</v-btn>
        </v-col>
      </v-row>

      <!-- 테이블 -->
      <v-table fixed-header density="compact" class="mt-1">
        <thead>
          <tr>
            <th style="width:80px; text-align:center" class="text-center">폰트명</th>
            <th style="width:80px; text-align:center" class="text-center">상태</th>
            <th style="width:80px; text-align:center" class="text-center">라이센스</th>
            <th style="width:80px; text-align:center" class="text-center">수정</th>
          </tr>
        </thead>
        <template v-if="itemList1.length == 0">
          <tr>
            <td colspan="7"><div class="text-center">데이터가 없습니다.</div></td>
          </tr>
        </template>
        <template v-else>
          <tbody>
            <tr v-for="element in itemList1" :key="element.FONT_IDX">
              <td style="width:80px; text-align:center">
                <a style="cursor:pointer;color:#0002ff" @click="loadsub(element)">{{element.FONT_NAME}}</a>
              </td>
              <td style="width:60px; text-align:center">{{element.FONT_STATE == 0 ? '사용안함' : '사용'}}</td>
              <td style="width:100px; text-align:center">
                <a style="cursor:pointer;color:#0002ff" @click="openlicense(element)">라이센스</a>
              </td>
              <td style="width:80px; text-align:center">
                <v-icon class="mr-2" small @click="edit_item1(element)">mdi-pencil</v-icon>
                <v-icon class="mr-2" small @click="delete_item1(element)">mdi-delete</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-table>      

      <!-- 하단검색 -->
      <searchbar ref="searchbar" @searchcallback="search_run"/>
      
      <!-- 하단페이징영역 -->
      <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  

    </v-col>

    <v-col cols="12" md="6"> 
      <v-row>
        <v-col cols="12" align="start" class="d-flex">
          <div>{{FONT_NAME}}</div>
          <v-spacer/>
          <v-btn @click="newinsert" size="small" variant="flat" color="black">추가</v-btn>
          <v-btn @click="saveSort" size="small" variant="flat" color="black" class="ml-1">순서저장</v-btn>
        </v-col>
      </v-row>
    
      <!-- 테이블 -->
      <v-table fixed-header density="compact" class="mt-1">
        <thead>
          <tr>
            <th style="width:50px;" class="text-center"></th>
            <th style="width:80px; text-align:center" class="text-left">구분</th>
            <th style="width:150px; text-align:center" class="text-center">예시</th>
            <th style="width:80px; text-align:center" class="text-center">수정</th>
          </tr>
        </thead>
        <template v-if="itemList.length == 0">
          <tr>
            <td colspan="7"><div class="text-center">데이터가 없습니다.</div></td>
          </tr>
        </template>
        <template v-else>
          <draggable v-model="itemList" tag="tbody" item-key="CATEGORY_IDX">
            <template #item="{ element }">
              <tr>
                <td style="width:50px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                <td style="width:80px; text-align:left">{{element.FONTWEIGHT_NAME}}</td>
                <td>
                  <template v-if="element.FONT_WEIGHT != null && element.FONT_WEIGHT.length > 0">
                    <label :style="`font-size:20px; font-family:${element.FONT_FAMILY};font-weight:${element.FONT_WEIGHT}`"> 안녕하세요 Hello 123456 </label>
                  </template>
                  <template v-else>
                    <label :style="'font-size:20px; font-family:'+element.FONT_FAMILY"> 안녕하세요 Hello 12345 </label>
                  </template>
                </td>
                <td style="width:80px; text-align:center"><v-icon class="mr-2" small @click="edit_item(element)">mdi-pencil</v-icon><v-icon small @click="delete_item(element)">mdi-delete</v-icon></td>
              </tr>
            </template>
          </draggable>
        </template>
      </v-table>

    </v-col>
  </v-row>


  </v-container>
</template>

<script>
import { ref as fref, uploadBytesResumable } from "firebase/storage";
import draggable from 'vuedraggable'
import { ref } from '@vue/reactivity';
export default {
    components:{
      draggable
    },

    setup(){
        var paging        = ref(null);
        var searchbar     = ref(null);
        var editor = ref(null);
        return {paging, searchbar, editor};
    },

    data: () => ({

      license_dialog:false,
      licenseitem:null,

      progress_dialog:false,
      progress_value:0,
      uploadFile : [],
      previewImg1 : "",
      imgSrc:'',
      crop_dialog:false,
      photo_index:0,

      FONTNATIONLIST: [
          {name:'영어',     value:0}
        , {name:'한국어',   value:1}
        , {name:'일본어',   value:2}
      ],

      STATELIST: [
          {name:'사용안함',   value:0}
        , {name:'사용',       value:1}
      ],

      FONT_NAME: "",
      FONT_IDX: null,
      LICENSE_TYPE: 0,
      itemList1: [],

      //필수 페이징 파라메터
      page : 0,
      totalcnt1 : 0,       //전체 아이템 개수
      totalcnt2 : 0,       //전체 아이템 개수
      loading : false,    //로딩 표시

      dialog1:false,
      editedItem1:{},
      editedIndex1: -1,
      defaultItem1: {
        FONT_NAME:'',
        FONT_NATION: 1,
        FONT_STATE: 1
      },

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          FONTWEIGHT_NAME:"",
          FONT_FAMILY:"",
          FONT_WEIGHT:"",
          FONT_FORMAT:"woff2",
          FONT_SORT:1,
          FONT_PATH_REF:null
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '', key: '', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {title: '이미지', key: 'CATEGORY_IMG', sortable: false, align:'center', width:80, class:"cheader_normal"},
        {title: '카테고리명', key: 'CATEGORY_NAME', sortable: false, align:'center', class:"cheader_normal"},
        {title: '상태', key: 'CATEGORY_STATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '등록일', key: 'REG_DATE', sortable: false, align:'center', class:"cheader_normal" },
        {title: '수정', key: 'actions', sortable: false, align:'center', class:"cheader_normal"}
      ],
      itemList: [],

      //검색 파라메터
      sort_name: 'REG_DATE',
      sort_type: false,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

    }),

    mounted(){

      if(this.$storage.getStorageSync("GRADE") < 80){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.").then(()=>{
          this.$router.back();
        });
        return;
      }

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      getTypename(type){
        for(var i=0;i<this.itemList1.length;i++){
          const item = this.itemList1[i];
          if(item.value == type){
            console.log(item.name);
            return item.name;
          }
        }
      },

      //이미지 선택시
      changefile(){

      },

      //지정한 data를 넣은 이미지를 리턴한다.
      loadImage(data){
        const myImage = new Image();
        myImage.src = data;
        return new Promise((resolve)=>{
          myImage.onload = () => resolve(myImage)
        })
      },

      //캔버스 TO BLOB
      dataURItoBlob(dataURI){
        const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const max = bytes.length;
        const ia = new Uint8Array(max);
        for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      },

      //firebase storage에 파일 업로드 및 프로그레스 표시
      uploadFileStorage(storage_ref, blobd_ata, metadata){
        return new Promise((resolve, reject)=>{

            const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running');
                    break;
                }                
              },
              (error) => {
                reject(null);
              },
              () => {
                // Upload completed successfully - 업로드 완료시 경로 리턴
                resolve(uploadTask.snapshot.ref);
              }
            );

        })
      },

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage();
      },

      async loadpage(p){
          console.log("loadpage...");
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/FontList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                this.totalcnt1 = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt1, this.pagerow);
                const list = result.data.resultData;
                this.itemList1.splice(0, this.itemList1.length);   //데이터 초기화
                list.forEach(element => {
                  this.itemList1.push(element); 
                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },      

      openlicense(item){
        this.licenseitem = item;
        this.license_dialog = true;
      },

      opensite(license_url){
        if(String(license_url).toLowerCase().startsWith("http")){
          window.open(license_url);
        }
      },

      loadsub(item){
        this.FONT_NAME = item.FONT_NAME;
        this.FONT_IDX = item.FONT_IDX;
        this.loadsublist();
      },

      async loadsublist(){
          //토큰값 체크
          let token;
          try{
            token = await this.$fireauth.currentUser.getIdToken();
          }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
          }

          this.loading = true;
          this.$http.post(this.$host+'/FontWeightList',{
            FONT_IDX: this.FONT_IDX
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){

                this.totalcnt2 = result.data.totalcnt;
                
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                  this.itemList.push(element); 

                  //폰트 등록
                  let fonturl = "url('"+element.FONT_PATH_REF+"')";
                  if(isNaN(element.FONT_FORMAT)){
                    fonturl += " format('"+element.FONT_FORMAT+"')"
                  }
                  let fontoption = {weight:element.FONT_WEIGHT ? element.FONT_WEIGHT : "normal", style:"normal"};
                  var temp_font = new FontFace(element.FONT_FAMILY, fonturl, fontoption);
                  document.fonts.add(temp_font);

                });

                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(1);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog1    = false;
        this.dialog     = false;
        setTimeout(() => {
          this.editedItem1 = Object.assign({}, this.defaultItem1);
          this.editedItem  = Object.assign({}, this.defaultItem);
          this.editedIndex = -1;
          this.editedIndex1= -1;
        }, 300)
      },


      //추가 저장
      async save1 () {

        if(this.isNullCheck(this.editedItem1.FONT_NAME)){
          this.$alert.fire("폰트이름을 입력하세요");
          return;
        }

        if(this.LICENSE_TYPE == 1){
          //파일첨부
          if(this.uploadFile == null || this.uploadFile == undefined || this.uploadFile.length < 1){
              if(this.isNullCheck(this.editedItem1.LICENSE_URL_REF)){
                this.$alert.fire("라이센스를 등록하세요");
                return;
              }
          }else{
            this.progress_dialog = true;            
            let curdate = Date.now().toString();
            var extension = this.uploadFile[0].name.split('.').pop();  //확장자 가져오기
            const uploadfile_ref1 = fref(this.$firestorage, "fontres/"+curdate+"."+extension);
            const metadata1 = { contentType: 'application/octet-stream', resumable: false }; 
            let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadFile[0], metadata1);
            
            if(upload_cb1 == null){
              this.progress_dialog = false;
              this.$alert.fire("라이센스 등록 오류 발생");
              return;
            } 
            this.uploadFile = [];
            this.editedItem1.LICENSE_URL_REF = upload_cb1._location.path_;          
          }
        }


        if(this.editedIndex1 > -1){

            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/FontUpdate', {
              FONT_NAME: this.editedItem1.FONT_NAME == null || this.editedItem1.FONT_NAME == undefined ? '' : escape(this.editedItem1.FONT_NAME)
            , LOCALID: this.editedItem1.LOCALID
            , FONT_NATION: this.editedItem1.FONT_NATION
            , LICENSE_NAME: this.editedItem1.LICENSE_NAME == null || this.editedItem1.LICENSE_NAME == undefined ? '' : escape(this.editedItem1.LICENSE_NAME)
            , LICENSE_URL: this.editedItem1.LICENSE_URL_REF == null || this.editedItem1.LICENSE_URL_REF == undefined ? '' : escape(this.editedItem1.LICENSE_URL_REF)
            , FONT_STATE: this.editedItem1.FONT_STATE
            , FONT_IDX: this.editedItem1.FONT_IDX
          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(this.page);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert.fire('수정이 실패했습니다.');
          });
        }else{
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/FontInsert',{
              FONT_NAME: this.editedItem1.FONT_NAME == null || this.editedItem1.FONT_NAME == undefined ? '' : escape(this.editedItem1.FONT_NAME)
            , LOCALID: this.editedItem1.LOCALID
            , FONT_NATION: this.editedItem1.FONT_NATION
            , LICENSE_NAME: this.editedItem1.LICENSE_NAME == null || this.editedItem1.LICENSE_NAME == undefined ? '' : escape(this.editedItem1.LICENSE_NAME)
            , LICENSE_URL: this.editedItem1.LICENSE_URL_REF == null || this.editedItem1.LICENSE_URL_REF == undefined ? '' : escape(this.editedItem1.LICENSE_URL_REF)
            , FONT_STATE: this.editedItem1.FONT_STATE
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                this.$alert.fire("새로운 폰트가 추가되었습니다.").then(()=>{
                  this.page = 1;
                  this.loadpage(this.page);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              console.log("error1 : " + result.data.resultCode);
              this.$alert.fire('폰트추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            console.log("error2 : " + error);
            this.loading = false;
            this.progress_dialog = false;
            this.$alert.fire('폰트추가 실패');
          });
        }

        this.close()
      },            


      //추가 저장
      async save () {

        if(this.isNullCheck(this.editedItem.FONTWEIGHT_NAME)){
          this.$alert.fire("FONT 굵기명을 입력하세요");
          return;
        }
        if(this.isNullCheck(this.editedItem.FONT_FAMILY)){
          this.$alert.fire("FONT FAMILY를 입력하세요");
          return;
        }
        if(this.isNullCheck(this.editedItem.FONT_FORMAT)){
          this.$alert.fire("FONT FORMAT를 입력하세요");
          return;
        }


        if(this.uploadFile == null || this.uploadFile == undefined || this.uploadFile.length < 1){
            if(this.isNullCheck(this.editedItem.FONT_PATH_REF)){
              this.$alert.fire("폰트를 등록하세요");
              return;
            }
        }else{
          this.progress_dialog = true;            
          let curdate = Date.now().toString();
          var extension = this.uploadFile[0].name.split('.').pop();  //확장자 가져오기
          const uploadfile_ref1 = fref(this.$firestorage, "fontres/"+curdate+"."+extension);
          const metadata1 = { contentType: 'application/octet-stream', resumable: false }; 
          let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadFile[0], metadata1);
          
          if(upload_cb1 == null){
            this.progress_dialog = false;
            this.$alert.fire("폰트등록 오류 발생");
            return;
          } 
          this.uploadFile = [];
          this.editedItem.FONT_PATH_REF = upload_cb1._location.path_;          
        }

        if(this.editedIndex > -1){
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/FontWeightUpdate', {
              FONTWEIGHT_IDX: this.editedItem.FONTWEIGHT_IDX
            , FONTWEIGHT_NAME: this.editedItem.FONTWEIGHT_NAME == null || this.editedItem.FONTWEIGHT_NAME == undefined ? '' : escape(this.editedItem.FONTWEIGHT_NAME)
            , FONT_FAMILY: this.editedItem.FONT_FAMILY == null || this.editedItem.FONT_FAMILY == undefined ? '' : escape(this.editedItem.FONT_FAMILY)
            , FONT_WEIGHT: this.editedItem.FONT_WEIGHT == null || this.editedItem.FONT_WEIGHT == undefined ? '' : escape(this.editedItem.FONT_WEIGHT)
            , FONT_FORMAT: this.editedItem.FONT_FORMAT == null || this.editedItem.FONT_FORMAT == undefined ? '' : escape(this.editedItem.FONT_FORMAT)
            , FONT_URL: this.editedItem.FONT_PATH_REF == null || this.editedItem.FONT_PATH_REF == undefined ? '' : escape(this.editedItem.FONT_PATH_REF)
          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadsublist();
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.progress_dialog = false;
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });
        }else{
            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/FontWeightInsert',{
              FONT_IDX: this.editedItem.FONT_IDX
            , FONTWEIGHT_NAME: this.editedItem.FONTWEIGHT_NAME == null || this.editedItem.FONTWEIGHT_NAME == undefined ? '' : escape(this.editedItem.FONTWEIGHT_NAME)
            , FONT_FAMILY: this.editedItem.FONT_FAMILY == null || this.editedItem.FONT_FAMILY == undefined ? '' : escape(this.editedItem.FONT_FAMILY)
            , FONT_WEIGHT: this.editedItem.FONT_WEIGHT == null || this.editedItem.FONT_WEIGHT == undefined ? '' : escape(this.editedItem.FONT_WEIGHT)
            , FONT_FORMAT: this.editedItem.FONT_FORMAT == null || this.editedItem.FONT_FORMAT == undefined ? '' : escape(this.editedItem.FONT_FORMAT)
            , FONT_URL: this.editedItem.FONT_PATH_REF == null || this.editedItem.FONT_PATH_REF == undefined ? '' : escape(this.editedItem.FONT_PATH_REF)
            , FONT_SORT: this.editedItem.FONT_SORT
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                
                this.$alert.fire("새로운 폰트가 추가되었습니다.").then(()=>{
                  this.loadsublist();
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.progress_dialog = false;
            this.$alert.fire('추가 실패');
          });
        }

        this.close()        

      },      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/FontList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      //신규 추가
      newinsert1(){
        this.uploadFile    = [];
        this.editedIndex1  = -1;
        this.editedItem1   = Object.assign({}, this.defaultItem1);
        this.editedItem1.ACCNT_NAME = this.SEARCH_ACCNT_NAME;
        this.editedItem1.LOCALID    = this.SEARCH_LOCALID;
        this.dialog1 = true;
        window.scrollTo(0,0);
      },

      newinsert(){

        if(this.FONT_IDX == null || this.FONT_IDX == undefined){
          this.$alert.fire("폰트를 먼저 선택해주세요.");
          return;
        }

        this.uploadFile = [];
        this.previewImg1 = "";
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItem.FONT_IDX = this.FONT_IDX;
        this.editedItem.FONT_SORT = this.itemList.length > 0 ? parseInt(this.itemList.length+1) : 0;

        console.log("this.editedItem.FONT_SORT : " + this.editedItem.FONT_SORT);
        this.dialog = true;
        window.scrollTo(0,0);
      },

      //아이템 수정
      edit_item1(item){
        console.log("edit item : " + JSON.stringify(item));
        this.uploadFile = [];
        this.editedIndex1 = this.itemList1.indexOf(item);
        this.editedItem1  = Object.assign({}, item);
        this.dialog1 = true;
      },

      edit_item(item){
        console.log("edit item : " + JSON.stringify(item));
        this.uploadFile  = [];
        this.previewImg1 = "";        
        this.dialogTitle = "수정하기"
        //this.editedIndex = item.CATEGORY_IDX;
        this.editedIndex = this.itemList.indexOf(item);
        console.log("edit item : " + this.editedIndex);
        
        this.editedItem  = Object.assign({}, item);
        this.previewImg1 = this.editedItem.CATEGORY_IMG != null && this.editedItem.CATEGORY_IMG.length > 0 ? this.editedItem.CATEGORY_IMG : "";
        this.dialog = true;
      },



      //아이템 삭제
      delete_item1(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{
            token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
            token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/FontDelete',{
              FONT_IDX:item.FONT_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){
                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  this.loadpage(this.page);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
                
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
              
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });      
          }
        });

      },      



      async saveSort(){

        if(this.itemList.length <= 1){
          this.$alert.fire('변경하실 내역이 없습니다.');
          return;
        }

        //드래그앤드랍 순서를 저장한다.
        this.$alert.fire({showCancelButton: true, title:'순서를 저장하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){

            var tempidx = 1;
            this.itemList.forEach(item=>{
              item.FONT_SORT = tempidx;
              tempidx++;
            });

            //토큰값 체크
            let token;
            try{
              token = await this.$fireauth.currentUser.getIdToken();
            }catch(error){
              token = this.$storage.getStorageSync("FIRETOKEN");
            }

            this.loading = true;
            this.$http.post(this.$host+'/FontWeightUpdateSort',
              {
                SORTDATA: escape(JSON.stringify(this.itemList))
              },{headers: { firetoken:token}})
            .then((result)=>{
              
              this.loading = false;
              if(result.data.resultCode == 0){

                  this.$alert.fire('순서가 저장되었습니다.').then(()=>{
                    this.loadsublist();
                  });

              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
              }else{
                this.$alert.fire('순서 변경이 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('순서 변경이 실패했습니다.');
            });
          }

        });
        
      },                

    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


  .image-border {
    color: #ffffff !important;
    background-color: #ffffff !important;
    border: thin solid #808080 !important;
    height: 120px !important;
    width: 120px !important;
    text-align: center !important;
  }
</style>