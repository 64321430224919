<template>

  <v-container fluid>
    
    <!-- 추가/수정 팝업 -->
    <v-row >
      <v-dialog scrollable overlay-color="#000000FF" persistent v-model="dialog" width="800px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">{{ dialogTitle }}</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row dense align="center" class="text-center">
                  <v-col cols="1">
                    <label>제목</label>
                  </v-col>
                  <v-col cols="11">
                    <v-text-field 
                      single-line v-model="editedItem.TITLE" dense
                      placeholder="제목을 입력하세요" variant="outlined" density="compact" color="#808080" hide-details        
                      />
                  </v-col>
                </v-row>

                <v-row dense align="center" class="text-center">
                  <v-col cols="1">
                    <label>상단</label>
                  </v-col>
                  <v-col cols="3">
                    <v-select 
                      v-model="editedItem.FIX" :items="FIXLIST" variant="outlined" density="compact"
                      placeholder="상단고정" item-title="name" item-value="value"
                      color="#808080" hide-details  dense 
                      />
                  </v-col>
                </v-row>

                <!-- 
                <v-row>
                  <v-col md="12" sm="12" xs="12">
                    <quill-editor v-model="content"
                      ref="myQuillEditor"
                      :options="editorOption"
                      >
                      </quill-editor>
                  </v-col>
                </v-row>
                -->

                <v-row>
                  <v-col md="12" sm="12" xs="12">
                  <!-- 
                    <ToastUIEditor :data="editorText" @setContent="setContent" />
                  -->
                  
                  <ToastUIEditor :data="editorText" ref="editor" />

                  <!--
                  <editor
                    :initialValue="editorText"
                    :options="editorOptions"
                    height="500px"
                    initialEditType="wysiwyg"
                    previewStyle="vertical"
                    ref="toastuiEditor"
                    />
                  -->

                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>

        <v-card-actions class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="close">취소</v-btn>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="save">확인</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>

    <!-- 상세내용 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="dialog2" width="800px">
        <v-card class="pa-1 ma-0">

        <v-row dense class="mt-5">
          <v-col cols="12" align="center">
            <span class="dialog_title">{{ editedItem.TITLE }}</span>
          </v-col>
        </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <v-container>
                <v-row>
                  <v-col md="12" sm="12" xs="12">
                    <div v-html="editedItem.DESC">
                    </div>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center">
            <v-btn color="primary" @click="dialog2 = false">닫기</v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" v-model="progress_dialog" width="400px">
        <v-row justify="center">
          <v-col cols="2">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
              />
          </v-col>
        </v-row>
        <v-row justify="center">
            <label>잠시만 기다려주세요</label>
        </v-row>
      </v-dialog>  
    </v-row>    

    <v-row align="center" justify="start" class="mt-5">
      <v-col cols="12" md="3" sm="12" xs="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
      <v-col cols="3" md="1" class="pa-0" align="center">         
        <v-btn style="width:94%" class="search_btn_freesize" elevation="0" outlined large @click="newinsert">추가</v-btn>
      </v-col>
  </v-row>
  
  <v-row dense no-gutters>
    <label class="total_label">전체:</label><label class="total_label">{{totalcnt}}</label>
  </v-row>
    
  <v-row align="start" dense>
    <v-col>
      <!-- 테이블 -->
      <v-data-table
        :headers="headers"
        :items="itemList"
        v-model="selected"
        no-data-text="데이터가 없습니다."
        hide-default-footer
        class="datatablestyle"
        :items-per-page="pagerow"
        :search="search"  
        item-key="NOTICE_IDX"
        :loading="loading"
        item-class="itemstyle"
        :mobile="false"
        >
        <template v-slot:header.NOTICE_IDX="{column}">
          <a :class="sort_name=='NOTICE_IDX'?'cheader_sortselect':'cheader_sort'" @click="customsort('NOTICE_IDX')">번호</a>
        </template>
        <template v-slot:header.TITLE>
          <a :class="sort_name=='TITLE'?'cheader_sortselect':'cheader_sort'" @click="customsort('TITLE')">제목</a>
        </template>
        <template v-slot:header.UP_DATE>
          <a :class="sort_name=='UP_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('UP_DATE')">수정일시</a>
        </template>
        <template v-slot:header.REG_DATE>
          <a :class="sort_name=='REG_DATE'?'cheader_sortselect':'cheader_sort'" @click="customsort('REG_DATE')">등록일시</a>
        </template>

        
        <template v-slot:item.TITLE="{ item }">  
          <template v-if="item.FIX == 1">
            <a style="color:red" @click="loadnotice(item)" >{{item.TITLE}}</a>
          </template>
          <template v-else>
            <a @click="loadnotice(item)" >{{item.TITLE}}</a>
          </template>
        </template>
        
        <template v-slot:item.FIX="{ item }">  
          <label style="text-align:center">{{ item.FIX == 1 ? '사용' : '미사용' }}</label>
        </template>     
        <template v-slot:item.actions="{ item }">  
            <v-icon class="mr-2" small @click="edit_item(item)">mdi-pencil</v-icon>
            <v-icon small @click="delete_item(item)">mdi-delete</v-icon>
        </template>     

        <template v-slot:item.UP_DATE="{ item }">  
          {{ this.$momenttz.utc(item.UP_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }}
        </template>

        <template v-slot:item.REG_DATE="{ item }">  
          {{ this.$momenttz.utc(item.REG_DATE).tz(this.$storage.getStorageSync("TIMEZONE")).format("YYYY-MM-DD HH:mm") }}
        </template>

        <template v-slot:bottom></template>
        
      </v-data-table>
    </v-col>
  </v-row>

  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  

  </v-container>
</template>

<script>
import { ref } from '@vue/reactivity';
import { ref as fref, uploadBytesResumable, uploadBytes, uploadString, deleteObject } from "firebase/storage";
import ToastUIEditor from '@/components/ToastUIEditor.vue'
export default {

    components:{
      ToastUIEditor
    },

    setup(){
        var searchbar = ref(null);
        var paging = ref(null);
        var editor = ref(null);
        return {searchbar, paging, editor};
    },


    data: () => ({
      
      editorText: "",
      
      uploadFile : [],
      descImgs:[],

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,        //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      dialog2:false,
      progress_dialog:false,
      defaultItem: {
          IDX:0,
          TITLE:'',
          DESC:'',
          FIX:0,
      },
      editedItem: {
      },
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      headers: [
        {title: '번호', key: 'NOTICE_IDX', sortable: false, align:'center', width:'80px', class:"cheader_normal"},
        {title: '제목', key: 'TITLE', sortable: false, align:'center', width:'320px', class:"cheader_normal"},
        {title: '상단고정', key: 'FIX', sortable: false, align:'center', width:'100px', class:"cheader_normal"},
        {title: '수정일시', key: 'UP_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
        {title: '등록일시', key: 'REG_DATE', sortable: false, align:'center' , width:'200px', class:"cheader_normal"},
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center', width:'100px', class:"cheader_normal"}
      ],
      itemList: [],

      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      FIXLIST: [
          {name:'사용안함',     value:0}
        , {name:'상단고정',     value:1}
      ],

    }),


    beforeUnmount() {
      if(this.editor != null){
        this.editor.destroy();
      }
    },

    mounted(){
      
      if(this.$storage.getStorageSync("GRADE") < 90){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
        this.$router.back();
        return;
      }

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      console.log("timezone : " + this.$storage.getStorageSync("TIMEZONE"));

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }

    },

    create(){
      
    },

    methods: {
      

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/NoticeList", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },

      customsort(sortname){
        console.log("customsort...");
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      async loadpage(p){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.page = p;
        this.paging.setpage(this.page);
        this.$http.post(this.$host+'/NoticeList',{
              search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
            , sort_name:this.sort_name
            , sort_type: this.sort_type==true ? "DESC" : "ASC" 
            , page: this.page
            , pagerow: this.pagerow
        },{headers: { firetoken:token}})
        .then((result)=>{
          
          console.log("notice result : " + JSON.stringify(result));
          
          if(result.data.resultCode == 0){
              this.totalcnt = result.data.totalcnt;
              this.paging.makepaging(this.totalcnt, this.pagerow);
              const list = result.data.resultData;
              this.itemList.splice(0, this.itemList.length);   //데이터 초기화
              list.forEach(element => {
                  if(element.FILE1 != undefined && element.FILE1 != null && element.FILE1.length > 0){
                    const last = element.FILE1.split("/")
                    element.FILENAME =  decodeURI(last[last.length-1]);
                  }else{
                    element.FILENAME = "";
                  }
                  this.itemList.push(element); 
              });

              this.loading = false;

          }else if(result.data.resultCode == 2){

            //로그인 필요
            this.loading = false;
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');

          }else{
            this.loading = false;
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.uploadFile = [];
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.editedItem.TITLE == undefined || this.editedItem.TITLE.length < 1){
          this.$alert.fire("제목을 입력하세요");
          return;
        } 

        //에디터에서 값 가져오기
        this.editedItem.DESC = this.editor.getContent();
        if(this.editedItem.DESC == undefined || this.editedItem.DESC.length < 1){
          this.$alert.fire("내용을 입력하세요");
          return;
        } 

        //에디터에서 data_url을 이미지 url로 변환한다.
        const temp_div = document.createElement("div");
        temp_div.innerHTML = this.editedItem.DESC;
        const imgs = temp_div.getElementsByTagName("img");
        const tempImgs = [];
        for(const img of imgs){
          const imgstr = String(img.src);
          if(imgstr.startsWith("data:image")){          
            //이미지 데이터가 있으면 파이어 베이스로 올려서 url로 교체해라.
            console.log("upload desc img");
            const upload_ref = fref(this.$firestorage, "notice/"+Date.now().toString()+".jpg");
            const metadata = { contentType: 'image/jpeg' }; 
            let upload_cb = await uploadString(upload_ref, imgstr, "data_url", metadata);
            img.src = "https://storage.googleapis.com/necsolution-59f61.appspot.com/"+upload_cb.metadata.fullPath;
          }else if(imgstr.startsWith(this.$storagepath)){
            tempImgs.push(imgstr);
          }
        }

        //교체
        this.editedItem.DESC = temp_div.innerHTML;
        this.editorText = this.editedItem.DESC;

        //상세페이지에서 빠진 이미지 삭제
        for(const img1 of this.descImgs){
          //console.log("img1 : "+img1);
          var flag = false;
          for(const img2 of tempImgs){
            //console.log("img2 : "+img2);
            if(img1 == img2){
              flag = true;  //여전히 존재함.
              break;
            }
          }
          if(flag == false){
            //해당 이미지는 제거된 상태임 파베에서 제거 처리
            console.log("delete img : " + img1.toString().replace("https://storage.googleapis.com/necsolution-59f61.appspot.com/", ""));
            const deleteRef = fref(this.$firestorage, img1.toString().replace("https://storage.googleapis.com/necsolution-59f61.appspot.com/", ""));
            await deleteObject(deleteRef);
          }
        }



        this.progress_dialog = true;

        if (this.editedIndex > -1) {
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //수정
          this.loading = true;
          this.$http.post(this.$host+'/NoticeUpdate', {
              TITLE: escape(this.editedItem.TITLE)
            , DESC: escape(this.editedItem.DESC)
            , FIX: this.editedItem.FIX
            , NOTICE_IDX: this.editedItem.NOTICE_IDX
          }, {headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
              this.$alert.fire("수정이 완료되었습니다.").then(()=>{
                this.loadpage(this.page);
              });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('수정이 실패했습니다.');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('수정이 실패했습니다.');
          });

        }else{
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          //교체된 내용 다시 넣기
          this.loading = true;
          this.$http.post(this.$host+'/NoticeInsert',{
              TITLE: escape(this.editedItem.TITLE)
            , DESC: escape(this.editedItem.DESC)
            , FIX: this.editedItem.FIX
          },{headers:{firetoken:token}})
          .then((result)=>{
            this.loading = false;
            this.progress_dialog = false;
            if(result.data.resultCode == 0){
                
                const p = this.page;
                this.page = 0;
                this.$alert.fire("새로운 공지사항이 추가되었습니다.").then(()=>{
                  this.loadpage(p);
                });

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');

            }else{
              this.$alert.fire('추가 실패');
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            this.$alert.fire('추가 실패');
          });

        }
        


        this.close();

      },      


      //신규 추가
      async newinsert(){
        this.uploadFile = [];
        this.descImgs = [];
        this.dialogTitle = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)

        this.editedItem.DESC  = "";
        this.editorText = "";
        this.dialog = true;
      },

      
      //게시글 상세보기
      loadnotice(item){
        console.log("load notice item : " + JSON.stringify(item));
        this.$router.push({path:"/NoticeDetail", query:{code:item.NOTICE_IDX}});

      },
      //아이템 수정
      edit_item(item){
        
        this.descImgs = [];

        //내용 가져오기
        this.loading = true;
        this.$http.post(this.$host+'/GuestNoticeDetail',{
          NOTICE_IDX: item.NOTICE_IDX
        }).then(async (result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){
            
            this.dialogTitle = "수정하기"
            this.editedIndex = this.itemList.indexOf(item)
            //this.editedItem = item;
            this.editedItem = Object.assign({}, item);

            //이미 선택된 아이템에 상세만 교체
            this.editedItem.DESC  = result.data.resultData[0].DESC;

            //에디터 등록된 이미지 URL을 파악한다.
            const temp_div = document.createElement("div");
            temp_div.innerHTML = this.editedItem.DESC;
            const imgs = temp_div.getElementsByTagName("img");
            for(const img of imgs){
              const imgstr = String(img.src);
              if(imgstr.startsWith(this.$storagepath)){          
                this.descImgs.push(imgstr);
              }
            }

            this.editorText = this.editedItem.DESC;
            this.dialog = true;

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
        });

      },

      //아이템 삭제
      delete_item(item){

        this.$alert.fire({showCancelButton: true, title:'정말 삭제하시겠습니까?'})
        .then(async (aresult) => {
          if(aresult.isConfirmed){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

            this.loading = true;
            this.$http.post(this.$host+'/NoticeDelete',{
              NOTICE_IDX:item.NOTICE_IDX
            },{headers: { firetoken:token}})
            .then((result)=>{
              this.loading = false;
              if(result.data.resultCode == 0){

                
                this.$alert.fire('삭제가 완료되었습니다.').then(()=>{
                  const p = this.page;
                  this.page = 0;
                  this.loadpage(p);
                });
                
              }else if(result.data.resultCode == 2){
                //로그인 필요
                this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
                this.$emitter.emit('clearsession');
                
              }else{
                this.$alert.fire('삭제가 실패했습니다.');
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('삭제 실패');
            });                  
          }
        });
      },

      //추가 함수들
      changefiles(file) {
        this.$alert.fire(JSON.stringify(file.files));
      },

      //base64 이미지 데이터를 파일로 변환
      dataURLtoFile(dataurl, fileName){
 
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], fileName, {type:mime});

      },

    },
  
}
</script>

<style lang="css">
  td{
    height: 36px !important;
  }
</style>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>

</style>