<!-- 공통 로딩바 -->
<template>

    <div id="loading" class="d-flex flex-column align-center justify-center" v-if="isVisible">

        <div v-if="msg" class="msg">{{ msg }}</div>

        <div class="circle-container">
            <div class="circle" style="background-color: #f20015;"></div>
            <div class="circle" style="background-color: #c20068;"></div>
            <div class="circle" style="background-color: #9800b1;"></div>
            <div class="circle" style="background-color: #7b0023;"></div>
            <div class="circle" style="background-color: #6e00fa;"></div>
        </div>      
        <div>

        <v-progress-linear 
          v-if="progress"
          class="progressbar"
          :model-value="progress"
          color="#6e00fa"
          height="15" rounded striped
          /> 
        </div>
  
    </div>  

</template>

<script>
export default {
        
    props: {
        isVisible: {
          type: Boolean,
          required: true,
        },
        msg: {
          type: String,
        },
        progress: {
          type: Number,
        },
    },    
    
    watch:{

    },
    
    setup(){

    },

    data: ()=> ({
        
    }),

    mounted(){
    },

    methods: {

    }

}
</script>

<style scoped>

  .progressbar{
    width: 200px;
    margin-top: 20px;
  }

  .msg{
    font-size:17px;
    font-weight: bold;
    color: #7e0077;
    font-family: 'Inter';
    margin-bottom: 15px;
  }

  #loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
  }

  .circle-container {
    display: flex;
    justify-content: space-around;
  }      

  .circle {
    width: 20px; /* 원의 초기 크기 */
    height: 20px; /* 원의 초기 크기 */
    margin-right: 10px;
    border-radius: 50%; /* 원 모양 만들기 */
    animation: scaleAndFade 1s infinite ease; /* 애니메이션 적용 */
  }

  .circle:nth-child(1) { animation-delay: 0s; }
  .circle:nth-child(2) { animation-delay: 0.1s; }
  .circle:nth-child(3) { animation-delay: 0.2s; }
  .circle:nth-child(4) { animation-delay: 0.3s; }
  .circle:nth-child(5) { animation-delay: 0.4s; }

  /* keyframes를 이용한 애니메이션 정의 */
  @keyframes scaleAndFade {
    0%, 100% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.5); opacity: 0.5; }
  }

</style>