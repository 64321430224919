<template>

  <v-container fluid>
  <template v-if="contentStore.select_component != null">

    <v-row dense justify="center">
        <v-col>
          <v-menu 
            v-model="dialog_com"
            location="start"
            :close-on-content-click="false"
            @update:modelValue="menu_handler"
            >

            <template v-slot:activator="{ props }">
              <div v-bind="props" style="cursor:pointer" class="d-flex justify-center">

                <div style="width:25px;height:25px;border:1px gray solid"
                    :style="{ background : contentStore.select_component.model.backgroundColor }"
                    />

              </div>
            </template>

            <v-card class="ma-0 pa-1">
              <v-row dense align="center" class="pa-0 ma-0">
                <v-col cols="9" class="ml-5 mt-2">
                  <label style="color:black">{{title}}</label>
                </v-col>
                <v-col cols="2" class="mt-2">
                  <v-btn size="small" class="dialog_close_btn mr-1" @click="close_com"><v-icon>mdi-close</v-icon></v-btn>
                </v-col>
              </v-row>

              <v-row dense justify="center" class="pa-0 ma-0">
                <v-col cols="11">
                  <div style="background:gray;height:1px;" />
                </v-col>
              </v-row>

              <v-card-text>
                <v-container>
                  <v-row justify="center">

                    <Vue3ColorPicker style="width: 270px;" class="elevation-0"
                      v-model="contentStore.select_component.model.backgroundColor" 
                      mode="solid" 
                      show-eye-drop="true" show-input-menu="true"
                      @update:modelValue="updateRender" 
                      />                

                  </v-row>
                </v-container>
              </v-card-text> 

              <v-row dense justify="center" class="pa-0 ma-0">
                <v-col cols="11">
                  <div style="background:gray;height:1px;" />
                </v-col>
              </v-row>

              <v-card-actions>
                <v-row justify="center">
                <v-btn size="small" @click="close_com">취소</v-btn>
                <v-btn size="small" class="ml-5" @click="save_com">선택</v-btn>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
    </v-row>  


  </template>
  </v-container>

</template>

<script>
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import '@cyhnkckali/vue3-color-picker/dist/style.css'
import { useContentStore } from "@/stores/content";

    export default {

        components:{
          Vue3ColorPicker
        },

        props:{
          title:{
            type:String,
            default(){
              return "";
            }
          },
          /*
          color:{
            type:String,
            default(){
              return "#00ff00ff";
            }
          },
          */
        },

        setup(){
          const contentStore = useContentStore();
          return {contentStore};
        },

        mounted(){

        },

        data: ()=> ({
            dialog_com : false,
            disabled:false,
            color_backup:"",
        }),

        methods: {

            //다이얼로그 닫기
            close_com () {
              this.contentStore.select_component.model.backgroundColor = this.color_backup;
              this.updateRender();
              this.dialog_com = false
            },

            //추가 저장
            save_com () {
              this.updateRender();
              this.dialog_com = false
            },      

            menu_handler(){
              //최초 또는 메뉴 외곽 클릭시 
              //텍스트값 백업
              this.color_backup = this.contentStore.select_component.model.backgroundColor;
            },            
            
            //편집창 컴포넌트 렌더링
            updateRender(){
                if(this.contentStore.select_component != null){
                    this.contentStore.saveSnapshot();
                    this.contentStore.select_component.updateRender();
                }
            },

        }

    }
</script>

<style scoped>


</style>