<template>

  <v-container fluid>

    <v-row align="center" justify="start">
      <v-col cols="6" md="3" sm="12" xs="12">
        <label class="menunamestyle">컨텐츠 선택</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row align="start" dense v-if="itemList.length > 0">
      <v-col cols="12">
        <div class="d-flex flex-wrap">
          <div v-for="item in itemList" :key="item" class="ma-1">
            <template v-if="item.CON_IDX != undefined">
            <ThumScreen
              :id="item.CON_IDX" @click="move_edit(item)"
              :screen_model="item.screen_model"
              :scalewidth="200" 
              />
            </template>
          </div>
        </div>
      </v-col>
    </v-row>

  
  <!-- 하단검색 -->
  <searchbar ref="searchbar" @searchcallback="search_run"/>
  
  <!-- 하단페이징영역 -->
  <pagingbar ref="paging" :page="page" @loadpage="movepage"/>  
  

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import { ref } from '@vue/reactivity';
import Screen_Model from '@/nextts/model/Screen_Model';
import ThumScreen from '@/nextvue/preview/ThumScreen.vue';

export default {

    components:{
      ThumScreen,
    },

    data: () => ({

      //필수 페이징 파라메터
      totalcnt : 0,       //전체 아이템 개수
      page : 0,
      maxpage : 0,        //전체 페이지 개수
      startpage : 1,      //시작 인덱스
      bottompage : 1,     //하단 페이징 번호의 시작점을 계산하기 위한 변수
      subpage : 1,       //하단 페이징 번호의 종료점을 계산하기 위한 변수
      loading : false,    //로딩 표시

      itemList: [],
      sort_name: 'REG_DATE',
      sort_type: true,    //true : DESC, false: ASC
      search_keyword : '',

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    

      SEARCH_CATEGORY_IDX:null,   
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',
      grade:0,

    }),

    setup(){
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        let contentStore   = useContentStore();
        return {paging, editor, searchbar, contentStore};
    },

    mounted(){

      this.page = this.$route.query.page;
      if(this.page == undefined || this.page == null){
        this.page = 1;
      }
      this.search_keyword = this.$route.query.search;
      if(this.search_keyword == undefined || this.search_keyword == null){
        this.search_keyword = '';
      }else{
        this.searchbar.setSearch(this.search_keyword);
      }

      this.SEARCH_LOCALID    = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      console.log("grade :" + this.grade);

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {
      

      //기본 템플릿으로 새로운 마이 템플릿 생성
      async move_edit(item){

        //토큰값 체크
        let token;
        try{
          token = await this.$fireauth.currentUser.getIdToken();
        }catch(error){
          token = this.$storage.getStorageSync("FIRETOKEN");
        }

        //내용 가져오기
        this.$http.post(this.$host+'/ContentDetail',{
          CON_IDX: item.CON_IDX
        },{headers: { firetoken:token}})
        .then(async (result)=>{
          if(result.data.resultCode == 0){
            const item = result.data.resultData[0];
            var jobj = JSON.parse(JSON.stringify(item.CON_JSON));
            this.contentStore.setContentSize(jobj.width, jobj.height);
            this.contentStore.content_model.idx = item.CON_IDX;
            this.contentStore.content_model.content_type = 0;
            this.contentStore.content_model.mcon_idx = null;  //null이여야 추가됨.
            this.contentStore.content_model.content_mode = 1; //사용자 모드
            this.contentStore.content_model.localid = this.SEARCH_LOCALID;
            this.contentStore.content_model.parent_localid = this.SEARCH_PARENT_LOCALID;
            this.contentStore.content_model.name = item.CON_NAME;
            this.contentStore.content_model.category_idx = item.CATEGORY_IDX;
            this.contentStore.content_model.setStringValue(item.CON_JSON);
            //idx를 넣지 말 것.
            this.$router.push({path:"/EditContent", query:{type:0, mode:1, conas:1}});

          }else if(result.data.resultCode == 2){
              //로그인 필요
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
        });



      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },



      async loadpage(p){
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.page = p;
          this.paging.setpage(this.page);
          this.$http.post(this.$host+'/ContentList',{
                search_keyword: this.search_keyword == null || this.search_keyword == undefined ?  '' : escape(this.search_keyword)
              , sort_name:this.sort_name
              , sort_type: this.sort_type==true ? "DESC" : "ASC" 
              , page: this.page
              , pagerow: this.pagerow
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                this.totalcnt = result.data.totalcnt;
                this.paging.makepaging(this.totalcnt, this.pagerow);
                const list = result.data.resultData;
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                  let screen_model        = new Screen_Model();
                  screen_model.setStringValue(element.THUM_JSON);
                  screen_model.cacheFont();
                  element.screen_model = screen_model;
                  this.itemList.push(element); 
                });
                this.loading = false;

                console.log("item list : " + this.itemList.length);
                this.$forceUpdate();

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      initpage(){
        //첫페이지 로드
        this.loadpage(this.page);
      },      


      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage(1);
      },      

      movepage(p){
        this.$router.push({path:"/SelectContent", query:{page:p, search:this.search_keyword}});
        //this.loadpage(p);
      },


    },
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>


.imgscaleup{
  overflow: hidden;
  transform:scale(1.2);
  transition: transform 1s;
}

</style>