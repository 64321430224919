<!-- 리소스는 사용 안해도 될 것 같아서 여기에 그냥 파베 테스트 했음. -->

<template>

  <v-container fluid>

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" :msg="progress_msg" :progress="progress_value" />

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040" 
        v-model="crop_dialog" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>        

    <v-row align="center" justify="start">
      <v-col cols="12" md="3">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>

      <v-col cols="12" md="3" v-if="this.$storage.getStorageSync('GRADE') >= 10">
        <comaccnt :comname="SEARCH_ACCNT_NAME" @cancelcallback="comsearchclear" @callback="comsearchcb"></comaccnt>
      </v-col>
      
    </v-row>


    <v-row align="start" dense class="mt-0 mt-md-3">
    <v-col cols="12" md="2"> 
      <v-row dense>
        <v-btn style="visibility:hidden" @click="addDataPage"></v-btn>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-table 
            fixed-header density="compact" height="600"
            >
            <thead>
              <tr>
                <th style="width:200px; text-align:center" class="text-center">이름</th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="content in contentList" :key="content">
              <td style="width:200px; text-align:center">
                <a style="cursor:pointer;" @click="$vuetify.display.smAndDown ? setDataListMB(content) : setDataList(content)">{{content}}</a>
              </td>
              </tr>
            </tbody>
          </v-table>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="10"  v-if="!$vuetify.display.smAndDown"> 
      <v-row dense justify="end">
        <v-btn @click="addDataParam">추가</v-btn>
        <v-btn @click="save">저장</v-btn>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-table 
            fixed-header density="compact" height="600"
            >
            <thead>
              <tr>
                <th style="width:80px; text-align:center" class="text-center"></th>
                <template v-for="format in columnList" :key="format">
                  <th style="width:100px; text-align:center" class="text-center">{{ format.name }}</th>
                </template>
                <th style="width:80px; text-align:center" class="text-center">삭제</th>
              </tr>
            </thead>
            <draggable v-model="itemList" tag="tbody" @change="handleDrag" :key="listRenderCount" handle=".drag-handle">
              <template #item="{ element }">
                <tr>
                  <td class="drag-handle" style="width:80px; text-align:center"><v-icon small>mdi-drag</v-icon></td>
                  <template v-for="format in columnList" :key="format">

                    <template v-if="format.type == 'text'">
                      <td style="width:100px;text-align:center"><input v-model="element[format.name]" class="inputparam_bg" /></td>
                    </template>
                    <template v-else>
                      <!-- 이미지 -->
                      <td style="width:100px;text-align:center" class="text-center">
                        <template v-if="element[format.name] != null && element[format.name] != undefined && element[format.name].length > 0">
                          <v-col class="pa-0" align="center">
                            <v-img width="100" style="height:50px;cursor:pointer" :src="element[format.name]" class="image" contain @click="fileSelect(element, format.name, format.width, format.height)" />
                          </v-col>
                        </template>
                        <template v-else>
                          <v-icon style="width:50px;height:50px;color:black" @click="fileSelect(element, format.name, format.width, format.height)">mdi-image</v-icon>
                        </template>
                      </td>
                    </template>
                    
                  </template>
                  <td style="width:80px; text-align:center"><v-icon small @click="delete_item(element)">mdi-delete</v-icon></td>
                </tr>
              </template>
            </draggable>
          </v-table>
        </v-col>
      </v-row>
    </v-col>

  </v-row>

  

  </v-container>
</template>

<script>
import { useContentStore } from "@/stores/content";
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import draggable from 'vuedraggable'
import { ref } from '@vue/reactivity';
// eslint-disable-next-line no-unused-vars
import { ref as fref, uploadBytesResumable, deleteObject } from "firebase/storage";

export default {

    components:{
      draggable, VueCropper
    },  

    data: () => ({

      progress_dialog:false,
      progress_value:0,
      uploadFile : [],
      imgSrc:'',
      crop_dialog:false,
      temp_element:null,
      temp_name:null,
      deleteimg: [],

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1,1],   //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },      

      loading : false,    //로딩 표시
      listRenderCount:0,
      curArrayName:'',

      //CRUD 관련
      dialogTitle: '추가하기',   //다이얼로그 제목
      editedIndex: -1,          //-1이면 신규, -1보다 크면 업데이트
      dialog:false,
      defaultItem: {
          LOCALID:'',
          DEVICE_NAME:'',
          STATE:1,
      },
      editedItem: {},
      selected:[],              //테이블에 선택된 아이템 리스트

      //테이블 정의
      theaders1: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' }
      ],

      theaders2: [
        {title: 'Thum', key: 'THUM_JSON', width:200, sortable: false, align:'center'},
        {title: 'Name', key: 'MCON_NAME',  sortable: false, align:'center' },
        {title: '수정/삭제', key: 'actions', sortable: false, align:'center'}
      ],      
      contentList: [],
      columnList: [],
      itemList: [],
      datamap: [],

      sort_name: 'DEVICE_NAME',
      sort_type: true,    //true : DESC, false: ASC

      search_keyword : '',
      search: '',         //필터링용 검색어

      //한 페이지 ROW 개수 설정
      pagerow : 20,       //한 페이지에 보여줄 row수
      pagerow_combo : [
          {NAME:'20', VALUE:20}
        , {NAME:'50', VALUE:50}
        , {NAME:'100', VALUE:100}
        , {NAME:'200', VALUE:200}
      ],    
         
      SEARCH_LOCALID: '',
      SEARCH_PARENT_LOCALID: '',
      SEARCH_ACCNT_NAME: '',

      grade:0,
      limit_page:0,

    }),

    setup(){
        var paging = ref(null);
        var searchbar = ref(null);
        var editor = ref(null);
        let contentStore   = useContentStore();
        return {paging, searchbar, editor, contentStore};
    },

    async mounted(){
      
      if(this.$storage.getStorageSync("GRADE") < 1){
        this.$alert.fire("사용할 수 없는 등급의 페이지입니다.");
        this.$router.push("/Login");
        return;
      }

      this.SEARCH_LOCALID         = this.$storage.getStorageSync("SEARCH_LOCALID");
      this.SEARCH_PARENT_LOCALID  = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
      this.SEARCH_ACCNT_NAME   = this.$storage.getStorageSync("SEARCH_ACCNT_NAME");
      this.grade               = this.$storage.getStorageSync("GRADE");
      
      if(this.$storage.getStorageSync("GRADE") < 1){
      //if(this.$storage.getStorageSync("GRADE") < 1 && this.$storage.getStorageSync("LIMIT_PAGE") == 0){
        this.$alert.fire("사용 권한이 없습니다.");
        this.$router.back();
        return;
      }
      this.limit_page = this.$storage.getStorageSync("LIMIT_PAGE");

      if(this.$storage.hasKey("LOCALID")){
        this.initpage();
      }else{
        //키값 없으면 로그인으로
        this.$router.push("Login");
      }
    },

    create(){
      
    },

    methods: {

      checkMove(evt){
        console.log("check move : "+evt.draggedContext.element.name);
        return true;

      },

      customsort(sortname){
        this.sort_name = sortname;
        this.sort_type = !this.sort_type;
        this.loadpage(this.page);
      },


      test(){
        console.log("하하하!");
      },
      
      async loadpage(){

          //토큰값 체크
          let token;
          try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

          this.loading = true;
          this.itemList.splice(0, this.itemList.length);
          this.uploadFile.splice(0, this.uploadFile.length);
          this.deleteimg.splice(0, this.deleteimg.length);
          this.$http.post(this.$host+'/MyResList',{
              LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID:this.SEARCH_PARENT_LOCALID
          },{headers: { firetoken:token}})
          .then((result)=>{
            
            if(result.data.resultCode == 0){
                //const list = result.data.resultData;
                if(result.data.resultData != null){
                  
                  console.log("res : " + JSON.stringify(result.data.resultData));

                  this.datamap = result.data.resultData;
                  this.contentList.splice(0, this.contentList.length); 
                  for (const arrayName in this.datamap.datamap){
                    this.contentList.push(arrayName); 
                  }
                  
                  if(this.contentList.length > 0){
                    this.setDataList(this.contentList[0]);  
                  }else{
                    this.datamap = [];
                  }

                }else{

                  this.contentList.splice(0, this.contentList.length); 

                }
                /*
                this.itemList.splice(0, this.itemList.length);   //데이터 초기화
                list.forEach(element => {
                    this.itemList.push(element); 
                });
                */
                this.loading = false;

            }else if(result.data.resultCode == 2){
              //로그인 필요
              this.loading = false;
              this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
              this.$emitter.emit('clearsession');
            }else{
              this.loading = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error)=>{
            this.loading = false;
            if(error.message == 'Request failed with status code 429')
            {
              this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
            }else{
              this.$alert.fire(error.message);
            }
          });

      },

      setDataListMB(arrayName){
        this.contentStore.datamap = this.datamap;
        this.$router.push({path:"/UserResourceDetail", query:{name:arrayName}});
      },

      setDataList(arrayName){

        this.curArrayName = arrayName;
        //우측 테이블에 배열 요소 표시
        this.itemList.splice(0, this.itemList.length);   //데이터 초기화
        for (const element of this.datamap.datamap[this.curArrayName]) {
          this.itemList.push(element); 
        }

        //컬럼명 처리
        this.columnList.splice(0, this.columnList.length);   //데이터 초기화
        for (const element of this.datamap.dataformat[this.curArrayName]) {
          this.columnList.push(element); 
        }
        
      },

      //우측 순서변경
      handleDrag(){
        //원본 데이터에 순서가 변경된 것을 저장한다.
        console.log("itemlist : " + JSON.stringify(this.itemList));
        this.datamap.datamap[this.curArrayName] = [...this.itemList];
        console.log("cur2 : "+JSON.stringify(this.datamap.datamap));
        //this.listRenderCount++;
      },

      //데이터 페이지 추가
      addDataPage(){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length + 1 ){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }
        
        this.$alert.fire({
          title: '추가하실 저장소 이름을 입력하세요.',
          input: 'text',
          inputPlaceholder: '저장소 이름을 입력하세요',
          showCancelButton: true,
          confirmButtonText: '추가',
          cancelButtonText: '취소',
          inputValidator: (value) => {
            if (!value) {
              return 'You need to write something!';
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            if(this.datamap == null || this.datamap == undefined || this.datamap.length == 0){
              console.log("new datamap...");
              //신규 데이터맵 생성
              this.datamap = { datamap : new Map() };
            }
            this.datamap.datamap[result.value] = [];

            this.contentList.splice(0, this.contentList.length); 
            for (const arrayName in this.datamap.datamap){
              this.contentList.push(arrayName); 
            }
            this.setDataList(this.contentList[0]);
          }
        });        



      },

      addDataParam(){

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.curArrayName == null || this.curArrayName == undefined || this.curArrayName.length == 0){
          return;
        }

        if(this.datamap.datamap[this.curArrayName].length >= 100){
          this.$alert.fire(`100개 이상의 데이터는 추가할 수 없습니다.`);
          return;
        }

        this.itemList.push({});
        this.datamap.datamap[this.curArrayName] = [...this.itemList];

      },

      //스토리지 이미지가 들어있는지 체크
      findHttpValue(obj) {
          for (let key in obj) {
              // eslint-disable-next-line no-prototype-builtins
              if (obj.hasOwnProperty(key) && typeof obj[key] === 'string' && obj[key].startsWith(this.$storagepath)) {
                  return obj[key];
              }
          }
          return null; // 'http'로 시작하는 값이 없는 경우
      },

      //아이템 삭제
      delete_item(item){

        const idx = this.itemList.indexOf(item);
        if (idx !== -1) {

          const imgpath = this.findHttpValue(item);
          if(imgpath != null && imgpath.length > 0){
            //스토리지 이미지가 이미 들어있다면 제거 대상에 등록
            this.deleteimg.push(item[name]);
          }

          this.itemList.splice(idx, 1);
        }
        this.datamap.datamap[this.curArrayName] = [...this.itemList];
      },


      initpage(){
        //첫페이지 로드
        this.loadpage();
      },      

      //다이얼로그 닫기
      close () {
        this.dialog     = false
        this.dialog_pw  = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      //추가 저장
      async save () {

        if(this.$storage.getStorageSync("GRADE") < 90){
          if( this.limit_page < this.contentList.length){
            if(this.limit_page == 0){
              this.$alert.fire(`실시간 페이지를 사용할 수 없습니다.(권한 부족)`);
            }else{
              this.$alert.fire(`${this.limit_page}개 이상의 페이지를 사용할 수 없습니다. (권한 부족)`);
            }
            return;
          }
        }

        if(this.datamap == null || this.datamap == undefined || this.datamap.length == 0){
          //신규 데이터맵 생성
          this.$alert.fire('저장데이터가 없습니다.');
          return;
        }


        //이미지 저장처리
        let localid = this.$storage.getStorageSync("SEARCH_LOCALID");
        let parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
        let folder = "company/"+parent_localid+"/users/"+localid+"/page";
        if(this.uploadFile.length > 0){
          //저장 시작
          this.progress_dialog = true;
          this.progress_msg = "이미지 업로드중...";
          for(const imgobject of this.uploadFile){

            console.log("1 : "+imgobject.name);
            console.log("2 : " + imgobject.data);

            await this.imageCheck(imgobject, folder);

          }
        }
        this.progress_dialog = false;


        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/MyResSave',{
              DATAMAP: JSON.stringify(this.datamap)
            , LOCALID:this.SEARCH_LOCALID
            , PARENT_LOCALID: this.SEARCH_PARENT_LOCALID
        },{headers: { firetoken:token}})
        .then(async (result)=>{
          this.loading = false;
          if(result.data.resultCode == 0){

            //이미지 삭제 처리 - 이건 그냥 삭제해도 된다.
            if(this.deleteimg.length > 0){
              this.progress_dialog = true;
              this.progress_msg = "이미지 동기화중...";
              for(const imgurl of this.deleteimg){
                const deleteRef = fref(this.$firestorage, imgurl.toString().replace(this.$storagepath, "").split("?")[0]);
                try{
                  await deleteObject(deleteRef);
                }catch(error){
                }
              }
            }
            this.progress_dialog = false;

            this.$alert.fire("수정이 완료되었습니다.").then(()=>{
              //this.loadpage();
              this.uploadFile.splice(0, this.uploadFile.length);
              this.deleteimg.splice(0, this.deleteimg.length);
            });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('수정이 실패했습니다.');
        });

      },      

      async imageCheck(object, folder){

        const upload_ref = fref(this.$firestorage, folder+"/"+Date.now().toString()+"_new.png");
        const metadata = { contentType: 'image/png' };

        

        this.test();

        let upload_cb = await this.uploadFileStorage(upload_ref, object.data, metadata);
        if(upload_cb != null){
          object.element[object.name] = this.$storagepath+upload_cb._location.path_;
        }

      },

      //검색
      search_run(searchkeyword){
        console.log("search....");
        if(searchkeyword != null && searchkeyword != undefined && searchkeyword.length > 0){
          this.search_keyword = searchkeyword;
        }else{
          this.search_keyword = '';
        }
        //페이지 상세가 있어서 다시 백을 해야하는 경우
        //this.movepage(1); 
        this.loadpage();
      },      

      //신규 추가
      newinsert(){
        this.dialogTitle  = "추가하기"
        this.editedIndex  = -1;
        this.editedItem = Object.assign({}, this.defaultItem)
        this.dialog = true;
      },

      //아이템 수정
      edit_item(item){
        this.dialogTitle = "수정하기"
        this.editedIndex = this.itemList.indexOf(item)
        //this.editedItem = item;
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
      },

      //검색조건 업체 설정
      comsearchcb(val1){
        if(val1.NAME.length > 0){
          this.SEARCH_ACCNT_NAME        = val1.NAME;
          this.SEARCH_LOCALID           = val1.LOCALID;
          this.SEARCH_PARENT_LOCALID    = val1.PARENT_LOCALID;
        }else{
          this.SEARCH_ACCNT_NAME = '';
          this.SEARCH_LOCALID  = null;
          this.SEARCH_PARENT_LOCALID    = null;
        }
        this.$storage.setStorageSync("SEARCH_LOCALID",           this.SEARCH_LOCALID);
        this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",    this.SEARCH_PARENT_LOCALID);
        this.$storage.setStorageSync("SEARCH_ACCNT_NAME",        this.SEARCH_ACCNT_NAME);
        this.loadpage();
      },
      
      // eslint-disable-next-line no-unused-vars
      comsearchclear(){
          this.SEARCH_LOCALID         = null;
          this.SEARCH_PARENT_LOCALID  = null;
          this.SEARCH_ACCNT_NAME = '';
          this.$storage.setStorageSync("SEARCH_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_PARENT_LOCALID",  null);
          this.$storage.setStorageSync("SEARCH_ACCNT_NAME", "");
          this.loadpage();
      },



      fileSelect(element, name, width, height){

        console.log("fileselect : "+width, height);
        this.option.fixedNumber = [Number(width), Number(height)];

        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"
        input.onchange = function (event) {
          changefunction(event.target.files[0], element, name);
        };
        input.click();
      },

      //이미지 선택시
      changefile(file, element, name){
        if(file.size/1024/1024 > 10){
          this.$alert.fire("10MB 이하 파일만 사용할 수 있습니다.");
          return;
        }
        const reader = new FileReader();
        reader.onload = (event)=>{
          this.temp_element = element;
          this.temp_name = name;

          console.log("temp_name : " + this.temp_name);

          this.crop_dialog = true;
          this.imgSrc = event.target.result;  //이게 blob데이터
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서
      },


    //이미지 크롭시
    cropimg(){
        
      this.$refs.cropper_land.getCropBlob(blob=>{
        this.crop_dialog = false;
        this.$refs.cropper_land.getCropData(async data=>{
          
          if(this.temp_element[this.temp_name] != null && this.temp_element[this.temp_name] != undefined && this.temp_element[this.temp_name].length > 0){
            if(this.temp_element[this.temp_name].startsWith(this.$storagepath)){
              //스토리지 이미지가 이미 들어있다면 제거 대상에 등록
              this.deleteimg.push(this.temp_element[this.temp_name]);
            }
          }

          this.temp_element[this.temp_name] = data; //미리보기
          this.uploadFile.push({element:this.temp_element, name:this.temp_name, data:blob});  //업로드용 blob 데이터

        });

      });      

    },


    //지정한 data를 넣은 이미지를 리턴한다.
    loadImage(data){
      const myImage = new Image();
      myImage.src = data;
      return new Promise((resolve)=>{
        myImage.onload = () => resolve(myImage)
      })
    },

    //캔버스 TO BLOB
    dataURItoBlob(dataURI){
      const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
      const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const max = bytes.length;
      const ia = new Uint8Array(max);
      for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
      return new Blob([ia], { type: mime });
    },


    //firebase storage에 파일 업로드 및 프로그레스 표시
    uploadFileStorage(storage_ref, blobd_ata, metadata){
      return new Promise((resolve, reject)=>{

          const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
          uploadTask.on('state_changed',
            (snapshot) => {
              this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }                
            },
            // eslint-disable-next-line no-unused-vars
            (error) => {
              reject(null);
            },
            () => {
              // Upload completed successfully - 업로드 완료시 경로 리턴
              resolve(uploadTask.snapshot.ref);
            }
          );

      })
    },

    isNullCheck(value){
      if(value == null || value == undefined || value.length < 1){
        return true;
      }else{
        return false;
      }
    },

  }
  
  
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.inputparam_bg{
  background: #e4e4e4;
  border-radius: 5px;
  color: #000000;
  padding: 5px 10px 5px 10px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

</style>