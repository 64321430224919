<template>
    <v-container>
    <template v-if="contentStore.getContentModel != null">

      <v-row>
        <v-col cols="12" align="center">
          <v-btn style="font-size:11px" variant="tonal" @click="loaddir"
            prepend-icon="mdi-transfer-down"
            >Sync Directory
          </v-btn>
        </v-col>  
      </v-row>

        <v-row v-if="itemList.length > 0">
          <v-col
            v-for="item in itemList"
            class="d-flex child-flex ma-0 pa-1"
            :key="item"
            cols="12"
            >
            
            <v-img
              :src="item.url"
              cover
              class="bg-grey-lighten-2"
              :key="renderCount"
              >
              <v-spacer/>
              <!-- <div style="color:white;background:#00000080;">{{`${item.name}`}}</div> -->
              <div :class="item.useurl ? 'bottom_div' : 'bottom_div2'">{{`size : ${item.size}mb`}}<v-icon v-if="item.useurl == false" @click="deleteFile(item)">mdi-delete</v-icon></div>
            </v-img>
            
            <!-- {{ `${item.name} ${item.size}mb` }} -->
          </v-col>
          
        </v-row>

        <v-row class="mt-10">

        </v-row>


    </template>
    </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import { ref as fref, listAll, getMetadata, deleteObject } from "firebase/storage";
import Component_Model from '@/nextts/model/Component_Model';

export default {

  components:{
    
  },

  
  methods:{

    deleteFile(item){
      
      const fileRef = fref(this.$firestorage, item.fullPath);
      deleteObject(fileRef).then(()=>{
        //삭제
        const idx = this.itemList.indexOf(item);
        if(idx !== -1){
          this.itemList.splice(idx, 1);
        }
        this.renderCount += 1;
      }).catch((error)=>{
        //오류
        console.log("error : " + error);
      })

    },

    async loaddir(){
      
      let folder = "";      
      if(this.contentStore.content_model.content_mode == 0){
        //관리자모드
        if(this.contentStore.content_model.content_type == 0){
          //컨텐츠
          folder = "content/"+this.contentStore.content_model.idx+"";
        }else if(this.contentStore.content_model.content_type == 1){
          //에셋
          folder = "asset/"+this.contentStore.content_model.idx+"";
        }else if(this.contentStore.content_model.content_type == 2){
          //팝업
          folder = "popup/"+this.contentStore.content_model.idx+"";
        }
      }else{
        //사용자모드
        let localid = this.contentStore.content_model.localid;
        let parent_localid = this.contentStore.content_model.parent_localid;
        if(localid == null || localid == undefined){
          localid = this.$storage.getStorageSync("SEARCH_LOCALID");
          parent_localid = this.$storage.getStorageSync("SEARCH_PARENT_LOCALID");
        }
        folder = "company/"+parent_localid+"/users/"+localid+"/"+this.contentStore.content_model.mcon_idx+"";

      }

      console.log("save_content load info my : " + folder);

      const listRef = fref(this.$firestorage, folder);
      const result = await listAll(listRef);
      this.renderCount = 0;
      this.itemList.splice(0, this.itemList.length);
      for (const itemRef of result.items) {
        
        const metadata = await getMetadata(itemRef);
        //console.log("metadata : " + JSON.stringify(metadata));

        //썸네일 이미지를 제외한 리소스의 경우는 사용되었는지 체크한다.
        let flag = false;
        if(!metadata.fullPath.includes("thum")){
          for(const screen of this.contentStore.content_model.screen_list){
            if(flag){
              break;
            }
            if(screen.bgtype == 1 && screen.backgroundImg.includes(metadata.fullPath)){
              flag = true;
              break;
            }

            for(const cp of screen.component_list){
              if(flag){
                break;
              }
              if(cp.component_type == Component_Model.COMPONENT_IMAGE){
                if(cp.image.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
                if(cp.mask_image.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
              }else if(cp.component_type == Component_Model.COMPONENT_COLOR){
                if(cp.mask_image.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
              }else if(cp.component_type == Component_Model.COMPONENT_IMAGESWITCHER){
                if(cp.imgswitcher_list != null && cp.imgswitcher_list.length > 0){
                  for(const img of cp.imgswitcher_list){
                    if(img.includes(metadata.fullPath)){
                      flag = true;
                      break;
                    }
                  }
                }
                if(cp.mask_image.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
              }else if(cp.component_type == Component_Model.COMPONENT_TEXT){
                if(cp.color_type == "image"){
                  if(cp.backgroundImg.includes(metadata.fullPath)){
                    flag = true;
                    break;
                  }
                }
              }else if(cp.component_type == Component_Model.COMPONENT_MOVIE){
                if(cp.movie.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
                if(cp.mask_image.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
              }else if(cp.component_type == Component_Model.COMPONENT_SOUND){
                if(cp.sound.includes(metadata.fullPath)){
                  flag = true;
                  break;
                }
              }
            }
          }
        }else{
          //썸네일은 true
          flag = true;
        }

        const item = {
                          name:metadata.name
                        , size:(metadata.size/1024/1024).toFixed(2)  //mb변환
                        , fullPath:metadata.fullPath
                        , url:this.$storagepath + metadata.fullPath
                        , create:metadata.timeCreated
                        , update:metadata.updated
                        , useurl:flag
                      }
        this.itemList.push(item);
        //this.renderCount += 1;
      }


    },


  },

  //프로퍼티 선언
  props:{
    
  },  

  setup(){
    const contentStore = useContentStore();
    return {contentStore};
  },

  data(){
    return{
      renderCount: 0,
      itemList: [],
    }
  },


  
}
</script>

<style scoped>

.bottom_div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color:white;
  background:#00000080;
}
.bottom_div2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color:white;
  background:#ff000080;
}

</style>