<template>
    <template v-if="contentStore.select_component != null 
        && (contentStore.select_component.model.component_type == 0 || contentStore.select_component.model.component_type == 2 
        || contentStore.select_component.model.component_type == 4 || contentStore.select_component.model.component_type == 8 || contentStore.select_component.model.component_type == 9)">
    <v-container fluid class="ma-0 pt-0">
    
        <!-- 텍스트 그림자 -->
        <v-row align="center" dense>
            <v-col cols="9">
                <v-switch label="그림자" color="red"  hide-details 
                    v-model="this.contentStore.select_component.model.shadow_use"
                    @change="shadow_switchchange"
                    />
            </v-col>
            <v-col cols="3" v-if="this.contentStore.select_component.model.shadow_use">
                <dialogsdcolor dense title="Font Color" ref="bgcolor" 
                    :color="this.contentStore.select_component.model.shadow_color"
                    />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.shadow_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="가로 간격" property="shadow_hori" :min="-50" :max="50" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.shadow_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="세로 간격" property="shadow_verti" :min="-50" :max="50" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0" v-if="this.contentStore.select_component.model.shadow_use">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="블러 크기" property="shadow_blur" :min="0" :max="50" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{

    shadow_switchchange(){
        this.updateRender();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    colorcallback(){
        this.updateRender();
    }, 
    

  },

  data(){
    return{

    }
  },

  mounted(){
    
  },

  
}
</script>