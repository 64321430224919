<template>
    <template v-if="contentStore.select_component != null && contentStore.select_component.model.component_type == 10">
    <v-container fluid class="ma-0 pt-0">
    

        <!-- 이미지 크롭 팝업 -->
        <v-row align="center"  justify="center" >
            <v-dialog persistent scrollable overlay-color="#00000040"
                :fullscreen="false"
                v-model="crop_dialog" max-width="500px">
    
                <v-card class="pa-0 ma-0">
    
                <v-card-title class="ma-0 pa-0" style="overflow:hidden">
                <v-row dense align="center" justify="center">
                    <v-col cols="12"  align="center" style="height:70px;background:black" class="d-flex align-center justify-center">
                    <span style="color:white">이미지 자르기</span>
                    </v-col>
                </v-row>
                </v-card-title>
    
                <v-card-text class="ma-0 pl-5 pr-5 pt-3">
    
                    <v-row align="center" justify="center" dense>
                    <v-col cols="12" align="center"> <!-- 해상도 조절 컬럼 -->
    
                    <div style="width:100%;height:500px;">
                        <vueCropper
                        ref="cropper_land"
                        :img="imgSrc"
                        :outputType="option.outputType"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :full="option.full"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :fixedBox="option.fixedBox"
                        :original="option.original"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="contentStore.select_component.model.width"
                        :autoCropHeight="contentStore.select_component.model.height"
                        :centerBox="option.centerBox"
                        :high="option.high"
                        :infoTrue="option.infoTrue"
                        :maxImgSize="option.maxImgSize"
                        :enlarge="option.enlarge"
                        :mode="option.mode"
                        :limitMinSize="option.limitMinSize"
                        />
                    </div>
                    </v-col>
                    </v-row>
                </v-card-text>
    
                <v-card-actions class="mt-1 mb-1">
                    <v-row align="center" justify="center">
                    <v-col cols="12" > <!-- 해상도 조절 컬럼 -->
                        <v-row class="ma-0 pa-0">
                        <v-col cols="6" class="ma-0 pa-0">
                            <v-btn elevation="0" class="white_btn" width="100%" height="50px" @click="crop_dialog = false">취소</v-btn>
                        </v-col>
                        <v-col cols="6" class="ma-0 pa-0">
                            <v-btn elevation="0" class="black_btn" width="100%" height="50px" @click="cropimg">저장</v-btn>
                        </v-col>
                        </v-row>
                    </v-col>
                    </v-row>
                </v-card-actions>
    
                </v-card>
            </v-dialog>
        </v-row>     




        <v-row justify="center" align="center" class="mt-0" dense>
            <v-col cols="12">
                <label style="font-size:12px">Color Type</label>
            </v-col>
            <v-col cols="12">
                <select 
                    v-model="contentStore.select_component.model.color_type" @change="colorcallback"
                    style="width:100%;border: 1px gray solid; border-radius:5px; padding-left:5px">
                    <template v-for="cptype in colortype" :key="cptype.name">
                        <option style="font-size:12px" :value="cptype.value">{{cptype.name}}</option>
                    </template>
                </select>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-0" dense v-if="contentStore.select_component.model.color_type == 'gradient'">
            <v-col cols="12"  align="center">

                <!-- 
                <ColorPicker
                    size="medium"
                    v-model="color"
                    />
                -->
                <dialoggrcolor dense title="ArtFont Color" property="backgroundColor" />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-0" dense v-if="contentStore.select_component.model.color_type == 'color'">
            <v-col cols="12" v-if="contentStore.select_component.model.color_type == 'color'" align="center">
                <dialogcolor dense title="ArtFont Color" property="textcolor" />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" dense class="mt-3" v-if="contentStore.select_component.model.color_type == 'image'">
            <v-col cols="12">
            <template v-if="this.contentStore.select_component.model.backgroundImg.length > 0">
                <v-row dense  justify="center">
                <div style="position:relative">
                    <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:150px;background-color:#00000080">
                        <v-icon style="width:50px;height:40px;color:white" @click="fileSelect()">mdi-pencil</v-icon>
                    </div>
                    <img  width="150" style="hegiht:auto" :src="this.contentStore.select_component.model.backgroundImg" class="image">
                </div>
                </v-row>
            </template>
            <template v-else>
                <v-row dense class="image-border2" align="center" justify="center">
                    <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect()">mdi-image</v-icon>
                </v-row>
            </template>
        </v-col>
        </v-row>


        <v-row class="pt-3 ma-0" align="center" justify="center" dense>
            <v-col cols="6" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.texthori_align"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="change_textalign"
                    >
                    <v-btn value="left" width="32" icon="mdi-format-align-left" size="x-small"></v-btn>
                    <v-btn value="center" width="32" icon="mdi-format-align-center" size="x-small"></v-btn>
                    <v-btn value="right" width="32px" icon="mdi-format-align-right" size="x-small"></v-btn>
                </v-btn-toggle>                
            </v-col>

            <v-col cols="6" class="pa-0" align="center">
                <v-btn-toggle
                    v-model="contentStore.select_component.model.textverti_align"
                    color="primary" 
                    variant="text"
                    mandatory 
                    style="height:34px"
                    @update:modelValue="change_textalign"
                    >
                    <v-btn value="start" icon="mdi-format-align-top" size="x-small"></v-btn>
                    <v-btn value="center" icon="mdi-format-align-middle" size="x-small"></v-btn>
                    <v-btn value="end" icon="mdi-format-align-bottom" size="x-small"></v-btn>
                </v-btn-toggle>                
            </v-col>
        </v-row>
        
        <v-row justify="center" align="center" dense class="mt-0 pt-0">
            <v-col cols="3">
                <dialogcolor dense title="ArtFont Color" property="textcolor" />
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-bold" flat size="small"
                    :color="this.contentStore.select_component.model.textBold == 0 ? 'white' : 'purple'"
                    @click="bold_handler"
                    >
                </v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-italic" flat size="small"
                    :color="this.contentStore.select_component.model.textItalic == 0 ? 'white' : 'purple'"
                    @click="Italic_handler"
                    >
                </v-btn>
            </v-col>

            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-underline" flat size="small"
                    :color="this.contentStore.select_component.model.textUnderline == 0 ? 'white' : 'purple'"
                    @click="underline_handler"
                    >
                </v-btn>
            </v-col>

            <!-- 밑줄 사용안함
            <v-col cols="3">
                <v-btn 
                    icon="mdi-format-underline" flat size="small"
                    :color="this.contentStore.select_component.model.textUnderline == 0 ? 'white' : 'purple'"
                    @click="underline_handler"
                    >
                </v-btn>
            </v-col>
            -->
        </v-row>

        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="11">
            <dialogfont :comname="this.contentStore.select_component.model.font_name" />
            <!-- 
            <comfont 
                :comname="this.contentStore.select_component.model.textfont"
                @cancelcallback="comfontcancel" @callback="comfontcb"
                />
            -->
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-3 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="폰트 사이즈" property="textsize" :min="10" :max="300" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="글자 간격" property="letterSpacing" :min="-100" :max="100" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="줄 간격" property="lineHeight" :min="0" :max="500" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="아크" property="text_arc" :min="-1000" :max="1000" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="가로 오프셋" property="text_offset" :min="-1000" :max="1000" :step="1" />
            </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-1 pt-0">
            <v-col cols="12">
                <comeditslider :object="this.contentStore.select_component.model" title="세로 오프셋" property="text_offsetverti" :min="-1000" :max="1000" :step="1" />
            </v-col>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
import 'vue-cropper/dist/index.css'
import { VueCropper }  from "vue-cropper";
import ColorPicker from '@mcistudio/vue-colorpicker'
import '@mcistudio/vue-colorpicker/dist/style.css'

export default {
  
    components:{
        VueCropper, ColorPicker
    },

  setup(){
    let cropper_land = ref(null);
    const contentStore = useContentStore();

    const color = ref({
                        mode: 'linear',
                        degree: 90,
                        gradients: [
                            { percent: 10, color: { r: 255, g: 10, b: 20, a: 0.5 } },
                            { percent: 100, color: { r: 59, g: 50, b: 240, a: 1 } }
                        ]
                  })    
    return {cropper_land, contentStore, color}
  },  

  methods:{

    textchange(){
        this.contentStore.select_component.updateInnerText();
    },

    change_textalign(){
        this.updateRender();
    },

    //편집창 컴포넌트 렌더링
    updateRender(){
        if(this.contentStore.select_component != null){
            this.contentStore.saveSnapshot();
            this.contentStore.select_component.updateRender();
        }
    },

    colorcallback(){
        this.updateRender();
    }, 
    
    underline_handler(){
        if(this.contentStore.select_component.model.textUnderline == 0){
            this.contentStore.select_component.model.textUnderline = 1;
        }else{
            this.contentStore.select_component.model.textUnderline = 0;
        }
        this.updateRender();
    },

    bold_handler(){
        if(this.contentStore.select_component.model.textBold == 0){
            this.contentStore.select_component.model.textBold = 1;
        }else{
            this.contentStore.select_component.model.textBold = 0;
        }
        this.updateRender();
    },

    Italic_handler(){
        if(this.contentStore.select_component.model.textItalic == 0){
            this.contentStore.select_component.model.textItalic = 1;
        }else{
            this.contentStore.select_component.model.textItalic = 0;
        }
        this.updateRender();
    },


      //폰트선택 취소
      comfontcancel(){

      },

      //폰트선택 콜백
      comfontcb(val){
        if(val.FONT_FAMILY.length > 0){
          this.contentStore.select_component.model.textfont     = val.FONT_FAMILY;
          this.contentStore.select_component.model.font_url     = val.FONT_PATH_REF;
          this.contentStore.select_component.model.font_format  = val.FONT_FORMAT;
          this.contentStore.select_component.model.font_weight  = val.FONT_WEIGHT;
          this.contentStore.select_component.model.font_style   = val.FONT_STYLE;

        }else{
          return;
        }
        this.updateRender();
      },    



    fileSelect(){

        const changefunction = this.changefile;
        var input    = document.createElement("input");
        input.style  = "display:none";
        input.name   = "imageinputcamera";
        input.type   = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
            //아이폰, 아이패드, 맥 => input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
            document.body.appendChild(input);
            input.onchange = function (event) {
                //추가된 input 제거
                document.body.removeChild(input);
                changefunction(event.target.files[0]);
            };

        }else{
            input.onchange = function (event) {
                changefunction(event.target.files[0]);
            };
        }
        input.click();
    },        


    //이미지 선택시
    changefile(file){

        if(file.size/1024/1024 > 10){
            alert("10메가 이하 파일만 사용하세요.");
            return;
        }

        //이미지 크롭 팝업 띄우기
        const reader = new FileReader();
        reader.onload = (event)=>{
            this.crop_dialog = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
            //this.contentStore.select_component.model.backgroundImg = event.target.result;
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

    },    

    //이미지 크롭시
    cropimg(){

        const updateFunction = this.updateRender();

        //이미지 크롭 
        this.cropper_land.getCropBlob(blob=>{

            this.crop_dialog = false;
            this.cropper_land.getCropData(async data=>{
  
                this.uploadFile = blob;  //업로드용 blob 데이터
                this.contentStore.select_component.model.backgroundImg = data;
                updateFunction();
                //원래 image가 있었다면 백업?

                //썸네일 생성
                /*
                var image     = await this.loadImage(data); //이미지 사이즈 알 수 있음.
                const canvas  = document.createElement("canvas");
                canvas.width  = 50;
                canvas.height = 50;
                canvas.getContext("2d").drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);

                //this.previewThumImg1 = canvas.toDataURL("image/jpeg");  //미리보기 데이터
                //this.uploadFileThum1 = await this.dataURItoBlob(this.previewThumImg1);     //썸네일 blob데이터
                //this.uploadFileThum = this.dataURItoBlob(canvas.toDataURL("image/jpeg"));     //썸네일 blob데이터
                */
               
            });

        });
    },    

  },

  data(){
    return{

        crop_dialog:false,
        imgSrc:'',

        //신규 크롭 파라메터
        option: {
            img: "",
            size: 1,
            full: true,
            outputType: "jpg",
            canMove: true,
            fixed: false,          //크롭박스 비율 고정
            fixedNumber: [1,1],     //크롭박스 비율
            fixedBox: false,      //true면 크롭박스 크기 조절 안됨
            original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
            canMoveBox: true,
            autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
            autoCropWidth:300,    //크롭박스의 가로 크기 fixed가 true면 비율대로
            autoCropHeight:300,   //크롭박스의 세로 크기 
            centerBox: true,
            high: false,
            cropData: {},
            enlarge: 1,
            mode: 'contain',
            maxImgSize: 2000,
            limitMinSize: [100, 100]
        },            

        horialign:[
                {name:"left",       value:"left"}
            ,   {name:"center",     value:"center"}
            ,   {name:"right",      value:"right"}
        ],

        vertialign:[
                {name:"top",        value:"top"}
            ,   {name:"center",     value:"center"}
            ,   {name:"end",        value:"end"}
        ],

        colortype:[
                {name:"Gradient",  value:"gradient"}
            ,   {name:"Image",     value:"image"}
            ,   {name:"Color",     value:"color"}
        ],


    }
  },

  mounted(){
    
  },

  
}
</script>