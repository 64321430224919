
//그라디언트 포인트 모델
class GradientPoint_Model {

    red: number     = 0;
    green: number   = 0;
    blue: number    = 0;
    alpha: number   = 1;
    left: number    = 0;

    constructor(){
        
    }

    setStringValue(params:string):void{
    
        const temp          = JSON.parse(JSON.stringify(params));

        this.red    = temp.red;
        this.green  = temp.green;
        this.blue   = temp.blue;
        this.alpha  = temp.alpha;
        this.left   = temp.left;
        
    }



}
//module.exports = {Component_Model}
export default GradientPoint_Model
