
<!-- 드래그 앤 드랍 기술 검토 : 직접 개발함 -->
<template>
  <v-container>
    <template v-if="contentStore.getContentModel != null">
    <v-row align="center">
        <v-btn size="small" variant="outlined">추가</v-btn>
        <v-btn size="small" variant="outlined">삭제</v-btn>
    </v-row>

    <!-- 
    <v-row>
    <draggable
      :list="contentStore.content_model.screen_list"
      item-key="name"
      class="list-group"
      ghost-class="ghost"
      
      >
      <template #item="{ element }">
        <PreviewScreen
          class="list-group-item"
          :screen_model="element"
          :scalewidth="200" 
          />
      </template>
    </draggable>    
    </v-row>
    -->

    <v-row>
      <v-col
        class="d-flex child-flex ma-0 pa-1"
        cols="12"
        v-for="screen in contentStore.getScreenList" :key="screen"
        >
        <PreviewScreen
            :screen_model="screen" :key="renderCount" class="item"
            :scalewidth="200" @click="test"
            @changescreen="changescreen"
            />
      </v-col>
    </v-row>
    
    </template>
  </v-container>
</template>

<script>
import PreviewScreen from '@/nextvue/preview/PreviewScreen.vue';
import Screen_Model from '@/nextts/model/Screen_Model';
import Component_Model from '@/nextts/model/Component_Model';
import { useContentStore } from "@/stores/content";
export default {
  
  name: 'HelloWorld',

  setup(){
    const contentStore = useContentStore();
    return {contentStore};
  },

  components:{
    PreviewScreen,
  },

  data () {
    return {
      drag: false,

      list: [
        { name: "John", id: 0 },
        { name: "Joao", id: 1 },
        { name: "Jean", id: 2 }
      ],            

    }
  },

  mounted(){

    this.addscreen();
    this.addscreen();
    this.addscreen();
    this.addscreen();
    this.addscreen();

    console.log(this.contentStore.content_model.screen_list);

  },

  methods:{
    test(){
      console.log("click...");
    },
    
    changescreen(){
      //드래그앤 드랍을 통한 모델 체인지 요청
      console.log("changescreen");
      
      let drag_pos = -1;
      let drag_screen = this.contentStore.drag_screen;
      for(var i = 0;i<this.contentStore.content_model.screen_list.length;i++){
        const sm = this.contentStore.content_model.screen_list[i];
        if(sm == drag_screen){
          drag_pos = i;
        }
      }
      
      let drop_pos = -1;
      let drop_screen = this.contentStore.drop_screen;
      for(var j = 0;j<this.contentStore.content_model.screen_list.length;j++){
        const sm = this.contentStore.content_model.screen_list[j];
        if(sm == drop_screen){
          drop_pos = j;
        }
      }

      this.contentStore.content_model.screen_list.splice(drag_pos, 1);                  //원본 위치에서 제거
      this.contentStore.content_model.screen_list.splice(drop_pos, 0, drag_screen);     //새로운 위치에 다시 추가
      this.contentStore.drag_screen = null;
      this.contentStore.drop_screen = null;

    },

    checkMove(e){
      console.log("Future index: " + e.draggedContext.futureIndex);
    },


    addscreen(){
      

      let screen_model    = new Screen_Model();
      
      screen_model.screen_idx = (Math.random()*16777215);

      screen_model.bgtype = 0;
      screen_model.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
      screen_model.width  = this.contentStore.getContentModel.width;
      screen_model.height = this.contentStore.getContentModel.height;
      console.log("add screen size : " + screen_model.width + " / " + screen_model.height);
      screen_model.rotate = 0;


      //랜덤 컴포넌트 추가 (테스트)
      for(var i=0;i<5;i++){
        const posx  = Math.floor(Math.random() * 700)+50;
        const posy  = Math.floor(Math.random() * 560)+50;
        const tempcm    = new Component_Model("");
        tempcm.width  = Math.floor(Math.random() * 150)+50;
        tempcm.height = Math.floor(Math.random() * 150)+50;
        tempcm.posx   = posx;
        tempcm.posy   = posy;
        tempcm.text = String(Math.floor(Math.random() * 150)+50);
        tempcm.rotate = 0;
        tempcm.transx = posx;
        tempcm.transy = posy;
        tempcm.backgroundColor = `#${Math.floor(Math.random()*16777215).toString(16)}`;
        screen_model.component_list.push(tempcm);      
      }


      //this.contentModel.screen_list.push(screen_model);
      //this.contentStore.addScreenModel(screen_model);
      this.contentStore.content_model.screen_list.push(screen_model);

    },


  }

}
</script>
