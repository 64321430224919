
<template>
  <div>
    <div id="editor" ref="editor" />
  </div>
</template>

<script setup>
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

import { ref, defineProps, onMounted } from 'vue';
import youtubeUrl from 'youtube-url';

const props = defineProps({
  data: {
    type: String,
    required: false,
    default: '',
  },
});

// 유튜브 플러그인 시작 - 유튜브 링크는 보여줄때만 컨버팅이네 뷰어도 만들어야한다.
// Step 1: Define the user plugin function
function youtubePlugin() {
  console.log("youtubePlugin");
}


/*
function youtubePlugin() {
  Editor.codeBlockManager.setReplacer('youtube', youtubeId => {
    const wrapperId = `yt${Math.random().toString(36).substr(2, 10)}`;
    setTimeout(renderYoutube.bind(null, wrapperId, youtubeId), 0);

    return `<div id="${wrapperId}"></div>`;
  });
}

function renderYoutube(wrapperId:any, youtubeId:any) {
  const el = document.querySelector(`#${wrapperId}`);
  if( !!!el ){
    return;
  }
  el.innerHTML = `<div class="toast-ui-youtube-plugin-wrap"><iframe src="https://www.youtube.com/embed/${youtubeId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
}
*/
// 유튜브 플러그인 끝

// eslint-disable-next-line no-unused-vars
var html = "";
//로드되면
function onEditorLoad(){
  const el  = document.getElementById("editor");
  checkimg(el);
  addYoutubeIframe(el);
}

//이미지 무조건 100%로 표시
function checkimg(el){
  const len = el.firstChild.children;
  for (const p of len) {
    for (const img of p.children) {
      const temp = img.firstChild;
      if(temp.src){
        img.style.width = "100%";
      } 
    }
  }
  return el.innerHTML   
}

//유튜브 URL을 찾아서 임베디드 플레이어로 변경
function addYoutubeIframe(el){
  //모든 child를 돌면서 a href에 유튜브 아이디값이 있으면 해당 태그를 iframe으로 교체한다.
  const len = el.firstChild.children
  const items = []
  for (const p of len) {
    for (const a of p.children) {
      const temp = a.firstChild;
      if (!temp.href) continue
      const id = youtubeUrl.extractId(temp.href)
      if (!id) continue
      temp.setAttribute('id', id)
      items.push({doc: temp, id: id});
    }
  }
  items.forEach(p => {
    p.doc.parentNode.insertBefore(setIframe(p.id), p.doc) 
    p.doc.parentNode.removeChild(p.doc);  //a href 제거
  })
}

function setIframe(id){
  const div = document.createElement('div');
  div.style.position  = "relative";
  div.style.overflow  = "hidden";
  div.style.width     = "100%";
  div.style.paddingTop = "56.25%";

  const iframe = document.createElement('iframe');
  iframe.style.position = "absolute";
  iframe.style.top = "0";
  iframe.style.left = "0";
  iframe.style.right = "0";
  iframe.style.bottom = "0";
  iframe.style.width = "100%";
  iframe.style.height = "100%";
  iframe.setAttribute('frameborder', '0')
  iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture')
  iframe.setAttribute('allowfullscreen', '')
  iframe.setAttribute('src', 'https://www.youtube.com/embed/' + id);
  div.appendChild(iframe);
  return div;
}




const editor = ref();
onMounted(() => {
  const e = Editor.factory({
    el: editor.value,
    height: '500px',
    initialValue: props.data,
    initialEditType: 'wysiwyg',
    previewStyle: 'vertical',
    hideModeSwitch:true,
    viewer:true,
    //plugins:[youtubePlugin],
    events:{
      load:onEditorLoad
    }
  });


});
</script>
