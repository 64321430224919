/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts () {
  const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

  webFontLoader.load({

    google: {
      families: ['Noto Sans KR:400,700'], // 로드할 폰트 및 스타일 지정
    },    

    custom: {
      families: [
                'Lemon'
              , 'Pretendard'
              , 'Inter'
              , 'Roboto'
            ],
      urls: [
            'https://fonts.googleapis.com/css2?family=Lemon&display=swap'
          , 'https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff'
          , 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'
          , 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'
        ],
    },    
    

  })
}
