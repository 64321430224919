<template>
  <v-container fluid style="background:white">

    <!-- 업로드 프로그레스 -->
    <comloading :isVisible="progress_dialog" msg="Please wait..." :progress="progress_value" />

    <!-- 이미지 크롭 팝업 -->
    <v-row>
      <v-dialog persistent scrollable overlay-color="#00000040"
      v-model="crop_dialog_land" max-width="500px">

        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">이미지 편집</span>
            </v-col>
          </v-row>

          <v-card-text class="ma-0 pl-5 pr-5 pt-3">
            <div style="width:100%;height:500px;">
            <vueCropper
                ref="cropper_land"
                :img="imgSrc"
                :outputType="option.outputType"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :fixedBox="option.fixedBox"
                :original="option.original"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :centerBox="option.centerBox"
                :high="option.high"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                :limitMinSize="option.limitMinSize"
                />
              </div>
          </v-card-text>
          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="crop_dialog_land = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="cropimg">확인</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-row>


    <!-- 회원탈퇴 -->
    <v-row >
      <v-dialog scrollable overlay-color="#00000040" persistent v-model="dialog_exit" width="480px">
        <v-card class="pa-1 ma-0">

          <v-row dense class="mt-5">
            <v-col cols="12" align="center">
              <span class="dialog_title">회원탈퇴</span>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
              <p>회원탈퇴를 위해 계정 비밀번호를 입력후 탈퇴 버튼을 누르세요</p>
            </v-col>
          </v-row>

          <v-row dense align="center" justify="center" class="mt-5">
            <v-col cols="10">
              <v-text-field :type="show1 ? 'text' : 'password'" v-model="user_pw" 
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              dense variant="outlined" density="compact" label="비밀번호" hint="비밀번호를 입력하세요"
              @click:append="show1 = !show1"
              />
            </v-col>
          </v-row>

          <v-card-actions class="ma-0 pa-0">
            <v-row class="ma-0 pa-0">
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_cancel_btn" width="100%" height="50px" @click="dialog_exit = false">취소</v-btn>
              </v-col>
              <v-col cols="6" class="ma-0 pa-0">
                <v-btn elevation="0" class="dialog_btn" width="100%" height="50px" @click="runexit">탈퇴</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>

        </v-card>
      </v-dialog>  
    </v-row>    

    <v-row>
      <v-col cols="12">
        <label class="menunamestyle">{{this.$storage.getStorageSync("MENUNAME")}}</label>
      </v-col>
      <v-spacer/>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="6">

    
    <v-row dense align="center" justify="center" v-if="editedItem.GRADE == 1 && editedItem.PARENT_NAME.length > 0">
      <v-col cols="3" align="center">
        <label class="mytextstyle">업체</label>
      </v-col>
      <v-col cols="9">
        <v-text-field disabled
          v-model="editedItem.PARENT_NAME" dense single-line
          variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
          />
      </v-col>
    </v-row>
    
    <v-row dense align="center" justify="center">
      <v-col cols="3" align="center">
        <label class="mytextstyle">계정</label>
      </v-col>
      <v-col cols="9">
        <v-text-field disabled
          v-model="editedItem.ACCNT" dense single-line
          variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
          />
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="3" align="center">
        <label class="mytextstyle">이름</label>
      </v-col>
      <v-col cols="9">
        <v-text-field
          v-model="editedItem.NAME" dense single-line
          variant="outlined" density="compact" height="48px" hide-details class="popupiputstyle"
          />
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="3" align="center">
        <label class="mytextstyle">회원등급</label>
      </v-col>
      <v-col cols="9">
        <v-select v-model="editedItem.GRADE" :items="GRADELIST" variant="outlined" density="compact"
          item-title="name" item-value="value" class="dialogselectstyle"
          hide-details elevation="0" dense height="48px" disabled
          /> 
      </v-col>
    </v-row>

    <v-row dense align="center" justify="center">
      <v-col cols="3" align="center">
        <label class="mytextstyle">사용지역</label>
      </v-col>
      <v-col cols="9">
        <v-autocomplete
          clearable
          :items="timeList"
          variant="outlined"
          density="compact" hide-details
          v-model="editedItem.TIMEZONE"
          />
      </v-col>
    </v-row>

  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0">
    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">가로 로고</p>
    </v-col>
    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
      <div class="black_line"/>    
    </v-col>
    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
      <v-col align="center" align-self="center" >

        <template v-if="previewImg1.length > 0">
          <v-row dense  justify="center">
            <div style="position:relative">
              <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                <v-icon style="width:80px;height:40px;color:white" @click="showImage(0)">mdi-magnify-expand</v-icon>
                <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(0)">mdi-pencil</v-icon>
                <v-icon style="width:80px;height:40px;color:white" @click="clearFile(0)">mdi-window-close</v-icon>
              </div>
              <img  width="240" style="hegiht:auto" :src="previewImg1" class="image">
            </div>
          </v-row>
        </template>
        <template v-else>
          <v-row dense class="image-border2" align="center" justify="center">
            <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(0)">mdi-image</v-icon>
          </v-row>
        </template>
      </v-col>
    </v-col>
  </v-row>

  <v-row dense align="center" justify="center" class="mt-2 mt-md-5 ma-0" >
    <v-col cols="12" md="10" align="start"  class="ma-0 pa-0">
      <p class="text-md-h6 text-subtitle-2 text-left ma-0 pa-0 dialog_subtitle_eng">세로 로고</p>
    </v-col>
    <v-col cols="12" md="10" align="center" class="pa-0 ma-0">
      <div class="black_line"/>    
    </v-col>
    <v-col cols="12" md="10" class="ma-0 pa-0" align="center">
      <v-col align="center" align-self="center" >
        <template v-if="previewImg2.length > 0">
          <v-row dense  justify="center">
              <div style="position:relative">
                <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:240px;background-color:#00000080">
                  <v-icon style="width:80px;height:40px;color:white" @click="showImage(1)">mdi-magnify-expand</v-icon>
                  <v-icon style="width:80px;height:40px;color:white" @click="fileSelect(1)">mdi-pencil</v-icon>
                  <v-icon style="width:80px;height:40px;color:white" @click="clearFile(1)">mdi-window-close</v-icon>
                </div>
                <img  width="240" style="hegiht:auto" :src="previewImg2" class="image">
              </div>
          </v-row>
        </template>
        <template v-else>
          <v-row dense class="image-border2" align="center" justify="center">
            <v-icon style="width:100px;height:100px;" size="100" color="#EDEDED" @click="fileSelect(1)">mdi-image</v-icon>
          </v-row>
        </template>
      </v-col>
    </v-col>
  </v-row>

  <v-row dense align="center" justify="center">
    <v-col cols="3" align="center">
      <label class="mytextstyle">회원상태</label>
    </v-col>
    <v-col cols="9">
      <v-select v-model="editedItem.STATE" :items="STATELIST" variant="outlined" density="compact"
        item-title="name" item-value="value" class="dialogselectstyle"
        hide-details elevation="0" dense height="48px" disabled
        />
    </v-col>
  </v-row>


    <v-row align="center" justify="center" class="mt-10">
      <v-col md="2" sm="4" xs="4" align="center">
        <v-btn class="dialog_cancel_btn" width="100%" large @click="exit">회원탈퇴</v-btn>
      </v-col>
      <v-col md="2" sm="4" xs="4" align="center">
        <v-btn class="dialog_cancel_btn" width="100%" large @click="changepass">PW변경</v-btn>
      </v-col>
      <v-col md="2" sm="4" xs="4" align="center">
        <v-btn class="dialog_btn" width="100%" large  @click="save">저장</v-btn>
      </v-col>
    </v-row>

  </v-col>
</v-row>

  </v-container>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import { VueCropper } from 'vue-cropper'; //교체용 크롭 라이브러리
import { ref as fref, uploadBytesResumable, uploadBytes, deleteObject } from "firebase/storage";
import { ref } from '@vue/reactivity';
export default {
  components:{
    VueCropper
  },

  setup(){
      var cropper_land = ref(null);
      return {cropper_land};
  },

  data: () => ({
      grade:0,
      user_pw : "",

      progress_dialog:false,
      progress_value:0,

      //파일 업로드
      uploadFile1 : [],
      uploadFile2 : [],
      previewImg1:"",
      previewImg2:"",
      crop_dialog_land: false,
      photo_index:0,
      imgSrc:'',
      ratio: 16/9,

      options: {
        focus:false,
      },      

      //신규 크롭 파라메터
      option: {
        img: "",
        size: 1,
        full: true,
        outputType: "jpg",
        canMove: true,
        fixed: true,          //크롭박스 비율 고정
        fixedNumber: [1920,1080],   //크롭박스 비율
        fixedBox: false,      //true면 크롭박스 크기 조절 안됨
        original: false,      //true면 이미지의 원본 크기로 불러와지고 false면 편집창 기준에 맞게 불러와진다.
        canMoveBox: true,
        autoCrop: true,       //true면 자동으로 크롭박스가 표시됨.
        autoCropWidth:100,    //크롭박스의 가로 크기 fixed가 true면 비율대로
        autoCropHeight:100,   //크롭박스의 세로 크기 
        centerBox: true,
        high: false,
        cropData: {},
				enlarge: 1,
        mode: 'contain',
        maxImgSize: 5000,
        limitMinSize: [100, 100]
      },            

      show1:false,
      loading : false,    //로딩 표시
      dialog_exit: false,
      dialog:false,
      defaultItem: {
          LOCALID:'',
          NAME:'',
          ACCNT:'',
          REFIMAGE:'',
          GRADE:1,
          STATE:1
      },
      editedItem: {
      },
      timeList: [],

      GRADELIST: [
          {name:'일반회원',       value:1}
        , {name:'기업회원',       value:10}
        , {name:'컨텐츠관리자',    value:80}
        , {name:'관리자',         value:99}
      ],
      STATELIST: [
          {name:'탈퇴',       value:0}
        , {name:'회원',       value:1}
      ],

  }),


  mounted(){
    this.grade = this.$storage.getStorageSync("GRADE");
    if(this.$storage.hasKey("LOCALID")){
      this.initpage();
    }else{
      //키값 없으면 로그인으로
      this.$router.push("Login");
    }
  },  


    methods: {

      changepass(){
        //인증코드 재발송
        this.$alert.fire({showCancelButton: true, title:'가입하신 이메일로 비밀번호를 변경하기 위한 인증 메일을 발송하시겠습니까?'})
        .then((aresult) => {
          if(aresult.isConfirmed){

            this.$http.post(this.$host+'/resetpassword',{
                ACCNT: this.editedItem.ACCNT
            })
            // eslint-disable-next-line no-unused-vars
            .then((result)=>{

              if(result.data.resultCode == 0){
                this.$alert.fire("비밀번호 변경 인증메일이 발송되었습니다.\n인증메일을 확인하세요");
              }else{
                this.$alert.fire(result.data.resultMsg);      
              }
            });

          }
        });
      },


      async loadtimezone(){

        this.$http.post(this.$host+'/GuestTimezone',{
        })
        .then((result)=>{
          if(result.data.resultCode == 0){
              const list = result.data.resultData;
              this.timeList.splice(0, this.timeList.length);   //데이터 초기화
              list.forEach(element => {
                this.timeList.push(element.CITY); 
              });
          }else{
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });

      },           

      initpage(){
        this.loadtimezone();
        this.loadpage();
      },

      async loadpage(){   
        //토큰값 체크
        let token;
        try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        this.loading = true;
        this.$http.post(this.$host+'/AcntDetail',{
        },{headers: { firetoken:token}})
        .then((result)=>{
          if(result.data.resultCode == 0){
              this.loading = false;
              const list = result.data.resultData;
              if(list.length > 0){
                this.editedItem = list[0];
                this.previewImg1 = this.editedItem.LOGO_IMG != null && this.editedItem.LOGO_IMG.length > 0 ? this.editedItem.LOGO_IMG : "";
                this.previewImg2 = this.editedItem.LOGO_IMG2 != null && this.editedItem.LOGO_IMG2.length > 0 ? this.editedItem.LOGO_IMG2 : "";
              }
          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.loading = false;
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');
          }else{
            this.loading = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert.fire('많은 요청으로 인해 잠시 후 시도해주세요.');
          }else{
            this.$alert.fire(error.message);
          }
        });
        
      },

      exit(){
        this.dialog_exit = true;
      },

      runexit(){

        this.dialog_exit = false;

        this.$alert.fire({showCancelButton: true, title:'탈퇴 후 재가입할 수 없습니다. 정말 탈퇴하시겠습니까?'})
        .then(async (aresult) => {

          if(aresult.isConfirmed){
            if(this.user_pw.length < 1){
              this.$alert.fire('비밀번호를 입력하세요');
              return;
            }
            if(this.user_pw.length < 6){
              this.$alert.fire("비밀번호는 최소 6글자 이상입니다.");
              return;
            }
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}
            
            this.loading = true;
            this.$http.post(this.$host+'/AcntDelete',{
              USER_PW: Buffer.from(this.user_pw).toString('base64')  
            },{headers: { firetoken:token}})
            .then((result)=>{
                this.loading = false;
                this.dialog_exit = false;
                if(result.data.resultCode == 0){
                  this.$alert.fire('회원탈퇴가 완료되었습니다.').then(()=>{
                    //로그아웃
                    this.$emitter.emit('clearsession');
                  });
                }else{
                  this.$alert.fire('회원탈퇴에 실패했습니다.('+result.data.resultMsg+")");
                }
            })
            // eslint-disable-next-line no-unused-vars
            .catch((error)=>{
                this.loading = false;
                this.$alert.fire('회원탈퇴 실패');
            });      
          }
        });

      },


      //추가 저장
      async save () {

        if(this.editedItem.GRADE == undefined || this.editedItem.GRADE.length < 1){
          this.$alert.fire("등급을 선택하세요");
          return;
        } 

        if(this.editedItem.TIMEZONE == null || this.editedItem.TIMEZONE == undefined || this.editedItem.TIMEZONE.length < 1){
          this.$alert.fire("시간대를 선택하세요");
          return;
        }

        if(this.editedItem.NAME.length < 1){
            this.$alert.fire("이름을 입력하세요");
            return;
        }
        if(this.editedItem.NAME.length > 10){
            this.$alert.fire("이름은 10자 이하로 입력하세요");
            return;
        }

        //로고 사진
        if(!this.isNullCheck(this.uploadFile1)){
          this.progress_dialog = true;            
          
          //신규인지 업데이트인지 체크
          let imgpath;
          if(!this.isNullCheck(this.editedItem.REFIMAGE)){
            imgpath  = this.editedItem.REFIMAGE;
          }else{
            let curdate = Date.now().toString();
            imgpath  = `logo/${curdate}.jpg`;
          }

          //대표 이미지 등록
          const uploadfile_ref1 = fref(this.$firestorage, imgpath);
          const metadata1 = { contentType: 'image/jpeg', resumable: false }; 
          let upload_cb1 = await this.uploadFileStorage(uploadfile_ref1, this.uploadFile1, metadata1);
          if(upload_cb1 == null){
            this.progress_dialog = false;
            this.$alert.fire("가로 로고 이미지 등록 오류 발생");
            return;
          } 
          this.uploadFile1 = [];
          /*
          try{
            if(!this.isNullCheck(this.editedItem.REFIMAGE)){
              const desertRef1 = fref(this.$firestorage, this.editedItem.REFIMAGE);
              await deleteObject(desertRef1);
            }
          }catch(err){
            console.log(err);
          }
          */
          this.editedItem.REFIMAGE = upload_cb1._location.path_;
        }

        if(!this.isNullCheck(this.uploadFile2)){
          this.progress_dialog = true;           
          //신규인지 업데이트인지 체크
          let imgpath;
          if(!this.isNullCheck(this.editedItem.REFIMAGE2)){
            imgpath  = this.editedItem.REFIMAGE2;
          }else{
            let curdate = Date.now().toString();
            imgpath  = `logo/${curdate}.jpg`;
          }
          
          //대표 이미지 등록
          const uploadfile_ref2 = fref(this.$firestorage, imgpath);
          const metadata2 = { contentType: 'image/jpeg', resumable: false }; 
          let upload_cb2 = await this.uploadFileStorage(uploadfile_ref2, this.uploadFile2, metadata2);
          if(upload_cb2 == null){
            this.progress_dialog = false;
            this.$alert.fire("세로 로고 이미지 등록 오류 발생");
            return;
          } 
          this.uploadFile2 = [];
          /*
          try{
            if(!this.isNullCheck(this.editedItem.REFIMAGE2)){
              const desertRef2 = fref(this.$firestorage, this.editedItem.REFIMAGE2);
              await deleteObject(desertRef2);
            }
          }catch(err2){
            console.log(err2);
          }
          */
          this.editedItem.REFIMAGE2 = upload_cb2._location.path_;
        }
            //토큰값 체크
            let token;
            try{ token = await this.$fireauth.currentUser.getIdToken(); }catch(error){token=this.$storage.getStorageSync("FIRETOKEN");}

        //수정
        this.loading = true;
        this.$http.post(this.$host+'/AcntUpdate',{
              GRADE: this.editedItem.GRADE
            , NAME: this.editedItem.NAME == null || this.editedItem.NAME == undefined ? '' : escape(this.editedItem.NAME)
            , LOGO_IMG: this.editedItem.REFIMAGE == null || this.editedItem.REFIMAGE == undefined ? '' : escape(this.editedItem.REFIMAGE)
            , LOGO_IMG2: this.editedItem.REFIMAGE2 == null || this.editedItem.REFIMAGE2 == undefined ? '' : escape(this.editedItem.REFIMAGE2)
            , STATE: this.editedItem.STATE
            , TIMEZONE: this.editedItem.TIMEZONE
            , PARENT_LOCALID: this.editedItem.PARENT_LOCALID
            , LOCALID: this.editedItem.LOCALID
        },{headers: { firetoken:token}})
        .then((result)=>{
          this.loading = false;
          this.progress_dialog = false;
          if(result.data.resultCode == 0){
              
              this.$alert.fire("수정이 완료되었습니다").then(()=>{
                this.loadpage();
              });

          }else if(result.data.resultCode == 2){
            //로그인 필요
            this.$alert.fire("토큰 만료로 인해 다시 로그인해주세요.");
            this.$emitter.emit('clearsession');

          }else{
              this.$alert.fire('수정이 실패했습니다.');
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          this.loading = false;
          this.$alert.fire('수정이 실패했습니다.');
        });

      
      },      



      showImage(index){
        var img;
        if(index == 0){
          img = [this.previewImg1];
        }else if(index == 1){
          img = [this.previewImg2];
        }
        this.$viewerApi({
          images: img,
        })
      },      

      clearFile(index){
        if(index == 0){
          this.previewImg1 = "";
          this.uploadFile1 = null;
          this.editedItem.BUSINESS_REF = null;
        }else if(index == 1){
          this.previewImg2 = "";
          this.uploadFile2 = null;
          this.editedItem.CARD_REF = null;
        }
      },

      fileSelect(index){
        const changefunction = this.changefile;
        var input = document.createElement("input");
        input.style = "display:none";
        input.name = "imageinputcamera";
        input.type = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        if(index == 0){
          this.option.fixedNumber = [1920, 1080];
          this.option.autoCropWidth = 178;
          this.option.autoCropHeight = 100;
        }else{
          this.option.fixedNumber = [1080, 1920];
          this.option.autoCropWidth = 100;
          this.option.autoCropHeight = 178;
        }

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){
          //아이폰, 아이패드, 맥
          //input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
          document.body.appendChild(input);
          input.onchange = function (event) {
            //추가된 input 제거
            document.body.removeChild(input);
            changefunction(event.target.files[0], index);
          };
        }else{
          input.onchange = function (event) {
            changefunction(event.target.files[0], index);
          };
        }
        input.click();
      },     


      //이미지 선택시
      changefile(file, pindex){
        console.log("change file1 : " + file);
        console.log("change file2 : " + pindex);

        if(file.size/1024/1024 > 10){
          this.$alert.fire("10MB 이하 파일만 사용할 수 있습니다.");
          if(pindex == 0){
            this.uploadFile1 = [];
          }else if(pindex == 1){
            this.uploadFile2 = [];
          }
          return;
        }

        //포커스에 따라서 자꾸 이벤트가 콜백되어서 실제 파일이 선택된 경우에만 처리되도록 수정함.
        //if(this.uploadFile != undefined && this.uploadFile.name != undefined && this.uploadFile.name.length > 0){
          //이미지 크롭 팝업 띄우기
          const reader = new FileReader();
          reader.onload = (event)=>{
            this.photo_index = pindex;
            this.crop_dialog_land = true;
            this.imgSrc = event.target.result;  //이게 blob데이터
          }
          reader.readAsDataURL(file);  //비동기로 파일을 읽어서
        //}

      },      

      //이미지 크롭시
      cropimg(){
        //이미지 크롭 
        this.cropper_land.getCropBlob(blob=>{
          this.crop_dialog_land = false;
          this.cropper_land.getCropData(data=>{
            if(this.photo_index == 0){
              this.uploadFile1 = blob;  //업로드용 blob 데이터
              this.previewImg1 = data; //이미지뷰 미리보기용
            }else if(this.photo_index == 1){
              this.uploadFile2 = blob;
              this.previewImg2 = data; //이미지뷰 미리보기용
            }
          });
        });
      },


      //firebase storage에 파일 업로드 및 프로그레스 표시
      uploadFileStorage(storage_ref, blobd_ata, metadata){
        return new Promise((resolve, reject)=>{

            const uploadTask = uploadBytesResumable(storage_ref, blobd_ata, metadata);
            uploadTask.on('state_changed',
              (snapshot) => {
                this.progress_value = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused');
                    break;
                  case 'running':
                    console.log('Upload is running' + this.progress_value);
                    break;
                }                
              },
              // eslint-disable-next-line no-unused-vars
              (error) => {
                reject(null);
              },
              () => {
                // Upload completed successfully - 업로드 완료시 경로 리턴
                resolve(uploadTask.snapshot.ref);
              }
            );

        })

      },      

      isNullCheck(value){
        if(value == null || value == undefined || value.length < 1){
          return true;
        }else{
          return false;
        }
      },

    },

}
</script>
