
import Component_Model from  '@/nextts/model/Component_Model';
import Http_Interface from '@/nextts/play/Http_Interface';
import Play_Interface from '@/nextts/play/Play_Interface';


//기본 데이터 속성
class Play_Sound extends HTMLAudioElement implements Http_Interface, Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    timeline:null|GSAPTimeline = null;

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.position = "absolute";
    }
    
    //Play_Interface 구현
    init(): void {
        //console.log("Play Text init");
        //커스텀 api등을 통해야 하는 경우에는 분기하고
        
        if(this.model == null) return;

        //바로재생
        if(this.model.api_call == 1){
            setTimeout(() => {
                this.soundplay();
             }, this.model.sound_delay * 1000);
        }

    }

    //이벤틀로 사운드 플레이 호출시
    soundplayevent(){
        if(this.model == null) return;
        setTimeout(() => {
            this.soundplay();
         }, this.model.sound_delay * 1000);
    }

    soundplay(){
        
        if(this.model == null) return;

        if(this.model.sound instanceof File){
            //파일이면 
            this.src = URL.createObjectURL(this.model.sound);
            if(this.model.loop){
                this.loop = true;  
            }else{
                this.loop = false;  
            }
            this.play();
        }else{
            if(this.model.sound != null && this.model.sound.length > 0){
                this.src = this.model.sound;    
                if(this.model.loop){
                    this.loop = true;  
                }else{
                    this.loop = false;  
                }
                this.play();
            }
        }

    }

    exit(): void {
        
        //효과음 초기화 (일단 패스)

        //타이머 초기화
        
        //파일인 경우 임시로 생성된 blob을 제거한다. (메모리 해제)
        if(this.model!.sound instanceof File){
            URL.revokeObjectURL(this.src);
        }
        this.src = "";

        //HTMLElement 초기화
        if(this.model != null){
            this.model.play_component = null;
        }


    }

    playAni(){

    }

    //애니매이션 실행
    runAni(){

    }

    drawView(){

    }

    //Http_Interface 구현
    resultHttp(a_id:number, result:string, obj:object){

        
    }

}

export default Play_Sound
customElements.define('play-audio', Play_Sound, {extends:'audio'});