<!-- 업체 선택용 공통 팝업 -->
<template>
    
    <v-row align="center" justify="center" class="mt-5 mt-md-5">
      <v-col cols="10" align-self="center" align="center" class="align-center justify-center pa-0 mt-1 mt-md-0 pl-1 pl-md-0 pr-1 pr-md-2 d-flex">
        <input
          dense single-line clearable
          @keyup.enter="searchbar_run" 
          v-model="search_keyword" 
          color="#000000"
          hide-details
          class="basicsearchstyle_mb"
          />
          <v-btn icon class="search_btn_mb" elevation="0" outlined large @click="searchbar_run">
            <v-icon size="20">mdi-magnify</v-icon>
          </v-btn>
      </v-col>
    </v-row>

</template>

<script>
    export default {
        
        //전달받을 프로퍼티 정의, 타입을 정의해도 되고 안해도 되고
        props:{
        
        },

        data: ()=> ({
          search_keyword: '',
        }),

        methods: {

          //외부에서 검색어 셋팅
          setSearch(search){
            this.search_keyword = search;
          },
          
          searchbar_run(){
            console.log("searchbar run : " + this.search_keyword);
            this.$emit('searchcallback', this.search_keyword);
          },

        }

    }
</script>

<style scoped>

  .basicsearchstyle_mb{
    font-size:14px;
    color: black;
    height: 30px;
    font-weight: bold;
    background: white;
    margin-right: 0px !important;
    border-radius: 0px !important;
    border: solid black 3px;
    max-width: 300px;
    width: 100%;
    padding-top: 2px !important;
    padding-left: 10px !important;
  }


  .search_btn_mb {
    color: #ffffff !important;
    background-color: #000000 !important;
    border: thin solid #000000 !important;
    border-radius: 0px !important;
    height: 30px !important;
    width: 30px !important;
  }


</style>