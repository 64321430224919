<template>
    <template v-if="contentStore.select_component != null && (contentStore.select_component.model.component_type == 0)">

    <v-container fluid class="ma-0 pt-0">
    
        <v-row align="center" dense>
            <v-col cols="12">
                <v-switch label="저장" color="red"  hide-details v-model="this.contentStore.select_component.model.save_use"></v-switch>
            </v-col>
        </v-row>

        <v-row justify="center" align="center" dense class="mt-0 pt-0" v-if="this.contentStore.select_component.model.save_use">

            <v-row class="pt-0 ma-0" align="center" justify="center" dense>
                <v-col cols="12" class="pa-0" align="left">
                    <label style="font-size:12px">저장소 이름</label>
                </v-col>
                <v-col cols="12" class="pa-0" align="center">
                    <input 
                        v-model="contentStore.select_component.model.save_name" 
                        style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                        />
                </v-col>
            </v-row>

        </v-row>

        <v-row dense class="mt-3"> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

    </v-container>
    </template>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";

export default {
  

  setup(){
    
    const contentStore = useContentStore();
    return {contentStore}
  },  

  methods:{


  },

  data(){
    return{

    }
  },

  mounted(){
    
  },

  
}
</script>