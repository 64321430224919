import AnimationPlay_Model from "@/nextts/model/AnimationPlay_Model";
import Gradient_Model from "@/nextts/model/Gradient_Model";
import API_Model from "./API_Model";


//컴포넌트 정의 모델
class Component_Model {

    //컴포넌트 타입 정의
    static COMPONENT_TEXT:number            = 0;
    static COMPONENT_IMAGE:number           = 1;
    static COMPONENT_TEXTSWITCHER:number    = 2;
    static COMPONENT_IMAGESWITCHER:number   = 3;
    static COMPONENT_COLOR:number           = 4;
    static COMPONENT_MOVIE:number           = 5;
    static COMPONENT_YOUTUBE:number         = 6;
    static COMPONENT_WEBVIEW:number         = 7;
    static COMPONENT_TEXT_SCROLL:number     = 8;
    static COMPONENT_DATETIME:number        = 9;
    static COMPONENT_ARTTEXT:number         = 10;
    static COMPONENT_SOUND:number           = 20;
    static COMPONENT_API:number             = 30;
    static COMPONENT_DATALOAD:number        = 40;
    static COMPONENT_ANITEST:number         = 9998;
    static COMPONENT_ANISHOW:number         = 9997;

    //그룹처리용
    isgroup:Boolean         = false;
    groupid:string          = "";

    //위치크기 정보 (기본 정보)
    width:number    = 0;
    height:number   = 0;
    posx:number     = 0;
    posy:number     = 0;
    alpha:number    = 1.0;
    
    //변형 정보 (변형 매트릭스)
    rotate:number   = 0;
    transx:number   = 0;
    transy:number   = 0;

    //선택락, 이동락
    selectlock:Boolean       = false;
    movelock:Boolean         = false;

    //컨텐츠 타입
    component_type:number = Component_Model.COMPONENT_TEXT;

    opacity:number = 1.0;

    //기본 외형정보
    bgtype:number           = 0;      //0:color, 1:image

    //그라데이션 컬러 (배경, 폰트, 컬러 공통임)
    gradient:Gradient_Model;

    //컬러
    backgroundColor:string  = "#880A6E";
    backgroundImg:string    = "";

    //텍스트
    text:string = "Input Text";         //텍스트 내용
    textfont:string     = "나눔고딕";        //텍스트 폰트, FONTFAMILY
    font_url:string     = "";
    font_format:string  = "";  
    font_weight:string  = "";
    font_style:string   = "";
    font_name:string    = "";
    font_idx:number         = 2;            //나눔고딕
    fontweight_idx:number   = 1;            //나눔고딕 Bold

    textsize:number         = 16;           //폰트 사이즈
    textcolor:string        = "#000000";    //폰트 색상
    texthori_align:string   = "center";     //가로 정렬
    textverti_align:string  = "center";     //세로 정렬

    letterSpacing:number    = 0;           //텍스트 글자간격
    lineHeight:number       = 100;         //텍스트 줄간격
    textUnderline:Number    = 0;
    textBold:Number         = 0;
    textItalic:Number       = 0;

    //세로배치시 영문과 특수문자가 옆으로 누워버림.
    //writingMode:string      = "horizontal-tb";  //텍스트 방향 가로,세로

    //텍스트 그림자
    shadow_use:boolean  = false;
    shadow_hori:number  = 2;
    shadow_verti:number = 2;
    shadow_blur:number  = 4;
    shadow_color:string = "#000000ff";

    //텍스트 외각선
    outline_use:boolean     = false;
    outline_color:string    = "#000000ff";
    outline_size:number     = 1;
    outline_offset:number   = 1;
    outline_dash1:number    = 0;
    outline_dash2:number    = 0;

    //데이트 타입
    date_type:string        = "YYYY-MM-DD HH:mm:ss";

    //고급 텍스트 기능
    color_type:string     = "color";    //image, gradient, color
    text_arc:number      = 0;           //텍스트 곡선
    text_offset:number   = 0;           //가로 오프셋
    text_offsetverti:number = 0;        //세로 오프셋

    //보더 레디우스
    border_radius:number = 0;


    save_use:boolean  = false;
    save_name:String = "";

    load_type:number = 0;
    load_name:String = "";
    load_path:String = "";
    load_data:String = "";

    //이미지
    image:string = "";

    //동영상
    movie:any = "";
    movie_time:number = 5;

    //유튜브 url
    youtube:string = "";
    youtube_time:number = 5;

    //웹뷰 url
    weblink:string = "";

    //텍스트 스위처
    textswitcher_list:Array<String> = [];
    textswitcher_time = 3;
    textswitcher_pos  = 0;
    textswitcher_ani  = 0;

    //이미지 스위처
    imgswitcher_list:Array<String> = [];
    imgswitcher_time = 3;
    imgswitcher_pos  = 0;
    imgswitcher_ani  = 0;
    
    //사운드
    sound:any = "";
    sound_time:number  = 0;
    sound_delay:number = 0;

    //동영상, 유튜브, 사운드 반복 재생 유무
    loop:boolean = false;

    //API
    apilink:string = "";
    apiname:string = "";    //api 이름
    api_type:number = 0;
    api_header:API_Model;
    api_param:API_Model;
    apiresult_key:string = "";          //정상처리 판단키 [][]
    apiresult_value:string = "";        //정상처리 판단값
    apiresult_type:string = "";         //후처리 타입
    apiresult_movekey:string = "";      //페이지 이동 키 [][]
    api_datatype:string = "json";

    api_call:number = 0;
    api_use:boolean  = false;
    apiarray:string = "";   //밸유맵 [][][]
    apirow:number = 0;      //맵핑 row,  사용안함
    apikey:string = "";     //맵핑 키값, 사용안함

    //이벤트
    event_use:boolean   = false;
    event_type:number   = 0;
    event_name:String   = "";       //컴포넌트 네임
    event_move:String   = "";       //이동할 페이지 네임
    event_api:String    = "";       //호출할 apiname
    event_input_target:String = ""; //타이핑이 들어간 컴포넌트 네임
    event_input_key:String = "";    //타이핑할 키값.


    //마스크 처리
    mask_use:boolean = false;
    mask_repeat:boolean = false;
    mask_left:number   = 50;
    mask_top:number    = 50;
    mask_width:number  = 100;
    mask_height:number = 100;
    mask_image:string  = "";

    //커스텀 - 팝업등에 사용
    use_popup:boolean  = false;

    //애니매이션 정보
    aniplaymodel:AnimationPlay_Model;

    //자기 자신의 Drawing Component 객체 - JSON.parse에서 제외시켜야 한다.
    component:HTMLElement | null = null;

    //Play용 HtmlElement 객체
    play_component:HTMLElement|null = null;

    constructor(){
        this.aniplaymodel   = new AnimationPlay_Model();
        this.gradient       = new Gradient_Model();
        this.api_header     = new API_Model();
        this.api_param      = new API_Model();
    }

    //애니매이션 전체 시간 리턴
    getAniDuration():number{
        let duration:number = 0;
        if(this.aniplaymodel != null && this.aniplaymodel.anilist != null && this.aniplaymodel.anilist.length > 0){
            this.aniplaymodel.anilist.forEach(ani=>{
                duration += Number(ani.delay) + Number(ani.duration);
            });
        }
        return duration;
    }

    //컨텐츠 타입에 따른 기본 재생시간 리턴
    getPlayDuration():number{
        let duration:number = 0;
        if(this.component_type == Component_Model.COMPONENT_TEXT || this.component_type == Component_Model.COMPONENT_DATETIME || this.component_type == Component_Model.COMPONENT_ARTTEXT){
            duration = 0;
        }else if(this.component_type == Component_Model.COMPONENT_IMAGE){
            duration = 0;
        }else if(this.component_type == Component_Model.COMPONENT_TEXTSWITCHER){

            if(this.api_use){
                //api 사용시 rownum+1 * 시간처리
                if(isNaN(this.textswitcher_time)){
                    this.textswitcher_time = 3;
                }
                duration = (this.textswitcher_time * (this.apirow+1));
            }else{
                if(this.textswitcher_list != null && this.textswitcher_list.length > 0){
                    if(isNaN(this.textswitcher_time)){
                        this.textswitcher_time = 3;
                    }
                    duration = (this.textswitcher_time * this.textswitcher_list.length);
                }else{
                    duration = 0;
                }
            }
        }else if(this.component_type == Component_Model.COMPONENT_IMAGESWITCHER){
            if(this.api_use){
                //api 사용시 rownum+1 * 시간처리
                if(isNaN(this.imgswitcher_time)){
                    this.imgswitcher_time = 3;
                }
                duration = (this.imgswitcher_time * (this.apirow+1));
            }else{
                if(this.imgswitcher_list != null && this.imgswitcher_list.length > 0){
                    if(isNaN(this.imgswitcher_time)){
                        this.imgswitcher_time = 3;
                    }
                    duration = (this.imgswitcher_time * this.imgswitcher_list.length);
                }else{
                    duration = 0;
                }
            }
        }else if(this.component_type == Component_Model.COMPONENT_COLOR){
            duration = 0;
        }else if(this.component_type == Component_Model.COMPONENT_MOVIE){
            duration = isNaN(this.movie_time) ? 5 : this.movie_time;
        }else if(this.component_type == Component_Model.COMPONENT_YOUTUBE){
            duration = isNaN(this.youtube_time) ? 5 : this.youtube_time;
        }else if(this.component_type == Component_Model.COMPONENT_WEBVIEW){
            duration = 0;
        }else if(this.component_type == Component_Model.COMPONENT_SOUND){
            duration = isNaN(this.sound_time) ? 0 : this.sound_time;
        }else if(this.component_type == Component_Model.COMPONENT_DATALOAD){
            duration = 0;
        }else if(this.component_type == Component_Model.COMPONENT_API){
            duration = 0;
        }else if(this.component_type == Component_Model.COMPONENT_TEXT_SCROLL){
            
            const font:string = `${this.textsize}px ${this.textfont}`;
            const textWidth:number = this.getTextWidth(this.text, font);
            const distanceToTravel = textWidth - this.width; //컴포넌트의 넓이, 이걸 빼면 텍스트의 끝이 컴포넌트의 우측 끝에서 시간 종료됨.

            //1초에 100px정도 이동하깐 넓이를 100으로 나누면 초가 나옴.
            duration = distanceToTravel/100;
            
            console.log("font : " + font);
            console.log("textWidth : " + textWidth);
            //console.log("distanceToTravel : " + distanceToTravel);
            console.log("duration : " + duration);
        }
        //console.log("get play duration : " + this.component_type + " = " + duration);
        return duration;
    }

    //텍스트 넓이 알아내기 (스크롤 텍스트 시간 구하기 위함.)
    getTextWidth(text: string, font: string): number {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) {
          throw new Error('CanvasRenderingContext2D not supported');
        }
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    setStringValue(params:string):void{
    
        const temp          = JSON.parse(JSON.stringify(params));

        //그룹처리용
        this.isgroup         = temp.isgroup;
        this.groupid         = temp.groupid;

        //위치크기 정보 (기본 정보)
        this.width  = temp.width;
        this.height = temp.height;
        this.posx   = temp.posx;
        this.posy   = temp.posy;
        this.alpha  = temp.alpha;
        
        //변형 정보 (변형 매트릭스)
        this.rotate = temp.rotate;
        this.transx = temp.transx;
        this.transy = temp.transy;

        //선택락 이동락
        this.selectlock = temp.selectlock == undefined || null ? false : temp.selectlock;
        this.movelock   = temp.movelock == undefined || null ? false : temp.movelock;

        //컨텐츠 타입
        this.component_type     = temp.component_type;

        this.opacity            = temp.opacity == undefined ? 1.0 : temp.opacity;

        //기본 외형정보
        this.bgtype          = temp.bgtype;

        //그라디언트
        if(temp.gradient != undefined && temp.gradient != null){
            this.gradient.setStringValue(temp.gradient);
        }

        //컬러
        this.backgroundColor = temp.backgroundColor;
        this.backgroundImg   = temp.backgroundImg;

        //텍스트
        this.text           = temp.text;
        this.textfont       = temp.textfont;
        this.font_url       = temp.font_url;
        this.font_format    = temp.font_format;
        this.font_weight    = temp.font_weight == null || temp.font_weight.length == 0 || temp.font_weight == undefined ? "normal" : temp.font_weight;
        this.font_style     = temp.font_style;
        this.font_name      = temp.font_name == undefined ? "" : temp.font_name;
        this.font_idx       = temp.font_idx == undefined ? -1 : temp.font_idx;
        this.fontweight_idx = temp.fontweight_idx == undefined ? -1 : temp.fontweight_idx;

        this.textsize       = temp.textsize;
        this.textcolor      = temp.textcolor;
        this.texthori_align = temp.texthori_align;
        this.textverti_align = temp.textverti_align;
        this.letterSpacing  = temp.letterSpacing;
        this.lineHeight     = temp.lineHeight;
        this.textUnderline  = temp.textUnderline;
        this.textBold       = temp.textBold;
        this.textItalic     = temp.textItalic;
        //this.writingMode    = temp.writingMode == undefined ? "horizontal-tb" : temp.writingMode;
        
        //텍스트 그림자
        this.shadow_use     = temp.shadow_use;
        this.shadow_hori    = temp.shadow_hori;
        this.shadow_verti   = temp.shadow_verti;
        this.shadow_blur    = temp.shadow_blur;
        this.shadow_color   = temp.shadow_color;

        //텍스트 외각선
        this.outline_use     = temp.outline_use == undefined ? false : temp.outline_use;
        this.outline_color   = temp.outline_color == undefined ? "#000000ff" : temp.outline_color;
        this.outline_size    = temp.outline_size == undefined ? 1 : temp.outline_size;
    
        //데이트 타입
        this.date_type   = temp.date_type == undefined ? "YYYY-MM-DD HH:mm:ss" : temp.date_type;

        //텍스트 색상 타입
        this.color_type     = temp.color_type == undefined ? "color" : temp.color_type;
        this.text_arc       = temp.text_arc == undefined ? 0 : temp.text_arc;
        this.text_offset    = temp.text_offset == undefined ? 0 : temp.text_offset;
        this.text_offsetverti = temp.text_offsetverti == undefined ? 0 : temp.text_offsetverti;

        this.border_radius  = temp.border_radius == undefined ? 0 : temp.border_radius;

        this.save_use = temp.save_use == undefined ? false : temp.save_use;
        this.save_name = temp.save_name == undefined ? "" : temp.save_name;

        this.load_type = temp.load_type == undefined ? 0 : temp.load_type;
        this.load_name = temp.load_name == undefined ? "" : temp.load_name;
        this.load_path = temp.load_path == undefined ? "" : temp.load_path;
        this.load_data = "";

        //이미지
        this.image  = temp.image;

        //동영상
        this.movie      = temp.movie;
        this.movie_time = temp.movie_time;

        //유튜브 url
        this.youtube        = temp.youtube;
        this.youtube_time   = temp.youtube_time;

        //웹뷰 url
        this.weblink = temp.weblink;

        //사운드
        this.sound = temp.sound;
        this.sound_time = temp.sound_time;
        this.sound_delay = temp.sound_delay == undefined ? 0 : temp.sound_delay;

        this.loop = temp.loop == undefined ? false : temp.loop;
        
        //api
        this.apiname = temp.apiname;
        this.apilink = temp.apilink;

        this.api_type = temp.api_type == undefined ? 0 : temp.api_type;
        this.api_call = temp.api_call == undefined ? 0 : temp.api_call;
        
        if(temp.api_header != undefined && temp.api_header != null){
            this.api_header.setStringValue(temp.api_header);
        }
        if(temp.api_param != undefined && temp.api_param != null){
            this.api_param.setStringValue(temp.api_param);
        }
        this.apiresult_key      = temp.apiresult_key == undefined ? "" : temp.apiresult_key;
        this.apiresult_type     = temp.apiresult_type == undefined ? "" : temp.apiresult_type;
        this.apiresult_value    = temp.apiresult_value == undefined ? "" : temp.apiresult_value;
        this.apiresult_movekey  = temp.apiresult_movekey == undefined ? "" : temp.apiresult_movekey;
        this.api_datatype       = temp.api_datatype == undefined ? "json" : temp.api_datatype;

        //api 맵핑
        this.api_use    = temp.api_use;
        this.apiarray   = temp.apiarray == undefined ? "" : temp.apiarray;
        this.apirow     = temp.apirow;
        this.apikey     = temp.apikey;

        //이벤트    
        this.event_use     = temp.event_use == undefined ? false : temp.event_use;
        this.event_type    = temp.event_type == undefined ? 0 : temp.event_type;
        this.event_name    = temp.event_name == undefined ? "" : temp.event_name;
        this.event_move    = temp.event_move == undefined ? "" : temp.event_move;
        this.event_api     = temp.event_api == undefined ? "" : temp.event_api;
        this.event_input_target = temp.event_input_target == undefined ? "" : temp.event_input_target;
        this.event_input_key    = temp.event_input_key == undefined ? "" : temp.event_input_key;

        //마스크
        this.mask_image     = temp.mask_image == undefined ? "" : temp.mask_image;
        this.mask_use       = temp.mask_use == undefined ? false : temp.mask_use;
        this.mask_repeat    = temp.mask_repeat == undefined ? false : temp.mask_repeat;
        this.mask_left      = temp.mask_left == undefined ? 50 : temp.mask_left;
        this.mask_top       = temp.mask_top == undefined ? 50 : temp.mask_top;
        this.mask_width     = temp.mask_width == undefined ? 100 : temp.mask_width;
        this.mask_height    = temp.mask_height == undefined ? 100 : temp.mask_height;

        //커스텀
        this.use_popup      = temp.use_popup == undefined ? false : temp.use_popup;

        //텍스트 스위처
        this.textswitcher_list.splice(0, this.textswitcher_list.length);
        if(temp.textswitcher_list != null && temp.textswitcher_list.length > 0){
            temp.textswitcher_list.forEach((textswitcher:string) => {
                this.textswitcher_list.push(textswitcher);
            });        
        }
        this.textswitcher_time = temp.textswitcher_time;
        this.textswitcher_pos  = temp.textswitcher_pos;
        this.textswitcher_ani  = temp.textswitcher_ani;

        //이미지 스위처
        this.imgswitcher_list.splice(0, this.imgswitcher_list.length);
        if(temp.imgswitcher_list != null && temp.imgswitcher_list.length > 0){
            temp.imgswitcher_list.forEach((imgswitcher:string) => {
                this.imgswitcher_list.push(imgswitcher);
            });        
        }
        this.imgswitcher_time = temp.imgswitcher_time;
        this.imgswitcher_pos  = temp.imgswitcher_pos;
        this.imgswitcher_ani  = temp.imgswitcher_ani;

        //애니매이션 정보
        if(temp.aniplaymodel != undefined && temp.aniplaymodel != null){
            this.aniplaymodel.setStringValue(temp.aniplaymodel);
        }

        
    }



}
//module.exports = {Component_Model}
export default Component_Model
