<template>
    <v-container fluid>
    <template v-if="contentStore.select_component == null && contentStore.select_screen != null">
        
        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">Screen Name</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <input 
                    v-model="contentStore.select_screen.screen_name" 
                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                    />
            </v-col>
        </v-row>
        <v-row class="pt-0 ma-0" align="center" justify="center" dense>
            <v-col cols="12" class="pa-0" align="left">
                <label style="font-size:12px">Next Page Name(사용시 입력)</label>
            </v-col>
            <v-col cols="12" class="pa-0" align="center">
                <input 
                    v-model="contentStore.select_screen.next_name" 
                    style="width:100%;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:left;padding-left:5px"
                    />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" dense class="pa-0">
            <v-col cols="12">
                <label style="font-size:12px">BG Type</label>
            </v-col>
            <v-col cols="12" class="pa-0 ma-0">
                <v-btn-toggle
                    v-model="contentStore.select_screen.bgtype"
                    color="primary" 
                    mandatory style="height:34px"
                    @update:modelValue="updateRender"
                    >
                    <v-btn :value="0" style="width:70px;height:30px" variant="text" size="x-small">color</v-btn>
                    <v-btn :value="2" style="width:70px;height:30px" variant="text" size="x-small">gradient</v-btn>
                    <v-btn :value="1" style="width:70px;height:30px" variant="text" size="x-small">image</v-btn>
                </v-btn-toggle>                
            </v-col>
        </v-row>

        <!-- 배경타입 : 컬러 -->
        <v-row v-if="contentStore.select_screen.bgtype == 0" justify="center" align="center" dense class="mt-0 pt-0">
            <v-col cols="12">
                <dialogscbgcolor dense title="BG Color" ref="bgcolor" />
            </v-col>
        </v-row>

        <!-- 배경타입 : 그라디언트 -->
        <v-row v-if="contentStore.select_screen.bgtype == 2" justify="center" align="center" dense class="mt-5 mb-4 pt-0">
            <v-col cols="12" align="center">
                <dialogscgrcolor dense title="BG Color" property="gradient"/>
            </v-col>
        </v-row>

        <!-- 배경타입 : 이미지 -->
        <v-row v-if="contentStore.select_screen.bgtype == 1" class="mt-3" justify="center" align="center" dense>
            <template v-if="this.contentStore.select_screen.backgroundImg.length > 0">
                <v-row dense  justify="center">
                <div style="position:relative">
                    <div style="position:absolute;bottom:5px;margin-top:calc(100%-40px);margin-left:0px;height:40px;width:200px;background-color:#00000080">
                        <v-icon style="width:50px;height:40px;color:white" @click="fileSelect()">mdi-pencil</v-icon>
                    </div>
                    <img  width="200" style="hegiht:auto" :src="this.contentStore.select_screen.backgroundImg" class="image">
                </div>
                </v-row>
            </template>
            <template v-else>
                <v-row dense class="image-border2" align="center" justify="center">
                    <v-icon style="width:50px;height:50px;" size="50" color="#EDEDED" @click="fileSelect()">mdi-image</v-icon>
                </v-row>
            </template>
        </v-row>

        <v-row dense> 
            <v-col>
                <v-divider/>        
            </v-col>
        </v-row>

        <!-- 스크린 대기 시간 -->
        <v-row justify="start" align="center" dense class="mt-2 pt-0">
            <label style="margin-left:4px;width:70px;text-align:left;font-size:12px">대기 시간</label>
            <input 
                v-model="contentStore.select_screen.trans_time" 
                style="width:80px;font-size:12px;border: solid 1px gray;border-radius:3px;text-align:center"
                />
            <label style="margin-left:5px;width:40px;text-align:left;font-size:12px">Sec</label>
        </v-row>


    </template>
    </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, toRefs, toRef, reactive, onMounted, getCurrentInstance } from 'vue'
import { useContentStore } from "@/stores/content";
export default {
  
  setup(){
    let bgcolor      = ref(null);
    const contentStore = useContentStore();
    return {bgcolor, contentStore}
  },

  methods:{
    
    updateRender(){
        //스크린 렌더링 - 이벤트버스 전송
        this.$emitter.emit("drawscreen");
    },


    fileSelect(){

        const changefunction = this.changefile;
        var input    = document.createElement("input");
        input.style  = "display:none";
        input.name   = "imageinputcamera";
        input.type   = "file";
        input.accept = ".jpg,.jpeg,.png"; // 확장자가 xxx, yyy 일때, ".xxx, .yyy"

        let indexcnt = navigator.userAgent.toLowerCase().indexOf("mac os x");
        if(indexcnt > -1){

            //아이폰, 아이패드, 맥 => input이 실제 돔에 추가되야 사진수정에 따른 onchange가 작동한다.
            document.body.appendChild(input);
            input.onchange = function (event) {
                //추가된 input 제거
                document.body.removeChild(input);
                changefunction(event.target.files[0]);
            };

        }else{
            input.onchange = function (event) {
                changefunction(event.target.files[0]);
            };
        }
        input.click();
    },    

    //이미지 선택시
    changefile(file){

        if(file.size/1024/1024 > 10){
            alert("10메가 이하 파일만 사용하세요.");
            this.uploadFile = [];
            return;
        }

        //이미지 크롭 팝업 띄우기
        const reader = new FileReader();
        reader.onload = (event)=>{
            this.contentStore.select_screen.backgroundImg = event.target.result;  //이게 blob데이터
            this.updateRender();
        }
        reader.readAsDataURL(file);  //비동기로 파일을 읽어서

    },

  },

  //프로퍼티 선언
  props:{
    
  },  

  data(){
    return{
        bgtypelist:[
                {name:"color",  value:0}
            ,   {name:"image",  value:1}
        ],
    }
  },

  mounted(){
    
  },

  
}
</script>