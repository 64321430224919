import Content_Model from '@/nextts/model/Content_Model';
import Component_Model from  '@/nextts/model/Component_Model';
import Play_Interface from '@/nextts/play/Play_Interface';
import { useContentStore } from "@/stores/content";

//기본 데이터 속성
class Play_DataLoad extends HTMLElement implements Play_Interface{
    
    //Component_Interface 구현
    model:Component_Model|null = null;
    contentStore = useContentStore();

    constructor(m:Component_Model){
        super();
        this.model = m;
        this.style.visibility = "hidden";
    }


    //데이터 로드
    loadData(){

        if(this.model == null || this.model == undefined){
            return;
        }

        if(this.model.load_type == 0){
            //text data
            if(Content_Model.apiData.has(String(this.model.load_name))){
                
                const dataary = Content_Model.apiData.get(String(this.model.load_name));
                if(dataary != null && dataary != undefined){
                    this.model.load_data = String(dataary);
                }else{
                    this.model.load_data = "";
                }
            }else{
                this.model.load_data = "";
            }

        }else if(this.model.load_type == 1){
            //api data
            if(Content_Model.apiData.has(String(this.model.load_name))){
                const resultdata = Content_Model.apiData.get(String(this.model.load_name));
                //path로 전체 리절트 값에서 꺼내기.
                const dataary = this.getValueByPath(resultdata, String(this.model.load_path)) as any;
                if(dataary != null && dataary != undefined){
                    this.model.load_data = String(dataary);
                }else{
                    this.model.load_data = "";
                }
            }else{
                this.model.load_data = "";
            }
        }

    }    
    
    //Play_Interface 구현
    init(): void {

    }

    exit(): void {
        //HTMLElement 초기화
        
    }

    drawView(): void{
        
    }

    //json값에서 path에 해당하는 값을 꺼냄. [key][0][key] 와 같은 동적 path가능.
    getValueByPath<T>(json: Record<string, any>, path: string): T | undefined {
        const parts = path.match(/\[([^\]]+)\]/g) || [];
        return parts.reduce((acc: any, part: string) => {
            // 대괄호를 제거하고 실제 키값만 추출
            const key = part.replace(/\[|\]/g, '');
            // 숫자로 변환할 수 있는 키는 배열의 인덱스로, 그렇지 않은 키는 객체의 키로 처리
            return acc && (acc.hasOwnProperty(key) ? acc[key] : acc[Number(key)]);
        }, json) as T;
    }

    
    
  
}

export default Play_DataLoad
customElements.define('play-dataload', Play_DataLoad);