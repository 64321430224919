
<template>
<v-row>
    <v-col>
    마케팅정보동의
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <v-card color="#f0f0f0" height="50px">
        <v-card-text>
          <p>BARO에서 보내드리는 메일정보 수신에 동의하시겠습니까?</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
export default {
 
    data: () => ({
    }),

    mounted(){ 
    },

    methods: {

    },
}
</script>

<style scoped>
</style>